import { filter, includes } from "lodash";

/**
 * Colunas do Grid
 */
export const columns = [{ text: "Descrição", value: "descricao" }];

/**
 * Ações do submenu
 */
export const subactions = function () {
  return [
    // {
    //   type: "report",
    //   title: "Relatórios",
    //   disabled: true,
    //   items: [
    // { id: "produtos_por_grupo", title: "Produtos por grupo" }
    // ]
    // }
  ];
};

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = filter(columns, (column) =>
  // Retorna as colunas que as incluem
  includes(["descricao"], column.value)
);

/**
 * Cache default
 */
export const cache = {
  selecionado: {
    index: 0,
    coluna: 0,
  },
  colunas: ["descricao"],
  filtros: [],
  ordenar: [
    {
      coluna: "descricao",
      ordem: "desc",
    },
  ],
};
