<template>
  <div v-if="useAsSlot">
    <slot />
  </div>

  <modal
    no-shadow
    persistent
    hide-overlay
    ref="modal"
    :title="title"
    :width="width"
    :height="dialogHeight"
    :flex-end="flexEnd"
    :fullscreen="fullscreen"
    @close="onModalClose"
    v-model="modal"
    v-bind="$attrs"
    v-resize="onResize"
    v-else
  >
    <template #title>
      <slot name="title" v-bind="{ processing, __ficha }" />
      <slot name="tab" v-bind="{ current: getCurrent, setCurrent, __ficha }" />
      <!-- <v-divider /> -->
    </template>

    <template #extra>
      <slot name="extra" />

      <component
        v-on="activeContextListeners"
        v-bind="activeContextProps"
        @close="onActiveComponentClose"
        :is="activeContextComponent"
      />

      <component
        v-on="activeFichaListeners"
        v-bind="activeFichaProps"
        @close="onActiveFichaClose"
        :is="activeFichaComponent"
      />
    </template>

    <template #overlay>
      <v-overlay opacity="0.36" :value="loading" absolute>
        <splash no-text white :width="splashWidth" />
      </v-overlay>
    </template>

    <template #text="props">
      <slot name="text" v-bind="{ ...props, __ficha }">
        <v-form
          :disabled="isFichaDisabled"
          :readonly="isFichaReadonly"
          v-if="currentModuloComponent"
          @submit.prevent="() => null"
        >
          <component
            :is="currentModuloComponent"
            :value="getCurrent"
            :selected="selected"
            :setSelected="setSelected"
            :isFichaDisabled="isFichaDisabled"
            :isFichaReadonly="isFichaReadonly"
            @close="onModalClose"
            v-bind="{ ...props, data, extra, __ficha }"
          />
        </v-form>
        <template v-else>
          <div class="mt-3 text-center">Ficha indisponível</div>
        </template>
      </slot>
    </template>

    <template #actions>
      <!-- <v-divider /> -->
      <v-card-actions class="px-4 py-2" style="height: 45px" v-if="!hideFooter">
        <div
          v-if="data && data.user_updated && data.updated_at && !$isMobile"
          class="caption text-lg-subtitle-1"
        >
          <small>
            Última alteração feita por
            <strong>{{ data.user_updated }}</strong>
            em
            {{ data.updated_at | date }}
          </small>
        </div>
        <v-spacer />
        <slot
          name="action-content"
          v-bind="{ current: getCurrent, setCurrent, __ficha }"
        />
        <btn
          small
          color="green darken-2"
          btn-class="white--text focus-priority"
          :loading="processing"
          :disabled="processing"
          @click="onModalClose"
        >
          Ok
        </btn>
      </v-card-actions>
      <div v-else />
    </template>
  </modal>
</template>

<script>
import Vue from "vue";

import debounce from "lodash/debounce";
import difference from "lodash/difference";
import get from "lodash/get";
import head from "lodash/head";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isObject from "lodash/isObject";
import isUndefined from "lodash/isUndefined";
import keys from "lodash/keys";
import pick from "lodash/pick";
import uniq from "lodash/uniq";
import values from "lodash/values";
import { mapActions } from "vuex";

import { EventBus } from "@/main";

import Modal from "@/components/utils/Modal.vue";
import Splash from "@/components/utils/Splash.vue";

import mutations from "@/store/mutations";
import actions from "@/store/actions";

import { Btn } from "@/components/form";
import { mDate } from "@/plugins/moment";

import { form } from "@/config/required";
import getters from "@/store/getters";

export default Vue.extend({
  name: "Ficha",
  props: {
    __modulo: Object,
    modulo: String,
    id: {},
    action: {},
    last: { type: Boolean, default: false },

    hideFooter: Boolean,
    fullscreen: Boolean,

    flexEnd: { type: Boolean, default: true },
    useAsSlot: { type: Boolean, default: false },
    initialData: { type: Object, default: () => ({}) },
    overlay: { type: Boolean, default: false },
    onData: { type: Function },

    width: { default: "100vw" },
    splashWidth: { default: "500px" },
    height: {},
    title: { default: "Ficha" },
  },
  filters: {
    date(value) {
      return mDate(value);
    },
  },
  components: {
    Btn,
    Modal,
    Splash,
  },
  /**
   * Vue override Hook
   */
  created() {
    const defaultData = this.getDefaults();
    this.append({ ...defaultData });

    this.append({ ...this.initialData });

    this.init();
  },
  data: () => ({
    modal: true,
    processing: false,
    dialogHeight: "0px",

    current: null,

    loading: true,
    changed: false,
    creating: false,
    data: { id: null },
    selected: {},
    extra: {},

    currentModuloComponent: null,

    activeContextProps: {},
    activeContextListeners: {},
    activeContextComponent: null,

    activeFichaListeners: {},
    activeFichaProps: {},
    activeFichaComponent: null,
  }),
  /**
   * Hook acionado antes de destruir o componente
   */
  beforeDestroy() {
    this.deinit();
  },
  /**
   * Hook acionado após montar o componente
   */
  mounted() {
    // Chama a função para recalcular a tela
    this.onResize_();
  },
  computed: {
    extraReadOneParams() {
      try {
        // Faz o require dinâmico para recuperar a ficha default
        const { extraReadOne } = this.$isConfig(this.modulo)
          ? require(`@/config/modulos/config/${this.modulo}`)
          : require(`@/config/modulos/${this.modulo}`);

        // Se for funtion, faz o bind
        return typeof extraReadOne === "function"
          ? extraReadOne.bind(this)()
          : {};
      } catch (e) {
        return {};
      }
    },
    isFichaReadonly() {
      return this.creating;
    },
    isFichaDisabled() {
      return this.$permissions[this.$permissionId(this.modulo)] === 3;
    },
    /**
     * Função que retorna a própria instância
     */
    __ficha() {
      return this;
    },
    /**
     * Função que retorna a tab/step ativa
     */
    getCurrent() {
      return this.current && this.current.item;
    },
    /**
     * Função que verifica se mostra o carregamento
     */
    fetching() {
      // Define os módulos que não terão carregamento
      const blacklist = ["nfe", "nfse", "sistema"];
      // Retorna se o módulo não está na blacklist e se está fazendo requisição
      return !blacklist.includes(this.modulo) && this.loading;
    },
  },
  methods: {
    ...mapActions({
      fetchRegistro: actions.MODULO.FICHA.READ,
      afterCloseFicha: actions.MODULO.AFTER_CLOSE_FICHA,
    }),
    /**
     * Função que abre uma ficha por cima da atual
     */
    initFichaAuxiliar(params) {
      this.activeFichaProps = {
        ...pick(params, ["modulo", "action", "id", "initialData", "onData"]),
        overlay: true,
        fullscreen: this.$isMobile,
      };

      const container = params.container ?? "TabContainer";

      this.$nextTick(() => {
        this.activeFichaComponent =
          require(`@/views/modulo/ficha/${container}.vue`).default;
      });
    },
    /**
     * Mutation de replace state data
     */
    setData(data) {
      this.data = { ...data };

      if (this.modulo === "emitente" && get(data, "id")) {
        this.$store.commit(mutations.APP.EMITENTE, data);
      }

      // Propaga o evento da leitura
      this.$emit("read", { data: this.data, extra: this.extra });
    },
    /**
     * Mutation de append state data
     */
    append(data) {
      this.data = {
        ...this.data,
        ...data,
      };
    },
    /**
     * Mutation de append state extra
     */
    appendExtra(extra) {
      this.extra = {
        ...this.extra,
        ...extra,
      };
    },
    setSelected(selected) {
      return (this.selected = {
        ...this.selected,
        ...selected,
      });
    },
    /**
     * Função que retorna os dados default da ficha
     */
    getDefaults() {
      try {
        // Faz o require dinâmico para recuperar a ficha default
        let { ficha } = this.$isConfig(this.modulo)
          ? require(`@/config/modulos/config/${this.modulo}`)
          : require(`@/config/modulos/${this.modulo}`);

        // Se for funtion, faz o bind
        ficha = typeof ficha === "function" ? ficha.bind(this)() : {};

        // Se tiver a ficha retorna
        return Object.assign({ id: null }, ficha);
      } catch (e) {
        return { id: null };
      }
    },
    /**
     * Função que limpa o state da ficha
     */
    clearState(ignoreCurrent = false) {
      // Se não for pra ignorar o step/tab, limpa
      if (!ignoreCurrent) this.setCurrent(null);

      // Limpa os dados
      this.setData({
        id: null,
      });
    },
    /**
     * Função que inicia os status das dependências e das relações
     */
    resources() {
      try {
        // Captura as dependencias e relações
        const { dependencies } = require(`@/config/modulos/${this.modulo}`);

        // Para cada dependência
        (dependencies || []).forEach((dependence) => {
          // Captura os itens da dependência
          const items = this.data[dependence.name] ?? [];
          // Atribui o valor
          this.data[dependence.name] = items;
        });
      } catch (e) {
        return;
      }
    },
    /**
     * Função que seta o step/tab atual
     */
    setCurrent(current) {
      this.current = current;
    },
    /**
     * Função que valida qual tipo de requisição enviar com base nos dados do evento
     */
    getRequestType(event) {
      if (!isNull(event.relation) && !isEmpty(event.relation)) {
        return actions.MODULO.FICHA.UPDATE_RELATION;
      }

      if (event.modulo === "sistema" || event.modulo === "emitente") {
        return actions.MODULO.FICHA.UPDATE_REGISTRO;
      }

      if (!get(this.data, "id") && !this.id) {
        return actions.MODULO.FICHA.CREATE_REGISTRO;
      }

      return actions.MODULO.FICHA.UPDATE_REGISTRO;
    },
    handleParent(parent, modulo = null) {
      if (!parent) return;

      // Se houver id e não houver registro criado ainda
      if (parent.id && !get(this.data, "id")) {
        // Dispara a rotina de after create
        this.afterCreate(parent, modulo);
      }

      // Caso contrário
      else {
        // Commita o registro na ficha
        this.append(parent);
      }
    },
    /**
     * Função que faz o handle da dependência
     */
    handleDependence(e) {
      const { type } = e;

      if (this.creating) {
        return Promise.reject();
      }

      // Se for uma requisição de create e não tem id
      if (type === "create" && !this.data.id) {
        this.creating = true;
        e.parent = this.data;
      }

      return new Promise((res, rej) =>
        debounce(async (event) => {
          // Retorna a promessa da requisição
          try {
            // Desestrutura o evento
            const { dependence, options } = event;

            // Desestrutura as opções
            const { ignoreAfterUpdate, updatedInsteadCreated, ignoreParent } =
              options ?? {};

            // Adiciona o registro id parent
            event.registro_id = get(this.data, "id");

            // Aguarda a resposta
            const response = await this.$store.dispatch(
              actions.MODULO.FICHA.HANDLE_DEPENDENCE,
              event
            );

            if (this.creating) this.creating = false;

            // Caso for um delete
            if (type === "delete" && !ignoreAfterUpdate) {
              // Commita o delete da dependência
              this.onDeleteDependence({
                // Id do registro
                id: event.id,
                // Dependencia
                dependence,
              });

              // Commita o registro no grid
              this.$store.commit(mutations.MODULO.REGISTROS.UPDATED, {
                ...this.data,
              });
            }

            // Se não houver dados na resposta, retorna
            if (!response.data || ignoreAfterUpdate) {
              return res(response);
            }

            // Captura o registro (dependencia) alterado e o seu pai
            let registros = get(response, "data.registros");
            const parent = get(response, "data.parent");

            // Transforma para array se não for
            registros = !isEmpty(registros)
              ? registros
              : [get(response, "data.registro", {})];

            // Se houver pai
            if (parent && !ignoreParent) {
              this.handleParent(parent, event.modulo);
            }

            // Se for do tipo create
            if (type === "create" && !updatedInsteadCreated) {
              // Se for uma dependência object (normalmente é um array)
              // if (includes(["vendedor"], dependence)) {
              //   // Captura o primeiro registro
              //   registros = head(registros) ?? {};
              // }

              // Commita a dependência criada
              this.onCreateDependence({
                // Dependencia
                dependence,
                // registro
                items: registros,
              });
            }

            // Se for do tipo update
            if (type === "update" || updatedInsteadCreated) {
              // Commita a dependência atualizada
              this.onUpdateDependence({
                items: registros,
                dependence,
              });
            }

            // Commita o registro no grid
            this.$store.commit(mutations.MODULO.REGISTROS.UPDATED, {
              ...this.data,
            });

            // Para cada registro atualizado, verifica se tem alteração persistent
            // each(registros, (registro) => {
            //   // Atualiza o persistent
            //   this.$store.commit(mutations.MODULO.PERSISTENT.UPDATED, {
            //     registro,
            //     modulo: event.modulo,
            //   });
            // });

            // Retorna a resposta
            return res(response);
          } catch (error) {
            // Faz o log do erro
            window.error(error);

            if (this.creating) this.creating = false;

            rej(error);
          }
        }, 100)(e)
      );
    },
    /**
     * Função que é chamada quando algum valor mudar (input persistente)
     */
    onChangePersistent(e) {
      if (this.creating) {
        return;
      }

      // se não for um objeto, retorna
      if (!isObject(e)) {
        return;
      }

      // Faz o coalesce do módulo e do id
      e.modulo = e.modulo ?? this.modulo;
      e.id = e.id ?? this.data.id;

      // Captura o tipo da requisição com base no evento
      const type = this.getRequestType(e);

      // Se for uma requisição de create e não tem id
      if (type === actions.MODULO.FICHA.CREATE_REGISTRO && !this.data.id) {
        // this.creating = true;
      }

      return new Promise((res, rej) =>
        debounce(async (event) => {
          // Aguarda um tick para atualizar os dados
          await this.$nextTick();

          try {
            const { format } = require(`@/config/modulos/${event.modulo}`);
            typeof format === "function" && this.append(format.bind(this)());
          } catch {
            //
          }

          // Aplica as validações
          const [valid, sendFullData] = this.validate(event);

          // Atualiza para changed true
          this.changed = true;

          // Se não for válido, aborta a requisição
          if (!valid) {
            return;
          }

          // se for para enviar todos os dados
          if (sendFullData || event.sendFullData) {
            // Seta o state no evento
            event.data = { ...this.data };
          }

          try {
            // Se for uma dependência
            if (event.dependence) {
              // REtorna a função de handle dependence
              return this.handleDependence(event);
            }

            // Se for uma requisição de create
            if (type === actions.MODULO.FICHA.CREATE_REGISTRO) {
              this.creating = true;

              // faz o merge dos dados
              event.data = {
                ...this.data,
                ...(event.data ?? {}),
              };
            }

            // se for um update
            if (type === actions.MODULO.FICHA.UPDATE_REGISTRO) {
              // insere o id dentro do evento
              event.id = event.id ?? this.id;
            }

            if (event.relation) {
              event.originalId = get(this.data, "id");
            }

            // Envia a requisição para o backend
            const response = await this.$store.dispatch(type, event);

            if (this.creating) this.creating = false;

            // Captura o registro
            const registro = get(response, "data.registro") ?? {};
            const extra = get(response, "data.extra");

            if (extra) {
              this.appendExtra(extra);
            }

            // Se for uma requisição de update
            if (type === actions.MODULO.FICHA.UPDATE_REGISTRO) {
              // Chama o hook de after update
              this.afterUpdate(registro, event.modulo);
            }

            // Se for uma requisição de create
            if (type === actions.MODULO.FICHA.CREATE_REGISTRO) {
              // Chama o hook de after update
              this.afterCreate(registro, event.modulo);
            }

            // retorna a resposta
            return res(response);
          } catch (error) {
            window.error(error);
            return rej(error);
          }
        }, 100)(e)
      );
    },
    /**
     * Função que atualiza a imagem
     */
    updateImage(params) {
      return this.onImageChange(actions.MODULO.FICHA.UPDATE_FILE, params);
    },
    /**
     * Função que apaga a imagem
     */
    deleteImage(params) {
      return this.onImageChange(actions.MODULO.FICHA.DELETE_FILE, params);
    },
    /**
     * Função genérica de update/delete de imagem
     */
    onImageChange(type, params) {
      params.modulo = params.modulo ?? this.modulo;
      params.id = params.id ?? this.data.id;

      // Retorna a promessa
      return this.$store.dispatch(type, params).then((response) => {
        // Desestrutura
        const { data } = response;

        // Se não houver data, retorna
        if (!data) return;

        // Se houver config
        if (data.config) {
          // Commita configurações
          this.$store.commit(mutations.APP.CONFIG, data.config);
        }

        // Se houver mensagem de retorno
        if (data.message) {
          // Commita o snackbar
          this.$store.commit(mutations.APP.SNACKBAR, {
            active: true,
            // Mensagem da resposta
            text: data.message,
          });
        }

        this.$store.commit(mutations.MODULO.PERSISTENT.UPDATED, {
          registro: data.registro,
          modulo: params.modulo,
        });

        this.$store.commit(mutations.MODULO.REGISTROS.UPDATED, data.registro);

        // Atualiza as chaves
        this.forceUpdateKeys(data.registro);

        return response;
      });
    },
    /**
     * Hook de after create
     */
    afterCreate(registro, modulo) {
      // Faz o merge dos dados
      this.append(registro);

      // Commita o registro persistente
      this.$store.commit(mutations.MODULO.PERSISTENT.DATA, {
        data: [registro],
        modulo: modulo ?? this.modulo,
      });

      // Se for uma ficha de overlay
      if (this.overlay) return;

      // Emite evento de create
      this.$emit("create", this.data);

      // Commita a criação do registro no grid
      this.$store.commit(mutations.MODULO.REGISTROS.CREATED, registro);

      this.goToUpdateRoute(registro.id);
    },
    goToUpdateRoute(id) {
      if (!id) return;

      // Se a ficha estiver aberta no módulo atual, e não estiver fazendo update
      if (this.action && this.action !== "alterar") {
        // Altera a rota para update passando o id do registro criado
        this.$router.push({
          params: { id, action: "alterar" },
        });
      }
    },
    /**
     * Hook de after update
     */
    afterUpdate(registro, modulo = null) {
      // Verifica por chaves forçadas de atualização
      this.forceUpdateKeys(registro);

      // Verifica pelos recursos da ficha
      this.resources();

      // Commita o registro persistente
      this.$store.commit(mutations.MODULO.PERSISTENT.UPDATED, {
        registro,
        modulo: modulo ?? this.modulo,
      });

      if ((modulo ?? this.modulo) === "emitente") {
        this.$store.commit(mutations.APP.EMITENTE, registro);
      }

      // Emite evento de update
      this.$emit("update", this.data);
    },
    /**
     * Função que encontra o próximo/anterior da ficha atual
     */
    goTo({ position, id }) {
      // Captura o id do parâmetro ou da ficha
      id = id ?? this.data.id;

      // Captura o registro atual
      const current = parseInt(id);

      // Se o id atual não existir, retorna
      if (isNaN(current)) return;

      // Captura a lista de registros carregados do módulo
      // const data = _getters[getters.MODULO.REGISTROS.DATA];
      const data = this.__modulo.data ?? [];

      // Captura o index do registro atual
      const index = data.findIndex((registro) => registro.id === current);

      // O novo index vai ser o atual somado com o do parametro (pra voltar um item position = -1, para avançar position = 1)
      const newIndex = index + position;

      // Se o novo intex está fora dos limites da lista, retorna
      // (aqui seria feita uma requisição para ver se existe próximos registros que não estão carregados no grid do modulo)
      // if (newIndex >= data.length || newIndex < 0) return;

      // Captura o registro no novo index
      const registro = data[newIndex];

      // se não encontrar registro, retorna
      if (!registro) return;

      // Altera os dados da ficha
      this.setData({
        ...registro,
      });

      // Retorna o registro
      return registro;
    },
    /**
     * Função que valida se o evento pode ser enviado ao backend, se precisa enviar algumas informações ou a ficha inteira
     */
    validate(event) {
      // Desestrutura o objeto event e armazena {force, data}
      const { force, data, modulo } = event;

      // Define valid e sendFullData para retornar
      let valid = false;
      let sendFullData = false;

      // Captura o nome e o valor do campo alterado
      const name = head(keys(data));
      const value = head(values(data));

      // Captura os campos obrigatórios para o módulo atual
      const requiredFields = form.bind(this)(modulo ?? this.modulo);

      // Precisamos enviar todos os dados caso o campo preenchido está dentro dos obrigatórios e ainda não tiver criado o registro.
      sendFullData = !this.data.id && (requiredFields || []).includes(name);

      // Os dados serão válidos se:
      // For passado o parâmetro force
      // ou se o valor não for undefined e não for nulo e se possui ID
      valid = !isUndefined(value) && !isNull(value) && !!this.data.id;

      // Verifica a diferença entre os campos obrigatórios e os campos preenchidos,
      // se não estiverem todos preenchidos, os dados estão inválidos e a requisição não é feita
      const diff = difference(requiredFields, keys(this.data));

      valid = force === true || diff.length === 0;

      // Retorna uma lista com as variáveis valid e sendFullData, que precisa ser desestruturado quando chamada essa função
      return [valid, sendFullData];
    },
    /**
     * Função que atualiza o state da ficha baseado nas chaves permitidas
     */
    forceUpdateKeys(registro) {
      // Tenta
      try {
        // Se não houver registro, retorna
        if (!registro) return;

        // Valida se o módulo é de config
        const isConfig = this.$store.getters[getters.MODULO.IS_CONFIG](
          this.modulo
        );

        // Define o modulo
        const modulo = isConfig ? `config/${this.modulo}` : this.modulo;

        // Captura as configs do módulo
        const {
          forceUpdateKeys,
          dependencies,
        } = require(`@/config/modulos/${modulo}`);

        // Captura as chaves de dependencias
        const dependenciesKeys = (dependencies || []).map(
          (dependency) => dependency.name
        );

        // concatena as chaves
        const keys = uniq(
          (forceUpdateKeys ?? []).concat(dependenciesKeys, [
            "updated_at",
            "user_updated",
          ])
        );

        // Caso não haja chaves para serem atualizadas
        if (!keys || !keys.length) {
          return;
        }

        // Atualiza o state com os dados atualizados
        this.append(pick(registro, keys));
      } catch (e) {
        return;
      }
    },
    /**
     * Função que faz a leitura de uma dependência (quando abrir as abas. ex: composição)
     */
    readDependence(params) {
      // se nao for um objeto, retorna
      if (!isObject(params)) return;

      params.modulo = params.modulo ?? this.modulo;
      params.value = params.value ?? get(this.data, "id");

      // Retorna a promessa da requisição
      return this.$store
        .dispatch(actions.MODULO.FICHA.DEPENDENCIES.READ, params)
        .then(({ response, dependence }) => {
          const registros = get(response, "data.registros");
          const extra = get(response, "data.extra");

          if (extra) {
            this.appendExtra(extra);
          }

          if (!registros) return response;

          const registro = {
            id: params.value,
            [dependence.name]: registros,
          };

          this.afterUpdate(registro, params.modulo);

          if (this.overlay) return response;

          this.$store.commit(mutations.MODULO.REGISTROS.UPDATED, registro);

          return response;
        });
    },
    /**
     * Hook mutation para quando for criada uma dependência dentro da ficha
     */
    onCreateDependence({ dependence, items, parent_id }) {
      /* se foi informado o id do parent
       * (quer dizer que está vindo o evento do websocket (alguem apagou essa dependencia))
       * for diferente do id da ficha, retorna
       */
      if (parent_id && parent_id !== get(this.data, "id")) return;

      // Se a dependencia acessada não existir, retorna
      if (!this.data[dependence]) return;

      // Se for um objeto
      if (!Array.isArray(items)) {
        // atualiza os itens
        return (this.data[dependence] = {
          ...(this.data[dependence] ?? {}),
          ...items,
        });
      }

      // Faz a concatenação dos itens
      this.data[dependence] = (this.data[dependence] ?? []).concat(items);
    },
    /**
     * Hook mutation para quando for atualizada uma dependência dentro da ficha
     */
    onUpdateDependence({ dependence, items, parent_id }) {
      /* se foi informado o id do parent
       * (quer dizer que está vindo o evento do websocket (alguem apagou essa dependencia))
       * for diferente do id da ficha, retorna
       */
      if (parent_id && parent_id !== get(this.data, "id")) return;

      // tenta capturar a dependência via dot notation
      const currentItems = [...get(this.data, dependence, [])];

      // Se a dependencia acessada não existir, retorna
      if (!dependence) return;

      // Para cada item
      (items || []).forEach((item) => {
        // Procura pelo index do item atual para atualizá-lo
        const index = currentItems.findIndex(
          (registro) => registro.id === item.id
        );

        // Se não encontrar o indice, retorna
        if (index === -1) {
          if (!item.id) return;
          return currentItems.push(item);
        }

        // Remove o item do indice encontrado e adiciona o novo item
        currentItems.splice(index, 1, item);
      });

      this.append({
        [dependence]: currentItems,
      });
    },
    /**
     * Hook mutation para quando for deletada uma dependência dentro da ficha
     */
    onDeleteDependence({ id, dependence, parent_id }) {
      /* se foi informado o id do parent
       * (quer dizer que está vindo o evento do websocket (alguem apagou essa dependencia))
       * for diferente do id da ficha, retorna
       */
      if (parent_id && parent_id !== get(this.data, "id")) return;

      // Se a dependencia acessada não existir, retorna
      if (!this.data[dependence]) return;

      // Captura o index da dependence
      const index = (get(this.data, dependence) || []).findIndex(
        (item) => item.id === id
      );

      // Se não houver index
      if (index === -1) return;

      // Remove da lista de dependencia
      this.data[dependence].splice(index, 1);
    },
    deinit() {
      // se for overlay, retorna
      if (this.overlay || this.useAsSlot) return;

      const defaultData = this.getDefaults();
      this.data = { ...defaultData };

      // Remove o evento de deleted
      EventBus.$off("registro.deleted", this.onRegistroDeleted);
      // Remove o evento de update
      EventBus.$off("registro.updated", this.onRegistroUpdated);

      // Remove o evento de authorized
      EventBus.$off("registro.authorized", this.onRegistroAuthorized);

      // Remove o evento de authorized
      EventBus.$off("dependence.deleted", this.onDeleteDependence);
      // Remove o evento de created
      EventBus.$off("dependence.created", this.onCreateDependence);
      // Remove o evento de created
      EventBus.$off("dependence.updated", this.onUpdateDependence);
    },
    async init() {
      // se a ficha for um slot, não inicia ela
      if (this.useAsSlot) return;

      // Valida se está no config
      const isConfig = this.$isConfig(this.modulo);

      // Seta os dados na ficha
      this.append({
        // Captura o id da rota, ou o id do emitente, se for módulo config
        id: isConfig ? this.$emitente.id : this.id,
      });

      // se não puder abrir a ficha, retorna
      if (!this.$canOpenFicha(this.modulo)) {
        return this.$nextTick(() => {
          this.onModalClose();
        });
      }

      // se não for uma ficha sobreposta
      if (!this.overlay) {
        // Registra evento de deleted do registro
        EventBus.$on("registro.deleted", this.onRegistroDeleted);
        // Registra evento de update do registro
        EventBus.$on("registro.updated", this.onRegistroUpdated);

        // Registra evento de authorized do registro
        EventBus.$on("registro.authorized", this.onRegistroAuthorized);

        // Registra evento de authorized do registro
        EventBus.$on("dependence.deleted", this.onDeleteDependence);
        // Registra evento de created do registro
        EventBus.$on("dependence.created", this.onCreateDependence);
        // Registra evento de created do registro
        EventBus.$on("dependence.updated", this.onUpdateDependence);
      }

      // Tenta recuperar as informações de ficha do módulo atual
      try {
        // Faz o tratamento do nome do módulo
        const modulo = this.$moduloFilename(this.modulo);

        // Faz o require do componente de formulário do módulo
        this.currentModuloComponent = isConfig
          ? // Se for config, adiciona o /config
            require(`./modulos/config/${modulo}.vue`).default
          : // Senão fica na pasta principal dos módulos
            require(`./modulos/${modulo}.vue`).default;

        // blacklist
        const blacklist = ["Sistema", "Nfe", "Nfse", "Cupons", "Contabil"];

        // Se o componente ignora a requisição incial de get, retorna
        // if (isConfig && includes(blacklist, modulo)) {
        if (blacklist.includes(modulo)) {
          return;
        }

        // Caso contrário, faz a leitura do registro
        const response = await this.fetchRegistro({
          id: get(this.data, "id") ?? this.id ?? "extra",
          config: isConfig,
          modulo: this.modulo,
          last: this.last,
          ...this.extraReadOneParams,
        });

        //  se não tiver dados, retorna
        if (!response || !response.data) return;

        const registro = get(response, "data.registro") ?? {};
        const extra = get(response, "data.extra") ?? {};

        // Se houver id e não for ficha de overlay
        if (get(registro, "id") && !this.overlay) {
          // Se não puder editar a ficha
          if (this.__modulo && !this.__modulo.validateForEditAction(registro)) {
            // volta para o grid
            return this.$router.replace({
              name: "modulo",
              params: { modulo: this.modulo },
            });
          }
        }

        // seta o state da ficha
        this.extra = { ...extra };
        // this.append({ ...registro });
        this.setData({
          ...this.data,
          ...registro,
        });

        if (!this.id && registro.id) {
          this.goToUpdateRoute(registro.id);
        }

        // inicializa os recursos
        this.resources();
      } catch (error) {
        window.error(error);
        // fecha a ficha
        this.onModalClose();
      } finally {
        this.loading = false;
      }
    },
    onActiveComponentClose() {
      this.activeContextComponent = null;
      this.activeContextProps = {};
      this.activeContextListeners = {};
    },
    onActiveFichaClose() {
      this.activeFichaComponent = null;
      this.activeFichaProps = {};
      this.activeFichaListeners = {};
    },
    /**
     * Função que abre o alert na tela
     */
    $alert(params = {}, on = {}) {
      this.activeContextProps = params;
      this.activeContextListeners = on;

      return this.$nextTick(() => {
        this.activeContextComponent =
          require("@/components/utils/info/Alert.vue").default;
      });
    },
    /**
     * Função callback de quando um documento está aberto e foi autorizado por outro usuário
     */
    onRegistroAuthorized(registro) {
      // se não for o documento aberto, retorna
      if (get(this.data, "id") !== get(registro, "id")) return;

      // Captura o status
      const status = get(registro, "nfe_status_descricao");

      // se tiver status
      if (status) {
        // Mostra a mensagem na tela
        this.$store.commit(mutations.APP.SNACKBAR, {
          active: true,
          text: status,
        });
      }

      this.$nextTick(() => {
        this.clearAndGoToNew();
      });
    },
    /**
     * Função callback de update do registro
     */
    onRegistroUpdated(registro) {
      // se não for o registro da mesma ficha, retorna
      if (get(registro, "id") !== get(this.data, "id")) return;
      // faz o merge dos dados
      this.append(registro);
    },
    /**
     * Função callback de delete do registro
     */
    onRegistroDeleted({ id, user, modulo }) {
      // se não for o mesmo registro, retorna
      if (get(this.data, "id") !== id) return;

      // Captura as informações da rota atual
      const { action } = this.$route.params;

      // Se a ficha estiver aberta no módulo atual, e não estiver fazendo update
      if (this.modulo === modulo && action === "alterar") {
        // Fecha a ficha e volta pro módulo
        this.$router.replace({
          name: "modulo",
          params: { modulo },
        });

        this.__modulo.$simpleAlert({
          title: "Atenção",
          message: `O registro <strong>${id}</strong> foi apagado por <strong>${user}</strong>.`,
          value: true,
        });
      }
    },
    onResize: debounce(function () {
      this.$nextTick(() => {
        this.onResize_();
      });
    }, 300),
    /**
     * função que faz o resize da altura da ficha
     */
    onResize_() {
      try {
        if (this.height) {
          return (this.dialogHeight = this.height);
        }

        const { moduloMenu, mainLayout } = this.$root.$refs;

        const vh = Math.max(0, window.innerHeight);

        const header = mainLayout.$refs.header
          ? mainLayout.$refs.header.clientHeight
          : 0;
        const modulo = moduloMenu.$el ? moduloMenu.$el.clientHeight : 0;

        const offset = this.$getPWADisplayMode() === "standalone" ? 8 : 0;

        this.dialogHeight =
          vh -
          modulo -
          header -
          (this.$isMobile ? 0 : 12) -
          (this.$getPWADisplayMode() === "standalone" ? -4 : 0) -
          offset +
          "px";
      } catch (e) {
        return;
      }
    },
    /**
     * Função que gerencia o fechamento da ficha
     */
    onModalClose() {
      if (this.overlay) {
        return this.$emit("close");
      }

      // Seta o timeout
      const timeout = setTimeout(() => {
        // Inicia o loading
        this.processing = true;
      }, 300);

      // Chama a função after close ficha
      this.afterCloseFicha({
        modulo: this.modulo,
        data: this.data ?? {},
      })
        .then((response) => {
          if (response && this.__modulo) {
            this.__modulo.onRefreshModuloData();
          }
        })
        .finally(() => {
          // limpa a ficha
          this.clearState();

          // Limpa o timeout
          clearTimeout(timeout);

          // Se tiver processando, cancelar
          if (this.processing) this.processing = false;

          const isConfig =
            this.$isConfig(this.modulo) || this.modulo === "config";

          // Define a rota
          const route = isConfig
            ? {
                name: "config",
              }
            : {
                name: "modulo",
                params: { modulo: this.modulo },
              };

          // faz o push da rota
          this.$router.push(route);
        });
    },
    /**
     * Limpa o form, move para a aba inicial e troca para a rota 'Novo'
     */
    clearAndGoToNew(current = null) {
      // Captura a ficha com os valores defaults
      const ficha = this.getDefaults();

      // Limpa a ficha
      this.clearState(true);

      // commita o default
      this.setData(Object.assign({ id: null }, ficha));

      // Cria os índices das dependências
      this.resources();

      // se for informado tab/step, seta
      if (current) this.setCurrent(current);

      // se já estiver na rota novo, ou for uma ficha overlay, retorna
      if (this.$route.params.action === "novo" || this.overlay) return;

      // aguarda o proximo tick
      return this.$nextTick(() => {
        // Altera para a rota de novo
        this.$router.replace({
          params: { id: null, action: "novo" },
        });
      });
    },
  },
  watch: {
    getCurrent() {
      this.selected = {};
    },
    data: {
      handler() {
        this.onData && this.onData(this.data);
      },
      deep: true,
    },
    /**
     * Prop de carregamento da ficha
     */
    loading(isLoading, wasLoading) {
      // Se não está carregando, mas estava antes
      if (!isLoading && wasLoading) {
        // emite o loaded
        this.$emit("loaded");
      }
    },
  },
});
</script>
