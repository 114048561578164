<template>
  <tabs-items
    v-model="value"
    @loaded-tab-planos-conta-contabil="onDependenceTabLoaded"
  >
    <template #tab-contabilidade>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <container-content title="Dados do contador">
            <v-row>
              <p-text-field
                auto-focus
                label="Nome do Contabilista"
                modulo="sistema"
                name="contabilidade_json.nome_contador"
                v-model="contabilidade.nome_contador"
              />
              <field-cpf-cnpj
                sm="6"
                label="CPF do Contabilista"
                modulo="sistema"
                name="contabilidade_json.cpf_contador"
                v-model="contabilidade.cpf_contador"
              />
              <p-text-field
                type="tel"
                sm="6"
                label="Inscrição do Contabilista (CRC)"
                maxlength="20"
                modulo="sistema"
                name="contabilidade_json.crc_contador"
                v-model="contabilidade.crc_contador"
              />
            </v-row>
          </container-content>

          <container-content title="Dados da contabilidade" class="mb-3">
            <v-row>
              <field-cpf-cnpj
                sm="6"
                label="CNPJ do Escritório"
                modulo="sistema"
                name="contabilidade_json.cgc_contabilidade"
                v-model="contabilidade.cgc_contabilidade"
              />
              <field-cep
                sm="6"
                modulo="sistema"
                name="contabilidade_json.cep"
                v-model="contabilidade.cep"
              />
              <p-select-uf
                sm="3"
                @change="(uf) => $refs.municipios.read(uf)"
                modulo="sistema"
                name="contabilidade_json.uf"
                v-model="contabilidade.uf"
              />
              <p-select-municipios
                sm="9"
                ref="municipios"
                :uf="contabilidade.uf"
                modulo="sistema"
                name="contabilidade_json.municipio_id"
                v-model="contabilidade.municipio_id"
              />
              <p-text-field
                sm="8"
                label="Endereço"
                maxlength="60"
                modulo="sistema"
                name="contabilidade_json.endereco"
                v-model="contabilidade.endereco"
              />
              <field-numero
                sm="4"
                maxlength="60"
                modulo="sistema"
                name="contabilidade_json.numero"
                v-model="contabilidade.numero"
              />
              <p-text-field
                sm="6"
                label="Bairro"
                maxlength="60"
                modulo="sistema"
                name="contabilidade_json.bairro"
                v-model="contabilidade.bairro"
              />
              <p-text-field
                sm="6"
                label="Complemento"
                maxlength="60"
                modulo="sistema"
                name="contabilidade_json.complemento"
                v-model="contabilidade.complemento"
              />
              <field-telefone
                sm="6"
                modulo="sistema"
                name="contabilidade_json.telefone"
                v-model="contabilidade.telefone"
              />
              <field-telefone
                label="Fax"
                sm="6"
                modulo="sistema"
                name="contabilidade_json.fax"
                v-model="contabilidade.fax"
              />
              <field-email
                modulo="sistema"
                name="contabilidade_json.email"
                v-model="contabilidade.email"
              />
            </v-row>
          </container-content>
        </v-row>
      </v-col>
    </template>

    <template #tab-planos-conta-contabil>
      <component
        :is="component"
        v-if="component"
        v-bind="componentProps"
        v-on="componentListeners"
      />

      <v-col class="px-0 pt-2" lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row class="mx-0" :style="`height: ${height() - 38}px`">
          <v-col cols="12" class="px-1">
            <v-row class="ma-0">
              <btn
                outlined
                :disabled="isFichaDisabled"
                @click="() => onNewOrEditClick()"
                >Novo</btn
              >
              <btn
                outlined
                :disabled="isFichaDisabled"
                class="ml-2"
                @click="() => onNewOrEditClick('edit')"
              >
                Alterar
              </btn>
              <btn
                outlined
                :disabled="isFichaDisabled"
                class="ml-2"
                @click="onImport"
                >Importar</btn
              >
            </v-row>

            <div
              class="mt-2"
              :style="`height: ${height() - 38 - 28}px; width: 100%;`"
            >
              <data-table
                hide-footer
                hide-empty-row
                auto-focus
                :loading="loading"
                :items="data.planos_conta"
                :headers="planosHeaders"
                @dblclick-cell="onCellDoubleClick"
                @force-delete="onDelete"
                :value="selected"
                @input="setSelected"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-icms-recolher>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2" class="px-0">
        <v-row class="ma-0">
          <v-col cols="12" class="px-1">
            <p class="mb-0">Dados para o registro E116 - ICMS a recolher</p>
            <small>
              * Verifique com seu contador para o preenchimento correto das
              informações
            </small>
          </v-col>
          <p-select-filter
            md="4"
            showValueText
            modulo="sistema"
            label="Código da obrigação"
            name="sped_icms_recolher.codigo_obrigacao"
            :items="codigosObrigacao"
            v-model="icmsRecolher.codigo_obrigacao"
          />
          <p-date-time
            md="4"
            modulo="sistema"
            label="Data de vencimento obrigação"
            name="sped_icms_recolher.vencimento_obrigacao_dt"
            v-model="icmsRecolher.vencimento_obrigacao_dt"
          />
          <p-text-field
            md="4"
            modulo="sistema"
            label="Código da receita obrigação"
            name="sped_icms_recolher.codigo_receita_obrigacao"
            v-model="icmsRecolher.codigo_receita_obrigacao"
          />
        </v-row>

        <v-row class="ma-0">
          <p-date-time
            md="4"
            modulo="sistema"
            label="Mês da referência"
            name="sped_icms_recolher.referencia_dt"
            v-model="icmsRecolher.referencia_dt"
          />
          <p-text-field
            md="4"
            modulo="sistema"
            label="Número do processo/auto de infração"
            name="sped_icms_recolher.numero_processo_infracao"
            v-model="icmsRecolher.numero_processo_infracao"
          />
          <p-select-filter
            showValueText
            md="4"
            modulo="sistema"
            label="Indicador de origem do processo"
            name="sped_icms_recolher.indicador_origem_processo"
            :items="origemProcesso"
            v-model="icmsRecolher.indicador_origem_processo"
          />
        </v-row>

        <p-text-field
          modulo="sistema"
          label="Código do ajuste de dedução de cada Secretaria de Fazenda (E111) - 02"
          name="sped_icms_recolher.codigo_ajuste_deducao"
          v-model="icmsRecolher.codigo_ajuste_deducao"
        />
        <p-text-field
          modulo="sistema"
          label="Descrição resumida do processo que embasou o lançamento"
          name="sped_icms_recolher.descricao_resumida"
          v-model="icmsRecolher.descricao_resumida"
        />
        <p-text-field
          modulo="sistema"
          label="Descrição complementar das obrigações a recolher"
          name="sped_icms_recolher.descricao_complementar"
          v-model="icmsRecolher.descricao_complementar"
        />
      </v-col>
    </template>

    <template #tab-icms-apuracao>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <container-content title="Apuração do ICMS">
          <v-row>
            <p-precision-field
              label="Saldo credor do período anterior - ICMS operações próprias"
              name="sped_icms_apuracao.saldo_credor_icms"
              modulo="sistema"
              v-model="icmsApuracao.saldo_credor_icms"
            />
            <p-precision-field
              label="Saldo credor do período anterior - ICMS Substituição Tributária"
              name="sped_icms_apuracao.saldo_credor_icms_st"
              modulo="sistema"
              v-model="icmsApuracao.saldo_credor_icms_st"
            />
          </v-row>
        </container-content>
      </v-col>
    </template>
  </tabs-items>
</template>

<script>
import get from "lodash/get";

import {
  PTextField,
  PPrecisionField,
  PSelectFilter,
  PSelectUf,
  PDateTime,
  PSelectMunicipios,
  Btn,
  ContainerContent,
  FieldEmail,
  FieldTelefone,
  FieldNumero,
  FieldCep,
  FieldCpfCnpj,
} from "@/components/form";

import { TabsItems } from "@/components/utils";

import DataTable from "@/components/table/DataTable.vue";

// Mixins
import Ficha from "@/mixins/ficha";
import actions from "@/store/actions";

export default Ficha.extend({
  components: {
    PTextField,
    PPrecisionField,
    PSelectFilter,
    FieldNumero,
    PDateTime,
    FieldCpfCnpj,
    TabsItems,
    ContainerContent,
    PSelectUf,
    PSelectMunicipios,
    Btn,
    FieldEmail,
    FieldTelefone,
    FieldCep,
    DataTable,
  },
  data: () => ({
    loading: false,
    contabilidade: {},
    icmsApuracao: {},
    icmsRecolher: {},

    component: null,
    componentProps: {},
    componentListeners: {},
  }),
  created() {
    this.$nextTick(() => {
      this.contabilidade = { ...(this.$config.contabilidade_json ?? {}) };
      this.icmsApuracao = { ...(this.$config.sped_icms_apuracao ?? {}) };
      this.icmsRecolher = { ...(this.$config.sped_icms_recolher ?? {}) };
    });
  },
  methods: {
    /**
     * Importa os grupos do estoque para os planos
     */
    async onImport() {
      this.loading = true;

      try {
        // Importa os planos
        const { registros } = await this.$store.dispatch(actions.CORE.POST, {
          path: "/planos-conta-contabil/import",
        });

        // Atualiza o registro
        this.__ficha.afterUpdate({
          planos_conta: registros,
        });
      } finally {
        this.loading = false;
      }
    },
    /**
     * Função que gerencia o clique duplo na célula da tabela
     * Se estiver liberado, não faz nada, caso contrário e se não for uma ação, abre a edição, caso contrário, executa a ação
     */
    onCellDoubleClick() {
      // Caso contrário, vai para rota de alterar
      return this.onNewOrEditClick("edit");
    },
    async onDelete() {
      if (this.loading) return;
      if (this.isFichaDisabled) return;

      const id = get(this.selected, "registro.id");

      if (!id) return;

      this.loading = true;

      try {
        // deleta o registro
        await this.$store.dispatch(actions.MODULO.REGISTROS.DELETE, {
          id,
          modulo: "planos-conta-contabil",
        });

        // atualiza o grid
        await this.onDependenceTabLoaded("tab-planos-conta-contabil");
      } finally {
        this.loading = false;
      }
    },
    /**
     * Função que faz a leitura das dependências
     */
    async onDependenceTabLoaded(dependence) {
      this.loading = true;

      try {
        await this.__ficha.readDependence({
          dependence,
        });
      } finally {
        this.loading = false;
      }
    },
    async onNewOrEditClick(action) {
      if (this.component) return;
      if (this.isFichaDisabled) return;

      const selected = this.selected ?? {};

      if (action === "edit" && !get(selected, "registro.id")) {
        return;
      }

      const mobile = this.$isMobile;

      this.componentProps = {
        modulo: "planos-conta-contabil",
        overlay: true,
        fullscreen: mobile,
        movable: !mobile,
        splashWidth: "300px",
        flexEnd: mobile,
        width: "50vw",
        height: "auto",
        title: "Novo Plano de conta",
      };

      if (action === "edit") {
        this.componentProps = {
          ...this.componentProps,
          action: "alterar",
          id: get(selected, "registro.id"),
          title: `Alterar ${
            get(selected, "registro.descricao") ?? "Plano de conta"
          }`,
        };
      }

      this.componentListeners = {
        close: () => {
          this.componentListeners = {};
          this.componentProps = {};
          this.component = null;

          // atualiza o grid
          this.onDependenceTabLoaded("tab-planos-conta-contabil");
        },
      };

      await this.$nextTick();

      try {
        this.component =
          require("@/views/modulo/ficha/TabContainer.vue").default;
      } catch (e) {
        console.error("erro ao abrir a ficha: ", e);
      }
    },
  },
  computed: {
    planosHeaders() {
      return [
        { text: "Código", value: "codigo" },
        { text: "Nome", value: "descricao" },
        { text: "Nível", value: "nivel" },
        { text: "Tipo", value: "tipo_descricao" },
        { text: "Grupo de Contas", value: "grupo_conta_descricao" },
        { text: "Grupo do Estoque", value: "grupo_descricao" },
      ];
    },
    codigosObrigacao() {
      return [
        { value: "000" },
        { value: "001" },
        { value: "002" },
        { value: "003" },
        { value: "004" },
        { value: "005" },
        { value: "006" },
        { value: "090" },
        { value: "999" },
      ];
    },
    origemProcesso() {
      return [
        { value: 0, text: "Sefaz" },
        { value: 1, text: "Justiça Federal" },
        { value: 2, text: "Justiça Estadual" },
        { value: 9, text: "Outros" },
      ];
    },
  },
});
</script>
