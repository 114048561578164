/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = [
  "endereco",
  "complemento",
  "bairro",
  "uf",
  "municipio_id",
];
