import moment from "@/plugins/moment";
// eslint-disable-next-line no-unused-vars
import Vue, { VueConstructor } from "vue";

import { mapGetters, mapState } from "vuex";

import capitalize from "lodash/capitalize";
import compact from "lodash/compact";
import get from "lodash/get";
import head from "lodash/head";
import includes from "lodash/includes";
import join from "lodash/join";
import last from "lodash/last";
import map from "lodash/map";
import split from "lodash/split";

import getters from "./store/getters";
import mutations from "./store/mutations";

import { store } from "@/main";
import actions from "./store/actions";
import Echo from "laravel-echo";

// Window global
window.debug = (...items) =>
  // Se estiver no ambiente de desenvolvimento, faz o stdout
  // eslint-disable-next-line
  process.env.VUE_APP_MODE !== "production" && console.log(...items);

// Window global
window.error = (...items) =>
  // Se estiver no ambiente de desenvolvimento, faz o stdout
  // eslint-disable-next-line
  process.env.VUE_APP_MODE !== "production" && console.error(...items);

window.openUrl = (url, target = "_blank") => {
  // Captura o agente
  const agent = window.navigator.userAgent.toLowerCase();

  // Se for safari
  if (includes(agent, "safari") && !includes(agent, "chrome"))
    // Commita o alerta do tipo popup
    return store.commit(mutations.APP.ALERT, {
      active: true,
      success: true,
      cancelable: true,
      title: "Seu documento está pronto",
      message: "Clique em visualizar para continuar",
      type: "popup",
      popupURL: url,
    });

  // Caso contrário, abre a url
  window.open(url, target);
};

window.$isAndroid = () => {
  if (!window || !window.navigator) return;
  return /android/gi.test(window.navigator.userAgent);
};
window.$isiOS = () => {
  if (!window || !window.navigator) return;
  return /iphone|ipod|ipad|crios/gi.test(window.navigator.userAgent);
};

window.openDownload = (blob, filename = "") => {
  // Cria a url
  const href = window.URL.createObjectURL(blob);

  // Se for iOS
  if (window.$isiOS()) {
    // // Commita o alerta
    return store.commit(mutations.APP.ALERT, {
      active: true,
      title: "Erro de incompatibilidade.",
      message:
        "Seu navegador não possui a funcionalidade de download de arquivos.<br><br>Por favor, tente utilizar outro navegador.",
    });
  }

  // Cria um link
  const link = document.createElement("a");
  // Seta o ref
  link.href = href;
  // Seta o nome do arquivo
  link.download = filename;
  // abre o link de download
  link.click();

  // Aguarda o timeout
  setTimeout(() => {
    // Revove o url
    window.URL.revokeObjectURL(href);
  }, 40);
};

window.fullscreen = {
  /* View in fullscreen */
  open() {
    const elem = document.documentElement as any;

    if (!elem) return;

    // Chrome
    if (elem.requestFullscreen) return elem.requestFullscreen();

    if (elem.webkitRequestFullscreen)
      // Safari
      return elem.webkitRequestFullscreen();

    if (elem.msRequestFullscreen)
      // IE11
      return elem.msRequestFullscreen();
  },

  /* Close fullscreen */
  close() {
    const elem = window.document.documentElement as any;

    // Chome
    if (elem.exitFullscreen) return elem.exitFullscreen();

    if (elem.webkitExitFullscreen)
      /* Safari */
      return elem.webkitExitFullscreen();

    if (elem.msExitFullscreen)
      /* IE11 */
      return elem.msExitFullscreen();
  },

  toggle() {
    const elem = window.document.documentElement;

    // return window.document.fullscreenEnabled
    return window.document.fullscreenElement
      ? window.document.exitFullscreen()
      : elem && elem.requestFullscreen && elem.requestFullscreen();
  },

  isFullscreen() {
    return !!window.document.fullscreenElement;
  },
};

export default (Vue as VueConstructor<Vue & MainMixin>).extend({
  filters: {
    /**
     * Função que valida se o el está incluido no array
     */
    includes(arr, el) {
      return includes(arr ?? [], el);
    },
  },
  methods: {
    // Referência da função debug do window
    $debug: window.debug,

    $optional(value, format = "[:value]") {
      return value ? format.replace(":value", value) : "";
    },
    $onThemeChange(value) {
      window.localStorage.setItem("darkmode", JSON.stringify(Boolean(value)));
    },

    $json(value, key, _default = null) {
      return get(value, key, _default);
    },

    $jsonToUri(obj: any): string {
      return "?" + new URLSearchParams(obj).toString();

      // const params = reduce(
      //   map(keys(obj), (k) => ({ [k]: obj[k] })),
      //   (memo, value) =>
      //     `${!isEmpty(memo) ? `${memo}&` : ""}${head(keys(value))}=${head(
      //       values(value)
      //     )}`,
      //   {}
      // );

      // return `?${params}`;
    },

    $pendingDownload(download) {
      return this.$store
        .dispatch(actions.UTILS.PENDING_DOWNLOAD, download)
        .then((response) => {
          this.$store.commit(mutations.APP.PENDING_DOWNLOAD);
          return response;
        });
    },

    $findComponentByName(name) {
      let component: Vue | undefined;
      let parent = this.$parent;
      while (parent && !component) {
        if (parent.$options.name === name) {
          component = parent;
        }
        parent = parent.$parent;
      }
      return component;
    },

    $getPWADisplayMode() {
      if (!window.matchMedia) return "browser";

      const isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;

      if (document.referrer.startsWith("android-app://")) {
        return "twa";
      }

      if (get(navigator, "standalone") || isStandalone) {
        return "standalone";
      }

      return "browser";
    },
    $disconnectTerminal(channel: string | null = null) {
      if (!window.TerminalEchoInstance || !window.defaultEchoSocket) return;
      channel && (window.defaultEchoSocket as Echo).leave(channel);
      window.TerminalEchoInstance = undefined;
    },
    /**
     * Esta função irá se conectar no Terminal
     *
     * Se houver uma conexão em algum terminal, sempre limpa a conexão antes de tentar a nova
     * @returns Websocket Instance
     */
    $getTerminal(): Promise<any> {
      const token = this.$getStorage("frente.acesso_terminal");
      if (!token) {
        // desconecta do terminal
        this.$disconnectTerminal();
        return Promise.reject("Chave de Acesso não encontrada");
      }

      const channel = `App.Terminal.${token}`;
      const newConnection = `presence-${channel}`;

      // se houver conexão prévia de outra chave
      if (
        window.TerminalEchoInstance &&
        ((window.TerminalEchoInstance as any).name ?? "") !== newConnection
      ) {
        // limpa a conexão anterior
        this.$disconnectTerminal(
          ((window.TerminalEchoInstance as any).name ?? "").substr(
            "presence-".length
          )
        );
      }

      if (!window.TerminalEchoInstance) {
        window.TerminalEchoInstance = (window.defaultEchoSocket as Echo).join(
          channel
        ) as any;

        return this.$internalHandleEchoPromise();
      }

      return Promise.resolve(window.TerminalEchoInstance);
    },

    $internalHandleEchoPromise() {
      return new Promise((resolve, reject) => {
        (window.TerminalEchoInstance as any)
          ?.subscribed(() => {
            return resolve(window.TerminalEchoInstance);
          })
          .error((error: any) => {
            window.TerminalEchoInstance = undefined;
            return reject(error);
          });
      });
    },

    /**
     * Função que gerencia o click do item do modulo
     */
    $logout(params = {}, clearSession = true) {
      params = {
        user_: {
          cpf_cnpj: this.$emitente.cpf_cnpj,
        },
        ...params,
      };

      // Retorna a função que faz logout
      return this.$store.dispatch(actions.APP.LOGOUT, clearSession).then(() => {
        // Se houver socket, desconecta
        if (window.defaultEchoSocket instanceof Echo)
          window.defaultEchoSocket.disconnect();

        // Manda pra tela de login
        this.$router.replace({ name: "login", params });
      });
    },

    $help(modulo = null, article = null) {
      // Monta a url
      const params = compact([modulo, article]).join("/");
      // Abre em nova guia o help do sistema
      return window.open(`${this.$appHelpUrl}/${params}`, "_blank");
    },
    /**
     * Função que retorna se o usuário pode editar o módulo
     */
    $canEdit(modulo = "") {
      // Captura o id da permissão do módulo
      modulo = this.$permissionId(modulo);

      // Retorna se for igual a 1
      return this.$permissions[modulo] === 1;
    },

    /**
     * Função que retorna se o usuário pode editar o módulo
     */
    $canOpenFicha(modulo = "") {
      // Captura o id da permissão do módulo
      modulo = this.$permissionId(modulo);

      // Retorna se for igual a 1
      return includes([1, 3], this.$permissions[modulo]);
    },

    /**
     * Função que retorna se o usuário pode apagar os registros do módulo
     */
    $canDelete(modulo = ""): boolean {
      // Captura o id da permissão do módulo
      modulo = this.$permissionId(modulo);

      // não permite excluir dav no paf-nfce
      if (modulo === "orcamento" && this.$emitente.uf === "SC") {
        return false;
      }

      // Retorna se for igual a 1
      return (
        this.$canEdit(modulo) &&
        !includes(
          ["saida", "cupons-fiscais", "servicos", "ordem-servico"],
          modulo
        )
      );
    },

    /**
     * Função que retorna se o usuário pode editar o módulo
     */
    $canSortHeader(modulo = ""): boolean {
      // Captura o id da permissão do módulo
      modulo = this.$permissionId(modulo);

      // Retorna se for igual a 1
      return !includes(["controle-bancario", "livro-caixa"], modulo);
    },

    /**
     * Função que retorna se o usuário pode ler o módulo
     */
    $canRead(modulo = ""): boolean {
      // Captura o id da permissão do módulo
      modulo = this.$permissionId(modulo);

      // Retorna se for igual a 1 ou 2
      return includes([1, 2, 3], this.$permissions[modulo]);
    },

    /**
     * @method $permissionId
     * Retorna o id da permissão do módulo
     */
    $permissionId(modulo: string): string {
      // Se não houver módulo, utiliza o atual
      modulo = modulo ?? this.$current;

      // Se houver mapeamento retorna, senão retorna o próprio módulo
      modulo = this.$mappingPermissions[modulo] ?? modulo;

      const modules = this.$store.state.app.modules;
      if (!modules.find((it) => it.id === modulo)) {
        return "";
      }

      // Se for config, retorna config, senão retorna o módulo
      return this.$isConfig(modulo) ? "config" : modulo;
    },

    $getStorage(key): any {
      if (!key) return {};

      const emitente_id = get(this.$emitente, "id");

      try {
        const _keys = split(key, ".");

        let firstKey = head(_keys) as string;

        if (emitente_id) firstKey = `${firstKey}.${emitente_id}`;

        if (_keys.length > 1)
          return get(
            JSON.parse(localStorage.getItem(firstKey) ?? "{}"),
            _keys.slice(1),
            null
          );

        if (emitente_id) key = `${key}.${emitente_id}`;

        return JSON.parse(localStorage.getItem(key) ?? "{}");
      } catch {
        return {};
      }
    },
    $setStorage(key, value): any {
      if (!key) return value;

      const emitente_id = get(this.$emitente, "id");

      let firstKey = head(split(key, ".")) as string;

      const strg = this.$getStorage(firstKey);

      if (emitente_id) firstKey = `${firstKey}.${emitente_id}`;

      if (split(key, ".").length === 2) {
        return localStorage.setItem(
          firstKey,
          JSON.stringify({
            ...strg,
            [last(split(key, ".")) as string]: value,
          })
        );
      }

      if (emitente_id) key = `${key}.${emitente_id}`;

      localStorage.setItem(
        key,
        JSON.stringify({
          ...strg,
          ...value,
        })
      );
    },
    $toggleBooleanStorage(key): any {
      const _keys = split(key, ".");

      const strg = this.$getStorage(head(_keys));

      return this.$setStorage(head(_keys), {
        [last(_keys) as string]: !strg[last(_keys) as string],
      });
    },

    /**
     * Função que interpreta a routa do VueRouter e transforma em um href nativo
     */
    $routeResolver(route, open = true, features = "") {
      // resolve a rota
      const routeData = this.$router.resolve(route);
      // if (open) window.open(routeData.href, route.name ?? "_blank", `width=${window.innerWidth}px,height=${window.innerHeight}px,titlebar=no,fullscreen=yes`, false);
      // Se for especificado para abrir a rota, abre em uma nova guia
      if (open) {
        features =
          features ||
          `left=${
            (window.innerWidth - 794) / 2
          },width=${794}px,height=${1123}px`;

        window.open(
          routeData.href,
          route.pageName ?? route.name ?? "_blank",
          features
        );
      }

      // Caso contrário, retorna o href
      return routeData.href;
    },
    /**
     * Função que processa o nome do módulo
     * ex: 'estoque' => Estoque
     * ex: 'ordem-servico' => OrdemServico
     */
    $moduloFilename(modulo) {
      const namespaces = {
        "contas-pagar": "cobrancas/",
        "contas-receber": "cobrancas/",
      };

      // Retorna o join
      return (
        get(namespaces, modulo, "") +
        join(
          // Mapeia o módulo splitado em '-', para cada um, capitaliza
          map(split(modulo, "-"), (n) => capitalize(n)),
          // Join com espaço vazio
          ""
        )
      );
    },
  },
  // Computed
  computed: {
    ...mapState({
      $config: (state: any) => state.app.config as any,
      $info: (state: any) => state.app.info as any,
      $emitente: (state: any) => state.app.emitente as any,
      $backend: (state: any) => state.app.backend as any,
      $user: (state: any) => state.app.user as any,

      $useSat: (state: any) =>
        state.app.useSat &&
        ["SP", "CE"].includes(get(state.app, "emitente.uf")),

      $current: (state: any) => state.modulo.current as string,

      $appDisplayMode: (state: any) => state.app.appDisplayMode,
    }),
    ...mapGetters({
      $permissions: getters.APP.USER.PERMISSIONS,
      $isConfig: getters.MODULO.IS_CONFIG,
    }),
    $menuFiscalMessage() {
      return process.env.VUE_APP_MODE === "production"
        ? ""
        : "MENU FISCAL INACESSÍVEL NESTA TELA";
    },
    $menuFiscalEnabled() {
      return this.$menuFiscalMessage && this.$emitente.uf === "SC";
    },
    $companyName() {
      return process.env.VUE_APP_COMPANY_NAME;
    },
    $companyFantasy() {
      return process.env.VUE_APP_COMPANY_FANTASY;
    },
    $staging() {
      return process.env.VUE_APP_MODE !== "production";
    },
    $chatUrl() {
      return `https://smallsoft.com.br/suporte/chat.php?serial=${this.$emitente.serial}&cnpj_cpf=${this.$emitente.cpf_cnpj}`;
    },
    $vlrDecimalCases() {
      return this.$config.qtd_decimal_valor;
    },
    $qtdDecimalCases() {
      return this.$config.qtd_decimal_quantidade;
    },
    $qtsDecimalCases() {
      return this.$config.qtd_decimal_quantidade_servicos;
    },
    $contrato() {
      return "https://s3.us-east-1.amazonaws.com/arquivos.smallsoft.com.br/downloads/SaaS/contrato_saas.pdf";
    },
    /**
     * Retorna o protocolo do terminal com base no ambiente
     * @returns {string}
     */
    $terminalProtocol(): string {
      if (this.$appMode === "production") return "terminal-saas://";
      if (this.$appMode === "beta") return "terminal-saas-beta://";
      return "terminal-saas-alpha://";
    },
    /**
     * Retorna o nome do App
     */
    $appName(): string {
      return process.env.VUE_APP_NAME ?? "";
    },
    $appLpUrl(): string {
      return process.env.VUE_APP_LP_URL ?? "";
    },
    $appHelpUrl(): string {
      return process.env.VUE_APP_HELP_URL ?? "";
    },
    $nomeCupomFiscal(): string {
      if (this.$emitente.mei) return "Cupom";

      if (this.$useSat) return this.$emitente.uf === "SP" ? "CFe-SAT" : "MFe";

      return "NFC-e";
    },
    $idCupomFiscal(): string {
      if (this.$useSat) return "cfe-sat";
      if (this.$emitente.mei) return "cupom";

      return "nfce";
    },
    // Mapeamento de permissões de módulos
    $mappingPermissions(): any {
      return {
        // Módulos que fazem parte do config
        nfe: "config",
        nfse: "config",
        cupons: "config",
        contabil: "config",
        sistema: "config",
        "planos-conta-contabil": "config",

        // Módulos independentes, mas inseridos dentro do config
        emitente: "config",
        "plano-contas": "config",
        "naturezas-operacao": "config",
        "contas-bancarias": "controle-bancario",
        convenios: "config",
        grupos: "config",
        contas: "config",
      };
    },
    /**
     * Função que retorna o título do assistente, baseado no período do dia
     */
    $greet() {
      // Captura o período do dia, AM/PM
      const shift = moment().format("A");
      // Captura a hora atual
      const hour = moment().format("H");

      // Retorna o ternário de, for entre 20hrs e 6hrs
      return parseInt(hour) >= 20 && parseInt(hour) <= 6
        ? // Boa noite
          "Boa noite"
        : // Senão se, for PM
        shift === "PM"
        ? // Boa tarde
          "Boa tarde"
        : // Senão, Bom dia
          "Bom dia";
    },
    /**
     * Ambiente
     */
    $appMode() {
      return process.env.VUE_APP_MODE;
    },
    /**
     * Build do frontend
     */
    $appVersion() {
      return process.env.VUE_APP_VERSION;
    },
    /**
     * Versão do backend
     */
    $apiVersion() {
      return this.$backend.version;
    },
    /**
     * Data da versão
     */
    $appLastVersionDate() {
      return process.env.VUE_APP_VERSION_DATE;
    },
    /**
     * Função que retorna se está no mobile ou não
     */
    $isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    /**
     * Função que retorna se está no android
     */
    $isAndroid() {
      return window.$isAndroid();
    },
    /**
     * Função que retorna se está no iOS
     */
    $isiOS() {
      return window.$isiOS();
    },
    /**
     * Retorna a imagem do assistente
     */
    // $assistentImage() {
    //   const max = 12;
    //   const min = 0;

    //   // Faz o require dinâmico
    //   return require(`@/assets/assistentes/${
    //     Math.floor(Math.random() * (max - min + 1)) + min
    //   }.png`);
    // },
  },
});
