<template>
  <p-select-search
    observe-items
    item-text="nome_razao_social"
    ref="select"
    :listItemHeight="50"
    :item-value="itemValue"
    :value-not-null="valueNotNull"
    :source="source"
    :target="target"
    :name="name"
    :label="label"
    :filter-by="target"
    :filter="filter"
    :initial="clifor || null"
    :input-initial="clifor && clifor.nome_razao_social"
    :value="value"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <template #list-item-content="{ item, buildTexts }">
      <v-list-item-content>
        <v-list-item-title
          class="caption"
          :class="{
            'v-select-list-item-selected': item[itemValue] === value,
          }"
          v-text="buildTexts(item)"
        />
        <slot name="subtitle" v-bind="{ item }">
          <v-list-item-subtitle
            v-html="
              `${item.cpf_cnpj || ''} ${item.municipio || ''} ${
                item.uf || ''
              }&nbsp;`
            "
          />
        </slot>
      </v-list-item-content>
    </template>
  </p-select-search>
</template>

<script>
import { PSelectSearch } from "@/components/form";

export default {
  components: {
    PSelectSearch,
  },
  props: {
    value: {},
    clifor: { type: Object },
    itemValue: { default: "id" },
    valueNotNull: { type: Boolean, default: true },

    filter: { default: () => [["ativo", true]] },
    label: { default: "Nome do cliente" },
    name: { default: "clifor_id" },
    source: { default: "cadastro" },
  },
  data: () => ({
    target: ["id", "nome_razao_social", "cpf_cnpj", "cpf_cnpj_raw"],
  }),
};
</script>
