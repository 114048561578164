<template>
  <p-text-field
    type="email"
    :multiple="multiple"
    :name="name"
    :label="label"
    :maxlength="maxlength"
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { mask } from "vue-the-new-mask";

import { PTextField } from "@/components/form";

export default {
  components: { PTextField },
  directives: { mask },
  props: {
    name: { default: "email" },
    label: { default: "E-mail" },
    maxlength: {},
    value: {},
    multiple: { default: false },
  },
};
</script>
