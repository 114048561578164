<template>
  <p-select-filter
    observe-items
    v-bind="$attrs"
    :value="value"
    :items="ufs"
    :name="name"
    :label="label"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  />
</template>

<script>
import { PSelectFilter } from "@/components/form";
import { compact } from "lodash";
import ufs from "@/mapping/ufs";

export default {
  components: {
    PSelectFilter,
  },
  props: {
    value: { required: false },
    hideEx: { default: false, type: Boolean },
    name: { default: "uf" },
    label: { default: "UF" },
  },
  computed: {
    ufs() {
      return compact([...ufs, !this.hideEx && { text: "EX", value: "EX" }]);
    },
  },
};
</script>
