<!-- eslint-disable prettier/prettier -->
<template>
  <li>
    <div :class="{ bold: hasChild, item: hasChild }" @click="toggle">
      <template v-if="!hasChild">
        <div class="tag-white">&lt;</div>
        <div class="tag-name">
          <span class="tag-white" v-if="value.annotation">?</span
          >{{ value.name }}
        </div>
        <div class="tag-white">
          <div class="tag-attribute" v-for="(attr, i) in value.attrs" :key="i">
            {{ attr.name }}<div class="tag-white">=</div>
            <div class="tag-attribute-value">"{{ attr.text }}"</div>
          </div>
          <span class="empty" v-if="inline"
            >&nbsp;{{ value.annotation ? "?" : "/" }}</span
          >&gt;
        </div>
        <div class="tag-white">{{ value.value }}</div>
        <template v-if="!inline">
          <div class="tag-white">&lt;/</div>
          <div class="tag-name">{{ value.name }}</div>
          <div class="tag-white">&gt;</div>
        </template>
      </template>
      <template v-else>
        <div class="tag-white">&lt;</div>
        <div class="tag-name">{{ value.name }}</div>
        <div v-if="value.attrs" style="display: inline-block">
          <div class="tag-attribute" v-for="(attr, i) in value.attrs" :key="i">
            {{ attr.name }}<div class="tag-white">=</div>
            <div class="tag-attribute-value">"{{ attr.text }}"</div>
          </div>
        </div>
        <div class="tag-white">&gt;</div>
      </template>
      <span v-if="hasChild" class="tag-white toggle-icon"
        >&nbsp;[{{ value.open ? "-" : "+" }}]</span
      >
      <div style="display: inline-block" v-if="hasChild && !value.open">
        <div class="tag-white">&lt;/</div>
        <div class="tag-name">{{ value.name }}</div>
        <div class="tag-white">&gt;</div>
      </div>
    </div>
    <ul v-show="value.open" v-if="hasChild">
      <tree-item
        v-for="(child, index) in value.children"
        :key="index"
        :value="child"
      />
    </ul>
    <div v-if="hasChild && value.open">
      <div class="tag-white">&lt;/</div>
      <div class="tag-name">{{ value.name }}</div>
      <div class="tag-white">&gt;</div>
    </div>
  </li>
</template>

<script>
export default {
  name: "TreeItem",
  props: {
    value: { type: Object },
  },
  /**
   * Vue Computed prop
   */
  computed: {
    /**
     * Retorna se o elemento possui tags filho
     */
    hasChild() {
      return this.value.children && this.value.children.length;
    },
    /**
     * Retorna se o elemento fecha a tag nele mesmo (sem valor)
     */
    inline() {
      return !this.value.value;
    },
  },
  methods: {
    /**
     * Função que alterna a tag entre aberto e fechado
     */
    toggle() {
      // Se tiver filhos
      if (this.hasChild) {
        // Inverte os valores
        this.value.open = !this.value.open;
      }
    },
  },
};
</script>

<style scoped>
.item {
  cursor: pointer;
}
ul {
  padding-left: 1em;
  line-height: 1.5em;
  list-style-type: none;
}

.tag-white {
  display: inline-block;
  color: #fff;
}
.tag-attribute {
  display: inline-block;
  color: #4dc8f4;
  /* color: #3fcf67; */
}
.toggle-icon {
  user-select: none;
}
.tag-attribute::before,
a .toggle-icon::before,
.toggle-icon::after {
  content: "\00a0";
}
.tag-attribute-value {
  display: inline-block;
  color: #e6f081;
}
.tag-name {
  display: inline-block;
  color: #c6619f;
}
.empty:before {
  content: "\00a0";
}
</style>
