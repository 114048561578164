<template>
  <v-tooltip :top="!bottom && top" :bottom="bottom" v-if="text">
    <template #activator="{ attrs, on }">
      <v-icon
        small
        style="outline: none"
        tabindex="-1"
        color="primary"
        :class="iconClass"
        v-on="on"
        v-bind="attrs"
        >mdi-information-outline</v-icon
      >
    </template>
    {{ text }}
  </v-tooltip>
</template>

<script>
export default {
  props: {
    iconClass: {},
    text: { type: String, default: null },
    bottom: { type: Boolean, default: true },
    top: { type: Boolean, default: false },
  },
};
</script>
