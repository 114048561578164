import compact from "lodash/compact";
import get from "lodash/get";

/**
 * Colunas do Grid
 */
export const columns = [
  addToRightSideColumn("contas-receber"),
  { text: "Documento", value: "documento", maxWidth: "150px" },
  { text: "Parcela", value: "numero" },
  { text: "Histórico", value: "descricao" },
  { text: "Plano de contas", value: "plano_conta_descricao" },
  { text: "Cliente", value: "clifor_razao_social" },
  { text: "Emissão", value: "emissao_dt", type: "date" },
  { text: "Vencimento", value: "vencimento_dt", type: "date" },
  { text: "Data de quitação", value: "pagamento_dt", type: "date" },
  { text: "Valor", value: "vlr_total", align: "right" },
  { text: "Valor Atual", value: "vlr_atual", align: "right" },
  { text: "Valor Recebido", value: "vlr_pago", align: "right" },
  { text: "Valor Juros", value: "vlr_juros", align: "right" },
  { text: "Portador", value: "portador" },
  { text: "Ativo", value: "ativo", type: "boolean" },

  // { text: "Documento", value: "documento" },
  // { text: "Descrição", value: "descricao" },
  // { text: "Emissão", value: "emissao_dt", type: "date" },
  // { text: "Cliente", value: "clifor_razao_social" },
  // { text: "Plano de Contas", value: "plano_conta_descricao" },
  // { text: "Valor total", value: "vlr_total", align: "right" },
  // { text: "Valor recebido", value: "vlr_pago", align: "right" },
  // { text: "Ativo", value: "ativo", type: "boolean" },

  // { text: "À Receber", value: "vlr_a_pagar", extra: true, hidden: true },
  // { text: "Recebidas", value: "vlr_pagas", extra: true, hidden: true },
  // { text: "Vencidas", value: "vlr_vencidas", extra: true, hidden: true },
  // { text: "À Vencer", value: "vlr_a_vencer", extra: true, hidden: true },
  // {
  //   text: "Vencendo Hoje",
  //   value: "vlr_vence_hoje",
  //   extra: true,
  //   hidden: true,
  // },
];

export {
  forceUpdateKeys,
  dependencies,
  ficha,
  context,
} from "./cobrancas/contas";
import {
  addToRightSideColumn,
  deletes as _deletes,
  cache as _cache,
  subactions as _subactions,
  exibir,
} from "./cobrancas/contas";
import { clearCurrency } from "@/utils";

export const deletes = _deletes(columns);
export const cache = {
  ..._cache,
  colunas: [
    ..._cache.colunas.slice(0, _cache.colunas.length - 3),
    "vlr_total",
    "vlr_atual",
    "vlr_pago",
  ],
};

/**
 * Ações do submenu
 */
export const subactions = function (this: any) {
  // Captura o registro selecionado
  const disabled = !this.registro || !this.registro.id;

  const institutions = get(this.__modulo, "extra.contas_bancarias", []);

  return [
    {
      title: "Arquivo",
      items: compact([
        {
          id: "quitar-contas",
          title: "Receber Contas",
        },
        {
          id: "quitar-contas",
          init: {
            initialStep: 2,
            initialCliforOrConvenio: {
              clifor_id: this.registro.clifor_id,
              nome_razao_social: this.registro.clifor_razao_social,
            },
          },
          hidden: disabled || !this.registro.clifor_id,
          title: `Receber Contas de ${this.registro.clifor_razao_social ?? ""}`,
        },
        {
          id: "quitar-contas",
          init: {
            initialStep: 2,
            loadFromGrid: true,
          },
          hidden: disabled,
          title: "Receber Contas Filtradas",
          divider: true,
        },
        {
          id: "gerar-parcelas",
          title: "Gerar Parcelas",
          divider: true,
        },
        {
          id: "quitar-contas",
          title: "CNAB - Importar Arquivo",
          init: {
            initialType: "CNAB",
          },
        },
        {
          id: "generate-cnab",
          title: "CNAB - Gerar Arquivo",
          divider: true,
          items: institutions,
        },
        {
          type: "report",
          id: "recibo",
          title: "Recibo",
          divider: true,
          registro_id: this.registro.id,
          disabled: disabled || clearCurrency(this.registro.vlr_pago) <= 0,
        },
        {
          subheader: `Ações para a Cobrança "${this.registro.documento || ""}"`,
        },
        {
          id: "boletos",
          title: "Bloqueto de Cobrança",
        },
        {
          id: "carnes",
          title: "Carnê",
          type: "report",
          registro_id: this.registro.conta_id,
          disabled: disabled || !this.registro.clifor_id,
        },
        {
          title: "Duplicatas",
          type: "report",
          id: "duplicatas",
          registro_id: this.registro.conta_id,
          disabled: disabled || !this.registro.clifor_id,
        },
      ]),
    },
    {
      type: "report",
      title: "Relatórios",
      items: reports.bind(this)(),
    },
    {
      title: "Exibir",
      type: "filter",
      items: exibir.bind(this)("contas-receber"),
    },
    ..._subactions.bind(this)(),
  ];
};

export const fichaActions = function (this: any) {
  const id = get(this.__ficha, "data.id");
  const isPago = get(this.__ficha, "data.is_pago");

  const disabled =
    !id || isPago || !clearCurrency(get(this.__ficha, "data.vlr_total"));

  return compact([
    {
      title: "Receber",
      id: "quitar",
      icon: require("@/assets/menu/modulos/receber.svg"),
      disabled,
    },
    {
      title: "Replicar",
      id: "replicate-registro",
      icon: require("@/assets/menu/actions/duplicar.svg"),
      disabled: !id,
    },
    {
      title: "Recibo",
      id: "recibo",
      icon: require("@/assets/menu/actions/recibo.svg"),
      disabled: !id || !isPago,
    },
    {
      title: "Boleto",
      id: "boleto",
      icon: require("@/assets/menu/actions/barcode.svg"),
      disabled: !id,
    },
  ]);
};

/**
 * Relatórios
 */
export const reports = function (this: any) {
  // Captura o registro selecionado

  return [
    {
      id: "inadimplencia",
      title: "Relatório de inadimplência",
    },
    {
      id: "resumo_inadimplencia",
      title: "Resumo de inadimplência",
    },
    {
      id: "ranking_devedores",
      title: "Ranking de devedores",
    },
  ];
};
