<template>
  <v-row no-gutters>
    <v-col cols="12" md="5" xl="6">
      <v-row no-gutters>
        <p-select-filter
          ignore-enter-focus
          value-not-null
          cols="6"
          sm="3"
          md="6"
          lg="6"
          name="nfe_finalidade"
          label="Finalidade"
          :item-texts="['value', 'text']"
          :items="finalidades"
          @change="onFinalidadeChanged"
          v-model="data.nfe_finalidade"
        >
          <template #append>
            <btn
              icon
              tabindex="-1"
              tooltip="Referenciar NF-e"
              btn-class="select-filter-btn"
              @click="finalidadeReference"
              v-if="referenceButton"
            >
              <v-icon small :color="$attrs.white ? 'white' : 'primary'"
                >mdi-relation-many-to-one</v-icon
              >
            </btn>
          </template>
        </p-select-filter>
        <p-select-filter
          ignore-enter-focus
          value-not-null
          cols="6"
          sm="3"
          md="6"
          lg="6"
          name="indicador_consumidor"
          label="Consumidor"
          :item-texts="['value', 'text']"
          :items="consumidores"
          v-model="data.indicador_consumidor"
        />
        <p-select-filter
          ignore-enter-focus
          value-not-null
          sm="6"
          md="12"
          name="indicador_presenca"
          label="Indicador de presença"
          :item-texts="['value', 'text']"
          :items="presencas"
          v-model="data.indicador_presenca"
        />
        <p-select-filter
          value-not-null
          observe-items
          mandatory
          item-value="id"
          :item-texts="['cfop', 'descricao']"
          ref="natureza_operacao"
          name="natureza_operacao_id"
          label="Natureza da operação"
          :items="naturezas"
          :auto-focus="modulo === 'saida'"
          v-model="data.natureza_operacao_id"
        />
      </v-row>
    </v-col>
    <v-col cols="12" md="3" xl="2">
      <v-row no-gutters>
        <clifor-select
          sm="5"
          md="12"
          label="Vendedor"
          name="colaborador_id"
          :valueNotNull="false"
          :filter="vendedor_filter"
          :clifor="colaborador"
          v-if="modulo == 'saida'"
          v-model="data.colaborador_id"
        />
        <p-date-time
          ignore-enter-focus
          sm="3"
          md="12"
          name="emissao_dt"
          type="timestamp"
          label="Emissão"
          v-model="data.emissao_dt"
        />
        <p-date-time
          ignore-enter-focus
          sm="4"
          md="12"
          name="saida_at"
          type="timestamp"
          :label="`Data e hora da ${modulo === 'saida' ? 'saída' : 'entrada'}`"
          v-model="data.saida_at"
        />
      </v-row>
    </v-col>
    <v-col cols="12" md="4" class="px-0 pt-0">
      <container-content hidden min-height="144" cols="12">
        <v-row>
          <clifor-combobox-v2
            :label="`CPF/CNPJ / Razão Social do ${
              modulo === 'saida' ? 'Cliente' : 'Fornecedor'
            }`"
            @click:append-outer="onEditCliforClicked"
            :beforeChanged="onBeforeChangedClifor"
            v-model="data.clifor"
          />
        </v-row>
        <v-row>
          <list-info :items="cliente" class="px-0" />
        </v-row>
      </container-content>
    </v-col>
  </v-row>
</template>

<script>
import get from "lodash/get";
import { mapState } from "vuex";

import ListInfo from "@/components/utils/info/ListInfo.vue";
import ContainerContent from "@/components/form/utils/ContainerContent.vue";

import { EventBus } from "@/main";

import {
  Btn,
  CliforSelect,
  TextField,
  PSelectSearch,
  PSelectFilter,
  PDateTime,
  CliforComboboxV2,
} from "@/components/form";

import mutations from "@/store/mutations";

import Clifor from "@/mixins/clifor";
import { clearCurrency, handleCfop } from "@/utils";
import actions from "@/store/actions";

export default Clifor.extend({
  props: {
    modulo: { default: "saida" },
  },
  components: {
    ListInfo,
    ContainerContent,
    TextField,
    CliforComboboxV2,
    CliforSelect,
    PSelectFilter,
    PSelectSearch,
    PDateTime,
    Btn,
  },
  methods: {
    /**
     * Função handle de update clifor
     */
    async onBeforeChangedClifor(value, item, component) {
      // habilita o desconto do convenio apenas na nfe de venda
      if (this.__ficha.modulo !== "saida") return Promise.resolve(value);

      // captura o convenio_id
      const convenio_id = get(item, "convenio_id");

      // se nao houver, retorna
      if (!convenio_id) return Promise.resolve(value);

      component.loading_ = true;

      const { registro } = await this.$store.dispatch(actions.CORE.READ, {
        path: `convenios/${convenio_id}`,
      });

      component.loading_ = false;

      await this.$nextTick();

      // captura o percentual de desconto do convenio_id
      const per_desconto = clearCurrency(registro.per_desconto);

      // se nao houver, retorna
      if (!per_desconto) return Promise.resolve(value);

      const ref = get(component, "$refs.select.$refs.textField");

      // retorna a promessa
      return new Promise((resolve) => {
        // Abre o alerta de confirmação
        this.__ficha.$alert(
          {
            value: true,
            cancelable: true,
            info: true,
            okText: "SIM",
            cancelText: "NÃO",
            title: "Confirmar desconto de Convênio",
            message: `Você confirma um desconto de ${registro.per_desconto}%<br>Conforme Convênio ${registro.descricao}?`,
          },
          {
            ok: () => {
              this.__ficha.append({
                per_desconto,
              });
              return this.__ficha.$nextTick(() => {
                if (ref) {
                  ref.next();
                }
                return resolve(value);
              });
            },
            cancel: () => {
              this.__ficha.append({
                per_desconto: null,
              });
              return this.__ficha.$nextTick(() => {
                if (ref) {
                  ref.next();
                }
                return resolve(value);
              });
            },
          }
        );
      });
    },
    importModalClosed() {
      this.$refs.natureza_operacao && this.$refs.natureza_operacao.focus();
    },
    onFinalidadeChanged(finalidade) {
      if (finalidade === "4") this.finalidadeReference();
    },
    finalidadeReference() {
      this.$store.commit(mutations.APP.ASSISTENT, {
        modulo: "saida",
        action: "devolucao_reference",
        registro: this.data,
        __ficha: this.__ficha,
      });
    },
  },
  computed: {
    ...mapState({
      assistent: (state) => state.app.assistent.active,
    }),
    referenceButton() {
      if (this.modulo === "saida") {
        return (
          this.data.nfe_finalidade === "4" || this.data.nfe_finalidade === "2"
        );
      }

      return this.data.nfe_finalidade === "3";
    },
    naturezas() {
      return (this.extra.naturezas_operacao || []).map((natureza) => ({
        ...natureza,
        cfop: handleCfop(
          natureza.cfop,
          this.$emitente.uf,
          this.data?.clifor?.uf,
          this.modulo === "saida" ? "1" : "0"
        ),
      }));
    },
    consumidores() {
      return [
        { text: "Normal", value: "0" },
        { text: "Final", value: "1" },
      ];
    },
    presencas() {
      return [
        {
          text: "Não se aplica (ex.: NF complementar ou ajuste)",
          value: "0",
        },
        { text: "Operação presencial", value: "1" },
        { text: "Operação não presencial, pela internet", value: "2" },
        { text: "Operação não presencial, teleatendimento", value: "3" },
        {
          text: `${this.$nomeCupomFiscal} em operação com entrega em domicílio`,
          value: "4",
        },
        { text: "Operação presencial fora do estabelecimento", value: "5" },
        { text: "Operação não presencial, outros", value: "9" },
      ];
    },
    finalidades() {
      return [
        { text: "Normal", value: "1" },
        { text: "Complementar", value: "2" },
        { text: "de Ajuste", value: "3" },
        { text: "Devolução de mercadoria", value: "4" },
      ];
    },
  },
  created() {
    EventBus.$on("close-import-modal", this.importModalClosed);
  },
  beforeDestroy() {
    EventBus.$off("close-import-modal", this.importModalClosed);
  },
});
</script>
