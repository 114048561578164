import API from "@/api";

import actions from "../actions";

const prefix = "/dashboard";

const api = API.config();

export default {
  actions: {
    [actions.APP.DASHBOARD]() {
      return api.get(prefix).then((response) => response.data);
    },
  },
};
