var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-badge',{attrs:{"overlap":"","bordered":"","color":"success","offset-x":25,"value":false},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('div',{staticClass:"mx-auto"},[_vm._v("3")])]},proxy:true}])},[_c('a',{staticClass:"s-menu-item-container",class:{
        ..._vm.item.class,
        'bottom-navigation': _vm.bottomNavigation,
        'disabled-button': _vm.disabled,
        active: _vm.$route.params.modulo === _vm.item.id || _vm.$route.name === _vm.item.id,
      },attrs:{"href":_vm.actions
          ? null
          : !_vm.item.route
          ? `/${_vm.item.id}`
          : _vm.$routeResolver(_vm.item.route, false)},on:{"contextmenu":(event) =>
          _vm.item.context
            ? (event.preventDefault(), _vm.$refs.menu.open(event))
            : null,"click":function($event){$event.preventDefault();return ((event) =>
          event.ctrlKey && _vm.item.route
            ? _vm.$routeResolver(_vm.item.route)
            : !_vm.disabled && _vm.$emit('click', event)).apply(null, arguments)}}},[(_vm.item.icon)?[_c('div',{staticClass:"s-image-container"},[_c('v-img',{staticClass:"item-icon mx-auto",attrs:{"contain":"","src":_vm.item.icon}})],1)]:[_c('div',{staticClass:"icon-wrapper"},[_c('div',{staticClass:"icon"})])],_c('span',{staticClass:"menu-label-font"},[_vm._v(_vm._s(_vm.item.title))])],2)]),_c('context-menu',{ref:"menu",attrs:{"context":_vm.item.context},on:{"click-item":_vm.onContextClick}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }