<template>
  <tabs-items
    v-model="value"
    @loaded-tab-cod-barras="onDependenceTabLoaded"
    @loaded-tab-grade="onDependenceTabLoaded"
    @loaded-tab-composicao="onDependenceTabLoaded"
  >
    <template #tab-principal>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <p-switch
                ignore-enter-focus
                cols="5"
                sm="3"
                class="my-auto py-0"
                name="ativo"
                label="Ativo"
                v-model="data.ativo"
              />
              <p-text-field
                disabled
                cols="7"
                sm="4"
                name="id"
                label="Código"
                :value="(data.id || '').toString().padStart(5, '0')"
              />
              <p-text-field
                sm="5"
                type="tel"
                label="Código de Barras"
                name="gtin"
                maxlength="14"
                v-model="data.gtin"
              />
              <p-text-field
                auto-focus
                maxlength="120"
                v-model="data.descricao"
                name="descricao"
                label="Descrição"
              />

              <p-select-filter
                ignore-enter-focus
                sm="6"
                label="Grupo"
                name="grupo_id"
                item-text="descricao"
                item-value="id"
                :items="extra.grupo"
                v-model="data.grupo_id"
              />
              <p-select-filter
                observe-items
                sm="6"
                label="Medida"
                name="unidade_medida"
                item-value="sigla"
                :item-texts="['sigla', 'descricao']"
                :items="extra.unidade_medida"
                v-model="data.unidade_medida"
              />
              <p-precision-field
                not-zero
                sm="6"
                :disabled="data.promocao_ativa"
                :label="
                  data.promocao_ativa ? 'Preço promocional' : 'Preço em R$'
                "
                prefix="R$"
                name="vlr_preco"
                v-model="data.vlr_preco"
              />
              <p-precision-field
                ignore-enter-focus
                sm="6"
                label="Preço em US$"
                prefix="US$"
                name="vlr_indexador"
                v-model="data.vlr_indexador"
              />
              <p-precision-field
                sm="6"
                md="4"
                :suffix="data.unidade_medida || ''"
                label="Quantidade"
                name="qtd_atual"
                v-model="data.qtd_atual"
              />
              <!-- :disabled="!$config.inventario && !!data.id"
                :readonly="!$config.inventario && !!data.id" -->
              <p-precision-field
                ignore-enter-focus
                sm="6"
                md="4"
                label="Qtd. Mínima"
                name="qtd_minimo"
                v-model="data.qtd_minimo"
              />
              <p-precision-field
                ignore-enter-focus
                cols="6"
                md="4"
                suffix="%"
                label="Comissão"
                name="per_comissao"
                v-model="data.per_comissao"
              />
              <p-precision-field
                cols="6"
                md="4"
                suffix="%"
                label="Lucro Bruto"
                name="per_lucro_bruto"
                v-model="data.per_lucro_bruto"
              />
              <p-precision-field
                ignore-enter-focus
                sm="6"
                md="4"
                prefix="R$"
                label="Custo Médio"
                name="vlr_custo_medio"
                v-model="data.vlr_custo_medio"
              />
              <p-precision-field
                ignore-enter-focus
                sm="6"
                md="4"
                prefix="R$"
                label="Custo de Compra"
                name="vlr_custo_compra"
                v-model="data.vlr_custo_compra"
              />
              <p-text-field
                ignore-enter-focus
                md="12"
                label="Localização"
                name="localizacao"
                maxlength="10"
                v-model="data.localizacao"
              />
              <p-precision-field
                ignore-enter-focus
                sm="4"
                suffix="KG"
                label="Peso"
                name="peso"
                fixed="4"
                v-model="data.peso"
              />
              <p-date-time
                hideButtonNow
                hideButtonCalendar
                cols="6"
                sm="4"
                label="Últ. Compra"
                name="ultima_compra_dt"
                v-model="data.ultima_compra_dt"
              />
              <p-date-time
                disabled
                cols="6"
                sm="4"
                label="Últ. Venda"
                name="ultima_venda_dt"
                v-model="data.ultima_venda_dt"
              />
            </v-row>
          </v-col>

          <v-col cols="12" md="6" class="mt-md-0">
            <v-row>
              <field-label-editable
                name="identificador1"
                parametro="lbl_tb_produtos_identificador_1"
                v-model="data.identificador1"
              />
              <field-label-editable
                name="identificador2"
                parametro="lbl_tb_produtos_identificador_2"
                v-model="data.identificador2"
              />
              <field-label-editable
                name="identificador3"
                parametro="lbl_tb_produtos_identificador_3"
                v-model="data.identificador3"
              />
              <field-label-editable
                name="identificador4"
                parametro="lbl_tb_produtos_identificador_4"
                v-model="data.identificador4"
              />
              <p-text-area
                ignore-enter-focus
                rows="5"
                cols="12"
                name="obs"
                label="Aplicação"
                maxlength="255"
                height="144px"
                v-model="data.obs"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-icms>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <p-select-filter
                show-value-text
                key="tipo_item"
                cols="12"
                label="Tipo do item"
                name="tipo_item"
                v-model="data.tipo_item"
                :items="extra__.tipo_item"
              />
              <p-text-field
                sm="3"
                md="4"
                xl="3"
                type="tel"
                label="NCM"
                name="ncm"
                maxlength="13"
                v-model="data.ncm"
              />
              <v-col
                align="center"
                justify="center"
                cols="12"
                sm="9"
                md="8"
                xl="9"
                class="pa-0 d-flex"
              >
                <span class="ml-2 my-auto">{{ ibpt.descricao }}</span>
              </v-col>
              <p-text-field
                sm="3"
                md="4"
                xl="3"
                name="st"
                label="CIT"
                maxlength="3"
                v-model="data.st"
              />
              <v-col
                align="center"
                justify="center"
                cols="12"
                sm="9"
                md="8"
                xl="9"
                class="pa-0 d-flex"
              >
                <span class="ml-2 my-auto" v-show="natureza && natureza.cfop">
                  {{ natureza.cfop }} - {{ natureza.descricao }}
                </span>
              </v-col>
              <p-text-field
                cols="6"
                type="tel"
                maxlength="7"
                label="CEST"
                name="cest"
                v-model="data.cest"
              />
              <p-precision-field
                cols="6"
                fixed="5"
                label="IVA"
                name="per_iva"
                v-model="data.per_iva"
              />
              <v-col class="px-0 pt-0 pb-0">
                <container-content
                  class="pb-0 px-0"
                  :title="`Imposto Aprox.${
                    ibpt && ibpt.fonte ? ` (Fonte: ${ibpt.fonte})` : ''
                  }`"
                >
                  <v-row>
                    <p-precision-field
                      cols="4"
                      readonly
                      suffix="%"
                      label="Federal"
                      name="per_iia_federal"
                      v-model="data.per_iia_federal"
                    />
                    <p-precision-field
                      cols="4"
                      readonly
                      suffix="%"
                      label="Estadual"
                      name="per_iia_estadual"
                      v-model="data.per_iia_estadual"
                    />
                    <p-precision-field
                      cols="4"
                      readonly
                      suffix="%"
                      label="Municipal"
                      name="per_iia_municipal"
                      v-model="data.per_iia_municipal"
                    />
                  </v-row>
                </container-content>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6" class="mt-1 mt-md-0">
            <v-row>
              <p-select-filter
                show-value-text
                label="Origem"
                name="origem"
                :items="extra__.origem"
                v-model="data.origem"
              />
              <p-select-filter
                show-value-text
                label="CSOSN"
                name="csosn"
                :items="extra__.csosn"
                v-model="data.csosn"
                v-if="$emitente && $emitente.crt == '1'"
              />
              <p-select-filter
                show-value-text
                label="CST"
                name="cst"
                :items="extra__.cst"
                v-model="data.cst"
                v-else
              />
              <p-select-filter
                show-value-text
                sm="6"
                default="T"
                label="IPPT"
                name="ippt"
                :items="extra__.ippt"
                v-model="data.ippt"
              />
              <p-select-filter
                show-value-text
                sm="6"
                default="T"
                label="IAT"
                name="iat"
                :items="extra__.iat"
                v-model="data.iat"
              />
              <v-col class="px-0 py-0">
                <container-content :title="$nomeCupomFiscal">
                  <v-row>
                    <p-select-filter
                      show-value-text
                      name="cfop_nfce"
                      :label="`CFOP ${$nomeCupomFiscal}`"
                      :items="extra__.cfop_nfce"
                      v-model="data.cfop_nfce"
                    />
                    <p-select-filter
                      show-value-text
                      name="csosn_nfce"
                      :label="`CSOSN ${$nomeCupomFiscal}`"
                      :items="extra__.csosn_nfce"
                      v-model="data.csosn_nfce"
                      v-if="$emitente && $emitente.crt == '1'"
                    />
                    <p-select-filter
                      show-value-text
                      name="cst_nfce"
                      :label="`CST ${$nomeCupomFiscal}`"
                      :items="extra__.cst_nfce"
                      v-model="data.cst_nfce"
                      v-else
                    />
                    <p-precision-field
                      suffix="%"
                      name="per_icms_nfce"
                      :label="`Alíquota ICMS/ISS ${$nomeCupomFiscal}`"
                      v-model="data.per_icms_nfce"
                    />
                  </v-row>
                </container-content>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-piscofins-ipi>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <container-content title="PIS/COFINS Saída" cols="12" md="6">
            <v-row>
              <p-select-filter
                show-value-text
                label="CST PIS/COFINS"
                name="cst_pis_cofins_saida"
                :items="extra__.cst_pis_cofins_saida"
                v-model="data.cst_pis_cofins_saida"
              />
              <p-precision-field
                cols="6"
                md="6"
                suffix="%"
                label="PIS"
                name="per_pis_saida"
                v-model="data.per_pis_saida"
              />
              <p-precision-field
                cols="6"
                md="6"
                suffix="%"
                label="COFINS"
                name="per_cofins_saida"
                v-model="data.per_cofins_saida"
              />
            </v-row>
          </container-content>
          <container-content title="PIS/COFINS Entrada" cols="12" md="6">
            <v-row>
              <p-select-filter
                show-value-text
                label="CST PIS/COFINS"
                name="cst_pis_cofins_entrada"
                :items="extra__.cst_pis_cofins_entrada"
                v-model="data.cst_pis_cofins_entrada"
              />
              <p-precision-field
                cols="6"
                md="6"
                label="PIS"
                suffix="%"
                name="per_pis_entrada"
                v-model="data.per_pis_entrada"
              />
              <p-precision-field
                cols="6"
                md="6"
                label="COFINS"
                suffix="%"
                name="per_cofins_entrada"
                v-model="data.per_cofins_entrada"
              />
            </v-row>
          </container-content>

          <container-content title="IPI" cols="12" md="6">
            <v-row>
              <p-select-filter
                show-value-text
                label="CST IPI"
                name="cst_ipi"
                :items="extra__.cst_ipi"
                v-model="data.cst_ipi"
              />
              <p-text-field
                cols="12"
                sm="6"
                maxlength="3"
                label="Cód. Enquad. legal do IPI"
                name="enquadramento_ipi"
                v-model="data.enquadramento_ipi"
              />
              <p-precision-field
                cols="6"
                md="6"
                suffix="%"
                label="IPI"
                name="per_ipi"
                v-model="data.per_ipi"
              />
              <!-- v-mask="'###'" -->
            </v-row>
          </container-content>
        </v-row>
      </v-col>
    </template>

    <template #tab-grade>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <div :style="`height: ${height() - 38}px; width: 100%;`">
          <v-col class="pa-1" cols="12">
            <div :style="`height: ${height() - 68}px; width: 100%;`">
              <data-table
                hide-footer
                hide-header
                editable
                :headers="gradeTabHeaders"
                :items="gradeTabValues"
                v-model="selectedGrade"
              />
            </div>
          </v-col>
          <v-row class="mx-0">
            <v-col class="pa-2">
              <btn color="actionbutton" outlined>Excluir grade</btn>
              <btn color="actionbutton" btn-class="ml-2" outlined>Cancelar</btn>
              <btn color="actionbutton" btn-class="ml-2" outlined
                >Salvar grade</btn
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
    </template>

    <template #tab-composicao>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <v-col class="px-0 py-1">
            <dependence-table
              enable-focus
              hide-container-content
              modulo="produto-compostos"
              dependence="composicao"
              produto-key="base_produto_id"
              :__ficha="__ficha"
              :allow-raw-text="false"
              :default-headers="composicaoTabHeaders"
              :selected="selected"
              @selected="setSelected"
              v-model="data"
            />
          </v-col>
        </v-row>
        <v-col md="8" offset-md="4" class="py-0 mt-5">
          <v-row class="ma-0">
            <v-col class="pa-1 d-flex justify-end" cols="5" md="3">
              <btn
                block
                outlined
                size="medium"
                btn-class="my-auto mr-1"
                :loading="loading"
                :disabled="isFichaDisabled || loading || !data.tem_composicao"
                @click="assemble({ quantity: assembleQuantity, id: data.id })"
                >Fabricar</btn
              >
            </v-col>
            <precision-field
              cols="6"
              sm="4"
              md="3"
              :disabled="loading || !data.tem_composicao"
              v-model="assembleQuantity"
            />
          </v-row>
          <v-row class="mx-0 mb-0 mt-1">
            <v-col class="pa-1 d-flex justify-end" cols="5" md="3">
              <btn
                block
                outlined
                size="medium"
                btn-class="my-auto mr-1"
                :loading="loading"
                :disabled="isFichaDisabled || loading || !data.tem_composicao"
                @click="
                  disassemble({
                    quantity: disassembleQuantity,
                    id: data.id,
                  })
                "
                >Desmontar</btn
              >
            </v-col>
            <precision-field
              cols="6"
              sm="4"
              md="3"
              :disabled="loading || !data.tem_composicao"
              :value="disassembleQuantity"
              @input="disassembleQuantity = $event"
            />
          </v-row>
          <!-- <v-row class="pt-2">
            <v-col class="pa-1 d-flex justify-end" cols="5" md="3">
              <btn
                outlined
                btn-class="align-self-end"
                :loading="loading"
                :disabled="loading"
                >Custo</btn
              >
            </v-col>
            <precision-field
              disabled
              prefix="R$"
              cols="6"
              sm="4"
              md="3"
              value="0,00"
            />
          </v-row> -->
        </v-col>
      </v-col>
    </template>

    <template #tab-foto>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <image-form
            :disabled="isFichaDisabled"
            modulo="estoque"
            :__ficha="__ficha"
          />
        </v-row>
      </v-col>
    </template>

    <template #tab-promocao>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <container-content cols="12" md="6" title="Promoção por período">
            <v-row>
              <p-date-time
                nullable
                auto-focus
                start-of-day
                label="Data inicial"
                name="promocao_inicio_dt"
                v-model="data.promocao_inicio_dt"
              />
              <p-date-time
                nullable
                end-of-day
                label="Data final"
                name="promocao_final_dt"
                v-model="data.promocao_final_dt"
              />
              <p-precision-field
                label="Preço promocional"
                name="vlr_promocao_por"
                v-model="data.vlr_promocao_por"
              />
              <precision-field
                disabled
                label="Preço normal"
                v-model="data.vlr_preco_original"
              />
            </v-row>
          </container-content>
          <container-content
            cols="12"
            md="6"
            class="mt-3 mt-md-0"
            title="Promoção por quantidade"
          >
            <v-row>
              <p-precision-field
                md="6"
                name="qtd_promocao_1"
                label="Comprando mais de"
                v-model="data.qtd_promocao_1"
              />
              <p-precision-field
                md="6"
                name="per_desconto_promocao_1"
                label="Desconto de"
                suffix="%"
                v-model="data.per_desconto_promocao_1"
              />

              <p-precision-field
                md="6"
                name="qtd_promocao_2"
                label="Comprando mais de"
                v-model="data.qtd_promocao_2"
              />
              <p-precision-field
                md="6"
                name="per_desconto_promocao_2"
                label="Desconto de"
                suffix="%"
                v-model="data.per_desconto_promocao_2"
              />
            </v-row>
          </container-content>
        </v-row>
      </v-col>
    </template>

    <template #tab-conversao>
      <v-row class="mx-0 pt-3">
        <v-col cols="12" lg="10" xl="8" offset-lg="1" offset-xl="2">
          <v-row>
            <v-col md="5">
              <v-row>
                <p-select-filter
                  label="Unidade de entrada"
                  name="entrada_unidade_medida"
                  item-value="sigla"
                  :item-texts="['sigla', 'descricao']"
                  :items="extra.unidade_medida"
                  v-model="data.entrada_unidade_medida"
                />
                <p-precision-field
                  label="Fator de conversão"
                  name="fator_conversao"
                  v-model="data.fator_conversao"
                />
                <p-select-filter
                  label="Unidade de saída"
                  name="unidade_medida"
                  item-value="sigla"
                  :item-texts="['sigla', 'descricao']"
                  :items="extra.unidade_medida"
                  v-model="data.unidade_medida"
                />
              </v-row>
            </v-col>
            <v-col md="7" class="d-flex align-center">
              <v-label class="caption">
                Exemplo:
                <br />
                Compra 1 {{ getUnidadeMedida("entrada_unidade_medida") }} e
                vende {{ data.fator_conversao || 1 }} {{ getUnidadeMedida() }}
              </v-label>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>

    <template #tab-cod-barras>
      <v-col class="px-0 pt-2" lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row class="mx-0" :style="`height: ${height() - 38}px`">
          <v-col md="8" cols="12" class="px-1">
            <div :style="`height: ${height() - 38}px; width: 100%;`">
              <data-table
                hide-footer
                hide-empty-row
                auto-focus
                :disabled="isFichaDisabled"
                :items="codBarrasTabValues"
                :headers="codBarrasTabHeaders"
                @delete="onDeleteCodBarras"
                @force-delete="onDeleteCodBarras"
                :value="selected"
                @input="setSelected"
              />
            </div>
          </v-col>
          <v-col cols="12" md="4" class="px-1">
            <div>
              Estes códigos de barras são usados na importação do XML.
              <br />Esta tabela é alimentada automaticamente na importação do
              XML da NF-e. <br />No caso de fornecedores com códigos de barras
              diferente para o mesmo produto.
              <br />
              <br />Para apagar, clique em cima do código desejeado e tecle
              &lt;DEL&gt;.
            </div>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-tags>
      <v-col class="px-0" lg="10" xl="8" offset-lg="1" offset-xl="2">
        <div :style="`height: ${height() - 38}px; width: 100%;`">
          <v-row no-gutters>
            <p-multiple-select-filter
              cols="12"
              md="6"
              name="tags_categorias"
              label="Categorias"
              item-text="descricao"
              item-value="id"
              :items="categorias"
              v-model="data.tags_categorias"
            />

            <v-col cols="12" md="6" class="align-self-center text-center">
              Tags do item (altere os valores da coluna VALOR)
            </v-col>
          </v-row>

          <tag-list-json
            :disabled="isFichaDisabled"
            :categories="data.tags_categorias"
            :__ficha="__ficha"
            :base="tagsTabValues"
            :headers="tagsTabHeaders"
            :height="height() - 38 - 52 - 8"
            v-model="data.tags"
          />
        </div>
      </v-col>
    </template>
  </tabs-items>
</template>

<script>
import find from "lodash/find";
import get from "lodash/get";
import map from "lodash/map";
import min from "lodash/min";
import toString from "lodash/toString";

import { mapState } from "vuex";

import { extra, tagsTabValues, tagsTabHeaders } from "@/config/modulos/estoque";

import { TabsItems, ImageForm } from "@/components/utils";
import { clearCurrency } from "@/utils";

import {
  ContainerContent,
  DependenceTable,
  TagListJson,
  FieldLabelEditable,
  PTextField,
  PPrecisionField,
  PrecisionField,
  PDateTime,
  PSwitch,
  PSelectFilter,
  PTextArea,
  PMultipleSelectFilter,
  Btn,
} from "@/components/form";
import DataTable from "@/components/table/DataTable.vue";

import actions from "@/store/actions";

// Mixins
import Ficha from "@/mixins/ficha";

export default Ficha.extend({
  components: {
    Btn,
    DependenceTable,
    FieldLabelEditable,
    TabsItems,
    PTextArea,
    PTextField,
    ContainerContent,
    PrecisionField,
    PPrecisionField,
    PDateTime,
    PSelectFilter,
    PSwitch,
    DataTable,
    ImageForm,
    TagListJson,
    PMultipleSelectFilter,
  },
  data: () => ({
    tagsTabValues,
    tagsTabHeaders,
    selectedGrade: { coluna: 1 },

    assembleQuantity: "0,00",
    disassembleQuantity: "",
  }),
  computed: {
    ...mapState({
      loading: (state) => state.modulos.estoque.loading,
    }),
    categorias() {
      return [
        {
          descricao: "FCP",
          id: "fcp",
        },
        {
          descricao: "Arma",
          id: "arma",
        },
        {
          descricao: "Veículo",
          id: "veicProd",
        },
        {
          descricao: "Combustível",
          id: "comb",
        },
        {
          descricao: "Observações",
          id: "obs",
        },
      ];
    },
    /**
     * Retorna a descrição da UND de medida
     */
    getUnidadeMedida() {
      // Retorna a função
      return (name = "unidade_medida") => {
        // Define a função que busca o item da Unidade de medida, através do id
        const find_ = (_name = name) =>
          find(
            // Unidades
            this.extra.unidade_medida,
            // Condição
            (und) => und.sigla === this.data[_name]
            // Caso nao encontrar, retorna um objeto vazio
          ) ?? {};

        // Faz a busca
        const unidade = find_(name);

        // Caso não houver, busca pela varável default
        if (!unidade.descricao) return find_("unidade_medida").descricao;

        // Retorna a descrição
        return unidade.descricao;
      };
    },

    /**
     * Função que retorna os valores da Tab Composição
     */
    composicaoTabHeaders() {
      return [
        {
          text: "Quantidade",
          value: "quantidade",
          disabled: "id",
          type: "precision",
        },
      ];
    },
    /**
     * Função que retorna os valores da Tab Código de Barras
     */
    codBarrasTabHeaders() {
      return [
        { text: "Código de barras", value: "gtin" },
        { text: "Fornecedor", value: "clifor" },
      ];
    },
    /**
     * Função que retorna os itens da tab código de barras
     */
    codBarrasTabValues() {
      // return this.dependencies["tab-cod-barras"] ?? [];
      return this.data.codigo_barras ?? [];
    },
    /**
     * Valores interpretados da tabela e arranjados para exibição
     * @returns {Array}
     */
    gradeTabValues() {
      // Retorna os itens da grade
      // return this.dependencies["tab-grade"] ?? [];
      return this.data.grade ?? [];
    },
    /**
     * Retorna as headers da grade, mesmo invisiveis na tabela, é necessário para a tabela montar corretamente, e o value precisa ser sequencial
     * baseado no número de colunas disponíveis, somente assim irão ser exibidos os valores
     * @returns {Array}
     */
    gradeTabHeaders() {
      // Quantidade de colunas
      const numberOfColumns = 10;
      const headers = [];

      // Percorre cada uma das vezes da quantidade de colunas
      for (let column = 0; column < numberOfColumns; column++) {
        // Adiciona uma nova coluna
        headers.push({
          noResize: true,
          width: "120px",
          color: column === 0 ? "error" : null,
          value: column,
          disabled: column === 0 ? (item) => item.id === 0 : false,
        });
      }
      // Retorna os cabeçalhos
      return headers;
    },
    ibpt() {
      return {
        descricao: get(this.data, "ibpt.descricao"),
        fonte: get(this.data, "ibpt.fonte"),
      };
    },
    natureza() {
      return {
        descricao: get(this.data, "natureza.descricao"),
        cfop: get(this.data, "natureza.cfop"),
      };
    },
    extra__() {
      return extra;
    },
  },
  methods: {
    assemble(params) {
      return this.$store
        .dispatch(actions.MODULOS.ESTOQUE.ASSEMBLE, params)
        .then(this.afterCompose);
    },
    disassemble(params) {
      return this.$store
        .dispatch(actions.MODULOS.ESTOQUE.DISASSEMBLE, params)
        .then(this.afterCompose);
    },
    afterCompose(response) {
      const registro = get(response, "data.registro");

      if (registro) {
        this.__ficha.afterUpdate(registro);
      }

      const composing = get(response, "data.composing");

      if (composing) {
        this.__ficha.onUpdateDependence({
          dependence: "composicao",
          items: composing,
        });
      }

      // se for overlay, retorna
      if (this.__ficha.overlay) return;

      // atualiza o grid
      this.__ficha.__modulo.onRefreshModuloData();
    },
    onDependenceTabLoaded(dependence) {
      const mappingValidations = {
        "tab-cod-barras": "tem_codigo_barras",
        "tab-grade": "tem_grade",
        "tab-composicao": "tem_composicao",
      };

      if (!this.data[mappingValidations[dependence]]) return;

      this.__ficha.readDependence({
        dependence,
      });
    },
    onDeleteCodBarras(value) {
      if (!value || !value.id) return;
      if (this.isFichaDisabled) return;

      this.__ficha.handleDependence({
        id: value.id,
        type: "delete",
        dependence: "codigo_barras",
        modulo: "codigo-barras",
      });
    },
  },
  watch: {
    "data.composicao"(values) {
      this.disassembleQuantity = this.data.qtd_atual;

      const mapped = map(
        values ?? [],
        (item) =>
          clearCurrency(item.qtd_atual ?? "0,0") /
          clearCurrency(item.quantidade ?? "0,0")
      );

      this.assembleQuantity = toString(Math.max(min(mapped) ?? 0, 0));
    },
  },
});
</script>
