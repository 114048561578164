/**
 * Colunas do Grid
 */
export const columns = [
  { text: "Data", value: "created_at", type: "timestamp" },
  { text: "Código", value: "id" },
  { text: "Módulo", value: "modulo" },
  { text: "Ação", value: "acao" },
  { text: "Usuário", value: "nome_usuario" },
  { text: "Descrição", value: "descricao" },
  // { text: "Valor antigo", value: "old" },
  // { text: "Valor novo", value: "new" }
];

/**
 * Se o módulo é protegido por senha de administrador
 */
export const guard = true;

/**
 * Cache default
 */
export const cache = {
  colunas: [
    "id",
    "created_at",
    "modulo",
    "acao",
    "nome_usuario",
    "descricao",
    // "old",
    // "new"
  ],
  filtros: [],
  ordenar: [
    {
      coluna: "id",
      ordem: "desc",
    },
  ],
};
