<template>
  <date-time
    :name="`${_uid}-${name}`"
    @input="$emit('input', $event)"
    @change="onChanged"
    v-bind="$attrs"
  />
</template>

<script>
import { DateTime } from "@/components/form";
import Persistent from "./persistent";

export default Persistent.extend({
  name: "PDateTime",
  components: { DateTime },
});
</script>
