<template>
  <div class="tabsItems">
    <template v-for="tab in length">
      <slot :name="tab" v-if="tab === value" />
    </template>
  </div>
</template>

<script>
import { keys } from "lodash";

export default {
  props: {
    value: {},
  },
  computed: {
    length() {
      return keys(this.$slots);
    },
  },
  watch: {
    value: {
      handler(tab) {
        if (tab) return this.$emit(`loaded-${tab}`, tab);
      },
    },
  },
};
</script>

<style scoped>
.tabsItems {
  min-height: 38px !important ;
  height: 38px !important ;
}
</style>
