import Vue from "vue";

export default Vue.extend({
  props: {
    guard: { type: Boolean, default: true },
    onlyPassword: { type: Boolean, default: false },
  },
  data: () => ({
    authorized: false,
    authModal: false,
  }),
  methods: {
    onAuthorized(): void {
      this.authorized = true;
    },
    onAuthClosed(): void {
      this.authorized = false;
      this.authModal = false;
    },
    requestAuth(): void {
      this.authModal = true;
    },
    onPassword(event): void {
      this.$emit("password", event);
    },
  },
  computed: {
    authenticated(): boolean {
      return !this.guard || this.authorized;
    },
    authModalOpen(): boolean {
      return !this.authenticated && this.authModal;
    },
  },
});
