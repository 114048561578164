var render = function render(){var _vm=this,_c=_vm._self._c;return _c('container-content',{attrs:{"hidden":_vm.hideContainerContent,"title":_vm.title}},[_c('v-row',[_c('v-col',{staticClass:"pa-1",attrs:{"md":_vm.compra ? 8 : 12,"lg":_vm.compra ? 8 : 12}},[_c('v-card',{attrs:{"flat":"","tile":"","min-height":_vm.minHeight,"height":_vm.height}},[_c('data-table',_vm._b({ref:"dataTable",attrs:{"editable":"","hideEmptyRow":"","hide-footer":_vm.modulo !== 'compra-itens' && _vm.modulo !== 'venda-itens',"items":_vm.items,"value":_vm.selected,"headers":_vm.headers_},on:{"input":function($event){return _vm.$emit('selected', $event)},"resize:header":_vm.onResizeHeader},scopedSlots:_vm._u([{key:"footer-content",fn:function(){return [(_vm.clearCurrency(_vm.value.vlr_produtos))?[_c('strong',[_vm._v("Valor Produtos:")]),_vm._v(" "+_vm._s(_vm.value.vlr_produtos)+" ")]:_vm._e(),(_vm.clearCurrency(_vm.value.vlr_servicos))?[_c('strong',[_vm._v("Valor Serviços:")]),_vm._v(" "+_vm._s(_vm.value.vlr_servicos)+" ")]:_vm._e(),(_vm.clearCurrency(_vm.value.vlr_total))?[_c('strong',[_vm._v("Valor Total:")]),_vm._v(" "+_vm._s(_vm.value.vlr_total)+" ")]:_vm._e()]},proxy:true},{key:"persistent-select",fn:function({
              index,
              item,
              selected,
              header,
              on,
              ...attrs
            }){return [_c('table-select-item',_vm._g(_vm._b({ref:`select-filter-${index}`,attrs:{"selected":selected,"allowRawText":_vm.allowRawText,"enableFocus":index === 0 && _vm.enableFocus,"produtoKey":_vm.produtoKey,"modulo":_vm.modulo,"disabled":_vm.disabled,"item":item,"color":item.row_color,"__ficha":_vm.__ficha},on:{"raw-text":(event) => _vm.onRawText(event, { item, header }),"change":(event) => _vm.onPersistentChange(event, { item, header })}},'table-select-item',attrs,false),on))]}},{key:"textfield",fn:function({
              on,
              item,
              type,
              value,
              index,
              column,
              header,
              selected,
              ...rest
            }){return [(type === 'precision')?_c('p-precision-field',_vm._g(_vm._b({attrs:{"clear":"","persist":false,"white":selected,"value":value,"name":header.value,"auto-focus":index === 0 && column === 0 && _vm.enableFocus,"disabled":_vm.isTextFieldDisabled(item, column),"readonly":_vm.isTextFieldReadonly(item, column),"relation":item},on:{"change":function($event){return _vm.onChange($event, { item, header })},"ctrl-delete":function($event){return _vm.onCtrlDel({ item, header })}}},'p-precision-field',{ ...rest, ...(header.options || {}) },false),on)):(type === 'date')?_c('date-time',_vm._g(_vm._b({ref:`date-time-${index}`,attrs:{"clear":"","white":selected,"value":value,"disabled":_vm.isTextFieldDisabled(item, column),"readonly":_vm.isTextFieldReadonly(item, column)},on:{"change":function($event){return _vm.onChange($event, { item, header })},"ctrl-delete":function($event){return _vm.onCtrlDel({ item, header })}}},'date-time',{ ...rest, ...(header.options || {}) },false),on)):_c('c-text-field',_vm._g(_vm._b({ref:`${type}-text-field-${index}`,attrs:{"white":selected,"value":value,"auto-focus":index === 0 && column === 0 && _vm.enableFocus,"disabled":_vm.isTextFieldDisabled(item, column),"readonly":_vm.isTextFieldReadonly(item, column)},on:{"change":function($event){return _vm.onChange($event, { item, header })},"ctrl-delete":function($event){return _vm.onCtrlDel({ item, header })}}},'c-text-field',{ ...rest, ...(header.options || {}) },false),on))]}},{key:"select",fn:function({
              on,
              item,
              value,
              index,
              column,
              header,
              selected,
              ...rest
            }){return [_c('select-filter',_vm._g(_vm._b({attrs:{"clear":"","white":selected,"value":item[header.value],"auto-focus":index === 0 && column === 0 && _vm.enableFocus,"disabled":_vm.isTextFieldDisabled(item, column),"readonly":_vm.isTextFieldReadonly(item, column),"input-initial":value,"items":header.source},on:{"change":function($event){return _vm.onChange($event, { item, header })},"ctrl-delete":function($event){return _vm.onCtrlDel({ item, header }, true)}}},'select-filter',{ ...rest, ...(header.options || {}) },false),on))]}}])},'data-table',_vm.$attrs,false))],1)],1),(_vm.compra)?_c('v-col',{staticClass:"pa-1",attrs:{"md":"4"}},[_c('referencia-produto',{attrs:{"__ficha":_vm.__ficha},on:{"produto":_vm.onProdutoChanged},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1):_vm._e(),_vm._t("extra")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }