import API from "@/api";
import actions from "@/store/actions";
import mutations from "@/store/mutations";

const prefix = "/estoque";

const api = API.config({
  state: "loading",
  mutation: mutations.MODULOS.ESTOQUE.LOADING,
  delay: 0,
});

const intialState = {
  loading: false,
};

export default {
  state: Object.assign({}, intialState),

  mutations: {
    /**
     * Mutação que controla o carregamento das funções do estoque
     */
    [mutations.MODULOS.ESTOQUE.LOADING](state, loading) {
      // Atribui o loading
      state.loading = loading;
    },
  },

  actions: {
    /**
     * Função que envia a requisição para gerar o arquivo SPED - Sintegra
     */
    [actions.MODULOS.ESTOQUE.SINTEGRA](store, params) {
      return api.store(store).post(`${prefix}/sped/sintegra`, params);
    },
    /**
     * Função que envia a requisição para gerar o arquivo SPED - EFD ICMS/IPI
     */
    [actions.MODULOS.ESTOQUE.EFD_ICMS_IPI](store, params) {
      return api.store(store).post(`${prefix}/sped/efd-icms-ipi`, params);
    },
    /**
     * Função que envia a requisição para gerar o arquivo txt do inventário
     */
    [actions.MODULOS.ESTOQUE.INVENTARIO](store, params) {
      return api.store(store).post(`${prefix}/sped/inventario`, params);
    },

    /**
     * Função que envia a requisição de fabricação do item baseado na sua composição
     */
    [actions.MODULOS.ESTOQUE.ASSEMBLE](store, params) {
      return api.store(store).post(`${prefix}/composing/assemble`, params);
    },

    /**
     * Função que envia a requisição de desmontagem do item baseado na sua composição
     */
    [actions.MODULOS.ESTOQUE.DISASSEMBLE](store, params) {
      return api.store(store).post(`${prefix}/composing/disassemble`, params);
    },

    /**
     * Função que envia a requisição de desmontagem do item baseado na sua composição
     */
    [actions.MODULOS.ESTOQUE.BALANCAS](store, data) {
      const { dispatch } = store;

      return dispatch(actions.UTILS.HANDLE_FILE, () => {
        return api.store(store).post(`${prefix}/balancas`, data);
      });
    },

    /**
     * Função que gera o arquivo de terminal consulta preço
     */
    [actions.MODULOS.ESTOQUE.TERMINAL_CONSULTA](store, data) {
      const { dispatch } = store;

      return dispatch(actions.UTILS.HANDLE_FILE, () => {
        return api.store(store).post(`${prefix}/terminal-consulta`, data);
      });
    },

    /**
     * Função que aumenta o preço de todos os itens filtrados
     */
    [actions.MODULOS.ESTOQUE.AUMENTO_PRECO](store, params) {
      return api.store(store).post(`${prefix}/aumento-de-preco`, params);
    },
  },
};
