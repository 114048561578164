/**
 * @exports Object Objeto com o mapeamento das Mutações
 */
export default {
  // App Actions

  APP: {
    STATS: "appStats",
    MODULES: "appLoadModules",
    DASHBOARD: "appDashboard",
    NOVIDADES: "appNovidades",

    REFRESH_USER: "appRefreshAppUser",
    LOGIN: "appLogin",
    LOGIN_ADMIN: "appLoginAdmin",
    LOGOUT: "appLogout",
    FLOW_SOCKET: "appFlowSocket",

    JOIN_EMITENTE: "appJoinEmitente",
    LISTEN_EVENTS: "appListenEvents",

    CACHE: "appCache",
    CACHE_REBUILD: "appCacheRebuild",

    CLEAR_ALL_DATA: "clearAllData",
    EXPORT_ALL_DATA: "exportAllData",
    REQUEST_SUPPORT_ACCESS: "requestSupportAccess",
    REVOKE_SUPPORT_ACCESS: "revokeSupportAccess",
    STATUS_SUPPORT_ACCESS: "supportAccessStatus",
  },

  REGISTER: {
    CHECK: "registerCheck",
    USER: "registerUser",
    CREATE: "registerCreate",
  },

  CORE: {
    READ: "coreRead",
    UPDATE: "coreUpdate",
    POST: "corePost",
    POST_REQUEST: "corePostRequest",
    POST_FORM: "corePostForm",
    DELETE: "coreDelete",
  },

  CONFIG: {
    NFE: {
      CERTIFICATE: "configNfeCertificate",
    },

    REVERSION: {
      REVERT: "configReversionRevert",
    },
  },

  // Modulo Actions
  MODULO: {
    CURRENT: "moduloCurrent",
    AFTER_CLOSE_FICHA: "moduloAfterCloseFicha",

    REGISTROS: {
      LAZY_READ: "moduloRegistrosLazyRead",
      READ: "moduloRegistrosRead",
      REPLACE_FILTERED_DATA: "moduloRegistrosReplaceFilteredData",
      REPLICATE: "moduloRegistrosReplicate",
      SEARCH: "moduloRegistrosSearch",
      DELETE: "moduloRegistrosDelete",
    },

    FICHA: {
      SEARCH: "moduloFichaSearch",
      READ: "moduloFichaRead",
      CREATE_REGISTRO: "moduloFichaCreateRegistro",
      UPDATE_REGISTRO: "moduloFichaUpdateRegistro",
      DUPLICATE: "moduloFichaDuplicate",

      DELETE_FILE: "moduloFichaDeleteFile",
      UPDATE_FILE: "moduloFichaUpdateFile",

      DEPENDENCIES: {
        READ: "moduloFichaDependenciesRead",
      },

      HANDLE_DEPENDENCE: "moduloFichaHandleDependence",
      UPDATE_RELATION: "moduloFichaUpdateRelation",
    },

    PERSISTENT: {
      READ: "moduloPersistentRead",
      SEARCH: "moduloPersistentSearch",
    },
  },

  MODULOS: {
    ESTOQUE: {
      ASSEMBLE: "modulosEstoqueAssemble",
      DISASSEMBLE: "modulosEstoqueDisassemble",

      BALANCAS: "modulosEstoqueBalancas",
      TERMINAL_CONSULTA: "modulosEstoqueTerminalConsulta",

      AUMENTO_PRECO: "modulosEstoqueAumentoPreco",

      SINTEGRA: "modulosEstoqueSintegra",
      EFD_ICMS_IPI: "modulosEstoqueEfdIcmsIpi",
      INVENTARIO: "modulosEstoqueInventario",
    },

    ENTRADA: {
      REFERENCE: "modulosEntradaReference",
      GERAR_NFE: "modulosEntradaGerarNfe",
      FATOR_CONVERSAO: "modulosFatorConversao",
    },

    PAYMENTS: {
      CREATE: "modulosPaymentsCreate",
      UPDATE: "modulosPaymentsUpdate",
      DELETE: "modulosPaymentsDelete",

      PARCELS: "modulosPaymentsParcels",
      UPDATE_VALUE: "modulosPaymentsUpdateValue",
      UPDATE_DATE: "modulosPaymentsUpdateDate",
    },

    CONTAS: {
      GENERATE: "modulosContasGenerate",
      MULTIPLE: "modulosContasMultiple",

      BOLETO_PDF: "modulosContasBoletoPdf",
      BOLETO_EMAIL: "modulosContasBoletoEmail",
    },

    CONSULTA: {
      CADASTRO: "modulosConsultaCadastro",
      CEP: "modulosConsultaCep",
    },
  },

  DFE: {
    HANDLE: "dfeHandle",

    STATUS: "dfeStatus",
    MANIFESTO: "dfeManifesto",
    DANFE: "dfeDanfe",
    EVENTO: "dfeEvento",
    DANFCE: "dfeDanfce",

    DIST_DFE: "dfeDist",
    SANGR_SUPR: "dfeSangriaSuprimento",

    EMITIR: "dfeEmitir",
    EMITIR_CONTINGENCIA: "dfeEmitirContingencia",
    XML: "dfeGerarXML",
    CANCELAR: "dfeCancelar",
    DUPLICIDADE: "dfeDuplicidadeDiffChave",
    CONSULTAR: "dfeConsultar",
    INUTILIZAR: "dfeInutilizar",
    EMAIL: "dfeEnviarEmail",
    CCE: "dfeCCe",
    CCE_PDF: "dfeCCePDF",
  },

  EXPORT: {
    XML: "exportXml",
    DATA: "exportData",
  },

  IMPORT: {
    HANDLE: "importHandle",
    XML: "importXml",
    DOCUMENT: "importDocument",
  },

  REPORTS: {
    READ: "reportsRead",
    REPORT: "reportsReport",
  },

  UTILS: {
    READ_XML: "utilsReadXml",
    PENDING_DOWNLOAD: "utilsPendindDownload",
    IS_DOWNLOAD_READY: "utilsDownloadReady",
    HANDLE_FILE: "utilsHandleFile",
  },

  // User Actions
  USERS: {
    UPDATE_PERMISSIONS: "userUpdatePermissions",
    UPDATE_PASSWORD: "userUpdatePassword",
    RESET_PASSWORD: "userResetPassword",
    RESET_USER: "userReset",
  },

  // Notifiction Actions
  NOTIFICATIONS: {
    READ: "notificationsRead",
    CLEAR: "notificationsClear",
  },
};
