<template>
  <v-col
    :cols="cols"
    :sm="sm"
    :md="md"
    :offset-md="offsetMd"
    :lg="lg"
    :xl="xl"
    :id="`col-${_uid}`"
    class="pb-1 pt-2 px-1"
  >
    <v-combobox
      dense
      outlined
      hide-details
      hide-no-data
      ref="combobox"
      :id="internalId"
      :value="value"
      :item-value="itemValue"
      :item-text="itemText"
      :items="internalItems"
      :filter="filter"
      :menu-props="menuProps"
      @input.native="onNativeInput"
      @input="onInput"
      @change="onChange"
      @keydown.native="onKeyDown"
      @blur="onBlur"
      @focus="onFocus"
      @click:append-outer="$listeners['click:append-outer']"
      v-scroll="onScroll"
      v-bind="$attrs"
    >
      <template #item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs">
          <v-list-item-content>
            <v-list-item-title
              class="caption"
              v-text="
                `${
                  (typeof itemText === 'function'
                    ? itemText(item)
                    : item[itemText]) || ''
                }`
              "
            />
            <slot name="subtitle" v-bind="{ item }">
              <v-list-item-subtitle
                v-html="
                  (typeof itemSubtitle === 'function'
                    ? itemSubtitle(item)
                    : item[itemSubtitle]) || ''
                "
              />
            </slot>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </v-col>
</template>

<script>
import Vue from "vue";

import { focusNextTextField } from "@/utils";
import { debounce, get, pick } from "lodash";

export default Vue.extend({
  props: {
    value: {},
    itemValue: { default: "value" },
    itemText: { default: "text" },
    ignoreEnterFocus: {
      type: Boolean,
      default: false,
    },

    searchBy: { type: [String, Array], default: () => [] },
    items: { type: Array, default: () => [] },
    itemSubtitle: { type: Function },

    cols: { default: "12" },
    pl: { type: Boolean, default: false },
    sm: { required: false },
    md: { required: false },
    offsetMd: { required: false },
    lg: { required: false },
    xl: { required: false },
  },

  computed: {
    internalId() {
      // df = DontFocus
      return this.ignoreEnterFocus
        ? `df-input-${this._uid}`
        : `input-${this._uid}`;
    },
    internalItems() {
      return typeof this.value !== "object" || this.value === null
        ? this.items
        : [this.value, ...this.items];
    },
    menuProps() {
      return {
        closeOnClick: false,
        closeOnContentClick: false,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 304,
        maxWidth: this.maxWidth,
      };
    },
  },

  data: () => ({
    index: -1,
    oldValue: null,
    maxWidth: "min-content",
  }),

  mounted() {
    const initial = this.$refs.combobox.onScroll;
    this.$nextTick(() => {
      this.maxWidth = get(this.$refs.combobox, "$el.clientWidth", 0) + "px";
    });

    this.$refs.combobox.onScroll = (event) => {
      initial();

      this.onScroll(event);
    };
  },

  methods: {
    onScroll(event) {
      this.$emit("scroll", event);
    },
    onInput(event) {
      this.$emit("input", event);
    },
    onNativeInput(event) {
      this.$emit("native-input", event.target.value);
    },
    onChange: debounce(function (value) {
      this.index =
        this.index >= 0
          ? this.index
          : this.internalItems.findIndex(
              (it) => get(it, this.itemValue) === get(value, this.itemValue)
            );

      if (value !== null && value !== "") {
        this.focusNext();
      }

      this.$nextTick(() => {
        this.$emit("input", value);
      });
    }, 40),
    onKeyDown(event) {
      this.index = this.$refs.combobox.getMenuIndex();

      const events = {
        Enter: (event) => this.focusNext(event),
      };

      return events[event.key] && events[event.key](event);
    },
    onFocus(event) {
      this.oldValue = event.target.value;
    },
    onBlur(event) {
      if (event.target.value === this.oldValue) return;
      this.emitUpdates();
    },
    focusNext(event = null) {
      focusNextTextField(event, this.internalId);

      return this.$nextTick(() => {
        this.$refs.combobox.blur();
      });
    },
    filter(item, query, text) {
      const search =
        (typeof this.searchBy === "string" ? [this.searchBy] : this.searchBy) ||
        [];

      query = query
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      text = search.length ? Object.values(pick(item, search)).join(" ") : text;

      text = text
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      return text.includes(query);
    },

    emitUpdates() {
      this.$emit("change", this.value);
    },
  },
  watch: {
    index(index) {
      this.$refs.combobox.setMenuIndex(index);
    },
  },
});
</script>
