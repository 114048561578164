<template>
  <v-row>
    <v-col md="7">
      <v-row>
        <select-filter
          sm="4"
          label="Ano"
          :items="years"
          v-model="inutilizacao.ano"
        />
        <select-filter
          cols="6"
          sm="4"
          label="Modelo"
          readonly
          :items="modelos"
          v-model="inutilizacao.modelo"
        />
        <select-filter
          observe-items
          cols="6"
          sm="4"
          label="Série"
          :items="series"
          v-model="inutilizacao.nSerie"
        />
        <text-field
          auto-focus
          maxlength="9"
          cols="6"
          sm="6"
          md="4"
          label="Número inicial"
          v-model="inutilizacao.nIni"
        />
        <text-field
          maxlength="9"
          cols="6"
          sm="6"
          md="4"
          v-model="inutilizacao.nFin"
          label="Número final"
        />
        <text-area
          cols="12"
          rows="3"
          maxlength="254"
          label="Justificativa (min. 15 caracteres)"
          @click="inutilizar"
          v-model="inutilizacao.xJust"
        />
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="px-1">
          <btn
            outlined
            color="actionbutton"
            btn-class="focus-priority"
            :loading="sending"
            :disabled="sending || __ficha.isFichaDisabled"
            @click="inutilizar"
            >Inutilizar intervalo</btn
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col md="5" class="px-1 py-2">
      <div :style="`height: 200px !important`">
        <data-table
          hide-footer
          :selectable="false"
          :headers="tabInutilizacaoHeaders"
          :items="tabInutilizacaoValues"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import moment from "@/plugins/moment";
import { get, map, omit, padStart } from "lodash";

import { TextField, SelectFilter, TextArea, Btn } from "@/components/form";

import DataTable from "@/components/table/DataTable.vue";
import { mapMutations, mapState } from "vuex";

import mutations from "@/store/mutations";
import actions from "@/store/actions";

export default {
  components: {
    DataTable,
    TextField,
    TextArea,
    Btn,
    SelectFilter,
  },
  props: {
    modelo: { required: true },
    data: { default: () => [] },
    extra: { default: () => ({}) },

    __ficha: {},
  },
  data: (vm) => ({
    inutilizacao: {
      ano: moment().year().toString().substr(2, 2),
      xJust: "",
      modelo: vm.modelo,
      nSerie: 1,
    },
  }),
  methods: {
    ...mapMutations({
      snackbar: mutations.APP.SNACKBAR,
    }),
    /**
     * Função que inutiliza um intervalo de NF-es
     */
    inutilizar() {
      // Captura a justificativa
      const { xJust } = this.inutilizacao;

      // Se não tiver ou for menor que 15 chars
      if (!xJust || xJust.toString().length < 15)
        // Retorna o erro
        return this.snackbar({
          text: "A justificativa precisa ter no mínimo 15 caracteres.",
          color: "error",
          active: true,
        });

      // Chama a ação de inutilzar
      this.$store
        .dispatch(actions.DFE.INUTILIZAR, {
          ...this.inutilizacao,
          document: this.modelo === "55" ? "nfe" : "nfce",
        })
        .then((response) => {
          // Captura os dados da resposta
          const { data } = response;

          // Se houver data e registro
          if (data && data.registro) {
            // commita as dependências
            this.__ficha.onCreateDependence({
              dependence: "inutilizacoes",
              items: [data.registro],
            });
          }

          // Continua a propagação da promessa
          return response;
        })
        .then(() => {
          // Quando sucesso, limpa as informações da inutilização
          this.inutilizacao = omit(this.inutilizacao, [
            "nIni",
            "nFin",
            "xJust",
          ]);
        });
    },
  },
  computed: {
    ...mapState({
      sending: (state) => state.dfe.sending,
    }),
    /**
     * Lista de Series cadastradas
     */
    series() {
      return map(get(this.extra, "series", []), (item) => ({
        text: padStart(item.serie, 3, "0"),
        value: parseInt(item.serie),
      }));
    },
    modelos() {
      return [{ text: this.modelo, value: this.modelo }];
    },
    /**
     * Lista dos últimos anos
     */
    years() {
      // Define a lista de anos
      const years = [];
      // Inicia há 8 anos atrás
      const dateStart = moment().subtract(8, "y");
      // Termina agora
      const dateEnd = moment();

      // Enquanto tiver diferença entre a data final e inicial
      while (dateEnd.diff(dateStart, "years") >= 0) {
        // Adiciona um ano
        years.push({
          text: dateStart.format("YY"),
          value: dateStart.format("YY"),
        });

        // Incrementa na data de inicio
        dateStart.add(1, "year");
      }

      // Retorna a lista de anos
      return years.reverse();
    },
    /**
     * Cabeçalho da tab de inutilização
     */
    tabInutilizacaoHeaders() {
      return [
        { text: "Data", value: "data", type: "date" },
        { text: "Modelo", value: "modelo" },
        { text: "Ano", value: "ano" },
        { text: "Série", value: "serie", padStart: 3 },
        { text: "Nº Início", value: "numero_inicio" },
        { text: "Nº Final", value: "numero_final" },
        { text: "Protocolo", value: "protocolo" },
      ];
    },
    /**
     * Valores interpretados da tabela e arranjados para exibição
     */
    tabInutilizacaoValues() {
      // Retorna as séries
      return this.data ?? [];
    },
  },
};
</script>
