<template>
  <tabs-items
    @loaded-tab-contatos="onDependenceTabLoaded"
    @loaded-tab-veiculos="onDependenceTabLoaded"
    @loaded-tab-bandeiras="onDependenceTabLoaded"
    v-model="value"
  >
    <template #tab-principal>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <v-col cols="12" md="6" class="pb-4 pb-lg-0">
            <v-row>
              <p-switch
                ignore-enter-focus
                sm="3"
                class="my-auto"
                name="ativo"
                label="Ativo"
                v-model="data.ativo"
              />
              <field-cpf-cnpj
                auto-focus
                sm="5"
                md="9"
                v-model="data.cpf_cnpj"
              />
              <p-text-field
                sm="4"
                type="tel"
                name="rg_ie"
                label="RG/IE"
                maxlength="16"
                v-model="data.rg_ie"
              />
              <p-text-field
                md="8"
                name="nome_razao_social"
                label="Nome/Razão Social"
                maxlength="60"
                v-model="data.nome_razao_social"
              />
              <p-text-field
                ignore-enter-focus
                md="9"
                name="nome_contato"
                label="Contato"
                maxlength="35"
                v-model="data.nome_contato"
              />
              <field-cep cols="6" sm="3" v-model="data.cep" />
              <p-select-uf
                cols="6"
                sm="3"
                @change="(uf) => $refs.municipios.read(uf)"
                v-model="data.uf"
              />
              <p-select-municipios
                sm="6"
                md="9"
                :uf="data.uf"
                ref="municipios"
                v-model="data[data.uf === 'EX' ? 'municipio' : 'municipio_id']"
              />
              <p-text-field
                sm="9"
                name="endereco"
                label="Endereço"
                maxlength="60"
                v-model="data.endereco"
              />
              <field-numero sm="3" maxlength="60" v-model="data.numero" />
              <p-text-field
                sm="6"
                name="bairro"
                label="Bairro"
                maxlength="60"
                v-model="data.bairro"
              />
              <p-text-field
                sm="6"
                name="complemento"
                label="Complemento"
                maxlength="60"
                v-model="data.complemento"
              />
              <field-telefone cols="6" md="6" v-model="data.telefone" />
              <field-telefone
                ignore-enter-focus
                cols="6"
                md="6"
                name="fax"
                label="Telefone secundário"
                v-model="data.fax"
              />
              <field-telefone
                ignore-enter-focus
                cols="6"
                md="6"
                name="celular"
                label="Celular"
                v-model="data.celular"
              />
              <field-telefone
                ignore-enter-focus
                cols="6"
                md="6"
                name="whatsapp"
                label="WhatsApp"
                v-model="data.whatsapp"
              />
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="pt-2 pt-md-3">
            <v-row>
              <field-email
                ignore-enter-focus
                multiple
                v-model="data.email"
                maxlength="-1"
              />
              <p-precision-field
                ignore-enter-focus
                sm="6"
                type="tel"
                name="vlr_credito"
                label="Limite de crédito"
                prefix="R$"
                v-model="data.vlr_credito"
              />
              <p-select-filter
                ignore-enter-focus
                sm="6"
                name="convenio_id"
                label="Convênio"
                item-text="descricao"
                item-value="id"
                :items="extra.convenios"
                v-model="data.convenio_id"
              />
              <p-date-time
                disabled
                cols="6"
                type="timestamp"
                name="created_at"
                label="Cadastro"
                v-model="data.created_at"
              />
              <p-date-time
                disabled
                cols="6"
                md="6"
                name="ultima_compra_dt"
                label="Última venda"
                v-model="data.ultima_compra_dt"
              />
              <p-date-time
                ignore-enter-focus
                hideButtonNow
                cols="6"
                name="proximo_contato_dt"
                label="Próx. Contato"
                v-model="data.proximo_contato_dt"
              />
              <p-date-time
                ignore-enter-focus
                hideButtonNow
                cols="6"
                md="6"
                name="nascimento_dt"
                label="Nascido em"
                v-model="data.nascimento_dt"
              />

              <p-multiple-select-filter
                name="clifor_tipos"
                label="Relação Comercial"
                item-text="descricao"
                item-value="descricao"
                :items="extra.clifor_tipos"
                v-model="data.clifor_tipos"
              />
              <p-text-area
                ignore-enter-focus
                cols="12"
                name="obs"
                height="144px"
                label="Observação"
                v-model="data.obs"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-identificadores>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <v-col cols="12" md="6" class="pb-4 pb-lg-0">
            <v-row>
              <field-label-editable
                name="identificador1"
                parametro="lbl_tb_clifors_identificador_1"
                v-model="data.identificador1"
              />
              <field-label-editable
                name="identificador2"
                parametro="lbl_tb_clifors_identificador_2"
                v-model="data.identificador2"
              />
              <field-label-editable
                name="identificador3"
                parametro="lbl_tb_clifors_identificador_3"
                v-model="data.identificador3"
              />
              <field-label-editable
                name="identificador4"
                parametro="lbl_tb_clifors_identificador_4"
                v-model="data.identificador4"
              />
              <field-label-editable
                name="identificador5"
                parametro="lbl_tb_clifors_identificador_5"
                v-model="data.identificador5"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-tags>
      <v-col class="px-0" lg="10" xl="8" offset-lg="1" offset-xl="2">
        <div :style="`height: ${height() - 38}px; width: 100%;`">
          <v-row class="mb-0 ml-1">
            Tags do cadastro (altere os valores da coluna VALOR)
          </v-row>

          <tag-list-json
            :disabled="isFichaDisabled"
            :__ficha="__ficha"
            :base="tagsTabValues"
            :headers="tagsTabHeaders"
            :height="height() - 48"
            v-model="data.tags"
          />
        </div>
      </v-col>
    </template>

    <template #tab-contatos>
      <v-col
        class="pt-3"
        lg="10"
        xl="8"
        offset-lg="1"
        offset-xl="2"
        :style="`height: ${height() - 16}px;`"
      >
        <dependence-table
          class="px-1 pb-0"
          hide-image
          enable-focus
          top-empty-row
          hide-default-item
          hide-container-content
          ignore-text-field-validations
          modulo="cadastro-contatos"
          dependence="contatos"
          :height="height() - 38 - 32"
          :orderBy="[['created_at'], ['desc']]"
          :__ficha="__ficha"
          :defaultHeaders="contatosTabHeaders"
          :selected="selected"
          @selected="setSelected"
          v-model="data"
        />
      </v-col>
    </template>

    <template #tab-foto>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <image-form
            :disabled="isFichaDisabled"
            modulo="cadastro"
            :__ficha="__ficha"
          />
        </v-row>
      </v-col>
    </template>

    <template #tab-bandeiras>
      <v-col
        lg="10"
        xl="8"
        class="pt-3"
        offset-lg="1"
        offset-xl="2"
        :style="`height: ${height() - 16}px;`"
      >
        <dependence-table
          class="px-1 pb-0"
          hide-image
          enable-focus
          hide-default-item
          hide-container-content
          modulo="cadastro-bandeiras"
          produto-key="clifor_id"
          dependence="bandeiras"
          :height="height() - 38 - 32"
          :__ficha="__ficha"
          :defaultHeaders="bandeirasTabHeaders"
          :selected="selected"
          @selected="setSelected"
          v-model="data"
        />
      </v-col>
    </template>

    <template #tab-comissao>
      <v-col cols="12" md="6" offset-md="3">
        <v-row>
          <p-precision-field
            md="6"
            suffix="%"
            name="per_comissao"
            label="Comissão"
            v-model="data.per_comissao"
          />
        </v-row>
      </v-col>
    </template>

    <template #tab-veiculos>
      <v-col
        lg="10"
        xl="8"
        class="pt-3"
        offset-lg="1"
        offset-xl="2"
        :style="`height: ${height() - 16}px;`"
      >
        <dependence-table
          class="px-1 pb-0"
          hide-image
          enable-focus
          hide-default-item
          hide-container-content
          modulo="cadastro-veiculos"
          produto-key="clifor_id"
          dependence="veiculos"
          :height="height() - 38 - 32"
          :__ficha="__ficha"
          :defaultHeaders="veiculosTabHeaders"
          :selected="selected"
          @selected="setSelected"
          v-model="data"
        />
      </v-col>
    </template>
  </tabs-items>
</template>

<script>
import {
  PTextField,
  PPrecisionField,
  PSelectFilter,
  PSelectUf,
  PSelectMunicipios,
  PDateTime,
  PSwitch,
  PTextArea,
  DependenceTable,
  FieldTelefone,
  FieldNumero,
  FieldCpfCnpj,
  FieldCep,
  FieldEmail,
  FieldLabelEditable,
  PMultipleSelectFilter,
  TagListJson,
} from "@/components/form";

import { TabsItems, ImageForm } from "@/components/utils";
import { tagsTabValues, tagsTabHeaders } from "@/config/modulos/cadastro";

import ufs from "@/mapping/ufs";

// Mixins
import Ficha from "@/mixins/ficha";

export default Ficha.extend({
  components: {
    TabsItems,
    DependenceTable,
    PTextField,
    PSwitch,
    PPrecisionField,
    PSelectFilter,
    PSelectUf,
    PSelectMunicipios,
    PDateTime,
    PTextArea,
    ImageForm,
    FieldTelefone,
    FieldNumero,
    FieldCpfCnpj,
    FieldCep,
    FieldEmail,
    FieldLabelEditable,
    PMultipleSelectFilter,
    TagListJson,
  },
  data: () => ({
    tagsTabValues,
    tagsTabHeaders,
  }),
  methods: {
    /**
     * On loaded tab
     */
    onDependenceTabLoaded(dependence) {
      // mapeia as condicionais
      const mappingValidations = {
        "tab-contatos": "tem_contatos",
        "tab-bandeiras": "id",
        "tab-veiculos": "id",
      };

      // Se não possui dados da dependência, retorna
      if (!this.data[mappingValidations[dependence]]) return;

      // Dispara a ação de leitura da dependência
      this.__ficha.readDependence({
        dependence,
      });
    },
  },
  computed: {
    contatosTabHeaders() {
      return [
        {
          value: "descricao",
          text: "Descrição",
          type: "persistent",
          editableIcon: true,
        },
        {
          value: "created_at",
          text: "Data",
          type: "timestamp",
          noEditable: true,
        },
        { value: "user_updated", text: "Usuário", noEditable: true },
      ];
    },
    veiculosTabHeaders() {
      return [
        {
          text: "Placa",
          value: "placa",
          type: "persistent",
          options: {
            maxlength: 11,
          },
        },
        {
          text: "UF",
          disabled: "id",
          value: "uf",
          type: "select",
          source: ufs,
        },
        {
          text: "ANTT",
          disabled: "id",
          value: "antt",
          options: {
            maxlength: 20,
          },
        },
      ];
    },
    bandeirasTabHeaders() {
      return [
        {
          value: "descricao",
          text: "Descrição",
          type: "persistent",
          options: {
            maxlength: "45",
          },
        },
        {
          text: "Tipo",
          disabled: "id",
          value: "tipo",
          type: "select",
          source: [
            { value: "C", text: "Crédito" },
            { value: "D", text: "Débito" },
          ],
        },
        {
          text: "Dias recebimento",
          disabled: "id",
          value: "dias_recebimento",
        },
      ];
    },
  },
  watch: {
    "data.uf"(uf, oldUF) {
      if (uf && uf !== oldUF) {
        this.$refs.municipios && this.$refs.municipios.read(uf);
      }
    },
  },
});
</script>
