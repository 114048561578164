<template>
  <component
    type="tel"
    maxlength="14"
    ref="input"
    :is="component"
    :clear="clear"
    :value="value"
    :name="name"
    @input="onInput"
    @change="onChanged"
    @keypress="onKeyPress"
    @focus="$emit('focus', $event), (focused = true)"
    @blur="$emit('blur', $event), (focused = false)"
    v-bind="$attrs"
    v-on="listeners"
  />
</template>

<script>
import { compact, head, isArray, isNull, map, omit } from "lodash";

import CTextField from "./textfield/CTextField.vue";
import TextField from "./textfield/TextField.vue";

import { setCurrency } from "@/utils";

export default {
  props: {
    value: { default: "" },
    relation: { type: Object },
    dependence: { type: Object },
    // percentual: { default: null },
    // maxPercentual: { default: "100,00" },
    allowedPattern: { default: null },
    name: { type: String, required: false },
    clear: { type: Boolean, default: false },
    notZero: { type: Boolean, default: false },
    notNull: { type: Boolean, default: false },
    fixed: { type: String, required: false, default: null },
  },
  components: {
    TextField,
    CTextField,
  },
  data: () => ({
    focused: false,
  }),
  computed: {
    listeners() {
      return omit(this.$listeners, [
        "change",
        "input",
        "focus",
        "blur",
        "keypress",
      ]);
    },
    component() {
      return this.clear ? CTextField : TextField;
    },
    precision() {
      if (this.fixed) return this.fixed;
      const configParamName = this.precisionType(this.name);
      return configParamName ? this[configParamName] : 2;
    },
  },
  mounted() {
    this.onInput(this.parseToBr(this.value));
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    /**
     * Função que determina o tipo da precisão
     */
    precisionType(name) {
      const rules = [
        (name) => /vlr_/.test(name) && "$vlrDecimalCases",
        (name) => /qtd_/.test(name) && "$qtdDecimalCases",
        (name) => /quantidade/.test(name) && "$qtdDecimalCases",
        (name) => /qts_/.test(name) && "$qtsDecimalCases",
      ];

      // Mapeia as regras
      const rule = map(rules, (test) => test(name));

      // Captura apenas a primeira que fez match
      return head(compact(rule));
    },
    /**
     * Toda vez que for digitado algum valor, emite para o componente pai atualizar na tela
     */
    onInput(value) {
      this.$emit("input", value.toString().replace(/'|`|"/g, ""));
    },
    onKeyPress(event) {
      // Se for numérico ou ',' ou '.' ou '-' retorna
      if (/^[0-9]$/i.test(event.key) || /\.|,|-/gi.test(event.key)) return;

      if (!isNull(this.allowedPattern)) {
        // Se permitir calculo em percentual e foi digitado um %, retorna
        if (this.allowedPattern.test(event.key)) return;
      }

      // Propaga o evento
      this.$emit("keypress", event);

      // Caso contrário, bloqueia a propagação do evento
      event.preventDefault();
    },
    isValueBr(value) {
      return (value ?? "").toString().includes(",");
    },
    handleEmpty() {
      if (this.notZero) return "0,01";
      if (this.notNull) return "0,00";
      return "";
    },
    /**
     * Função que transforma um float nativo em padrão brasileiro
     */
    parseToBr(value = "") {
      if (this.isValueBr(value)) return value;

      if (isNaN(parseFloat(value))) {
        return this.handleEmpty();
      }

      // return parseFloat(value.toString())
      //   .toFixed(this.precision)
      //   .replace(".", ",");

      return setCurrency(parseFloat(value.toString()), this.precision);
    },
    parseToFloat(value = "") {
      return parseFloat(
        (value ?? "").toString().replaceAll(".", "").replace(",", ".")
      );
    },
    /**
     * Função é chamada quando o valor do input mudou de um para outro diferente
     */
    onChanged(value, oldValue) {
      // Se o valor for em branco, persiste com o valor vazio
      if (!value) return this.change(this.handleEmpty(), oldValue);

      const hasTokens =
        !isNull(this.allowedPattern) && value.match(this.allowedPattern);

      const token = isArray(hasTokens) ? head(hasTokens) : "";

      // Captura o valor, substituindo a , por . (para o padrão nativo)
      const _value = this.parseToFloat(value);

      // Se o valor for zerado e não permitir zerado
      if (_value === 0.0 && this.notZero)
        // Retorna
        return this.change(this.handleEmpty(), oldValue);

      // Se não for um número válido, mantém o valor antigo
      if (isNaN(parseFloat(_value))) return this.change(oldValue, oldValue);

      // Retorna o valor digitado formatado no padão brasileiro
      return this.change(this.parseToBr(_value) + token, oldValue);
    },
    change(value, oldValue) {
      // Emite o evento para atualizar o componente pai
      this.$emit("input", value);

      // Emite o evento que atualizou o valor
      this.$emit("change", value, oldValue);
    },
  },
  watch: {
    value: {
      handler(value) {
        if (!this.isValueBr(value) && !this.focused) {
          this.onInput(this.parseToBr(value));
        }
      },
    },
  },
};
</script>
