<template>
  <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
    <v-row>
      <field-cpf-cnpj
        readonly
        sm="4"
        :persist="persist"
        :required="required | includes('cpf_cnpj')"
        @change="onCpfCnpjChange"
        v-model="data.cpf_cnpj"
      />
      <p-text-field
        sm="4"
        type="tel"
        maxlength="16"
        label="RG/IE"
        name="rg_ie"
        :persist="persist"
        :required="required | includes('rg_ie')"
        v-model="data.rg_ie"
      />
      <p-text-field
        sm="4"
        type="tel"
        label="IM"
        name="im"
        maxlength="18"
        :persist="persist"
        :required="required | includes('im')"
        v-model="data.im"
      />
      <p-text-field
        :auto-focus="autoFocus"
        md="6"
        maxlength="60"
        label="Razão Social"
        name="razao_social"
        :persist="persist"
        :required="required | includes('razao_social')"
        v-model="data.razao_social"
      />
      <p-text-field
        md="6"
        maxlength="60"
        label="Nome Fantasia"
        name="fantasia"
        :persist="persist"
        :required="required | includes('fantasia')"
        v-model="data.fantasia"
      />
      <p-text-field
        sm="6"
        md="3"
        maxlength="35"
        label="Responsável"
        name="contato"
        :persist="persist"
        :required="required | includes('contato')"
        v-model="data.contato"
      />
      <field-telefone
        sm="6"
        md="3"
        :persist="persist"
        :required="required | includes('telefone')"
        v-model="data.telefone"
      />
      <field-email
        md="6"
        maxlength="132"
        :persist="persist"
        :required="required | includes('email')"
        v-model="data.email"
      />
      <field-cep
        cols="6"
        sm="3"
        md="2"
        :persist="persist"
        :required="required | includes('cep')"
        @change="onCepChange"
        v-model="data.cep"
      />
      <p-select-uf
        value-not-null
        hide-ex
        sm="3"
        md="2"
        cols="6"
        maxlength="2"
        :persist="persist"
        :required="required | includes('uf')"
        @change="(uf) => $refs.municipios.read(uf)"
        v-model="data.uf"
      />
      <p-select-municipios
        sm="6"
        md="8"
        ref="municipios"
        :uf="data.uf"
        :persist="persist"
        :required="required | includes('municipio_id')"
        v-model="data.municipio_id"
      />
      <p-text-field
        sm="6"
        md="9"
        maxlength="35"
        label="Endereço"
        name="endereco"
        :persist="persist"
        :required="required | includes('endereco')"
        v-model="data.endereco"
      />
      <field-numero
        sm="6"
        md="3"
        maxlength="10"
        :persist="persist"
        :required="required | includes('numero')"
        v-model="data.numero"
      />
      <p-text-field
        sm="6"
        maxlength="20"
        label="Bairro"
        name="bairro"
        :persist="persist"
        :required="required | includes('bairro')"
        v-model="data.bairro"
      />
      <p-text-field
        sm="6"
        maxlength="20"
        label="Complemento"
        name="complemento"
        :persist="persist"
        :required="required | includes('complemento')"
        v-model="data.complemento"
      />
      <p-text-field
        sm="6"
        type="url"
        name="site"
        maxlength="35"
        label="Página"
        :persist="persist"
        :required="required | includes('site')"
        v-model="data.site"
      />
      <p-select-filter
        sm="6"
        name="crt"
        label="CRT"
        :items="crt"
        :persist="persist"
        :item-texts="['value', 'text']"
        :required="required | includes('crt')"
        v-model="data.crt"
      />
      <p-select-search
        use-local
        md="12"
        name="cnae"
        label="CNAE"
        source="cnae"
        item-value="cnae"
        :persist="persist"
        :target="['cnae', 'descricao']"
        :item-texts="['cnae', 'descricao']"
        :required="required | includes('cnae')"
        :extra="extra.cnae"
        v-model="data.cnae"
      />
      <p-switch
        name="mei"
        label="MEI - Micro Empreendedor Individual"
        v-if="
          data.habilitar_mei ||
          $appMode !== 'production' ||
          modulo === 'register'
        "
        :persist="persist"
        v-model="data.mei"
      />
    </v-row>
  </v-col>
</template>

<script>
import {
  PTextField,
  PSwitch,
  PSelectSearch,
  PSelectFilter,
  PSelectUf,
  PSelectMunicipios,
  FieldTelefone,
  FieldNumero,
  FieldCpfCnpj,
  FieldCep,
  FieldEmail,
} from "@/components/form";

// Mixins
import Ficha from "@/mixins/ficha";
import get from "lodash/get";
import toString from "lodash/toString";

export default Ficha.extend({
  props: {
    modulo: {},
    autoFocus: { default: true },
    required: { type: Array, default: () => ["razao_social"] },
    hidden: { type: Array, default: () => [""] },
    persist: { default: true },
    filterCrt: { type: String },
  },
  components: {
    PTextField,
    PSwitch,
    PSelectSearch,
    PSelectFilter,
    PSelectUf,
    PSelectMunicipios,
    FieldNumero,
    FieldTelefone,
    FieldCpfCnpj,
    FieldCep,
    FieldEmail,
  },
  methods: {
    onCpfCnpjChange(value) {
      value = toString(value).replaceAll(/[^0-9]/g, "");
      this.$emit("cpf-cnpj-change", value);
    },
    onCepChange(value) {
      value = toString(value).replaceAll(/[^0-9]/g, "");
      this.$emit("cep-change", value);
    },
    forceReloadMunicipios() {
      this.$refs.municipios.read(this.data.uf);
    },
  },
  computed: {
    crt() {
      const crt = [
        { text: "Simples Nacional", value: "1" },
        {
          text: "Simples Nacional - Excesso de Sublimite de Receita Bruta",
          value: "2",
        },
        { text: "Regime Normal", value: "3" },
      ];

      const fixed =
        get(this.data, "detalhes.emitente.crt") || get(this.data, "crt_fixo");

      return !fixed ? crt : crt.filter((it) => it.value === fixed);
    },
  },
});
</script>
