<template>
  <div>
    <div
      class="d-flex"
      :style="`border: rgba(0, 0, 0, 0.12) thin solid; width: ${width}px !important; height: ${height}px !important`"
      v-if="loading"
    >
      <v-progress-circular
        indeterminate
        class="ma-auto"
        color="primary"
        size="54"
      />
    </div>
    <v-img
      class="rounded-lg"
      :src="src"
      :width="width"
      :contain="contain"
      :height="height"
      @error="onError"
      v-bind="$attrs"
      v-else
    />
  </div>
</template>

<script>
import loadResource from "@/mixins/loadResource";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  extends: loadResource,
  props: {
    value: {},
    filename: {},
    nullImage: {},

    width: { default: 100 },
    height: { default: 100 },

    contain: { type: Boolean, default: true },
    hideDefault: { type: Boolean, default: false },
  },
  data: () => ({
    loading: true,
    internalValue: null,
  }),
  created() {
    this.init();
  },
  computed: {
    ...mapState({
      cachedImages: (state) => state.app.cachedImages,
    }),
    defaultImage() {
      return this.hideDefault
        ? null
        : this.nullImage ?? require("@/assets/imagem_indisponivel.svg");
    },
    src: {
      get() {
        return this.internalValue || this.value;
      },
      set(value) {
        this.value === undefined
          ? (this.internalValue = value)
          : this.$emit("input", value);
      },
    },
  },
  watch: {
    filename() {
      this.src = null;
      this.init();
    },
  },
  methods: {
    init() {
      if (this.src) {
        this.loading = false;
        return;
      }

      if (!this.filename) {
        this.loading = false;
        return (this.src = this.defaultImage);
      }

      const cached = this.cachedImages[this.filename];
      if (cached) {
        this.loading = false;
        return (this.src = cached);
      }

      this.loadResource(this.filename)
        .then((resource) => (this.src = resource || this.defaultImage))
        .finally(() => {
          this.loading = false;
        });
    },
    onError() {
      this.src = this.defaultImage;
      if (this.filename) {
        this.uncacheImage(this.filename);
      }

      this.$nextTick(() => {
        this.loading = false;
      });
    },
  },
});
</script>
