<template>
  <clifor-select
    label="Intermediador"
    name="intermediador_id"
    :valueNotNull="false"
    :filter="intermediadorFilter"
    :value="value"
    @input="(value) => $emit('input', value)"
    v-bind="$attrs"
  />
</template>

<script>
import includes from "lodash/includes";
import CliforSelect from "./CliforSelect.vue";

export default {
  components: { CliforSelect },
  props: {
    value: {},
  },
  computed: {
    intermediadorFilter() {
      return [
        [
          (item) =>
            item && includes(item.clifor_tipos, "Intermediador") && item.ativo,
        ],
      ];
    },
  },
};
</script>
