import get from "lodash/get";

import actions from "@/store/actions";
import mutations from "@/store/mutations";

export default {
  actions: {
    /**
     * Função que salva o certificado digital do emitente
     */
    [actions.CONFIG.NFE.CERTIFICATE]({ commit, dispatch, rootState }, props) {
      // Captura o certificado e a senha
      const { certificate, password } = props;

      // Dispara a ação de upload de arquivo
      return dispatch(actions.MODULO.FICHA.UPDATE_FILE, {
        // Nome do arquivo para ser identificado
        name: "certificate",
        // Arquivo
        data: certificate,
        // Tipo do certificado
        mimeType: certificate && certificate.type,
        modulo: "nfe",
        id: get(rootState, "app.emitente.id"),

        // Dados extras que serão incluídos no FormData
        password,
      }).then((response) => {
        const text = get(response, "data.message");
        const config = get(response, "data.config");

        if (config) {
          commit(mutations.APP.CONFIG, config);
        }

        if (text)
          commit(mutations.APP.SNACKBAR, {
            text,
            active: true,
          });

        return response;
      });
    },
  },
};
