// Importa o cliente de requisições
import axios, { AxiosInstance } from "axios";

// Importa o transmissor de eventos globais
import { EventBus } from "@/main";
import get from "lodash/get";
import pick from "lodash/pick";

// Define as headers padrões do axios
axios.defaults.headers.common = {
  // Tipo da requisição
  "X-Requested-With": "XMLHttpRequest",
  // Aceita
  Accept: "application/json",
  // Tipo do conteúdo
  "Content-Type": "application/json",
};

// Define o interceptador de requisição do AXIOS
axios.interceptors.request.use((config: any) => {
  // se nao houver headers default, seta default
  if (!config.headers) config.headers = {};

  // Define a url base da api (manda para /api e depois é redirecionado através dos proxies)
  config.baseURL = config.baseURL ?? `${window.location.origin}/api`;

  // Captura o socket id
  const sid = window.socketId;

  // Se houver id
  if (sid) {
    // adiciona o id no cabeçalho
    config.headers["X-Socket-ID"] = sid;
  }

  // captura o emitente
  const emitente = get(config, "defaultSessionParams.emitente")?.();

  // se houver emitente
  if (emitente) {
    // captura as configs do frente
    const frente = JSON.parse(
      window.localStorage.getItem(`frente.${emitente.id}`) ?? "{}"
    );

    // Captura o socket id
    const terminal = get(frente, "acesso_terminal");

    // valida se usa o sat
    config.headers["X-CFe-SAT"] = get(frente, "cfe_sat", "false");

    // Se houver id
    if (terminal) {
      // adiciona o id no cabeçalho
      config.headers["terminal-auth-token"] = terminal;
    }
  }

  // Retorna o objeto de config atualizado
  return config;
});

// Mapeia os tipos de requisições do axios
const types = {
  // POST (CREATE)
  post: axios.post,
  // Get (READ)
  get: axios.get,
  // Put (UPDATE)
  put: axios.put,
  // DELETE (DELETE)
  delete: axios.delete,
};

const delay = 800;

/**
 * @copyright Smallsoft
 * @author Gustavo Lidani <lidanig0@gmail.com>
 */
class API {
  /**
   * @var {any} loading Estado de controle das requisições
   */
  loading = {
    delay,
    state: "",
    mutation: "",
  };

  /**
   * @var {any} defaultOptions Opções default para a requisição
   */
  defaultOptions = {
    withCredentials: true,

    /**
     * Informações out scope do axios, por exemplo o emitente
     */
    defaultSessionParams: {},
  };

  /**
   * @var {*} timeout Timeout
   */
  timeout = null;

  /**
   * @var {Store} $store Referência da Store do VueX
   */
  $store?: any;

  /**
   * Função estática que cria uma instância nova com as opções definidas
   * @param {any} options Opções de configuração das requisições
   */
  static config(options: any = {}) {
    // Cria uma instância nova
    const instance = new this();
    // Seta as opções
    instance.loading = { state: "", mutation: "", delay, ...options };
    // Retorna a instaância configurada
    return instance;
  }

  /**
   * Função que seta a store
   * @param {any} store Referência da Store do VueX
   */
  store(store) {
    // Seta a store
    this.$store = store;

    const emitente = () => {
      return get(
        store,
        "rootState.app.emitente",
        get(store, "state.app.emitente", {})
      );
    };

    this.defaultOptions.defaultSessionParams = {
      ...this.defaultOptions.defaultSessionParams,
      emitente,
    };

    // Retorna a instância
    return this;
  }

  /**
   * Função que mapeia e faz o uso da função correta da requisição
   *
   * @param {String} method Método da requisição (post|get|put|delete)
   * @param {String} url Caminho da requisição
   * @param {any} data Corpo|Params da requisição
   * @param {any} options Params|Options da requisição
   */
  request(method, url, data, options = {}) {
    // Desestrutura os parâmetros da classe
    const { loading, handleError, $store } = this;

    // Atribui ao timeout
    const timeout = setTimeout(() => {
      if ($store && loading.mutation)
        // Faz o commit do loading true
        $store.commit(loading.mutation, true);
    }, loading.delay);

    // Retorna uma nova promessa de requisição
    return (
      // Acessa o método corretamente
      types[method](url, data || {}, options)
        .then((response) => {
          if (get(response, "data.warning")) {
            EventBus.$emit("snackbar", {
              active: true,
              text: get(response, "data.warning"),
              color: "info",
            });
          } else if (
            get(response, "data.success") ||
            get(response, "data.message")
          ) {
            EventBus.$emit("snackbar", {
              active: true,
              text:
                get(response, "data.success") || get(response, "data.message"),
              color: "success",
            });
          } else if (get(response, "data.error")) {
            EventBus.$emit("snackbar", {
              active: true,
              text: get(response, "data.error"),
              color: "info",
            });
          }

          return response;
        })
        // Caso erro
        .catch(async (error) => {
          // Passa pela rotina padrão de tratamento de erros
          return Promise.reject(await handleError(error));
        })
        // Depois de passar pelo then/catch
        .finally(() => {
          // Limpa o timeout
          clearTimeout(timeout);

          // Aguarda o próximo tick
          setTimeout(() => {
            // Se houver store e loading
            if ($store && loading.mutation) {
              // Caso estiver carregando, quer dizer que a requisição demorou mais que o delay
              // if ($store.state[loading.state] === true) {
              // Commita loading false
              $store.commit(loading.mutation, false);
              // }
            }
          });
        })
    );
  }

  /**
   * Função que envia uma requisiçao POST para a url informada
   *
   * @param {String} url Caminho da requisição
   * @param {any} data Corpo da requisição
   * @param {any} options Config da requisição
   */
  post(url, data = {}, options = {}) {
    return this.request("post", url, data, {
      ...this.defaultOptions,
      ...options,
    });
  }

  /**
   * Função que envia uma requisiçao GET para a url informada
   *
   * @param {String} url Caminho da requisição
   * @param {any} options Config da requisição
   */
  get(url, options = {}) {
    return this.request("get", url, { ...this.defaultOptions, ...options }, {});
  }

  /**
   * Função que envia uma requisiçao PUT para a url informada
   *
   * @param {String} url Caminho da requisição
   * @param {any} data Corpo da requisição
   * @param {any} options Config da requisição
   */
  put(url, data = {}, options = {}) {
    return this.request("put", url, data, {
      ...this.defaultOptions,
      ...options,
    });
  }

  /**
   * Função que envia uma requisiçao DELETE para a url informada
   *
   * @param {String} url Caminho da requisição
   * @param {any} options Config da requisição
   */
  delete(url, options = {}) {
    return this.request(
      "delete",
      url,
      { ...this.defaultOptions, ...options },
      {}
    );
  }

  /** Função que faz o tratamento padrão dos erros HTTP */
  async handleError(error: any) {
    // Faz o log do erro
    window.error("Erro tratado pelo handleError", error.response || error);

    const status = get(error, "response.status");

    let data = get(error, "response.data");

    if (get(error, "response.config.responseType") === "arraybuffer") {
      const blob = new Blob([data], {
        type: "text",
      });

      data = await blob.text().then((data) => {
        try {
          return JSON.parse(data);
        } catch {
          return {};
        }
      });
    }

    if (
      status === 401 &&
      get(error, "response.config.url") !== "/broadcasting/auth"
    ) {
      return EventBus.$emit("unauthenticated"), error;
    }

    if (status === 503) {
      return EventBus.$emit("unauthenticated"), error;
    }

    const e = {
      ...pick(data, ["detail", "help", "title", "errors"]),
      active: true,
      type: "error",
      message: get(data, "message"),
    };

    const isResponseError = data !== null;
    if (!e.message && isResponseError) return error;

    return (
      EventBus.$emit(e.help || e.detail || e.errors ? "alert" : "snackbar", {
        ...e,
        text: e.message ?? get(error, "message", "Erro desconhecido"),
        color: e.type,
      }),
      error
    );
  }
}

// Exporta a classe
export default API;

declare module "vue" {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
    $api: API;
  }
}

export const plugin = {
  install: (app, { store }) => {
    app.prototype.$api = new API().store(store);
    app.prototype.$axios = axios;
  },
};
