import get from "lodash/get";

import API from "@/api";
import actions from "@/store/actions";
import mutations from "@/store/mutations";

const api = API.config();

const initialState = {
  loading: false,
};

export default {
  state: Object.assign({}, initialState),

  mutations: {
    /**
     * Mutação de loading
     */
    [mutations.EXPORT.LOADING](state, loading) {
      state.loading = loading;
    },
  },

  actions: {
    /**
     * Função que importa o XML de compra
     */
    [actions.EXPORT.XML]({ commit }, params) {
      const isArrayBuffer = get(params, "choose") === "download";
      const { modulo, ...rest } = params;

      // Retorna a promessa da requisição
      return api
        .post(`${modulo}/export-xml`, rest, {
          responseType: isArrayBuffer ? "arraybuffer" : null,
        })
        .then((response) => {
          if (isArrayBuffer) {
            // Captura o type
            const type = response.headers["content-type"];
            // captura o nome do arquivo de download
            const filename = response.headers["x-filename"];

            // monta o blob
            const blob = new Blob([response.data], {
              type: type,
              // encoding: "UTF-8"
            });

            // Abre o blob para download
            window.openDownload(blob, filename);
          }

          // caso json
          else {
            const text = get(response, "data.message");

            if (text) {
              commit(mutations.APP.SNACKBAR, {
                active: true,
                text,
              });
            }
          }

          // Propaga a resposta
          return response;
        })
        .catch((error) => {
          if (isArrayBuffer) {
            const data = get(error, "response.data");

            // monta o blob
            const blob = new Blob([data], {
              type: "text",
              // encoding: "UTF-8"
            });

            blob
              .text()
              .then((data) => JSON.parse(data))
              .then((data) => {
                const text = get(data, "message");

                if (text)
                  return commit(mutations.APP.SNACKBAR, {
                    active: true,
                    color: "error",
                    text,
                  });
              });
          }

          //
          else {
            const text = get(error, "response.data.message");

            if (text) {
              commit(mutations.APP.SNACKBAR, {
                active: true,
                color: "error",
                text,
              });
            }
          }
        });
    },

    /**
     * Função que dispara o GET para a API
     */
    [actions.EXPORT.DATA]({}, params) {
      // Retorna a promessa da requisição
      return api.post("/export-all-my-data", params, {
        responseType: "arraybuffer",
      });
    },
  },
};
