<template>
  <v-btn
    ref="btn"
    :class="btnClass"
    :small="size === 'small'"
    :x-small="size === 'x-small'"
    :color="color"
    :id="`btn-${_uid}`"
    @click="onClick"
    @keydown="onKeyDown"
    v-bind="$attrs"
    v-show="!hidden"
  >
    <slot />
  </v-btn>
</template>

<script>
import Vue from "vue";
import { findIndex, includes } from "lodash";

export default Vue.extend({
  props: {
    color: { default: "primary" },
    tooltip: { type: String, default: null },
    autoFocus: { type: Boolean, default: false },
    size: { default: "small" },

    hidden: { type: Boolean, default: false },
    btnClass: { default: false },

    bottom: { type: Boolean, default: true },
    top: { type: Boolean, default: false },
  },

  methods: {
    onClick(event) {
      this.$emit("click", event);
    },
    onKeyDown(event) {
      if (!includes(["ArrowLeft", "ArrowRight"], event.key)) return;
      return this.focusNearButton(event.key === "ArrowLeft" ? -1 : 1);
    },
    focusNearButton(direction) {
      let parent = this.$parent;

      if (includes(["close-button", "v-menu"], parent.$options._componentTag)) {
        parent = parent.$parent;
      }

      const buttons = parent.$el.querySelectorAll(
        `button.v-btn:not([readonly]):not([disabled])`
      );

      const currentIndex = findIndex(
        buttons,
        (btn) => btn.id === `btn-${this._uid}`
      );

      const button = buttons[currentIndex + direction];

      if (button) {
        button.focus();
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.autoFocus && this.$refs.btn && this.$refs.btn.$el.focus();
    }, 40);
  },
});
</script>
