<template>
  <v-col cols="12" md="8" offset-md="2" v-bind="$attrs">
    <v-row>
      <v-col>
        <btn
          class="mr-2"
          :disabled="disabled || !meios.length || nothingToReceive"
          :loading="loading"
          @click="onInsertPayment"
          >Inserir Pagamento</btn
        >
        <btn
          text
          :disabled="loading || disabled || !meios.length || !pagamento"
          @click="onUpdatePayment"
          >Alterar Pagamento</btn
        >
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col style="height: 300px">
        <data-table
          selectable
          hide-empty-row
          v-model="selected"
          :headers="headers"
          :items="pagamentos"
        >
          <template #footer-content>
            <h5>Valor a Pagar: R$ {{ data.vlr_pagamento_restante }}</h5>
          </template>
        </data-table>
      </v-col>
    </v-row>

    <component
      :data="data"
      :__ficha="__ficha"
      :is="activeComponent"
      @close="onActiveComponentClose"
      v-bind="activeComponentProps"
      v-if="activeComponent"
    />
  </v-col>
</template>

<script>
import get from "lodash/get";

import DataTable from "@/components/table/DataTable.vue";
import Btn from "@/components/form/buttons/Btn.vue";

import actions from "@/store/actions";
import { clearCurrency } from "@/utils";

export default {
  components: {
    Btn,
    DataTable,
  },
  props: {
    __ficha: {},

    data: { default: () => ({}) },
    disabled: { default: false, type: Boolean },
    autoFocus: { default: false, type: Boolean },
  },
  data: () => ({
    loading: true,
    meios: [],
    selected: {},

    activeComponentProps: {},
    activeComponent: null,
  }),
  methods: {
    onInsertPayment() {
      this.activeComponentProps = {
        ...this.activeComponentProps,
        formas: this.meios,
        onFinish: this.onActiveComponentClose,
      };

      this.$nextTick(() =>
        import(
          "@/components/popups/modulos/cupons-fiscais/InserirFormaPagamento.vue"
        ).then((component) => {
          this.activeComponent = component.default;
        })
      );
    },
    onUpdatePayment() {
      this.activeComponentProps = {
        initialPagamento: this.pagamento,
      };

      return this.onInsertPayment();
    },
    onActiveComponentClose() {
      this.activeComponentProps = {};
      this.activeComponent = null;
    },
  },
  created() {
    this.$store
      .dispatch(actions.CORE.READ, {
        path: "meios-pagamentos",
        type: "registros",
        orderBy: "ordem",
        ativo: true,
      })
      .then((data) => {
        this.meios = data.registros;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    headers() {
      return [
        {
          text: "Pagamento",
          type: "json",
          value: "forma.descricao",
        },
        { text: "Valor", value: "valor" },
      ];
    },
    pagamentos() {
      return get(this.data, "pagamentos") ?? [];
    },

    pagamento() {
      return this.pagamentos.find(
        (it) => it.id === get(this.selected, "registro.id")
      );
    },

    modulo() {
      return get(this.__ficha, "modulo");
    },

    nothingToReceive() {
      return clearCurrency(this.data.vlr_pagamento_restante) <= 0;
    },
  },
};
</script>
