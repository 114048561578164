<template>
  <div style="display: inline-block">
    <v-menu
      top
      left
      offset-y
      :disabled="!$menuFiscalEnabled"
      @input="onCloseMenu"
    >
      <template #activator="{ on, attrs }">
        <sale-btn
          descricao="Menu Fiscal"
          color="saleGrey"
          v-if="$menuFiscalEnabled"
          v-on="on"
          v-bind="attrs"
        />
      </template>

      <v-list dense>
        <v-list-item
          dense
          style="min-height: 35px !important"
          :key="i"
          @click="() => onClickReport(rel)"
          v-for="(rel, i) in pafReports"
        >
          <!-- <v-list-item-avatar class="ma-0" style="height: 35px !important">
            {{ rel.params.id.toUpperCase() }}
          </v-list-item-avatar> -->

          <v-list-item-title>
            {{ rel.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <report-paf :report="report" @input="onCloseDialog" v-if="report" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import SaleBtn from "@/components/form/cupons/SaleBtn.vue";
import ReportPAF from "./ReportPAF";

export default defineComponent({
  components: { SaleBtn, "report-paf": ReportPAF },
  data: () => ({ report: null }),
  computed: {
    pafReports() {
      return [
        {
          params: { id: "identificacao-paf-nfce" },
          label: "Identificação do PAF-NFC-e",
        },
        {
          params: { id: "i", type: "total" },
          label: "Registros do PAF-NFC-e (Estoque Total)",
        },
        {
          params: { id: "i", type: "parcial" },
          label: "Registros do PAF-NFC-e (Estoque Parcial)",
        },
        { params: { id: "ii" }, label: "Saídas Identificadas pelo CPF/CNPJ" },
        { params: { id: "iv" }, label: "Controle dos DAV" },
      ];
    },
  },
  methods: {
    onCloseMenu(val) {
      if (val === true) return;
      this.$emit("close:menu");
    },
    onCloseDialog(val) {
      if (val === true) return;
      this.report = null;
      this.$emit("close:dialog");
    },
    onClickReport(report) {
      this.$emit("open:dialog");
      return (this.report = report);
    },
  },
});
</script>
