<template>
  <combobox
    ref="combobox"
    v-bind="$attrs"
    :loading="awaiting"
    @change="onChanged"
    v-on="$listeners"
  />
</template>

<script>
import Combobox from "../inputs/Combobox.vue";
import persistent from "./persistent";

export default persistent.extend({
  components: { Combobox },
  props: {
    combobox: { default: true, type: Boolean },
  },
});
</script>
