import { PropType } from "vue";
import merge from "lodash/merge";

import MainMixin from "@/mixin";

export default MainMixin.extend({
  props: {
    name: { type: String, required: true },
    saveAs: { type: String, required: false },
    // TODO: remove prop below
    combobox: { type: Boolean, default: false },
    emitAsValue: { type: Boolean, default: false },
    persist: { type: Boolean, default: true },
    dependence: { type: Object },
    event: { type: Object, default: () => ({}) },
    modulo: { default: null },
    relation: { type: Object },

    beforeChanged: {
      required: false,
      type: Function as PropType<
        // eslint-disable-next-line no-unused-vars
        (value: any, oldValue: any, instance: Vue) => Promise<any>
      >,
      default: (value) => value,
    },
    afterChanged: {
      required: false,
      type: Function as PropType<
        // eslint-disable-next-line no-unused-vars
        (value: any, oldValue: any, instance: Vue) => Promise<any>
      >,
      default: (value) => value,
    },
    onError: {
      required: false,
      type: Function as PropType<
        // eslint-disable-next-line no-unused-vars
        (error: any, instance: Vue) => void
      >,
      default: () => null,
    },
  },
  computed: {
    cEvent(): any {
      return merge(this.event, this.event_);
    },
  },
  data: () => ({
    event_: {},
    awaiting: false,
  }),
  methods: {
    /**
     * Função que seta um evento custom
     * @param event any
     */
    setEvent(event: any): void {
      this.event_ = event;
    },
    /**
     * Função é chamada quando o valor do input mudou de um para outro diferente
     */
    async onChanged(value: any, oldValue: any, name = ""): Promise<void> {
      try {
        this.awaiting = true;
        // executa o hook de before change
        value = await this.beforeChanged(value, oldValue, this);

        // Emite o evento para atualizar o componente pai
        this.$emit("input", value);

        // Emite o evento que atualizou o valor
        this.$emit("change", value, oldValue);

        // se não for persistir, retorna
        if (!this.persist) return;

        // aguarda a persistencia dos dados
        return await this.save(value, oldValue, name);
      } catch (error: any) {
        console.error(error);
        // Chama o callback de error
        this.onError(error, this);
        this.$emit("input", oldValue);
      } finally {
        this.awaiting = false;
      }
    },
    /**
     * Função que envia a requisição de persistir
     */
    async save(value: any, oldValue: any = null, name = ""): Promise<void> {
      name ||= this.saveAs ?? this.name;
      value =
        typeof value === "object" && this.emitAsValue && value !== null
          ? value.id
          : value;

      // define o evento
      const event = merge(
        {
          data: { [name]: value },
          dependence: this.dependence,
          modulo: this.modulo,
          relation: this.relation,
        },
        this.cEvent
      );

      // Tenta buscar a ficha que está renderizando esse componente persistente
      const ficha = this.$findComponentByName("Ficha") as Ficha | undefined;

      // Se não encontrar a ficha
      if (!ficha) throw new Error("Ficha indevidamente configurada");

      // se houver a ficha
      const response = await ficha.onChangePersistent(event);

      // executa o hook de after change
      await this.afterChanged(value, oldValue, this);

      // retorna a resposta
      return response;
    },
  },
});
