/**
 * Objeto com o mapeamento das Mutações
 */
export default {
  // App Mutations
  APP: {
    STATS: "APP_STATS",
    MODULES: "APP_MODULES",
    PENDING_DOWNLOAD: "APP_PENDING_DOWNLOAD",
    NEW_VERSION: "APP_NEW_VERSION",
    BLOCKED: "APP_BLOCKED",
    // Mutação do usuário
    USER: "APP_USER",
    // Mutação do Emitente
    TOGGLE_USE_SAT: "APP_TOGGLE_USE_SAT",
    EMITENTE: "APP_EMITENTE",
    // Mutação do config do emitente
    CONFIG: "APP_CONFIG",
    INFO: "APP_INFO",
    // Mutação de Loading da aplicação
    LOADING: "APP_LOADING",
    // Mutação de cache do usuário
    CACHE: "APP_CACHE",
    // Mutação de permissão do usuário
    PERMISSIONS: "APP_PERMISSIONS",

    // Alerta da aplicação
    ALERT: "APP_ALERT",
    // Snackbar da aplicação
    SNACKBAR: "APP_SNACKBAR",
    // Assitente da aplicação
    ASSISTENT: "APP_ASSISTENT",
    DISPLAY_MODE: "APP_DISPLAY_MODE",
    CACHED_IMAGE: "APP_CACHED_IMAGE",
  },

  // Modulo Mutations
  MODULO: {
    // Mutação de módulo liberado ou bloqueado
    LOCKED: "MODULO_LOCKED",

    // *Module* Registros
    REGISTROS: {
      // Lista de registros
      DATA: "MODULO_REGISTROS_DATA",
      APPEND_DATA: "MODULO_REGISTROS_APPEND_DATA",
      APPEND_DATA_DIRECTION: "MODULO_REGISTROS_APPEND_DATA_DIRECTION",
      UNSHIFT_DATA: "MODULO_REGISTROS_UNSHIFT_DATA",
      // Limpa a lista de registros
      CLEAR_DATA: "MODULO_REGISTROS_CLEAR_DATA",

      // Modulo carregado ou não
      LOADED: "MODULO_REGISTROS_LOADED",

      FETCHING: "MODULO_REGISTROS_FETCHING",
      // Registro criado
      CREATED: "MODULO_REGISTROS_CREATED",
      // Registro alterado
      UPDATED: "MODULO_REGISTROS_UPDATED",
      // Registro apagado
      DELETED: "MODULO_REGISTROS_DELETED",
    },

    // Modulos persistentes
    PERSISTENT: {
      // Carregamento dos itens
      LOADING: "MODULO_PERSISTENT_LOADING",
      PAGINATOR: "MODULO_PERSISTENT_PAGINATOR",
      DATA: "MODULO_PERSISTENT_DATA",
      UPDATED: "MODULO_PERSISTENT_UPDATED",
      DELETED: "MODULO_PERSISTENT_DELETED",
    },

    // *Module* ficha
    FICHA: {
      FETCHING: "MODULO_FICHA_FETCHING",
    },

    // Status bar do módulo
    STATUSBAR: "MODULO_STATUSBAR",
  },

  MODULOS: {
    ESTOQUE: {
      LOADING: "MODULOS_ESTOQUE_LOADING",
    },

    ENTRADA: {
      LOADING: "MODULOS_ENTRADA_LOADING",
    },

    PAYMENTS: {
      LOADING: "MODULOS_PAYMENTS_LOADING",
    },

    CONTAS: {
      LOADING: "MODULOS_CONTAS_LOADING",
    },
  },

  REGISTER: {
    LOADING: "REGISTER_LOADING",
  },

  DFE: {
    SENDING: "DFE_SENDING",
  },

  EXPORT: {
    LOADING: "EXPORT_LOADING",
  },

  IMPORT: {
    LOADING: "IMPORT_LOADING",
  },

  // Reports
  REPORTS: {
    LOADING: "REPORTS_LOADING",
    DATA: "REPORTS_DATA",
    COUNT: "REPORTS_COUNT",
    SELECTED: "REPORTS_SELECTED",
    REPORT: "REPORTS_REPORT",
    CLEAR_REPORT: "REPORTS_CLEAR_REPORT",
  },

  // Notifications Mutations
  NOTIFICATION: {
    DATA: "NOTIFICATION_DATA",
    CLEAR: "NOTIFICATION_CLEAR",
  },
};
