/**
 * Colunas do Grid
 */
export const columns = [
  { text: "Natureza", value: "descricao" },
  { text: "CFOP", value: "cfop" },
  { text: "ST", value: "st" },
  // { text: "CSOSN", value: "csosn" },
  // { text: "CST", value: "cst" },
  // { text: "CST Pis Cofins", value: "cst_pis_cofins" },
  { text: "Plano de contas", value: "plano_conta_descricao" },
  { text: "Observações", value: "obs" },
  // { text: "% Base ISS", value: "per_bc_iss" },
  // { text: "% ISS", value: "per_iss" },
  // { text: "% Base ICMS", value: "per_bc_icms" },
  // { text: "% PIS", value: "per_pis" },
  // { text: "% Base PIS", value: "per_bc_pis" },
  // { text: "% COFINS", value: "per_cofins" },
  // { text: "% Base COFINS", value: "per_bc_cofins" },
  { id: "pICMS", text: "% ICMS AM", value: "AM", hidden: true },
  { id: "pICMS", text: "% ICMS AC", value: "AC", hidden: true },
  { id: "pICMS", text: "% ICMS AL", value: "AL", hidden: true },
  { id: "pICMS", text: "% ICMS AP", value: "AP", hidden: true },
  { id: "pICMS", text: "% ICMS BA", value: "BA", hidden: true },
  { id: "pICMS", text: "% ICMS CE", value: "CE", hidden: true },
  { id: "pICMS", text: "% ICMS DF", value: "DF", hidden: true },
  { id: "pICMS", text: "% ICMS ES", value: "ES", hidden: true },
  { id: "pICMS", text: "% ICMS GO", value: "GO", hidden: true },
  { id: "pICMS", text: "% ICMS MA", value: "MA", hidden: true },
  { id: "pICMS", text: "% ICMS MG", value: "MG", hidden: true },
  { id: "pICMS", text: "% ICMS MT", value: "MT", hidden: true },
  { id: "pICMS", text: "% ICMS MS", value: "MS", hidden: true },
  { id: "pICMS", text: "% ICMS PB", value: "PB", hidden: true },
  { id: "pICMS", text: "% ICMS PE", value: "PE", hidden: true },
  { id: "pICMS", text: "% ICMS PI", value: "PI", hidden: true },
  { id: "pICMS", text: "% ICMS PR", value: "PR", hidden: true },
  { id: "pICMS", text: "% ICMS RJ", value: "RJ", hidden: true },
  { id: "pICMS", text: "% ICMS RN", value: "RN", hidden: true },
  { id: "pICMS", text: "% ICMS RO", value: "RO", hidden: true },
  { id: "pICMS", text: "% ICMS RR", value: "RR", hidden: true },
  { id: "pICMS", text: "% ICMS RS", value: "RS", hidden: true },
  { id: "pICMS", text: "% ICMS SC", value: "SC", hidden: true },
  { id: "pICMS", text: "% ICMS SE", value: "SE", hidden: true },
  { id: "pICMS", text: "% ICMS SP", value: "SP", hidden: true },
  { id: "pICMS", text: "% ICMS TO", value: "TO", hidden: true },
  { id: "pICMS", text: "% ICMS EX", value: "EX", hidden: true },
];

/**
 * Tabs da ficha
 */
export const tabs = [
  { item: "tab-principal", title: "Principal" },
  { item: "tab-icms", title: "ICMS UF", disabled: (data) => !data.id },
  {
    item: "tab-preferencias",
    title: "Preferências",
    disabled: (data) => !data.id,
  },
  { item: "tab-tags", title: "Tags", disabled: (data) => !data.id },
];

/**
 * Ficha default
 */
export const ficha = () => ({
  icms: {
    pBC: 0,
  },
  issqn: {
    pBC: 0,
    vAliq: 0,
  },
  preferencias: {
    alterar_quantidade_estoque: true,
  },
  pis: {},
  cofins: {},
});

export const forceUpdateKeys = ["per_icms"];

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = columns.filter((column) =>
  ["descricao", "cfop", "st", "plano_conta_descricao"].includes(column.value)
);

/**
 * Cache dafult
 */
export const cache = {
  colunas: ["descricao", "cfop", "st", "plano_conta_descricao"],
  filtros: [],
  ordenar: [
    {
      coluna: "descricao",
      ordem: "desc",
    },
  ],
};

/**
 * Valores da tabela de Tags
 */
export const tagsTabValues = [
  {
    nome: "idDest",
    exemplo: "1",
    descricao:
      "Identificador de local de destino da operação: (1=Operação interna; 2=Operação interestadual;)",
  },
  {
    nome: "pDif",
    exemplo: "33.3333",
    descricao: "Percentual do diferimento - tag usada no grupo do CST51",
  },
  // {
  //   nome: "BCST",
  //   exemplo: "100.00",
  //   descricao: "BC do ICMS ST",
  // },
  {
    nome: "pAprovCred",
    exemplo: "2.82",
    descricao: "Alíquota do Aproveitamento de Crédito de ICMS",
  },
  {
    nome: "vAprovCred",
    exemplo: "5.00",
    descricao:
      "Valor do Aproveitamento de Crédito de ICMS. Obs: Deixar em branco para o Sistema calcular",
  },
  {
    nome: "vTotTrib",
    exemplo: "N",
    descricao:
      "Preencha com o caractere 'N' (sem aspas) se desejar ocultar a tag vTotTrib do item, apague o conteúdo do campo para o sistema calcular e informar a tag",
  },
];

/**
 * Colunas da tabela de Tags
 */
export const tagsTabHeaders = [
  {
    text: "Nome",
    value: "nome",
    noEditable: true,
    maxWidth: "120px",
  },
  {
    text: "Valor",
    value: "valor",
    maxWidth: "120px",
  },
  {
    text: "Exemplo",
    value: "exemplo",
    noEditable: true,
    maxWidth: "120px",
  },
  {
    text: "Descrição",
    value: "descricao",
    noEditable: true,
    width: "550px",
  },
];
