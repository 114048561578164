import { capitalize, get, pick } from "lodash";

import API from "@/api";
import actions from "@/store/actions";
import mutations from "@/store/mutations";
import { EventBus } from "@/main";
import { base64ToArrayBuffer, printPDF } from "@/utils/print";

const prefix = "/documentos-fiscais";

const api = API.config();

const initialState = {
  sending: false,
};

export default {
  state: Object.assign({}, initialState),

  mutations: {
    /**
     * Mutação de loading de alguma operação
     * @param {*} state
     * @param {*} sending
     */
    [mutations.DFE.SENDING](state, sending) {
      state.sending = sending;
    },
  },

  actions: {
    /**
     * Função que gerencia todas as requisições de NF-e, para aproveitar as rotinas em comum
     *
     * Commitar sending no inicio e no fim de cada requisição
     *
     * then: Se houver message, exibir. Se houver registro, commitar no Grid
     *
     * error: Se houver message, mostrar
     *
     * finally: Commits de sending e status
     */
    async [actions.DFE.HANDLE]({ commit, state }, props) {
      // se já estiver enviando, cancela
      if (state.sending) return;

      // Commita sending true
      commit(mutations.DFE.SENDING, true);

      // Captura as informações usadas de props
      const {
        promise,
        // errorTitle,
        // appendErrorMessage,
        // ignoreMessage,
        document,
      } = props;

      let _response;

      // Tenta
      try {
        // Espera a função principal ser executada, e recupera a resposta da mesma
        const response = await promise();

        if (!response) return;

        _response = response;

        // Desestrutura data da resposta
        const { data } = response;

        // Se houver timeout, remove
        if (window.cfe_sat_timeout) clearTimeout(window.cfe_sat_timeout);

        // Se houver data
        if (data) {
          // Se houver mensagem
          if (data.message)
            // Commita para snackbar
            commit(mutations.APP.SNACKBAR, {
              active: true,
              text: data.message,
            });

          // Se houver registro
          if (data.registro) {
            // Commita o registro no Grid
            commit(mutations.MODULO.REGISTROS.UPDATED, data.registro);

            // Commita o registro na Ficha
            // commit(mutations.MODULO.FICHA.APPEND_DATA, data.registro);
          }
        }

        // Continua com a propagação do sucesso
        return response;
      } catch (error: any) {
        // Se não houver resposta no erro, continua com o erro
        if (!error || !error.response) {
          throw error || new Error("Erro desconhecido");
        }

        // Desestrutura data da resposta
        const { data } = error.response;

        // Se houver data e registro
        if (data && data.registro) {
          // Commita o registro no grid
          commit(mutations.MODULO.REGISTROS.UPDATED, data.registro);

          // Commita o registro na Ficha
          // commit(mutations.MODULO.FICHA.APPEND_DATA, data.registro);
        }

        // Captura a mensagem, concatenando com append, se houver
        // const message =
        //   ((data ? data.message : error) ?? "") + (appendErrorMessage ?? "");

        // const message = get(error, "response.data.message", error) ?? "";

        // // Se não for pra ignorar
        // if (!ignoreMessage) {
        //   // Faz o commit do alerta
        //   commit(mutations.APP.ALERT, {
        //     // Mensagem do erro
        //     message:
        //       message + (appendErrorMessage ?? "") || "Erro desconhecido",
        //     type: "error",
        //     // Títul do erro
        //     title: errorTitle,
        //     // State de mostrar o alerta
        //     active: true,
        //   });
        // }

        // Manda o erro para as promessas seguintes
        throw error;
      } finally {
        // Verifica se foi um sat
        const isSat =
          get(_response, "headers.x-cfe-sat", "false") === "true" &&
          document === "cfe-sat";

        // Caso não seja CFe-SAT
        if (!isSat) {
          // Commitar sending false
          commit(mutations.DFE.SENDING, false);
          // Commitar a statusbar para null (remover o status do rodapé)
          commit(mutations.MODULO.STATUSBAR, null);
        }

        // caso for sat
        else {
          // Aguarda 1 minuto para remover os status
          window.cfe_sat_timeout = setTimeout(() => {
            // Commitar sending false
            commit(mutations.DFE.SENDING, false);
            // Commitar a statusbar para null (remover o status do rodapé)
            commit(mutations.MODULO.STATUSBAR, null);

            // Emite o evento para o frente com null, para apenas limpar o status
            EventBus.$emit("terminal-saas-callback", null);

            // Mostra o alerta de erro
            commit(mutations.APP.ALERT, {
              active: true,
              message:
                "Não foi possível se comunicar com o Terminal. Verifique as chaves de acesso e se o Terminal está aberto e tente novamente",
            });
          }, 40 * 1000);
        }
      }
    },

    /**
     * Função que consulta o status do serviço da SEFAZ
     */
    [actions.DFE.MANIFESTO](store, { document, id, tpEvento, xJust }) {
      // extrai da store
      const { dispatch } = store;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Título a ser mostrado caso erro
        errorTitle: "Erro ao Manifestar ciência",
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api
            .store(store)
            .post(`${prefix}/${document ?? "nfe"}/manifesto`, {
              id,
              xJust,
              tpEvento,
            });
        },
      });
    },

    /**
     * Função que consulta o status do serviço da SEFAZ
     */
    [actions.DFE.STATUS](store, document: string) {
      // extrai da store
      const { dispatch } = store;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Documento
        document,
        // Título a ser mostrado caso erro
        errorTitle: "Erro ao consultar Status de Serviço",
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api.store(store).get(`${prefix}/${document}/status`);
        },
      });
    },

    /**
     * Função que aciona a transmissão da NF-e
     */
    [actions.DFE.EMITIR](store, params) {
      // extrai da store
      const { dispatch } = store;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Documento
        document: params.document,
        // Título a ser mostrado caso erro
        errorTitle: "Erro ao transmitir Documento",
        // Ignorar a mensagem de erro
        ignoreMessage: params.ignoreMessage,
        // Mensagem de erro padrão para qualquer erro dessa requisição
        appendErrorMessage:
          "<br><br>Leia atentamente a mensagem acima e tente resolver o problema. Considere pedir ajuda ao seu contador para o preenchimento correto do Documento.",
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api
            .store(store)
            .post(`${prefix}/${params.document}/emitir`, params)
            .then((response) => {
              const pdf = get(response, "data.pdf");

              if (pdf) {
                // printPDF({ data: window.atob(pdf) }, "Documento Auxiliar");
                printPDF(
                  { data: base64ToArrayBuffer(pdf) },
                  "Documento Auxiliar"
                );
              }

              return response;
            });
        },
      });
    },

    /**
     * Função que transmite os cupons pendentes em contingência
     */
    [actions.DFE.EMITIR_CONTINGENCIA](store, params) {
      const { dispatch } = store;

      return dispatch(actions.DFE.HANDLE, {
        document: "nfce",
        errorTitle: "Erro ao gerar transmitir NFCs em contingência",
        promise() {
          return api.store(store).post(`${prefix}/nfce/contingencia`, params);
        },
      });
    },

    /**
     * Função que aciona a a geração do XML dos documentos
     */
    [actions.DFE.XML](store, params) {
      // extrai da store
      const { dispatch, commit } = store;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Documento
        document: params.document,
        // Título a ser mostrado caso erro
        errorTitle: "Erro ao gerar XML",
        // Mensagem de erro padrão para qualquer erro dessa requisição
        appendErrorMessage:
          "<br><br>Leia atentamente a mensagem acima e tente resolver o problema. Considere pedir ajuda ao seu contador para o preenchimento correto do Documento.",
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api
            .store(store)
            .post(`${prefix}/${params.document}/xml`, params)
            .then((response) => {
              commit(mutations.APP.SNACKBAR, {
                active: true,
                text: "XML gerado com sucesso",
              });

              return response;
            });
        },
      });
    },

    /**
     * Função que gera o DANFE de uma NF-e autorizada
     */
    [actions.DFE.DANFE](store, { id, type }) {
      // extrai da store
      const { dispatch } = store;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Título a ser mostrado caso erro
        errorTitle: "Erro ao gerar DANFE",
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api
            .store(store)
            .get(`${prefix}/nfe/da/${id}`, {
              params: { type },
              responseType: "arraybuffer",
            })
            .then((response) => {
              return printPDF(response, "DANFE");
            });
        },
      });
    },

    /**
     * Função que consulta a dist dfe
     */
    [actions.DFE.DIST_DFE](store, { modulo }) {
      // se nao foi chamado pela entrada, retorna
      if (modulo !== "entrada") return;

      // extrai da store
      const { dispatch } = store;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Documento
        document,
        // Título a ser mostrado caso erro
        errorTitle: "Erro ao consultar NFs emitidas",
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api.store(store).get(`${prefix}/nfe/dist-dfe`, {
            params: {
              manually: true,
            },
          });
        },
      });
    },

    /**
     * Função que cria a movimentação de sangria/suprimento
     */
    [actions.DFE.SANGR_SUPR](store, data) {
      // extrai da store
      const { dispatch } = store;
      const { document, rel_type } = data;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Documento
        document,
        // Título a ser mostrado caso erro
        errorTitle: `Erro ao gerar ${capitalize(rel_type)}`,
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api
            .store(store)
            .post(`${prefix}/${document}/sangria-suprimento`, data);
        },
      });
    },

    /**
     * Função que gera o DANFE de uma NF-e autorizada
     */
    [actions.DFE.DANFCE](store, params) {
      // extrai da store
      const { dispatch } = store;

      const { id, document } = params;

      // caminho do auxiliar
      const path =
        document === "cfe-sat"
          ? `cfe-sat/extrato/${id}`
          : document === "cupom"
          ? `cupom/da/${id}`
          : `nfce/da/${id}`;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Documento
        document,
        // Título a ser mostrado caso erro
        errorTitle: `Erro ao gerar ${
          document === "cfe-sat"
            ? "Extrato"
            : document === "cupom"
            ? "Cupom"
            : "DANFCE"
        }`,
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api
            .store(store)
            .get(`${prefix}/${path}`, {
              params: pick(params, ["type", "imprimir_bobina_58mm"]),
              responseType: "arraybuffer",
            })
            .then((response) => {
              return printPDF(
                response,
                document === "cfe-sat" ? "Extrato" : "DANFCE"
              );
            });
        },
      });
    },

    /**
     * Função que cancela a NF-e já autorizada
     */
    [actions.DFE.CANCELAR](store, { data, document }) {
      // extrai da store
      const { dispatch } = store;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Documento
        document,
        // Título a ser mostrado caso erro
        errorTitle: "Erro ao cancelar documento",
        ignoreMessage: true,
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api.store(store).post(`${prefix}/${document}/cancelar`, {
            id: data.id,
            justificativa: data._nfe_justificativa,
          });
        },
      });
    },

    /**
     * Função que consulta uma NF-e
     */
    [actions.DFE.CONSULTAR](store, { id, document }) {
      // extrai da store
      const { dispatch } = store;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Título a ser mostrado caso erro
        errorTitle: "Erro ao consultar documento",
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api
            .store(store)
            .post(`${prefix}/${document}/consultar`, { id });
        },
      });
    },

    /**
     * Função que inutiliza uma NF-e
     */
    [actions.DFE.INUTILIZAR](store, params) {
      // extrai da store
      const { dispatch } = store;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Título a ser mostrado caso erro
        errorTitle: "Erro ao inutilizar intervalo de NFs",
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api
            .store(store)
            .post(`${prefix}/${params.document}/inutilizar`, params);
        },
      });
    },

    /**
     * Função que dispara um e-mail com DANFE e XML
     */
    [actions.DFE.EMAIL](store, { id, document }) {
      // extrai da store
      const { dispatch } = store;

      let name = "DANFE";
      if (document === "nfce") name = "DANFCE";
      if (document === "cfe-sat") name = "Extrato";
      if (document === "cupom") name = "Cupom";
      else {
        name += " e XML";
      }

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Documento
        document,
        // Título a ser mostrado caso erro
        errorTitle: `Erro ao enviar ${name} por e-mail`,
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api.store(store).post(`${prefix}/${document}/mail`, { id });
        },
      });
    },

    /**
     * Função que dispara um download de documento com diferença na chave de acesso
     */
    [actions.DFE.DUPLICIDADE](store, { id, document }) {
      // extrai da store
      const { dispatch } = store;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Título a ser mostrado caso erro
        errorTitle: "Erro ao fazer download de XML",
        ignoreMessage: true,
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api
            .store(store)
            .post(`${prefix}/${document}/duplicidade-chave`, {
              id,
            });
        },
      });
    },

    /**
     * Função que dispara um e-mail com DANFE e XML
     */
    [actions.DFE.CCE](store, { id, _nfe_texto_correcao }) {
      // extrai da store
      const { dispatch } = store;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Título a ser mostrado caso erro
        errorTitle: "Erro ao gerar CC-e",
        ignoreMessage: true,
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api.store(store).post(`${prefix}/nfe/cce`, {
            id,
            texto: _nfe_texto_correcao,
          });
        },
      });
    },

    /**
     * Função que dispara um e-mail com DANFE e XML
     */
    [actions.DFE.CCE_PDF]({ dispatch }, id) {
      return dispatch(actions.DFE.EVENTO, {
        id,
        type: "cce",
      });
    },

    /**
     * Gera o PDF da representação gráfica do evento
     */
    [actions.DFE.EVENTO](store, { id, type }) {
      // extrai da store
      const { dispatch } = store;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.DFE.HANDLE, {
        // Título a ser mostrado caso erro
        errorTitle: "Erro ao gerar o PDF",
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Retorna a promessa da requisição
          return api
            .store(store)
            .get(`${prefix}/nfe/da/evento/${type}`, {
              responseType: "arraybuffer",
              params: { id },
            })
            .then((response) => {
              return printPDF(response, "Representação Gráfica de Evento");
            });
        },
      });
    },
  },
};
