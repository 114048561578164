<template>
  <v-col class="pa-1" cols="12">
    <div :style="`height: ${height}px; width: 100%;`">
      <data-table
        editable
        hide-footer
        hide-empty-row
        :headers="headers"
        :items="tagsTabValues"
        v-model="selectedTag"
      >
        <template #textfield="{ on, index, selected, value, item, ...rest }">
          <c-text-field
            :auto-focus="index === 0"
            :value="value"
            :white="selected"
            :disabled="disabled"
            v-on="on"
            v-bind="rest"
            @change="(event) => onTagCellChanged({ item, value: event })"
          />
        </template>
      </data-table>
    </div>
  </v-col>
</template>

<script>
import get from "lodash/get";

import DataTable from "@/components/table/DataTable.vue";
import CTextField from "@/components/form/inputs/textfield/CTextField.vue";

export default {
  components: {
    DataTable,
    CTextField,
  },
  props: {
    height: { default: "0" },
    value: { default: () => ({}) },
    base: { default: () => [] },
    headers: { default: () => [] },
    categories: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    __ficha: {},
  },
  data: () => ({
    selectedTag: { index: 0, coluna: 1 },
  }),
  computed: {
    /**
     * Função que monta os valores da Tabela TAGs
     */
    tagsTabValues() {
      const base = this.base.map((i, key) => ({
        ...i,
        id: key + 1,
      }));

      return base
        .map((tag) => ({
          ...tag,
          valor: get(this.value, tag.nome),
        }))
        .filter(
          (value) =>
            !value.categorias ||
            value.categorias
              .split(",")
              .some((it) => this.categories.includes(it))
        );
    },
  },

  methods: {
    /**
     * Função que é chamada quando um valor da tabela TAGs é alterado
     */
    onTagCellChanged({ item, value }) {
      const name = item ? item.nome : null;

      if (!name) return;

      value = !value || !(value || "").trim() ? null : value.trim();

      // Emite o evento de input
      this.$emit("input", {
        ...this.value,
        [name]: value,
      });

      // Envia a requisição de update das tags
      this.__ficha.onChangePersistent({
        data: { [`tags.${name}`]: value },
      });
    },
  },
};
</script>
