<template>
  <v-menu
    absolute
    offset-y
    ref="menu"
    :content-class="`v-menu-focusable ${_uid}-v-menu-focusable`"
    :key="`MenuContext${_uid}`"
    :position-x="x"
    :position-y="y"
    v-model="menu"
  >
    <v-list>
      <template v-for="(item, i) in context">
        <v-list-item
          style="min-height: 32px !important"
          :key="item.title"
          :disabled="item.disabled"
          @click="$emit('click-item', item)"
          v-if="!item.hidden"
        >
          <v-list-item-action class="mx-0 ml-2 my-0" v-if="item.icon">
            <v-icon small v-text="item.icon" />
          </v-list-item-action>
          <v-list-item-content
            class="py-0"
            :class="[item.icon ? 'pr-2' : 'px-2']"
          >
            <v-list-item-title style="font-size: 12px">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="i" v-if="!item.hidden && item.divider" />
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import Vue from "vue";
import { find, head } from "lodash";

export default Vue.extend({
  props: {
    context: { type: Array, default: () => [] },
  },
  data: () => ({ menu: false, x: 0, y: 0 }),
  methods: {
    open(event) {
      if (!event) return;

      // document.addEventListener("keypress", this.onKeyPress);

      this.x = event.clientX;
      this.y = event.clientY;

      this.$nextTick(() => {
        this.menu = true;

        setTimeout(() => {
          const el = head(
            document.getElementsByClassName(`${this._uid}-v-menu-focusable`)
          );

          if (el) {
            el.tabIndex = -1;
            el.focus();
          }
        }, 40);
      });
      // this.$refs.menu.open(e);
    },
    onKeyPress(event) {
      // Busca pelo item cujo código é igual a tecla pressionada
      const item = find(this.context, (item) => item.code === event.key);

      // Se não encontrar retorna
      if (!item || item.disabled) return;

      // Emite o evento de click
      this.$emit("click-item", item);

      // document.removeEventListener("keypress", this.onKeyPress);

      // Fecha o menu
      this.$refs.menu.close(event);
    },
  },
  beforeDestroy() {
    // document.removeEventListener("keypress", this.onKeyPress);
  },
  computed: {
    isOpen() {
      return this.menu;
    },
  },
});
</script>

<style lang="scss" scoped>
.v-list-item {
  padding: 4px 0px;
}
</style>

<style lang="scss">
.v-menu-focusable {
  outline: none;
}
</style>
