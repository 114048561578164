<template>
  <stepper-items v-model="value">
    <template #item-1>
      <v-col
        lg="10"
        offset-lg="1"
        class="d-flex flex-column pa-1"
        :style="`height:` + screenHeight + `px;`"
      >
        <v-flex shrink ref="cabecalho">
          <basico-notas
            class="px-0 pt-0"
            ref="basico"
            :data="data"
            :extra="extra"
            :__ficha="__ficha"
          />
        </v-flex>
        <v-row>
          <v-col class="pb-1 pb-md-2">
            <dependence-table
              modulo="venda-itens"
              :height="heightTable"
              :__ficha="__ficha"
              :double-item="!!$config.itens_duplos_nfe"
              :append-headers="appendHeaders"
              :disabled="!data.id"
              :selected="selected"
              @selected="setSelected"
              v-model="data"
            />
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #item-2>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2" class="px-1 py-0">
        <v-row class="mx-0 mt-3">
          <v-expansion-panels flat multiple v-model="expansion">
            <expansion-panel value title="Impostos">
              <impostos :data="data" modulo="saida" />
            </expansion-panel>

            <expansion-panel
              title="Intermediador da Operação"
              v-if="data.tem_intermediador"
            >
              <v-row no-gutters>
                <intermediador-select
                  md="6"
                  :clifor="data.intermediador"
                  v-model="data.intermediador_id"
                />

                <v-col md="6" class="d-flex px-2">
                  <div class="my-auto text-center">
                    {{ data.intermediador ? data.intermediador.cpf_cnpj : "" }}
                    {{ data.intermediador ? data.intermediador.tags : "" }}
                  </div>
                </v-col>
              </v-row>
            </expansion-panel>

            <expansion-panel title="Frete / Transportadora">
              <transportadora :data="data" :extra="extra" />
            </expansion-panel>
          </v-expansion-panels>
        </v-row>

        <v-row class="mx-0 pt-1">
          <text-area
            readonly
            :colClass="['px-0', data.complemento && 'pr-1']"
            cols="12"
            md="6"
            rows="4"
            label="Informações complementares no XML"
            :value="data.complemento"
            v-if="data.complemento"
          />

          <p-text-area
            cols="12"
            :colClass="['px-0', data.complemento && 'pl-1']"
            :md="data.complemento ? 6 : 12"
            rows="4"
            name="complemento_editavel"
            label="Informações complementares"
            v-model="data.complemento_editavel"
          />
        </v-row>
      </v-col>
    </template>

    <template #item-3>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2" class="px-1 py-0">
        <pagamentos-v2 auto-focus :data="data" :__ficha="__ficha" />
      </v-col>
    </template>
  </stepper-items>
</template>

<script>
import { concat, get } from "lodash";
import { StepperItems } from "@/components/utils";

import {
  PTextArea,
  TextArea,
  DependenceTable,
  Impostos,
  Transportadora,
  ExpansionPanel,
  PagamentosV2,
  BasicoNotas,
  IntermediadorSelect,
} from "@/components/form";

// Mixins
import Ficha from "@/mixins/ficha";
import { EventBus } from "@/main";
import { handleCfop } from "@/utils";

export default Ficha.extend({
  components: {
    StepperItems,
    DependenceTable,
    Impostos,
    PTextArea,
    TextArea,
    Transportadora,
    ExpansionPanel,
    PagamentosV2,
    BasicoNotas,
    IntermediadorSelect,
  },
  data: () => ({
    heightTable: 0,
    screenHeight: 0,

    expansion: [0],
  }),
  created() {
    EventBus.$on("resize", this.onResize);
  },
  mounted() {
    this.onResize();
  },
  beforeDestroy() {
    EventBus.$off("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.screenHeight = 0;
      this.heightTable = 0;

      setTimeout(() => {
        this.screenHeight = this.height() - 16 - 2;
        this.heightTable =
          this.screenHeight -
          get(this.$refs, "cabecalho.clientHeight", 0) -
          16 -
          16 -
          24;
      }, 40);
    },
  },
  computed: {
    icmsUfDest() {
      const natureza = (this.extra.naturezas_operacao || []).find(
        (n) => n.id === this.data.natureza_operacao_id
      );

      const cfop = handleCfop(
        get(natureza, "cfop"),
        this.$emitente.uf,
        this.data?.clifor?.uf,
        "1"
      );

      // Habilita o ICMS UF Dest caso for indicador consumidor final e for CFOP interestadual e nao Simples Nacional
      return (
        cfop.startsWith("6") &&
        this.data.indicador_consumidor === "1" &&
        this.$emitente.crt !== "1"
      );
    },
    /**
     * Função que retorna corretamente o cabeçalho dos itens
     */
    appendHeaders() {
      return concat(
        [
          { text: "CFOP", value: "cfop", disabled: "produto_id" },
          { text: "Pedido", value: "xped", disabled: "produto_id" },
          { text: "Item Pedido", value: "nitemped", disabled: "produto_id" },
        ],
        this.icmsUfDest
          ? [
              {
                type: "precision",
                text: "FCP",
                value: "icms_uf_dest.pFCPUFDest",
                disabled: "produto_id",
              },
              {
                type: "precision",
                text: "ICMS UF",
                value: "icms_uf_dest.pICMSUFDest",
                disabled: "produto_id",
              },
            ]
          : [],
        ["2", "4"].includes(this.data.nfe_finalidade)
          ? [
              {
                text: "BC ICMS",
                type: "precision",
                disabled: "produto_id",
                value: "icms.vBC",
                width: "90px",
              },
              {
                text: "ICMS",
                type: "precision",
                disabled: "produto_id",
                value: "icms.vICMS",
                width: "90px",
              },
              {
                text: "BC ICMS ST",
                type: "precision",
                disabled: "produto_id",
                value: "icms.vBCST",
                width: "90px",
              },
              {
                text: "ICMS ST",
                type: "precision",
                disabled: "produto_id",
                value: "icms.vICMSST",
                width: "90px",
              },
              {
                text: "IPI",
                type: "precision",
                disabled: "produto_id",
                value: "ipi.vIPI",
                width: "90px",
              },
            ]
          : [],
        ["4"].includes(this.data.nfe_finalidade)
          ? [
              {
                text: "CST IPI",
                value: "ipi.CST",
                disabled: "produto_id",
                type: "tel",
                options: { maxLength: "2" },
              },
              {
                text: this.$emitente.crt === "1" ? "CSOSN ICMS" : "CST ICMS",
                value: this.$emitente.crt === "1" ? "icms.CSOSN" : "icms.CST",
                disabled: "produto_id",
                type: "tel",
                options: { maxLength: this.$emitente.crt === "1" ? "3" : "2" },
              },
            ]
          : []
      );
    },
  },
  watch: {
    "__ficha.fullscreen"() {
      this.onResize();
    },
  },
});
</script>
