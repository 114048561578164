<template>
  <tabs-items
    @loaded-tab-series="onDependenceTabLoaded"
    @loaded-tab-inutilizacao="onDependenceTabLoaded"
    v-model="value"
  >
    <template #tab-principal>
      <v-col cols="12" lg="10" offset-lg="1">
        <template v-if="!$emitente.mei">
          <template v-if="!$useSat">
            <v-row>
              <p-select-filter
                guard
                value-not-null
                md="6"
                modulo="sistema"
                name="nfce_ambiente_emissao"
                label="Ambiente"
                :disabled="isFichaDisabled"
                :items="environments"
                v-model="$config.nfce_ambiente_emissao"
              />
            </v-row>
            <v-row>
              <s-switch
                modulo="sistema"
                name="nfce_contingencia_json"
                label="Modo Contingência"
                :value="$config.nfce_contingencia_json.ativo"
                v-if="$staging"
                @change="onContingencyChanged"
              />
            </v-row>
          </template>

          <template v-else>
            <v-row>
              <p-select-filter
                guard
                value-not-null
                md="4"
                modulo="sistema"
                name="cfe_ambiente_emissao"
                label="Ambiente"
                :items="environments"
                v-model="$config.cfe_ambiente_emissao"
              />
              <p-select-filter
                value-not-null
                md="4"
                modulo="sistema"
                name="cfe_versao_layout"
                label="Versão Layout"
                :items="cfeSatLayouts"
                v-model="$config.cfe_versao_layout"
              />
            </v-row>
            <v-row v-if="$config.cfe_ambiente_emissao == 2">
              <p-text-field
                md="4"
                modulo="sistema"
                name="cfe_homologacao_json.cpf_cnpj"
                label="CPF/CNPJ Homologação"
                v-model="$config.cfe_homologacao_json.cpf_cnpj"
              />
              <p-text-field
                md="4"
                modulo="sistema"
                name="cfe_homologacao_json.rg_ie"
                label="RG/IE Homologação"
                v-model="$config.cfe_homologacao_json.rg_ie"
              />
            </v-row>
          </template>
        </template>

        <v-row>
          <p-switch
            modulo="sistema"
            name="nfce_envio_email"
            label="Envio automático de e-mail ao autorizar"
            v-model="$config.nfce_envio_email"
          />
        </v-row>
      </v-col>
    </template>

    <template #tab-series>
      <v-col cols="12" lg="10" offset-lg="1">
        <v-row>
          <v-col cols="12" class="px-1" style="height: 250px">
            <document-sequences
              modulo="cupons-series"
              :__ficha="__ficha"
              :items="tabSeriesValues"
              :value="selected"
              @input="setSelected"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pa-0">
            <p-text-field
              value-not-null
              md="3"
              type="tel"
              maxlength="3"
              modulo="sistema"
              class="mt-2"
              name="nfce_numero_serie"
              label="Número de Série NFC-e"
              v-model="$config.nfce_numero_serie"
            />
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-inutilizacao>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <document-inutilizacao
          modelo="65"
          :data="data.inutilizacoes"
          :extra="extra"
          :__ficha="__ficha"
        />
      </v-col>
    </template>
  </tabs-items>
</template>

<script>
import { TabsItems, ImageNfce } from "@/components/utils";
import {
  PSelectFilter,
  ContainerContent,
  Switch as SSwitch,
  PTextField,
  PSwitch,
} from "@/components/form";

import DocumentSequences from "@/components/form/combos/DocumentSequences.vue";
import DocumentInutilizacao from "@/components/form/combos/DocumentInutilizacao.vue";

// Mixins
import Ficha from "@/mixins/ficha";

import mutations from "@/store/mutations";

export default Ficha.extend({
  components: {
    TabsItems,
    ImageNfce,
    ContainerContent,
    PSelectFilter,
    SSwitch,
    DocumentSequences,
    DocumentInutilizacao,
    PSwitch,
    PTextField,
  },
  methods: {
    /**
     * Função que faz a leitura das dependências
     */
    onDependenceTabLoaded(dependence) {
      if (dependence === "tab-series") {
        this.setSelected({ coluna: 2 });
      }

      this.__ficha.readDependence({
        dependence,
      });
    },
    onChangeCfeJsonParam(value, name) {
      this.__ficha.onChangePersistent({
        modulo: "sistema",
        data: {
          cfe_homologacao_json: JSON.stringify({
            ...this.$config.cfe_homologacao_json,
            [name]: value,
          }),
        },
      });
    },
    /**
     * Função que gerencia a contingencia
     */
    onContingencyChanged(value) {
      return value
        ? this.$store.commit(mutations.APP.ASSISTENT, {
            modulo: "general",
            action: "contingency",
            registro: {
              ...this.$config.nfce_contingencia_json,
              document: "nfce",
            },
          })
        : this.__ficha.onChangePersistent({
            modulo: "sistema",
            data: {
              nfce_contingencia_json: JSON.stringify({
                justificativa: "",
                inicio: "",
                ativo: false,
              }),
            },
          });
    },
  },
  computed: {
    cfeSatLayouts() {
      return [
        { text: "0.07", value: 0.07 },
        { text: "0.08", value: 0.08 },
      ];
    },
    /**
     * Ambientes de emissão de NF-e
     */
    environments() {
      return [
        { text: "Homologação", value: 2 },
        { text: "Produção", value: 1 },
      ];
    },
    /**
     * Valores interpretados da tabela e arranjados para exibição
     */
    tabSeriesValues() {
      // Retorna as séries
      return this.data.series ?? [];
    },
  },
});
</script>
