<template>
  <v-dialog
    scrollable
    persistent
    no-click-animation
    :width="$isMobile ? '95vw' : '80vw'"
    :value="value"
    @click:outside="$emit('click-outside', $event)"
    @input="forceClose"
    v-bind="$attrs"
    v-resize="onResize"
  >
    <v-card outlined :height="$isMobile ? '85vh' : '80vh'" ref="card">
      <v-card-title
        class="tabs px-3 body-1 text-md-h6 font-weight-bold"
        ref="cardTitle"
      >
        <v-col class="pa-0" cols="10" sm="11" md="10">
          <div>
            <template>{{ $greet }}</template>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="pa-0" cols="auto">
          <slot name="title-actions">
            <close-button v-if="!preferences.persistent" @close="forceClose">
              <v-icon>mdi-close</v-icon>
            </close-button>
          </slot>
        </v-col>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row no-gutters>
          <v-col class="pa-4" cols="12">
            <v-card flat>
              <v-card-text
                :height="height"
                class="pa-2 overflow-visible caption text-md-body-2"
              >
                <v-row>
                  <v-col v-if="!transitioning">
                    <v-row>
                      <v-col>
                        <div class="mb-4 px-1" v-html="message" />
                      </v-col>
                    </v-row>

                    <template v-for="(item, i) in form">
                      <v-row class="ma-0 pb-1" :key="i">
                        <file-field
                          :value="data[item.name]"
                          @input="(event) => onInput(item.name, event)"
                          @enter="() => confirm(i)"
                          v-bind="item"
                          v-if="item.type === 'file'"
                        />
                        <v-progress-linear
                          rounded
                          indeterminate
                          color="primary"
                          class="mx-4"
                          v-else-if="item.type === 'loading'"
                        />
                        <text-area
                          :value="data[item.name]"
                          @input="(event) => onInput(item.name, event)"
                          @enter="() => confirm(i)"
                          v-bind="item"
                          v-else-if="item.type === 'textarea'"
                        />
                        <precision-field
                          :value="data[item.name]"
                          @input="(event) => onInput(item.name, event)"
                          @enter="() => confirm(i)"
                          v-bind="item"
                          v-else-if="item.type === 'precision'"
                        />
                        <select-filter
                          :value="data[item.name]"
                          @change="(event) => onInput(item.name, event)"
                          @enter="() => confirm(i)"
                          v-bind="item"
                          v-else-if="item.type === 'select'"
                        />

                        <password
                          :value="data[item.name]"
                          :score="pw.score"
                          :score-color="pw.scoreColor"
                          @input="
                            (event) => {
                              onInput(item.name, event);

                              if (!form[i + 1]) {
                                return;
                              }

                              form[i + 1].type === 'confirm-password'
                                ? checkPass(event, data[form[i + 1].name])
                                : null;
                            }
                          "
                          @enter="() => confirm(i)"
                          v-bind="item"
                          v-else-if="item.type === 'password'"
                        />
                        <password
                          :value="data[item.name]"
                          @input="
                            (event) => (
                              onInput(item.name, event),
                              checkPass(data[form[i - 1].name], event)
                            )
                          "
                          @enter="() => confirm(i)"
                          v-bind="item"
                          v-else-if="item.type === 'confirm-password'"
                        />

                        <text-field
                          :value="data[item.name]"
                          @input="(event) => onInput(item.name, event)"
                          @enter="() => confirm(i)"
                          v-bind="item"
                          v-else
                        />
                      </v-row>
                    </template>
                    <list-info v-if="info && info.length" :items="info" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col md="3" class="d-none d-md-block ma-0 py-0 pl-0 pr-2">
            <v-img
              position="top"
              contain
              :src="$assistentImage"
              :height="height"
            />
          </v-col> -->
        </v-row>
      </v-card-text>
      <v-card-actions class="tabs" ref="cardActions">
        <v-row no-gutters>
          <v-col class="text-center">
            <btn
              text
              btn-class="mr-1"
              :loading="loading"
              :disabled="loading || preferences.disableOk || loadingNext"
              v-if="
                preferences.multiple &&
                !isFirstSelected &&
                !preferences.hideBackButton
              "
              @click="back"
              >Voltar</btn
            >
            <btn
              :loading="loading || loadingNext"
              :disabled="loading || preferences.disableOk"
              v-if="preferences.multiple && !isLastSelected"
              @click="() => confirm(form.length - 1)"
              >Próximo</btn
            >
            <btn
              :loading="loading"
              :disabled="loading || preferences.disableOk"
              v-if="!preferences.multiple || isLastSelected"
              @click="() => confirm()"
              >Ok</btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <alert
      v-if="error_"
      v-model="error_"
      :message="error"
      @input="onCloseAlert"
    />
  </v-dialog>
</template>

<script>
import {
  isObject,
  isString,
  head,
  values,
  debounce,
  findIndex,
  get,
  isFunction,
  filter,
  has,
} from "lodash";

import ListInfo from "./ListInfo.vue";

import {
  TextField,
  TextArea,
  FileField,
  SelectFilter,
  Btn,
  CloseButton,
  PrecisionField,
  Password,
} from "@/components/form";

import Alert from "./Alert.vue";

import mPassword from "@/mixins/password";

export default {
  mixins: [mPassword],

  components: {
    TextField,
    Alert,
    TextArea,
    Btn,
    CloseButton,
    FileField,
    ListInfo,
    SelectFilter,
    PrecisionField,
    Password,
  },
  props: {
    value: {},
    modulo: {},
    action: {},
    registro: {},

    successCallback: { type: Function, default: () => null },
    errorCallback: { type: Function, default: () => null },

    registros: { default: () => [] },
    extra: { default: () => ({}) },

    __ficha: {},
  },
  data: () => ({
    preferences: {},
    transitioning: false,
    loading: false,
    loadingNext: false,
    errorMessage_: "",
    currentError: null,
    error_: false,
    height: "400",

    pw: {
      // Reescreve as rules do objeto
      rules: ["min:6", "equals", "strong"],
    },
  }),
  /**
   * Created hook
   */
  created() {
    // Inicializa o assistente
    this.init();
  },
  /**
   * Hook acionado após montar o componente
   */
  mounted() {
    // Chama a função para recalcular a tela
    this.onResize_();
  },

  computed: {
    /**
     * Função que valida se está no ultimo item do multiple
     */
    isLastSelected() {
      // Se não houver registros
      if (!this.registros || !this.registros.length) return;

      // Procura o index
      const index = findIndex(
        // Nos registros
        this.registros,
        // no qual está ativo
        (registro) => this.registro && registro.id === this.registro.id
      );

      // Retorna se estiver no último registro da lista de registros do multiple
      return index === this.registros.length - 1;
    },
    /**
     * Função que valida se está no primeiro item do multiple
     */
    isFirstSelected() {
      // Se não houver registros
      if (!this.registros || !this.registros.length) return;

      // Procura o index
      const index = findIndex(
        // Nos registros
        this.registros,
        // no qual está ativo
        (registro) => this.registro && registro.id === this.registro.id
      );

      // Retorna se estiver no último registro da lista de registros do multiple
      return index === 0;
    },
    /**
     * Dados do formulário
     */
    data() {
      return this.registro;
    },
    /**
     * Mensagem a ser exibida no assistente
     */
    message() {
      // Retorna a mensagem das preferências do assistente
      return (
        this.preferences.message &&
        this.preferences.message.bind(this)(this.data)
      );
    },
    /**
     * Informações extras após o form
     */
    info() {
      // Retorna a mensagem das preferências do assistente
      return this.preferences.info
        ? this.preferences.info.bind(this)(this.data)
        : [];
    },
    /**
     * Mensagem de erro caso o validator falhe
     */
    error() {
      // Se houver mensagem, retorna
      if (this.errorMessage_) return this.errorMessage_;

      // Se não houver função de erro, retorna
      if (!this.preferences.error) return;

      // Chama a função de erro
      const error = this.preferences.error.bind(this)(this.data);

      // Se houver erro
      if (this.currentError)
        // Retorna o erro ou uma mensagem default
        return error[this.currentError] ?? "Erro desconhecido.";

      // Retorna a mensagem de erro das preferências do assistente
      return isObject(error) ? head(values(error)) : error;
    },
    /**
     * Formulário a ser exibido no assistente
     * Lista de objetos que representam um TextField
     */
    form() {
      // Retorna o form das preferências
      return filter(
        this.preferences.form && this.preferences.form.bind(this)(this.data),
        (item) => {
          if (!has(item, "dontShow")) return true;

          return isFunction(item.dontShow)
            ? !item.dontShow.bind(this)(this.data)
            : !item.dontShow;
        }
      );
    },
  },
  methods: {
    getTags(registro) {
      return get(registro, "tags") || {};
    },
    onInput(name, value) {
      const registro = {
        ...this.registro,
        [name]: value,
      };

      const registros = [...this.registros];

      registros.splice(
        findIndex(registros, (reg) => reg.id === registro.id),
        1,
        registro
      );

      this.$emit("assistent", {
        ...this.$props,
        registro,
        registros,
      });
    },
    /**
     * Função que inicializa o assistente
     */
    init() {
      // Tente
      try {
        // Importar o assistente do módulo passado no parametro
        const { assistent } = require(`@/config/modulos/${this.modulo}`);

        // Se houver assistente, e a ação que foi passada
        if (assistent && assistent[this.action]) {
          // Captura as tags
          const tags = this.getTags(this.registro);

          // Emite um evento para atualizar o registro
          this.$emit("assistent", {
            ...this.$props,
            registro: {
              ...this.registro,
              // Adiciona o valor das tags
              ...tags,
            },
          });

          // Aguarda um tick
          this.$nextTick(() => {
            // Seta as preferências do assistente
            this.preferences = assistent[this.action];

            if (this.preferences.autoConfirm) {
              this.confirm();
            }
          });
        }
      } catch (e) {
        // Caso houver erros, retorna
        return;
      }
    },
    /**
     * Função callback de close alert
     */
    onCloseAlert(event) {
      // Se não houver evento, limpa a mensagem de rro
      !event && (this.errorMessage_ = "");

      // Se existir a flag close after error
      if (this.preferences.closeAfterError)
        // Fecha o assistente
        this.forceClose();
    },
    /**
     * Função que configura o assistente, se chamado a partir de outro assistente
     */
    setup(assistent) {
      // Inicia a transição
      this.transitioning = true;

      // emite o evento de atualização do assistente
      this.$emit("assistent", {
        ...assistent,
        registro: head(assistent.registros ?? []),
      });

      // Aguarda um tick
      this.$nextTick(() => {
        // Inicializa de novo
        this.init();

        // finaliza a transição
        this.transitioning = false;
      });

      // Retorna a instância
      return this;
    },
    /**
     * Função que valida e volta para o step anterior (caso for um assistente multiplo)
     */
    back() {
      // Se não for multiplo, retorna
      if (!this.preferences.multiple) return this;

      // Procura o index
      const index = findIndex(
        // Nos registros
        this.registros,
        // no qual está ativo
        (registro) => registro.id === this.registro.id
      );

      // Se não houver index ou estourar a lista
      if (index === -1 || index - 1 < 0)
        // Confirma o assistente
        return this;

      // Inicia a transição
      this.transitioning = true;

      // Captura as tags
      const tags = this.getTags(this.registros[index - 1]);

      // Emite o evento
      this.$emit("assistent", {
        // Aproveita os dados atuais
        ...this.$props,
        // Substitui o registro
        registro: { ...this.registros[index - 1], ...tags },
      });

      // Aguarda um tick
      this.$nextTick(() => {
        // Termina a transição
        this.transitioning = false;
      });

      // Retorna a instância
      return this;
    },
    /**
     * Função que valida e avança para o proximo step (caso for um assistente multiplo)
     */
    async next() {
      // Se não for multiplo, retorna
      if (!this.preferences.multiple) return this;

      // Se existir função de validar os dados
      if (this.preferences.validator && this.preferences.validateOnNext) {
        // chama a função
        const validator = this.preferences.validator.bind(this)(this.data);

        // Se for uma string, seta o erro atual
        if (isString(validator)) this.currentError = validator;
        // Caso contrário, remove o erro
        else this.currentError = null;

        // Se a resposta for falsa
        if (validator !== true)
          // Ativa a mensagem de erro
          return (this.error_ = true);
      }

      let nextReturnData = null;

      // Se houver o hook next
      if (this.preferences.next) {
        this.loadingNext = true;
        // verifica se deve continuar
        nextReturnData = await this.preferences.next.bind(this)(this.data);

        this.loadingNext = false;

        // Se não deve continuar, retorna
        if (!nextReturnData) return this;
      }

      // Procura o index
      const index = findIndex(
        // Nos registros
        this.registros,
        // no qual está ativo
        (registro) => registro.id === this.registro.id
      );

      // Se não houver index ou estourar a lista
      if (index === -1 || index + 1 >= this.registros.length)
        // Confirma o assistente
        return this;

      // Inicia a transição
      this.transitioning = true;

      // Captura as tags
      const tags = this.getTags(this.registros[index + 1]);

      const registro = {
        ...this.registros[index + 1],
        ...(nextReturnData ?? {}),
        ...tags,
      };

      const registros = [...this.registros];

      if (nextReturnData) {
        registros.splice(index, 1, registro);
      }

      // Emite o evento
      this.$emit("assistent", {
        // Aproveita os dados atuais
        ...this.$props,
        // Substitui o registro
        registro,
      });

      // Aguarda um tick
      this.$nextTick(() => {
        // Termina a transição
        this.transitioning = false;
      });

      // Retorna a instância
      return this;
    },
    /**
     * Função que é ativada no click do x
     */
    forceClose() {
      // Se for um persistente, retorna
      if (this.preferences.persistent) return;

      // Caso contrário, fecha o modal
      this.close();
    },
    /**
     * Função que fecha o modal
     */
    close() {
      // Emite o evento input false
      this.$emit("input", false);
    },
    /**
     * Função que é ativada quando clicado em OK
     */
    async confirm(index = -1) {
      // Se não for o último, retorna
      if (index > -1 && index !== this.form.length - 1) return;

      if (this.preferences.multiple) {
        if (index > -1 && !this.isLastSelected) return this.next();
      }

      // Se existir função de validar os dados
      if (this.preferences.validator) {
        // chama a função
        const validator = this.preferences.validator.bind(this)(this.data);

        // Se for uma string, seta o erro atual
        if (isString(validator)) this.currentError = validator;
        // Caso contrário, remove o erro
        else this.currentError = null;

        // Se a resposta for falsa
        if (validator !== true)
          // Ativa a mensagem de erro
          return (this.error_ = true);
      }

      // Se não existir função de sucesso, retorna e fecha o assistente
      if (!this.preferences.success) return this.close();

      try {
        // Seta loading como true
        this.loading = true;

        // Define a resposta
        const response =
          // Acessa a função
          await this.preferences.success
            // Faz o bind da com a store e chama a função
            .bind(this)(this.data);

        this.successCallback(response);

        // if (!response && this.mu)
        if (response && response._isVue)
          // Se retornar a instância do Vue, retorna
          return;

        // Fecha o asisstente
        this.close();
      } catch (error) {
        // Debuga o erro
        this.$debug(error);
        this.errorCallback(error);

        if (this.preferences.ignoreErrors) return;

        //  Se não houver resposta ou não houver data de resposta, retorna
        if (!error.response || !error.response.data) return;
        // Seta a mensagem de erro
        this.errorMessage_ = error.response.data.message;

        // Aguarda o próximo tick, e abre o alerta de erro
        this.$nextTick(() => (this.error_ = true));
      } finally {
        // Tira o loading
        this.loading = false;
        this.$emit("finally");
      }
    },
    /**
     * Função debounced para recalcular altura do assistente
     */
    onResize: debounce(function () {
      // Aguarda um tick
      this.$nextTick(() => {
        // Faz o resize
        this.onResize_();
      });
    }, 300),
    /**
     * Função que calcula a altura
     */
    onResize_() {
      try {
        // Captura a altura do card
        const hCard = this.$refs.card.$el.clientHeight;
        // Captura a altura do card title
        const hCardTitle = this.$refs.cardTitle.clientHeight;
        // Captura a altura do card action
        const hCardActions = this.$refs.cardActions.clientHeight;

        // Atribui a altura do assistente
        this.height = hCard - hCardTitle - hCardActions;
      } catch (e) {
        return;
      }
    },
  },
};
</script>
