/* eslint-disable no-console */

import { register } from "register-service-worker";

const production = process.env.VUE_APP_MODE === "production";
const beta = process.env.VUE_APP_MODE === "beta";

// if (beta || production) {
// if (window.location.origin.includes("app.")) {
if (beta || (production && window.location.origin.includes("app."))) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // production && console.log(
      //   "App is being served from cache by a service worker.\n" +
      //   "For more details, visit https://goo.gl/AFskqB"
      // );
    },
    registered() {
      if (!production) {
        console.log("[Service Worker] Registrado com sucesso.");
      }
    },
    cached() {
      if (!production) {
        console.log("[Service Worker] Cache criado para uso offline.");
      }
    },
    updatefound() {
      if (!production) {
        console.log("[Service Worker] Fazendo download de conteúdo novo.");
      }
    },
    updated(registration) {
      if (!production) {
        console.log(
          "[Service Worker] Conteúdo novo disponível, reinicie para atualizar"
        );
      }

      document.dispatchEvent(
        new CustomEvent("swUpdated", { detail: registration })
      );
    },
    offline() {
      if (!production) {
        console.log(
          "[Service Worker] Sem conexão com a internet, aplicação rodando em Modo Offline"
        );
      }
    },
    error(error) {
      if (!production) {
        console.error(
          "[Service Worker] Error during service worker registration:",
          error
        );
      }
    },
  });
}
