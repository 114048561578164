<template>
  <tr
    ref="row"
    class="s-datatable-row"
    style="max-height: 22px !important; height: 22px !important"
  >
    <slot />
  </tr>
</template>

<script>
export default {
  name: "DataTableRow",
  props: {},
};
</script>
