import API from "@/api";
import actions from "@/store/actions";
import mutations from "@/store/mutations";

const api = API.config({
  state: "loading",
  mutation: mutations.MODULOS.PAYMENTS.LOADING,
  delay: 500,
});

const intialState = {
  loading: false,
};

export default {
  state: Object.assign({}, intialState),

  mutations: {
    /**
     * Mutação que controla o carregamento das funções de pagamentos
     */
    [mutations.MODULOS.PAYMENTS.LOADING](state, loading) {
      // Atribui o loading
      state.loading = loading;
    },
  },

  actions: {
    [actions.MODULOS.PAYMENTS.CREATE](store, params) {
      return api.store(store).post("/pagamentos", params);
    },
    [actions.MODULOS.PAYMENTS.UPDATE](store, params) {
      return api.store(store).put(`/pagamentos/${params.id}`, params);
    },
    [actions.MODULOS.PAYMENTS.DELETE](store, params) {
      return api.store(store).delete(`/pagamentos/${params.id}`);
    },
    /**
     * Função que envia a requisição de gerar parcelas
     * @deprecated
     */
    [actions.MODULOS.PAYMENTS.PARCELS](store, params) {
      const { modulo } = params;
      return api.store(store).post(`${modulo}/payments/parcels`, params);
    },
  },
};
