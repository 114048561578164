var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"offset-y":"","top":"","left":"","max-width":"90vw","min-width":_vm.$isMobile ? '80vw' : '500',"value":_vm.value,"close-on-content-click":false},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('sale-btn',_vm._g(_vm._b({attrs:{"color":"saleRed","nome":"F10","descricao":"Menu"}},'sale-btn',attrs,false),on))]}}])},[_c('v-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.value),expression:"value"}],staticClass:"overflow-y-auto",attrs:{"dense":"","width":"100%","height":"calc(100vh - 124px)"}},[_vm._l((_vm.configs),function(item,i){return [(item.children)?_c('v-list-group',{key:i,class:[
          _vm.highlighted === i &&
            _vm.subhighlighted === -1 &&
            'v-list-group-item__highlighted',
        ],attrs:{"no-action":"","append-icon":"mdi-chevron-right","value":_vm.expanded[i]},on:{"input":(value) => (_vm.expanded[i] = value),"click":() => {
            _vm.expanded[i] = !_vm.expanded[i];
            _vm.highlighted = i;
          }},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{ref:"``",refInFor:true,attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)],1)]},proxy:true}],null,true)},[_vm._l((item.children),function(child,j){return [_c('v-list-item',{key:j,class:[_vm.subhighlighted === j && 'v-list-item-item__highlighted'],staticStyle:{"padding":"0px"},on:{"click":() => _vm.onClick(child, item)}},[_c('v-list-item-action',{staticClass:"my-0 mx-2"},[(child.type && child.type.includes('boolean'))?_c('v-checkbox',{attrs:{"disabled":"","input-value":_vm._f("json")(_vm.data,child.key ? child.key : child.id)}}):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(child.title)}})],1)],1),(child.divider)?_c('v-divider',{key:`d-${j}`}):_vm._e()]})],2):_c('v-list-item',{key:i,class:[
          _vm.highlighted === i &&
            _vm.subhighlighted === -1 &&
            'v-list-item-item__highlighted',
        ],attrs:{"dense":""},on:{"click":() => _vm.onClick(item)}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1),(item.divider)?_c('v-divider',{key:`d-${i}`}):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }