/**
 * Tabs da ficha
 */
export const tabs = [
  {
    title: "Principal",
    item: "tab-principal",
  },
  {
    title: "Séries",
    item: "tab-series",
    dependence: "nfce_serie",
    hidden(this: any) {
      return this.$useSat || this.$emitente.mei;
    },
  },
  {
    title: "Inutilização",
    item: "tab-inutilizacao",
    hidden(this: any) {
      return this.$useSat || this.$emitente.mei;
    },
  },
];

/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = [];

/**
 * Dependências do módulo
 */
export const dependencies = [
  {
    tab: "tab-series",
    name: "series",
    modulo: "cupons-series",
  },
  {
    tab: "tab-inutilizacao",
    name: "inutilizacoes",
    modulo: "cupons-inutilizacoes",
  },
];
