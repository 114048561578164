import estoque from "./estoque";
import entrada from "./entrada";
import payments from "./payments";
import contas from "./contas";
import consulta from "./consulta";

export default {
  modules: {
    estoque,
    entrada,
    payments,

    contas,
    consulta,
  },
};
