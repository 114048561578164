import Vue from "vue";

import { focusNextTextField } from "@/utils";
import { debounce, get, isObject } from "lodash";

// Exporta o mixin
export default Vue.extend({
  props: {
    value: { default: "" },
    color: { default: "" },
    textColor: { default: "" },
    label: { required: false },
    maxlength: { default: "-1" },
    placeholder: { required: false },
    withoutCols: { type: Boolean },
    ignoreTyped: { type: Boolean, default: false },
    name: { type: String, required: false },
    type: { type: String, default: "text" },
    inputStyle: { type: String, default: "" },
    readonly: { type: Boolean, default: false },
    listenForSearch: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    autoFocus: { type: Boolean, default: false },
    autocomplete: { type: String, default: null },
    ignoreEnterFocus: { type: Boolean, default: false },
    emitShiftEnter: { type: Boolean, default: false },
    ignoreFocusNextField: { type: Boolean, default: false },
    clear: { type: Boolean, default: false, required: false },
    white: { type: Boolean, default: false, required: false },
    forceChangeOnBlur: { type: Boolean, default: false },
    // context: { type: Array, default: () => [] },
  },
  /**
   * Vue data
   */
  data: () => ({
    value_: "",
    focused: false,
    typed: false,

    unwatchAutoFocus: (() => null) as () => void,
  }),
  created() {
    // this.unwatchAutoFocus = this.$watch("fetching", (fetching, wasFetching) => {
    //   // Remove o watch
    //   this.unwatchAutoFocus();
    //   // Se não estiver mais carregando mas estava antes
    //   if (!fetching && wasFetching) {
    //     // Se for autofocus
    //     if (this.autoFocus) {
    //       // Faz o foco
    //       this.focus();
    //     }
    //   }
    // });
  },
  computed: {
    inputId(this: any): string {
      // df = DontFocus
      return this.ignoreEnterFocus
        ? `df-input-${this._uid}`
        : `input-${this._uid}`;
    },
    autoComplete_(): any {
      return this.autocomplete ? this.autocomplete : Math.random();
    },
    /**
     * Função que retorna as classes do input
     */
    inputClass(): string[] {
      return [
        // this.ignoreEnterFocus && "ignore-enter-focus",
        this.white ? "text--white" : "",
        this.white
          ? ""
          : (this.color || "").startsWith("text--") ||
            (this.color || "").endsWith("--text")
          ? this.color
          : "text--" + this.color,
        this.textColor,
        "text-sm-caption text-md-body-2",
      ];
    },
  },
  methods: {
    onSearch: debounce(function (this: any, value: string) {
      this.$emit("search", value);
    }, 400),
    /**
     * Retorna a referência do input
     */
    getRef(): Element | Vue | (Element | Vue)[] | undefined {
      // Captura a ref atraves do vuetify
      const ref = get(this.$refs, "input.$refs.input");

      // Retorna a ref do vuetify ou senao, captura a ref do clear text field
      return ref ?? get(this.$refs, "input");
    },
    /**
     * Função ativada toda vez que for digitado algum valor no input
     * @param {any} event
     */
    // onInput: debounce(function (this: any, event: any): void {
    //   console.log(event, this)
    //   // coalesce do value
    //   const value = isObject(event) ? (event as any).target.value : event;

    //   // propaga o evento
    //   this.$emit("input", value, event);

    //   // Se não foi digitado ainda, define como já digitado
    //   if (!this.typed) this.typed = true;

    //   // se ouvir search
    //   if (this.listenForSearch) {
    //     // faz o search
    //     this.onSearch(value);
    //   }
    // }, 1),
    onInput(event: any): void {
      // coalesce do value
      const value = isObject(event) ? (event as any).target.value : event;

      // propaga o evento
      this.$emit("input", value, event);

      // Se não foi digitado ainda, define como já digitado
      if (!this.typed) this.typed = true;

      // se ouvir search
      if (this.listenForSearch) {
        // faz o search
        this.onSearch(value);
      }
    },
    /**
     * Função ativada quando é feito focus no input
     * @param {FocusEvent} event
     */
    onFocus(this: any, event): void {
      // Propaga o evento de focus
      this.$emit("focus", event);

      // Diz que está no foco
      this.focused = true;

      // Armazena o valor antes de editar o campo
      this.value_ = event.target.value;

      // Seleciona o conteúdo do input
      this.getRef() && this.getRef().select();
    },
    /**
     * Função executada no blur do input
     * @param {FocusEvent} event
     */
    onBlur(event): void {
      // Propaga o evento blur
      this.$emit("blur", event, this.value, this.value_);

      // Diz que não está no foco
      this.focused = false;

      // Compara se o valor atual é diferente do antigo
      if (
        (event.target.value !== this.value_ &&
          (this.typed || this.ignoreTyped)) ||
        this.forceChangeOnBlur
      )
        // Se for, emite o evento change (alterou de um valor para o outro)
        this.$emit("change", event.target.value, this.value_);

      // Limpa o valor antigo para nulo
      this.value_ = "";
      this.typed = false;
    },
    /**
     * Função que avança para o próximo textfield selecionável
     * @param {Event} event
     */
    next(this: any, event, id = null): any {
      return focusNextTextField(event, id ?? this.inputId);
    },
    /**
     * Função que ouve os eventos keypress
     * @param {KeyboardEvent} event
     */
    onKeyDown(event): void {
      // Propaga o evento
      this.$emit("keydown", event);

      // Se for um evento de Ctrl+Delete
      if (event.ctrlKey && event.key === "Delete")
        // Faz o prevent do evento e emite um novo evento de delete
        event.preventDefault(), this.$emit("ctrl-delete", event);

      // Se for o evento de enter
      if (event.key === "Enter") {
        // Se nao for shifted
        if (!event.shiftKey || this.emitShiftEnter) this.$emit("enter", event);

        // Se ignorar o focus
        if (this.ignoreFocusNextField)
          // Retorna
          return;

        // Emite o evento enter e avança para o próximo campo
        return this.next(event);
      }
    },
    /**
     * Função que ouve os eventos keypress
     * @param {KeyboardEvent} event
     */
    onKeyPress(this: any, event): void {
      // Propaga o evento
      this.$emit("keypress", event);
    },
    /**
     * Função que faz o foco do input
     */
    focus(this: any): void {
      // se for no android, não faz o foco
      if (this.$isAndroid) return;

      // Aguarda o próximo tick
      setTimeout(() => {
        // Ativa o focus do input
        this.getRef() && this.getRef().focus();
      }, 40);
    },
    /**
     * Função que faz o select do input
     */
    select(this: any): void {
      // Aguarda o próximo tick
      setTimeout(() => {
        // Ativa o select do input
        this.getRef() && this.getRef().select();
      }, 40);
    },
  },
  /**
   * Vue mounted Hook
   */
  mounted(this: any): void {
    // Emite o evento de boot
    this.$emit("booted");
    // Se for autofocus
    // if (this.autoFocus && !this.fetching) {
    if (this.autoFocus && !this.$isMobile) {
      // Faz o foco
      setTimeout(() => {
        this.focus();
      }, 40);
    }
  },
  watch: {
    value(this: any): void {
      if (!this.typed && this.focused)
        setTimeout(() => {
          this.getRef() && this.getRef().select();
        }, 40);
    },
  },
});
