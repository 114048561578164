import Vue from "vue";
import VueRouter from "vue-router";

import { stepContainer } from "@/mapping/constants";

// eslint-disable-next-line no-unused-vars
import type { Route } from "vue-router";
import compact from "lodash/compact";
import get from "lodash/get";

// Faz o uso da rota
Vue.use(VueRouter);

/**
 * Função mapeadora das props das rotas de fichas
 * @param route Rota
 */
const action = (route: Route) => ({
  id: route.params.id,
  modulo: route.params.modulo,
  action: route.params.action,
  last:
    get(route, "params.modulo") === "cupons-fiscais" ? route.params.last : null,
  import: route.name === "step-container" ? route.params.import : null,
  import_registro_id:
    route.name === "step-container" ? route.params.import_registro_id : null,
});

/**
 * Função mapeadora das props das rotas de módulos
 * @param route Rota
 */
const modulo = (route: Route) => ({
  modulo: route.params.modulo,
});

/**
 * Função mapeadora das props das rotas de relatórios
 * @param route Rota
 */
const report = (route: Route) => {
  // Tenta
  try {
    // Retorna o objeto
    return {
      // As colunas selecionadas do relatório, se houver
      colunas: JSON.parse(
        window.atob(route.query.colunas?.toString()).replace(/(-)/gi, "/")
      ),
    };
  } catch (_) {
    // Caso haja erros, retorna null
    return null;
  }
};

export default new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    //https://router.vuejs.org/guide/advanced/scroll-behavior.html
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: compact([
    process.env.VUE_APP_MODE === "development"
      ? { path: "/testing", component: () => import("@/views/Test.vue") }
      : null,
    {
      path: "/auth",
      redirect: "/entrar",
      component: () =>
        import(/* webpackChunkName: "auth" */ "@/layouts/AuthLayout.vue"),
      children: [
        {
          path: "/entrar",
          name: "login",
          alias: "/auth/entrar",
          component: () =>
            import(/* webpackChunkName: "auth" */ "@/views/auth/Login.vue"),
        },
        {
          path: "/registro",
          name: "old-register",
          alias: "/auth/registro",
          // component: () =>
          //   import(/* webpackChunkName: "auth" */ "@/views/auth/Register.vue"),
          redirect: () => "/entrar",
        },
        {
          path: "/reginterno",
          name: "register",
          alias: "/auth/reginterno",
          component: () =>
            import(/* webpackChunkName: "auth" */ "@/views/auth/Register.vue"),
        },
        {
          path: "/registro/emitente",
          name: "register-emitente",
          alias: "/auth/registro/emitente",
          component: () =>
            import(/* webpackChunkName: "auth" */ "@/views/auth/Emitente.vue"),
          props: (route: Route) => route.params,
        },
        {
          path: "/registro/usuario",
          name: "register-usuario",
          alias: "/auth/registro/usuario",
          component: () =>
            import(/* webpackChunkName: "auth" */ "@/views/auth/Usuario.vue"),
          props: (route: Route) => route.params,
        },
      ],
    },
    {
      path: "/",
      props: true,
      redirect: "home",
      component: () =>
        import(/* webpackChunkName: "main" */ "@/layouts/MainLayout.vue"),
      children: [
        {
          path: "home",
          name: "home",
          // component: () => import("@/views/home/Novidades.vue"),
          component: () =>
            import(/* webpackChunkName: "main" */ "@/views/home/Dashboard.vue"),
        },
        {
          path: "indicadores",
          name: "graphics",
          component: () =>
            import(
              /* webpackChunkName: "dev" */ "@/views/graphics/Graphics.vue"
            ),
        },
        {
          path: "resolucao",
          name: "resolucao",
          component: () =>
            import(
              /* webpackChunkName: "dev" */ "@/views/devtools/Resolucao.vue"
            ),
        },
        {
          path: "config",
          name: "config",
          component: () =>
            import(
              /* webpackChunkName: "config" */ "@/views/config/Config.vue"
            ),
          children: [
            {
              path: "formas-pagamento",
              name: "formas-pagamento",
              component: () =>
                import(
                  /* webpackChunkName: "config" */ "@/views/config/FormasPagamento.vue"
                ),
            },
            {
              path: "estatisticas",
              name: "stats",
              component: () =>
                import(
                  /* webpackChunkName: "config" */ "@/views/config/Stats.vue"
                ),
            },
            {
              path: "reversao",
              name: "reversion",
              component: () =>
                import(
                  /* webpackChunkName: "config" */ "@/views/config/Reversion.vue"
                ),
            },
            {
              path: "sobre",
              name: "about",
              component: () =>
                import(
                  /* webpackChunkName: "config" */ "@/views/config/About.vue"
                ),
            },
            {
              path: "renovacao",
              name: "renew-serial",
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "config" */ "@/views/config/RenewSerial.vue"
                ),
            },
            {
              path: "meus-dados",
              name: "my-data",
              props: true,
              component: () =>
                import(
                  /* webpackChunkName: "config" */ "@/views/config/MyData.vue"
                ),
            },
            {
              path: "novidades",
              name: "news",
              component: () =>
                import(
                  /* webpackChunkName: "config" */ "@/views/config/News.vue"
                ),
            },
            {
              path: ":modulo(emitente|sistema|nfe|nfse|cupons|contabil)",
              name: "config-ficha",
              component: () =>
                import(
                  /* webpackChunkName: "core" */ "@/views/modulo/ficha/TabContainer.vue"
                ),
              props: modulo,
            },
            {
              path: "*",
              redirect: { name: "config" },
            },
          ],
          //   },
          // ],
        },
        {
          path: "relatorios",
          component: () =>
            import(/* webpackChunkName: "core" */ "@/layouts/ReportLayout.vue"),
          children: [
            {
              name: "report",
              path: ":modulo/:id/:registro?",
              props: report,
              component: () =>
                import(
                  /* webpackChunkName: "core" */ "@/views/reports/Report.vue"
                ),
            },
          ],
        },
        /**
         * Rota dinâmica dos módulos
         */
        {
          path: `:modulo`,
          name: "modulo",
          component: () =>
            import(/* webpackChunkName: "core" */ "@/views/modulo/Modulo.vue"),
          props: modulo,
          children: [
            /**
             * Fichas de documentos (Steps)
             */
            {
              path: `/:modulo(${stepContainer})/:action(novo|alterar)/:id?`,
              name: "step-container",
              component: () =>
                import(
                  /* webpackChunkName: "core" */ "@/views/modulo/ficha/StepContainer.vue"
                ),
              props: action,
            },
            /**
             * Fichas dos outros módulos (Tabs)
             */
            {
              path: "/:modulo/:action(novo|alterar)/:id?",
              name: "tab-container",
              component: () =>
                import(
                  /* webpackChunkName: "core" */ "@/views/modulo/ficha/TabContainer.vue"
                ),
              props: action,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      component: () => import(/* webpackChunkName: "core" */ "@/views/404.vue"),
    },
  ]),
});
