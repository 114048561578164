<template>
  <!-- <div
    class="clear-text-field v-input__slot"
    :class="{ outlined, medium, sale }"
  >
    <div class="v-text-field__slot">
      <input
        ref="input"
        style="padding-left: 8px; cursor: initial !important"
        :id="`input-${_uid}`"
        :type="type"
        :name="name"
        :value="value"
        :class="inputClass"
        :style="inputStyle"
        :maxlength="maxlength"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        :autocomplete="autoComplete_"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keydown="onKeyDown"
        @keypress="onKeyPress"
        v-bind="$attrs"
      />
    </div>
    <div
      class="v-input__append-inner"
      @click.stop="$emit('append-inner', $event)"
      v-if="hasSlot('append')"
    >
      <slot name="append" />
    </div>
  </div> -->

  <div
    class="clear-text-field"
    :class="{ outlined, medium: medium || hasSlot('append'), sale }"
  >
    <v-input dense hide-details>
      <input
        ref="input"
        style="padding-left: 8px; cursor: initial !important"
        :id="`input-${_uid}`"
        :style="inputStyle"
        :class="inputClass"
        :type="type"
        :name="name"
        :value="value"
        :maxlength="maxlength"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        :autocomplete="autoComplete_"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keydown="onKeyDown"
        @keypress="onKeyPress"
        v-bind="$attrs"
      />

      <template #append>
        <slot name="append" />
      </template>
    </v-input>
  </div>
</template>

<script>
import textfield from "./mixin";

export default {
  mixins: [textfield],
  props: {
    outlined: { type: Boolean, default: false },
    medium: { type: Boolean, default: false },
    sale: { type: Boolean, default: false },
  },
  methods: {
    /**
     * Função que verifica a existência de um slot
     */
    hasSlot(name = "default") {
      // Retorna se tem slot
      return this.$slots && this.$slots[name];
    },
  },
};
</script>

<style lang="scss">
.clear-text-field {
  margin-bottom: 0px !important;

  &.sale {
    width: 100%;
    height: 60px;

    .v-input .v-input__control .v-input__slot {
      border: 1px solid rgba(108, 133, 147, 0.4) !important;
      border-radius: 4px;
      // border: 1px solid red !important;
      input {
        height: 58px !important;
        max-height: 60px !important;
        font-size: 40px !important;
        font-weight: 500;
        color: #ffffff !important;
        padding: 0px 5px 0px 5px;
      }
    }

    &.text-right {
      input {
        text-align: right !important;
      }
    }
  }

  &.text--white .v-input__control .v-input__slot {
    input {
      color: #ffffff !important;
      caret-color: #ffffff !important;
    }
  }

  &:not(.text--white) .v-input__control .v-input__slot {
    input {
      &.red {
        color: var(--v-error-base);
      }
      &.blue {
        color: var(--v-info-base);
      }
    }
  }

  &.outlined {
    border: thin solid #e0e0e0;
    border-collapse: collapse;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
  }

  .v-input {
    .v-input__append-outer,
    .v-input__prepend-outer {
      margin: 0px;
    }

    .v-input__control .v-input__slot {
      input.text--white {
        color: #ffffff !important;
        caret-color: #ffffff !important;
      }

      input:not(.text--white) {
        &.red {
          color: var(--v-error-base);
        }
        &.blue {
          color: var(--v-info-base);
        }
      }

      // .v-text-field__slot {
      width: 100%;

      input {
        width: 100%;
        height: 22px;
        max-height: 50px !important;
        background: transparent !important;
        outline: none;

        color: var(--v-text-base);

        &.white {
          color: #ffffff !important;
          caret-color: #ffffff !important;
        }

        &:not(.white) {
          &.red {
            color: var(--v-error-base);
          }
          &.blue {
            color: var(--v-info-base);
          }
        }
      }
    }
  }

  &.medium {
    .v-input .v-input__control .v-input__slot input {
      height: 28px !important;
    }
  }
}

.theme--light .clear-text-field {
  &.text--disabled {
    input {
      color: rgba(71, 71, 71, 0.5) !important;
    }
  }
}

.theme--dark .clear-text-field {
  &.text--disabled {
    input {
      color: rgba(255, 255, 255, 0.5) !important;
    }
  }
}
</style>
