<template>
  <div v-bind="$attrs">
    <v-row :class="[miniLayout && 'ma-0', !miniLayout && 'mt-2']">
      <select-filter
        return-object
        observe-items
        item-value="id"
        label="Forma de Pagamento"
        :value-not-null="!miniLayout"
        :auto-focus="autoFocus"
        :md="miniLayout ? '12' : extraTemplate ? '8' : '6'"
        :offset-md="miniLayout ? '0' : extraTemplate ? '2' : '0'"
        :item-texts="['codigo', 'descricao']"
        :items="meios"
        :disabled="disabled"
        :loading="loading"
        :value="data.pagamento ? data.pagamento.forma : null"
        @input="data.pagamento.forma = $event"
        @change="(value) => onChange(value, 'meio_pagamento_id')"
      />

      <template v-if="isPrazo">
        <text-field
          md="6"
          label="Quantia de Parcelas"
          ref="next"
          :disabled="disabled"
          v-model="data.pagamento.duplicatas"
          @blur="generateParcels"
          maxlength="2"
        />

        <v-col class="px-1">
          <div :style="`height: ${300}px !important`">
            <parcelas
              :__ficha="__ficha"
              :items="parcels"
              :modulo="modulo"
              :total="data.vlr_total"
              :loading="loading"
            />
          </div>
        </v-col>
      </template>

      <template v-if="isCard">
        <select-filter
          value-not-null
          observe-items
          return-object
          ref="next"
          label="Bandeira"
          item-value="id"
          :md="
            data.pagamento.bandeira && data.pagamento.bandeira.tipo === 'C'
              ? '4'
              : '6'
          "
          :disabled="disabled"
          :items="bandeiras"
          :item-texts="['tipo_descricao', 'descricao']"
          :loading="loading"
          v-model="data.pagamento.bandeira"
          @change="(value) => onChange(value, 'bandeira')"
        />
        <text-field
          v-if="data.pagamento.bandeira && data.pagamento.bandeira.tipo === 'C'"
          md="2"
          ref="next"
          label="Parcelas"
          :disabled="disabled"
          v-model="data.pagamento.parcelas"
          @change="(value) => onChange(value, 'parcelas')"
        />
      </template>

      <template v-if="isOutros">
        <text-field
          md="6"
          maxlength="60"
          :disabled="disabled"
          label="Justificativa do pagamento"
          ref="next"
          v-model="data.pagamento.xPag"
          @change="(value) => onChange(value, 'xPag')"
        />
      </template>
    </v-row>
  </div>
</template>

<script>
import { get, head, includes, isEmpty, pick, sumBy } from "lodash";

import SelectFilter from "../inputs/SelectFilter.vue";
import TextField from "../inputs/textfield/TextField.vue";
import Parcelas from "./Parcelas.vue";

import actions from "@/store/actions";
import mutations from "@/store/mutations";
import { clearCurrency } from "@/utils";
import { CODIGOS_PAGAMENTO_PRAZO } from "@/mapping/constants";

export default {
  props: {
    __ficha: {},

    disabled: { default: false, type: Boolean },
    data: { default: () => ({}) },
    autoFocus: { default: false, type: Boolean },
  },
  components: {
    Parcelas,
    SelectFilter,
    TextField,
  },
  data: () => ({
    loading: true,
    meios: [],
    bandeiras: [],
  }),
  methods: {
    update(data = {}) {
      this.$store
        .dispatch(actions.CORE.POST, {
          path: "/pagamentos",
          data,
          registro_id: this.data.id,
          document: this.modulo,
        })
        .then((data) => {
          if (data && data.registro) {
            this.__ficha.append(pick(data.registro, "pagamento"));
          }
        });
    },
    loadBandeiras() {
      this.loading = true;

      this.$store
        .dispatch(actions.CORE.READ, {
          path: "cadastro-bandeiras",

          // Params
          type: "registros",
          ordered: true,
        })
        .then((data) => {
          this.bandeiras = data.registros;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    generateParcels(_, value, oldValue) {
      const force = value === "" || value == "0";

      value = parseInt(value || 0);
      oldValue = parseInt(oldValue || 0);

      const totalDoc = clearCurrency(
        get(this.__ficha, "data.vlr_total", 0.0)
      ).toFixed(2);

      const totDup = sumBy(this.parcels, (dup) =>
        clearCurrency(get(dup, "vlr_total", 0))
      ).toFixed(2);

      const quantity = Math.max(value, 1);

      // se a quantidade é a mesmo mas o total das parcelas ainda bate com o total do documento, retorna
      if (value === oldValue && totDup === totalDoc && !force) {
        return;
      }

      this.loading = true;

      this.update({
        duplicatas: quantity,
      });

      this.data.pagamento.duplicatas = quantity;

      this.$store
        .dispatch(actions.MODULOS.PAYMENTS.PARCELS, {
          id: this.data.id,
          modulo: this.modulo,
          vlr_total: this.data.vlr_total,
          quantity,
        })
        .then(({ data }) => {
          // const registro = {
          //   ...(data.parent ?? {}),
          // };

          // set(registro, "billing.parcels", data.registros ?? []);

          this.__ficha.afterUpdate({
            parcelas_json: get(data, "registros", []),
          });

          this.$nextTick(() => {
            this.$store.commit(mutations.MODULO.REGISTROS.UPDATED, {
              ...this.data,
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkPayment() {
      if (includes(["orcamento", "ordem-servico"], this.modulo)) return;

      if (isEmpty(get(this.data, "pagamento.forma"))) {
        this.data.pagamento = {
          forma: head(this.meios),
        };
      }
    },
    onChange(value, name) {
      // se apagou o pagamento
      if (!value && name === "meio_pagamento_id") {
        // Faz update
        return this.update({
          type: "delete",
        });
      }

      if (name === "meio_pagamento_id") {
        // se foi escolhido cartão e não tiver carregado as bandeiras, tenta carregar
        if (value.codigo === "03/04" && !this.bandeiras.length) {
          this.loadBandeiras();
        }

        // ajusta para pegar o id do meio de pagamento
        value = get(value, "id");
      }

      let data = { [name]: value };

      if (name === "bandeira") {
        this.$refs.parcelas && this.$refs.parcelas.focus();
        data = {
          bandeira_id: get(value, "id"),
          ...pick(value, ["clifor_id", "tipo"]),
        };
      }

      // Faz update
      this.update(data);

      setTimeout(() => {
        if (name === "parcelas" || get(data, "tipo") === "D") return;

        const el = get(
          this.$refs,
          "next.focus",
          get(this.$refs, "next.focusTextField")
        );

        if (el) el();
      }, 40);
    },
  },
  created() {
    this.$store
      .dispatch(actions.CORE.READ, {
        path: "meios-pagamentos",

        // Params
        type: "registros",
        ordered: true,
      })
      .then((data) => {
        this.meios = data.registros;

        this.$nextTick(() => {
          this.checkPayment();
        });
      })
      .finally(() => {
        this.loading = false;
      });

    if (this.codigo === "03/04" && !this.bandeiras.length) {
      this.loadBandeiras();
    }
  },
  computed: {
    codigo() {
      return get(this.data, "pagamento.forma.codigo");
    },
    isPrazo() {
      return !this.miniLayout && CODIGOS_PAGAMENTO_PRAZO.includes(this.codigo);
    },
    isCard() {
      return !this.miniLayout && this.codigo === "03/04";
    },
    isOutros() {
      return !this.miniLayout && this.codigo === "99";
    },
    extraTemplate() {
      const cod = this.codigo;
      return (
        !CODIGOS_PAGAMENTO_PRAZO.includes(this.codigo) &&
        cod !== "03/04" &&
        cod !== "99"
      );
    },
    modulo() {
      return get(this.__ficha, "modulo");
    },
    miniLayout() {
      return ["orcamento", "ordem-servico"].includes(this.modulo);
    },
    parcels() {
      return get(this.data, "parcelas_json") ?? [];
    },
  },
};
</script>
