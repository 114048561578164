var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',{staticClass:"pb-1 pt-2 px-1",attrs:{"cols":_vm.cols,"sm":_vm.sm,"md":_vm.md,"offset-md":_vm.offsetMd,"lg":_vm.lg,"xl":_vm.xl,"id":`col-${_vm._uid}`}},[_c('v-combobox',_vm._b({directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],ref:"combobox",attrs:{"dense":"","outlined":"","hide-details":"","hide-no-data":"","id":_vm.internalId,"value":_vm.value,"item-value":_vm.itemValue,"item-text":_vm.itemText,"items":_vm.internalItems,"filter":_vm.filter,"menu-props":_vm.menuProps},on:{"input":_vm.onInput,"change":_vm.onChange,"blur":_vm.onBlur,"focus":_vm.onFocus,"click:append-outer":_vm.$listeners['click:append-outer']},nativeOn:{"input":function($event){return _vm.onNativeInput.apply(null, arguments)},"keydown":function($event){return _vm.onKeyDown.apply(null, arguments)}},scopedSlots:_vm._u([{key:"item",fn:function({ item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"caption",domProps:{"textContent":_vm._s(
              `${
                (typeof _vm.itemText === 'function'
                  ? _vm.itemText(item)
                  : item[_vm.itemText]) || ''
              }`
            )}}),_vm._t("subtitle",function(){return [_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                (typeof _vm.itemSubtitle === 'function'
                  ? _vm.itemSubtitle(item)
                  : item[_vm.itemSubtitle]) || ''
              )}})]},null,{ item })],2)],1)]}}],null,true)},'v-combobox',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }