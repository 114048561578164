/* eslint-disable no-unused-vars */
import { isNull, isUndefined } from "lodash";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moment, { Moment } from "moment";

moment.locale("pt-br");

export default moment;

export const FormatUnix = "x";
export const FormatDate = "DD/MM/YYYY";
export const FormatTime = "HH:mm";
export const FormatTimestamp = `${FormatDate} ${FormatTime}`;

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d.getTime());
};

/**
 * Função que valida o value e retorna a data atual ou baseada no informado
 *
 * @param {any} value
 * @returns {Moment}
 */
const assert = (value: any = undefined) => {
  return isNull(value) ||
    value == "0" ||
    value == "" ||
    isUndefined(value) ||
    !isValidDate(new Date(value))
    ? moment()
    : moment(value);
};

export const endOfDay = (value?: any) => {
  return parseFloat(assert(value).endOf("day").format(FormatUnix));
};

export const startOfDay = (value?: any) => {
  return parseFloat(assert(value).startOf("day").format(FormatUnix));
};

export const milliseconds = (value?: any) => {
  return parseFloat(assert(value).format(FormatUnix));
};

export const mDate = (value?: any) => {
  return assert(value).format(FormatDate);
};

export const mTime = (value?: any) => {
  return assert(value).format(FormatTime);
};

export const mTimestamp = (value: any = undefined) => {
  return assert(value).format(FormatTimestamp);
};

export const startOfDaySubtract = (amount = "30", unit = "days") => {
  return startOfDay(assert().subtract({ [unit]: amount }));
};

export const startOfDayAdd = (amount = "30", unit = "days") => {
  return startOfDay(assert().add({ [unit]: amount }));
};

export const mNow = (value?: any) => {
  return assert(value);
};

export const mDay = (value?: any) => {
  const days = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  return days[assert(value).day()];
};
