import { filter, includes, get, pick, compact, endsWith, concat } from "lodash";

import { filterTypes, isNull, today } from "@/utils/filters";

import { endOfDay, milliseconds, startOfDaySubtract } from "@/plugins/moment";

import { equals, _in, notIn } from "@/utils/filters";

import _actions from "@/store/actions";

import DFESTATUS, {
  AUTHORIZED,
  CANCELLED,
  DENIED,
  PROCESSING,
} from "@/mapping/status";
import { isCpf } from "@/utils";

// Retorna se a NF está ou não autorizada
export const isAuthorized = (registro) =>
  !registro || !registro.nfe_status
    ? false
    : includes(AUTHORIZED, registro.nfe_status);

// Retorna se a NF está ou não cancelada
export const isCancelled = (registro) =>
  !registro || !registro.nfe_status_descricao
    ? false
    : includes(CANCELLED, registro.nfe_status);

/**
 * Colunas do Grid
 */
export const columns = [
  { text: "NF", value: "numero_nf", maxWidth: "86px" },
  { text: "Série", value: "serie" },
  {
    text: "Status da NF-e",
    value: "nfe_status",
    hidden: true,
  },
  {
    text: "Status da NF-e",
    value: "nfe_status_descricao",
    document: "nfe",
    action: "dfe_transmitir",
    type: "value-icon",
    align: "left",
    maxWidth: "245px",
  },
  { text: "Emissão", value: "emissao_dt", type: "timestamp" },
  { text: "Data Saída", value: "saida_at", type: "timestamp" },
  { text: "Cliente", value: "clifor_razao_social" },
  { text: "Natureza Operação", value: "natureza_descricao" },
  { text: "Vendedor", value: "colaborador_razao_social" },
  { text: "Produtos", value: "vlr_produtos", align: "right" },
  { text: "Serviços", value: "vlr_servicos", align: "right" },
  { text: "Desconto", value: "vlr_desconto", align: "right" },
  { text: "Total", value: "vlr_total", align: "right" },
  { text: "ICMS", value: "vlr_icms", align: "right" },
  { text: "ISS", value: "vlr_iss", align: "right" },
  { text: "IPI", value: "vlr_ipi", align: "right" },
  { text: "Frete", value: "vlr_frete", align: "right" },
  { text: "Seguro", value: "vlr_seguro", align: "right" },
  { text: "Outras Despesas", value: "vlr_despesas", align: "right" },
  { text: "ICMS Sub. Trib.", value: "vlr_icms_st", align: "right" },
  { text: "Base Sub. Trib.", value: "vlr_bc_icms_st", align: "right" },
  {
    text: "Transportadora",
    value: "transportadora_razao_social",
  },
  { text: "Base de ICMS", value: "vlr_bc_icms", align: "right" },
  { text: "Base de ISS", value: "vlr_bc_iss", align: "right" },
  {
    id: "xml_cce",
    text: "CC-e XML",
    value: "tem_xml_cce",
    action: "xml",
    type: "xml",
    hiddenReport: true,
  },
  {
    document: "nfe",
    text: "ID da NF-e",
    value: "nfe_id",
    action: "dfe_consultar_chave",
  },
  {
    id: "xml_nfe",
    text: "NF-e XML",
    value: "tem_xml_nfe",
    action: "xml",
    type: "xml",
    hiddenReport: true,
  },
];

/**
 * Função que valida se o registro pode ou não ser alterado
 */
export const validateForEditAction = function (registro) {
  // A NF-e não pode estar cancelada, nem autorizada e nem referenciada como NF-e de entrada
  return (
    !!get(registro, "id") &&
    !includes(
      concat(AUTHORIZED, DFESTATUS, PROCESSING),
      get(registro, "nfe_status")
    ) &&
    !registro.compra_id
  );
};

/**
 * Lista de identificadores para a ficha
 */
export const identificadores = new Array(1)
  .fill(null)
  .map((_, i) => `lbl_tb_vendas_identificador_${i + 1}`);

/**
 * Ações do submenu
 */
export const subactions = function (this: any) {
  // Captura o registro selecionado
  // const cache = this.$store.getters[getters.APP.USER.CACHE]("saida");
  // const disabled = !this.registro || !this.registro.id;

  const registro = this.registro ?? {};

  return [
    {
      title: "Arquivo",
      items: compact([
        // {
        //   id: "exportar_nfe",
        //   title: !disabled
        //     ? `Exportar NF número ${this.registro.numero_nf} de ${this.registro.clifor}`
        //     : "Exportar NF-e",
        //   disabled
        // },
        // {
        //   id: "exportar_nfe_contabilidade",
        //   title: "Exportar NFs para contabilidade"
        // },
        {
          id: "dfe_importar_xml",
          title: "Importar XML NF-e",
          modulo: "saida",
        },
        {
          id: "dfe_exportar_xml",
          modulo: "saida",
          title: "Exportar NFs em arquivo XML",
          divider: true,
        },
        this.$emitente.modulo_ordem_servico
          ? {
              id: "import_document",
              import: "ordem-servico",
              title: "Importar Ordem de Serviço",
            }
          : false,
        {
          id: "import_document",
          import: "orcamento",
          title: "Importar Orçamento",
        },
        {
          divider: true,
          id: "import_document",
          import: "cupons-fiscais",
          title: `Importar ${this.$nomeCupomFiscal}`,
          // disabled: true,
        },
        {
          subheader: `Ações para a NFe ${registro.numero_nf || ""}/${
            registro.serie || ""
          }`,
        },
        {
          id: "dfe_generate_new_number",
          modulo: "saida",
          title: "Gerar numeração nova",
          disabled: !registro || !registro.id,
        },
        {
          id: "dfe_retorno_registro",
          modulo: "saida",
          type: "estoque",
          title: "Retorno de estoque",
          disabled: !registro || !registro.id || !isAuthorized(registro),
        },
        // {
        //   id: "transmitir_proximas",
        //   title: "NF-e - Transmitir as próximas"
        // }
      ]),
    },
    {
      title: "Relatórios",
      type: "report",
      items: reports.bind(this)(),
    },
    {
      title: "Exibir",
      items: [
        {
          id: "nf_venda_serie_1",
          title: "Notas Fiscais de saídas (vendas) série 001",
          // disabled: get(cache, "serie") === "001",
        },
        {
          id: "nf_venda_serie_2",
          title: "Notas Fiscais de saídas (vendas) série 002",
          // disabled: get(cache, "serie") === "002",
        },
        {
          id: "nf_venda_serie_especial",
          title: `Notas Fiscais de saídas (vendas) com CPF série ${this.$config.nfe_numero_serie_especial}`,
          disabled:
            !isCpf(this.$emitente.cpf_cnpj) ||
            !this.$config.nfe_numero_serie_especial,
        },
        {
          id: "nf_compra",
          title: "Notas Fiscais de entrada (compras)",
          divider: true,
          route: {
            name: "modulo",
            params: {
              modulo: "entrada",
            },
          },
        },
        {
          type: "filter",
          title: "Notas Fiscais de hoje",
          filter: today("emissao_dt"),
        },
        {
          type: "filter",
          title: "Notas Fiscais Autorizadas",
          filter: _in("nfe_status", AUTHORIZED, "authorized"),
        },
        {
          type: "filter",
          title: "Notas Fiscais Canceladas",
          filter: _in("nfe_status", CANCELLED, "cancelled"),
        },
        {
          type: "filter",
          title: "Notas Fiscais Rejeitadas",
          filter: notIn(
            "nfe_status",
            concat(AUTHORIZED, DFESTATUS),
            "rejeicao"
          ),
        },
        {
          type: "filter",
          title: "Notas Fiscais abertas",
          filter: [
            notIn(
              "nfe_status",
              concat(AUTHORIZED, DFESTATUS),
              "em_aberto",
              "or"
            ),
            isNull("nfe_status", null, "or"),
          ],
        },
        { type: "filter", title: "Todas", filter: null },
      ],
    },
  ];
};

/**
 * Relatórios
 */
export const reports = function () {
  // Captura o registro selecionado
  // const disabled = !this.registro || !this.registro.id;
  // const descricao = this.registro.descricao;

  // Define o intervalo de data de 1 mes
  const filterDateInterval = {
    // Iniciando 30 dias antes de hoje
    queryStart: startOfDaySubtract(),
    // Terminando hoje
    queryEnd: endOfDay(),
  };

  // Retorna a lista de relatórios
  return [
    {
      id: "relatorio_vendas",
      modulo: "estoque",
      title: "Vendas (Nota Fiscal)",
      type: "form-report",
      form: [
        {
          title: "Vendas (Nota Fiscal)",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
        {
          title: "Selecione abaixo as operações que devem ser listadas",
          search: {
            id: "cfops",
            target: "cfop",
            value: "(5|6|7)%",
            path: "/naturezas-operacao/search",
          },
          default: {
            cfops: [],
          },
          type: filterTypes.CFOP,
        },
        {
          title: "Escolha o modelo do relatório",
          type: filterTypes.CHOOSE_ONE,
          default: {
            choose: "icms",
          },
          options: [
            {
              text: "Relatório de ICMS",
              value: "icms",
            },
            {
              text: "Item por item",
              value: "items",
            },
          ],
        },
      ],
    },
    {
      id: "servicos_nf",
      modulo: "estoque",
      title: "Serviços (Nota Fiscal)",
      type: "form-report",
      form: [
        {
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },

    {
      id: "resumo_vendas",
      title: "Resumo das vendas",
      type: "form-report",
      form: [
        {
          title: "Resumo das vendas",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
        {
          title: "Selecione abaixo as operações que devem ser listadas",
          search: {
            id: "cfops",
            target: "cfop",
            value: "(5|6|7)%",
            path: "/naturezas-operacao/search",
          },
          default: {
            cfops: [],
          },
          type: filterTypes.CFOP,
        },
      ],
      divider: true,
    },
    {
      id: "vendas_por_vendedor",
      modulo: "cadastro",
      title: "Vendas por vendedor",
      type: "form-report",
      form: [
        {
          title: "Vendas por vendedor",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
        {
          title: "Selecione abaixo as operações que devem ser listadas",
          search: {
            id: "cfops",
            target: "cfop",
            value: "(5|6|7)%",
            path: "/naturezas-operacao/search",
          },
          default: {
            cfops: [],
          },
          type: filterTypes.CFOP,
        },
      ],
    },
    {
      id: "vendas",
      title: "Vendas (Cupom Fiscal)",
      modulo: "cupons-fiscais",
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
          children: [
            {
              label: "Número do Caixa",
              queryStart: "numero_caixa",
              default: { numero_caixa: "" },
            },
          ],
        },
      ],
    },
    {
      id: "cupom_monofasicos",
      title: "Produtos Monofásicos (Cupom Fiscal)",
      modulo: "cupons-fiscais",
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "venda_monofasicos",
      title: "Produtos Monofásicos (Nota Fiscal)",
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    // {
    //   id: "complemento_restituicao",
    //   title: "Complemento/Restituição por ICMS ST",
    // },
    // { id: "correlacao", title: "Correlação" },
  ];
};

/**
 * Ação de contexto da tabela
 *
 * Quando autorizada NF-e:
 * Ações Bloqueadas: 1,2,3,9,V,R,I
 *
 * Quando Rejeição:
 * Ações Bloqueadas: 4,7,V,C,I
 *
 * Quando Cancelado:
 * Ações Bloqueadas: 1,2,3,5,7,8,9,V,C,I
 */
export const context = function (this: any) {
  // Captura o registro selecionado

  // Define o desabilitado
  // const disabled = true;

  const nfeStatus = get(this.registro, "nfe_status");

  // Verifica se o documento está autorizado
  const authorized = includes(AUTHORIZED, nfeStatus);
  // Verifica se o documento está cancelado
  const cancelled = includes(CANCELLED, nfeStatus);

  const notRegistro = !get(this.registro, "id");

  const processing = includes(PROCESSING, nfeStatus);
  const denied = includes(DENIED, nfeStatus);

  const disabled = includes(DFESTATUS, nfeStatus) || notRegistro || processing;

  const document = "nfe";
  const id = `${this.registro?.numero_nf}/${this.registro?.serie}`;

  // Retorna a lista dos itens
  return compact([
    {
      document,
      id: "dfe_status_servico",
      icon: "mdi-bug",
      title: "Testar Servidor NF-e",
      code: "0",
      divider: true,
    },
    // this.$appMode !== "production"
    //   ? {
    //       document,
    //       id: "dfe_duplicidade_diferenca",
    //       title: "Duplicidade com Diferença na chNFe",
    //       hidden: nfeStatus !== "539",
    //     }
    //   : null,
    {
      document,
      id: "dfe_transmitir",
      title: "Enviar NF-e",
      code: "1",
      icon: "mdi-arrow-right-bold",
      hidden: disabled || notRegistro || authorized,
    },
    !disabled && !authorized && !cancelled
      ? {
          document,
          id: "dfe_gerar_xml",
          title: "Gerar XML",
          code: "1",
          icon: "mdi-xml",
          hidden: disabled || notRegistro,
        }
      : null,
    {
      document,
      id: "xml_nfe",
      action: "xml",
      title: "Visualizar XML",
      icon: "mdi-magnify",
    },
    {
      document,
      id: "dfe_consultar",
      title: "Consultar NF-e",
      code: "2",
      icon: "mdi-sync",
      hidden: notRegistro || cancelled,
    },
    {
      document,
      id: "dfe_cancelar",
      title: "Cancelar NF-e",
      code: "5",
      icon: "mdi-close",
      hidden: !authorized,
      divider: true,
    },
    {
      document,
      id: "dfe_visualizar_danfe",
      title: "Visualizar DANFE",
      code: "3",
      icon: "mdi-magnify",
      hidden: !authorized && !cancelled && !denied,
    },
    {
      document,
      id: "dfe_visualizar_danfe",
      type: "direct-print",
      title: "Imprimir DANFE",
      code: "3",
      icon: "mdi-printer",
      hidden: !authorized && !cancelled && !denied,
    },
    {
      document,
      type: "cancelamento",
      id: "dfe_visualizar_evento",
      title: "Imprimir Cancelamento",
      code: "3",
      icon: "mdi-printer",
      hidden: !cancelled,
    },
    {
      document,
      id: "dfe_enviar_da_xml_email",
      title: "Enviar o XML e DANFE por E-mail",
      code: "4",
      icon: "mdi-email",
      hidden: !authorized && !cancelled,
    },
    {
      id: "dfe_copiar_nfe_id",
      icon: "mdi-content-copy",
      code: "9",
      title: "Copiar Chave da NF-e",
      hidden: !this.registro.nfe_id,
      divider: true,
    },
    {
      document,
      id: "nfe_cce",
      title: "Carta de Correção Eletrônica (CC-e)",
      code: "7",
      icon: "mdi-email-edit",
      hidden: !authorized || cancelled,
      divider: true,
    },
    {
      id: "nfe_imprimir_cce",
      icon: "mdi-printer",
      title: "Imprimir Carta de Correção Eletrônica (CC-e)",
      code: "8",
      hidden: !this.registro.icce || parseInt(this.registro.icce) <= 1,
      divider: true,
    },
    {
      id: "etiquetas",
      title: "Etiquetas",
      descricao: `NF-e ${id}`,
      icon: "mdi-tag-outline",
    },
  ]);
};

/**
 * Relações do módulo que SÃO JSON
 */
// export const relations = [
//   {
//     name: "clifor",
//     modulo: "cadastro",
//     updateKeys: [
//       "id",
//       "nome_razao_social",
//       "cpf_cnpj",
//       "rg_ie",
//       "cep",
//       "endereco",
//       "bairro",
//       "telefone"
//     ]
//   },
//   {
//     name: "transportadora",
//     modulo: "transportadoras",
//     updateKeys: [
//       "id",
//       "cpf_cnpj",
//       "rg_ie",
//       "uf",
//       "municipio_id",
//       "endereco",
//       "telefone",
//       "veiculo_uf",
//     ]
//   }
// ];

/**
 * Steps da ficha
 */
export const steps = [
  {
    title: "Básico",
    item: 1,
  },
  // {
  //   title: "Itens",
  //   item: 2,
  //   rules: true
  // },
  {
    title: "Complemento",
    // title: (state) =>
    //   state.dfe.sending ? "Calculando NF-e..." : "Complemento",
    item: 2,
    rules: true,
  },
  {
    title: "Pagamentos",
    item: 3,
    rules: true,
  },
];

/**
 * Ficha default
 */
export const ficha = () => ({
  ativo: true,
  nfe_finalidade: "1",
  indicador_consumidor: "0",
  indicador_presenca: "1",
  qtd_volumes: "1",
  indicador_frete: "0",
  emissao_dt: milliseconds(),
  saida_at: milliseconds(),
  especie: "Caixa",
  marca: "Varias",
});

/**
 * Formata os dados da ficha antes de enviar pro backend (update/create)
 */
export const format = function (this: any) {
  return {
    clifor_id: get(this.data, "clifor_id", get(this.data, "clifor.id")),
  };
};

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = filter(columns, (column) =>
  // Retorna as colunas que as incluem
  includes(
    [
      "numero_nf",
      "serie",
      "emissao_dt",
      // "contato",
      // "cep",
      // "endereco",
      // "bairro",
      // "municipio",
    ],
    column.value
  )
);

/**
 * Dependências do módulo
 */
export const dependencies = [
  {
    name: "itens",
  },
  {
    name: "pagamentos",
  },
];

/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = [
  "vlr_total",
  "vlr_desconto",
  "clifor",
  "intermediador",
  "tem_intermediador",
  "transportadora",
  "transportadora_id",
  "vlr_pagamento_restante",
  "colaborador_id",
  "veiculo",
  "veiculo_id",
  "complemento",
];

/**
 * Filtros default
 */
const filtros = ({ serie }) => [
  // today("emissao_dt"),
  { ...equals("serie", [serie]) },
];

/**
 * Cache default this = current user cache
 */
export const cache = function (this: any) {
  const serie = (this && this.serie) ?? "001";

  return {
    selecionado: {
      index: 0,
      coluna: 0,
    },
    serie,
    colunas: [
      "numero_nf",
      "serie",
      "nfe_status_descricao",
      "emissao_dt",
      "clifor_razao_social",
      // "vlr_produtos",
      // "vlr_servicos",
      // "vlr_desconto",
      "vlr_total",
      "natureza_descricao",
      "nfe_id",
      "tem_xml_nfe",
    ],

    filtros: filtros({ serie }),
    ordenar: [
      {
        coluna: "numero_nf",
        ordem: "desc",
      },
    ],
  };
};

const attemptToTransmitByAssistent = function (this: any, params = {}) {
  return this.$store.dispatch(_actions.DFE.EMITIR, {
    ...params,
    isFromAssistent: true,
  });
};

const complemento_reference = {
  // Fechar o assistente após confirmar o erro
  ignoreErrors: true,
  /**
   * Função que retorna a mensagem inicial do assistente
   */
  message() {
    return `
      Informe a Chave de acesso da NF-e referenciada (ID da NF-e).
     `;
  },
  /**
   * Função que retorna o formulário a ser exibido no assistente
   */
  form() {
    return [
      {
        autoFocus: true,
        name: "nf_refs",
        label: "Chave da NF-e",
      },
    ];
  },
  /**
   * Função que é chamada quando clicado no botão ok do assistente
   *
   * retorna uma string representando um erro ou true para válido
   */
  validator(item) {
    if (!item || !item.nf_refs) return "empty";
    if (item.nf_refs.length < 44) return "min_44";
    return true;
  },
  /**
   * Função que retorna todos os erros
   */
  error() {
    return {
      empty: "A chave não pode ser vazia",
      min_44: "A chave precisa ter no mínimo 44 caracteres",
    };
  },
  /**
   * Função chamada caso passe pelo validator sem nenhum erro
   */
  success(this: any, item) {
    // Retorna a promessa da requisição
    return attemptToTransmitByAssistent.bind(this)({
      id: item.id,
      document: "nfe",
      ignoreMessage: true,
      data: { nf_refs: item.nf_refs },
    });
  },
};

/**
 * Configuração do assistente
 */
export const assistent = {
  // Identificador do assistente
  nfe_cancelar: {
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message(item) {
      return `
        Atenção<br>
        <br>Você está prestes a cancelar uma NF-e. O DANFE referente a esta NF-e se tornará inválido e não poderá ser usado para acompanhar a mercadoria.<br>
        <br>Para cancelar a NF-e: <strong>${item.numero_nf}/${item.serie}</strong>, insira uma justificativa (min. 15 caracteres).
      `;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      return [
        {
          autoFocus: true,
          name: "_nfe_justificativa",
          label: "Justificativa",
        },
      ];
    },
    /**
     * Função que é chamada quando clicado no botão ok do assistente
     *
     * retorna uma string representando um erro ou true para válido
     */
    validator(item) {
      if (!item || !item._nfe_justificativa) return "empty";
      if (item._nfe_justificativa.length < 15) return "min_15";
      return true;
    },
    /**
     * Função que retorna todos os erros
     */
    error() {
      return {
        empty: "A justificativa não pode ser vazia.",
        min_15: "A justificativa precisa ter no mínimo 15 caracteres.",
      };
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, data) {
      const document = data.modelo === 55 ? "nfe" : "nfce";

      // Retorna a promessa da requisição
      return this.$store.dispatch(_actions.DFE.CANCELAR, {
        data,
        document,
      });
    },
  },
  // Identificador do assistente
  duplicidade_diferenca_chnfe: {
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message() {
      return `
        Atenção<br>
      `;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    // form() {
    //   return [
    //     {
    //       autoFocus: true,
    //       name: "_nfe_justificativa",
    //       label: "Justificativa",
    //     },
    //   ];
    // },
    /**
     * Função que é chamada quando clicado no botão ok do assistente
     *
     * retorna uma string representando um erro ou true para válido
     */
    // validator(item) {
    //   if (!item || !item._nfe_justificativa) return "empty";
    //   if (item._nfe_justificativa.length < 15) return "min_15";
    //   return true;
    // },
    /**
     * Função que retorna todos os erros
     */
    // error() {
    //   return {
    //     empty: "A justificativa não pode ser vazia.",
    //     min_15: "A justificativa precisa ter no mínimo 15 caracteres.",
    //   };
    // },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, data) {
      // Retorna a promessa da requisição
      return this.$store.dispatch(_actions.DFE.DUPLICIDADE, {
        id: data.id,
        document: data.document,
      });
    },
  },
  // Identificador do assistente
  nfe_veiculos_itens: {
    // Tipo do assistente
    multiple: true,
    // Fechar o assistente após confirmar o erro
    ignoreErrors: true,
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message(item) {
      return `
        Detalhamento de veículos novos: <strong>${item.descricao}</strong>.
      `;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      return [
        {
          autoFocus: true,
          name: "chassi",
          label: "Chassi do veículo VIN",
        },
        {
          name: "cCor",
          label: "Cor Código de cada montadora",
        },
        {
          name: "xCor",
          label: "Descrição da cor",
        },
        {
          name: "nSerie",
          label: "Serial(série)",
        },
        {
          name: "nMotor",
          label: "Número de Motor",
        },
        {
          name: "cCorDENATRAN",
          label: "Código da Cor (ver tab. DENATRAN)",
        },
        {
          name: "tpRest",
          label: "Restrição",
        },
      ];
    },
    /**
     * Função que retorna informações extras após o formulário
     */
    info() {
      return [
        "0 - Não há;",
        "1 - Alienação Fiduciária;",
        "2 - Arrendamento Mercantil;",
        "3 - Reserva de Domínio;",
        "4 - Penhor de veículos;",
        "9 - Outras.",
      ];
    },
    /**
     * Hook next
     *
     * must return bool
     */
    async next(this: any, data) {
      // Captura as tags
      const tags = pick(data, [
        "chassi",
        "cCor",
        "xCor",
        "nSerie",
        "nMotor",
        "cCorDENATRAN",
        "tpRest",
      ]) as any;

      // Define os dados
      const event = {
        id: data.id,
        modulo: "venda-itens",
        data: {
          tags,
          id: data.produto_id,
          tipo: data.tipo,
        },
        options: { ignoreAfterUpdate: true },
      };

      // Retorna a promessa da requisição
      await this.$store.dispatch(_actions.MODULO.FICHA.UPDATE_REGISTRO, event);

      // retorna true para avançar para o proximo
      return true;
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    async success(this: any, item) {
      // Captura as tags
      const tags = pick(item, [
        "chassi",
        "cCor",
        "xCor",
        "nSerie",
        "nMotor",
        "cCorDENATRAN",
        "tpRest",
      ]) as any;

      // Define os dados
      const data = {
        tags,
        id: item.produto_id,
        tipo: item.tipo,
      };

      // Retorna a promessa da requisição
      await this.$store.dispatch(_actions.MODULO.FICHA.HANDLE_DEPENDENCE, {
        type: "update",
        id: item.id,
        modulo: "venda-itens",
        dependence: "itens",
        data,
        options: { ignoreAfterUpdate: true },
      });

      // Se não estiver no último
      if (!this.isLastSelected) return this.next();

      // Retorna a promessa da requisição, transmitindo a NF-e
      return attemptToTransmitByAssistent.bind(this)({
        id: item.venda_id,
        document: "nfe",
        ignoreMessage: true,
      });
    },
  },
  // Identificador do assistente
  nfe_cce: {
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message(item) {
      return `
       Para enviar uma carta de correção para a NFe: <strong>${item.numero_nf}/${item.serie}</strong> insira um texto livre (min. 30 caracteres).<br/><br/>
       Ajuste o SINIEF 01/07 veda a correção das seguintes informações relacionadas com o Fato Gerador do ICMS da NF-e:<br/><br/>
       I – as variáveis que determinam o valor do imposto tais como: base de cálculo, alíquota, diferença de preço, quantidade, valor da operação ou da prestação;<br/>
       II – a correção de dados cadastrais que implique mudança do remetente ou do destinatário;<br/>
       III – a data de emissão ou de saída.<br/><br/>
       Uma NF-e pode ter até 20 cartas de correção e a última carta substitui as anteriores, assim o emissor deve consolidar o texto na nova carta de correção.<br/><br/>
       O texto da correção é um texto livre com o tamanho limitado à 1000 caracteres e inexiste modelo ou padrão do texto, assim o emissor deve descrever de forma clara e objetiva a correção que deve ser considerada.<br/>
      `;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      return [
        {
          autoFocus: true,
          type: "textarea",
          maxlength: "1000",
          name: "_nfe_texto_correcao",
          label: "Texto de correção",
        },
      ];
    },
    /**
     * Função que é chamada quando clicado no botão ok do assistente
     *
     * retorna uma string representando um erro ou true para válido
     */
    validator(item) {
      if (!item || !item._nfe_texto_correcao) return "empty";
      if (item._nfe_texto_correcao.length < 30) return "min_30";
      return true;
    },
    /**
     * Função que retorna todos os erros
     */
    error() {
      return {
        empty: "O texto não pode ser vazio.",
        min_30: "O texto precisa ter no mínimo 30 caracteres.",
      };
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, item) {
      // Retorna a promessa da requisição
      return this.$store.dispatch(_actions.DFE.CCE, item);
    },
  },
  // Identificador do assistente
  complemento_reference,
  cupom_reference: {
    ...complemento_reference,
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message() {
      return `
      Informe a Chave de acesso da NFC-e referenciada (ID da NFC-e).
     `;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      return [
        {
          autoFocus: true,
          name: "nf_refs",
          label: "Chave da NFC-e",
        },
      ];
    },
  },
  // Identificador do assistente
  devolucao_reference: {
    // Fechar o assistente após confirmar o erro
    ignoreErrors: true,
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message() {
      // ou Número da ECF (3) + COO (6) para cupom fiscal referenciado
      return `
        Informe a(s) Chave(s) de acesso da NF-e de devolução referenciada (ID da NF-e). Para informar mais de uma Chave, separe as mesmas pelo caractere ;
        Exemplo: ################################################;################################################
       `;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      return [
        {
          autoFocus: true,
          name: "nf_refs",
          label: "Chave da NF-e",
        },
      ];
    },
    /**
     * Função que é chamada quando clicado no botão ok do assistente
     *
     * retorna uma string representando um erro ou true para válido
     */
    validator(item) {
      if (!item || !item.nf_refs) return "empty";
      if (item.nf_refs.length < 44) return "min_44";
      return true;
    },
    /**
     * Função que retorna todos os erros
     */
    error() {
      return {
        empty: "A chave não pode ser vazia",
        min_44: "A chave precisa ter no mínimo 44 caracteres",
      };
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, item) {
      // Define os dados
      const data = { nf_refs: item.nf_refs };

      // Commita as alterações
      this.__ficha.append(data);

      // Retorna a promessa da requisição
      return this.__ficha.onChangePersistent({
        data,
      });
    },
  },
  // Identificador do assistente
  nfe_export: {
    // Fechar o assistente após confirmar o erro
    ignoreErrors: true,
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message() {
      return `Exportação`;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      // <exporta>
      //   <UFSaidaPais>SC</UFSaidaPais>
      //   <xLocExporta>Itajai</xLocExporta>
      //   <xLocDespacho>Porto</xLocDespacho>
      // </exporta>
      return [
        {
          autoFocus: true,
          name: "xPais",
          label: "País destino",
        },
        {
          name: "cPais",
          label: "Codigo do País destino",
        },
        {
          name: "idEstrangeiro",
          label:
            "Identificação do destinatário no caso de comprador estrangeiro",
        },
        {
          name: "UFSaidaPais",
          label: "UF de embarque",
        },
        {
          name: "xLocExporta",
          label: "Local do embarque",
        },
        {
          name: "xLocDespacho",
          label:
            "Descrição do Recinto Alfandegado onde foi efetivado o despacho conforme RFB",
        },
      ];
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, item) {
      // Captura as tags preenchidas
      const tags = pick(item, [
        "xPais",
        "cPais",
        "idEstrangeiro",
        "UFSaidaPais",
        "xLocExporta",
        "xLocDespacho",
      ]) as any;

      // Define os dados
      const data = { tags };

      // Retorna a promessa da requisição
      this.$store.dispatch(_actions.MODULO.FICHA.UPDATE_REGISTRO, {
        id: item.id,
        modulo: "saida",
        data,
        options: { ignoreAfterUpdate: true },
      });

      // Retorna o setup do assistente dos itens
      return this.setup({
        modulo: "saida",
        action: "nfe_export_itens",
        registros: filter(item.itens, (item) => !!item.produto_id),
      });
    },
  },
  // Identificador do assistente
  nfe_export_itens: {
    // Tipo do assistente
    multiple: true,
    // Fechar o assistente após confirmar o erro
    ignoreErrors: true,
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message(item) {
      return `NF-e de Exportação
      <br><br>Item: <strong>${item.descricao}</strong>`;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form(item) {
      // Valida se é uma NF-e de exportação referenciada
      const referencedExport =
        // Caso for final 503 ou 501 o CFOP do item
        endsWith(item.cfop, "503") || endsWith(item.cfop, "501");

      // <prod>
      // ...
      //   <detExport>
      //     <nDraw>1</nDraw>
      //   </detExport>
      // </prod>
      return compact([
        {
          autoFocus: true,
          name: "nDraw",
          label: "Número do ato concessório de Drawback",
        },
        referencedExport && {
          label: "Chave de acesso recebida para exportação",
          name: "chNFe",
        },
        referencedExport && {
          label: "Número de Registro de exportação",
          name: "nRE",
        },
      ]);
    },
    /**
     * Hook next
     *
     * must return bool
     */
    async next(this: any, data) {
      // Captura as tags
      const tags = pick(data, ["nDraw", "chNFe", "nRE"]) as any;

      // Define os dados
      const event = {
        id: data.id,
        modulo: "venda-itens",
        data: {
          tags,
          id: data.produto_id,
          tipo: data.tipo,
        },
        options: {
          ignoreAfterUpdate: true,
        },
      };

      // Retorna a promessa da requisição
      await this.$store.dispatch(_actions.MODULO.FICHA.UPDATE_REGISTRO, event);

      // retorna true para avançar para o proximo
      return true;
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    async success(this: any, item) {
      // Captura as tags
      const tags = pick(item, ["nDraw", "chNFe", "nRE"]);

      // Define os dados
      const data = {
        tags,
        id: item.produto_id,
        tipo: item.tipo,
      };

      // Retorna a promessa da requisição
      await this.$store.dispatch(_actions.MODULO.FICHA.HANDLE_DEPENDENCE, {
        type: "update",
        id: item.id,
        modulo: "venda-itens",
        dependence: "itens",
        data,
        options: { ignoreAfterUpdate: true },
      });

      // Se não estiver no último
      if (!this.isLastSelected) return this.next();

      // Retorna a promessa da requisição, transmitindo a NF-e
      return attemptToTransmitByAssistent.bind(this)({
        id: item.venda_id,
        document: "nfe",
        ignoreMessage: true,
      });
    },
  },
  // Identificador do assistente
  nfe_foreign: {
    // Fechar o assistente após confirmar o erro
    ignoreErrors: true,
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message() {
      return `NF-e para estrangeiro`;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      // <dest>
      //    <enderDest>
      //      <xPais>SC</xPais>
      //      <cPais>Itajai</cPais>
      //    </enderDest>
      //    <idEstrangeiro>Porto</idEstrangeiro>
      // </dest>
      return [
        {
          autoFocus: true,
          name: "xPais",
          label: "País destino",
        },
        {
          name: "cPais",
          label: "Codigo do País destino",
        },
        {
          name: "idEstrangeiro",
          label: "Número do passaporte ou documento legal",
        },
      ];
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, item) {
      // Captura as tags preenchidas
      const tags = pick(item, ["xPais", "cPais", "idEstrangeiro"]);

      // Define os dados
      const data = { tags };

      // Retorna a promessa da requisição
      return attemptToTransmitByAssistent.bind(this)({
        id: item.id,
        document: "nfe",
        data,
        ignoreMessage: true,
      });
    },
  },
  // Identificador do assistente
  nfe_import: {
    ignoreErrors: true,
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message() {
      return `Importação`;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      // <dest>
      //   <idEstrangeiro></idEstrangeiro>
      //   <enderDest>
      //     <xPais></xPais>
      //     <cPais></cPais>
      //   </enderDest>
      // </dest>
      // ...
      // <prod>
      //   <DI>
      //     <nDI></nDI>
      //     <dDI></dDI>
      //     <xLocDesemb></xLocDesemb>
      //     <UFDesemb></UFDesemb>
      //     <cExportador></cExportador>
      //     <adi>
      //       <nAdicao></nAdicao>
      //     </adi>
      //   </DI>
      // </prod>
      return [
        {
          autoFocus: true,
          name: "xPais",
          label: "País de origem",
        },
        {
          name: "cPais",
          label: "Código do País de origem",
        },
        {
          name: "nDI",
          label: "Número do Documento de Importação (DI/DSI/DA)",
        },
        {
          name: "dDI",
          label: "Data de Registro da DI/DSI/DA no formato AAAA-MM-DD",
        },
        {
          name: "xLocDesemb",
          label: "Local de desembaraço",
        },
        {
          name: "UFDesemb",
          label: "Sigla da UF onde ocorreu o Desembaraço Aduaneiro",
        },
        {
          name: "dDesemb",
          label: "Data do Desembaraço Aduaneiro no formato AAAA-MM-DD",
        },
        {
          name: "cExportador",
          label: "Código do exportador",
        },
        {
          name: "nAdicao",
          label: "Número da adição",
        },
        {
          name: "idEstrangeiro",
          label:
            "Identificação do destinatário no caso de comprador estrangeiro",
        },
      ];
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    async success(this: any, item) {
      // Captura as tags preenchidas
      const tags = pick(item, [
        "xPais",
        "cPais",
        "nDI",
        "dDI",
        "xLocDesemb",
        "UFDesemb",
        "dDesemb",
        "cExportador",
        "nAdicao",
        "idEstrangeiro",
      ]) as any;

      // Define os dados
      const data = { tags };

      // Inicia o loading
      this.loading = true;

      // Retorna a promessa da requisição
      const response = await this.$store.dispatch(
        _actions.MODULO.FICHA.UPDATE_REGISTRO,
        {
          id: item.compra_id,
          modulo: "entrada",
          data,
          options: { ignoreAfterUpdate: true },
        }
      );

      const registro = get(response, "data.registro", {});

      // Finaliza o loading
      this.loading = false;

      // Retorna o setup do assistente dos itens
      return this.setup({
        modulo: "saida",
        action: "nfe_import_itens",
        registros: filter(registro.itens, (item) => !!item.produto_id),
      });
    },
  },
  // Identificador do assistente
  nfe_import_itens: {
    multiple: true,
    // Fechar o assistente após confirmar o erro
    ignoreErrors: true,
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message(item) {
      return `Importação do item: <strong>${item.descricao}</strong>`;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      // <prod>
      //   <imposto>
      //     <II>
      //       <vBC></vBC>
      //       <vDespAdu></vDespAdu>
      //       <vII></vII>
      //       <vIOF></vIOF>
      //     </II>
      //     <IPI>
      //       <IPITrib>
      //         <vBC></vBC>
      //       </IPITrib>
      //     </IPI>
      //   </imposto>
      //   ...
      //   <DI>
      //     <vAFRMM></vAFRMM>
      //     <tpViaTransp></tpViaTransp>
      //     <adi>
      //       <nDraw></nDraw>
      //     </adi>
      //   </DI>
      //   ...
      //   <detExport>
      //     <nDraw>1</nDraw>
      //   </detExport>
      // </prod>
      return [
        {
          autoFocus: true,
          name: "vBC",
          label: "Base de Cálculo (BC)",
        },
        {
          name: "vDespAdu",
          label: "Despesas aduaneiras",
        },
        {
          name: "vII",
          label: "Valor do Imposto de Importação (II)",
        },
        {
          name: "vIOF",
          label: "Valor do Imposto sobre Operações Financeiras (IOF)",
        },
        {
          name: "vBC_IPI",
          label: "Valor da base de cálculo do IPI",
        },
        {
          name: "vAFRMM",
          label:
            "AFRMM (Adicional do Frete para Renovação da Marinha Mercante)",
        },
        {
          name: "nDraw",
          label: "Número do Ato concessório de Drawback",
        },
        {
          name: "tpViaTransp",
          label: "Via de transporte internacional informada (DI)",
        },
      ];
    },
    /**
     * Função que retorna informações extras após o formulário
     */
    info() {
      return [
        "1 - Marítima;",
        "2 - Fluvial;",
        "3 - Lacustre;",
        "4 - Aérea;",
        "5 - Postal;",
        "6 - Ferroviária;",
        "7 - Rodoviária;",
        "8 - Conduto / Rede de transmissão;",
        "9 - Meios próprios;",
        "10 - Entrada / Saída ficta;",
        "11 - Courier;",
        "12 - Em mãos;",
        "13 - Por reboque.",
      ];
    },
    /**
     * Hook next
     *
     * must return bool
     */
    async next(this: any, data) {
      // Captura as tags
      const tags = pick(data, [
        "vBC",
        "vDespAdu",
        "vII",
        "vIOF",
        "vBC_IPI",
        "vAFRMM",
        "nDraw",
        "tpIntermedio",
        "tpViaTransp",
      ]) as any;

      // Define os dados
      const event = {
        id: data.id,
        modulo: "compra-itens",
        data: {
          tags,
          id: data.produto_id,
          tipo: data.tipo,
        },
        options: {
          // Ignorar eventos de after update
          ignoreAfterUpdate: true,
        },
      };

      // Retorna a promessa da requisição
      await this.$store.dispatch(_actions.MODULO.FICHA.UPDATE_REGISTRO, event);

      // retorna true para avançar para o proximo
      return true;
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    async success(this: any, item) {
      // Captura as tags
      const tags = pick(item, [
        "vBC",
        "vDespAdu",
        "vII",
        "vIOF",
        "vBC_IPI",
        "vAFRMM",
        "nDraw",
        "tpIntermedio",
        "tpViaTransp",
      ]) as any;

      const event = {
        id: item.id,
        modulo: "compra-itens",
        data: {
          tags,
          id: item.produto_id,
          tipo: item.tipo,
        },
        options: {
          // Ignorar eventos de after update
          ignoreAfterUpdate: true,
        },
      };

      // Retorna a promessa da requisição
      await this.$store.dispatch(_actions.MODULO.FICHA.UPDATE_REGISTRO, event);

      // Retorna a promessa da requisição, transmitindo a NF-e
      return attemptToTransmitByAssistent.bind(this)({
        id: item.compra_id,
        document: "nfe",
        data: { nfe_entrada: true },
        ignoreMessage: true,
      });
    },
  },
};
