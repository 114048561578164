<template>
  <v-col cols="12" class="pa-2">
    <v-alert
      dense
      outlined
      text
      border="left"
      transition="scale-transition"
      class="mb-0 py-1 caption"
      :type="type"
      :color="color"
      :value="value"
      @input="$emit('input', $event)"
      v-bind="{ ...$attrs }"
    >
      <div v-html="message" />
    </v-alert>
  </v-col>
</template>

<script>
export default {
  props: {
    value: {},
    message: { default: "Mensagem desconhecida" },
    type: { default: "warning" },
    color: { default: "blue" },
  },
};
</script>

<style></style>
