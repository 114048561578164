import { concat, filter, orderBy, uniqBy } from "lodash";

import API from "@/api";
import actions from "@/store/actions";
import mutations from "@/store/mutations";

const prefix = "/notifications";

const api = API.config();

import { notifications as initialState } from "../../defaultStates";

export default {
  state: Object.assign({}, initialState()),

  mutations: {
    [mutations.NOTIFICATION.DATA](state, data) {
      state.data = orderBy(
        uniqBy(concat(state.data, data), "id"),
        ["published_at"],
        ["desc"]
      );
    },
    [mutations.NOTIFICATION.CLEAR](state, { id }) {
      state.data = id ? filter(state.data, (it) => it.id !== id) : [];
    },
  },

  actions: {
    [actions.NOTIFICATIONS.READ]({ commit }) {
      return api.get(`${prefix}`).then((response) => {
        commit(mutations.NOTIFICATION.DATA, response.data.registros);
      });
    },
    [actions.NOTIFICATIONS.CLEAR]({ commit }, { id }) {
      return api.put(`${prefix}/clear${id ? `/${id}` : ""}`).then(() => {
        commit(mutations.NOTIFICATION.CLEAR, { id });
      });
    },
  },
};
