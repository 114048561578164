<template>
  <v-row no-gutters>
    <v-tooltip bottom v-if="item && item.produto_temporario">
      <template #activator="{ on: onTooltip, attrs }">
        <v-icon
          small
          class="pl-1"
          :color="selected ? 'white' : ''"
          v-bind="attrs"
          v-on="onTooltip"
        >
          mdi-alert-decagram
        </v-icon>
      </template>

      <span>Produto novo</span>
    </v-tooltip>

    <v-col>
      <select-item
        :auto-focus="enableFocus"
        type="search"
        ref="selectItem"
        ignore-focus-next-field
        clear-to-remove
        emit-shift-enter
        :open="menu"
        :sale="false"
        :value="value"
        :changed="changed"
        :readonly="disabled"
        :color="selected ? 'text--white' : color"
        :__ficha="__ficha"
        @focus="(changed = false), $emit('focus', $event)"
        @blur="onBlur"
        @result="onSelectItem"
        @ctrl-delete="() => onSelectItem(null, 'remove')"
        @enter="onEnter"
        @menu="menu = $event"
        @input="onInput"
      />
    </v-col>
  </v-row>
</template>

<script>
import { get, includes, isUndefined } from "lodash";

import SelectItem from "../cupons/SelectItem.vue";

export default {
  components: { SelectItem },

  props: {
    __ficha: {},
    modulo: {},
    value: { default: "" },
    color: { default: "" },
    table: {},

    item: { default: () => ({}) },
    selected: {},
    allowRawText: { default: true },
    produtoKey: {},
    index: {},
    enableFocus: {},
    disabled: {},
    header: {},
    on: {},
  },

  data: () => ({
    menu: false,
    changed: false,
  }),

  methods: {
    /**
     * Evento de input
     */
    onInput(event) {
      // propaga o evento
      this.$emit("input", event);
      // define como modificado
      if (!this.changed) this.changed = true;
    },
    /**
     * Evento de blur
     */
    onBlur(event) {
      // propaga o blur
      this.$emit("blur", event);

      // se for um blur para clicar no item, retorna
      if (includes(get(event, "relatedTarget.id"), "list-item")) return;

      // altera o changed
      this.changed = false;

      // se houver menu
      if (this.menu) {
        // fecha o menu
        this.menu = false;
      }
    },
    /**
     * Evento de keydown enter
     */
    onEnter(event) {
      // se não tiver sido modificado o campo
      if (!this.changed) {
        // fecha o menu
        if (this.menu) this.menu = false;

        // Avança o campo
        return this.$refs.selectItem.$refs.textField.next(event);
      }

      // se não tiver valor na pesquisa ou não tiver id no item
      if (!this.value && !get(this.item, "id")) {
        // define como alterado
        this.changed = false;

        // se o menu está fechado
        if (!this.menu) {
          // Avança o campo
          return this.$refs.selectItem.$refs.textField.next(event);
        }
      }

      // faz a busca pelo item
      this.$refs.selectItem.search();
    },
    /**
     * Função executada quando um item for selecionado
     */
    onSelectItem(item, modifiers) {
      // se não houver item, não houver modifier e não permitir observação
      if (isUndefined(item) && isUndefined(modifiers) && !this.allowRawText) {
        // reseta
        return (this.menu = false), (this.changed = false);
      }

      // se não houver item e não houver modificador
      if (isUndefined(item) && isUndefined(modifiers)) {
        // Retorna a observação
        return (
          this.$emit("raw-text", this.value),
          // Avança para o próximo campo
          this.$refs.selectItem.$refs.textField.next(),
          (this.menu = false)
        );
      }

      // se não tiver item ou houver modificador de remover
      if (!item || includes(modifiers, "remove")) {
        // faz o delete
        return (
          this.$emit("change", null),
          (this.menu = false),
          (this.changed = false)
        );
      }

      // emite o evento do item encontrado
      this.$emit("change", item);

      // Aguarda o update
      this.$nextTick(() => {
        // fecha o menu
        this.menu = false;
        // Avança o campo
        return this.$refs.selectItem.$refs.textField.next();
      });
    },
  },
};
</script>
