<template>
  <v-row no-gutters :style="`height: calc(100vh - 16px - 2px)`">
    <v-col class="pa-0 px-md-2 d-flex flex-column" cols="12">
      <!-- Header -->
      <v-flex ref="header" shrink>
        <v-row no-gutters>
          <v-col cols="12" class="py-0">
            <label
              class="text-caption font-weight-bold"
              style="margin-bottom: 2px"
            >
              Descrição, Código ou Código de Barras
            </label>
          </v-col>

          <v-col cols="12" class="pt-0">
            <select-item
              ignore-focus-next-field
              ref="selectItem"
              :readonly="state !== STATES.open || isFichaReadonly"
              :placeholder="placeholder"
              :open="selectItemOpen"
              :disable-keys="menu"
              :__cupom="__cupom"
              @result="onSelectItem"
              @menu="selectItemOpen = $event"
              v-model="query"
            />
          </v-col>
        </v-row>
      </v-flex>

      <!-- Body -->
      <v-flex grow>
        <v-row no-gutters class="h-100">
          <!-- Cupom -->
          <v-col cols="12" md="8" class="h-100 pl-0 py-4 pr-0 pr-md-4">
            <v-card
              outlined
              class="text-caption text-lg-body-1 text-xl-h5"
              style="display: flex; flex-flow: column"
              ref="couponCard"
            >
              <!-- Header -->
              <v-flex shrink ref="couponHeader">
                <v-col cols="12">
                  <template v-if="clifor?.id">
                    <div class="py-1 font-weight-bold">
                      {{ clifor.cpf_cnpj }}
                      {{ $optional(clifor.nome_razao_social, " - :value") }}
                    </div>
                    <v-divider class="my-1" light />
                  </template>
                  <div class="text-left">
                    {{ $nomeCupomFiscal }}:
                    <strong>{{ nroCupom }}</strong> Caixa:
                    <strong>
                      {{
                        data.numero_caixa || $getStorage("frente.numero_caixa")
                      }}
                    </strong>
                  </div>
                  <v-divider class="my-1" light />
                </v-col>
              </v-flex>

              <!-- Body -->
              <div
                class="px-4"
                ref="scrollWrapper"
                :style="`height: ${scrollHeight}px; overflow-y: auto`"
              >
                <cupom-items :items="data.itens" :headers="headers" />
              </div>

              <!-- Footer -->
              <v-flex shrink ref="couponFooter">
                <v-col
                  cols="12"
                  class="text-center font-weight-bold text-body-1 text-lg-h5 text-xl-h4"
                >
                  <v-divider class="mb-3 mt-2" light />
                  <div>
                    Total {{ $nomeCupomFiscal }} R$
                    {{ data.vlr_total || "0,00" }}
                  </div>
                </v-col>
              </v-flex>
            </v-card>
          </v-col>

          <!-- Informações -->
          <v-col
            cols="12"
            md="4"
            ref="couponInfo"
            class="px-0 pt-3 pb-4 d-none d-md-flex flex-column"
          >
            <v-flex ref="couponInfoShrink" shrink>
              <!-- Quantidades -->
              <v-row no-gutters ref="cupomTots">
                <sale-text-field
                  disabled
                  color="saleGrey"
                  label="Quantidade"
                  :value="item.quantidade || '0,00'"
                />
                <sale-text-field
                  disabled
                  color="saleGreen"
                  label="Total Item R$"
                  :value="item.vlr_subtotal || '0,00'"
                />
                <sale-text-field
                  disabled
                  color="saleRed"
                  :label="`Total ${$nomeCupomFiscal} R$`"
                  :value="data.vlr_total || '0,00'"
                />
              </v-row>
            </v-flex>

            <!-- Imagem e Pagamentos -->
            <v-flex
              grow
              class="overflow-y-auto mt-4 px-2"
              :style="
                (data.pagamentos && data.pagamentos.length) ||
                clearCurrency(data.vlr_desconto) ||
                clearCurrency(data.vlr_despesas)
                  ? 'border: thin solid var(--v-tableborder-base); border-radius: 4px'
                  : null
              "
            >
              <v-row no-gutters class="h-100">
                <v-col cols="12" class="px-0 pt-2 pb-0 d-none d-md-flex">
                  <!-- Imagem -->
                  <template v-if="galeria">
                    <image-view
                      hide-default
                      class="my-auto"
                      :width="`${imageWidth}px`"
                      :height="`${imageHeight}px`"
                      :filename="galeria"
                    />
                  </template>

                  <!-- Totalizadores e Pagamentos -->
                  <div
                    v-else
                    :style="`max-height: ${maxPaymentsHeight}px; width: 100%`"
                  >
                    <table
                      style="width: 100%"
                      class="text-body-1 font-weight-bold"
                    >
                      <template v-for="(pagamento, i) in data.pagamentos">
                        <tr :key="i">
                          <td style="width: 7% !important">
                            <btn
                              icon
                              color="primary"
                              @click="() => onEditPayment(i)"
                            >
                              <v-icon small> mdi-pencil </v-icon>
                            </btn>
                          </td>

                          <td class="elipsed-td">
                            {{ pagamento.forma?.descricao }}
                          </td>

                          <td class="text-right success--text">
                            {{ pagamento.valor }}
                            <v-icon x-small color="success">mdi-plus</v-icon>
                          </td>
                        </tr>
                      </template>

                      <template
                        v-if="data.pagamentos && data.pagamentos.length"
                      >
                        <tr>
                          <td colspan="3"><v-divider /></td>
                        </tr>

                        <tr>
                          <td colspan="2">Total dos Pagamentos</td>
                          <td class="text-right success--text">
                            {{ totalPayments }}
                            <v-icon x-small color="success">mdi-equal</v-icon>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="2">Saldo</td>
                          <td class="text-right error--text">
                            {{ data.vlr_pagamento_restante }}
                            <v-icon x-small color="error">mdi-equal</v-icon>
                          </td>
                        </tr>
                      </template>

                      <tr v-if="clearCurrency(data.vlr_troco)">
                        <td colspan="2">Troco</td>
                        <td class="text-right info--text">
                          {{ data.vlr_troco }}
                          <v-icon x-small color="info">mdi-minus</v-icon>
                        </td>
                      </tr>

                      <tr v-if="clearCurrency(data.vlr_desconto)">
                        <td colspan="2">Desconto</td>
                        <td class="text-right info--text">
                          {{ data.vlr_desconto }}
                          <v-icon x-small color="info">mdi-minus</v-icon>
                        </td>
                      </tr>
                      <tr v-if="clearCurrency(data.vlr_despesas)">
                        <td colspan="2">Acréscimo</td>
                        <td class="text-right info--text">
                          {{ data.vlr_despesas }}
                          <v-icon x-small color="info">mdi-plus</v-icon>
                        </td>
                      </tr>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-flex>
          </v-col>
        </v-row>
      </v-flex>

      <!-- Footer -->
      <v-flex ref="footer" shrink>
        <v-row no-gutters>
          <v-col cols="auto" :class="$isMobile ? 'mx-auto' : ''">
            <!-- <sale-btn
              nome="F1"
              descricao="Ajuda"
              @click="onActionClicked(CODES.F1)"
              class="hidden-xs-only"
            /> -->
            <sale-btn
              nome="F2"
              :descricao="`Finalizar ${$nomeCupomFiscal}`"
              @click="
                onActionClicked(
                  clearCurrency(data.vlr_pagamento_restante) <= 0
                    ? CODES.CTRLF3
                    : CODES.F2
                )
              "
            />
            <!-- <sale-btn
              nome="F2"
              descricao="Finalizar"
              @click="onActionClicked(CODES.F2)"
              v-else
            /> -->
            <!-- <sale-btn
              nome="F3"
              :descricao="`Finalizar ${$nomeCupomFiscal}`"
              :show="state >= STATES.open"
              @click="onActionClicked(CODES.F3)"
            /> -->
            <sale-btn
              nome="F4"
              descricao="Informar Consumidor"
              class="hidden-xs-only"
              @click="onActionClicked(CODES.F4)"
            />
            <!-- <sale-btn
              nome="F6"
              descricao="Finalizar Cartão"
              class="hidden-sm-only"
              @click="onActionClicked(CODES.F6)"
              :show="state >= STATES.open"
            /> -->
            <sale-btn
              nome="F7"
              descricao="Finalizar Dinheiro"
              class="hidden-xs-only"
              @click="onActionClicked(CODES.F7)"
              :show="state >= STATES.open"
            />
            <sale-btn
              nome="F8"
              descricao="Desconto Item"
              :show="state >= STATES.open"
              @click="onActionClicked(CODES.F8)"
            />

            <menu-config
              @click="onConfigItemClick"
              v-model="menu"
              :storage="storage"
            />

            <menu-fiscal
              @open:dialog="() => (menuFiscal = true)"
              @close:dialog="onCloseMenuFiscal"
              @close:menu="focusTextField"
            />

            <sale-btn
              class="mr-0"
              color="saleGrey"
              nome="F12"
              descricao="Sair"
              @click="onActionClicked(CODES.F12)"
            />
          </v-col>
        </v-row>

        <v-row class="mx-1 mb-0" v-if="!$isMobile">
          <v-col cols="auto" class="py-0 pl-0">
            <small>Copyright&copy; {{ $companyFantasy }}</small>
          </v-col>
          <v-spacer />
          <v-col cols="auto" class="py-0 my-auto">
            <div
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              <small>{{ informations }}</small>
            </div>
          </v-col>
          <v-spacer />
          <v-col cols="auto" class="py-0 pr-0">
            <small><timer /></small>
          </v-col>
        </v-row>
      </v-flex>
    </v-col>

    <component
      :is="activeComponent"
      :__ficha="__ficha"
      :data="data"
      @close="onActiveComponentClose"
      v-if="activeComponent"
      v-bind="activeComponentAttrs"
      v-on="activeComponentListeners"
    />

    <!-- @deprecated Clifor e pagamentos dialog -->
    <v-dialog
      fullscreen
      scrollable
      persistent
      no-click-animation
      :value="state === STATES.checkout"
      v-if="state === STATES.checkout"
      @input="onSecondaryDialogClose"
    >
      <v-card>
        <v-card-text class="pa-1 pa-md-2">
          <v-col class="pa-0 pt-2 pt-md-0 d-flex flex-column h-100" cols="12">
            <v-flex shrink>
              <v-row no-gutters>
                <v-col cols="12" md="6" class="px-2 pt-md-2">
                  <v-card tile elevation="1" class="pb-2" style="height: 95%">
                    <v-col
                      cols="12"
                      class="text-h5 px-6 font-weight-bold cupomtext--text"
                    >
                      <template v-for="(value, text, i) in checkoutDetails">
                        <v-row
                          class="mt-auto"
                          :class="{ 'pt-4': i > 0 }"
                          :key="i"
                        >
                          <div>{{ text }}</div>
                          <div class="ml-auto">
                            {{ value }}
                          </div>
                        </v-row>
                        <v-row
                          :key="`divider-${i}`"
                          v-if="i === Object.keys(checkoutDetails).length - 2"
                        >
                          <v-col class="pa-0">
                            <v-divider light />
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6" class="px-1 py-1">
                  <v-row no-gutters>
                    <clifor-select
                      md="6"
                      label="Vendedor"
                      name="colaborador_id"
                      :valueNotNull="false"
                      :filter="vendedor_filter"
                      :clifor="colaborador"
                      v-model="data.colaborador_id"
                    />
                    <clifor-combobox-v2
                      md="6"
                      label="CPF/CNPJ / Razão Social do Cliente"
                      :item-text="
                        (it) =>
                          it.cpf_cnpj ||
                          it.nome_razao_social ||
                          `Cliente ${it.id || ''}`
                      "
                      :item-subtitle="
                        (it) =>
                          `${!it.cpf_cnpj ? '' : it.nome_razao_social || ''}`
                      "
                      @click:append-outer="onEditCliforClicked"
                      v-model="data.clifor"
                    />
                  </v-row>
                  <ficha
                    use-as-slot
                    modulo="cadastro"
                    :initial-data="
                      typeof data.clifor === 'object' ? data.clifor : {}
                    "
                    :id="clifor.id"
                  >
                    <v-row class="ma-0">
                      <p-text-field
                        md=""
                        name="nome_razao_social"
                        label="Razão Social"
                        :event="{ force: true }"
                        :readonly="!data.clifor_id"
                        v-model="clifor.nome_razao_social"
                      />
                    </v-row>
                  </ficha>
                  <v-row class="ma-0" v-if="data.tem_intermediador">
                    <intermediador-select
                      md="6"
                      :clifor="data.intermediador"
                      v-model="data.intermediador_id"
                    />
                    <p-switch
                      md="6"
                      class="caption my-auto"
                      switch-class="mx-auto"
                      name="indicador_presenca"
                      :label="`Cliente para Delivery em ${$emitente.uf}`"
                      true-value="4"
                      false-value="1"
                      v-model="data.indicador_presenca"
                      v-if="!$useSat"
                    />
                  </v-row>
                </v-col>
              </v-row>
            </v-flex>

            <v-flex grow>
              <v-row no-gutters>
                <template v-for="(pagamento, i) in extra.meios_pagamentos">
                  <v-col
                    :key="i"
                    class="px-2 py-0"
                    cols="12"
                    sm="6"
                    md="4"
                    xl="3"
                  >
                    <sale-text-field
                      readonly
                      :color="
                        ['01', '02', '03/04', '05'].includes(pagamento.codigo)
                          ? 'saleGreen'
                          : 'saleGrey'
                      "
                      :name="pagamento.codigo"
                      :label="pagamento.descricao"
                      @click="
                        onActionClicked(CODES.F2, {
                          initialCodigoPagamento: pagamento.codigo,
                        })
                      "
                    />
                  </v-col>
                </template>
                <v-col class="px-2 py-0" cols="12" sm="6" md="4" xl="3">
                  <sale-text-field
                    disabled
                    color="saleRed"
                    name="vlr_troco"
                    label="Troco"
                    v-model="data.vlr_troco"
                  />
                </v-col>
              </v-row>
            </v-flex>

            <v-flex shrink>
              <v-row no-gutters class="px-2" v-if="$menuFiscalEnabled">
                {{ $menuFiscalMessage }}
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="px-2 pt-4 pb-2 pb-md-2 mb-2 pb-md-0">
                  <sale-btn
                    nome="F1"
                    descricao="Ajuda"
                    class="hidden-xs-only"
                    @click="onActionClicked(CODES.F1)"
                  />
                  <sale-btn
                    focus-priority
                    nome="F3"
                    descricao="Finalizar"
                    @click="onActionClicked(CODES.CTRLF3)"
                  />
                  <sale-btn
                    nome="F8"
                    descricao="Acréscimo /Desconto"
                    @click="onActionClicked(CODES.CTRLF8)"
                  />
                  <sale-btn
                    color="saleGrey"
                    nome="F12"
                    descricao="Voltar"
                    @click="onActionClicked(CODES.F12)"
                  />
                </v-col>
              </v-row>
            </v-flex>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Status dialog -->
    <v-dialog
      scrollable
      persistent
      no-click-animation
      max-width="70vw"
      :value="[STATES.finishing, STATES.consulting].includes(state)"
      v-if="[STATES.finishing, STATES.consulting].includes(state)"
    >
      <v-card>
        <v-card-text class="pa-2">
          <v-col class="d-flex">
            <v-progress-circular
              indeterminate
              size="64"
              width="5"
              color="primary"
              class="mx-auto"
            />
          </v-col>
          <v-col class="d-flex">
            <h3 class="mx-auto" v-if="state === STATES.consulting">
              Consultando Status {{ $nomeCupomFiscal }}
            </h3>
            <h3 class="mx-auto" v-else>
              Transmitindo {{ $nomeCupomFiscal }}... aguarde
            </h3>
          </v-col>
          <v-col class="d-flex">
            <h4 class="mx-auto">
              {{ status }}
            </h4>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

import debounce from "lodash/debounce";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";

import SaleTextField from "@/components/form/cupons/SaleTextField.vue";
import SaleBtn from "@/components/form/cupons/SaleBtn.vue";
import SelectItem from "@/components/form/cupons/SelectItem.vue";
import CupomItems from "@/components/form/cupons/CupomItems.vue";
import MenuConfig from "@/components/form/cupons/MenuConfig.vue";
import MenuFiscal from "@/components/modules/cupons-fiscais/MenuFiscal";
import Ficha from "@/components/fichas/Ficha.vue";

import {
  PTextField,
  Btn,
  PSelectFilter,
  PSelectSearch,
  FieldCpfCnpj,
  PTextArea,
  CliforSelect,
  IntermediadorSelect,
  CliforComboboxV2,
  PSwitch as PSwitch,
} from "@/components/form";

import { ImageView, Timer } from "@/components/utils";

// Mixins
import Clifor from "@/mixins/clifor";
import actions from "@/store/actions";
import { clearCurrency, setCurrency } from "@/utils";

import mutations from "@/store/mutations";
import { EventBus } from "@/main";

import { mDate } from "@/plugins/moment";

import { import_orcamento } from "@/config/modulos/cupons-fiscais";

const STATES = {
  open: 10,
  checkout: 20,
  finishing: 30,
  consulting: 40,
};

const CODES = {
  // Atalhos usados
  F1: "help",
  F2: "inserir-forma-pagamento",
  F4: "informar-cliente",

  // Antiga aba F3, será removida nas próximas atualizações
  F3: "finalizar-next",
  F6: "finalizar-cartao",
  F7: "finalizar-dinheiro",

  // Transmissão
  CTRLF3: "finalizar",

  F8: "desconto-item",
  F11: "fullscreen",
  F10: "menu",
  F12: "close",
  CTRLF5: "refresh",
  CTRLF8: "desconto-total",
  CTRLF10: "complemento",
  F9: "import-orcamento",

  // Atalhos disponíveis
  F5: "F5",
  CTRLF1: "CTRL+F1",
  CTRLF2: "CTRL+F2",
  CTRLF4: "CTRL+F4",
  CTRLF6: "CTRL+F6",
  CTRLF7: "CTRL+F7",
  CTRLF9: "CTRL+F9",
  CTRLF11: "CTRL+F11",
  CTRLF12: "CTRL+F12",
};

export default Clifor.extend({
  components: {
    ImageView,
    SaleTextField,
    SaleBtn,
    SelectItem,
    Btn,
    IntermediadorSelect,
    PTextField,
    PSwitch,
    FieldCpfCnpj,
    CliforSelect,
    PSelectSearch,
    Timer,
    PSelectFilter,
    PTextArea,
    CupomItems,
    MenuConfig,
    CliforComboboxV2,
    Ficha,
    MenuFiscal,
  },
  data: () => ({
    STATES,
    CODES,

    galleryTimeout: undefined,
    operationTimeout: undefined,

    state: STATES.open,
    menu: false,
    menuFiscal: false,
    selectItemOpen: false,
    lastActionClicked: null,

    scrollHeight: "auto",
    imageWidth: "100px",
    imageHeight: "100px",
    maxPaymentsHeight: 300,

    placeholder: "",
    query: "",

    item: {},
    nextItemDiscount: "",

    activeComponent: null,
    activeComponentAttrs: {},
    activeComponentListeners: {},
  }),
  created() {
    EventBus.$on("resize", this.onResize);
    EventBus.$on("terminal-saas-callback", this.onTerminalCallback);

    if (this.$useSat) {
      this.$getTerminal().catch(() => null);
    }

    if (!this.data.id || !this.__ficha.id) {
      this.__ficha.append({
        numero_caixa: this.$getStorage("frente.numero_caixa"),
      });
    }
  },
  mounted() {
    this.$nextTick(this.onResize);

    if (this.$appMode !== "development") {
      !window.fullscreen.isFullscreen() && window.fullscreen.toggle();
    }

    if (!this.isFichaDisabled) {
      this.focusTextField();
    }

    document.addEventListener("keydown", this.onKeyDown);
    document.addEventListener("mousedown", this.onMouseDown);
  },
  beforeDestroy() {
    if (this.$appMode !== "development") {
      window.fullscreen.isFullscreen() && window.fullscreen.toggle();
    }

    EventBus.$off("resize", this.onResize);
    EventBus.$off("terminal-saas-callback", this.onTerminalCallback);

    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("mousedown", this.onMouseDown);

    this.galleryTimeout && clearTimeout(this.galleryTimeout);
    this.operationTimeout && clearTimeout(this.operationTimeout);
  },
  computed: {
    ...mapState({
      assistent: (state) => state.app.assistent,
      status: (state) => state.modulo.statusbar.text,
    }),
    __cupom() {
      return this;
    },
    /**
     * Retorna as informações de status do frente de caixa
     * - Ambiente de homologação
     * - Status Contingência
     * - Desconto no próximo item
     */
    informations() {
      const messages = [];

      if (this.nextItemDiscount) {
        const i = (this.nextItemDiscount || "").includes("%") ? "" : "R$";
        return `Desconto de ${i} ${this.nextItemDiscount} no próximo item`;
      }

      if (this.$idCupomFiscal === "cfe-sat") {
        if (get(this.$config, "cfe_ambiente_emissao") == "2") {
          messages.push("Ambiente de Homologação");
        }
      } else if (this.$idCupomFiscal === "nfce") {
        if (get(this.$config, "nfce_ambiente_emissao") == "2") {
          messages.push("Ambiente de Homologação");
        }

        if (get(this.$config, "nfce_contingencia_json.ativo")) {
          messages.push("Operando em Contingência");
        } else {
          const contingencia = get(
            this.__ficha,
            "__modulo.extra.contingencia_pendente"
          );
          contingencia &&
            messages.push(`Contingência pendente ${mDate(contingencia)}`);
        }
      }

      return messages.join(" - ");
    },
    /**
     * Retorna o número do cupom baseado no tipo de documento
     */
    nroCupom() {
      const nro = this.data.numero_nf || "0".repeat(9);
      const serie = this.data.serie || "0".repeat(3);

      return this.$idCupomFiscal === "nfce" ? `${nro}/${serie}` : nro;
    },
    /**
     * retorna o cabeçalho dos itens
     */
    headers() {
      return [
        {
          text: "Item",
          value: "index",
          class: "text-body-2 text-left",
        },
        {
          text: "EAN / Codigo - Descrição",
          class: "text-left",
          value: (it) =>
            `${this.$optional(this.gtin(it), ":value -")} ${it.descricao}`,
        },
        {
          text: "Quantidade",
          class: "text-body-2 text-right",
          value: (it) =>
            `${it.quantidade || ""}${
              it.produto ? " " + (it.produto.unidade_medida || "") : ""
            }`,
        },
        {
          text: "Unitário",
          value: "vlr_unitario",
          class: "text-body-2 text-right",
        },
        {
          text: "Total",
          value: "vlr_total",
          class: "text-body-2 text-right",
        },
      ];
    },
    /**
     * Retorna true se algum assistente estiver aberto no frente de caixa
     * Assistente de formas de pagamento
     * Informar cliente
     */
    isOverlayActionActive() {
      return this.assistent.active || this.activeComponent !== null;
    },
    /**
     * retorna a imagem do último item lançado
     */
    galeria() {
      const galeria = get(this.item, "galeria.principal", null);
      const id = get(this.item, "produto_id", null);

      if (!galeria || !id) return;

      return `/estoque/${id}/${galeria}`;
    },
    /**
     * retorna o consumidor do documento
     */
    clifor() {
      return this.data.clifor ?? {};
    },
    /**
     * @deprecated
     */
    checkoutDetails() {
      const text = {
        "Subtotal R$": this.data.vlr_subtotal,
      };

      const desconto = clearCurrency(this.data.vlr_desconto) ?? 0.0;
      const acrescimo = clearCurrency(this.data.vlr_despesas) ?? 0.0;

      if (desconto) {
        text["Desconto R$"] = `-${this.data.vlr_desconto}`;
      }

      if (acrescimo) {
        text["Acréscimo R$"] = this.data.vlr_despesas;
      }

      text["Total R$"] = this.data.vlr_total;

      return text;
    },
    totalPayments() {
      return setCurrency(
        clearCurrency(this.data.vlr_total) -
          clearCurrency(this.data.vlr_pagamento_restante)
      );
    },
  },
  methods: {
    clearCurrency,
    onEditPayment(index, params = {}) {
      const payment = this.data.pagamentos[index];

      if (!payment) return;

      return this.$nextTick(() =>
        this.requestInsertPaymentMethod({
          editMode: true,
          initialPagamento: payment,
          title: "Editar Pagamento",
          ...params,
        })
      );
    },
    /**
     * Faz o foco no text field de lançamento de informações
     */
    focusTextField() {
      this.$nextTick(() => this.$refs.selectItem.getRef().focus());
    },
    onCloseMenuFiscal() {
      this.menuFiscal = false;
      this.$nextTick(this.focusTextField);
    },
    /**
     * Retorna os dados de storage local
     *
     * @deprecated salvar essas informações no usuário
     */
    storage() {
      return { frente: this.$getStorage("frente") };
    },
    /**
     * Callback do terminal quando for transmitido um cupom via equipamento
     * SAT ou MFe
     */
    onTerminalCallback(response) {
      // Captura a ação
      const action = get(response, "action");

      // se houver timeout, limpa
      if (this.operationTimeout) {
        clearTimeout(this.operationTimeout);
      }

      // Se houver algum erro, cancela
      if (get(response, "error")) {
        return (this.state = STATES.open);
      }

      // Se for ação de emitir
      if (action === "emitir") {
        // roda as rotinas de após autorizado
        return this.afterAuthorized();
      }

      // Status inicial
      this.state = STATES.open;
    },
    /**
     * resize page event listener
     */
    onResize() {
      this.$nextTick(() => {
        const available = this.height();
        const header = get(this.$refs, "header.clientHeight", 0);
        const footer = get(this.$refs, "footer.clientHeight", 0);
        const couponHeader = get(this.$refs, "couponHeader.clientHeight", 0);
        const couponFooter = get(this.$refs, "couponFooter.clientHeight", 0);
        const paddings = 48;

        this.scrollHeight =
          available - header - footer - couponHeader - couponFooter - paddings;

        const coupon = this.scrollHeight + couponHeader + couponFooter;

        this.imageWidth = get(this.$refs, "couponInfo.clientWidth", 100) - 12;
        this.imageHeight = get(this.$refs, "couponInfoShrink.clientHeight");
        this.maxPaymentsHeight =
          coupon - get(this.$refs, "couponInfoShrink.clientHeight") - 24;
      });
    },
    /**
     * Formata o GTIN do item
     */
    gtin(item) {
      // Se o item foi cancelado
      if (!item.ativo) return "";
      item = get(item, "produto.gtin", get(item, "produto.id", "")) || "";
      item = item.toString();
      return item.toUpperCase() === "SEM GTIN" ? "" : item.padStart(13, `0`);
    },
    /**
     * Persiste as configurações do sistema
     */
    persistConfigs: debounce(function (data) {
      this.__ficha.onChangePersistent({
        modulo: "sistema",
        data,
      });
    }, 10),
    /**
     * ao fechar qualquer assistente que foi aberto pelo frente de caixa
     * executa rotinas de limpeza
     */
    onActiveComponentClose: debounce(function () {
      this.activeComponent = null;
      this.activeComponentAttrs = {};
      this.activeComponentListeners = {};

      if (this.state === STATES.open && !this.isFichaDisabled) {
        this.focusTextField();
      }
    }, 10),
    /**
     * evento de click de qualquer item no menu config F10
     */
    onConfigItemClick(item) {
      if (item.id === CODES.F1) {
        return this.onActionClicked(item.id);
      }

      if (item.id === CODES.CTRLF10) {
        this.menu = false;
        return this.onActionClicked(item.id);
      }

      if (item.id === "dfe_consultar_status") {
        this.menu = false;

        return this.$nextTick(() => {
          this.state = STATES.consulting;

          return this.$store
            .dispatch(actions.DFE.STATUS, this.$idCupomFiscal)
            .catch(() => {
              this.state = STATES.open;
            })
            .finally(() => {
              if (!this.$useSat && this.state !== STATES.open)
                this.state = STATES.open;
            });
        });
      }

      if (includes(item.type, "new-ficha")) {
        this.__ficha.initFichaAuxiliar({
          modulo: item.modulo,
        });

        return this.$nextTick(() => {
          this.menu = false;
        });
      }

      let registro = this.$config;

      if (includes(item.type, "storage")) {
        registro = {
          [item.id]: this.$getStorage(item.key),
        };
      }

      if (includes(item.type, "assistent")) {
        return this.handleAssistents(item, registro);
      }

      this.menu = false;

      // se for boolean mas não é local storage
      if (includes(item.type, "boolean") && !includes(item.type, "storage")) {
        this.$config[item.id] = !this.$config[item.id];
      }
      // se for boolean mas é local storage
      else if (includes(item.type, "storage")) {
        return this.$toggleBooleanStorage(item.key);
      }

      if (item.component) {
        if (item.id === "exportar_xml_contabilidade")
          this.activeComponentAttrs = {
            modulo: "cupons-fiscais",
          };

        if (includes(["import_orcamento"], item.id)) {
          this.activeComponentAttrs = {
            component_type: "import",
            origin: item.id.replace("import_", ""),
            target: "cupons-fiscais",
            registro: this.data,
          };
        }

        return this.$nextTick(() => {
          item.component().then((component) => {
            this.activeComponent = component.default;
          });
        });
      }

      // Salva as configurações do sistema
      this.persistConfigs(pick(this.$config, item.id));
    },
    /**
     * Quando o valor restante de pagamentos zerar
     * podemos emitir o documento
     */
    onFinishFormasPagamentos() {
      this.onActiveComponentClose();
      return this.$nextTick(this.attemptToIssue);
    },
    /**
     * @deprecated
     */
    onSecondaryDialogClose(value) {
      !value && this.state === STATES.checkout && (this.state = STATES.open);
    },
    /**
     * Callback de inserção de texto no input de informações
     * - Lançar item
     * - Remover item
     * - Remover pagamento
     */
    async onSelectItem(item, modifiers) {
      let index = -1;

      this.$nextTick(() => {
        this.query = "";
      });

      if (includes(modifiers, "remove-payment")) {
        index = parseInt(item) - 1;
        item = this.data.pagamentos[index];

        if (!item) {
          return (this.placeholder = "<Pagamento não encontrado>");
        }

        if (this.placeholder) this.placeholder = "";

        return this.__ficha.handleDependence({
          modulo: "pagamentos",
          dependence: "pagamentos",
          type: "delete",
          id: item.id,
        });
      }

      if (includes(modifiers, "remove")) {
        index = parseInt(item) - 1;
        item = this.data.itens[index];

        if (!item || !item.ativo) {
          return (this.placeholder = "<Item não encontrado>");
        }

        if (this.placeholder) this.placeholder = "";

        // TODO: deixar o backend setar essa informação
        item = {
          ...(item ?? {}),
          ativo: false,
          cancelado: true,
          produto_id: null,
          descricao: `${this.gtin(item)} <Cancelado>`.substr(0, 120),
        };

        this.data.itens.splice(index, 1, item);

        try {
          return await this.__ficha.handleDependence({
            id: item.id,
            data: {
              ...pick(item, ["descricao", "produto_id", "ativo"]),
            },
            registro_id: this.data.id,
            type: "update",
            dependence: "itens",
            modulo: "cupons-fiscais-itens",
            options: { updatedInsteadCreated: true },
          });
        } catch (e) {
          cosole.error(e);
          return (this.placeholder = "<Erro ao cancelar item>");
        }
      }

      if (isEmpty(item)) return (this.placeholder = "<Não cadastrado>");

      if (this.placeholder) this.placeholder = "";

      // Se houver desconto
      if (this.nextItemDiscount) {
        const discount = clearCurrency(this.nextItemDiscount.replace("%", ""));

        // Se for em porcentagem
        if (includes(this.nextItemDiscount, "%")) {
          if (discount > 0.0 && discount <= 99) {
            item.vlr_desconto =
              (clearCurrency(item.vlr_subtotal) * discount) / 100;
          }
        }

        // Caso contrário, e o desconto for menor ou igual que o total do item
        else if (discount < clearCurrency(item.vlr_subtotal)) {
          item.vlr_desconto = discount;
        }

        // Formata o desconto
        item.vlr_desconto =
          item.vlr_desconto > 0.0
            ? setCurrency(item.vlr_desconto, this.$vlrDecimalCases)
            : null;

        this.nextItemDiscount = null;
      }

      item.item = this.data.itens.length + 1;

      this.item = { ...item };

      if (!this.data.itens.length) {
        this.__ficha.append({
          itens: [item],
        });
      }

      //
      else {
        this.data.itens.push(item);
      }

      const currentIndex = findIndex(
        this.data.itens,
        (i) => i.item === item.item
      );

      try {
        const cupomItem = {
          ...item,
        };

        // Se o cupom ainda não foi criado
        if (!this.data.id) {
          // Atribui o número do caixa do usuário
          cupomItem.numero_caixa =
            this.$getStorage("frente.numero_caixa") ?? "001";
        }

        const response = await this.__ficha.handleDependence({
          data: cupomItem,
          registro_id: this.data.id,
          type: "create",
          dependence: "itens",
          modulo: "cupons-fiscais-itens",
          options: { ignoreAfterUpdate: true },
        });

        const data = get(response, "data") ?? {};

        if (data.registro) {
          this.data.itens.splice(currentIndex, 1, data.registro);
        }

        if (data.parent) {
          this.__ficha.handleParent(data.parent);
        }
      } catch (e) {
        this.data.itens.splice(currentIndex, 1);

        window.error(e);
      }
    },
    /**
     * Faz a tentativa de emitir o documento
     */
    async attemptToIssue() {
      // não permite transmitir no modo leitura
      if (this.isFichaDisabled || this.isFichaReadonly) {
        return;
      }

      // não permite transmitir se ainda não criou o cupom
      if (!this.data.id || clearCurrency(this.data.vlr_total) <= 0) {
        return;
      }

      // se já estiver transmitindo, retorna
      if (this.state === STATES.finishing) {
        return;
      }

      // Se não estiver finalizando, altera status para finalizando
      if (this.state !== STATES.finishing) {
        this.state = STATES.finishing;
      }

      try {
        await this.$store.dispatch(actions.DFE.EMITIR, {
          id: this.data.id,
          document: this.$idCupomFiscal,
          type: "direct-print",
          imprimir_bobina_58mm: this.$getStorage("frente.imprimir_bobina_58mm"),
          finishOn: this.lastActionClicked === CODES.F7 ? "01" : null,
        });

        if (this.$useSat) return;

        this.afterAuthorized();
      } catch (e) {
        this.state = STATES.open;

        const registro = get(e, "response.data.registro");
        if (registro) this.__ficha.afterUpdate(registro);
      }
    },
    /**
     * Rotinas de limpeza após autorização do cupom
     */
    afterAuthorized() {
      // limpa a ficha
      this.item = {};
      this.__ficha.clearAndGoToNew();

      // retoma o foco no input
      if (!this.isFichaDisabled) {
        this.focusTextField();
      }

      // abre o cupom novamente
      if (this.state !== STATES.open) {
        this.state = STATES.open;
      }
    },

    /**
     * qualquer ação executada através de clicks ou atalhos do teclado
     * são gerenciados por esta função
     */
    onActionClicked(action, params = null) {
      // se estiver finalizando, bloqueia as ações
      if (this.state == STATES.finishing) return;

      this.lastActionClicked = action;

      if (action === CODES.F12) {
        if (this.selectItemOpen) {
          return (this.selectItemOpen = false);
        }

        if (this.menu) {
          return (this.menu = false);
        }

        if (this.activeComponent !== null) {
          return this.onActiveComponentClose();
        }

        if (this.assistent.active) return;

        return this.state > STATES.open
          ? (this.state = STATES.open)
          : this.$emit("close");
      }

      // Help
      if (action === CODES.F1) {
        return this.$help(
          get(this.$route, "params.modulo"),
          `${this.$idCupomFiscal}/primeiros-passos.html`
        );
      }

      // F3 tela de finalização
      // TODO: remove @deprecated
      if (this.state === STATES.open && action === CODES.F3) {
        return (this.state = STATES.checkout);
      }

      if (action === CODES.F4) {
        return this.$nextTick(() => {
          import(
            "@/components/popups/modulos/cupons-fiscais/InformarCliente.vue"
          ).then((component) => {
            this.activeComponent = component.default;
          });
        });
      }

      if (action === CODES.F10) {
        return (this.menu = !this.menu);
      }

      if (action.startsWith(CODES.CTRLF3) || action === CODES.F2) {
        if (!this.data.id || clearCurrency(this.data.vlr_total) == 0.0)
          return this.__ficha.__modulo.$simpleAlert({
            title: "Atenção",
            message: `${this.$nomeCupomFiscal} não pode estar com Valor Total igual a 0,00`,
            value: true,
          });
      }

      // TODO: remover quando a aba F3 for removida
      // @deprecated Apenas um workaround para a tela antiga
      if ([CODES.F2].includes(action) && params !== null) {
        return this.$nextTick(() => this.requestInsertPaymentMethod(params));
      }

      // F2 = Inserir pagamento
      // F6 = Finalizar em cartão
      if ([CODES.F2, CODES.F6].includes(action)) {
        return this.$nextTick(() =>
          this.requestInsertPaymentMethod({
            initialCodigoPagamento: action === CODES.F6 ? "03/04" : null,
          })
        );
      }

      if (action.startsWith(CODES.CTRLF3)) {
        return this.attemptToIssue();
      }

      if (action === CODES.CTRLF10) {
        return this.$nextTick(() => {
          import(
            "@/components/popups/modulos/cupons-fiscais/Complemento.vue"
          ).then((component) => {
            this.activeComponent = component.default;
          });
        });
      }

      if (includes([CODES.CTRLF8, CODES.F8], action)) {
        this.activeComponentAttrs = {
          vlr_total: this.data.vlr_total,
          item: action === CODES.F8,
        };

        if (action === CODES.CTRLF8) {
          this.activeComponentAttrs = {
            ...this.activeComponentAttrs,
            initial_desconto: this.data.vlr_desconto,
            initial_acrescimo: this.data.vlr_despesas,
          };
        }

        if (action === CODES.F8) {
          this.activeComponentListeners = {
            onDiscountItem: (discount) => {
              this.nextItemDiscount = discount;
            },
          };
        }

        return this.$nextTick(() => {
          import(
            "@/components/popups/modulos/cupons-fiscais/DescontoAcrescimo.vue"
          ).then((component) => {
            this.activeComponent = component.default;
          });
        });
      }
    },
    /**
     * mouse click event listener
     */
    onMouseDown(event) {
      // se estiver finalizando, permite clicks
      if (this.state == STATES.finishing) return;

      // se houver ficha overlay, permite clicks
      if (this.__ficha.activeFichaComponent !== null) return;

      // se houver algum assistente aberto, permite clicks
      if (this.isOverlayActionActive) return;

      if (this.menuFiscal) return;

      // permite dar focos em outros inputs não disabled
      if (event.target.tagName === "INPUT" && !event.target.disabled) return;

      // se o cupom já estiver aberto, permite clicks
      if (this.state > STATES.open) return;

      // ignora quaisquer outros clicks
      event.preventDefault();
    },
    /**
     * keyboard press event listener
     */
    onKeyDown(event) {
      // se já estiver finalizando, cancela todas as ações de teclas
      if (this.state == STATES.finishing) return;

      // se houver ficha overlay, retorna
      if (this.__ficha.activeFichaComponent !== null) return;

      // captura a referencia do container
      const ref = get(this.__ficha, "__stepContainer");

      // se o menu de contexto (criar produto/cadastro) estiver aberto, retorna
      if (ref && get(ref, "$refs.menu.isOpen")) {
        return;
      }

      if (this.menuFiscal) return;

      // se houver modal aberto (importar orcamento), retorna
      if (ref && ref.activeContextComponent !== null) return;

      // monta a ação prefixando o CTRL+ caso tenha sido pressionado o control
      const action = CODES[event.ctrlKey ? `CTRL${event.code}` : event.code];

      // Permite alternar tela cheia
      if (action === CODES.F11) return;

      // permite o ctrl + f5 para recarregar a página
      if (action === CODES.CTRLF5) return;

      // evento de help
      if (action === CODES.F1) {
        event.stopPropagation();
        return this.onActionClicked(action);
      }

      // ignora as ações quando tiver algum assistente aberto
      if (this.isOverlayActionActive) {
        return this.lastActionClicked === action
          ? this.onActiveComponentClose()
          : void 0;
      }

      // Valida se é tecla de função
      const isFKey = /^F[0-9]{1,2}$/.test(event.code);

      // Se for tecla de função
      // Se for eventos de F1-F12. ignora a função default da tecla
      if (isFKey) {
        event.preventDefault();
      }

      // Se for tecla F3 (manter o assistente antigo de informar pagamentos e cliente)
      if (action === CODES.F3) {
        return this.onActionClicked(action);
      }

      // Se for tecla F9 (importar orçamento)
      if (action === CODES.F9) {
        return this.onConfigItemClick(import_orcamento);
      }

      // Se for tecla CTRL + F8 (Desconto/Acréscimo no total)
      if (action === CODES.CTRLF8) {
        return this.onActionClicked(action);
      }

      // Se for tecla F8 (Desconto/Acréscimo no total) / (desconto no item)
      if (action === CODES.F8) {
        return this.state > STATES.open
          ? this.onActionClicked(CODES.CTRLF8)
          : this.onActionClicked(action);
      }

      // Se for tecla CTRLF10 (Complementos)
      if (action === CODES.CTRLF10) {
        return this.onActionClicked(action);
      }

      // Se for tecla F12 (fechar frente de caixa)
      if (action === CODES.F12) {
        return this.onActionClicked(action);
      }

      // ações abaixo não são permitidas se não estiver na tela inicial
      // do frente de caixa: ex: tela de clifor/pagamentos, finalizando cupom ou consultando status
      if (this.state > STATES.open) return;

      // Se for tecla F2 (Inserir Pagamento)
      if (action === CODES.F2) {
        return this.onActionClicked(
          clearCurrency(this.data.vlr_pagamento_restante) <= 0
            ? CODES.CTRLF3
            : CODES.F2
        );
      }

      // Se for tecla F4 (Informar Cliente)
      if (action === CODES.F4) {
        return this.onActionClicked(action);
      }

      // Se for tecla F6 (Fecha em cartão)
      if (action === CODES.F6) {
        return this.onActionClicked(action);
      }

      // Se for tecla F7 (Fecha em denero)
      if (action === CODES.F7) {
        return this.onActionClicked(action);
      }

      // Se for tecla F10 (Menu)
      if (action === CODES.F10) {
        return this.onActionClicked(action);
      }

      // Se não for tecla de função, faz o foco no input
      if (!isFKey) {
        this.focusTextField();
      }

      const isEnter =
        event.keyCode === 13 ||
        includes(["Enter", "NumpadEnter", 13], event.code);

      // Se for enter, faz a busca
      if (isEnter) {
        return this.$nextTick(this.$refs.selectItem.search);
      }
    },

    /**
     * Gerencia a abertura de assistentes
     */
    handleAssistents(item, registro) {
      try {
        // se for contingencia
        if (item.id === "nfce_contingencia") {
          // se a contingência está desativada, abre o assistente de ativação
          if (!get(registro, "nfce_contingencia_json.ativo", false)) {
            return this.$store.commit(mutations.APP.ASSISTENT, {
              modulo: "general",
              action: "contingency",
              registro: {
                ...registro.nfce_contingencia_json,
                document: "nfce",
              },
            });
          }

          // Caso contrário, desativa
          this.__ficha.onChangePersistent({
            modulo: "sistema",
            data: {
              nfce_contingencia_json: JSON.stringify({
                justificativa: "",
                inicio: "",
                ativo: false,
              }),
            },
          });
        }

        if (item.id === "rel_caixa") {
          registro = {
            document: this.$useSat ? "cfe-sat" : "nfce",
            caixa: this.$getStorage("frente.numero_caixa"),
            rel_type: item.report,
          };
        } else if (item.id === "nfce_csc") {
          registro = this.$emitente;
        } else if (item.id === "numero_caixa") {
          registro = {
            ...this.data,
            numero_caixa: this.$getStorage("frente.numero_caixa"),
          };
        }

        this.$store.commit(mutations.APP.ASSISTENT, {
          registro,
          modulo: "cupons-fiscais",
          action: item.id,
          __ficha: this.__ficha,
        });
      } finally {
        this.$nextTick(() => {
          this.menu = false;
        });
      }
    },
    /**
     * Requisita o assistente de métodos de pagamento
     */
    requestInsertPaymentMethod(params = {}) {
      if (this.isFichaDisabled || this.isFichaReadonly) {
        return;
      }

      if (
        !params.editMode &&
        clearCurrency(this.data.vlr_pagamento_restante) <= 0
      ) {
        return this.attemptToIssue();
      }

      this.activeComponentAttrs = {
        formas: this.extra.meios_pagamentos,
        onFinish: this.onFinishFormasPagamentos,
        ...params,
      };

      import(
        "@/components/popups/modulos/cupons-fiscais/InserirFormaPagamento.vue"
      ).then((component) => {
        this.activeComponent = component.default;
      });
    },
  },
  watch: {
    menu(isOpen) {
      !isOpen && this.focusTextField();
    },
    galeria(path) {
      if (!path) return;

      clearTimeout(this.galleryTimeout);
      this.galleryTimeout = setTimeout(() => {
        this.item.galeria = null;
        clearTimeout(this.galleryTimeout);
      }, 5000);
    },
    clifor(clifor, old) {
      if (!old?.id && clifor?.id !== null) {
        this.$nextTick(this.onResize);
      }
    },
    "data.pagamentos"() {
      if (this.data.review_imported_payment !== true) return;
      this.data.review_imported_payment = false;

      return this.onEditPayment(0, {
        title: "Confirmação do Pagamento importado",
        persistent: true,
        preventFinish: true,
      });
    },
    "__ficha.__modulo.activeContextComponent"(component) {
      if (component === null) this.focusTextField();
    },
    "data.itens"() {
      setTimeout(() => {
        const card = get(this.$refs, "scrollWrapper");
        card && (card.scrollTop = card.scrollHeight);
      }, 40);
    },
  },
});
</script>

<style lang="scss" scoped>
.elipsed-td {
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & {
    @media (min-width: 1024px) {
      max-width: 185px;
    }

    @media (min-width: 1200px) {
      max-width: 245px;
    }

    @media (min-width: 1400px) {
      max-width: 310px;
    }
  }
}
</style>
