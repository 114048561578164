<template>
  <p-text-field
    ref="input"
    :loading="loading"
    :name="name"
    :persist="persist"
    :value="value"
    :type="show ? 'text' : 'password'"
    :readonly="readonly"
    :disabled="disabled"
    @enter="$emit('enter', $event)"
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    v-bind="$attrs"
  >
    <template #append>
      <btn
        icon
        tabindex="-1"
        @click="show = !show"
        aria-label="Ver / Ocultar Senha"
      >
        <v-icon>{{ show ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
      </btn>
    </template>

    <template #progress>
      <v-progress-linear
        absolute
        rounded
        height="5"
        style="margin-top: 2px"
        aria-label="Força da Senha"
        :value="score"
        :color="scoreColor"
      />
    </template>
  </p-text-field>
</template>

<script>
import PTextField from "../persistents/PTextField.vue";
import Btn from "../buttons/Btn.vue";
import get from "lodash/get";

export default {
  components: { PTextField, Btn },
  props: {
    name: { type: String, default: "password" },
    value: { default: "" },
    score: {},
    scoreColor: {},
    persist: { type: Boolean, default: false },
    loading: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    show: false,
  }),
  methods: {
    focus() {
      const input = get(this.$refs, "input.$refs.input");
      if (!input) return;
      input.focus();
    },
  },
};
</script>
