<template>
  <v-col cols="12">
    <p-select-filter
      auto-focus
      showValueText
      value-not-null
      name="grupo_conta"
      :beforeChanged="onGrupoContaChanged"
      :items="grupos"
      v-model="data.grupo_conta"
      label="Grupo de contas"
    />
    <v-row class="ma-0">
      <p-select-filter
        md="3"
        name="nivel"
        :beforeChanged="onNivelChanged"
        :items="niveis"
        v-model="data.nivel"
        label="Nível"
      />
      <p-select-filter
        md="9"
        name="tipo"
        :items="tipos"
        v-model="data.tipo"
        label="Tipo de conta"
      />
    </v-row>
    <p-text-field
      maxlength="20"
      name="codigo"
      v-model="data.codigo"
      label="Código da conta"
    />
    <p-text-field
      name="descricao"
      maxlength="40"
      v-model="data.descricao"
      label="Nome da conta"
    />
    <p-select-filter
      name="grupo_id"
      item-value="id"
      item-text="descricao"
      label="Grupo do Estoque"
      :items="extra.grupos"
      v-model="data.grupo_id"
    />
  </v-col>
</template>

<script>
import { PSelectFilter, PTextField } from "@/components/form";

import Ficha from "@/mixins/ficha";

import { toString } from "lodash";

export default Ficha.extend({
  components: { PSelectFilter, PTextField },
  methods: {
    onGrupoContaChanged(value, _, component) {
      // adiciona os dados
      this.__ficha.append({
        codigo: `${value}${toString(this.data.codigo).substr(1)}`,
      });

      // ajusta para enviar tudo
      component.setEvent({ sendFullData: true });

      return Promise.resolve(value);
    },
    onNivelChanged(value, _, component) {
      let registro = {
        tipo: "",
        codigo: "",
      };

      // Quando o Nível for = 5. preencher o Tipo de Conta com Analítica,
      if (value === 5) {
        registro = {
          tipo: "A",
          codigo: `${this.data.grupo_conta}.1.01.001.000001`,
        };
      }

      // Quando o Nível for <> 5 preencher o Tipo de Conta com Sintética,
      else {
        registro = {
          tipo: "S",
        };

        if (value === 1) {
          registro.codigo = `${this.data.grupo_conta}`;
        }
        if (value === 2) {
          registro.codigo = `${this.data.grupo_conta}.1`;
        }
        if (value === 3) {
          registro.codigo = `${this.data.grupo_conta}.1.01`;
        }
        if (value === 4) {
          registro.codigo = `${this.data.grupo_conta}.1.01.001`;
        }
      }

      // adiciona os dados
      this.__ficha.append(registro);

      // ajusta para enviar tudo
      component.setEvent({ sendFullData: true });

      // resolve o valor padrão
      return Promise.resolve(value);
    },
  },
  computed: {
    niveis() {
      return [
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5", value: 5 },
      ];
    },
    grupos() {
      return [
        { text: "Contas de ativo", value: 1 },
        { text: "Contas de passivo", value: 2 },
        { text: "Patrimônio líquido", value: 3 },
        { text: "Contas de resultado", value: 4 },
        { text: "Contas de compensação", value: 5 },
        { text: "Outras", value: 9 },
      ];
    },
    tipos() {
      return [
        { text: "Analítica", value: "A" },
        { text: "Sintética", value: "S" },
      ];
    },
  },
});
</script>
