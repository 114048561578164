import omit from "lodash/omit";

import API from "@/api";
import actions from "@/store/actions";
import mutations from "@/store/mutations";

const prefix = "/usuarios";

const api = API.config();

export default {
  actions: {
    /**
     * Ação que faz update das permissões do usário selecionado
     */
    [actions.USERS.UPDATE_PERMISSIONS](_, permissions) {
      // Captura o id do usuário selecionado
      const { id } = permissions;

      // Remove o id
      permissions = omit(permissions, "id");

      // Retorna a promessa da requisição
      return api.post(`${prefix}/permissions`, { permissions, id });
    },

    /**
     * Ação que reseta o cache do usuário
     */
    [actions.USERS.RESET_USER]({ rootState, commit }, id) {
      // Retorna a promessa da requisição
      return api.post(`${prefix}/resetuser`, { id }).then(async (response) => {
        // Captura o usuário da resposta
        const user = (response.data && response.data.registro) ?? {};

        if (user.id === rootState.app.user.id) {
          location.reload();
        }

        // Commita a snackbar
        commit(mutations.APP.SNACKBAR, {
          active: true,
          // Mensagem de reset
          text: `Redefinidas as preferências de ${user.nome}.`,
        });
      });
    },

    /**
     * Ação para alterar a senha do usuário selecionado
     */
    [actions.USERS.UPDATE_PASSWORD]({ commit }, props) {
      return api.post(`${prefix}/password`, props).then((response) => {
        const { data } = response;

        if (data && data.message)
          commit(mutations.APP.SNACKBAR, {
            active: true,
            text: data.message,
          });

        return response;
      });
    },
  },
};
