<template>
  <text-area
    :name="`${_uid}-${name}`"
    @change="onChanged"
    @input="$emit('input', $event)"
    v-bind="$attrs"
  />
</template>

<script>
import { TextArea } from "@/components/form";

import Persistent from "./persistent";

export default Persistent.extend({
  name: "PTextArea",
  components: {
    TextArea,
  },
});
</script>
