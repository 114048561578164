var render = function render(){var _vm=this,_c=_vm._self._c;return _c('p-select-search',_vm._g(_vm._b({ref:"select",attrs:{"item-value":"id","order-by":"nome_razao_social","returnObject":"","emitAsValue":"","listItemHeight":50,"item-text":_vm.itemText,"source":_vm.source,"target":_vm.target,"filter-by":_vm.target,"name":_vm.name,"value":_vm.value,"label":_vm.label,"filter":_vm.filter,"allowRawText":true},on:{"raw-text":_vm.onRawText},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('btn',{attrs:{"icon":"","color":"grey darken-1"},on:{"click":(...event) => _vm.$emit('click:append-outer', ...event)}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]},proxy:true},{key:"list-item-content",fn:function({ item, buildTexts }){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"caption",class:{
          'v-select-list-item-selected': _vm.value && item.id === _vm.value.id,
        },domProps:{"textContent":_vm._s(buildTexts(item))}}),_vm._t("subtitle",function(){return [_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
            typeof (_vm.itemSubtitle || _vm.internalItemSubtitle) === 'function'
              ? (_vm.itemSubtitle || _vm.internalItemSubtitle)(item)
              : _vm.itemSubtitle || _vm.internalItemSubtitle
          )}})]},null,{ item })],2)]}}],null,true)},'p-select-search',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }