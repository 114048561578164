import API from "@/api";
import mutations from "@/store/mutations";
import Vue from "vue";

export default Vue.extend({
  methods: {
    cacheImage(filename, data) {
      this.$store.commit(mutations.APP.CACHED_IMAGE, {
        filename,
        data,
      });
    },
    uncacheImage(filename) {
      this.$store.commit(mutations.APP.CACHED_IMAGE, {
        filename,
        data: undefined,
      });
    },
    loadResource(filename): Promise<string> {
      return API.config()
        .get("/storage", {
          params: {
            path: filename,
          },
        })
        .then((response) => {
          this.cacheImage(filename, response.data);
          return response.data;
        });
    },
  },
});
