<template>
  <p-select-filter
    paginate
    observe-items
    ref="select"
    :name="name"
    :value="value"
    :paginating="loading"
    :page="paginator.page"
    :lastPage="paginator.lastPage"
    :items="items"
    :persist="persist"
    :item-value="itemValue"
    @search="onSearch"
    @paginate="onPaginate"
    v-bind="$attrs"
    v-on="listeners"
  >
    <template #list-item-content="attrs">
      <slot name="list-item-content" v-bind="attrs" />
    </template>
    <template #append-outer><slot name="append-outer" /></template>
  </p-select-filter>
</template>

<script>
import {
  concat,
  each,
  filter,
  get,
  head,
  isArray,
  isFunction,
  last,
  omit,
  uniqBy,
} from "lodash";
import { mapActions, mapState } from "vuex";

import { PSelectFilter } from "@/components/form";

import actions from "@/store/actions";

export default {
  components: {
    PSelectFilter,
  },
  data: () => ({
    local: [],
  }),
  props: {
    useItems: { default: null },

    source: { type: String, required: true },
    value: {},
    filter: { type: Array, default: () => [] },
    extra: { type: Array, default: () => [] },
    name: { default: "search" },
    useLocal: { type: Boolean, default: false },
    preventSearch: { type: Boolean, default: false },
    target: {},
    itemValue: { default: "id" },
    persist: { default: true },
  },
  created() {
    this.onPaginate(true);
  },
  computed: {
    ...mapState({
      loading: function (state) {
        return get(state.persistent, `fetching.${this.source}`, false);
      },
      items: function (state) {
        if (this.useItems && isArray(this.useItems)) {
          return this.useItems;
        }

        let data = get(state.persistent, `data.${this.source}`, []);

        data = concat(this.extra, this.local, data);

        each(this.filter ?? [], (_filter) => {
          const condition = head(_filter);
          const value = last(_filter);

          data = filter(data, (item) => {
            if (isFunction(condition)) {
              return value(item);
            }

            return item[condition] === value;
          });
        });

        return data;
      },
      paginator: function (state) {
        return get(state.persistent, `paginator.${this.source}`, {});
      },
    }),
    listeners() {
      return omit(this.$listeners, ["search"]);
    },
  },
  methods: {
    ...mapActions({
      fetchPersistentData: actions.MODULO.PERSISTENT.READ,
      searchPersistentData: actions.MODULO.PERSISTENT.SEARCH,
    }),
    onPaginate(firstAttempt = false) {
      this.fetchPersistentData({ firstAttempt, modulo: this.source });
    },
    onSearch(value) {
      if (this.preventSearch) return;

      if (!value) return;

      return this.searchPersistentData({
        modulo: this.source,
        target: this.target,
        value,
      }).then((data) => {
        if (this.useLocal)
          this.local = uniqBy(
            concat(this.local, get(data, "registros", [])),
            this.itemValue
          );
      });
    },
  },
};
</script>
