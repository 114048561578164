<template>
  <p-select-search
    ref="select"
    item-value="id"
    order-by="nome_razao_social"
    returnObject
    emitAsValue
    :listItemHeight="50"
    :item-text="itemText"
    :source="source"
    :target="target"
    :filter-by="target"
    :name="name"
    :value="value"
    :label="label"
    :filter="filter"
    :allowRawText="true"
    @raw-text="onRawText"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <template #append-outer>
      <btn
        icon
        color="grey darken-1"
        @click="(...event) => $emit('click:append-outer', ...event)"
      >
        <v-icon> mdi-pencil </v-icon>
      </btn>
    </template>

    <template #list-item-content="{ item, buildTexts }">
      <v-list-item-content>
        <v-list-item-title
          class="caption"
          :class="{
            'v-select-list-item-selected': value && item.id === value.id,
          }"
          v-text="buildTexts(item)"
        />
        <slot name="subtitle" v-bind="{ item }">
          <v-list-item-subtitle
            v-html="
              typeof (itemSubtitle || internalItemSubtitle) === 'function'
                ? (itemSubtitle || internalItemSubtitle)(item)
                : itemSubtitle || internalItemSubtitle
            "
          >
          </v-list-item-subtitle>
        </slot>
      </v-list-item-content>
    </template>
  </p-select-search>
</template>

<script>
import PSelectSearch from "@/components/form/persistents/PSelectSearch.vue";
import Btn from "../buttons/Btn.vue";

export default {
  components: {
    PSelectSearch,
    Btn,
  },

  props: {
    value: {},
    label: { default: "Cliente" },
    name: { default: "clifor_id" },
    rawName: { default: "cpf_cnpj" },
    itemText: {
      default: "nome_razao_social",
    },
    itemSubtitle: {},

    filter: { default: () => [["ativo", true]] },
    source: { default: "cadastro" },
    target: {
      type: Array,
      default: () => ["id", "nome_razao_social", "cpf_cnpj", "cpf_cnpj_raw"],
    },
  },
  methods: {
    internalItemSubtitle(it) {
      return `${it.cpf_cnpj || ""} ${it.municipio || ""} ${it.uf || ""}&nbsp;`;
    },
    onRawText(rawText) {
      console.log(rawText);
      console.log(this.$refs.select);
      console.log(this.$refs.select.$refs.select);
      console.log(this.$refs.select.$refs.select.onChanged);

      this.$refs.select.$refs.select.onChanged(rawText, null, this.rawName);
    },
  },
};
</script>
