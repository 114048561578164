import { keys, head, last, map, join } from "lodash";
import moment from "@/plugins/moment";
import { mDate, mTimestamp } from "../plugins/moment";

/**
 * Tipos de colunas
 */
const types = {
  ID: /^id$/,
  DATE: /_dt$/,
  TIME: /_hr$/,
  BOOLEAN: /(^tem_)|(ativo)/,
  TIMESTAMP: /_at$/,
  CURRENCY: /^vlr_/,
  QUANTITY: /^(qtd_)|(qts_)|(quantidade)|(peso)/,
  CPF_CNPJ: /(cpf|cnpj)/,
};

/**
 * Tipos de filtros
 */
export const filterTypes = {
  TIME: "time",
  DATE: "date",
  CFOP: "cfop",
  ID: "integer",
  CURRENCY: "double",
  QUANTITY: "double",
  BOOLEAN: "boolean",
  TIMESTAMP: "timestamp",
  CHOOSE_ONE: "chooseone",
  DATE_INTERVAL: "date_interval",
  FORM: "form",
  CPF_CNPJ: "cpf_cnpj",
};

/**
 * Função que captura o tipo da coluna
 */
export const getFilterTypeByColumn = (column = "") => {
  // Para cada tipo
  for (const key of keys(types)) {
    // Se a coluna faz match com o regex
    if (column.match(types[key])) {
      // Retorna o tipo
      return filterTypes[key];
    }
  }
};

/**
 * Função que formata os valores
 */
const format = (value, filter) => {
  if (filter.predicado === "<>" && !value) return "vazio";
  if (filter.predicado === "=" && !value) return "vazio";

  switch (filter.tipo_coluna) {
    case "date":
      return mDate(parseFloat(value));
    case "timestamp":
      return mTimestamp(parseFloat(value));
    default:
      return value;
  }
};

/**
 * Função que monta o label do filtro
 */
export const label = (filter, column) => {
  if (filter.label) {
    return filter.label;
  }

  // Captura o title da coluna, usando apenas o valor da mesma
  column = `<strong>${column}</strong>`;

  // Se houver filtros predefinidos com chaves identificadoras
  if (filter.key) {
    // Alterna entra as keys
    switch (filter.key) {
      // Caso for um filtro between startOfDay and endOfDay (today)
      case "today":
        // Retorna o label que representa um filtro de 'hoje'
        return `${column} igual a <strong>${mDate()}</strong>`;
      // Caso for um filtro de ativo/inativo
      case "ativo":
        // Retorna o label que representa um filtro de 'ativo'
        return head(filter.parametros)
          ? "<strong>Ativos</strong>"
          : "<strong>Inativos</strong>";

      case "authorized":
        return `${column} for autorizado`;
      case "cancelled":
        return `${column} for cancelado`;
      case "em_aberto":
        return `${column} em aberto`;
      case "rejeicao":
        return `${column} for rejeição`;

      default:
        return `${column} igual a <strong>${last(filter.parametros)}</strong>`;
    }
  }

  // Se for um filtro boolean
  if (filter.tipo_coluna === "boolean")
    // Retorna a mensagem
    return `${
      !head(filter.parametros) ? "<strong>Não</strong> " : ""
    }Possuir ${column}`;

  // Caso contrário, alterna entre o predicado
  switch (filter.predicado) {
    // Caso for um filtro entre dois valores
    case "between":
      // Retorna o label formatado, que representa um filtro entre valores
      return `${column} entre <strong>[${format(
        head(filter.parametros),
        filter
      )} e ${format(last(filter.parametros), filter)}]</strong>`;
    // Caso for um filtro negativo entre dois valores
    case "not between":
      // Retorna o label formatado, que representa um filtro entre valores
      return `${column} NÃO estiver entre <strong>[${format(
        head(filter.parametros),
        filter
      )} e ${format(last(filter.parametros), filter)}]</strong>`;
    // Caso for um filtro ilike
    case "in":
      return `${column} contendo <strong>[${format(
        join(filter.parametros, ", ") ?? "vazio",
        filter
      )}]</strong>`;
    case "ilike":
      return `${column} contendo <strong>[${format(
        head(filter.parametros) ?? "vazio",
        filter
      )}]</strong>`;
    // Caso for um filtro equals
    case "=":
      return `${column} igual a <strong>[${format(
        head(filter.parametros),
        filter
      )}]</strong>`;
    // Caso for um filtro menor ou igual
    case "<=":
      return `${column} menor ou igual a <strong>${format(
        head(filter.parametros),
        filter
      )}</strong>`;
    // Caso for um filtro maior ou igual
    case ">=":
      return `${column} maior ou igual a <strong>${format(
        head(filter.parametros),
        filter
      )}</strong>`;
    // Caso for um filtro maior
    case ">":
      return `${column} maior que <strong>${format(
        head(filter.parametros),
        filter
      )}</strong>`;
    // Caso for um filtro menor
    case "<":
      return `${column} menor que <strong>${format(
        head(filter.parametros),
        filter
      )}</strong>`;
    // Caso for um filtro not equals
    case "<>":
      return `${column} diferente de <strong>[${format(
        head(filter.parametros),
        filter
      )}]</strong>`;
    // Caso for um filtro not ilike
    case "not ilike":
    case "not in":
      return `${column} NÃO contendo <strong>[${format(
        head(filter.parametros),
        filter
      )}]</strong>`;
    // Caso for um filtro is null
    case "is null":
      return `${column} <strong>[nulo]</strong>`;
    // Caso for um filtro is not null
    case "is not null":
      return `${column} NÃO <strong>[nulo]</strong>`;
  }

  // Caso contrário, retorna vazio
  return "";
};

const filterDateFormat = "x";

export const today = (coluna, chave = "and") => ({
  coluna,
  chave,
  tipo_coluna: "date",
  predicado: "between",
  key: "today",
  parametros: [
    moment().startOf("day").format(filterDateFormat),
    moment().endOf("day").format(filterDateFormat),
  ],
});

export const date = (coluna, parametros, negative = false, chave = "and") => ({
  coluna,
  chave,
  predicado: negative ? "not between" : "between",
  parametros: map(parametros, (date) => moment(date).format(filterDateFormat)),
  tipo_coluna: "date",
});

export const between = (
  coluna,
  parametros,
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  tipo_coluna,
  chave,
  parametros,
  predicado: "between",
});

export const notBetween = (
  coluna,
  parametros,
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  tipo_coluna,
  chave,
  parametros,
  predicado: "not between",
});

export const ilike = (
  coluna,
  parametros,
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  tipo_coluna,
  chave,
  parametros,
  predicado: "ilike",
});

export const notIlike = (
  coluna,
  parametros,
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  tipo_coluna,
  chave,
  parametros,
  predicado: "not ilike",
});

export const _in = (
  coluna,
  parametros,
  key = "",
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  key,
  tipo_coluna,
  chave,
  parametros,
  predicado: "in",
});

export const notIn = (
  coluna,
  parametros,
  key = "",
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  key,
  tipo_coluna,
  chave,
  parametros,
  predicado: "not in",
});

export const isNull = (
  coluna,
  parametros,
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  tipo_coluna,
  chave,
  parametros,
  predicado: "is null",
});

export const isNotNull = (
  coluna,
  parametros,
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  tipo_coluna,
  chave,
  parametros,
  predicado: "is not null",
});

export const active = (chave = "and", tipo_coluna = "string") => ({
  chave,
  tipo_coluna,
  key: "ativo",
  coluna: "ativo",
  parametros: [true],
  predicado: "=",
});

export const inactive = (chave = "and", tipo_coluna = "string") => ({
  chave,
  tipo_coluna,
  key: "ativo",
  coluna: "ativo",
  parametros: [false],
  predicado: "=",
});

export const equals = (
  coluna,
  parametros,
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  tipo_coluna,
  chave,
  parametros,
  predicado: "=",
});

export const notEqual = (
  coluna,
  parametros,
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  chave,
  tipo_coluna,
  parametros,
  predicado: "<>",
});

export const greaterThan = (
  coluna,
  parametros,
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  tipo_coluna,
  chave,
  parametros,
  predicado: ">",
});

export const lessThan = (
  coluna,
  parametros,
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  tipo_coluna,
  chave,
  parametros,
  predicado: "<",
});

export const greaterThanOrEqualTo = (
  coluna,
  parametros,
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  tipo_coluna,
  chave,
  parametros,
  predicado: ">=",
});

export const lessThanOrEqualTo = (
  coluna,
  parametros,
  chave = "and",
  tipo_coluna = "string"
) => ({
  coluna,
  tipo_coluna,
  chave,
  parametros,
  predicado: "<=",
});

export default {
  filterTypes,
  getFilterTypeByColumn,
  label,
  today,
  date,
  between,
  notBetween,
  ilike,
  notIlike,
  isNull,
  isNotNull,
  active,
  inactive,
  equals,
  notEqual,
  greaterThan,
  lessThan,
  greaterThanOrEqualTo,
  lessThanOrEqualTo,
};
