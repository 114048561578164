import {
  mDate,
  milliseconds,
  startOfDay,
  startOfDayAdd,
} from "@/plugins/moment";
import { filterTypes } from "@/utils/filters";
import filter from "lodash/filter";
import get from "lodash/get";
import includes from "lodash/includes";

/**
 * Colunas do Grid
 */
export const columns = [
  { text: "Lançamento", value: "created_at", hidden: true },
  { text: "Data", value: "movimento_dt", type: "date" },
  { text: "Plano de contas", value: "plano_conta_descricao" },
  { text: "Histórico", value: "descricao_composta", maxWidth: "400px" },
  { text: "Cliente/Fornecedor", value: "clifor_razao_social" },
  { text: "Entrada", value: "vlr_entrada", align: "right" },
  { text: "Saída", value: "vlr_saida", align: "right" },
  { text: "Saldo", value: "vlr_saldo", align: "right" },
];

/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = ["vlr_entrada", "vlr_saida", "plano"];

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = filter(columns, (column) =>
  // Retorna as colunas que as incluem
  includes(
    [
      "movimento_dt",
      "plano_conta_descricao",
      "descricao",
      "vlr_entrada",
      "vlr_saida",
      "vlr_saldo",
    ],
    column.value
  )
);

/**
 * Ações do submenu
 */
export const subactions = function (this: any) {
  return [
    {
      type: "report",
      title: "Relatórios",
      items: reports.bind(this)(),
    },
  ];
};

/**
 * Relatórios
 */
export const reports = function (this: any) {
  // Captura o registro selecionado
  const disabled = !this.registro || !this.registro.id;
  const data = get(this.registro, "movimento_dt");

  const filterDateInterval = {
    // Iniciando 30 dias antes de hoje
    queryStart: startOfDay(),
    // Terminando hoje
    queryEnd: startOfDayAdd("60"),
  };

  return [
    {
      id: "caixa_dia",
      title: `Caixa do dia ${mDate(data)}`,
      registro_id: data,
      disabled,
    },
    {
      id: "fluxo_caixa",
      title: "Fluxo de caixa",
      type: "form-report",
      form: [
        {
          title: "Fluxo de caixa",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
      features: `left=0,width=${794}px,height=${1123}px`,
    },
    {
      id: "analise_anual",
      title: `Análise Anual`,
    },
  ];
};

/**
 * Ficha default
 */
export const ficha = () => ({
  movimento_dt: milliseconds(),
});

/**
 * Cache default
 */
export const cache = {
  colunas: [
    "movimento_dt",
    "plano_conta_descricao",
    "descricao_composta",
    "vlr_entrada",
    "vlr_saida",
    "vlr_saldo",
  ],
  filtros: [],
  ordenar: [
    {
      coluna: "movimento_dt",
      ordem: "desc",
    },
    {
      coluna: "created_at",
      ordem: "desc",
    },
  ],
};
