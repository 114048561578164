import API from "@/api";
import actions from "@/store/actions";
import mutations from "@/store/mutations";

const api = API.config({
  state: "loading",
  mutation: mutations.REPORTS.LOADING,
});

const initialReport = {
  request: {},
  response: {},
};

const intialState = {
  data: {},
  loading: true,
  count: 0,
  selected: -1,

  report: Object.assign({}, initialReport),
};

export default {
  state: Object.assign({}, intialState),

  mutations: {
    /**
     * Mutação de loading dos relatórios
     */
    [mutations.REPORTS.LOADING](state, loading) {
      // Seta loading
      state.loading = loading;
    },

    /**
     * Mutação de selected dos relatórios
     */
    [mutations.REPORTS.SELECTED](state, selected = -1) {
      // Seta selected
      state.selected = selected;
    },

    /**
     * Mutação de selected dos relatórios
     */
    [mutations.REPORTS.COUNT](state, count) {
      // Seta count
      state.count = count;
    },

    /**
     * Mutação de relatórios
     */
    [mutations.REPORTS.DATA](state, reports) {
      // Seta os relatórios
      state.data = reports;
    },

    /**
     * Mutação de relatórios
     */
    [mutations.REPORTS.REPORT](state, report) {
      // Seta o relatório
      state.report = {
        ...state.report,
        ...report,
      };
    },

    /**
     * Mutação de relatórios
     */
    [mutations.REPORTS.CLEAR_REPORT](state) {
      // Limpa o relatório
      state.report = Object.assign({}, initialReport);
    },
  },

  actions: {
    /**
     * Função que faz a leitura dos relatórios
     */
    [actions.REPORTS.READ]() {
      //
      return false;
    },

    /**
     * Função que faz a geração de um relatório
     */
    [actions.REPORTS.REPORT](store, options) {
      const { commit } = store;

      const { modulo, id, params, ...rest } = options;
      let { registro } = options;

      registro = registro ? `/${registro}` : "";

      return api
        .store(store)
        .post(`/${modulo}/reports/${id}${registro}`, rest, {
          params,
        })
        .then(({ data }) => {
          if (!data) return;

          commit(mutations.REPORTS.REPORT, { response: data.report ?? {} });
        });
    },
  },
};
