<template>
  <div>
    <template v-for="i in length">
      <slot :name="`item-${i}`" v-if="i === value" />
    </template>
  </div>
</template>

<script>
import { keys } from "lodash";

export default {
  props: {
    value: {},
  },
  computed: {
    length() {
      return keys(this.$slots).length ?? 6;
    },
  },
  watch: {
    value: {
      handler(step) {
        if (step) return this.$emit(`loaded-${step}`);
      },
    },
  },
};
</script>
