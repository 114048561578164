<template>
  <v-col cols="12" md="6" lg="5" offset-lg="1">
    <v-row>
      <p-date-time
        auto-focus
        name="movimento_dt"
        label="Data"
        v-model="data.movimento_dt"
      />
      <p-select-filter
        name="plano_conta_id"
        label="Plano de contas"
        :item-texts="['conta', 'descricao']"
        item-value="id"
        :items="extra.plano_contas"
        v-model="data.plano_conta_id"
      />
      <p-text-field
        name="descricao"
        label="Histórico"
        v-model="data.descricao"
      />
      <!-- <clifor-select
        label="Cliente/Fornecedor"
        name="clifor_id"
        :valueNotNull="false"
        :clifor="clifor"
        v-model="data.clifor_id"
      /> -->
      <p-precision-field
        name="vlr_entrada"
        label="Entrada"
        :disabled="isDespesa"
        v-model="data.vlr_entrada"
      />
      <p-precision-field
        name="vlr_saida"
        label="Saída"
        :disabled="isReceita"
        v-model="data.vlr_saida"
      />
      <p-precision-field
        readonly
        name="vlr_saldo"
        label="Saldo"
        v-model="data.vlr_saldo"
      />
    </v-row>
  </v-col>
</template>

<script>
import find from "lodash/find";
import get from "lodash/get";

import {
  PTextField,
  PDateTime,
  PPrecisionField,
  PSelectFilter,
  // CliforSelect,
} from "@/components/form";

// Mixins
import Clifor from "@/mixins/clifor";

export default Clifor.extend({
  components: {
    PTextField,
    PDateTime,
    PPrecisionField,
    PSelectFilter,
    // CliforSelect,
  },
  computed: {
    conta() {
      return get(
        find(
          this.extra.plano_contas,
          (it) => it.id === this.data.plano_conta_id
        ),
        "conta"
      );
    },
    isReceita() {
      return this.conta && this.conta.startsWith("1");
    },
    isDespesa() {
      return this.conta && this.conta.startsWith("3");
    },
  },
});
</script>
