<template>
  <v-card flat>
    <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
      <v-row>
        <container-content class="pl-md-2" title="Cliente" md="6">
          <v-row>
            <clifor-combobox-v2
              label="CPF/CNPJ / Razão Social do Cliente"
              @click:append-outer="onEditCliforClicked"
              v-model="data.clifor"
            />
          </v-row>
          <v-row>
            <list-info :items="cliente" />
          </v-row>
        </container-content>
        <container-content class="pr-md-2" title="Vendedor" md="6">
          <v-row>
            <clifor-select
              label="Nome do vendedor"
              name="colaborador_id"
              :valueNotNull="false"
              :filter="vendedor_filter"
              :clifor="colaborador"
              v-model="data.colaborador_id"
            />
          </v-row>
          <v-row>
            <list-info :items="infoVendedor" />
          </v-row>
        </container-content>
      </v-row>

      <v-row>
        <v-col class="px-0 px-md-1 pt-0 pb-2">
          <dependence-table
            enable-focus
            class="pb-0"
            modulo="orcamento-itens"
            :__ficha="__ficha"
            :selected="selected"
            @selected="setSelected"
            v-model="data"
          />
        </v-col>
      </v-row>

      <v-row class="px-0 px-md-1 pt-0">
        <p-precision-field
          sm="6"
          md="3"
          name="vlr_frete"
          v-model="data.vlr_frete"
          label="Frete"
        />
        <p-date-time
          sm="6"
          md="3"
          name="validade_dt"
          type="date"
          label="Validade"
          v-model="data.validade_dt"
        />
        <precision-field
          readonly
          sm="6"
          md="3"
          label="Desconto"
          v-model="data.vlr_desconto"
          @click.native="requestDiscountAssistent"
        />
        <precision-field
          sm="6"
          md="3"
          disabled
          label="Total"
          v-model="data.vlr_total"
        />
      </v-row>

      <v-row>
        <v-col class="px-0 pl-md-1 pt-0 pb-0" cols="12" md="6">
          <p-text-area
            cols="12"
            rows="3"
            maxlength="254"
            label="Observação"
            name="obs"
            v-model="data.obs"
          />
        </v-col>

        <v-col class="pt-0 px-0" sm="8" md="5">
          <single-pagamento
            :disabled="!data.id"
            :data="data"
            :__ficha="__ficha"
          />
        </v-col>

        <v-col sm="4" md="1" class="d-flex align-center">
          <btn
            text
            btn-class="mx-auto pa-0 focus-priority"
            color="secondary"
            height="50"
            :disabled="!data.id"
            @click="print"
          >
            <v-img
              contain
              height="50"
              width="50"
              :src="require('@/assets/menu/actions/imprimir.svg')"
            />
          </btn>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>

<script>
import get from "lodash/get";
import { ListInfo } from "@/components/utils";

import {
  PTextArea,
  CliforSelect,
  PSelectSearch,
  ContainerContent,
  DependenceTable,
  PPrecisionField,
  PrecisionField,
  Btn,
  SinglePagamento,
  CliforComboboxV2,
  PDateTime,
} from "@/components/form";

// Mixins
import Clifor from "@/mixins/clifor";
import { clearCurrency, setCurrency } from "@/utils";

export default Clifor.extend({
  name: "FichaOrcamento",
  components: {
    ListInfo,
    Btn,
    CliforSelect,
    CliforComboboxV2,
    PSelectSearch,
    ContainerContent,
    DependenceTable,
    PPrecisionField,
    PrecisionField,
    SinglePagamento,
    PTextArea,
    PDateTime,
  },
  methods: {
    print() {
      this.$routeResolver({
        name: "report",
        params: {
          modulo: "orcamento",
          id: "visualizar",
          registro: this.data.id,
        },
      });
    },
    requestDiscountAssistent() {
      // Importa o componente
      return import(
        "@/components/popups/modulos/cupons-fiscais/DescontoAcrescimo.vue"
      ).then((Component) => {
        this.__ficha.activeContextProps = {
          __ficha: this.__ficha,
          onlyDiscount: true,
          initial_desconto: this.data.vlr_desconto,
          vlr_total: setCurrency(
            parseFloat(clearCurrency(this.data.vlr_total)) +
              parseFloat(clearCurrency(this.data.vlr_desconto))
          ),
        };

        return this.$nextTick(
          () => (this.__ficha.activeContextComponent = Component.default)
        );
      });
    },
  },
  computed: {
    infoVendedor() {
      return [
        get(this.data, "colaborador_razao_social") ?? "<Nome do vendedor>",
        this.$emitente.razao_social || "<Razão Social>",
        this.$emitente.telefone || "<Telefone>",
        this.$emitente.cpf_cnpj || "<CPF/CNPJ>",
      ];
    },
  },
});
</script>
