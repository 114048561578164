// Core
import core from "@/store/modules/core";
import app from "@/store/modules/app";
import modulo from "@/store/modules/modulo";
import users from "@/store/modules/users";
import dashboard from "@/store/modules/dashboard";
import novidades from "@/store/modules/novidades";

// Utils
import utils from "@/store/modules/utils";
import notifications from "@/store/modules/utils/notifications";

// Transmission
import dfe from "@/store/modules/transmission/dfe";

// Imports
import import_ from "@/store/modules/import";

// Exports
import export_ from "@/store/modules/export";

// Modulo
import registros from "@/store/modules/modulo/registros";
import ficha from "@/store/modules/modulo/ficha";
import persistent from "@/store/modules/modulo/persistent";

// Config
import config from "@/store/modules/config";

// Relatórios
import reports from "@/store/modules/reports";

// Módulos
import modulos from "@/store/modules/modulos";

// Módulos
import register from "@/store/modules/register";

/**
 * @exports Object Objeto com os modules a serem usados pela Vuex
 */
export default {
  // Core
  app,
  core,
  modulo,
  users,
  dashboard,
  novidades,

  // Utils
  utils,
  notifications,

  // Transmission
  dfe,

  // Módulos
  modulos,

  // Imports
  import: import_,

  // Exports
  export: export_,

  // Modulo
  registros,
  ficha,
  persistent,

  // Config
  config,

  // Reports
  reports,

  // Register
  register,
};
