<template>
  <p-text-field
    type="tel"
    :name="name"
    :label="label"
    v-mask="masks"
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { mask } from "vue-the-new-mask";

import { PTextField } from "@/components/form";

export default {
  components: { PTextField },
  directives: { mask },
  props: {
    name: { default: "telefone" },
    label: { default: "Telefone" },
    value: {},
  },
  computed: {
    masks() {
      return ["## ####-####", "## #####-####"];
    },
  },
};
</script>
