<template>
  <v-col
    class="pb-0 pt-2 px-1"
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
    v-bind="$attrs"
  >
    <v-card outlined :min-height="minHeight">
      <v-card-text>
        <v-row class="pa-0" v-if="!hidden">
          <v-chip
            v-if="title"
            small
            class="border-chip font-weight-bold mb-1"
            text-color="actionbutton"
            >{{ title }}</v-chip
          >
        </v-row>
        <slot />
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    title: {},
    minHeight: { type: String, default: "" },
    hidden: { type: Boolean, default: false },
    cols: { default: "12" },
    sm: {},
    md: {},
    lg: {},
    xl: {},
  },
};
</script>

<style scoped>
.border-chip {
  border: 1px solid #d8d8d8 !important;
}
</style>
