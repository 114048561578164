<template>
  <modal
    movable
    :title="title"
    :value="true"
    :width="$isMobile ? '95vw' : '60vw'"
    @input="$emit('close')"
  >
    <template #text>
      <v-col md="8" offset-md="2">
        <v-row>
          <field-desconto
            auto-focus
            ignore-error-message
            invalid-value-null
            ignore-old-value
            :maxPercentual="maxPercentual"
            :ignore-total-value="item"
            :basePercentual="item ? null : vlr_total"
            :persist="false"
            label="Desconto"
            @change="onChange('vlr_desconto')"
            v-model="vlr_desconto"
          />
        </v-row>
        <v-row v-if="!item && !onlyDiscount">
          <field-desconto
            ignore-error-message
            invalid-value-null
            ignore-old-value
            :maxPercentual="maxPercentual"
            :basePercentual="vlr_total"
            :persist="false"
            label="Acréscimo"
            @change="onChange('vlr_despesas')"
            v-model="vlr_despesas"
          />
        </v-row>

        <v-row v-if="isPasswordEnabled">
          <password
            auto-focus
            :disabled="false"
            label="Senha"
            v-model="password"
          />
        </v-row>

        <v-row class="mt-8">
          <v-col cols="12" class="px-1 py-0">
            <p class="font-weight-normal">
              Para um valor fixo de desconto{{
                !item && !onlyDiscount ? " ou acréscimo" : ""
              }}
              em <span class="font-weight-black">R$</span>, apenas adicione o
              valor do desconto.
              <span class="font-weight-black">Ex: 5,00</span>
            </p>
            <p class="font-weight-normal">
              Para preencher um
              <span class="font-weight-black">percentual</span> de desconto{{
                !item && !onlyDiscount ? " ou acréscimo " : ""
              }}, apenas adicione o símbolo de % após o valor.
              <span class="font-weight-black">Ex: 5,00%</span>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #action-content>
      <v-spacer />
      <btn
        color="error"
        :disabled="loading != ''"
        class="mr-2"
        @click="$emit('close')"
        >Cancelar</btn
      >
      <btn
        outlined
        btn-class="focus-priority"
        :disabled="loading != ''"
        :loading="loading === 'confirm'"
        @click="onConfirm"
        >Confirmar</btn
      >
      <v-spacer />
    </template>
  </modal>
</template>

<script>
import get from "lodash/get";

import Btn from "@/components/form/buttons/Btn.vue";
import FieldDesconto from "@/components/form/mutuals/FieldDesconto.vue";
import Password from "@/components/form/inputs/Password.vue";
import Modal from "@/components/utils/Modal.vue";

import { clearCurrency } from "@/utils";

export default {
  components: {
    Btn,
    Modal,
    Password,
    FieldDesconto,
  },
  props: {
    vlr_total: { default: "0,00" },
    item: { default: false, type: Boolean },
    onlyDiscount: { default: false, type: Boolean },
    initial_desconto: { default: null },
    initial_acrescimo: { default: null },
    maxPercentual: { default: "99,00" },
    __ficha: {},
  },
  created() {
    if (clearCurrency(this.initial_desconto)) {
      this.vlr_desconto = this.initial_desconto;
    } else if (clearCurrency(this.initial_acrescimo)) {
      this.vlr_despesas = this.initial_acrescimo;
    }
  },
  data: () => ({
    vlr_desconto: null,
    vlr_despesas: null,
    isPasswordEnabled: false,

    loading: "",
    password: "",
  }),
  computed: {
    title() {
      return this.item
        ? "Desconto no próximo Item"
        : this.onlyDiscount
        ? "Desconto"
        : "Desconto / Acréscimo";
    },
  },
  methods: {
    onChange(column) {
      if (column === "vlr_desconto") return (this.vlr_despesas = null);
      return (this.vlr_desconto = null);
    },
    async onConfirm() {
      if (this.item)
        return (
          this.$emit("onDiscountItem", this.vlr_desconto), this.$emit("close")
        );

      this.loading = "confirm";

      try {
        await this.__ficha.onChangePersistent({
          modulo: this.__ficha.modulo,
          data: {
            password: this.password,
            vlr_desconto: this.vlr_desconto,
            vlr_despesas: this.vlr_despesas,
          },
        });

        this.$emit("close");
      } catch (e) {
        this.isPasswordEnabled =
          get(e, "response.data.required_field") === "password";
      } finally {
        this.loading = "";
      }
    },
  },
};
</script>
