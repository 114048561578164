<template>
  <p-precision-field
    ref="persistent"
    :allowed-pattern="/%$/g"
    :name="name"
    :label="label"
    :value="value"
    :persist="false"
    @input="$emit('input', $event)"
    @change="onChange"
    v-bind="$attrs"
  />
</template>

<script>
import { get, includes, isNull } from "lodash";

import { PPrecisionField } from "@/components/form";
import { mapMutations } from "vuex";
import mutations from "@/store/mutations";

export default {
  components: { PPrecisionField },
  props: {
    value: {},
    name: { default: "desconto" },
    label: { default: "Desconto" },
    persist: { type: Boolean, default: true },

    ignoreTotalValue: { type: Boolean, default: false },
    ignoreOldValue: { type: Boolean, default: false },
    ignoreErrorMessage: { type: Boolean, default: false },
    invalidValueNull: { type: Boolean, default: false },
    basePercentual: { default: null },
    maxPercentual: { default: "100,00" },
  },
  methods: {
    ...mapMutations({
      alert: mutations.APP.ALERT,
    }),
    onChange(value, oldValue) {
      /**
       *
       * Total R$ = 100
       * Max = 50%
       *
       * Insere 60% => Block
       * Insere 50% => emit(50 * (100 + old || 0.0) / 100)
       *
       * Insere R$60 => Block pois, (value * 100 / max) > 100 (%)
       * Insere R$50 => emit(50)
       */

      // Captura a ref
      const persistent = get(this.$refs, "persistent");

      // Se não houver valor total, contiua com o fluxo normalmente
      if (isNull(this.basePercentual))
        // Executa o fluxo normal
        return this.normalFlow(persistent, value);

      // Captura a ref do precision
      const precision = get(persistent, "$refs.precision");

      // Formata o percentual máximo
      const maxPercentual = precision.parseToFloat(this.maxPercentual);

      // Captura o valor, substituindo a , por . (para o padrão nativo)
      const _value = precision.parseToFloat(value);

      // Valor total REAL (o valor antigo mais o valor total)
      const totalValue =
        // Valor total
        precision.parseToFloat(this.basePercentual) +
        // Valor antigo de desconto
        (this.ignoreOldValue ? 0.0 : precision.parseToFloat(oldValue) || 0.0);

      // Se o valor for valor em %
      if (includes(value, "%")) {
        // Se for pra ignorar o valor total como base, apenas deixa o % no input
        if (this.ignoreTotalValue) return this.normalFlow(persistent, value);

        // Se o valor ultrapassar o percentual máximo
        if (_value > maxPercentual)
          // Retorna o fluxo inválido
          return this.invalidFlow(oldValue);

        // Caso contrário, retorna o cálculo da porcentagem
        return persistent.onChanged(
          // Chama a função que transforma em BR
          precision.parseToBr(
            // Calcula o percentual
            (_value * totalValue) / 100
          )
        );
      }

      // Valor máximo de desconto
      const maxDiscount = (totalValue * maxPercentual) / 100;

      // Caso for valor em R$ e ultrapassa o limite de desconto
      if (_value > maxDiscount)
        // Retorna o fluxo inválido
        return this.invalidFlow(oldValue);

      // Caso contrário, executa o fluxo normal
      return this.normalFlow(persistent, value);
    },
    /**
     * Fluxo de valor máximo de desconto ultrapassado
     */
    invalidFlow(oldValue) {
      // Retorna o valor antigo
      this.$emit("input", this.invalidValueNull ? null : oldValue);

      if (this.ignoreErrorMessage) return;

      // Aguarda o tick
      return this.$nextTick(() => {
        // Abre o modal de alerta
        this.alert({
          active: true,
          title: "Desconto não permitido!",
          message: `Verifique as permissões com o administrador em:<br>Configurações do sistema > Permitir.<br><br>O desconto máximo no total do orçamento está configurado para <strong>${this.maxPercentual}%</strong>`,
        });
      });
    },
    /**
     * Fluxo de um evento normal de change, sem cálculos de porcentagem
     */
    normalFlow(ref, value) {
      // Emite o evento para atualizar o componente pai
      this.$emit("input", value);

      // Emite o evento que atualizou o valor
      this.$emit("change", value);

      if (!this.persist) return;

      // Retorna o valor atual
      return ref.save(value);
    },
  },
};
</script>
