<template>
  <v-col
    :class="colClass"
    :cols="cols"
    :sm="sm"
    :md="md"
    :offset-md="offsetMd"
    :lg="lg"
    :xl="xl"
    :id="`col-${_uid}`"
  >
    <v-menu
      offset-y
      ref="menu"
      :top="top"
      :class="`select-filter-menu-${_uid}`"
      :close-on-click="false"
      :close-on-content-click="false"
      v-show="booted"
      v-model="menu"
    >
      <template #activator="{ on, attrs }">
        <component
          without-cols
          ref="textField"
          :is="component"
          :class="textFieldClass"
          :loading="loading"
          :disabled="disabled || locked_"
          :readonly="readonly || locked_"
          @keydown.down.up.exact="onTextFieldKeyDown($event, on)"
          @keydown.exact.esc="closeMenu"
          @blur="onBlur"
          @change="onTextFieldChanged"
          @focus="$emit('focus', $event), (focused = true)"
          @input="(value, event) => onTextFieldInput(value, event, on)"
          @ctrl-delete="$emit('ctrl-delete', $event), (inputValue = '')"
          :value="inputValue"
          v-bind="{ ...$attrs, ...attrs }"
          v-on="{ ...pick($listeners, ['click:append-outer']) }"
        >
          <template #append>
            <slot name="append" />
            <btn
              icon
              tabindex="-1"
              tooltip="Remover"
              :btn-class="`select-filter-${_uid}-btn`"
              :disabled="disableButton || disabled || readonly"
              @click.stop="reset()"
              v-if="clearable && !valueNotNull && value"
            >
              <v-icon
                small
                :color="
                  $attrs.white ? 'white' : focused ? 'primary' : 'grey darken-1'
                "
              >
                mdi-close
              </v-icon>
            </btn>
            <btn
              icon
              tabindex="-1"
              :btn-class="`select-filter-${_uid}-btn`"
              :disabled="disableButton || disabled || readonly"
              @click="modal = true"
              v-if="locked_"
            >
              <v-icon
                dense
                :color="
                  $attrs.white ? 'white' : focused ? 'primary' : 'grey darken-1'
                "
              >
                mdi-lock
              </v-icon>
            </btn>
            <btn
              icon
              tabindex="-1"
              :btn-class="`select-filter-${_uid}-btn`"
              :disabled="disableButton || disabled || readonly"
              v-else
              @click="menu ? closeMenu() : openMenu($event, on)"
            >
              <v-icon
                dense
                :color="
                  $attrs.white ? 'white' : focused ? 'primary' : 'grey darken-1'
                "
                :class="{ 'v-icon-animated': menu }"
              >
                mdi-menu-down
              </v-icon>
            </btn>
          </template>

          <template #append-outer>
            <slot name="append-outer" />
          </template>
        </component>
      </template>

      <v-list dense class="py-0" ref="list">
        <v-virtual-scroll
          tabindex="0"
          :class="`v-virtual-scroll-${_uid}`"
          :bench="0"
          :items="items_"
          :height="listItemHeight * visibleItems"
          :item-height="listItemHeight"
          @keydown.native.stop.prevent="onKeyDown"
          @scroll="onScroll"
        >
          <!-- @blur.native="closeMenu" -->
          <template v-slot="{ item, index }">
            <v-list-item
              tabindex="1"
              :class="[
                `list-${_uid}-item`,
                `list-${_uid}-item-${index}`,
                highlighted === index && 'v-list-item--highlighted',
              ]"
              :data-key="index"
              :key="index"
              :disabled="item && item.disabled"
              @click.stop="() => onItemSelectChange(item)"
            >
              <slot name="list-item-content" v-bind="{ item, buildTexts }">
                <v-list-item-content>
                  <v-list-item-title
                    class="caption"
                    :class="{
                      'v-select-list-item-selected': returnObject
                        ? value && item[itemValue] === value[itemValue]
                        : item[itemValue] === value,
                    }"
                    v-text="buildTexts(item)"
                  />
                </v-list-item-content>
              </slot>
            </v-list-item>
          </template>
        </v-virtual-scroll>

        <v-progress-linear
          indeterminate
          relative
          v-if="paginate && paginating"
        />
      </v-list>
    </v-menu>

    <admin
      destroy
      @close="(locked = true), (modal = false)"
      @auhorized="onAuthorized"
      v-if="locked && modal"
    />
  </v-col>
</template>

<script>
import {
  findIndex,
  find,
  uniqBy,
  filter,
  pick,
  values,
  includes,
  concat,
  compact,
  uniq,
  isEmpty,
  head,
  last,
  join,
  debounce,
  map,
  get,
  isObject,
} from "lodash";

import Btn from "../buttons/Btn.vue";
import TextField from "./textfield/TextField.vue";
import CTextField from "./textfield/CTextField.vue";
import Admin from "@/components/utils/Admin.vue";

export default {
  components: { Btn, Admin },

  props: {
    value: { required: false },
    readonly: { type: Boolean, default: false },

    paginate: { type: Boolean, default: false },
    paginating: { type: Boolean, default: false },
    page: { default: 0 },
    lastPage: { default: 0 },

    disabled: { type: Boolean, default: false },
    guard: { type: Boolean, default: false },

    mandatory: { type: Boolean, default: false },

    listItemHeight: { type: Number, default: 40 },

    items: {
      type: Array,
      default: () => [],
    },

    textFieldClass: {},

    inputInitial: { default: "" },

    initial: { required: false },
    valueNotNull: { type: Boolean, default: false, required: false },
    allowRawText: { type: Boolean, default: false, required: false },
    disableButton: { type: Boolean, default: false, required: false },

    filterBy: { type: Array, default: () => [] },
    // autoFocus: { type: Boolean, default: false },
    loading: { type: Boolean, default: null },
    clearable: { type: Boolean, default: true },
    clear: { type: Boolean, default: false },

    returnObject: { type: Boolean, default: false },
    observeItems: { type: Boolean, default: false },

    showValueText: { type: Boolean, default: false },

    itemText: { type: [String, Function], default: "text" },
    itemValue: { type: String, default: "value" },

    itemTexts: { type: Array, default: () => [] },

    cols: { default: "12" },
    pl: { type: Boolean, default: false },
    sm: { required: false },
    md: { required: false },
    offsetMd: { required: false },
    lg: { required: false },
    xl: { required: false },
  },

  data: () => ({
    // Auth modal
    modal: false,
    locked: true,

    booted: false,
    top: false,
    menu: false,
    filtering: false,
    changed: false,
    inputed: false,
    focused: false,
    inputValue: "",
    selectedIndex: -1,
    visibleItems: 5,

    highlighted: 0,

    keyCodes: {
      esc: 27,
      up: 38,
      down: 40,
      enter: 13,
    },
  }),
  computed: {
    __self() {
      return this;
    },
    itemTexts_() {
      return this.showValueText
        ? [this.itemValue, this.itemText]
        : this.itemTexts;
    },

    locked_() {
      return this.guard && this.locked;
    },
    /**
     * Função que retorna se está desabilitado
     */
    isDisabled() {
      return (
        this.menu ||
        this.disabled ||
        this.disableButton ||
        this.readonly ||
        this.locked_
      );
    },
    colClass() {
      return {
        "pa-0": this.clear,
        "pb-1 pt-2 px-1": !this.clear,
        "pl-2": this.pl,
      };
    },
    component() {
      return this.clear ? CTextField : TextField;
    },
    fullItems() {
      return concat(
        this.items,
        typeof this.value === "object" && this.value !== null
          ? [this.value]
          : []
      );
    },
    /**
     * Itens filtrados e únicos, para evitar repetição de valores iguais
     */
    items_() {
      return uniqBy(
        concat(
          this.isFiltering ? this.filtered : uniqBy(this.items, this.itemValue),
          this.initial ? [this.initial] : []
        ),
        this.itemValue
      );
    },
    /**
     * Verifica se está sendo filtrado as informações
     */
    isFiltering() {
      return this.filtering;
    },
    selectedItem() {
      return find(
        this.fullItems,
        // (item) => item && item[this.itemValue] === this.value
        (item) =>
          this.returnObject
            ? get(item, this.itemValue, null) ===
              get(this.value, this.itemValue, null)
            : get(item, this.itemValue, null) === this.value
      );
    },
    /**
     * Função que filtra os dados apropriadamente
     * Padrão de key para filtrar = this.itemText
     * Pode ser múltiplas chaves atribuindo à this.filterBy
     */
    filtered() {
      // Compacta os dados pra evitar ir item null
      return compact(
        // Filtra os itens
        filter(this.items, this.findThroughIndicies)
      );
    },
  },
  mounted() {
    this.checkInitialState();

    this.$on("updated", () => {
      this.checkInitialState();
    });

    this.$nextTick(() => (this.booted = true));
  },
  beforeDestroy() {
    this.$off("updated");

    this.booted = false;
  },
  methods: {
    pick,
    onAuthorized() {
      this.locked = false;

      if (this.value && this.items.length === 2) {
        const item = this.items[this.selectedIndex === 0 ? 1 : 0];

        if (item) {
          this.onItemSelectChange(item);
        }
      }
    },
    onScroll: debounce(function () {
      if (!this.paginate || this.paginating || this.filtering) return;

      if (this.page >= this.lastPage) return;

      const container = document.querySelector(
        `.v-virtual-scroll-${this._uid}`
      );

      const offset = this.listItemHeight * this.visibleItems + 400;

      if (container.scrollTop >= container.scrollHeight - offset) {
        return this.$emit("paginate");
      }
    }, 10),
    onTextFieldInput(value, event, on) {
      // Valida se o vlaue é um objeto (se for objeto estamos setando sem interferir no evento de search)
      const isObject = typeof value === "object";
      // Valor recebe value.value caso objeto, caso contrário o proprio value
      value = isObject ? value.value : value;

      if (event && on && !this.menu) this.openMenu(event, on);

      // Seta o valor no data com o value
      this.inputValue = value;

      if (this.mandatory) this.highlighted = 0;

      // Se nao for objeto, trata-se de um comportamento de digitação do usuario
      if (!isObject) {
        this.inputed = true;
        // Emite evento search
        this.prepareToSearch(value);
        // Habilita o filtro dos itens baseado no text-field
        this.filtering = true;
      }

      if (!value) this.filtering = false;
    },
    prepareToSearch: debounce(function (value) {
      if (this.focused) this.$emit("search", value);
    }, 400),
    onTextFieldChanged() {
      this.changed = true;
    },
    onTextFieldKeyDown(event, on) {
      switch (event.code) {
        case "ArrowDown":
          if (!this.menu) return this.openMenu(event, on);
          break;
      }

      this.onKeyDown(event, true);
    },
    findItemIndex(item) {
      return (
        findIndex(
          this.items,
          (_item) => _item[this.itemValue] === item[this.itemValue]
          // this.returnObject ? item : { [this.itemValue]: item[this.itemValue] }
        ) ?? -1
      );
    },
    findItemByCurrentInput(equals = false) {
      return find(this.items, (item) => this.findThroughIndicies(item, equals));
    },
    onKeyDown: debounce(function (event, firstCall = false) {
      // Desestrutura os códigos de teclas que serão usadas
      const { up, down, enter, esc } = this.keyCodes;

      const goToItem = (position = 1) => {
        const isDown = position === 1;

        if (isDown && this.highlighted + position === this.items_.length)
          return;

        if (!isDown && this.highlighted + position < 0)
          return this.focusTextField();

        this.highlighted += position;

        // Aguarda próximo tick
        this.$nextTick(() => {
          const virtualScroll = document.querySelector(
            `.v-virtual-scroll-${this._uid}`
          );

          if (!virtualScroll) return;

          virtualScroll.focus();

          const items = document.querySelectorAll(
            `.v-virtual-scroll-${this._uid} .v-list-item`
          );

          const index = findIndex(items, (item) =>
            includes(
              item.className,
              `list-${this._uid}-item-${this.highlighted}`
            )
          );

          const performHighlight = () => {
            this.highlighted = -1;

            if (!firstCall)
              virtualScroll.scrollTop +=
                this.listItemHeight * this.visibleItems * position;
            // else if (isDown) {
            // }

            setTimeout(() => {
              const items = document.querySelectorAll(
                `.v-virtual-scroll-${this._uid} .v-list-item`
              );

              const nextHighlighted = isDown ? head(items) : last(items);

              this.highlighted = parseFloat(nextHighlighted.dataset.key) ?? 0;
            }, 40);
          };

          if (this.highlighted === -1) return;

          if (index == -1) {
            return performHighlight();
          }
        });
      };

      // Define os eventos de tecla da tabela
      const events = {
        // Evento de tecla para cima
        [up]: () => {
          // Chama a função com o índice negativo (para cima)
          return goToItem(-1);
        },
        // Evento de tecla para baixo
        [down]: () => {
          // Chama a função com o índice positivo (para baixo)
          return goToItem(1);
        },
        [enter]: () => {
          const item = this.items_[this.highlighted];

          // this.selectedIndex = this.highlighted;
          this.onItemSelectChange(item);
        },
        [esc]: () => {
          this.closeMenu();

          this.focusTextField();
        },
      };

      // Se não ouver eventos para essa tecla, retorna uma função nula
      if (!events[event.keyCode]) return () => null;

      // Prevê o padrão do evento
      event.preventDefault();

      // Acessa o evento da tecla, e chama a função
      events[event.keyCode]();
    }, 5),
    /**
     * Função que ativa quando o usuário perder o foco no text-field
     */
    async onBlur(event) {
      // Propaga o evento do blur
      this.$emit("blur", event);

      this.focused = false;
      this.inputed = false;

      const inputValue = this.inputValue;

      // Valida se não tiver valor, que dizer que o usuário não quer mais selecionar nenhum item,
      // Sendo assim, emite o evento do two-way data bindind com o valor null
      if (event && event.relatedTarget) {
        if (
          // event.relatedTarget.className.includes("v-list-item") ||
          event.relatedTarget.className.includes(`list-${this._uid}-item`) ||
          event.relatedTarget.className.includes(
            `select-filter-${this._uid}-btn`
          ) ||
          event.relatedTarget.className.includes("v-virtual-scroll")
        )
          return event.preventDefault(), (this.changed = false);
      }

      // Captura o item pesquisando pelo texto atual do input
      const item = this.findItemByCurrentInput(true);
      const highlighted = this.items_[this.highlighted];

      // Fecha o menu do select
      this.closeMenu();

      await this.$nextTick();

      if (!this.changed) return;

      if (!item && this.mandatory) {
        this.onItemSelectChange(highlighted);
      }

      this.changed = false;

      // Caso o select não puder ter valores nulos, isto é, sempre deverá ter algum valor selecionado após selecionar pela primeira vez.
      if (this.valueNotNull) {
        // Caso não houver item com a descrição atual
        if (!item)
          // Atualiza o input text para o equivalente ao registro selecionado atual, caso houver
          return this.forceInputTextChange(this.buildTexts(this.selectedItem));

        // Caso houver item com a descrição atual, define-o como selecionado
        return this.onItemSelectChange(item);
      }

      if (isEmpty(inputValue) && !this.allowRawText) return this.reset();
      else if (isEmpty(inputValue)) return this.$emit("raw-text");

      // Caso o select permitir descrição sem ter valor representando o mesmo na lista de dados (caso de itens de documentos ficais, como a descrição/comentário/obs do item)
      if (this.allowRawText) {
        // Caso houver item com a descrição
        if (item) return this.onItemSelectChange(item);

        // Caso contrário, emite o evento de raw-text
        return this.$emit("raw-text", inputValue);
      }

      // Caso default do select

      // Caso houver item, seleciona o item encontrado
      if (item) return this.onItemSelectChange(item);

      // Caso contrário
      return this.onItemSelectChange(this.selectedItem);
    },
    search(props) {
      const item = find(
        this.items,
        (item) => (item[props.param] ?? "") === props.value
      );
      if (item) return this.onItemSelectChange(item);
    },
    getItemText(item) {
      if (isEmpty(item)) return;
      return this.itemText && typeof this.itemText === "function"
        ? this.itemText(item)
        : item[this.itemText];
    },
    getItemTextAsText() {
      return this.itemText && typeof this.itemText === "function"
        ? null
        : this.itemText;
    },
    /**
     * Função que retorna o parâmetro de busca baseado nas listas de alvo, (filtar um ou mais itens, procurando por várias colunas)
     */
    findThroughIndicies(item, equals = false) {
      // Captura apenas os dados que possuem chave no filterBy e itemText
      let params = pick(
        item,
        // Une o itemText com os dados do filterBy
        uniq(concat([this.getItemTextAsText()], this.filterBy, this.itemTexts_))
      );

      // Captura o valor do input que será usado como base para filtrar
      const value = (this.inputValue ?? "")
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      if (!value) return;

      params = values(params);
      params = map(params, (param) =>
        isObject(param) ? values(param) : param
      );

      if (equals === true) {
        for (const param of params) {
          if (
            (param ?? "")
              .toString()
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "") == value
          )
            return true;
        }

        return false;
      }

      // Retorna a validação se o valor do input está contido nos valores alvo (uma lista vira um array e tudo minúsculo)
      return includes(
        params
          .join(" ")
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, ""),
        value
      );
    },
    checkInitialState() {
      let selected = this.selectedItem;

      if (this.filtering) return;

      if (selected) this.forceInputTextChange(this.getItemText(selected));
      else {
        this.forceInputTextChange(this.inputInitial);

        selected = this.findItemByCurrentInput(true);

        if (!selected) this.selectedIndex = -1;
      }

      const editing = this.focused && (this.changed || this.inputed);

      if (editing || this.filtering) return;

      // this.selectedIndex = this.selectedItemIndex;
      this.onItemSelectChange(selected, false);
    },
    buildTexts(item = {}) {
      if (this.itemTexts_.length === 0) return this.getItemText(item);

      item = pick(item, this.itemTexts_);

      return join(
        map(item, (value) => (isObject(value) ? values(value) : value)),
        " - "
      );
    },

    /**
     * Função que abre o menu select
     */
    openMenu(event, on) {
      // Se o menu já estiver aberto, ou for um select disabled ou readonly
      if (this.isDisabled)
        // Retorna e para de propagar o evento
        return event && event.stopPropagation && event.stopPropagation();

      const { textField } = this.$refs;

      const { y } = textField.$el.getBoundingClientRect();
      const vh = Math.max(0, window.innerHeight);

      this.top = vh - y < 200 + 35;

      // Aguarda o proximo tick
      this.$nextTick(() => {
        // Caso contrário, ativa o click do v-menu manualmente
        on.click(event);

        // Faz o focus do textField
        this.focusTextField();

        this.findAndFocusItem();
      });
    },
    findAndFocusItem() {
      setTimeout(() => {
        const container = document.querySelector(
          `.v-virtual-scroll-${this._uid}`
        );

        if (!container) return;

        container.scrollTop =
          this.listItemHeight * this.selectedIndex -
          parseInt(this.visibleItems / 2) * this.listItemHeight;

        this.highlighted = this.selectedIndex;
      }, 40);
    },

    /**
     * Função que fecha o menu select
     */
    closeMenu() {
      setTimeout(() => {
        // Atribui false para o menu
        this.menu = false;
        // Atribui false para o filtro
        this.filtering = false;
      }, 40);
    },

    onItemSelectChange(item, change = true) {
      if (!item) return;

      this.inputed = false;

      const text = this.buildTexts(item);

      this.changed = false;

      if (this.value === item[this.itemValue]) {
        this.forceInputTextChange(text);

        this.selectedIndex = this.findItemIndex(item);

        return this.closeMenu();
      }

      let value = item[this.itemValue];

      this.onInput(this.returnObject ? item : value);

      this.forceInputTextChange(text);

      if (change) {
        value = this.returnObject ? item : value;

        this.$emit("change", value, item);
        this.$refs.textField.next();
      }

      this.selectedIndex = this.findItemIndex(item);

      if (change) this.closeMenu();
    },
    /**
     * Função que emite o evento de input
     */
    onInput(value) {
      // Emite o evento
      this.$emit("input", value);
    },
    /**
     * Define o select para o primeiro estado, tudo limpo
     */
    reset(change = true) {
      // Emite o evento de atualização do v-model
      this.$emit("input", null);
      // Emite o evento de alteração
      if (change) this.$emit("change", null);
      // Altera o valor do text-field para vazio
      this.forceInputTextChange("");
      // Seleciona nenhum elemento no menu
      this.selectedIndex = -1;
      this.changed = false;
      this.inputed = false;
    },
    /**
     * Função que altera manualmente o valor do input do select, sem emitir eventos de pesquisa
     */
    forceInputTextChange(text = "") {
      // const editing = this.focused && (this.changed || this.inputed);

      // if (!force && (editing || this.filtering)) return;
      // Se o valor atual for diferente do valor informado
      if (this.inputValue !== text) {
        // Atualiza o texto para o texto novo, usando um objeto, para ignorar evento de pesquisa
        this.onTextFieldInput({ value: text });
      }
    },
    focusTextField() {
      this.$refs.textField && this.$refs.textField.focus();
    },
  },
  watch: {
    async value() {
      const editing = this.focused && (this.changed || this.inputed);

      if (editing || this.filtering) return;

      await this.$nextTick();

      if (this.selectedItem) {
        this.highlighted = this.selectedIndex = this.findItemIndex(
          this.selectedItem
        );
      }

      //
      else {
        this.highlighted = this.selectedIndex = -1;
      }

      this.forceInputTextChange(this.buildTexts(this.selectedItem));
    },
    inputInitial: {
      handler() {
        this.$emit("updated");
      },
    },
    items: {
      handler() {
        if (this.observeItems) this.checkInitialState();
      },
    },
  },
};
</script>

<style scoped>
.v-icon-animated {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  transform: rotate(180deg);
}
</style>

<style>
.v-select-list-item-selected {
  color: var(--v-primary-base) !important;
}
</style>
