import getters from "@/store/getters";
import { isNotNull, isNull } from "@/utils/filters";
import { filter, get, includes, map } from "lodash";
import { milliseconds } from "@/plugins/moment";

/**
 * Colunas do Grid
 */
export const columns = [
  { text: "Emissão", value: "emissao_dt", type: "date" },
  { text: "Documento", value: "documento" },
  { text: "Histórico", value: "historico" },
  { text: "Entrada", value: "vlr_entrada", align: "right" },
  { text: "Saída", value: "vlr_saida", align: "right" },
  { text: "Pré datado", value: "pre_dt", type: "date" },
  {
    text: "Compensado",
    value: "compensado_dt",
    action: "compensado",
    type: "date",
    _default: "<Clique Duplo>",
  },
  { text: "Saldo", value: "vlr_saldo", align: "right" },
  // { text: "Nominal à", value: "nominal" },
  {
    text: "Conta Bancária",
    from: "conta_bancaria_id",
    value: "conta_bancaria",
    hidden: true,
  },
];

/**
 * Título do módulo
 */
export const title = function (this: any) {
  // Captura o cache
  const { cache } = this.$store.state.app;
  // Captura a conta
  const conta_bancaria = get(cache, "controle-bancario.conta_bancaria");

  // Retorna o title
  return `Controle Bancário ${
    conta_bancaria ? "(" + conta_bancaria + ")" : ""
  }`;
};

/**
 * Pré-filtros antes de ler o grid do módulo
 */
export const prefilter = function (this: any) {
  const cache =
    this.$store.getters[getters.APP.USER.CACHE]("controle-bancario");

  return {
    params: [cache.conta_bancaria_id],
  };
};

/**
 * Ações do submenu
 */
export const subactions = function (this: any) {
  // Dados extras
  const extra = get(this.$store, "state.registros.extra.contas_bancarias", []);
  const cache =
    this.$store.getters[getters.APP.USER.CACHE]("controle-bancario");

  const descricao =
    get(this.registro, "documento") ??
    get(this.registro, "historico") ??
    "Conta";

  return [
    {
      title: "Arquivo",
      items: [
        {
          id: "compensado",
          title: `Compensar ${descricao}`,
          divider: true,
        },

        {
          route: { name: "modulo", params: { modulo: "contas-bancarias" } },
          title: "Contas Bancárias",
          divider: true,
        },

        {
          subheader: `Visualizar Conta`,
        },

        ...map(extra, (conta) => ({
          id: "controle_bancario_toggle",
          title: conta.nome,
          conta_bancaria_id: conta.id,
          disabled: cache.conta_bancaria_id === conta.id,
        })),
      ],
    },
    {
      title: "Exibir",
      type: "filter",
      items: [
        {
          title: "Todas",
          filter: null,
        },
        {
          title: "Lançamentos compensados",
          filter: isNotNull("compensado_dt", [""]),
        },
        {
          title: "Lançamentos não compensados",
          filter: isNull("compensado_dt", []),
        },
      ],
    },
  ];
};

/**
 * Ficha default (novo)
 */
export const ficha = () => ({
  ativo: true,
  // pre_dt: milliseconds(),
  emissao_dt: milliseconds(),
  vlr_entrada: "0,00",
  vlr_saida: "0,00",
});

/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = ["vlr_entrada", "vlr_saida", "vlr_saldo"];

/**
 * Filtros default
 */
// const filtros = ({ conta_bancaria_id, conta_bancaria }) => [
//   {
//     ...equals(
//       "conta_bancaria_id",
//       [conta_bancaria_id, conta_bancaria],
//       "conta_bancaria",
//       "int"
//     ),
//     persistent: true
//   }
// ];

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = filter(columns, (column) =>
  // Retorna as colunas que as incluem
  includes(
    [
      "emissao_dt",
      "documento",
      "historico",
      "vlr_entrada",
      "vlr_saida",
      // "pre_dt",
      "compensado_dt",
      "vlr_saldo",
      // "nominal",
    ],
    column.value
  )
);

/**
 * Cache default this = current user cache
 */
export const cache = function (this: any) {
  const conta_bancaria_id = (this && this.conta_bancaria_id) ?? 1;
  const conta_bancaria = (this && this.conta_bancaria) ?? "Banco do Brasil";

  return {
    colunas: [
      "emissao_dt",
      "documento",
      "historico",
      "vlr_entrada",
      "vlr_saida",
      // "pre_dt",
      "compensado_dt",
      "vlr_saldo",
      // "nominal",
    ],
    ordenar: [
      {
        coluna: "compensado_dt",
        ordem: "desc",
      },
      // {
      //   coluna: "pre_dt",
      //   ordem: "desc",
      // },
      {
        coluna: "emissao_dt",
        ordem: "desc",
      },
    ],
    conta_bancaria,
    conta_bancaria_id,
    filtros: [],
  };
};
