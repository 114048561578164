<template>
  <ul class="d-flex ma-0 pa-0">
    <menu-item
      actions
      :disabled="disabled(item)"
      :key="i"
      :item="item"
      v-for="(item, i) in items_"
      @click="$emit('click-action', item)"
    />
  </ul>
</template>

<script>
import concat from "lodash/concat";
import filter from "lodash/filter";
import get from "lodash/get";
import includes from "lodash/includes";

import { actions, fichaExtraActions } from "@/mapping/constants";

import MenuItem from "@/components/menu/MenuItem.vue";

export default {
  props: {
    modulo: {},
    registro: {},
    loading: { type: Boolean, default: false },

    __ficha: {},
    overlay: { type: Boolean, default: false },
  },
  components: { MenuItem },
  computed: {
    fichaExtraActions() {
      const extra = fichaExtraActions;

      try {
        const {
          fichaActions,
        } = require(`@/config/modulos/${this.__ficha.modulo}`);

        // if (
        //   this.__ficha.modulo.includes("contas-receber") ||
        //   this.__ficha.modulo.includes("contas-pagar")
        // ) {
        //   extra = filter(
        //     extra,
        //     (it) =>
        //       !includes(["previous-registro", "next-registro", "search"], it.id)
        //   );
        // }

        if (!fichaActions) return extra;

        return concat(extra, fichaActions.bind(this.__ficha)());
      } catch (e) {
        return extra;
      }
    },
    items() {
      return filter(actions, (action) => {
        const deleteActions = ["delete-registro"];
        const editActions = ["edit-registro", "new-registro"];
        const readActions = ["filter", "detail", "search", "print"];

        if (includes(readActions, action.id)) {
          return this.$canRead(this.modulo);
        }

        if (includes(deleteActions, action.id)) {
          return this.$canDelete(this.modulo);
        }

        if (includes(editActions, action.id)) {
          return this.$canEdit(this.modulo);
        }

        if (action.id === "view-ficha" && !this.$canEdit(this.modulo)) {
          return this.$canOpenFicha(this.modulo);
        }

        return false;
      });
    },
    items_() {
      return this.__ficha
        ? filter(this.fichaExtraActions, (action) => {
            if (!this.overlay) return true;

            return (
              this.overlay &&
              !includes(
                ["search", "previous-registro", "next-registro"],
                action.id
              )
            );
          })
        : this.items;
    },
  },
  methods: {
    /**
     * Função que valida se o item está desabilitado ou não
     */
    disabled(action) {
      const disabled = get(action, "disabled");

      // Prioriza caso esteja definido no item
      if (disabled) return disabled;

      if (this.loading) return true;

      const id = get(this.__ficha, "data.id");

      switch (action.id) {
        case "edit-registro":
        case "delete-registro":
        case "view-ficha":
        case "detail":
          return this.__ficha ? !id : !this.registro || !this.registro.id;
        case "next-registro":
        case "previous-registro":
          return !id;
        default:
          return false;
      }
    },
  },
};
</script>
