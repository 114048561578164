<template>
  <container-content :hidden="hideContainerContent" :title="title">
    <v-row>
      <v-col class="pa-1" :md="compra ? 8 : 12" :lg="compra ? 8 : 12">
        <v-card flat tile :min-height="minHeight" :height="height">
          <data-table
            editable
            hideEmptyRow
            :hide-footer="modulo !== 'compra-itens' && modulo !== 'venda-itens'"
            ref="dataTable"
            :items="items"
            :value="selected"
            :headers="headers_"
            @input="$emit('selected', $event)"
            @resize:header="onResizeHeader"
            v-bind="$attrs"
          >
            <template #footer-content>
              <template v-if="clearCurrency(value.vlr_produtos)">
                <strong>Valor Produtos:</strong> {{ value.vlr_produtos }}
              </template>
              <template v-if="clearCurrency(value.vlr_servicos)">
                <strong>Valor Serviços:</strong> {{ value.vlr_servicos }}
              </template>
              <template v-if="clearCurrency(value.vlr_total)">
                <strong>Valor Total:</strong> {{ value.vlr_total }}
              </template>
            </template>

            <template
              #persistent-select="{
                index,
                item,
                selected,
                header,
                on,
                ...attrs
              }"
            >
              <table-select-item
                :ref="`select-filter-${index}`"
                :selected="selected"
                :allowRawText="allowRawText"
                :enableFocus="index === 0 && enableFocus"
                :produtoKey="produtoKey"
                :modulo="modulo"
                :disabled="disabled"
                :item="item"
                :color="item.row_color"
                :__ficha="__ficha"
                @raw-text="(event) => onRawText(event, { item, header })"
                @change="(event) => onPersistentChange(event, { item, header })"
                v-on="on"
                v-bind="attrs"
              />
            </template>

            <template
              #textfield="{
                on,
                item,
                type,
                value,
                index,
                column,
                header,
                selected,
                ...rest
              }"
            >
              <p-precision-field
                clear
                :persist="false"
                :white="selected"
                :value="value"
                :name="header.value"
                :auto-focus="index === 0 && column === 0 && enableFocus"
                :disabled="isTextFieldDisabled(item, column)"
                :readonly="isTextFieldReadonly(item, column)"
                :relation="item"
                @change="onChange($event, { item, header })"
                @ctrl-delete="onCtrlDel({ item, header })"
                v-if="type === 'precision'"
                v-on="on"
                v-bind="{ ...rest, ...(header.options || {}) }"
              />
              <date-time
                clear
                :white="selected"
                :value="value"
                :ref="`date-time-${index}`"
                :disabled="isTextFieldDisabled(item, column)"
                :readonly="isTextFieldReadonly(item, column)"
                @change="onChange($event, { item, header })"
                @ctrl-delete="onCtrlDel({ item, header })"
                v-else-if="type === 'date'"
                v-on="on"
                v-bind="{ ...rest, ...(header.options || {}) }"
              />
              <c-text-field
                :white="selected"
                :value="value"
                :ref="`${type}-text-field-${index}`"
                :auto-focus="index === 0 && column === 0 && enableFocus"
                :disabled="isTextFieldDisabled(item, column)"
                :readonly="isTextFieldReadonly(item, column)"
                @change="onChange($event, { item, header })"
                @ctrl-delete="onCtrlDel({ item, header })"
                v-else
                v-on="on"
                v-bind="{ ...rest, ...(header.options || {}) }"
              />
            </template>

            <template
              #select="{
                on,
                item,
                value,
                index,
                column,
                header,
                selected,
                ...rest
              }"
            >
              <select-filter
                clear
                :white="selected"
                :value="item[header.value]"
                :auto-focus="index === 0 && column === 0 && enableFocus"
                :disabled="isTextFieldDisabled(item, column)"
                :readonly="isTextFieldReadonly(item, column)"
                :input-initial="value"
                :items="header.source"
                @change="onChange($event, { item, header })"
                @ctrl-delete="onCtrlDel({ item, header }, true)"
                v-on="on"
                v-bind="{ ...rest, ...(header.options || {}) }"
              />
            </template>
          </data-table>
        </v-card>
      </v-col>

      <v-col class="pa-1" md="4" v-if="compra">
        <referencia-produto
          v-model="current"
          @produto="onProdutoChanged"
          :__ficha="__ficha"
        />
      </v-col>

      <slot name="extra" />
    </v-row>
  </container-content>
</template>

<script>
import {
  compact,
  get,
  omit,
  pick,
  isArray,
  findIndex,
  last,
  isObject,
  includes,
  head,
  concat,
  set,
  merge,
  pickBy,
} from "lodash";
import { mapMutations, mapState } from "vuex";

import SelectFilter from "../inputs/SelectFilter.vue";
import CTextField from "../inputs/textfield/CTextField.vue";
import PPrecisionField from "../persistents/PPrecisionField.vue";
import DateTime from "../inputs/DateTime.vue";
import ContainerContent from "../utils/ContainerContent.vue";

import TableSelectItem from "../dfs/TableSelectItem.vue";

import ReferenciaProduto from "./ReferenciaProduto.vue";

import DataTable from "@/components/table/DataTable.vue";

import mutations from "@/store/mutations";
import actions from "@/store/actions";

import { clearCurrency, setCurrency } from "@/utils";
import { milliseconds } from "@/plugins/moment";
import getters from "@/store/getters";

export default {
  components: {
    ContainerContent,
    DataTable,
    SelectFilter,
    CTextField,
    DateTime,
    PPrecisionField,
    ReferenciaProduto,

    TableSelectItem,
  },
  props: {
    value: { type: Object, default: () => ({}) },
    __ficha: {},

    height: { default: "0" },
    minHeight: { default: "210" },

    headers: { type: Array },
    validate: { type: Function },

    title: { default: "Produtos / Serviços" },
    defaultSelectItemLabel: { default: "Código / Descrição / GTIN" },

    selected: { type: Object, default: () => ({}) },
    hideContainerContent: { type: Boolean, default: false },
    persistent: { type: Boolean, default: true },
    allowRawText: { type: Boolean, default: true },
    enableFocus: { type: Boolean, default: false },
    /**
     * Chave que representa o produto na tabela dos itens do módulo
     * ex: Na NF-e (venda_itens.produto_id)
     * ex: Na composição (composicoes.base_produto_id)
     */
    produtoKey: { default: "produto_id" },
    ignoreTextFieldValidations: { type: Boolean, default: false },

    modulo: { required: true },
    disabled: { type: Boolean, default: false },
    doubleItem: { type: Boolean, default: true },
    hideDefaultItem: { type: Boolean, default: false },
    hideImage: { type: Boolean, default: false },

    dependence: { default: "itens" },
    targetModulo: { default: "estoque" },
    defaultHeaders: {
      type: Array,
    },
    appendHeaders: { type: Array, default: () => [] },
    prependHeaders: { type: Array, default: () => [] },
  },
  /**
   * Filtros
   */
  filters: {
    /**
     * Filtro que captura uma ou mais chaves de um objeto
     */
    only(value, key, defaultValue) {
      // Retorna
      return (
        // Se as chaves forem array, captura todas as chaves, senão apenas a chave, e se não houver, retorna o default
        (isArray(key) ? pick(value, key) : get(value, key)) ?? defaultValue
      );
    },
    /**
     * Filtro que monta o caminho da imagem do produto
     */
    image(props, resources, produtoKey) {
      // Captura o nome do arquivo
      const main = get(props.galeria, "principal");
      // Captura o id do produto
      const id = get(props, produtoKey);

      // Se não tiver id ou não tiver foto, retorna a foto default
      if (!id || !main) return require("@/assets/utils/placeholder.png");

      // Caso contrário, tenta acessar no estoque
      return `${resources}/estoque/${id}/${main}`;
    },
  },
  data: () => ({
    booted: false,
    current: {},
  }),
  computed: {
    ...mapState({
      alert_: (state) => state.app.alert,
      // loading: state => state.persistent.loading,
      // items_(state) {
      //   return filter(
      //     state.persistent.data[this.targetModulo],
      //     item => item.ativo !== false
      //   );
      // }
    }),
    textFieldInsteadSelect() {
      return includes(
        ["cadastro-contatos", "cadastro-bandeiras", "cadastro-veiculos"],
        this.modulo
      );
    },
    items() {
      return concat(get(this.value, this.dependence, []), [{ descricao: "" }]);
    },
    /**
     * Breakpoint definido conforme regras das props
     */
    md() {
      return this.hideImage ? 12 : this.compra ? 8 : 10;
    },
    /**
     * Breakpoint definido conforme regras das props
     */
    lg() {
      return this.hideImage ? 12 : this.compra ? 8 : 10;
    },
    /**
     * Breakpoint definido conforme regras das props
     */
    xl() {
      return this.hideImage ? 12 : this.compra ? 7 : 9;
    },
    /**
     * Valida se está no modulo de compras
     */
    compra() {
      return this.modulo === "compra-itens";
    },
    /**
     * Lista dos itens das dependências
     */
    // itemList() {
    //   // return !this.items
    //   //   ? (this.dependencies ?? {})[this.dependence] ?? []
    //   //   : this.items;
    //   return this.items;
    // },
    /**
     * Opções do select filter
     */
    // options_() {
    //   return {
    //     filterBy: ["gtin", "id"],
    //     itemText: "descricao",
    //     itemValue: "id",
    //   };
    // },
    /**
     * Cabeçalho da tabela
     */
    headers_() {
      const item = {
        text: this.defaultSelectItemLabel,
        width: "300px",
        value: "descricao",
        type: "persistent-select",
      };

      const options = {
        allowedPattern: this.modulo === "orcamento-itens" ? /%$/g : undefined,
      };

      // Retorna a junção de tudo
      const columns = compact([
        this.hideDefaultItem ? null : item,
        ...this.prependHeaders,
        ...(this.defaultHeaders ?? [
          {
            text: "Quantidade",
            value: "quantidade",
            type: "precision",
            disabled: this.produtoKey,
          },
          {
            text: "Unitário",
            value: "vlr_unitario",
            type: "precision",
            disabled: this.produtoKey,
            options,
          },
          {
            text: "Total",
            value: "vlr_total",
            type: "precision",
            width: "80px",
            disabled: this.produtoKey,
            options,
          },
        ]),
        ...this.appendHeaders,
      ]);

      return columns.map((header) => ({
        ...header,
        width: get(
          this.$store.getters[getters.APP.USER.CACHE](this.modulo),
          `itemsColumnsWidth.${header.value}`,
          header.width
        ),
      }));
    },
    /**
     * Captura o objeto atual
     */
    current_() {
      const registro = pick(this.selected.registro ?? {}, [
        "id",
        "galeria",
        "descricao",
        "produto.fator_conversao",
        "produto.vlr_preco",
        "produto.unidade_medida",
        "produto.entrada_unidade_medida",
        "produto.vlr_preco_old",
        "produto_temporario",
        this.produtoKey,
      ]);

      return {
        ...registro,
        produto: {
          ...registro.produto,
          vlr_preco_original: get(registro, "produto.vlr_preco"),
        },
      };
    },
  },
  methods: {
    ...mapMutations({
      alert: mutations.APP.ALERT,
    }),
    clearCurrency,
    onResizeHeader({ header, width }) {
      this.$store
        .dispatch(actions.APP.CACHE, {
          modulo: this.modulo,
          itemsColumnsWidth: {
            ...pickBy(
              get(
                this.$store.getters[getters.APP.USER.CACHE](this.modulo),
                "itemsColumnsWidth",
                {}
              ),
              (width) => width && width !== "auto"
            ),
            [header.value]: width,
          },
        })
        .then((cache) => {
          this.$api.post(`${this.modulo}/save-cache`, {
            cache,
          });
        });
    },
    onProdutoChanged(produto) {
      const index = findIndex(
        this.value[this.dependence],
        (i) => i.id === get(this.selected, "registro.id")
      );

      if (index === -1) return;

      const created = {
        ...this.value[this.dependence][index],
        produto: {
          ...produto,
        },
      };

      // Adiciona o registro no lugar do temporario
      this.value[this.dependence].splice(index, 1, created);

      this.$nextTick(() => {
        this.onIndexChanged();
      });
    },
    /**
     * Função chamada quando fechar o alerta
     */
    onAlertClose() {
      // Captura a ref da tabela
      const table = get(this.$refs, "dataTable");

      // Captura o index atual
      const index =
        table && table.findIndexById(get(this.selected, "registro.id"));

      // Se houver index, seleciona o campo
      if (index) this.focusSelectFilter(index, true, false);
    },
    focusTextField(index) {
      return this.$nextTick(() => {
        // Captura o select
        const textfield = this.$refs[`persistent-text-field-${index}`];

        this.$emit("selected", {
          registro: this.items[index] ?? {},
          coluna: 0,
        });

        // Se houver textfield, faz o foco
        textfield && textfield.focus();
      });
    },
    /**
     * Função que faz o foco no select informado
     */
    focusSelectFilter(index, reset = false, resetChange = true) {
      // Aguarda o proximo tick
      return this.$nextTick(() => {
        // Captura o select
        let select = this.$refs[`select-filter-${index}`];

        select = get(select, "$refs.selectItem");

        // Se houver select, faz o foco
        select && select.focus();

        // Se foi passado a flag reset
        if (reset) {
          // Faz o reset
          select && select.reset(resetChange);
        }

        // Retorna true se achou o select
        return select !== null;
      });
    },
    /**
     * Função que valida se pode inserir um item que pode ser duplicado
     */
    canDoubleItem(event) {
      // Captura o tipo do evento
      const { type, data } = event;

      // se nao houver id, retorna
      if (!data || !data.id) return true;

      // Se for update de item inteiro ou for um create
      if (type !== "create") return true;

      // Se não puder ter itens duplicados
      if (this.doubleItem) return true;

      // Captura o index do item
      const index = findIndex(
        // Na lista dos itens
        this.items,
        // Procura pelo id que está tentando inserir
        (item) => item[this.produtoKey] === data.id
      );

      // Se não encontrar registro na lista
      if (index === -1) return true;

      // Seleciona o select e reseta se for create
      this.focusSelectFilter(this.selected.index, type === "create", false);

      // Abre o alerta
      this.alert({
        active: true,
        message:
          "Esse item já foi referenciado<br><br>Para permitir itens duplos na NF-e, basta alterar em Config > Configurações > NFe.",
        info: true,
      });

      // Retorna false
      return false;
    },
    /**
     * Valida se o textField está desabilitado
     */
    isTextFieldDisabled(item, column) {
      // Se a tabela estiver disabled, retorna true
      if (this.disabled) return true;

      // Se é pra ignorar as validações, retorna false
      if (this.ignoreTextFieldValidations) return false;

      if (this.textFieldInsteadSelect) return false;

      if (this.compra && item.produto_temporario) return true;

      // Senão retorna se não tiver produto e o item não for temporário e for da coluna 2 em diante
      return !item[this.produtoKey] && !item.produto_temporario && column > 1;
    },
    /**
     * Função que valida se o textField está somente leitura
     */
    isTextFieldReadonly(item, column) {
      // Se é pra ignorar as validações, retorna false
      if (this.ignoreTextFieldValidations) return false;

      if (column === 0 && this.textFieldInsteadSelect) return false;

      // Retorna se não tiver id ou (se o item não for temporário e não houver produto e for da coluna 2 em diante)
      return (
        !item.id ||
        (!item.produto_temporario && !item[this.produtoKey] && column === 1)
      );
    },
    /**
     * Função que atualiza o objeto selecionado
     */
    onIndexChanged() {
      // Atribui o novo objeto atual
      this.current = this.current_;
    },
    /**
     * Função que faz o boot do component
     */
    boot() {
      if (this.booted) return;

      this.$nextTick(() => {
        // Se habilitar focus
        if (this.enableFocus) {
          this.textFieldInsteadSelect
            ? this.focusTextField(this.items.length)
            : this.focusSelectFilter(this.items.length);
        }

        // Atualiza o objeto selecionado
        this.onIndexChanged();

        this.$refs.dataTable.scrollY("max");
      });

      this.booted = true;
    },
    /**
     * Função executada quando é pressionado ctrl+del em alguma célula da tabela
     */
    async onCtrlDel({ item }) {
      // Se não for persistente ou não tiver id, retorna
      if (!this.persistent || !item.id) return;

      // Chama a função que gerencia os itens da tabela
      return this.handleDependence({
        type: "delete",
        id: item.id,
        modulo: this.modulo,
      });
    },
    /**
     * Função executada quando o usuário digita algo no select mas nao encontra produto
     */
    onRawText(descricao, props) {
      const { item } = props;

      const itemId = get(item, "id", null);

      if (!descricao && itemId)
        return this.handleDependence({
          type: "delete",
          id: itemId,
          modulo: this.modulo,
        });

      if (!descricao) return;

      // tenta capturar do último item
      const tipo = get(item, "tipo", get(last(this.items), "tipo", "P"));

      // Se houver id
      if (itemId)
        // Faz um update
        return this.handleDependence({
          type: "update",
          id: itemId,
          data: {
            tipo,
            descricao,
          },
          modulo: this.modulo,
        });

      // Caso contrário, cria um registro novo
      this.handleDependence({
        modulo: this.modulo,
        type: "create",
        data: {
          descricao,
          tipo,
        },
      });
    },
    /**
     * Função que executa quando é selecionado um novo item
     */
    onPersistentChange(value, props) {
      const { item } = props;

      const itemId = get(item, "id", null);

      if (value === null && itemId)
        return this.handleDependence({
          modulo: this.modulo,
          type: "delete",
          id: itemId,
        });

      if (!isObject(value)) return;

      const tipo = get(item, "tipo", get(last(this.items), "tipo", "P"));

      let prod = pick(value, [
        this.produtoKey,
        "id",
        "produto_id",
        "descricao",
        "quantidade",
        "vlr_unitario",
        "vlr_subtotal",
      ]);

      let data = {
        [this.produtoKey]: prod.id ?? prod[this.produtoKey] ?? prod.produto_id,
        tipo,
      };

      if (this.modulo === "compra-itens") {
        prod = {
          ...prod,
          qtd_original: get(prod, "quantidade"),
        };
      } else {
        data = {
          ...data,
          vlr_total: prod.vlr_subtotal,
        };
      }

      data = {
        ...prod,
        ...data,
      };

      // Caso não tem id no item
      if (!itemId) {
        return this.handleDependence({
          modulo: this.modulo,
          type: "create",
          data,
        });
      }

      return this.handleDependence({
        modulo: this.modulo,
        type: "update",
        id: itemId,
        data,
      });
    },
    /**
     * Função principal de mudança de valores na tabela
     */
    onChange(value, props) {
      // Captura o item e o cabeçalho das props
      const { item, header } = props;

      // Captura o nome da coluna editada
      const { value: name } = header ?? {};

      // Captura o id do item
      const itemId = get(item, "id", null);

      // Se for os contatos do clifor
      if (this.textFieldInsteadSelect) {
        const data = {};

        // Retorna
        return this.handleDependence({
          modulo: this.modulo,
          type: !itemId ? "create" : "update",
          id: itemId,
          data: set(data, name, value),
        });
      }

      // Se nao houver id retorna
      if (!itemId) return;

      // inicia a base do objeto a ser atualizado
      let base = { [name]: value };

      // se houver ponto (acessa um json)
      if ((name || "").includes(".")) {
        // captura a raiz do json
        base = pick(item, head((name || "").split("."))) ?? {};
      }

      // const data = { [name]: value, id: get(item, this.produtoKey, null) };

      const data = {
        ...base,
        id: get(item, this.produtoKey, null),
      };

      if (this.modulo === "venda-itens") {
        const currentItem = pick(item, [
          "quantidade",
          "vlr_total",
          "vlr_unitario",
        ]);

        value = Math.max(clearCurrency(value), 0.01);

        if (name === "vlr_unitario") {
          data[name] = setCurrency(value, this.$vlrDecimalCases);

          data.vlr_total = setCurrency(
            Math.max(clearCurrency(currentItem.quantidade) * value, 0.01),
            this.$vlrDecimalCases
          );
        }

        if (name === "vlr_total") {
          data[name] = setCurrency(value, this.$vlrDecimalCases);

          data.vlr_unitario = setCurrency(
            Math.max(value / clearCurrency(currentItem.quantidade), 0.01),
            this.$vlrDecimalCases
          );
        }

        if (name === "quantidade") {
          data[name] = setCurrency(value, this.$qtdDecimalCases);

          data.vlr_total = setCurrency(
            Math.max(clearCurrency(currentItem.vlr_unitario) * value, 0.01),
            this.$vlrDecimalCases
          );
        }
      }

      // Retorna a requisição de update
      return this.handleDependence({
        modulo: this.modulo,
        type: "update",
        id: itemId,
        data,
      });
    },
    /**
     * Função que persiste as alterações
     */
    handleDependence(event) {
      // Se não for persistent, retorna
      if (!this.persistent) return;

      // Se houver um item e tentar inserí-lo novamente e não for permitido, retorna
      if (!this.canDoubleItem(event)) return;

      const table = get(this.$refs, "dataTable");
      let index = table && table.findIndexById(event.id);

      // let index = -1;
      let spliced = null;

      // Se for delete
      if (event.type === "delete") {
        index = findIndex(this.items, (item) => item.id === event.id);

        // Remove o item da tabela
        spliced = this.value[this.dependence].splice(index, 1);

        index = Math.max(Math.min(index, this.items.length), 0);

        // if (this.textFieldInsteadSelect)
        // else index = Math.max(Math.min(index, this.items.length), 0);

        if (index > 0 && !get(this.items, `${index}.id`, null)) {
          index = this.items.length - 1;
        }

        // this.$nextTick(() => {
        // Emite o input para atualizar o selecionado
        this.$emit("selected", {
          registro: this.items[index] ?? {},
          coluna: 0,
        });

        this.textFieldInsteadSelect
          ? this.focusTextField(index)
          : this.focusSelectFilter(index);

        // });
      }

      let data = event.data;
      const temp_id = milliseconds();

      if (event.type === "create") {
        data = { ...data, id: temp_id, temp_id };

        // if (!this.items.length) {
        //   this.__ficha.append({
        //     [this.dependence]: [data],
        //   });
        // } else {
        //   this.value[this.dependence].push(data);
        // }

        this.value[this.dependence].push(data);
        // index = Math.max(pushed - 1, 0);

        // Se for no cadastro de contatos
        // if (this.textFieldInsteadSelect)
        // Força ficar no campo vazio
        // data = { id: null };

        // Emite o input para atualizar o selecionado
        this.$nextTick(() => {
          if (this.textFieldInsteadSelect) {
            if (this.modulo === "cadastro-contatos") {
              this.focusTextField(0);

              this.$emit("selected", {
                registro: data,
                coluna: 1,
              });
            } else {
              this.$emit("selected", {
                ...this.selected,
                registro: data,
              });
            }
          }
          // Atualiza o selected
          else {
            this.$emit("selected", {
              registro: data,
              coluna: 1,
            });
          }

          // Se for no cadastro de contatos
          // if (this.textFieldInsteadSelect)
          // força selecionar sempre o primeiro campo
          // this.focusTextField(0);
        });
      }

      if (event.type === "update") {
        // omit(data, ["id"]), {
        //   ...get(this.value[this.dependence], index, {}),
        //   ...omit(data, ["id"]),
        // }
        // faz o merge das informações com o que foi alterado
        merge(get(this.value[this.dependence], index, {}), omit(data, ["id"]));

        // console.log(get(this.value[this.dependence], index, {}))
        // return;
        // this.value[this.dependence].splice(index, 1, {
        //   ...get(this.value[this.dependence], index, {}),
        //   ...omit(data, ["id"]),
        // });
      }

      // event.dependence = this.dependence;

      // Chama a função para enviar os dados para o backend
      return this.__ficha
        .handleDependence(event)
        .then((response) => {
          const { data } = response ?? {};

          // se houver obs
          if (data.registros) {
            // Adiciona todas ignorando o primeiro
            this.value[this.dependence].push(...data.registros.slice(1));
          }

          const registro = get(data, "registro", head(data.registros) ?? {});

          if (event.type === "update") {
            this.atualizaRegistro(registro);

            // return this.value[this.dependence].splice(
            //   findIndex(this.items, (item) => item.id === registro.id),
            //   1,
            //   registro
            // );
          }

          // se for create
          if (event.type === "create") {
            // Cata o index do produto com id temporario
            const index = findIndex(
              this.value[this.dependence],
              (i) => i.temp_id === temp_id
            );

            const created = {
              ...this.value[this.dependence][index],
              ...registro,
            };

            // Adiciona o registro no lugar do temporario
            this.value[this.dependence].splice(index, 1, created);

            // se for cadastro de contatos retorna
            if (this.modulo === "cadastro-contatos") return;
            // if (this.textFieldInsteadSelect) return;

            // Emite o input para atualizar o selecionado
            return this.$emit("selected", {
              ...this.selected,
              registro: created,
            });
          }

          return response;
        })
        .catch((error) => {
          window.error(error);

          if (event.type === "delete" && isArray(spliced)) {
            return this.value[this.dependence].push(...spliced);
          }

          if (event.type === "create") {
            this.value[this.dependence].splice(
              findIndex(this.items, (item) => item.temp_id === temp_id),
              1
            );

            const index = this.items.length;

            // Emite o input para atualizar o selecionado
            this.$emit("selected", {
              registro: this.items[index] ?? {},
              coluna: 0,
            });

            return this.$nextTick(() => {
              this.focusSelectFilter(index);
            });
          }

          if (event.type === "update") {
            const registro = get(error, "response.data.registro");
            this.atualizaRegistro(registro);
          }

          // Captura o último index da lista
          const index = this.items.length;

          // Tenta focar o select do index
          if (this.focusSelectFilter(index, true, false))
            // caso conseguir, atualiza o selecionado
            this.$emit("selected", { ...this.selected, coluna: 0 });
        });
    },
    atualizaRegistro(registro) {
      if (!registro || !registro.id) return;

      // faz o merge das informações com o que foi alterado
      merge(
        get(
          this.value[this.dependence],
          findIndex(this.items, (item) => item.id === registro.id),
          {}
        ),
        // TODO: criar vergonha na cara e melhorar isso ai...
        // atualiza apenas o que não foi acabado de alterar
        pick(registro, [
          "cfop",
          "row_color",
          "vlr_unitario",
          "vlr_unitario_original",
          "vlr_total",
          "qtd_original",
          "quantidade",
        ])
      );
    },
  },
  watch: {
    // fetching: "boot",
    selected: "onIndexChanged",
    items(items, old) {
      if (items.length > 1 && isArray(old) && old.length === 1) this.boot();
    },
    "alert_.active": "onAlertClose",
  },
};
</script>
