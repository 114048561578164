<template>
  <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
    <v-row>
      <p-text-field
        sm="6"
        md="3"
        auto-focus
        maxlength="10"
        name="documento"
        label="Documento"
        v-model="data.documento"
      />
      <p-text-field
        md="9"
        lg="9"
        name="historico"
        maxlength="50"
        label="Historico"
        v-model="data.historico"
      />

      <p-date-time
        sm="4"
        md="3"
        name="emissao_dt"
        label="Emissão"
        v-model="data.emissao_dt"
      />

      <p-precision-field
        sm="4"
        md="3"
        name="vlr_entrada"
        label="Entrada"
        v-model="data.vlr_entrada"
      />

      <p-precision-field
        sm="4"
        md="3"
        name="vlr_saida"
        label="Saida"
        v-model="data.vlr_saida"
      />

      <p-date-time
        sm="4"
        md="3"
        name="pre_dt"
        label="Pré-datado"
        v-model="data.pre_dt"
      />

      <p-date-time
        sm="4"
        md="3"
        name="compensado_dt"
        label="Compensado"
        v-model="data.compensado_dt"
      />

      <p-precision-field
        sm="4"
        md="3"
        readonly
        name="vlr_saldo"
        label="Saldo"
        v-model="data.vlr_saldo"
      />

      <p-text-field
        sm="12"
        md="6"
        maxlength="30"
        name="nominal"
        label="Nominal a"
        v-model="data.nominal"
      />
    </v-row>
  </v-col>
</template>

<script>
import { PPrecisionField, PTextField, PDateTime } from "@/components/form";

// Mixins
import Ficha from "@/mixins/ficha";

export default Ficha.extend({
  components: {
    PPrecisionField,
    PTextField,
    PDateTime,
  },
});
</script>
