<template>
  <select-filter
    ref="select"
    :name="`${_uid}-${name}`"
    :items="items"
    :loading="loading || loading_"
    @change="onChanged"
    v-bind="$attrs"
    v-on="listeners"
  >
    <template #append><slot name="append" /></template>
    <template #append-outer><slot name="append-outer" /></template>
    <template #list-item-content="attrs">
      <slot name="list-item-content" v-bind="attrs" />
    </template>
  </select-filter>
</template>

<script>
import omit from "lodash/omit";

import { SelectFilter } from "@/components/form";

import Persistent from "./persistent";

export default Persistent.extend({
  name: "PSelectFilter",
  components: {
    SelectFilter,
  },
  props: {
    items: { default: () => [] },
    loading: { type: Boolean, default: false },
  },
  computed: {
    listeners() {
      return omit(this.$listeners, ["change"]);
    },
  },
  data: () => ({
    loading_: false,
  }),
  methods: {
    search(props) {
      const { select } = this.$refs;
      return select && select.search(props);
    },
    focus() {
      this.$refs.select && this.$refs.select.focusTextField();
    },
    onSearch(value) {
      this.onDatabaseSearch(value);
    },
  },
});
</script>
