<template>
  <modal-icon
    movable
    title="Assistente de procura"
    :src="require('@/assets/menu/actions/procurar.svg')"
    @close="$emit('close')"
    v-model="modal"
  >
    <template #text>
      <v-col>
        <v-row no-gutters>
          <span class="ml-2 mb-1"
            >Digite a informação a ser localizada e clique em Avançar, ou
            pressione a tecla [enter]</span
          >
          <text-field
            auto-focus
            ignore-focus-next-field
            class="mb-2"
            :label="`${column.text} com`"
            @enter="findItem()"
            v-model="query"
          />
        </v-row>
        <v-row no-gutters>
          <div
            class="ml-2"
            v-html="
              current && current.id
                ? `${column.text}: ${current[column.value]}`
                : searched
                ? `Não foi encontrado nenhum registro.`
                : ' '
            "
          />
        </v-row>
      </v-col>
    </template>
    <template #actions>
      <v-spacer />
      <btn
        outlined
        :disabled="fetching"
        btn-class="mr-2"
        @click="findItem(true)"
        >Voltar</btn
      >
      <btn outlined :loading="fetching" @click="findItem()">Avançar</btn>
      <v-spacer />
      <btn @click="$emit('close', $event)">Ok</btn>
    </template>
  </modal-icon>
</template>

<script>
import { get, isEmpty } from "lodash";

import { TextField, Btn } from "@/components/form";

import ModalIcon from "@/components/utils/ModalIcon.vue";
import actions from "@/store/actions";
import { mapState } from "vuex";

export default {
  components: {
    Btn,
    ModalIcon,
    TextField,
  },
  props: {
    /**
     * $ref da instância do componente Modulo.vue
     */
    __modulo: {},

    /**
     * $ref da instância do componente Ficha.vue
     */
    __ficha: {},
  },
  data: () => ({
    modal: true,
    searched: false,
    query: "",
    searchedQuery: "",
    current: {},

    currentIndex: 0,
    lastIndex: 0,
  }),
  created() {
    // Se houver referência do módulo e houver texto selecionado
    if (this.__modulo && this.__modulo.text)
      // adiciona na query
      this.query = this.__modulo.text;
  },
  computed: {
    ...mapState({
      fetching: (state) => state.registros.fetching,
    }),
    /**
     * Retorna a coluna selecionada
     */
    column() {
      // Retorna a coluna selecionada na lista das colunas
      return this.__modulo.visibleColumns(this.__modulo.modulo)[
        this.__modulo.column
      ];
    },
  },
  methods: {
    /**
     * Função que encontra o item buscado
     */
    async findItem(reversed = false) {
      // Se não tiver query, retorna
      if (isEmpty(this.query) || this.fetching) return;

      // Captura a ref do grid principal
      const table = get(this.__modulo, "$refs.table");

      // Se não encontrar table retorna
      if (!table) return;

      // Define o fator com base na lista normal ou reversa
      const factor = reversed ? -1 : 1;

      // Inicia o índice
      let index = 0;

      // Se o índice atual for maior ou igual que o último
      if (this.currentIndex >= this.lastIndex && !reversed)
        // Começa do início
        index = 0;
      // Se o índice atual for menor que 0
      else if (this.currentIndex < 0 || (this.currentIndex <= 0 && reversed))
        // Começa do final
        index = this.lastIndex;
      // Caso contrário, incrementa o fator
      else index = this.currentIndex + factor;

      if (
        index >= 0 &&
        this.lastIndex === index &&
        this.searchedQuery !== this.query
      ) {
        this.searchedQuery = this.query;

        await this.$store.dispatch(actions.MODULO.REGISTROS.SEARCH, {
          modulo: this.__modulo.modulo,
          target: this.column.value,
          value: this.query,
        });
      }

      // Chama a função de pesquisa, e desestrutura o retorno
      [this.current, this.currentIndex, this.lastIndex] = table.search(
        // Passa a query
        this.query,
        // A coluna alvo de busca
        this.column.value,
        // index para seleção
        index
      );

      // Aguarda um tick
      this.$nextTick(() => {
        if (!this.searched) this.searched = true;

        // Se não encontrar registro
        if (isEmpty(this.current)) {
          // Retorna e diminui o fator
          return (this.currentIndex -= factor);
        }

        // Caso contrário, seta a célula ativa
        table.setActiveCell(this.current, null, null, true);

        // Se for um search dentro da ficha
        if (this.__ficha) {
          // Seta a ficha com o registro encontrado
          this.__ficha.setData(this.current);

          // Aguarda um tick
          return this.$nextTick(() => {
            // Se o id encontrado é diferente do ID da ficha
            if (this.current.id !== this.__ficha.id) {
              // Faz replace da Rota
              this.$router.replace({
                params: { id: this.current.id, action: "alterar" },
              });
            }
          });
        }
      });
    },
  },
  watch: {
    /**
     * Quando a query mudar
     */
    query() {
      // Reseta o index selecionado
      this.currentIndex = 0;
    },
  },
};
</script>
