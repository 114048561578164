<template>
  <v-col
    class="pa-0 ma-0 d-flex"
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
  >
    <v-checkbox
      class="checkbox"
      :hide-details="hideDetails"
      :input-value="value"
      :value="value"
      @input="$emit('input', $event)"
      @change="onChange"
      v-bind="$attrs"
    />
  </v-col>
</template>

<script>
import isBoolean from "lodash/isBoolean";

export default {
  props: {
    value: {},
    hideDetails: { type: Boolean, default: true },

    // Cols
    cols: {},
    sm: {},
    md: {},
    lg: {},
    xl: {},
  },
  methods: {
    onChange(value) {
      this.$emit("input", Boolean(value));
      this.$emit("change", Boolean(value));
    },
  },
  created() {
    if (!isBoolean(this.value)) this.onChange(false);
  },
};
</script>

<style scoped>
.checkbox {
  margin-top: 0px !important;
  padding: 0px;
}
</style>

<style lang="scss">
.v-input--selection-controls {
  margin-top: 1px;
}
.v-input--selection-controls:first-child {
  margin-top: 8px;
}
.v-input--selection-controls__ripple {
  border-radius: 50%;
  cursor: pointer;
  height: inherit;
  position: absolute;
  transition: inherit;
  width: inherit;
  left: -8px;
  top: calc(50% - 19px);
  margin: 7px;
}
</style>
