<template>
  <stepper-items v-model="value">
    <template #item-1>
      <v-col
        lg="10"
        offset-lg="1"
        class="d-flex flex-column pa-1"
        :style="`height:` + screenHeight + `px;`"
      >
        <v-flex shrink ref="cabecalho">
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-row no-gutters>
                <p-select-filter
                  value-not-null
                  mandatory
                  cols="12"
                  item-value="id"
                  item-text="descricao"
                  name="natureza_operacao_id"
                  label="Natureza da operação"
                  :items="extra.naturezas_operacao"
                  v-model="data.natureza_operacao_id"
                />
                <clifor-select
                  md="6"
                  label="Vendedor"
                  name="colaborador_id"
                  :valueNotNull="false"
                  :filter="vendedor_filter"
                  :clifor="colaborador"
                  v-model="data.colaborador_id"
                />
                <p-date-time
                  ignore-enter-focus
                  md="6"
                  name="emissao_dt"
                  type="timestamp"
                  label="Emissão"
                  v-model="data.emissao_dt"
                />
              </v-row>
            </v-col>
            <v-col cols="12" md="6" class="px-0 pt-0">
              <container-content hidden min-height="144" cols="12">
                <v-row>
                  <clifor-combobox-v2
                    label="CPF/CNPJ / Razão Social do Cliente"
                    @click:append-outer="onEditCliforClicked"
                    v-model="data.clifor"
                  />
                </v-row>
                <v-row>
                  <list-info :items="cliente" class="px-0" />
                </v-row>
              </container-content>
            </v-col>
          </v-row>
        </v-flex>
        <v-row>
          <v-col class="pb-1 pb-md-2">
            <dependence-table
              title="Serviços"
              defaultSelectItemLabel="Código / Descrição"
              modulo="venda-servico-itens"
              :height="heightTable"
              :__ficha="__ficha"
              :append-headers="appendHeaders"
              :disabled="!data.id"
              :selected="selected"
              @selected="setSelected"
              v-model="data"
            />
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #item-2>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2" class="px-1 py-0">
        <v-row class="mx-0 mt-3">
          <v-expansion-panels flat multiple v-model="expansion">
            <expansion-panel value title="Impostos">
              <impostos :data="data" modulo="servicos" />
            </expansion-panel>
          </v-expansion-panels>
        </v-row>

        <v-row class="mx-0 pt-1">
          <text-area
            readonly
            :colClass="['px-0', data.complemento && 'pr-1']"
            cols="12"
            md="6"
            rows="4"
            label="Informações complementares no XML"
            :value="data.complemento"
            v-if="data.complemento"
          />

          <p-text-area
            cols="12"
            :colClass="['px-0', data.complemento && 'pl-1']"
            :md="data.complemento ? 6 : 12"
            rows="4"
            name="complemento_editavel"
            label="Informações complementares"
            v-model="data.complemento_editavel"
          />
        </v-row>
      </v-col>
    </template>

    <template #item-3>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2" class="px-1 py-0">
        <pagamentos auto-focus :data="data" :__ficha="__ficha" />
      </v-col>
    </template>
  </stepper-items>
</template>

<script>
import get from "lodash/get";
import { StepperItems } from "@/components/utils";

import {
  PTextArea,
  TextArea,
  DependenceTable,
  Impostos,
  ExpansionPanel,
  Pagamentos,
  BasicoNotas,
  PSelectFilter,
  CliforComboboxV2,
  CliforSelect,
  PDateTime,
} from "@/components/form";

import ListInfo from "@/components/utils/info/ListInfo.vue";
import ContainerContent from "@/components/form/utils/ContainerContent.vue";

// Mixins
import Clifor from "@/mixins/clifor";
import { EventBus } from "@/main";

export default Clifor.extend({
  components: {
    StepperItems,
    DependenceTable,
    Impostos,
    PTextArea,
    TextArea,
    ExpansionPanel,
    Pagamentos,
    BasicoNotas,
    PSelectFilter,
    ListInfo,
    ContainerContent,
    CliforComboboxV2,
    CliforSelect,
    PDateTime,
  },
  data: () => ({
    heightTable: 0,
    screenHeight: 0,

    expansion: [0],
  }),
  created() {
    EventBus.$on("resize", this.onResize);
  },
  mounted() {
    this.onResize();
  },
  beforeDestroy() {
    EventBus.$off("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.screenHeight = 0;
      this.heightTable = 0;

      setTimeout(() => {
        this.screenHeight = this.height() - 16 - 2;
        this.heightTable =
          this.screenHeight -
          get(this.$refs, "cabecalho.clientHeight", 0) -
          16 -
          16 -
          24;
      }, 40);
    },
  },
  computed: {
    appendHeaders() {
      return [];
    },
  },
  watch: {
    "__ficha.fullscreen"() {
      this.onResize();
    },
  },
});
</script>
