export const tabs = [
  {
    title: "Contabilidade",
    item: "tab-contabilidade",
  },
  {
    title: "ICMS Recolher",
    item: "tab-icms-recolher",
  },
  {
    title: "ICMS Apuração",
    item: "tab-icms-apuracao",
    hidden(this: any) {
      return !this.$staging;
    },
  },
  {
    title: "Planos de Conta Contábil",
    item: "tab-planos-conta-contabil",
  },
];

/**
 * Dependências do módulo
 */
export const dependencies = [
  {
    tab: "tab-planos-conta-contabil",
    name: "planos_conta",
    modulo: "contabil",
  },
];

/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = ["planos_conta"];
