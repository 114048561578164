<template>
  <td
    ref="cell"
    style="outline: none"
    class="s-datatable-cell"
    :style="cellStyles"
    :class="cellClasses"
    :tabindex="selectable ? '0' : ''"
    :key="_uid"
    @dblclick="$emit('dblclick')"
    @click="$emit('click', $event)"
    @contextmenu="$emit('contextmenu')"
    @mousedown="$emit('mousedown', $event)"
  >
    <!-- Template de célula editável -->
    <template v-if="editable">
      <!-- Se for um select persistente -->
      <template v-if="type === 'persistent-select'">
        <!-- Abre um slot para o select -->
        <slot
          ref="select"
          name="persistent-select"
          v-bind="{
            selected,
            value,
            color,
            on: { focus: onFocus, input: onInput },
          }"
        />
      </template>

      <!-- Se for um select -->
      <template v-else-if="type === 'select'">
        <!-- Abre um slot para o select -->
        <slot
          ref="select"
          name="select"
          v-bind="{
            selected,
            value,
            color,
            on: { focus: onFocus, input: onInput },
          }"
        />
      </template>

      <!-- Caso contrário -->
      <template v-else>
        <!-- Abre um slot para o textfield -->
        <slot
          name="textfield"
          v-bind="{
            selected,
            type,
            value,
            color,
            on: { focus: onFocus, input: onInput },
          }"
        />
      </template>
    </template>

    <template v-else>
      <!-- Template para células que possuem apenas ícones -->
      <template v-if="type == 'icon'">
        <div class="d-flex">
          <v-icon :class="forceColor(item)" class="mx-auto" small>
            {{ (customIcon && customIcon(item, $store.state)) || icon }}
          </v-icon>
        </div>
      </template>

      <!-- Template que trata as células que possuem ícone e valor -->
      <template v-else-if="type == 'before-value-icon' && !report">
        <div class="d-flex pa-0 ma-0">
          <!-- Template para se acaso não houver valor, o ícone continuar à direita -->
          <v-icon
            x-small
            class="icon mr-1"
            :color="iconColor || color"
            :class="{ selected: selected }"
          >
            {{ (customIcon && customIcon(item, $store.state)) || icon }}
          </v-icon>
          <template v-if="!value">
            <div class="overflowed-cell">&nbsp;</div>
          </template>
          <!-- Template para se tiver valor e ícone -->
          <div class="overflowed-cell" v-else>{{ value }}</div>
        </div>
      </template>

      <template v-else-if="type == 'value-icon'">
        <div class="d-flex justify-space-between pa-0 ma-0">
          <!-- Template para se acaso não houver valor, o ícone continuar à direita -->
          <template v-if="!value">
            <div class="overflowed-cell">&nbsp;</div>
          </template>
          <!-- Template para se tiver valor e ícone -->
          <div class="overflowed-cell px-1" v-else>{{ value }}</div>
          <v-icon
            class="icon ml-1"
            :class="{ selected: selected }"
            small
            :color="iconColor || color === 'text--disabled' ? 'grey' : null"
          >
            {{ icon }}
          </v-icon>
        </div>
      </template>

      <template v-else-if="type == 'copy-to-clipboard'">
        <div class="d-flex pa-0 ma-0">
          <!-- Template para se tiver valor e ícone -->
          <v-btn icon x-small @click="copy(value)" v-if="value">
            <v-icon small class="icon ml-1" :class="{ selected: selected }">
              mdi-content-copy
            </v-icon>
          </v-btn>
          <!-- Template para se acaso não houver valor, o ícone continuar à direita -->
          <template v-if="!value">
            <div class="overflowed-cell">&nbsp;</div>
          </template>
          <div class="overflowed-cell px-1" v-else>{{ value }}</div>
        </div>
      </template>

      <!-- Caso for campo de data -->
      <div
        class="overflowed-cell px-1"
        :class="{ empty: !value }"
        v-else-if="type == 'date' && value"
      >
        <div>
          {{ value | date }}
          <span :class="value | day | dayColor">{{ value | day }}</span>
        </div>
      </div>

      <!-- Caso for campo de timestamp -->
      <div
        class="overflowed-cell px-1"
        :class="{ empty: !value }"
        v-else-if="type == 'timestamp' && value"
      >
        <div>
          {{ value | timestamp }}
          <span :class="value | day | dayColor">{{ value | day }}</span>
        </div>
      </div>

      <!-- Caso for campo de day -->
      <div
        class="overflowed-cell px-1"
        :class="{ empty: !value }"
        v-else-if="type == 'day' && value"
      >
        <div>
          <span :class="value | day | dayColor">{{ value | day }}</span>
        </div>
      </div>

      <!-- Template para células boolean -->
      <template v-else-if="type == 'boolean'">
        <div
          class="d-flex"
          v-if="!disabled && (value === false || value === true)"
        >
          <v-icon
            small
            class="bool-icon mx-auto"
            :class="{ selected: selected }"
          >
            {{ value ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline" }}
          </v-icon>
        </div>
      </template>

      <!-- Células XML -->
      <div
        class="overflowed-cell px-1"
        :class="{ empty: !value }"
        v-else-if="type === 'xml'"
      >
        {{ value ? "Visualizar XML" : "" }}
      </div>

      <!-- Label estático -->
      <div
        class="overflowed-cell px-1"
        :class="{ empty: !value }"
        v-else-if="label"
      >
        {{ value ? label : "" }}
      </div>

      <!-- Caso for link -->
      <div
        class="overflowed-cell px-1 link"
        :class="{ empty: !value }"
        v-else-if="type == 'link' && value"
      >
        <div>
          <a :href="`#${value}`">
            {{ parseFloat(value) | date }}
          </a>
        </div>
      </div>

      <!-- Template default, apenas valor -->
      <div
        class="overflowed-cell px-1"
        v-else
        :class="{ empty: !value, [`text-${this.align}`]: this.align }"
      >
        <slot>
          <template v-if="padStart">
            {{ value && value.toString().padStart(padStart, "0") }}
          </template>

          <template v-else-if="json">
            {{ value | json(json) }}
          </template>

          <template v-else>{{ value || _default || "" }}</template>
        </slot>
      </div>
    </template>
  </td>
</template>

<script>
import { get, includes } from "lodash";

import copy from "copy-to-clipboard";
import mutations from "@/store/mutations";
import { mDate, mDay, mTimestamp } from "@/plugins/moment";

export default {
  props: {
    value: {},
    _default: {},
    align: { default: "left" },
    item: {},
    icon: { type: String },
    color: { type: String },
    iconColor: { type: String },
    editable: { type: Boolean },
    background: { type: String },
    highlight: { type: Boolean },
    disabled: { required: false },
    padStart: { required: false },
    selectable: { type: Boolean },
    options: {},
    json: {},
    type: { type: String, default: "" },
    label: { type: String, default: null },
    report: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    customIcon: { type: Function, default: null },
    hidden: { type: Boolean, default: null },
    forceColor: { type: Function, default: () => () => null },
    width: { default: "" },
    maxWidth: { default: "160px" },
  },
  // Filtros
  filters: {
    json(json, key) {
      return get(json, key);
    },
    // Data
    date(value) {
      // Retorna a data em string
      return mDate(value);
    },
    // Timestamp
    timestamp(value) {
      // Retorna o timestamp em string
      return mTimestamp(value);
    },
    // Dia
    day(value) {
      // Retorna formatado
      return mDay(value);
    },
    // Dia
    dayColor(day) {
      // Retorna a cor com base no dia
      return includes(["Sáb", "Dom"], day) ? "error-text" : "text--text";
    },
  },
  computed: {
    // Classes da célula
    cellClasses() {
      return {
        "pa-0": this.editable,
        selected: this.selected,
        report: this.report,
      };
    },
    // Styles da célula
    cellStyles() {
      return {
        "max-width": this.maxWidth,
        "background-color": this.background,
        color: this.color,
        width: this.width,
      };
    },
  },
  methods: {
    copy(value) {
      if (!value) return;

      if (!copy(value)) return;

      this.$store.commit(mutations.APP.SNACKBAR, {
        active: true,
        text: "Chave copiada para área de transferência",
      });
    },
    /**
     * Evento de input dos campos de texto/select
     */
    onInput(event) {
      this.$emit("input", event);
    },
    /**
     * Evento de focus dos campos de texto/select
     */
    onFocus(event) {
      this.$emit("focus", event);
    },
    /**
     * Função chamada externamente para focar a célula
     */
    focus() {
      // Foca a td
      this.$el.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
div.link a {
  &:visited {
    color: red;
  }
}

.theme--light {
  .s-datatable-cell {
    &.selected {
      background-color: #82d5f4;
    }
  }
}

.theme--dark {
  .s-datatable-cell {
    &.selected {
      background-color: #82d6f4b2;
    }
  }
}

.s-datatable-cell {
  // padding: 1px 4px;
  padding: 0px 1px;
  // color: rgba(0, 0, 0, 0.9);
  color: var(--v-text-base);

  // .empty::before {
  // content: "\00a0 ";
  // }

  .icon {
    color: #4dc8f4;

    &.selected {
      color: white !important;
    }
  }

  .bool-icon.selected {
    color: white !important;
  }

  &:not(.selected) .red-text,
  &:not(.selected) .red-text > .v-icon,
  &:not(.selected) .error-text,
  &:not(.selected) .error-text > .v-icon {
    color: var(--v-error-base) !important;
  }

  &:not(.selected) .black-text {
    // color: black;
    color: var(--v-text-base);
  }

  // &.text--disabled {
  // color: rgba(43, 43, 43, 0.5) !important;
  // }

  &.selected > * {
    caret-color: #fff !important;
    text-emphasis-color: #fff !important;
    color: #fff !important;
  }

  &:not(.report) {
    border-right: 1px solid var(--v-tableborder-base) !important;
    border-bottom: 1px solid var(--v-tableborder-base) !important;
    white-space: nowrap;

    div.overflowed-cell {
      font-size: 12px;
      height: inherit;
      text-overflow: text-truncate;
      overflow: hidden;
    }
  }

  @media print {
    &.report > * {
      color: var(--v-text-base) !important;
    }
  }

  &.report {
    border: #7c7c7c 1px solid;

    // div.overflowed-cell,
    // div.overflowed-cell div {
    //   // height: 20px;
    //   // font-size: 9px !important;
    //   // line-height: 1.5;
    // }
  }
}
</style>
