<template>
  <tabs-items v-model="value">
    <template #tab-principal>
      <v-col cols="12" md="6" lg="5" offset-lg="1">
        <v-row>
          <p-select-filter
            auto-focus
            name="banco.codigo"
            label="Instituição"
            item-value="codigo"
            :item-texts="['codigo_full', 'descricao_curta']"
            :items="extra.bancos"
            @change="onBancoChange"
            v-model="data.banco.codigo"
          />

          <p-text-field
            maxlength="30"
            name="nome"
            label="Nome"
            :ignore-typed="!data.id"
            v-model="data.nome"
          />

          <p-text-field
            sm="6"
            name="banco.agencia"
            label="Agência"
            maxlength="10"
            v-model="data.banco.agencia"
          />

          <p-text-field
            sm="6"
            name="banco.conta"
            label="Conta corrente"
            maxlength="16"
            v-model="data.banco.conta"
          />

          <p-select-filter
            sm="6"
            md="8"
            name="plano_conta_id"
            label="Plano de contas"
            :item-texts="['conta', 'descricao']"
            item-value="id"
            :items="extra.plano_contas"
            v-model="data.plano_conta_id"
          />

          <p-precision-field
            sm="6"
            md="4"
            readonly
            name="vlr_saldo"
            label="Saldo do banco"
            v-model="data.vlr_saldo"
          />
        </v-row>
      </v-col>
    </template>

    <template #tab-boleto>
      <v-row class="mx-0">
        <v-col cols="12" class="mt-2">
          <label>
            Configurações padrão em todos os Bloquetos de Cobrança desta Conta
            Bancária
          </label>
          <br />
          <label class="caption mt-2">
            *Este layout é destinado apenas para fim de configurações e não é a
            representação oficial de um Bloqueto de Cobrança
          </label>
        </v-col>

        <v-col cols="12" md="8">
          <v-col class="wrapper pa-0" cols="12">
            <v-row no-gutters>
              <v-col cols="8">
                <p>Local de pagamento</p>
                <p-text-field
                  clear
                  class="no-border content"
                  name="boleto.descLocalPagamento"
                  v-model="data.boleto.descLocalPagamento"
                />
              </v-col>
              <v-col cols="2">
                <p>{{ [756].includes(code) ? "Cooperativa" : "Agência" }}</p>
                <p-text-field
                  clear
                  name="banco.agencia"
                  class="no-border content"
                  v-model="data.banco.agencia"
                />
              </v-col>
              <v-col cols="2">
                <p>Conta</p>
                <p-text-field
                  clear
                  class="no-border content"
                  name="banco.conta"
                  v-model="data.banco.conta"
                />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <template v-if="![756].includes(code)">
                <v-col cols="2">
                  <p>Número Convênio</p>
                  <p-text-field
                    clear
                    class="no-border content"
                    maxlength="7"
                    name="boleto.nroConvenio"
                    v-model="data.boleto.nroConvenio"
                  />
                </v-col>
                <v-col cols="2">
                  <p>Número Carteira</p>
                  <p-text-field
                    clear
                    maxlength="7"
                    class="no-border content"
                    name="boleto.nroCarteira"
                    v-model="data.boleto.nroCarteira"
                  />
                </v-col>
                <v-col cols="2">
                  <p style="overflow: hidden">Número Variação Carteira</p>
                  <p-text-field
                    clear
                    maxlength="7"
                    name="boleto.nroVariacaoCarteira"
                    class="no-border content"
                    v-model="data.boleto.nroVariacaoCarteira"
                  />
                </v-col>
              </template>
              <template v-else-if="[756].includes(code)">
                <v-col cols="6">
                  <p>Número Contrato</p>
                  <p-text-field
                    clear
                    class="no-border content"
                    maxlength="7"
                    name="boleto.nroContrato"
                    v-model="data.boleto.nroContrato"
                  />
                </v-col>
              </template>
              <v-col cols="1">
                <p>Espécie</p>
                <p-text-field
                  clear
                  maxlength="2"
                  class="no-border content"
                  name="boleto.descTipoTitulo"
                  v-model="data.boleto.descTipoTitulo"
                />
              </v-col>
              <v-col cols="1">
                <p>Aceite</p>
                <p-text-field
                  clear
                  maxlength="1"
                  class="no-border content"
                  name="boleto.codAceite"
                  v-model="data.boleto.codAceite"
                />
              </v-col>
              <v-col cols="4">
                <p>Nosso Número</p>
                <p-text-field
                  clear
                  maxlength="10"
                  class="no-border content"
                  name="boleto.nroTituloCliente"
                  v-model="data.boleto.nroTituloCliente"
                />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col class="no-border-bottom label pl-2" cols="12">
                Instruções: (Todas as informações deste bloqueto são de
                exclusiva resposabilidade do Beneficiário)
              </v-col>
            </v-row>

            <v-row no-gutters v-for="r in 5" :key="r">
              <v-col :class="[r * 2 < 9 ? 'no-border-bottom' : '']" cols="12">
                <p-text-field
                  clear
                  type="text"
                  maxlength="55"
                  class="no-border content"
                  style="width: 100%"
                  :disabled="r + (r - 1) > 3"
                  :name="`boleto.msgBloquetoOcorrencia.ln${r + (r - 1)}`"
                  v-model="
                    data.boleto.msgBloquetoOcorrencia[`ln${r + (r - 1)}`]
                  "
                />

                <p-text-field
                  clear
                  type="text"
                  maxlength="55"
                  class="no-border content"
                  style="width: 100%"
                  :disabled="r * 2 > 3"
                  :name="`boleto.msgBloquetoOcorrencia.ln${r * 2}`"
                  v-model="data.boleto.msgBloquetoOcorrencia[`ln${r * 2}`]"
                  v-if="r * 2 < 9"
                />
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="auto">
                <p>Índice da Remessa</p>
                <p-text-field
                  clear
                  maxlength="6"
                  class="content"
                  name="boleto.indexRemessa"
                  v-model="data.boleto.indexRemessa"
                />
              </v-col>
              <v-col cols="auto">
                <p>Integração</p>
                <p-radio-group
                  row
                  name="boleto.integration"
                  v-model="data.boleto.integration"
                  class="integration-radio mt-0 ml-0"
                >
                  <v-row no-gutters>
                    <v-radio
                      v-if="isApiEnabled"
                      class="mr-2 text-small no-border"
                      value="API"
                      label="API"
                    />
                    <v-radio
                      class="mx-2 text-small"
                      value="CNAB240"
                      label="CNAB 240"
                    />
                  </v-row>
                </p-radio-group>
              </v-col>
              <!-- border -->
              <v-col />
            </v-row>
          </v-col>
        </v-col>

        <v-col cols="12" md="4">
          <btn
            outlined
            class="mb-2"
            :disabled="!selectedBatch"
            :loading="batchLoading"
            @click="onGenerateBatch"
          >
            Gerar a Remessa {{ selectedBatch }} novamente
          </btn>
          <data-table
            hide-empty-row
            hide-footer
            selectable
            style="max-height: 300px"
            :headers="headers"
            :items="items"
            :value="selected"
            @input="setSelected"
          />
        </v-col>
      </v-row>
    </template>
  </tabs-items>
</template>

<script>
import get from "lodash/get";
import { TabsItems } from "@/components/utils";
import {
  PPrecisionField,
  PTextField,
  PSelectFilter,
  CTextField,
  PRadioGroup,
  Btn,
} from "@/components/form";

import DataTable from "@/components/table/DataTable.vue";
import ContextMenu from "@/components/popups/ContextMenu.vue";

import Ficha from "@/mixins/ficha";
import actions from "@/store/actions";
import { isActionAllowed } from "@/mapping/constants";

export default Ficha.extend({
  components: {
    TabsItems,
    PPrecisionField,
    PSelectFilter,
    PTextField,
    CTextField,
    PRadioGroup,
    DataTable,
    ContextMenu,
    Btn,
  },
  data: () => ({
    batchLoading: false,
  }),
  computed: {
    isApiEnabled() {
      return (
        isActionAllowed(this.$emitente, { id: "boletos" }) &&
        [1, 756].includes(this.code)
      );
    },
    code() {
      return this.data?.banco?.codigo || 0;
    },
    items() {
      return this.extra.lotes;
    },
    selectedBatch() {
      return get(this.selected, "registro.lote");
    },
    headers() {
      return [
        { text: "Status", value: "status_descricao" },
        { text: "Remessa", value: "lote" },
        { text: "Data geração", value: "updated_at", type: "timestamp" },
      ];
    },
  },
  methods: {
    onGenerateBatch() {
      if (this.batchLoading) return;
      this.batchLoading = true;

      return this.$store
        .dispatch(actions.UTILS.HANDLE_FILE, () =>
          this.$store.dispatch(actions.CORE.POST_REQUEST, {
            path: "/contas-receber/boletos/cnab/generate",
            conta_bancaria_id: this.data.id,
            lote: this.selectedBatch,
          })
        )
        .finally(() => {
          this.batchLoading = false;
        });
    },
    onBancoChange(value) {
      if (!this.data.id && !this.data.nome) {
        const nome = get(
          (this.extra.bancos || []).find((i) => i.codigo === value),
          "descricao_curta"
        );

        setTimeout(() => {
          this.__ficha.append({
            nome,
          });
        }, 40);
      }
    },
  },
});
</script>

<style lang="scss">
.integration-radio {
  label {
    font-size: 12px;
  }
}
</style>

<style scoped lang="scss">
$border-color: #2e2e2e;
$border: thin solid #{$border-color};

.wrapper {
  border: $border;
  // width: 660px;
  color: var(--v-text-base);

  p,
  .label,
  div.clear-text-field {
    height: 20px;
    font-size: 11px;
    line-height: 20px;
    margin: 0px;

    &.content {
      font-size: 12px;
      font-family: "Courier New", Courier, sans-serif;
      font-display: swap;

      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  div.row {
    margin: 0px;
    flex-wrap: nowrap !important;

    div {
      p:empty:before {
        content: "\00a0";
      }

      p:not(.normal):first-child {
        letter-spacing: 0 !important;
        font-size: 10px;
        font-weight: 500;
        padding-left: 4px;
      }

      p:last-child,
      p.normal {
        font-weight: 300;
        padding-left: 4px;
      }
    }

    div:not(:last-child):not(.no-border-right):not(.no-border) {
      border-right: $border;
    }

    div:last-child {
      p:last-child {
        text-align: end;
        padding-right: 4px;
      }
    }
  }

  div.row:not(:last-child) {
    div:not(.no-border-bottom):not(.no-border) {
      border-bottom: $border;
    }
  }
}
</style>
