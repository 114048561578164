<template>
  <precision-field
    ref="precision"
    :name="`${_uid}-${name}`"
    @change="onChanged"
    v-bind="$attrs"
    v-on="listeners"
  />
</template>

<script>
import omit from "lodash/omit";
import { PrecisionField } from "@/components/form";
import Persistent from "./persistent";

export default Persistent.extend({
  name: "PPrecisionField",
  components: {
    PrecisionField,
  },
  computed: {
    listeners() {
      return omit(this.$listeners, ["change"]);
    },
  },
});
</script>
