<template>
  <div>
    <span class="font-weight-bold">Modelo:</span>
    {{ data.modelo || "55" }}
    <span class="font-weight-bold ml-2">NF/Série:</span>
    {{ data.numero_nf || "000000000" }}/{{ data.serie || "000" }}
  </div>
</template>

<script>
export default {
  props: {
    data: { default: () => ({}) },
  },
};
</script>
