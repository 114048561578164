<template>
  <v-col cols="12" md="6" lg="5" offset-lg="1">
    <v-row>
      <p-text-field
        name="descricao"
        label="Convênio"
        maxlength="30"
        v-model="data.descricao"
      />
      <p-text-field
        name="razao"
        label="Razão social"
        maxlength="60"
        v-model="data.razao"
      />
      <field-telefone v-model="data.telefone" />
      <p-text-field
        type="email"
        name="email"
        label="E-mail"
        v-model="data.email"
        maxlength="60"
      />
      <p-precision-field
        suffix="%"
        label="Desconto"
        name="per_desconto"
        v-model="data.per_desconto"
      />
    </v-row>
  </v-col>
</template>

<script>
import { PTextField, FieldTelefone, PPrecisionField } from "@/components/form";

// Mixins
import Ficha from "@/mixins/ficha";

export default Ficha.extend({
  components: {
    PTextField,
    PPrecisionField,
    FieldTelefone,
  },
});
</script>
