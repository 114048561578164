import { get, keys } from "lodash";

const modulos = {
  estoque: [],
  cadastro: [],
  municipios: [],
};
const paginator = {
  estoque: {
    page: 0,
    lastPage: 0,
  },
  cadastro: {
    page: 0,
    lastPage: 0,
  },
};
const fetching = {
  estoque: false,
  cadastro: false,
  municipios: false,
};

export const notifications = () => ({
  data: [],
});

export const ficha = () => ({
  fetching: false,
  extra: {},
  data: {},
});

export const persistent = () => ({
  // Loading
  loading: false,
  // Lista de módulos que estão sendo carregados
  fetching: { ...fetching },
  // Módulos que já foram carregados
  fetched: [],
  // Paginação
  paginator: { ...paginator },
  // Lista de módulos persistentes
  modulos: keys(modulos),
  // Dados, começa com os índices dos módulos, apontando para uma lista vazia
  data: {
    // Cópia dos módulos
    ...modulos,
  },
});

export const registros = () => ({
  fetching: false,
  data: [],
  extra: {},
  total: 0,
  pages: 0,
  perPage: 0,
  currentPage: 1,
  loadedPages: [],
});

export const app = () => ({
  // Usuários conectados
  pendingDownload: {},
  cachedImages: {},
  newVersion: {},
  currentConnectedUsers: [],

  modules: [],

  // Usuário logado
  user: {},
  // estatísticas do emitente
  stats: {},
  // Emitente do Usuário logado
  emitente: {},
  // Configurações do emitente
  config: {},
  info: {},
  // Permissões do usuário logado
  permissions: {},
  // Cache do usuário logado
  cache: {},
  // Informações da api
  backend: {},
  // Estado de carregamento da aplicação
  loading: true,

  useSat: false,

  // Alerta de erros/avisos
  alert: { active: false },
  // Snackbar de erros/avisos/sucessos/info
  snackbar: { active: false },
  // Assistente de Avisos e formulários
  assistent: { active: false },

  appDisplayMode: get(
    JSON.parse(window.localStorage.getItem("app") ?? "{}"),
    "displayMode",
    "browser"
  ),

  blocked: {
    code: null,
    message: "",
    reasons: [],
  },
});

export const modulo = () => ({
  configModulos: [
    "nfe",
    "nfse",
    "cupons",
    "contabil",

    "nfe-series",
    "nfse-series",
    "cupons-series",

    "nfe-inutilizacoes",
    "cupons-inutilizacoes",

    "sistema",
    "emitente",

    "chaves-acesso",
  ],
  // Status bar do módulo
  statusbar: {
    // Texto do status
    text: null,
  },
  // Módulo está bloqueado ou desbloqueado
  locked: true,
  // Módulo atual
  current: null,
});

export default () => ({
  ficha: ficha(),
  persistent: persistent(),
  registros: registros(),
  app: app(),
  modulo: modulo(),

  notifications: notifications(),
});
