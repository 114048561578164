export const form = function (this: any, modulo) {
  const fields = {
    estoque: ["descricao"],
    // cadastro: this.data.cpf_cnpj ? ["cpf_cnpj"] : ["nome_razao_social"],
    grupo: ["descricao"],
    "naturezas-operacao": ["descricao"],
    convenios: ["descricao"],
    "plano-contas": ["descricao"],
    "contas-bancarias": ["nome"],
    "ordem-servico": ["clifor_id"],
    saida: [
      // "clifor_id",
      // "natureza_operacao_id",
      "nfe_finalidade",
      "indicador_consumidor",
      "indicador_presenca",
    ],
    entrada: ["numero_serie"],
    usuarios: ["nome_usuario", "nome"],
    "livro-caixa": ["descricao"],

    "planos-conta-contabil": ["codigo", "descricao"],
  };

  return fields[modulo] ?? [];
};
