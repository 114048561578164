/**
 * @exports Object Objeto com o mapeamento das Mutações
 */
export default {
  // Getters di aoo
  APP: {
    EMITENTE: "APP_EMITENTE",
    USE_SAT: "APP_USE_SAT",
    USER: {
      CACHE: "APP_USER_CACHE",
      PERMISSIONS: "APP_USER_PERMISSIONS",
    },

    CONFIG: "APP_CONFIG",
    IDENTIFICADORES: "APP_IDENTIFICADORES",
  },

  // Getters do módulo
  MODULO: {
    COLUMNS: "MODULO_COLUMNS",
    CURRENT: "MODULO_CURRENT",
    ORDERED_COLUMNS: "MODULO_ORDERED_COLUMNS",
    COLUMN_TITLE: "MODULO_COLUMN_TITLE",
    IS_CONFIG: "MODULO_IS_CONFIG",

    REGISTROS: {
      DATA: "MODULO_REGISTROS_DATA",
      COUNT: "MODULO_REGISTROS_COUNT",
      SELECIONADO: "MODULO_REGISTROS_SELECIONADO",
    },
  },
};
