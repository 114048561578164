<template>
  <v-row no-gutters>
    <text-field
      readonly
      ignore-enter-focus
      label="Descrição"
      class="mt-n2"
      :value="value.descricao"
    >
      <template #append>
        <btn
          icon
          tabindex="-1"
          tooltip="Alterar"
          btn-class="select-filter-btn"
          :disabled="disabled_"
          @click.stop="changing = !changing"
        >
          <v-icon>mdi-dots-horizontal</v-icon>
        </btn>
      </template>
    </text-field>
    <template v-if="!changing">
      <v-row no-gutters>
        <text-field
          disabled
          cols="6"
          label="Preço atual"
          :value="produto.vlr_preco_old || produto.vlr_preco_original"
        />
        <p-precision-field
          ignore-enter-focus
          cols="6"
          label="Novo preço"
          name="vlr_preco"
          :readonly="disabled_"
          :disabled="!value.produto_id"
          :relation="relation"
          @change="(v, old) => onChangeProduto('vlr_preco', old)"
          v-model="produto.vlr_preco"
        />
      </v-row>
      <v-row no-gutters class="my-0">
        <p-select-filter
          ignore-enter-focus
          cols="6"
          item-value="sigla"
          :item-texts="['sigla', 'descricao']"
          label="Und. de entrada"
          name="entrada_unidade_medida"
          :readonly="disabled_"
          :disabled="!value.produto_id"
          :relation="relation"
          :items="extra.unidade_medida"
          @change="onChangeProduto"
          v-model="produto.entrada_unidade_medida"
        />
        <p-select-filter
          ignore-enter-focus
          cols="6"
          item-value="sigla"
          :item-texts="['sigla', 'descricao']"
          label="Und. de saída"
          name="unidade_medida"
          :readonly="disabled_"
          :disabled="!value.produto_id"
          :relation="relation"
          :items="extra.unidade_medida"
          @change="onChangeProduto"
          v-model="produto.unidade_medida"
        />
      </v-row>
      <text-field
        ignore-enter-focus
        sm="4"
        md="12"
        label="Fator de conversão"
        :readonly="disabled_"
        :disabled="!value.produto_id"
        @change="onFatorConversaoChange"
        v-model="produto.fator_conversao"
      />
      <v-col cols="12" class="d-flex align-center px-1 pt-0 mt-n1 text-caption">
        Compra 1 {{ getUnidadeMedida("entrada_unidade_medida") }} e vende
        {{ produto.fator_conversao || 1 }} {{ getUnidadeMedida() }}
      </v-col>
    </template>
    <template v-else>
      <p-select-search
        auto-focus
        return-object
        observe-items
        label="Alterar entrada para"
        item-text="descricao"
        item-value="id"
        source="estoque"
        :target="['id', 'descricao', 'gtin']"
        :persist="false"
        :filterBy="['gtin', 'id']"
        :readonly="disabled || disabled_"
        @change="onChange"
        v-model="produto_id"
      />

      <alert
        info
        cancelable
        prevent-close
        title="Referenciar produto"
        :loading="loading"
        :message="message"
        @ok="onChangeProductReference"
        v-model="alert"
      />
    </template>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { find, get } from "lodash";

import TextField from "../inputs/textfield/TextField.vue";
import PSelectFilter from "../persistents/PSelectFilter.vue";
import PSelectSearch from "../persistents/PSelectSearch.vue";
import PPrecisionField from "../persistents/PPrecisionField.vue";
import Alert from "@/components/utils/info/Alert.vue";
import Btn from "../buttons/Btn.vue";
import actions from "@/store/actions";

export default {
  props: {
    value: {},
    __ficha: {},
    disabled: { type: Boolean, default: false },
  },
  components: {
    TextField,
    PPrecisionField,
    PSelectFilter,
    PSelectSearch,
    Alert,
    Btn,
  },
  data: () => ({
    changing: false,
    loading: false,
    produto_id: 0,
    alert: false,
    produtoAtivo: {},
  }),
  methods: {
    ...mapActions({
      reference: actions.MODULOS.ENTRADA.REFERENCE,
      updateFatorConversao: actions.MODULOS.ENTRADA.FATOR_CONVERSAO,
    }),
    onChangeProduto(name, old) {
      const produto = {
        ...this.produto,
      };

      if (name === "vlr_preco") {
        produto.vlr_preco_old = old;
      }

      this.$emit("produto", produto);
    },
    /**
     * Função chamada quando o fator de conversão muda
     */
    onFatorConversaoChange(fator_conversao) {
      this.onChangeProduto();

      this.updateFatorConversao({
        item_id: this.value.id,
        fator_conversao,
      }).then((response) => {
        // Captura o registro atualizado
        const registro = get(response, "data.registro");

        // Se houver registro
        if (registro) {
          // Commita o registro
          this.__ficha.onUpdateDependence({
            dependence: "itens",
            items: [registro],
          });
        }
      });
    },
    getUnidadeMedida(name = "unidade_medida") {
      // Define a função que busca o item da Unidade de medida, através do id
      const find_ = (_name = name) =>
        find(
          // Unidades
          this.extra.unidade_medida,
          // Condição
          (und) => und.sigla === this.produto[_name]
          // Caso nao encontrar, retorna um objeto vazio
        ) ?? {};

      // Faz a busca
      const unidade = find_(name);

      // Caso não houver, busca pela varável default
      if (!unidade.descricao) return find_("unidade_medida").descricao;

      // Retorna a descrição
      return unidade.descricao;
    },
    onChange(event) {
      if (!event || !event.id) return;

      this.produtoAtivo = event;

      this.$nextTick(() => {
        this.alert = true;
      });
    },
    onChangeProductReference() {
      const event = {
        current_id: this.value.produto_id,
        descricao: this.value.descricao,
        new_id: this.produtoAtivo.id,
        id: get(this.__ficha, "data.id"),
      };

      if (!event.id) return;

      this.loading = true;

      this.reference(event)
        .then((response) => {
          // Captura os dados
          const { data } = response;

          // Se não houver retorna
          if (!data) return;

          // captura os registros
          const { registros } = data;

          this.__ficha.onUpdateDependence({
            dependence: "itens",
            items: registros,
          });
        })
        .finally(() => {
          this.changing = false;
          this.loading = false;
          this.produto_id = 0;
          this.produtoAtivo = {};

          this.$nextTick(() => {
            this.alert = false;
          });
        });
    },
  },
  computed: {
    extra() {
      return get(this.__ficha, "extra", {});
    },
    disabled_() {
      if (!this.value.produto_id && !this.value.produto_temporario) return true;
      else if (this.value.produto_id) return false;

      // return (
      //   (!this.value.produto_id && !this.value.produto_temporario) ||
      // );
      return !this.value.produto_temporario;
    },
    produto() {
      return this.value.produto ?? {};
    },
    message() {
      return `<br>Você deseja alterar de <strong>${
        this.value.descricao
      }</strong> para o item <strong>${get(
        this.produtoAtivo,
        "descricao"
      )}</strong>?`;
    },
    relation() {
      //
      return this.value && this.value.produto_id
        ? {
            id: this.value.produto_id,
            modulo: "estoque",
            options: {
              ignoreAfterUpdate: true,
            },
          }
        : null;
    },
  },
};
</script>
