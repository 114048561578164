<template>
  <div>
    <span class="font-weight-bold ml-2"
      >Recibo Provisório de Serviço - RPS:</span
    >
    {{ data.numero_rps || "000000000" }}/RPS
  </div>
</template>

<script>
export default {
  props: {
    data: { default: () => ({}) },
  },
};
</script>
