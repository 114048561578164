<template>
  <p-combobox-search
    ref="select"
    item-value="id"
    order-by="nome_razao_social"
    append-outer-icon="mdi-pencil"
    :item-subtitle="itemSubtitle || internalItemSubtitle"
    :item-text="itemText"
    :source="source"
    :target="target"
    :name="name"
    :label="label"
    :filter="filter"
    v-on="$listeners"
    v-bind="$attrs"
  />
</template>

<script>
import PComboboxSearch from "@/components/form/persistents/PComboboxSearch.vue";

export default {
  components: {
    PComboboxSearch,
  },

  props: {
    label: { default: "Cliente" },
    name: { default: "clifor_id" },
    itemText: {
      default: "nome_razao_social",
    },
    itemSubtitle: {},

    filter: { default: () => [["ativo", true]] },
    source: { default: "cadastro" },
    target: {
      type: Array,
      default: () => ["id", "nome_razao_social", "cpf_cnpj", "cpf_cnpj_raw"],
    },
  },
  methods: {
    internalItemSubtitle(it) {
      return `${it.cpf_cnpj || ""} ${it.municipio || ""} ${it.uf || ""}&nbsp;`;
    },
  },
};
</script>
