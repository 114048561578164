<template>
  <v-snackbar
    multi-line
    max-width="80vw"
    :top="top"
    :right="right"
    :value="value"
    :color="color"
    :timeout="timeout"
    @input="$emit('input', $event)"
    v-bind="$attrs"
  >
    <div :class="textColor" v-html="text" />
    <!-- {{ text }} -->
    <!-- <div class="text-truncate">
      <div v-html="text"></div>
    </div> -->
    <template #action="{ attrs }">
      <slot name="action">
        <btn
          icon
          color=""
          v-bind="attrs"
          @click="$emit('input', false)"
          v-if="clearable"
        >
          <v-icon>mdi-close</v-icon>
        </btn>
      </slot>
    </template>
  </v-snackbar>
</template>
<script>
import { Btn } from "@/components/form";

export default {
  components: {
    Btn,
  },
  props: {
    value: {},
    text: {},
    top: { type: Boolean, default: true },
    timeout: { default: 10000 },
    right: { type: Boolean, default: true },
    clearable: { default: true },
    textColor: { default: "white--text" },
    color: { default: "success" },
  },
};
</script>

<style>
.v-snack__wrapper {
  min-width: unset !important;
  /* max-width: 80vw !important; */
}
</style>
