/**
 * Tabs da ficha
 */
export const tabs = [
  {
    title: "Principal",
    item: "tab-principal",
  },
  {
    title: "Autenticação",
    item: "tab-autenticacao",
  },
  {
    title: "Séries",
    item: "tab-series",
  },
];

/**
 * Dependências do módulo
 */
export const dependencies = [
  {
    tab: "tab-series",
    name: "series",
    modulo: "nfse-series",
  },
];
