import { endOfDay, startOfDaySubtract } from "@/plugins/moment";
import { filterTypes } from "@/utils/filters";
import { isAuthorized, isCancelled } from "./saida";

import compact from "lodash/compact";
import get from "lodash/get";
import pick from "lodash/pick";
import actions from "@/store/actions";

/**
 * Colunas do Grid
 */
export const columns = ({ document, pronoun, shortName, name }) =>
  compact([
    { text: shortName, value: "numero_nf", maxWidth: "86px" },
    document === "nfce" ? { text: "Série", value: "serie" } : null,
    {
      document,
      text: `Status d${pronoun} ${name}`,
      value: "nfe_status_descricao",
      action: "nfce_transmitir",
      type: "value-icon",
      align: "left",
      maxWidth: "245px",
    },
    { text: "Emissão", value: "emissao_dt", type: "date" },
    { text: "Vendedor", value: "colaborador_razao_social" },
    { text: "Cliente", value: "clifor_razao_social" },
    { text: "Valor troco", value: "vlr_troco", align: "right" },
    { text: "Valor desconto", value: "vlr_desconto", align: "right" },
    { text: "Valor acréscimo", value: "vlr_despesas", align: "right" },
    { text: "Valor total", value: "vlr_total", align: "right" },
    document !== "cupom-mei"
      ? { text: `ID d${pronoun} ${name}`, value: "nfe_id" }
      : null,
    document !== "cupom-mei"
      ? {
          id: "xml_nfe",
          text: `${name} XML`,
          value: "tem_xml_nfe",
          action: "xml",
          type: "xml",
          hiddenReport: true,
        }
      : null,
    { text: "Caixa", value: "numero_caixa" },
    { text: "NF-e", value: "numero_nfe" },
  ]);

export const extraReadOne = function (this: any) {
  return {
    numero_caixa: this.$getStorage("frente.numero_caixa"),
  };
};

/**
 * Ações do submenu
 */
export const subactions = function (this: any) {
  // Captura o registro selecionado
  // const cache = this.$store.getters[getters.APP.USER.CACHE]('cupons-fiscais');
  // const disabled = !this.registro || !this.registro.id;
  const registro = this.registro ?? {};
  const mei = this.$emitente.mei;
  const useSatOption = ["SP", "CE"].includes(get(this.$emitente, "uf"));
  const isNFCe = this.$idCupomFiscal === "nfce";

  return [
    {
      title: "Arquivo",
      items: compact([
        isNFCe
          ? {
              id: "dfe_importar_xml",
              title: `Importar XML ${this.$nomeCupomFiscal}`,
              modulo: "cupons-fiscais",
            }
          : null,
        !mei
          ? {
              id: "dfe_exportar_xml",
              modulo: "cupons-fiscais",
              title: `Exportar ${this.$nomeCupomFiscal} em arquivo XML`,
              divider: !isNFCe,
            }
          : null,
        isNFCe
          ? {
              id: "dfe_emitir_contingencia",
              modulo: "cupons-fiscais",
              title: "Transmitir NFCs em contingência",
              divider: true,
            }
          : null,
        this.$emitente.modulo_ordem_servico
          ? {
              id: "import_document",
              import: "ordem-servico",
              title: "Importar Ordem de Serviço",
            }
          : false,
        {
          id: "import_document",
          import: "orcamento",
          title: "Importar Orçamento",
          divider: true,
        },
        !this.$useSat && !mei
          ? {
              modulo: "cupons-fiscais",
              id: "dfe_generate_new_number",
              title: `Gerar numeração nova para ${registro.numero_nf || ""}/${
                registro.serie || ""
              }`,
              disabled: !registro || !registro.id,
            }
          : null,
        // { id: "", title: "Importar OS" },
        // { id: "", title: "Importar Orçamento" },
        !this.$useSat && !mei
          ? {
              type: "assistent",
              title: "ID e Número do CSC",
              divider: true,
              assistent: {
                modulo: "cupons-fiscais",
                action: "nfce_csc",
                registro: {
                  csc_id: get(this.$emitente, "csc.id"),
                  csc_numero: get(this.$emitente, "csc.numero"),
                },
              },
            }
          : null,
        {
          type: "assistent",
          title: "Número do caixa",
          assistent: () => ({
            modulo: "cupons-fiscais",
            action: "numero_caixa",
            registro: {
              numero_caixa: this.$getStorage("frente.numero_caixa") ?? "001",
            },
          }),
        },
        {
          type: "assistent",
          title: "Valor limite para identificar Consumidor",
          divider: true,
          assistent: {
            modulo: "cupons-fiscais",
            action: "valor_limite_consumidor",
            registro: {
              nfce_limite_ident_clifor: this.$config.nfce_limite_ident_clifor,
            },
          },
        },
        {
          type: "assistent",
          title: "Acesso no Terminal",
          assistent: () => ({
            modulo: "cupons-fiscais",
            action: "acesso_terminal",
            registro: {
              acesso_terminal: this.$getStorage("frente.acesso_terminal") ?? "",
            },
          }),
        },
        {
          id: "consult_status_terminal_saas",
          title: "Status do Terminal",
          divider: useSatOption,
        },
        useSatOption
          ? {
              id: "use_sat",
              type: "boolean",
              title: `Usar ${
                get(this.$emitente, "uf") === "SP" ? "CFe-SAT" : "MFe"
              }`,
              active: this.$useSat,
            }
          : null,
      ]),
    },
    {
      title: "Relatórios",
      type: "report",
      items: reports.bind(this)(),
    },
    // {
    //   title: 'Exibir',
    //   items: [],
    // },
  ];
};

/**
 * Relatórios
 */
export const reports = function (this: any) {
  // Captura o registro selecionado
  // const disabled = !this.registro || !this.registro.id;
  // const descricao = this.registro.descricao;

  // Define o intervalo de data de 1 mes
  const filterDateInterval = {
    // Iniciando 30 dias antes de hoje
    queryStart: startOfDaySubtract(),
    // Terminando hoje
    queryEnd: endOfDay(),
  };

  // Retorna a lista de relatórios
  return [
    {
      id: "vendas",
      title: `Vendas (${this.$nomeCupomFiscal})`,
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
          children: [
            {
              label: "Número do Caixa",
              queryStart: "numero_caixa",
              default: { numero_caixa: "" },
            },
          ],
        },
      ],
    },
    {
      id: "cupom_monofasicos",
      title: `Produtos Monofásicos (${this.$nomeCupomFiscal})`,
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "movimento_dia",
      title: "Movimento do Dia",
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.TIMESTAMP,
          children: [
            {
              label: "Número do Caixa",
              queryStart: "numero_caixa",
              default: { numero_caixa: "" },
            },
          ],
        },
      ],
    },
    {
      id: "meios_pagamento",
      title: "Meios de Pagamento",
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "documentos_no_periodo",
      title: `${this.$nomeCupomFiscal} no período`,
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "resumo_vendas",
      title: "Resumo das Vendas",
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
          children: [
            {
              label: "Forma pagamento",
              queryStart: "meio_pagamento_descricao",
              default: { meio_pagamento_descricao: "" },
            },
            {
              label: "Número do Caixa",
              queryStart: "numero_caixa",
              default: { numero_caixa: "" },
            },
            {
              label: "Cliente",
              queryStart: "clifor_razao_social",
              default: { clifor_razao_social: "" },
            },
          ],
        },
      ],
    },
    {
      id: "total_diario",
      title: "Total Diário",
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
          children: [
            {
              label: "Número do Caixa",
              queryStart: "numero_caixa",
              default: { numero_caixa: "" },
            },
          ],
        },
      ],
    },
    // {
    //   id: 'relatorio_vendas',
    //   modulo: 'estoque',
    //   title: 'Vendas (Nota Fiscal)',
    //   type: 'form-report',
    //   form: [
    //     {
    //       title: 'Vendas (Nota Fiscal)',
    //       default: filterDateInterval,
    //       type: filterTypes.DATE_INTERVAL,
    //     },
    //     {
    //       title: 'Selecione abaixo as operações que devem ser listadas',
    //       search: {
    //         id: 'cfops',
    //         path: '/naturezas-operacao',
    //         raw: true,
    //       },
    //       default: {
    //         cfops: [],
    //       },
    //       type: filterTypes.CFOP,
    //     },
    //     {
    //       title: 'Escolha o modelo do relatório',
    //       type: filterTypes.CHOOSE_ONE,
    //       default: {
    //         choose: 'icms',
    //       },
    //       options: [
    //         {
    //           text: 'Relatório de ICMS',
    //           value: 'icms',
    //         },
    //         {
    //           text: 'Item por item',
    //           value: 'items',
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   id: 'servicos_nf',
    //   modulo: 'estoque',
    //   title: 'Serviços (Nota Fiscal)',
    //   type: 'form-report',
    //   form: [
    //     {
    //       default: filterDateInterval,
    //       type: filterTypes.DATE_INTERVAL,
    //     },
    //   ],
    // },
    // {
    //   id: 'resumo_vendas',
    //   modulo: 'estoque',
    //   title: 'Resumo das vendas',
    //   type: 'form-report',
    //   form: [
    //     {
    //       title: 'Resumo das vendas',
    //       default: filterDateInterval,
    //       type: filterTypes.DATE_INTERVAL,
    //     },
    //     {
    //       title: 'Selecione abaixo as operações que devem ser listadas',
    //       search: {
    //         id: 'cfops',
    //         path: '/naturezas-operacao',
    //         raw: true,
    //       },
    //       default: {
    //         cfops: [],
    //       },
    //       type: filterTypes.CFOP,
    //     },
    //   ],
    //   divider: true,
    // },
    // {
    //   id: 'vendas_por_vendedor',
    //   modulo: 'cadastro',
    //   title: 'Vendas por vendedor',
    //   type: 'form-report',
    //   form: [
    //     {
    //       title: 'Vendas por vendedor',
    //       default: filterDateInterval,
    //       type: filterTypes.DATE_INTERVAL,
    //     },
    //   ],
    // },
  ];
};

/**
 * Ação de contexto da tabela
 *
 */
export const context = function (this: any) {
  const useSat = this.$useSat;

  // Retorna a lista dos itens
  const document = useSat ? "cfe-sat" : "nfce";

  // Verifica se o registro está autorizado
  const authorized = isAuthorized(this.registro);
  const notRegistro = !get(this.registro, "id");
  // Verifica se o registro está autorizado
  const cancelled = isCancelled(this.registro);

  if (this.$emitente.mei && !useSat) {
    return [
      {
        document: "cupom",
        id: "dfe_visualizar_danfce",
        title: "Visualizar Cupom",
        code: "3",
        icon: "mdi-magnify",
        disabled: !authorized && !cancelled,
      },
      {
        document: "cupom",
        id: "dfe_visualizar_danfce",
        type: "direct-print",
        title: "Imprimir Cupom",
        code: "3",
        icon: "mdi-printer",
        disabled: !authorized && !cancelled,
      },
      {
        document: "cupom",
        id: "dfe_cancelar",
        title: "Cancelar Cupom",
        code: "5",
        icon: "mdi-close",
        disabled: !authorized || cancelled,
      },
      {
        document: "cupom",
        id: "dfe_enviar_da_xml_email",
        title: "Enviar o Cupom por E-mail",
        code: "4",
        icon: "mdi-email",
        hidden: (!authorized && !cancelled) || !this.registro.clifor_id,
      },
    ];
  }

  const contingencia = this.registro.autorizada_contingencia;

  return compact([
    {
      document,
      id: "dfe_status_servico",
      icon: "mdi-bug",
      title: useSat ? "Consulta Status SAT" : "Testar Servidor NFC-e",
      code: "0",
    },
    {
      document,
      id: "dfe_visualizar_danfce",
      title: `Visualizar ${useSat ? "Extrato" : "DANFCE"}`,
      code: "3",
      icon: "mdi-magnify",
      disabled: contingencia ? false : !authorized && !cancelled,
    },
    {
      document,
      id: "xml_nfe",
      action: "xml",
      title: "Visualizar XML",
      icon: "mdi-magnify",
    },
    {
      document,
      id: "dfe_visualizar_danfce",
      type: "direct-print",
      title: `Imprimir ${useSat ? "Extrato" : "DANFCE"}`,
      code: "3",
      icon: "mdi-printer",
      disabled: contingencia ? false : !authorized && !cancelled,
    },
    !useSat
      ? {
          document,
          id: "dfe_consultar",
          title: `Consultar ${this.$nomeCupomFiscal}`,
          code: "2",
          icon: "mdi-sync",
          disabled: notRegistro || cancelled || !this.registro.nfe_id,
        }
      : null,
    {
      document,
      id: "dfe_cancelar",
      title: `Cancelar ${this.$nomeCupomFiscal}`,
      code: "5",
      icon: "mdi-close",
      disabled: !authorized || cancelled,
    },
    {
      document,
      id: "dfe_enviar_da_xml_email",
      title: `Enviar o XML e ${useSat ? "Extrato" : "DANFCE"} por E-mail`,
      code: "4",
      icon: "mdi-email",
      disabled: (!authorized && !cancelled) || !this.registro.clifor_id,
    },
    // !useSat
    //   ? {
    //       document,
    //       id: "dfe_verificar_schema",
    //       title: "Verificar esquema (schema)",
    //       icon: "mdi-check",
    //       code: "6",
    //       disabled: !this.registro.xml_nfe,
    //     }
    //   : null,
    {
      id: "dfe_copiar_nfe_id",
      icon: "mdi-content-copy",
      code: "9",
      title: `Copiar Chave ${this.$nomeCupomFiscal}`,
      disabled: !this.registro.nfe_id,
    },
  ]);
};

/**
 * Função que valida se o registro pode ou não ser alterado
 */
export const validateForEditAction = function (registro) {
  // A NF-e não pode estar cancelada, nem autorizada e nem referenciada como NF-e de entrada
  return (
    !isCancelled(registro) &&
    !isAuthorized(registro) &&
    !registro.autorizada_contingencia
  );
};

/**
 * Dependências do módulo
 */
export const dependencies = [
  {
    name: "itens",
  },
  {
    name: "pagamentos",
  },
];

/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = [
  "vlr_total",
  "itens",
  "clifor",
  "intermediador",
  "tem_intermediador",
  "vlr_pagamento_restante",
  "numero_caixa",
  "clifor_id",
  "colaborador_id",
  "vlr_desconto",
  "vlr_despesas",
  "review_imported_payment",
  "pagamentos_agrupados",
];

/**
 * Formata os dados da ficha antes de enviar pro backend (update/create)
 */
export const format = function (this: any) {
  return {
    clifor_id: get(this.data, "clifor_id", get(this.data, "clifor.id")),
  };
};

/**
 * Cache default
 */
export const cache = {
  colunas: [
    "numero_nf",
    "serie",
    "nfe_status_descricao",
    "emissao_dt",
    "clifor_razao_social",
    "vlr_desconto",
    "vlr_despesas",
    "vlr_total",
    "nfe_id",
    "tem_xml_nfe",
    "numero_caixa",
  ],
  filtros: [],
  ordenar: [
    {
      coluna: "numero_nf",
      ordem: "desc",
    },
  ],
};

import { assistent as saidaAssistent } from "./saida";

/**
 * Configuração do assistente
 */
export const assistent = {
  // Identificador do assistente
  nfce_cancelar: {
    ...saidaAssistent.nfe_cancelar,
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message(this: any, item) {
      return this.$emitente.mei
        ? `Atenção<br>
        <br>Você está prestes a cancelar um Cupom.
        <br>Para cancelar o Cupom: <strong>${item.numero_nf}</strong>, insira uma justificativa`
        : `Atenção<br>
        <br>Você está prestes a cancelar uma NFC-e. O DANFCE referente a esta NFC-e se tornará inválido e não poderá ser usado para acompanhar a mercadoria.<br>
        <br>Para cancelar a NFC-e: <strong>${item.numero_nf}/${item.serie}</strong>, insira uma justificativa (min. 15 caracteres).
      `;
    },
    /**
     * Função que é chamada quando clicado no botão ok do assistente
     *
     * retorna uma string representando um erro ou true para válido
     */
    validator(this: any, item) {
      if (!item || !item._nfe_justificativa) return "empty";
      if (this.$emitente.mei) return true;
      if (item._nfe_justificativa.length < 15) return "min_15";
      return true;
    },
  },
  nfce_csc: {
    message() {
      return `
        Atenção<br>
        <br>Informe o ID e o Número do CSC (Código de Segurança do Contribuinte).
      `;
    },
    form() {
      return [
        {
          autoFocus: true,
          name: "csc_id",
          label: "ID do CSC",
        },
        {
          name: "csc_numero",
          label: "Número do CSC",
        },
      ];
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, data) {
      return this.$store.dispatch(actions.MODULO.FICHA.UPDATE_REGISTRO, {
        modulo: "emitente",
        data: {
          csc: {
            id: data.csc_id,
            numero: data.csc_numero,
          },
        },
      });
    },
  },
  rel_caixa: {
    message(this: any) {
      return `
        Atenção<br><br>
        Informe o valor de <strong>${
          this.registro.rel_type === "sangria" ? "retirada" : "reforço"
        }</strong> de caixa e clique em <Ok> para continuar.
      `;
    },
    form() {
      return [
        {
          autoFocus: true,
          type: "precision",
          name: "value",
          prefix: "R$",
          label: "Valor",
        },
      ];
    },
    /**
     * Função que é chamada quando clicado no botão ok do assistente
     *
     * retorna uma string representando um erro ou true para válido
     */
    validator(item) {
      if (!item || !item.value) return "empty";
      return true;
    },
    /**
     * Função que retorna todos os erros
     */
    error() {
      return {
        empty: "Preencha o Valor",
      };
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, data) {
      return this.$store
        .dispatch(actions.DFE.SANGR_SUPR, data)
        .then((response) => {
          const registro = get(response, "data.registro");

          this.$routeResolver({
            // Rota de relatórios
            name: "report",
            params: {
              registro: registro,
              modulo: "livro-caixa",
              id: "sangria_suprimento",
            },
          });
        });
    },
  },
  valor_limite_consumidor: {
    message() {
      return `
        Atenção<br>
        <br>Informe o valor limite para obrigar a informação do consumidor.<br>Obs: Atente-se para o limite de sua UF
      `;
    },
    form() {
      return [
        {
          autoFocus: true,
          type: "precision",
          name: "nfce_limite_ident_clifor",
          label: "Valor limite",
        },
      ];
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, data) {
      this.$store.dispatch(actions.MODULO.FICHA.UPDATE_REGISTRO, {
        modulo: "sistema",
        data: pick(data, "nfce_limite_ident_clifor"),
      });
    },
  },
  mensagem_promocional: {
    message(this: any) {
      return `
        Atenção<br>
        <br>Informe a Mensagem Promocional para exibir na impressão do ${
          this.$useSat ? "Extrato" : "DANFCE"
        }
      `;
    },
    form() {
      return [
        {
          autoFocus: true,
          maxlength: 120,
          name: "cfe_mensagem_promocional",
          label: "Mensagem promocional",
        },
      ];
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, data) {
      this.$store.dispatch(actions.MODULO.FICHA.UPDATE_REGISTRO, {
        modulo: "sistema",
        data: pick(data, "cfe_mensagem_promocional"),
      });
    },
  },
  numero_caixa: {
    message() {
      return `
        Atenção<br>
        <br>Informe o Número deste Caixa. Obs: o número ficará salvo apenas neste navegador.
      `;
    },
    form() {
      return [
        {
          autoFocus: true,
          maxlength: 3,
          name: "numero_caixa",
          label: "Número do Caixa",
        },
      ];
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, data) {
      const numero_caixa = data.numero_caixa ?? "001";

      if (this.__ficha && data.id) {
        this.__ficha.onChangePersistent({
          data: { numero_caixa },
        });
      }

      this.$setStorage("frente.numero_caixa", numero_caixa);
    },
  },
  acesso_terminal: {
    message() {
      return `
        Atenção<br>
        <br>Informe a Chave de Acesso deste Caixa. Obs: A Chave de Acesso informada precisa estar vinculada a algum Terminal para o funcionamento correto.
      `;
    },
    form() {
      return [
        {
          autoFocus: true,
          name: "acesso_terminal",
          label: "Chave de acesso",
        },
      ];
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, data) {
      this.$setStorage("frente.acesso_terminal", data.acesso_terminal ?? null);

      if (data.acesso_terminal !== null) {
        this.$getTerminal().catch((e) => console.warn(e));
      }
    },
  },
};

export const import_orcamento = {
  id: "import_orcamento",
  component: () => import("@/components/popups/actions/Importar.vue"),
  title: "Importar Orçamento (F9)",
  divider: true,
};

/**
 * Configurações do frente de caixa
 */
export const configs = function (this: any) {
  const useSat = this.$useSat;
  const mei = this.$emitente.mei;
  const printName = useSat ? "Extrato" : mei ? "Cupom" : "DANFCe";

  const printOptions = [
    {
      id: "nfce_imprimir_danfce_autorizada",
      title: `Imprimir ${printName} ao Autorizar`,
      type: "boolean",
    },
    {
      key: "frente.imprimir_bobina_58mm",
      id: "imprimir_bobina_58mm",
      type: ["boolean", "storage"],
      title: `Imprimir ${printName} em 58mm`,
    },
    {
      id: "nfce_danfce_detalhado",
      title: `Imprimir ${printName} detalhado`,
      type: "boolean",
    },
    {
      id: "frente_nota_promissoria",
      title: "Nota Promissória",
      type: "boolean",
    },
  ];

  return compact([
    mei
      ? null
      : {
          title: this.$nomeCupomFiscal,
          divider: true,
          children: compact([
            {
              id: "exportar_xml_contabilidade",
              title: "Exportar XML para contabilidade",
              component: () =>
                import("@/components/popups/modulos/dfs/ExportXMLs.vue"),
            },
            {
              id: "dfe_consultar_status",
              title: useSat ? "Consulta Status SAT" : "Testar Servidor NFC-e",
              divider: true,
            },
            this.$idCupomFiscal === "nfce"
              ? {
                  id: "nfce_contingencia",
                  title: "NFC-e em contingência",
                  type: ["boolean", "assistent"],
                  key: "nfce_contingencia_json.ativo",
                }
              : null,
            this.$idCupomFiscal === "nfce"
              ? {
                  id: "dfe_emitir_contingencia",
                  title: "Transmitir NFCs Emitidas em Contingência",
                  divider: true,
                }
              : null,
            this.$idCupomFiscal === "nfce"
              ? {
                  id: "nfce_csc",
                  title:
                    "ID e Número CSC (Código de Segurança do Contribuinte)",
                  type: "assistent",
                  divider: true,
                }
              : null,
            !useSat
              ? {
                  id: "nfce_informar_indIntermed",
                  title: "Informar <indIntermed> no xml",
                  type: "boolean",
                }
              : null,
          ]),
        },
    {
      title: "Impressão",
      children: printOptions,
      divider: true,
    },
    {
      id: "rel_caixa",
      report: "sangria",
      title: "Sangria (Retirada de caixa)",
      type: "assistent",
    },
    {
      id: "rel_caixa",
      report: "suprimento",
      title: "Suprimento (Reforço de caixa)",
      type: "assistent",
      divider: true,
    },
    {
      id: "formas_pagamento",
      component: () => import("@/views/config/FormasPagamento.vue"),
      title: "Formas de pagamento",
      divider: true,
    },
    import_orcamento,
    // { id: "consulta_preco", title: "Consulta de preços", divider: true },
    {
      title: "Configurações",
      children: [
        {
          modulo: "sistema",
          title: "Sistema",
          type: "new-ficha",
        },
        {
          modulo: "cupons",
          title: this.$nomeCupomFiscal,
          type: "new-ficha",
          divider: true,
        },
        {
          id: "mensagem_promocional",
          title: "Mensagem Promocional",
          type: "assistent",
        },
        {
          id: "valor_limite_consumidor",
          title: "Valor Limite para Identificar consumidor",
          type: "assistent",
          divider: true,
        },
        {
          // Required quando type storage
          key: "frente.usar_balanca",
          id: "usar_balanca",
          title: "Usar Balança",
          type: ["boolean", "storage"],
        },
        {
          // Required quando type storage
          key: "frente.numero_caixa",
          id: "numero_caixa",
          title: "Número do Caixa",
          type: ["assistent", "storage"],
        },
        {
          // Required quando type storage
          key: "frente.acesso_terminal",
          id: "acesso_terminal",
          title: "Acesso no Terminal",
          type: ["assistent", "storage"],
        },
      ],
      divider: true,
    },
    {
      id: "complemento",
      title: "Complemento (CTRL+F10)",
    },
    {
      id: "help",
      title: "Ajuda (F1)",
    },
  ]);
};
