<template>
  <div>
    <span class="font-weight-bold hidden-md-and-up">O.S:</span>
    <span class="font-weight-bold hidden-sm-and-down"> Ordem de Serviço: </span>
    {{ data.numero || "" }}
  </div>
</template>

<script>
export default {
  props: {
    data: { default: () => ({}) },
  },
};
</script>
