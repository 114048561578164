<template>
  <v-row class="pa-4 ma-0">
    <v-col class="mx-auto" cols="12" md="8">
      <status-body v-if="code === 418">
        O {{ $appName }} está sendo usado em outra janela. Clique em
        <strong>"Usar aqui"</strong> para usar o {{ $appName }} nesta janela.

        <template #actions>
          <v-spacer />
          <btn text @click="onClose">Fechar</btn>
          <btn :loading="loading" :disabled="loading" @click="reflowSocket"
            >Usar aqui</btn
          >
          <v-spacer />
        </template>
      </status-body>

      <status-body v-else-if="code === 4031">
        O {{ $appName }} está sendo usado em outra janela. Clique em
        <strong>"Usar aqui"</strong> para usar o {{ $appName }} nesta janela.

        <template #actions>
          <v-spacer />
          <btn text @click="onClose">Fechar</btn>
          <btn :loading="loading" :disabled="loading" @click="reflowSocket"
            >Usar aqui</btn
          >
          <v-spacer />
        </template>
      </status-body>

      <template v-else-if="code === 4032">
        <v-col cols="12" md="8" offset-md="2" class="my-auto">
          <v-card flat>
            <v-card-title>
              <v-img
                contain
                width="160"
                height="160"
                :src="require('@/assets/logo_smallsaas.svg')"
              />
            </v-card-title>
            <v-card-text class="text-body-1 text-center">
              O Existe uma sessão aberta com esse usuário.<br />Se você optar
              por usar o sistema aqui, a sessão da outra aba será finalizada.<br /><br />Tem
              certeza que deseja usar o sistema aqui?
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <btn text @click="onClose">Fechar</btn>
              <btn :loading="loading" :disabled="loading" @click="reflowSocket"
                >Usar aqui</btn
              >
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
      </template>

      <template v-else>
        <v-row>
          <v-col cols="12">
            <slot name="title" />
          </v-col>
          <v-col cols="12">
            <v-card flat class="pa-0">
              <v-card-text>
                <slot name="content" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <slot name="footer" />
    </v-col>
  </v-row>
</template>

<script>
import { Btn } from "@/components/form";
import { mapActions } from "vuex";
import actions from "@/store/actions";

import StatusBody from "@/components/utils/info/StatusBody.vue";

export default {
  components: { Btn, StatusBody },
  props: {
    code: {},
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions({
      flowSocket: actions.APP.FLOW_SOCKET,
    }),
    onClose() {
      this.$logout();
    },
    reflowSocket() {
      this.loading = true;

      this.flowSocket({ force_connection: true }).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>
