import { endOfDay, startOfDaySubtract } from "@/plugins/moment";

import filter from "lodash/filter";
import get from "lodash/get";
import includes from "lodash/includes";

import { ilike, filterTypes, greaterThan, equals } from "@/utils/filters";

/**
 * Colunas que serão exibidas no Grid do módulo.
 */
export const columns = [
  // { text: "Código", value: "id" },
  { text: "Nome/Razão Social", value: "nome_razao_social", maxWidth: "370px" },
  { text: "Contato", value: "nome_contato" },
  { text: "CPF/CNPJ", value: "cpf_cnpj", type: "cpf_cnpj" },
  { text: "RG/IE", value: "rg_ie" },
  { text: "WhatsApp", value: "whatsapp" },
  { text: "Endereço", value: "endereco" },
  { text: "Número", value: "numero" },
  { text: "Bairro", value: "bairro" },
  { text: "Município", value: "municipio" },
  { text: "Estado", value: "uf" },
  { text: "CEP", value: "cep" },
  { text: "Telefone", value: "telefone" },
  { text: "Fax", value: "fax" },
  { text: "E-mail", value: "email" },
  { text: "Celular", value: "celular" },
  { text: "Limite Crédito", value: "vlr_credito", align: "right" },
  { text: "Última Compra", value: "ultima_compra_dt", type: "date" },
  { text: "Próximo contato", value: "proximo_contato_dt", type: "date" },
  { text: "Convênio", value: "convenio_descricao" },
  { text: "Identificador 1", value: "identificador1" },
  { text: "Identificador 2", value: "identificador2" },
  { text: "Identificador 3", value: "identificador3" },
  { text: "Identificador 4", value: "identificador4" },
  { text: "Identificador 5", value: "identificador5" },
  { text: "Nascimento", value: "nascimento_dt", type: "date" },
  { text: "Data de Cadastro", value: "created_at", type: "timestamp" },
  { text: "Observação", value: "obs" },
  { text: "Relação Comercial", value: "clifor_tipos_descricao" },
  { text: "% Comissão Venda", value: "per_comissao" },
  // Colunas de relatórios
  {
    text: "Recebido",
    value: "ctr_recebida",
    extra: true,
    hidden: true,
  },
  {
    text: "A receber",
    value: "ctr_receber",
    extra: true,
    hidden: true,
  },
  {
    text: "Atrasado (Receber)",
    value: "ctr_atrasada",
    extra: true,
    hidden: true,
  },
  {
    text: "Paga",
    value: "ctp_paga",
    extra: true,
    hidden: true,
  },
  {
    text: "A pagar",
    value: "ctp_pagar",
    extra: true,
    hidden: true,
  },
  {
    text: "Atrasado (Pagar)",
    value: "ctp_atrasada",
    extra: true,
    hidden: true,
  },
  {
    text: "Exibir",
    value: "exibir",
    hidden: true,
  },
];

/**
 * Lista de identificadores para a ficha
 */
export const identificadores = new Array(5)
  .fill(null)
  .map((_, i) => `lbl_tb_clifors_identificador_${i + 1}`);

/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = [
  "galeria",
  "endereco",
  "complemento",
  "bairro",
  "uf",
  "municipio_id",
];

/**
 * Dependências do módulo
 */
export const dependencies = [
  {
    tab: "tab-contatos",
    name: "contatos",
    modulo: "cadastro-contatos",
    target: ["clifor_id"],
  },
  { name: "vendedor" },
  {
    tab: "tab-veiculos",
    name: "veiculos",
    modulo: "cadastro-veiculos",
    target: ["clifor_id"],
  },
  {
    tab: "tab-bandeiras",
    name: "bandeiras",
    modulo: "cadastro-bandeiras",
    target: ["clifor_id"],
  },
];

/**
 * Ações de contexto da tabela
 */
export const context = function (this: any) {
  const isRegistroActive = this.registro && this.registro.ativo;

  return [
    {
      id: "ativo",
      title: "Ativo",
      modulo: "cadastro",
      icon: !isRegistroActive ? "mdi-checkbox-blank-outline" : "mdi-check",
    },
    {
      id: "etiquetas",
      title: "Etiquetas",
      descricao: this.registro?.nome_razao_social,
      icon: "mdi-tag-outline",
    },
  ];
};

/**
 * Ações do submenu
 */
export const subactions = function (this: any) {
  return [
    // {
    // title: "Arquivo",
    // disabled: true,
    // items: [
    // {
    //   id: "email_clifor",
    //   title: `Enviar um e-mail para: ${descricao}`,
    //   disabled: disabled || !hasEmail,
    // },
    // {
    //   id: "email_clifor_filtrados",
    //   title: "Enviar um e-mail para todos os cadastros filtrados",
    //   divider: true,
    // },
    // {
    //   id: "whatsapp_clifor",
    //   title: `Enviar uma mensagem no WhatsApp® para: ${descricao}`,
    //   disabled: disabled || !hasEmail,
    // },
    // {
    //   id: "whatsapp_clifor_filtrados",
    //   title:
    //     "Enviar uma mensagem WhatsApp® para todos os cadastros filtrados",
    //     divider: true,
    // },
    // {
    //   id: "",
    //   title: "Escrever carta para mala direta",
    // },
    // {
    //   id: "",
    //   title: "Imprimir etiqueta para mala direta",
    // },
    // {
    //   id: "",
    //   title: "Imprimir carta para mala direta",
    // },
    // ]
    // },
    {
      type: "report",
      title: "Relatórios",
      items: reports.bind(this)(),
    },
    {
      title: "Exibir",
      type: "filter",
      items: [
        {
          title: "Com contas a receber atrasadas",
          filter: greaterThan("ctr_atrasada", [0], "and", "numeric"),
        },
        {
          title: "Com contas a receber em dia",
          filter: greaterThan("ctr_recebida", [0], "and", "numeric"),
        },
        {
          title: "Com contas a receber",
          filter: greaterThan("ctr_receber", [0], "and", "numeric"),
          divider: true,
        },
        {
          title: "Com contas a pagar atrasadas",
          filter: greaterThan("ctp_atrasada", [0], "and", "numeric"),
        },
        {
          title: "Com contas a pagar em dia",
          filter: greaterThan("ctp_paga", [0], "and", "numeric"),
        },
        {
          title: "Com contas a pagar",
          filter: greaterThan("ctp_pagar", [0], "and", "numeric"),
          divider: true,
        },
        {
          title: "Todos",
          filter: null,
        },
        {
          title: "Só Cliente",
          filter: ilike("clifor_tipos_descricao", ["Cliente"]),
        },
        {
          title: "Só Fornecedor",
          filter: ilike("clifor_tipos_descricao", ["Fornecedor"]),
        },
        {
          title: "Só Funcionário",
          filter: ilike("clifor_tipos_descricao", ["Funcionário"]),
        },
        {
          title: "Só Revenda",
          filter: ilike("clifor_tipos_descricao", ["Revenda"]),
        },
        {
          title: "Só Representante",
          filter: ilike("clifor_tipos_descricao", ["Representante"]),
        },
        {
          title: "Só Distribuidor",
          filter: ilike("clifor_tipos_descricao", ["Distribuidor"]),
        },
        {
          title: "Só Vendedor",
          filter: ilike("clifor_tipos_descricao", ["Vendedor"]),
        },
        {
          title: "Só Credenciadora de Cartão",
          filter: ilike("clifor_tipos_descricao", ["Credenciadora de Cartão"]),
        },
        {
          title: "Só Técnico",
          filter: ilike("clifor_tipos_descricao", ["Técnico"]),
        },
        {
          title: "Só Transportadora",
          filter: ilike("clifor_tipos_descricao", ["Transportadora"]),
        },
        {
          title: "Só Intermediador",
          filter: ilike("clifor_tipos_descricao", ["Intermediador"]),
          divider: true,
        },
        {
          title: "Ocultos",
          filter: equals("exibir", [false]),
        },
      ],
    },
  ];
};

/**
 * Relatórios
 */
export const reports = function (this: any) {
  // Captura o registro selecionado
  const disabled = !this.registro || !this.registro.id;
  const descricao = this.registro.nome_razao_social ?? "";
  // const hasEmail = split(this.registro.email, ";").length > 0;

  const filterDateInterval = {
    // Iniciando 30 dias antes de hoje
    queryStart: startOfDaySubtract(),
    // Terminando hoje
    queryEnd: endOfDay(),
  };

  return [
    {
      id: "historico_de",
      title: `Histórico de: ${descricao}`,
      registro_id: this.registro.id,
      type: "form-report",
      form: [
        {
          title: `Histórico de: ${descricao}`,
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
      disabled,
    },
    {
      id: "vendas_para",
      title: `Vendas para: ${descricao}`,
      registro_id: this.registro.id,
      type: "form-report",
      form: [
        {
          title: `Vendas para: ${descricao}`,
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
      disabled,
    },
    {
      id: "resumo_vendas_para",
      title: `Resumo das Vendas para: ${descricao}`,
      registro_id: this.registro.id,
      type: "form-report",
      form: [
        {
          title: `Resumo das Vendas para: ${descricao}`,
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
        {
          title: "Selecione abaixo as operações que devem ser listadas",
          search: {
            id: "cfops",
            target: "cfop",
            value: "(5|6|7)%",
            path: "/naturezas-operacao/search",
          },
          default: {
            cfops: [],
          },
          type: filterTypes.CFOP,
        },
      ],
      disabled,
    },
    {
      id: "resumo_compras_de",
      title: `Resumo das Compras de: ${descricao}`,
      registro_id: this.registro.id,
      type: "form-report",
      form: [
        {
          title: `Resumo das Compras de: ${descricao}`,
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
      disabled,
      divider: true,
    },
    {
      id: "curva_abc",
      title: "Curva ABC dos clientes",
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "ranking_clientes",
      title: "Ranking de clientes",
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "ranking_devedores",
      title: "Ranking de devedores",
      modulo: "contas-receber",
    },
    // {
    //   id: "",
    //   title: "Álbum de fotografias",
    //   divider: true,
    // },
    // {
    //   id: "",
    //   title: "Clientes contatados por mês por vendedor",
    // },
    // {
    //   id: "",
    //   title: "Clientes contatados por dia",
    // },
    // {
    //   id: "",
    //   title: "Clientes contatados por dia por vendedor",
    // },
    // {
    //   id: "",
    //   title: "Contatos de hora em hora",
    //   divider: true,
    // },
    {
      id: "comissoes",
      title: `Relatório de Comissão de: ${descricao} `,
      registro_id: this.registro.id,
      type: "form-report",
      hidden: !includes(get(this.registro, "clifor_tipos"), "Vendedor"),
      disabled,
      form: [
        {
          title: `Selecione o período`,
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
        {
          title: "Escolha o modelo do relatório",
          type: filterTypes.CHOOSE_ONE,
          default: {
            choose: "items",
          },
          options: [
            {
              text: "Discriminado item por item",
              value: "items",
            },
            {
              text: "Discriminado item por item e do que foi recebido",
              value: "items_recebido",
            },
            {
              text: "Somente do que foi recebido",
              value: "recebido",
            },
          ],
        },
      ],
    },
    {
      id: "vendas_por_vendedor",
      title: "Vendas por vendedor",
      type: "form-report",
      form: [
        {
          title: "Vendas por vendedor",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
        {
          title: "Selecione abaixo as operações que devem ser listadas",
          search: {
            id: "cfops",
            target: "cfop",
            value: "(5|6|7)%",
            path: "/naturezas-operacao/search",
          },
          default: {
            cfops: [],
          },
          type: filterTypes.CFOP,
        },
      ],
    },
  ];
};

/**
 * Tabs da ficha
 */
export const tabs = [
  { item: "tab-principal", title: "Principal" },
  { item: "tab-identificadores", title: "Identificadores" },
  { item: "tab-contatos", title: "Contatos", disabled: (data) => !data.id },
  { item: "tab-foto", title: "Foto", disabled: (data) => !data.id },
  {
    item: "tab-bandeiras",
    title: "Bandeiras",
    disabled: (data) => !data.id,
    hidden: (data) =>
      !includes(data.clifor_tipos ?? [], "Credenciadora de Cartão"),
  },
  {
    item: "tab-veiculos",
    title: "Veículos",
    disabled: (data) => !data.id,
    hidden: (data) => !includes(data.clifor_tipos ?? [], "Transportadora"),
  },
  {
    item: "tab-comissao",
    title: "Comissão",
    disabled: (data) => !data.id,
    hidden: (data) => !includes(data.clifor_tipos ?? [], "Vendedor"),
  },
  { item: "tab-tags", title: "Tags", disabled: (data) => !data.id },
];

/**
 * Ficha default
 */
export const ficha = () => ({
  ativo: true,
  vendedor: {},
});

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = filter(columns, (column) =>
  // Retorna as colunas que as incluem
  includes(
    [
      "cpf_cnpj",
      "nome_razao_social",
      "contato",
      "cep",
      "endereco",
      "bairro",
      "municipio",
    ],
    column.value
  )
);

/**
 * Cache default
 */
export const cache = {
  colunas: [
    "nome_razao_social",
    "nome_contato",
    "rg_ie",
    "cpf_cnpj",
    "whatsapp",
    "celular",
    "telefone",
    "created_at",
    "clifor_tipos_descricao",
  ],
  filtros: [],
  ordenar: [
    {
      coluna: "nome_razao_social",
      ordem: "asc",
    },
  ],
};

/**
 * Valores da tabela de Tags
 */
export const tagsTabValues = [
  {
    id: 1,
    nome: "SUFRAMA",
    exemplo: "",
    descricao: "Inscrição da entidade na SUFRAMA",
  },
  {
    id: 2,
    nome: "cPais",
    exemplo: "1058 (Brasil)",
    descricao:
      "Código do país. Utilizar a Tabela do BACEN (Anexo IX - Tabela de UF, Município e País).",
  },
  {
    id: 3,
    nome: "xPais",
    exemplo: "Brasil",
    descricao: "Nome do País",
  },
  {
    id: 4,
    nome: "idEstrangeiro",
    exemplo: "",
    descricao: "Identificação do destinatário no caso de comprador estrangeiro",
  },
  {
    id: 5,
    nome: "idCadIntTran",
    exemplo: "",
    descricao: "Identificador cadastrado no intermediador",
  },
];

/**
 * Colunas da tabela de Tags
 */
export const tagsTabHeaders = [
  {
    text: "Nome",
    value: "nome",
    noEditable: true,
    maxWidth: "120px",
  },
  {
    text: "Valor",
    value: "valor",
    maxWidth: "120px",
  },
  {
    text: "Exemplo",
    value: "exemplo",
    noEditable: true,
    maxWidth: "120px",
  },
  {
    text: "Descrição",
    value: "descricao",
    noEditable: true,
    width: "650px",
  },
];
