import Echo from "laravel-echo";
import Pusher from "pusher-js";

import get from "lodash/get";
import isNull from "lodash/isNull";
import pick from "lodash/pick";
import omit from "lodash/omit";
import head from "lodash/head";
import last from "lodash/last";

import API from "@/api";

import actions from "@/store/actions";
import getters from "@/store/getters";
import mutations from "@/store/mutations";

import defaultState, { app as initialState } from "@/store/defaultStates";

import { EventBus } from "@/main";

import { modulos } from "@/mapping/constants";
import { isInvalid } from "@/utils";

import { today } from "@/utils/filters";
import DFESTATUS, { AUTHORIZED } from "@/mapping/status";

const api = API.config();

const connectionLimit = (code) => ({
  code,
  message: "Limite de conexões excedido",
  reasons: [
    {
      text: "Você chegou no limite máximo de abas simultâneas.",
    },
    {
      text: "Verifique quantas guias do navegador estão com sendo utilizadas pelo sistema com seu usuário.",
    },
    {
      text: "Verifique quantos navegadores estão sendo utilizados com o sistema pelo seu usuário.",
    },
  ],
});

export default {
  state: Object.assign({}, initialState()),
  getters: {
    [getters.APP.EMITENTE](state) {
      return state.emitente;
    },
    [getters.APP.USE_SAT](state) {
      return state.useSat;
    },
    /**
     * Getter das configurações
     */
    [getters.APP.CONFIG](state) {
      // Retorna uma função que espera um label para acessar como índice
      return function (label) {
        // Retorna o valor do label ou nulo
        return state.config[label] ?? null;
      };
    },

    /**
     * Getter dos identificadores
     */
    [getters.APP.IDENTIFICADORES](_, _getters) {
      // Retorna a função que procura pelos identificadores do módulo passado por parametro
      return function (modulo) {
        // Tenta
        try {
          // Capturar os identificadores do módulo
          const { identificadores } = require(`@/config/modulos/${modulo}`);

          // Cria a referência do config
          const config = _getters[getters.APP.CONFIG];

          // Retorna os identificadores mapeados
          return (identificadores ?? []).map((item) => ({
            // Nome para salvar
            name: item,
            // Valor para ser exibido
            value: config(item),
          }));
        } catch (_) {
          // Retorna uma lista vazia
          return [];
        }
      };
    },

    /**
     * Getter das permissoes
     */
    [getters.APP.USER.PERMISSIONS]: (state) => state.permissions ?? {},

    /**
     * Captura o cache do usuário, e se for inválido, constrói um baseado no padrão
     */
    [getters.APP.USER.CACHE]:
      (state, _getters) =>
      (modulo, optimized = false) => {
        // Captura o módulo do parametro, caso contrário, pega o atual
        modulo = modulo || _getters[getters.MODULO.CURRENT];
        // Captura o cache do módulo
        const cache = state.cache[modulo] ?? {};

        if (!optimized) return cache;

        return {
          ...cache,
          selecionado: {
            coluna: get(cache, "selecionado.coluna"),
            registro: pick(get(cache, "selecionado.registro"), "id"),
          },
        };
        // ? {
        //     ...state.cache[modulo],
        //     selecionado: pick(get(state.cache[modulo], "selecionado"), [
        //       "id",
        //       "coluna"
        //     ])
        //   }
        // : {};
      },
  },

  mutations: {
    [mutations.APP.CACHED_IMAGE](state, { filename, data }) {
      state.cachedImages[filename] = data;
    },
    [mutations.APP.TOGGLE_USE_SAT](state) {
      state.useSat = !state.useSat;

      const key = `frente.${get(state.emitente, "id")}`;

      const frente = JSON.parse(localStorage.getItem(key) ?? "{}") ?? {};
      localStorage.setItem(
        key,
        JSON.stringify({
          ...frente,
          cfe_sat: state.useSat,
        })
      );
    },

    [mutations.APP.PENDING_DOWNLOAD](state, download = {}) {
      // atribui o download pendente
      state.pendingDownload = download;
    },

    [mutations.APP.NEW_VERSION](state, newVersion = {}) {
      // atribui a versao nova
      state.newVersion = newVersion;
    },

    /**
     * Mutação de estatísticas do emitente
     */
    [mutations.APP.MODULES](state, modules) {
      const defaults = [...modulos];

      state.modules = modules.map((it) => {
        const module = {
          ...it,
          ...(defaults.find((i) => i.id === it.id) || {}),
        };

        module.route ??= {
          name: "modulo",
          params: {
            modulo: module.id,
          },
        };

        return module;
      });
    },

    /**
     * Mutação de estatísticas do emitente
     */
    [mutations.APP.BLOCKED](state, blocked = {}) {
      state.blocked = blocked;
    },
    /**
     * Mutação de estatísticas do emitente
     */
    [mutations.APP.DISPLAY_MODE](state, mode) {
      state.appDisplayMode = mode;

      const app = JSON.parse(localStorage.getItem("app") ?? "{}") ?? {};

      localStorage.setItem(
        "app",
        JSON.stringify({
          ...app,
          displayMode: mode,
        })
      );
    },

    /**
     * Mutação de estatísticas do emitente
     */
    [mutations.APP.STATS](state, stats) {
      state.stats = stats;
    },

    /**
     * Mutação de loading da aplicação
     */
    [mutations.APP.LOADING](state, loading) {
      state.loading = loading;
    },

    /**
     * Mutação dos dados do usuário logado
     */
    [mutations.APP.USER](state, user) {
      state.user = { ...(state.user ?? {}), ...user };
      // state.logged = true;
    },

    /**
     * Mutação dos dados do usuário logado
     */
    [mutations.APP.EMITENTE](state, emitente) {
      state.emitente = { ...(state.emitente ?? {}), ...emitente };

      const key = `frente.${get(emitente, "id")}`;
      const frente = JSON.parse(window.localStorage.getItem(key) ?? "{}");

      state.useSat = get(frente, "cfe_sat", false);

      if (state.emitente && !["SP", "CE"].includes(state.emitente.uf)) {
        state.useSat = false;

        localStorage.setItem(
          key,
          JSON.stringify({
            ...frente,
            cfe_sat: state.useSat,
          })
        );
      }
    },

    /**
     * Mutação para exibir os alertas na tela
     */
    [mutations.APP.ALERT](state, alert) {
      state.alert = {
        active: false,
        title: null,
        description: null,
        ...alert,
      };
    },

    /**
     * Mutação para exibir os shackbars na tela
     */
    [mutations.APP.SNACKBAR](state, snackbar) {
      state.snackbar = { active: false, text: null, ...snackbar };
    },

    /**
     * Mutação para exibir o assistente na tela
     */
    [mutations.APP.ASSISTENT](state, assistent) {
      state.assistent = { active: true, ...assistent };
    },

    /**
     * Mutação das configurações do emitente
     */
    [mutations.APP.CONFIG](state, config) {
      // Seta as configurações do sistema
      state.config = { ...(state.config ?? {}), ...config };
    },

    /**
     * Mutação das informações do emitente
     */
    [mutations.APP.INFO](state, info) {
      // Seta as info do sistema
      state.info = info;
    },

    /**
     * Mutação do cache do usuário logado
     */
    [mutations.APP.CACHE](state, cache) {
      state.cache = cache;
    },

    /**
     * Mutação de permissões do usuário
     */
    [mutations.APP.PERMISSIONS](state, permissions) {
      state.permissions = {
        // Permissões do parâmetro
        ...permissions,
        // Permissões da auditoria, default 2
        auditoria: 2,
        usuarios: 1,
      };
    },
  },

  actions: {
    /**
     * Faz a leitura das estatísticas do emitente
     */
    [actions.APP.STATS]({ commit }) {
      // Retorna a promessa da requisição
      return api.get("/stats").then((response) => {
        // Commita as estatísticas
        commit(mutations.APP.STATS, response.data);
      });
    },

    /**
     * Faz a mutação do cache do usuário
     */
    [actions.APP.CACHE]({ state, getters: _getters }, cache) {
      return new Promise((resolve, reject) => {
        try {
          // Retorna a promessa de resolução
          // Captura o modulo atual
          const modulo = cache.modulo ?? _getters[getters.MODULO.CURRENT];

          // Se nao houver módulo, retorna
          if (!modulo) return reject(Error("Informe o módulo"));

          // Atribui o novo cache do modulo
          state.cache[modulo] = {
            // Cópia do cache antigo, para manter os dados
            ...state.cache[modulo],
            // Novos dados do cache
            ...omit(cache, "modulo"),
          };

          // Resolve a promessa
          resolve({ ...state.cache[modulo] });
        } catch (error) {
          window.error(error);
          return reject(error);
        }
      });
    },

    /**
     * Função que faz a busca do objeto do usuário através do token gerado no login
     */
    async [actions.APP.REFRESH_USER]({ commit, state, dispatch }) {
      // Commita loading para mostrar a splash de carregamento
      commit(mutations.APP.LOADING, true);

      try {
        // Retorna a promessa da requisição
        const { data } = await api.get("/auth/me");

        // Se houver dados
        if (!data) return;

        // Captura o usuário e as configurações do emitente
        const { user, config, info, emitente, version } = data;

        // Faz o commit das permissões
        commit(mutations.APP.PERMISSIONS, user.permissoes);

        // Faz o commit do usuário
        commit(mutations.APP.USER, user);

        // Faz o commit das configurações do emitente
        commit(mutations.APP.CONFIG, config);

        // Faz o commit das configurações do emitente
        commit(mutations.APP.INFO, info);

        // Faz o commit do objeto do emitente
        commit(mutations.APP.EMITENTE, emitente);

        // Commita as informações do backend
        state.backend = {
          ...state.backend,
          version,
        };

        // Se não estiver na rota de relatórios
        if (!location.pathname.includes("relatorios")) {
          await dispatch(actions.APP.MODULES);
          await dispatch(actions.APP.FLOW_SOCKET);
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async [actions.APP.MODULES](store) {
      const { commit } = store;
      const { data } = await api.store(store).get("/modules");
      commit(mutations.APP.MODULES, data);
    },

    /**
     * Função que segue o flow de conexões com o socket
     */
    async [actions.APP.FLOW_SOCKET]({ dispatch, commit }, params = {}) {
      try {
        // Dispara a ação para conectar e recuperar o canal do emitente
        const channel = await dispatch(actions.APP.JOIN_EMITENTE, params);
        // Registra os eventos utilizando o canal do emitente
        dispatch(actions.APP.LISTEN_EVENTS, channel);

        // limpa o state de bloqueado
        commit(mutations.APP.BLOCKED);
      } catch (error) {
        window.error(error);
        // Bloqueia
        commit(mutations.APP.BLOCKED, error);
      }
    },

    /**
     * Função que se inscreve no canal do Emitente
     */
    [actions.APP.JOIN_EMITENTE]({ state, commit }, extraParams) {
      // Retorna uma promessa
      return new Promise((resolve, reject) => {
        // Inicia um cliente pusher
        const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY || "", {
          // host do websocket
          wsHost: process.env.VUE_APP_PUSHER_HOST || "localhost",
          // porta do websocket
          wsPort: parseInt(process.env.VUE_APP_PUSHER_PORT || "6001"),
          // porta segura do websocket
          wssPort: parseInt(process.env.VUE_APP_PUSHER_PORT || "6001"),

          /* Configs */
          forceTLS: process.env.VUE_APP_MODE !== "development",
          disableStats: true,
          enabledTransports: ["ws", "wss"],
          /**
           * Autorizador
           */
          authorizer: (channel) => ({
            // Função que autoriza os canais private e presence
            authorize: async (socketId, callback) => {
              // Inicia os dados da requisição
              const data = {
                socket_id: socketId,
                channel_name: channel.name,
                ...extraParams,
              };

              // Post para auth dos canais de socket
              api
                .post("/broadcasting/auth", data)
                .then((response) => {
                  callback(null, response.data);
                  window.socketId = socketId;
                })
                .catch((error) => {
                  // Chama o callback
                  callback(error, null!);
                  // Rejeita a promessa
                  reject(connectionLimit(get(error.response, "status", 403)));
                });
            },
          }),
        });

        // se já houver instância ativa de socket
        if (window.defaultEchoSocket instanceof Echo) {
          // Desconecta
          window.defaultEchoSocket.disconnect();
        }

        // Cria uma instância do Echo, e coloca na superglobal window
        window.defaultEchoSocket = new Echo({
          // Seta o broadcaster
          broadcaster: "pusher",
          // Cliente
          client: pusher,
        });

        // Conecta no canal do User
        window.defaultEchoSocket
          .channel(`App.User.${state.user.id}`)
          .listen(".Disconnect", () => {
            // Bloqueia
            commit(mutations.APP.BLOCKED, connectionLimit(418));

            // Para de ouvir o evento
            // userChannel.stopListening(".Disconnect");

            // Desconecta do socket
            if (window.defaultEchoSocket instanceof Echo) {
              window.defaultEchoSocket.disconnect();
            }
          });

        // Conecta no canal privado do emitente
        const channel = window.defaultEchoSocket
          .join(`App.Emitente.${state.emitente.id}`)
          // Quando entrar no canal
          .here((data) => {
            // Atribui os users conectados
            state.currentConnectedUsers = data;

            // Resolve a promessa
            return resolve(channel);
          })
          // Quando alguem estiver entrando
          .joining((data) => {
            // Aidiciona aos usuários conectados
            state.currentConnectedUsers.push(data);
          })
          // Quando alguem estiver saindo
          .leaving((data) => {
            // Aidiciona aos usuários conectados
            state.currentConnectedUsers.splice(
              // Captura o index do usuario que saiu
              state.currentConnectedUsers.findIndex(
                (u) => u.socketId === data.socketId
              ),
              1
            );
          });
      });
    },

    /**
     * Função que registra os eventos do emitente, usuário e sistema
     */
    [actions.APP.LISTEN_EVENTS]({ state, commit, rootState }, channel) {
      // Se houver canal
      if (!channel) return;

      // Ouve eventos de update dos módulos
      channel
        .listen(".Registro.Update", ({ registro, related, modulo }) => {
          // Captura o módulo atual
          const { current } = rootState.modulo;

          // Separa o modulo das sua relação
          const path = (modulo || "").split(".");

          const isDFe = (r) =>
            ["cupons-fiscais", "saida", "servicos"].includes(current) &&
            DFESTATUS.concat(AUTHORIZED).includes(r.nfe_status);

          // Se o módulo que teve o registro atualizado for o mesmo do atual
          if (head(path) === current) {
            // Se houver mais que um valor, quer dizer que uma relação foi atualizada
            if (path.length === 2) {
              // Captura o nome da relação e a chave estrangeira
              const dependence = last(path);

              // se for Documento Fiscal e foi autorizado
              if (isDFe(related)) {
                EventBus.$emit("registro.authorized", related);
              }

              // Atualiza a ficha
              // Se o registro criado for da mesma relação que está aberta na ficha
              EventBus.$emit("registro.updated", related);

              // Faz o commit do registro alterado no grid
              commit(mutations.MODULO.REGISTROS.UPDATED, related);

              // Commita a dependencia
              EventBus.$emit("dependence.updated", {
                dependence,
                items: [registro],
                parent_id: related.id,
              });
            }

            //
            else {
              // Faz o commit do registro alterado no grid
              commit(mutations.MODULO.REGISTROS.UPDATED, registro);

              // se for Documento Fiscal e foi autorizado
              if (isDFe(registro)) {
                EventBus.$emit("registro.authorized", registro);
              }

              // Se o registro criado for da mesma relação que está aberta na ficha
              EventBus.$emit("registro.updated", registro);
            }
          }

          // Commita o registro persistente
          commit(mutations.MODULO.PERSISTENT.UPDATED, { registro, modulo });
        })
        // Ouve eventos de create
        .listen(".Registro.Create", ({ registro, related, modulo }) => {
          // Captura o módulo atual
          const { current } = rootState.modulo;

          // Separa o modulo das sua relação
          const path = (modulo || "").split(".");

          // Se o módulo que teve o registro atualizado for o mesmo do atual
          if (head(path) === current) {
            // Se houver mais que um valor, quer dizer que uma relação foi atualizada
            if (path.length === 2) {
              // Captura o nome da relação e a chave estrangeira
              const dependence = last(path);

              // Se o registro criado for da mesma relação que está aberta na ficha
              EventBus.$emit("registro.updated", related);

              // Faz o commit do registro alterado no grid
              commit(mutations.MODULO.REGISTROS.UPDATED, related);

              // Commita a ficha
              EventBus.$emit("dependence.created", {
                dependence,
                items: [registro],
                parent_id: related.id,
              });
            }

            // Adiciona o registro do Grid
            else commit(mutations.MODULO.REGISTROS.CREATED, registro);
          }

          // Commita o registro persistente
          commit(mutations.MODULO.PERSISTENT.DATA, {
            // Registro
            data: [registro],
            // Módulo
            modulo,
          });
        })
        // Ouve eventos de delete
        .listen(".Registro.Delete", ({ registro, related, user, modulo }) => {
          // Captura o módulo atual
          const { current } = rootState.modulo;

          // Separa o modulo das sua relação
          const path = (modulo || "").split(".");

          // Se o módulo que teve o registro atualizado for o mesmo do atual
          if (head(path) === current) {
            // Se houver mais que um valor, quer dizer que uma relação foi atualizada
            if (path.length === 2) {
              // Captura o nome da relação e a chave estrangeira
              const dependence = last(path);

              // Se o registro criado for da mesma relação que está aberta na ficha
              EventBus.$emit("registro.updated", related);

              // Faz o commit do registro alterado no grid
              commit(mutations.MODULO.REGISTROS.UPDATED, related);

              // Commita a ficha
              EventBus.$emit("dependence.deleted", {
                dependence,
                id: registro.id,
                parent_id: related.id,
              });
            }

            // Remove o registro do grid
            else {
              // Se o registro criado for da mesma relação que está aberta na ficha
              EventBus.$emit("registro.deleted", {
                id: registro.id,
                user,
                modulo,
              });

              // Apaga o registro do grid
              commit(mutations.MODULO.REGISTROS.DELETED, registro.id);
            }
          }

          // Commita o registro persistente
          commit(mutations.MODULO.PERSISTENT.DELETED, {
            id: registro.id,
            modulo,
          });
        })
        // Ouve por eventos de Notificações
        .listen(".Notification", (data) => {
          // Se houver config
          if (data.notifications) {
            // Commita a nova configuração
            commit(mutations.NOTIFICATION.DATA, data.notifications);
          }
        })
        // Ouve por eventos de Config
        .listen(".Config", (data) => {
          // Se houver config
          if (data.config) {
            // Commita a nova configuração
            commit(mutations.APP.CONFIG, data.config);
          }
        })
        // Ouve por eventos de Emitente
        .listen(".Emitente", (data) => {
          // Se houver emitente
          if (data.emitente) {
            // Commita o novo emitente atualizado
            commit(mutations.APP.EMITENTE, data.emitente);
          }
        });

      // Conecta no canal privado do usuário
      (window.defaultEchoSocket as Echo)
        .private(`App.User.${state.user.id}`)
        // Ouve por eventos de Permissions
        .listen(".Permissions", (data) => {
          // Se houver permissões
          if (data.permissions)
            // Faz o commit das novas permissões
            commit(mutations.APP.PERMISSIONS, data.permissions);
        })
        // Ouve por eventos de Status
        .listen(".Status", (data) => {
          // Se houver mensagem
          if (data.message)
            // Commita as alterações de status
            commit(mutations.MODULO.STATUSBAR, data.message);
        })
        // Ouve por eventos de Status
        .listen(".Download.Ready", (data) => {
          // captura o tipo de download
          const type = get(data, "descricao");

          // se nao houver download, é pq deu erro
          if (!get(data, "id")) {
            // emite o evento de download erro
            EventBus.$emit("download.error", data ?? {});

            // se houver listener de download, retorna
            if (EventBus.$refs.download) {
              return;
            }

            // Mostra o aviso de download disponível
            return commit(mutations.APP.PENDING_DOWNLOAD, data);
          }

          // se houver tipo
          if (type) {
            // emite o evento de download finalizado
            EventBus.$emit(`download.ready.${type}`, data ?? {});

            // se houver listener de download, retorna
            if (EventBus.$refs.download) {
              return;
            }

            // Mostra o aviso de download disponível
            commit(mutations.APP.PENDING_DOWNLOAD, data);
          }
        })
        // Ouve por eventos de Callback do terminal
        .listen(".TerminalCallback", (response) => {
          // Captura os retornos
          const { data, error, action } = response;

          // Desabilita o loading
          commit(mutations.DFE.SENDING, false);

          // se estiver nas ações
          if (["status", "emitir"].includes(action)) {
            // Emite evento para o frente (cupomfiscal vue)
            EventBus.$emit("terminal-saas-callback", response);
          }

          // se houver timeout criado, limpa
          if (window.cfe_sat_timeout) clearTimeout(window.cfe_sat_timeout);

          // limpa a status bar
          commit(mutations.MODULO.STATUSBAR, null);

          const message = get(response, "data.message");
          const registro = get(response, "data.registro");

          // Se houver registro
          if (registro) {
            // Commita o registro no Grid
            commit(mutations.MODULO.REGISTROS.UPDATED, registro);
          }

          const optional = (value, format = "[:value]") =>
            value ? format.replace(":value", value) : "";

          if (message)
            commit(mutations.APP.SNACKBAR, {
              active: true,
              text: message,
            });

          // se foi consulta status
          if (action === "status") {
            // se houver erro
            if (error) {
              return commit(mutations.APP.SNACKBAR, {
                active: true,
                color: "error",
                text: `${optional(error.codigo)} ${error.mensagem}`,
              });
            }

            // Mostra o status
            return commit(mutations.APP.SNACKBAR, {
              active: true,
              text: `${optional(data.codigo)} ${data.mensagem}`,
            });
          }

          // se houver erro
          if (error) {
            const code = get(error, "codigo", "");
            const cStat = get(error, "status", "");
            const xMotivo = get(error, "mensagem", "");

            // Monta a mensagem
            const message = [
              `${optional(code)} ${optional(cStat)} ${xMotivo}`,
              get(error, "detalhes", ""),
            ];

            // Mostra o alerta de erro
            return commit(mutations.APP.ALERT, {
              active: true,
              message: message.join("<br><br>"),
            });
          }
        })
        // Ouve por eventos de Disconnect do usuário
        // Forçar atualização, mudança de senha do usuário etc.
        .listen(".Logout", () => {
          // Emite o evento de desautenticado
          EventBus.$emit("unauthenticated");
        });

      // Conecta no canal do sistema
      (window.defaultEchoSocket as Echo)
        .channel("App.System")
        // Ouve o evento deploying
        .listen(".Deploying", () => {
          // Faz reload da página
          window.location.reload();
        })
        .listen(".NewVersion", (data) => {
          // mostra o aviso
          commit(mutations.APP.NEW_VERSION, data);
        })
        // Ouve o evento Logout
        .listen(".Disconnect", () => {
          // Emite o evento de desautenticado
          EventBus.$emit("unauthenticated");
        })
        // Ouve o evento de liberação/bloqueio de módulo
        .listen(".Module", (data) => {
          console.log("module event ", data);
        });
    },

    /**
     * Função que faz o build do cache de todos os módulos
     *
     * Mantem o que vem do banco, aplica validações e adiciona índices que faltam, baseado no cache default em @/config/modulos/{modulo}
     */
    [actions.APP.CACHE_REBUILD]({ state, commit }, defaultCache: any = null) {
      // Retorna uma promessa
      return new Promise((res) => {
        // Captura o cache do usuário
        const userCache = !isNull(defaultCache)
          ? { ...defaultCache }
          : { ...state.user.cache };

        // Captura todos os módulos, mas obtém apenas o id dele
        const items = state.modules.map((modulo) => modulo.id);

        // Para cada módulo em módulos
        for (const modulo of items) {
          // Tente
          try {
            // Fazer o import do cache default do módulo iterado
            const { cache } = require(`@/config/modulos/${modulo}`);

            // Se for uma função, chama a função passando o state
            const defaultCache =
              typeof cache === "function"
                ? cache.bind(userCache[modulo])()
                : cache;

            // Se não houver cache setado para esse módulo, seta com o default
            if (!userCache[modulo]) userCache[modulo] = defaultCache;

            // Captura o cache do módulo iterado
            const cache_ = userCache[modulo];

            // Aplica as validações de ordenar do cache
            if (
              isInvalid(cache_.ordenar) ||
              typeof cache_.ordenar === "string"
            ) {
              // Atribui o ordernar default ou uma lista vazia
              cache_.ordenar = defaultCache.ordenar ?? [];
            }

            // Aplica as validações de filtros do cache
            if (isInvalid(cache_.filtros)) {
              // Atribui os filtros default ou uma lista vazia
              cache_.filtros = defaultCache.filtros ?? [];
            }

            // Caso os filtros estejam válidos, verifica por predefinidos
            else {
              cache_.filtros = (cache_.filtros ?? []).map((filter) => {
                // Se o filtro tiver a chave 'key' = 'today', quer dizer que é um filtro predefinido de data, substitui para o dia de hoje
                if (filter.key === "today") return today(filter.coluna);
                // Caso contrário, retorna
                return filter;
              });
            }

            // Aplica as validações de colunas do cache
            if (isInvalid(cache_.colunas)) {
              // Atribui as colunas default ou uma lista vazia
              cache_.colunas = defaultCache.colunas ?? [];
            }

            // Aplica as validações do selecionado do cache
            if (isInvalid(cache_.selecionado)) {
              // Atribui o selecionado default ou um objeto default
              cache_.selecionado = defaultCache.selecionado ?? {
                index: 0,
                coluna: 0,
              };
            }
          } catch (e) {
            // Caso ocorra erros, continua para o próximo módulo
            continue;
          }
        }

        // Comita a mutação do cache, com o cache todo estruturado
        commit(mutations.APP.CACHE, userCache);

        // Resolve a promessa
        return res({});
      });
    },

    /**
     * Função que faz o logout do usuário logado
     */
    [actions.APP.LOGOUT]({ rootState }, clearSession = false) {
      // Logout
      if (clearSession) api.post("/auth/logout");

      // Limpa o root state
      Object.assign(rootState, { ...defaultState() });
    },

    /**
     * Função que faz login no sistema
     */
    [actions.APP.LOGIN](_, data) {
      // Retorna a promessa da requisição
      return api.post("/auth/login", data);
    },

    /**
     * Função que faz o login do usuário Administrador dentro do sistema (módulo config)
     */
    [actions.APP.LOGIN_ADMIN](_, password) {
      // Retorna a promessa da requisição
      return api.post("/auth/admin", {
        password,
      });
    },

    /**
     * Função que apaga todos os dados do Emitente
     */
    [actions.APP.CLEAR_ALL_DATA](_, params) {
      return api.post("delete-all-my-data", params);
    },
    /**
     * Função que exporta todos os dados do Emitente
     */
    [actions.APP.EXPORT_ALL_DATA](_, { confirm_text, password }) {
      return api.post("export-all-my-data", { confirm_text, password });
    },

    /**
     * Função que consulta o acesso ao suporte
     */
    [actions.APP.STATUS_SUPPORT_ACCESS]() {
      return api.get("/support-access");
    },
    /**
     * Função que requisita acesso ao suporte
     */
    [actions.APP.REQUEST_SUPPORT_ACCESS](_, { expires }) {
      return api.post("/support-access", { expires });
    },
    /**
     * Função que revoga o acesso ao suporte
     */
    [actions.APP.REVOKE_SUPPORT_ACCESS]() {
      return api.put("/support-access/revoke");
    },
  },
};
