<template>
  <component
    class="ma-0"
    style="cursor: initial !important"
    :is="rootComponent"
    v-bind="rootAttrs"
  >
    <v-text-field
      dense
      eager
      outlined
      hide-details
      class="pa-0 ma-0"
      style="cursor: initial !important"
      ref="input"
      :prefix="prefix"
      :suffix="suffix"
      :maxlength="maxlength"
      :disabled="disabled"
      :readonly="readonly"
      :name="name"
      :value="value"
      :id="inputId"
      :class="inputClass"
      :type="type"
      :required="required ? 'required' : ''"
      :placeholder="placeholder"
      :label="label"
      :autocomplete="autoComplete_"
      @input.native="onInput"
      @focus="onFocus"
      @blur="onBlur"
      @click="onClick"
      @keydown="onKeyDown"
      @keypress="onKeyPress"
      v-bind="{ ...$attrs }"
    >
      <template #append><slot name="append" /></template>
      <template #append-outer><slot name="append-outer" /></template>
      <template #progress><slot name="progress" /></template>
    </v-text-field>

    <slot name="extra" />
  </component>
</template>

<script>
import Tooltip from "@/components/form/utils/Tooltip.vue";

import Btn from "../../buttons/Btn.vue";

import textfield from "./mixin";
import { VCol } from "vuetify/lib";

export default {
  mixins: [textfield],
  props: {
    prefix: { type: String, default: null, required: false },
    suffix: { type: String, default: null, required: false },
    type: { type: String, default: "text" },
    tooltip: { type: String },
    required: { type: Boolean, default: false },

    // Cols
    cols: { default: "12" },
    pl: { type: Boolean, default: false },
    sm: { required: false },
    md: { required: false },
    lg: { required: false },
    xl: { required: false },
  },
  components: {
    Btn,
    Tooltip,
  },
  computed: {
    rootComponent() {
      return this.withoutCols ? "div" : VCol;
    },
    rootAttrs() {
      return this.withoutCols
        ? {}
        : {
            class: this.colClass,
            cols: this.cols,
            sm: this.sm,
            md: this.md,
            lg: this.lg,
            xl: this.xl,
          };
    },
    colClass() {
      return {
        "pa-0": this.clear,
        "pb-1 pt-2 px-1": !this.clear,
        "pl-2": this.pl,
      };
    },
    wrapperClass() {
      return { "v-input-clear": this.clear };
    },
  },
  methods: {
    onClick(event) {
      this.focus();
      this.$emit("click", event);
    },
  },
};
</script>

<style lang="scss">
.v-input-clear .v-btn--icon.v-size--small {
  height: 14px !important;
}

.theme--dark {
  input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
    background-color: rgba(255, 255, 255, 0) !important;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.theme--light {
  input:-webkit-autofill {
    -webkit-text-fill-color: black !important;
    background-color: rgba(255, 255, 255, 0) !important;
    transition: background-color 5000s ease-in-out 0s;
  }
}
</style>
