import get from "lodash/get";

export const CODIGOS_PAGAMENTO_PRAZO = ["05", "14", "15"];

// Função que retorna a estrutura padrao da rota, usando o modulo do parâmetro
const _route = (modulo) => ({
  name: "modulo",
  params: {
    modulo,
  },
});

// Função que retorna a estrutura padrao da rota, usando o modulo do parâmetro
const _rStepsAction = (modulo, action, params = {}) => ({
  name: "step-container",
  params: {
    modulo,
    action,
    ...params,
  },
});

export const stepContainer =
  "saida|entrada|servicos|orcamento|ordem-servico|cupons-fiscais|manifestos-eletronicos";

const validate = (
  data: any,
  allowed: string[] = [],
  blocked: string[] = []
) => {
  if (!data) return true;

  const id = typeof data.guarded === "string" ? data.guarded : data.id;

  if (!id) return true;

  const permission =
    typeof data.prevalent !== "undefined" ? data.prevalent : true;

  if (!blocked.length && !allowed.length) {
    return permission;
  }

  if (blocked.includes(id) || blocked.includes("*")) return false;

  // se a permissão for prevalente bloqueado, a mesma precisa existir no array de liberado
  if (permission === false) {
    return allowed.includes(id);
  }

  return allowed.includes(id) || allowed.includes("*");
};

/**
 * Verifica se o módulo está liberado para o emitente
 * @deprecated
 */
export const isModuleAllowed = (emitente, module) => {
  const allowed = get(emitente, "detalhes.liberar.modulos", []);
  const blocked = get(emitente, "detalhes.bloquear.modulos", []);

  if (module.config || module.id === "config") return true;

  return validate(module, allowed, blocked);
};

/**
 * Verifica se a ação está liberada para o emitente
 */
export const isActionAllowed = (emitente, action) => {
  const blocked = get(emitente, "detalhes.bloquear.acoes", []);
  const allowed = get(emitente, "detalhes.liberar.acoes", []);

  return validate(action, allowed, blocked);
};

/**
 * @exports Object
 */
export const modulos = [
  {
    id: "saida",
    icon: require("@/assets/menu/modulos/nfe.svg"),
  },
  {
    id: "entrada",
    icon: require("@/assets/menu/modulos/entrada.svg"),
  },
  {
    id: "servicos",
    icon: require("@/assets/menu/modulos/nfse.svg"),
  },
  {
    id: "cupons-fiscais",
    route: _rStepsAction("cupons-fiscais", "novo", { last: true }),
    icon: require("@/assets/menu/modulos/cupom-fiscal.svg"),
    context: [
      {
        title: "Registros",
        onClick(this: any) {
          this.$router.push(_route("cupons-fiscais"));
        },
      },
    ],
  },
  {
    id: "ordem-servico",
    icon: require("@/assets/menu/modulos/ordem.svg"),
  },
  {
    id: "orcamento",
    icon: require("@/assets/menu/modulos/orcamento.svg"),
  },
  {
    id: "estoque",
    icon: require("@/assets/menu/modulos/estoque.svg"),
  },
  {
    id: "cadastro",
    icon: require("@/assets/menu/modulos/cadastro.svg"),
  },
  {
    id: "contas-receber",
    icon: require("@/assets/menu/modulos/receber.svg"),
  },
  {
    id: "contas-pagar",
    icon: require("@/assets/menu/modulos/pagar.svg"),
  },
  {
    id: "livro-caixa",
    icon: require("@/assets/menu/modulos/caixa.svg"),
  },
  {
    id: "controle-bancario",
    icon: require("@/assets/menu/modulos/bancos.svg"),
    context: [
      {
        title: "Contas Bancárias",
        onClick(this: any) {
          this.$router.push(_route("contas-bancarias"));
        },
      },
    ],
  },
  {
    id: "config",
    route: { name: "config" },
    icon: require("@/assets/menu/modulos/config.svg"),
  },
];

export const actions = [
  {
    title: "Novo",
    id: "new-registro",
    icon: require("@/assets/menu/actions/novo.svg"),
  },
  {
    title: "Apagar",
    id: "delete-registro",
    icon: require("@/assets/menu/actions/apagar.svg"),
  },
  {
    title: "Procurar",
    id: "search",
    icon: require("@/assets/menu/actions/procurar.svg"),
  },
  {
    title: "Imprimir",
    id: "print",
    icon: require("@/assets/menu/actions/imprimir.svg"),
  },
  {
    title: "Visualizar",
    id: "detail",
    icon: require("@/assets/menu/actions/visualizar.svg"),
  },
  {
    title: "Alterar",
    id: "edit-registro",
    icon: require("@/assets/menu/actions/alterar.svg"),
  },
  {
    title: "Ver Ficha",
    id: "view-ficha",
    icon: require("@/assets/menu/actions/alterar.svg"),
  },
  // {
  //   title: "Liberar",
  //   id: "locked",
  //   icon: require("@/assets/menu/actions/bloqueado.svg")
  // },
  // {
  //   title: "Bloquear",
  //   id: "unlocked",
  //   icon: require("@/assets/menu/actions/liberado.svg")
  // },
  {
    title: "Filtrar",
    id: "filter",
    icon: require("@/assets/menu/actions/filtrar.svg"),
  },
];

/**
 * Ações em comum entre ficha e módulo
 */
const commonFichaActions = [
  "new-registro",
  "search",
  "detail",
  "previous-registro",
  "next-registro",
];

/**
 * Exporta as ações da ficha
 */
export const fichaExtraActions = actions
  .filter((action) => commonFichaActions.includes(action.id))
  .concat([
    {
      title: "Anterior",
      id: "previous-registro",
      icon: require("@/assets/menu/actions/voltar.svg"),
    },
    {
      title: "Próximo",
      id: "next-registro",
      icon: require("@/assets/menu/actions/avancar.svg"),
    },
  ]);
