<template>
  <text-field
    maxlength="13"
    :name="name"
    :label="label"
    v-mask="'A##A########A'"
    v-bind="$attrs"
    :value="value"
    @input="$emit('input', $event)"
    @enter="$emit('enter', $event)"
  />
</template>

<script>
import { mask } from "vue-the-new-mask";

import { TextField } from "@/components/form";

export default {
  components: { TextField },
  directives: { mask },
  props: {
    name: { default: "serial" },
    label: { default: "Serial" },
    value: {},
  },
};
</script>
