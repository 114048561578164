import filter from "lodash/filter";
import includes from "lodash/includes";
import get from "lodash/get";
import compact from "lodash/compact";

import { milliseconds } from "@/plugins/moment";

import _actions from "@/store/actions";

import DFESTATUS, {
  AUTHORIZED,
  CANCELLED,
  DENIED,
  PROCESSING,
} from "@/mapping/status";

/**
 * Colunas do Grid
 */
export const columns = [
  { text: "RPS", value: "numero_rps", maxWidth: "86px" },
  { text: "NFS-e", value: "numero_nf", maxWidth: "86px" },
  { text: "Série", value: "serie" },
  {
    text: "Status da NF-e",
    value: "nfe_status",
    hidden: true,
  },
  {
    text: "Status da NFS-e",
    value: "nfe_status_descricao",
    document: "nfse",
    action: "dfe_transmitir",
    type: "value-icon",
    align: "left",
    maxWidth: "245px",
  },
  { text: "Emissão", value: "emissao_dt", type: "timestamp" },
  { text: "Cliente", value: "clifor_razao_social" },
  { text: "Serviços", value: "vlr_servicos", align: "right" },
  { text: "Desconto", value: "vlr_desconto", align: "right" },
  { text: "Total", value: "vlr_total", align: "right" },
  { text: "Natureza Operação", value: "natureza_descricao" },
  { text: "Vendedor", value: "colaborador_razao_social" },
  { text: "ISS", value: "vlr_iss", align: "right" },
  {
    id: "xml_nfe",
    text: "NFS-e XML",
    value: "tem_xml_nfe",
    action: "xml",
    type: "xml",
    hiddenReport: true,
  },
  {
    id: "xml_recibo",
    text: "NFS-e recibo XML",
    value: "tem_xml_recibo",
    action: "xml",
    type: "xml",
    hiddenReport: true,
  },
];

export { validateForEditAction } from "./saida";

/**
 * Ações do submenu
 */
export const subactions = function (this: any) {
  return [
    {
      title: "Arquivo",
      items: compact([
        this.$emitente.modulo_ordem_servico
          ? {
              id: "import_document",
              import: "ordem-servico",
              title: "Importar Ordem de Serviço",
            }
          : false,
        {
          id: "import_document",
          import: "orcamento",
          title: "Importar Orçamento",
        },
        // {
        //   subheader: `Ações para a NFSe ${registro.numero_nf || ""}/${
        //     registro.serie || ""
        //   }`,
        // },
        // {
        //   id: "dfe_generate_new_number",
        //   modulo: "saida",
        //   title: "Gerar numeração nova",
        //   disabled: !registro || !registro.id,
        // },
      ]),
    },
  ];
};

/**
 * Ação de contexto da tabela
 *
 * Quando autorizada NF-e:
 * Ações Bloqueadas: 1,2,3,9,V,R,I
 *
 * Quando Rejeição:
 * Ações Bloqueadas: 4,7,V,C,I
 *
 * Quando Cancelado:
 * Ações Bloqueadas: 1,2,3,5,7,8,9,V,C,I
 */
export const context = function (this: any) {
  // Captura o registro selecionado

  // Define o desabilitado
  // const disabled = true;

  const nfeStatus = get(this.registro, "nfe_status");

  // Verifica se o documento está autorizado
  const authorized = includes(AUTHORIZED, nfeStatus);
  // Verifica se o documento está cancelado
  const cancelled = includes(CANCELLED, nfeStatus);

  const notRegistro = !get(this.registro, "id");

  const processing = includes(PROCESSING, nfeStatus);
  const denied = includes(DENIED, nfeStatus);

  const disabled = includes(DFESTATUS, nfeStatus) || notRegistro || processing;

  const document = "nfse";

  // Retorna a lista dos itens
  return compact([
    {
      document,
      id: "dfe_transmitir",
      title: "Enviar NFS-e",
      icon: "mdi-arrow-right-bold",
      hidden: disabled || notRegistro || authorized,
    },
    !disabled && !authorized && !cancelled
      ? {
          document,
          id: "dfe_gerar_xml",
          title: "Gerar XML",
          icon: "mdi-xml",
          hidden: disabled || notRegistro,
        }
      : null,
    {
      document,
      id: "xml_nfe",
      action: "xml",
      title: "Visualizar XML",
      icon: "mdi-magnify",
    },
    {
      document,
      id: "dfe_cancelar",
      title: "Cancelar NFS-e",
      code: "5",
      icon: "mdi-close",
      hidden: !authorized,
      divider: true,
    },
    {
      document,
      id: "dfe_visualizar_danfe",
      title: "Visualizar NFS-e",
      code: "3",
      icon: "mdi-magnify",
      hidden: !authorized && !cancelled && !denied,
    },
    {
      document,
      id: "dfe_visualizar_danfe",
      type: "direct-print",
      title: "Imprimir NFS-e",
      code: "3",
      icon: "mdi-printer",
      hidden: !authorized && !cancelled && !denied,
    },
    {
      document,
      type: "cancelamento",
      id: "dfe_visualizar_danfe",
      title: "Imprimir Cancelamento",
      code: "3",
      icon: "mdi-printer",
      hidden: !cancelled,
    },
    {
      document,
      id: "dfe_enviar_da_xml_email",
      title: "Enviar o XML e NFS-e por E-mail",
      code: "4",
      icon: "mdi-email",
      hidden: !authorized && !cancelled,
    },
  ]);
};

/**
 * Dependências do módulo
 */
export const dependencies = [
  {
    name: "itens",
  },
];

/**
 * Steps da ficha
 */
export const steps = [
  {
    title: "Básico",
    item: 1,
  },
  {
    title: "Complemento",
    item: 2,
    rules: true,
  },
  {
    title: "Pagamento",
    item: 3,
    rules: true,
  },
];

/**
 * Ficha default
 */
export const ficha = () => ({
  ativo: true,
  emissao_dt: milliseconds(),
});

/**
 * Formata os dados da ficha antes de enviar pro backend (update/create)
 */
export const format = function (this: any) {
  return {
    clifor_id: get(this.data, "clifor_id", get(this.data, "clifor.id")),
  };
};

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = filter(columns, (column) =>
  includes(["numero_rps", "numero_nf", "serie", "emissao_dt"], column.value)
);

/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = [
  "vlr_total",
  "vlr_desconto",
  "itens",
  "parcelas_json",
  "clifor",
  "complemento",
];

/**
 * Cache default this = current user cache
 */
export const cache = function (this: any) {
  return {
    selecionado: {
      index: 0,
      coluna: 0,
    },
    colunas: [
      "numero_rps",
      "numero_nf",
      "serie",
      "nfe_status_descricao",
      "emissao_dt",
      "clifor_razao_social",
      "vlr_total",
      "natureza_descricao",
    ],

    filtros: [],
    ordenar: [
      {
        coluna: "numero_rps",
        ordem: "desc",
      },
    ],
  };
};

/**
 * Configuração do assistente
 */
export const assistent = {
  // Identificador do assistente
  nfse_cancelar: {
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message(item) {
      return `
        Atenção<br>
        <br>Você está prestes a cancelar uma NFS-e. Esta NFS-e se tornará inválida.<br>
        <br>Para cancelar a NFS-e: <strong>${item.numero_nf}/${item.serie}</strong>, insira uma justificativa (min. 15 caracteres).
      `;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      return [
        {
          autoFocus: true,
          name: "_nfe_justificativa",
          label: "Justificativa",
        },
      ];
    },
    /**
     * Função que é chamada quando clicado no botão ok do assistente
     *
     * retorna uma string representando um erro ou true para válido
     */
    validator(item) {
      if (!item || !item._nfe_justificativa) return "empty";
      if (item._nfe_justificativa.length < 15) return "min_15";
      return true;
    },
    /**
     * Função que retorna todos os erros
     */
    error() {
      return {
        empty: "A justificativa não pode ser vazia.",
        min_15: "A justificativa precisa ter no mínimo 15 caracteres.",
      };
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, data) {
      // Retorna a promessa da requisição
      return this.$store.dispatch(_actions.DFE.CANCELAR, {
        data,
        document: "nfse",
      });
    },
  },
};
