import { filter, find, includes } from "lodash";
/**
 * Colunas do Grid
 */
export const columns = [
  {
    text: "...",
    position: 0,
    noResize: true,
    unsortable: true,
    selectable: false,
    hiddenReport: true,
    value: "status",
    type: "icon",
    color: "green--text",
    icon(this: any, item: any) {
      return find(
        this.$store.state.app.currentConnectedUsers,
        (user) => item.id === user.id
      )
        ? "mdi-checkbox-blank-circle"
        : "mdi-checkbox-blank-circle-outline";
    },
  },
  { text: "Nome", value: "nome" },
  { text: "Nome usuário (Login)", value: "nome_usuario" },
  { text: "CPF", value: "cpf_cnpj", type: "cpf_cnpj" },
  { text: "Ativo", value: "ativo", type: "boolean" },
  { text: "Data de criação", value: "created_at", type: "timestamp" },
];

export const permissions = [
  {
    text: "Bloqueado",
    value: 0,
  },
  {
    text: "Completo",
    value: 1,
  },
  {
    text: "Leitura Parcial",
    value: 2,
  },
  {
    text: "Leitura Completa",
    value: 3,
  },
];

/**
 * Tabs da ficha
 */
export const tabs = [
  { item: "tab-principal", title: "Principal" },
  // { item: "tab-senha", title: "Senha" },
  { item: "tab-permissoes", title: "Permissões", disabled: (data) => !data.id },
  { item: "tab-email", title: "E-mail", disabled: (data) => !data.id },
];

/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = ["parametros_email"];

/**
 * Se o módulo é protegido por senha de administrador
 */
export const guard = true;

export const ficha = () => ({
  ativo: true,
  reset_pass_after_login: false,
});

/**
 * Ações do submenu
 */
export const subactions = function () {
  return [
    // {
    //   type: "report",
    //   title: "Relatórios",
    //   disabled: true,
    //   items: []
    // }
  ];
};

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = filter(columns, (column) =>
  // Retorna as colunas que as incluem
  includes(["nome", "nome_usuario"], column.value)
);

/**
 * Cache default
 */
export const cache = {
  colunas: ["status", "nome", "nome_usuario", "ativo", "created_at"],
  filtros: [],
  ordenar: [
    {
      coluna: "nome",
      ordem: "desc",
    },
  ],
};
