import actions from "@/store/actions";

import API from "@/api";
import get from "lodash/get";
import mutations from "@/store/mutations";

// inicializa a API
const api = API.config();

export default {
  actions: {
    /**
     * Ação que faz a leitura do xml de um registro específico, informando o módulo e o nome da coluna do XML
     */
    [actions.UTILS.READ_XML](_state, { xml, modulo, id }) {
      // Retorna a promessa da api
      return api
        .get(`/${modulo}/${id}/xml`, {
          params: { xml },
        })
        .then((response) => {
          // Retorna somente o XML para o componente renderizar
          return response && response.data ? response.data : null;
        });
    },

    [actions.UTILS.HANDLE_FILE](store, callback) {
      const { commit } = store;

      // Retorna a promessa
      return callback()
        .then((response) => {
          // Captura o type
          const type = get(response.headers, "content-type");
          // captura o nome do arquivo de download
          const filename = get(response.headers, "x-filename");

          if (!response.data || type === "application/json") {
            return response;
          }

          // monta o blob
          const blob = new Blob([response.data], {
            type: type,
            // encoding: "UTF-8"
          });

          // Abre o blob para download
          window.openDownload(blob, filename);

          return response;
        })
        .catch((error) => {
          console.error(get(error, "response", error));

          const contentType = get(error, "response.headers.content-type");

          const data = get(error, "response.data");
          const message = get(data, "message");

          if (message && contentType === "application/json") {
            return commit(mutations.APP.ALERT, {
              active: true,
              title: "Erro ao gerar o arquivo",
              color: "error",
              message,
            });
          }

          if (!data) return;

          // monta o blob
          const blob = new Blob([data], {
            type: "text",
            // encoding: "UTF-8"
          });

          blob
            .text()
            .then((data) => {
              try {
                return JSON.parse(data);
              } catch {
                return {};
              }
            })
            .then((data) => {
              const message = get(data, "message");

              if (message)
                return commit(mutations.APP.ALERT, {
                  active: true,
                  title: "Erro ao gerar o arquivo",
                  color: "error",
                  message,
                });
            });

          // throw error;
        });
    },

    [actions.UTILS.PENDING_DOWNLOAD]({ dispatch }, { id }) {
      return dispatch(actions.UTILS.HANDLE_FILE, () => {
        return api.get(`pending-downloads/${id}`, {
          responseType: "arraybuffer",
        });
      });
    },

    [actions.UTILS.IS_DOWNLOAD_READY]({}, { type }) {
      return api.get(`pending-downloads/${type}/ready`);
    },
  },
};
4;
