<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl" class="pa-0">
    <v-menu
      offset-y
      bottom
      disable-keys
      :top="top"
      :close-on-click="false"
      :close-on-content-click="false"
      v-model="menu"
    >
      <template #activator="{ attrs }">
        <c-text-field
          listen-for-search
          class="sale-text-field"
          ref="textField"
          autocomplete="off"
          :class="[sale && 'saleGrey', sale && 'rounded', color]"
          :sale="sale"
          :value="value"
          :placeholder="placeholder"
          :emit-shift-enter="emitShiftEnter"
          @input="onInput"
          @enter="$emit('enter', $event)"
          @focus="$emit('focus', $event)"
          @blur="$emit('blur', $event)"
          @keydown="onKeyDown"
          @search="() => onSearch()"
          @ctrl-delete="$emit('ctrl-delete', $event)"
          v-bind="{ ...$attrs, ...attrs }"
        />
      </template>

      <v-list dense class="py-0" flat>
        <v-progress-linear
          indeterminate
          size="64"
          width="5"
          color="primary"
          class="mx-auto"
          v-if="loading"
        />
        <v-virtual-scroll
          tabindex="0"
          :class="`v-virtual-scroll-${_uid}`"
          :bench="0"
          :items="items"
          :height="listItemHeight * visibleItems"
          :item-height="listItemHeight"
        >
          <template v-slot="{ item, index }">
            <v-list-item
              two-line
              tabindex="1"
              :data-key="index"
              :key="index"
              :disabled="item && item.disabled"
              style="min-height: 50px !important"
              :class="[
                highlighted === index && 'v-list-item__highlighted',
                `v-virtual-scroll-${_uid}-item-${index}`,
              ]"
              @click="onItemClick(item, index)"
            >
              <v-list-item-content class="py-0">
                <v-list-item-title>
                  {{ buildTexts(item) }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  {{ buildSubTexts(item) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-list>
    </v-menu>
  </v-col>
</template>

<script>
import {
  filter,
  find,
  get,
  includes,
  last,
  split,
  trim,
  toString,
  head,
  isEmpty,
  isObject,
  upperCase,
  startsWith,
  padStart,
  pick,
} from "lodash";
import { mapActions, mapState } from "vuex";
import CTextField from "@/components/form/inputs/textfield/CTextField.vue";

import actions from "@/store/actions";
import mutations from "@/store/mutations";

import { clearCurrency, setCurrency } from "@/utils";

export default {
  components: { CTextField },
  props: {
    value: { default: "" },

    itemValue: { default: "id" },
    itemTexts: { default: () => ["id", "descricao", "gtin"] },

    sale: { type: Boolean, default: true },
    disableKeys: { type: Boolean, default: false },
    open: { type: Boolean, default: false },
    // Remove o item se estiver vazio
    clearToRemove: { type: Boolean, default: false },
    changed: { type: Boolean, default: false },
    emitShiftEnter: { type: Boolean, default: false },
    text: { default: "" },
    color: { default: "" },
    placeholder: { default: "" },

    __ficha: {},
    __cupom: {},

    cols: { default: "12" },
    pl: { type: Boolean, default: false },
    sm: { required: false },
    md: { required: false },
    lg: { required: false },
    xl: { required: false },
  },
  data: () => ({
    query: "",
    // menu: false,
    highlighted: 0,
    listItemHeight: 50,
    top: false,
    selected: null,
  }),
  created() {
    this.onPaginate(true);
  },
  computed: {
    ...mapState({
      loading: function (state) {
        return get(state.persistent, "fetching.estoque", false);
      },
      products: function (state) {
        let products = filter(
          get(state.persistent, `data.estoque`, []),
          "ativo"
        );

        if (this.modulo === "servicos") {
          products = filter(products, (it) => it.tipo_item === "09");
        }

        return products;
      },
      isLastPage: function (state) {
        const page = get(state.persistent, "paginator.estoque.page", 0);
        const lastPage = get(state.persistent, "paginator.estoque.lastPage", 0);

        return page >= lastPage;
      },
    }),
    visibleItems() {
      return this.__cupom ? 7 : 5;
    },
    items() {
      return this.menu && this.query ? this.filtered : this.products;
    },
    filtered() {
      return filter(this.products, (item) => {
        return this.comparisionValues(item.descricao, this.query);
      });
    },
    menu: {
      get() {
        return this.open;
      },
      set(value) {
        if (value) {
          const { textField } = this.$refs;
          const { y } = textField.$el.getBoundingClientRect();
          const vh = Math.max(0, window.innerHeight);

          // this.top = vh - y < 200 + 35;
          this.top = vh - y < 200 + this.listItemHeight + 23;
        }

        this.$nextTick(() => {
          this.$emit("menu", value);
        });
      },
    },
    modulo() {
      return get(this.__ficha, "modulo", get(this.__cupom, "__ficha.modulo"));
    },
  },
  methods: {
    ...mapActions({
      fetchPersistentData: actions.MODULO.PERSISTENT.READ,
      searchPersistentData: actions.MODULO.PERSISTENT.SEARCH,
    }),
    comparisionValues(haystack, needle) {
      return includes(
        (haystack ?? "")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase(),
        (needle ?? "")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
      );
    },
    reset() {
      this.query = "";
      this.$nextTick(() => {
        this.$emit("input", "");
      });
    },
    focus() {
      const input = this.$refs.textField;
      input && input.getRef().focus();
    },
    deeplyFindItem(value) {
      const columns = [...this.itemTexts.slice(1)];

      let item = find(this.items, (item) => {
        return (
          (item.gtin ?? "").toLowerCase() === (value ?? "").toLowerCase() ||
          this.isGtinPesavel(
            (item.gtin ?? "").toLowerCase(),
            (value ?? "").toLowerCase()
          ) ||
          parseInt(item.id) === parseInt(value)
        );
      });

      if (!item && value.length > 3) {
        item = find(this.items, (item) => {
          for (const column of columns) {
            return this.comparisionValues(item[column], value);
          }
        });
      }

      return item;
    },
    onSearch(value = null) {
      this.handleModifiers();

      const query = trim(value ?? this.query);

      if (isEmpty(query)) return;

      return this.searchPersistentData({
        ignoreWhenLastPage: true,
        modulo: "estoque",
        target: this.itemTexts,
        value: query,
      });
    },
    getCheckoutBalanceWeight() {
      return new Promise((resolve, reject) => {
        if (this.__ficha) {
          this.__ficha.activeContextListeners = {
            then: (peso) => resolve(peso),
            catch: () => reject(),
          };
        }

        if (this.__cupom) {
          this.__cupom.activeComponentListeners = {
            then: (peso) => resolve(peso),
            catch: () => reject(),
          };
        }

        return import(
          "@/components/popups/modulos/cupons-fiscais/Balanca.vue"
        ).then((Component) => {
          if (this.__ficha)
            return (this.__ficha.activeContextComponent = Component.default);

          this.__cupom.activeComponent = Component.default;
        });
      });
    },
    buildTexts(item = {}) {
      const id = padStart(item.id, 5, "0");
      const desc = item.descricao || "";
      const gtin = item.gtin || "";

      return `${id} ${desc} ${gtin}`;
    },
    buildSubTexts(item = {}) {
      const qtd = item.qtd_atual || "0,00";
      const un = item.unidade_medida || "UN";
      const vlr = item.vlr_preco || "0,01";

      return `${qtd} ${un} R$ ${vlr}`;
    },
    onItemClick(item, index) {
      if (!item) return;

      this.highlighted = index;

      return this.$nextTick(() => {
        this.search();
      });
    },
    onKeyDown(event) {
      if (!this.menu || this.disableKeys) return;

      if (this.modulo === "saida" && event.key === "Escape") {
        return (this.menu = false);
      }

      if (this.menu && !this.query && event.key === "Backspace") {
        return (this.menu = false);
      }

      const isUp = event.key === "ArrowUp";
      const isDown = event.key === "ArrowDown";

      if (!includes(["ArrowUp", "ArrowDown"], event.key)) return;

      event.preventDefault();

      if (isDown && this.highlighted < this.items.length - 1)
        this.highlighted += 1;
      else if (isUp && this.highlighted > 0) this.highlighted -= 1;

      const item = document.querySelector(
        `.v-virtual-scroll-${this._uid}-item-${this.highlighted}`
      );

      if (item) return;

      return this.scroll(isDown ? 1 : -1);
    },
    scroll(direction) {
      const virtualScroll = document.querySelector(
        `.v-virtual-scroll-${this._uid}`
      );

      if (!virtualScroll) return;

      virtualScroll.scrollTop +=
        this.listItemHeight * this.visibleItems * direction;
    },
    onInput(event) {
      this.$emit("input", event);

      // Se o menu estiver aberto e for apagado o texto, permanece aberto
      if (this.menu && this.query && !event) return (this.query = "");

      const fulltext = /^(?!-)[^0-9]/g;
      const priceAndQuantity = /^(.*[0-9])\$(.*[0-9])\*(.*)/;
      const quantity = /^(.*[0-9])\*(.*)/;
      const price = /^(.*[0-9])\$(.*)/;

      const regexps = [priceAndQuantity, quantity, price, fulltext];

      const match = head(
        filter(regexps, (regexp) => toString(event).match(regexp) !== null)
      );

      if (!match) return (this.menu = false), (this.query = "");

      const res = toString(event).match(match);

      if (match === priceAndQuantity) this.query = res[3];
      else if (includes([price, quantity], match)) this.query = res[2];
      else if (match === fulltext) this.query = event;

      // if (/^[0-9]/.test(this.query)) {
      if (parseInt(this.query) == this.query) {
        return (this.menu = false), (this.query = "");
      }

      this.highlighted = 0;

      this.$nextTick(() => {
        if (this.query && !this.menu) this.menu = true;
      });
    },
    onPaginate(firstAttempt = false) {
      this.fetchPersistentData({
        firstAttempt,
        orderBy: "descricao",
        modulo: "estoque",
      });
    },
    getRef() {
      return this.$refs.textField;
    },
    isGtinPesavel(gtin, value) {
      return (
        toString(value).length === 13 &&
        toString(gtin) === toString(value).substr(0, 7)
      );
    },
    handleModifiers() {
      let match;
      let modifier = null;

      // Se for modificador de valor unitario e quantidade
      if ((match = this.value.match(/^(.*[0-9])\$(.*[0-9])\*(.*)/))) {
        this.query = match[3] ?? "";
        modifier = `vlr_unitario:${match[1] ?? 1}|quantity:${match[2] ?? 1}`;
      }

      // Se for modificador de quantidade
      else if ((match = this.value.match(/^(.*[0-9])\*(.*)/))) {
        this.query = match[2] ?? "";
        modifier = `quantity:${match[1] ?? 1}`;
      }

      // Se for modificador de valor unitario
      else if ((match = this.value.match(/^(.*[0-9])\$(.*)/))) {
        this.query = match[2] ?? "";
        modifier = `vlr_unitario:${match[1] ?? 1}`;
      }

      // Se for modificador de cancelar pagamento
      else if ((match = this.value.match(/^-[p|P](.*)/))) {
        this.query = match[1] ?? "";
        modifier = `remove-payment:${match[1]}`;
      }

      // Se for modificador de cancelar item
      else if ((match = this.value.match(/^-(.*)/))) {
        this.query = match[1] ?? "";
        modifier = `remove:${match[1]}`;
      }

      return modifier;
    },
    async search() {
      const modifiers = this.handleModifiers();

      await this.$nextTick();

      const value = this.query ? trim(this.query) : trim(this.value);

      if (
        includes(modifiers, "remove-payment") &&
        this.modulo === "cupons-fiscais"
      ) {
        this.query = "";

        return this.$emit(
          "result",
          last(split(modifiers, "remove-payment:")),
          modifiers
        );
      }

      if (
        includes(modifiers, "remove") ||
        (this.clearToRemove && !value && this.changed)
      ) {
        this.query = "";

        return this.$emit(
          "result",
          last(split(modifiers, "remove:")),
          modifiers
        );
      }

      let item;

      if (this.menu) {
        item = this.items[this.highlighted];
        this.menu = false;
      }

      // Caso contrário
      else {
        if (!value) return this.$emit("input", "");

        item = this.deeplyFindItem(value);
      }

      this.query = "";

      // se não encontrou o item
      if (isEmpty(item)) {
        // se não estiver na última página
        if (!this.isLastPage) {
          try {
            const data = await this.onSearch(value);
            const registros = get(data, "registros", []);

            // se encontrou apenas 1 ocorrencia
            if (registros.length === 1) {
              // captura o primeiro
              item = head(registros);
            }

            // caso encontrou mais de uma ocorrencia
            else {
              // tenta buscar o item pela condição inicial
              item = this.deeplyFindItem(value);

              if (!item) {
                return this.$emit("result", item);
              }
            }
          } catch (e) {
            return this.$emit("result", item);
          }
        }

        //
        else {
          // retorna vazio
          return this.$emit("result", item);
        }
      }

      let quantity = -1;
      let vlr_unitario = clearCurrency(get(item, "vlr_preco", "0,01"));

      item = isObject(item) ? item : {};

      const unidade_medida = upperCase(get(item, "unidade_medida", "UN"));

      const isPesavel =
        startsWith(item.gtin, "2") && includes(["KG", "KU"], unidade_medida);

      //aplica os modificadores
      for (const modifier of split(modifiers, "|")) {
        if (includes(modifier, "quantity:"))
          quantity = clearCurrency(last(split(modifier, "quantity:")));

        if (includes(modifier, "vlr_unitario:"))
          vlr_unitario = clearCurrency(last(split(modifier, "vlr_unitario:")));
      }

      // Se for um produto pesável
      if (isPesavel) {
        // valida se é um código de etiqueta
        const gtinDeEtiqueta = this.isGtinPesavel(
          (get(item, "gtin", "") ?? "").toLowerCase(),
          (value ?? "").toLowerCase()
        );

        // Se for digitado etiqueta da balança
        if (gtinDeEtiqueta) {
          // "preco", "quantidade"
          const tipo = get(this.$config, "etiqueta_balanca_montante", "preco");

          let valorDoGtin = clearCurrency(toString(value).substr(7, 5));

          if (tipo === "quantidade") {
            quantity =
              unidade_medida === "KU" ? valorDoGtin : valorDoGtin / 1000;
          }

          // se for preço
          else {
            valorDoGtin = valorDoGtin / 100;

            if (valorDoGtin > 0.0) {
              quantity = valorDoGtin / vlr_unitario;
            }
          }
        }

        // Se for um produto pesável que nao foi lido da etiqueta, precisa capturar o peso da balança
        else {
          try {
            // se nao foi informado quantidade pelo modificador
            if (quantity === -1) {
              // captura a quantidade da balança
              quantity = await this.getCheckoutBalanceWeight();
            }
          } catch (_) {
            // se o usuário cancelar, retorna vazio
            if (this.menu) this.menu = false;

            return this.$emit("input", "");
          }
        }
      }

      // se nao for produto pesavel
      else {
        // se foi inserido quantidade por modificador
        if (includes(modifiers, ["quantity:"])) {
          // quantidade default
          quantity = Math.max(quantity, 0.01);
        } else {
          // quantidade default
          quantity = Math.max(quantity, 1);
        }
      }

      quantity = Math.max(quantity, 0.01);

      let discount = null;
      const subTotal = quantity * vlr_unitario;

      const per_promo1 = clearCurrency(item.per_desconto_promocao_1);
      const qtd_promo1 = clearCurrency(item.qtd_promocao_1);

      if (per_promo1 > 0.0 && qtd_promo1 > 0.0 && quantity >= qtd_promo1) {
        discount = (subTotal * per_promo1) / 100;
      }

      const per_promo2 = clearCurrency(item.per_desconto_promocao_2);
      const qtd_promo2 = clearCurrency(item.qtd_promocao_2);

      if (per_promo2 > 0.0 && qtd_promo2 > 0.0 && quantity >= qtd_promo2) {
        discount = (subTotal * per_promo2) / 100;
      }

      // se for venda
      // if (includes(["saida", "cupons-fiscais"], this.modulo)) {

      //   // se não permitir vendas abaixo do custo
      //   if (
      //     !this.$config.vendas_abaixo_do_custo &&
      //     subTotal < clearCurrency(item.vlr_custo_compra)
      //   ) {
      //     return this.$emit("result", null);
      //   }
      // }

      // se for venda
      if (includes(["saida", "cupons-fiscais"], this.modulo)) {
        // se não permitir estoque negativo na emissão da nfe
        if (
          !this.$config.estoque_negativo_emissao_nfe &&
          clearCurrency(item.qtd_atual) <= 0
        ) {
          // Commita o alert
          this.$store.commit(mutations.APP.ALERT, {
            active: true,
            title: `Não foi possível efetuar a venda`,
            message: `O item "${item.descricao}" possui quantidade negativa: ${
              item.qtd_atual
            } ${item.unidade_medida || "UN"}`,
          });

          return this.$emit("result", null);
        }
      }

      item = {
        ...pick(item, [
          "item",
          "descricao",
          "numero_caixa",
          "unidade_medida",
          "galeria",
        ]),
        produto_id: item.id,
        quantidade: setCurrency(quantity, this.$qtdDecimalCases),
        vlr_unitario: setCurrency(vlr_unitario, this.$vlrDecimalCases),
        vlr_subtotal: setCurrency(subTotal, this.$vlrDecimalCases),
        vlr_desconto: discount
          ? setCurrency(discount, this.$vlrDecimalCases)
          : null,
      };

      this.$emit("result", item, modifiers);

      return this.$nextTick(() => {
        if (this.menu) this.menu = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.v-list-item-selected {
  color: var(--v-tabs-darken1) !important;
}
</style>

<style lang="scss">
.v-list-item__highlighted {
  background-color: var(--v-tabs-darken1) !important;
}
</style>
