import API from "@/api";
import actions from "@/store/actions";
import mutations from "@/store/mutations";

const api = API.config({
  state: "fetching",
  mutation: mutations.MODULO.FICHA.FETCHING,
  delay: 200,
});

export default {
  actions: {
    /**
     * Função que consulta os dados do cpf/cnpj informado
     */
    [actions.MODULOS.CONSULTA.CADASTRO](store, params) {
      return api.store(store).get("/consulta-cadastro", { params });
    },
    /**
     * Função que consulta os dados do cep informado
     */
    [actions.MODULOS.CONSULTA.CEP](store, params) {
      return api.store(store).get("/consulta-cep", { params });
    },
  },
};
