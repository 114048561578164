<template>
  <div
    tabindex="0"
    ref="container"
    style="outline: none"
    @mousedown="onMouseDown"
    @keydown="onKeyDown"
    @contextmenu.prevent="$emit('context', $event)"
  >
    <div
      ref="wrapper"
      class="s-datatable-wrapper"
      :class="{ footer: !hideFooter && !$isMobile }"
      v-scroll.self="onScroll"
    >
      <v-progress-linear
        indeterminate
        relative
        v-if="simpleLoading && loading"
      />
      <table ref="table" class="s-datatable" :class="{ fixed: fixed }">
        <thead
          ref="thead"
          class="s-datatable-thead"
          v-if="!hideHeader && (headers || !loading)"
        >
          <data-table-header
            @click="$emit('click-header', header)"
            @dblclick="$emit('double-click-header', header)"
            @resize:header="(event) => $emit('resize:header', event)"
            :order="order"
            :key="i"
            :header="header"
            v-for="(header, i) in headers"
          />
        </thead>
        <tbody ref="tbody" class="s-datatable-tbody">
          <template v-for="(item, index) in items_">
            <data-table-row :key="index">
              <template v-for="(header, column) in headers">
                <data-table-cell
                  :ref="`data-table-cell-item-${index}-${column}`"
                  :class="
                    [
                      item[`${header.value}_color`],
                      item.row_color,
                      header.class,
                    ] | classMapping
                  "
                  :color="item.color || item.row_color || header.color"
                  :editable="editable && !header.noEditable"
                  :selected="
                    selectable &&
                    header.selectable !== false &&
                    column === value.coluna &&
                    item.id === selectedId
                  "
                  :item="item"
                  :icon="
                    header.type === 'icon' || header.type === 'value-icon'
                      ? item.icon
                      : null
                  "
                  :key="column"
                  :selectable="selectable"
                  :disabled="
                    header.disabled &&
                    (typeof header.disabled === 'function'
                      ? header.disabled(item)
                      : header.disabled === true || !item[header.disabled])
                  "
                  @mousedown="
                    (event) =>
                      onCellClick(event, {
                        item,
                        header,
                        index,
                        coluna: column,
                      })
                  "
                  @dblclick="
                    $emit('dblclick-cell', { item, header, coluna: column })
                  "
                  @focus="
                    (event) =>
                      onInputFocused(event, {
                        item,
                        header,
                        index,
                        coluna: column,
                      })
                  "
                  @change-input="
                    (event) => onInputChange(event, { item, header })
                  "
                  v-bind="header"
                  :value="getModelValue(item, header)"
                  @input="(value) => onSetModelValue(item, value, header)"
                >
                  <template #persistent-select="attrs">
                    <slot
                      name="persistent-select"
                      v-bind="{
                        ...attrs,
                        item,
                        header,
                        column,
                        index,
                        disabled,
                      }"
                    />
                  </template>

                  <template #textfield="attrs">
                    <slot
                      name="textfield"
                      v-bind="{
                        ...attrs,
                        item,
                        header,
                        column,
                        index,
                        disabled,
                      }"
                    />
                  </template>

                  <template #select="attrs">
                    <slot
                      name="select"
                      v-bind="{
                        ...attrs,
                        item,
                        header,
                        column,
                        index,
                        disabled,
                      }"
                    />
                  </template>
                </data-table-cell>
              </template>
            </data-table-row>
          </template>
        </tbody>
      </table>
    </div>
    <!-- Footer da tabela -->
    <div
      ref="footer"
      class="s-datatable-footer text-center text--text"
      v-if="!hideFooter && !$isMobile"
    >
      <!-- Slot para ser sobrescrito o conteúdo do footer -->
      <slot name="footer-content" />
    </div>

    <!-- Overlay para centralizar o elemento -->
    <v-overlay v-if="!simpleLoading" absolute :value="loading" :opacity="0.5">
      <!-- Card que renderiza a splash da tabela -->
      <v-card flat style="background-color: transparent" v-show="loading">
        <v-col cols="12" class="splash">
          <v-row align="center">
            <!-- Componente Splash que renderiza a logo -->
            <splash fixed white :width="!$isMobile ? '30vw' : '80vw'" />
          </v-row>
        </v-col>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
import {
  head,
  concat,
  get,
  isArray,
  find,
  join,
  compact,
  isObject,
  uniqBy,
  filter,
  includes,
  findIndex,
  orderBy,
  last,
  set,
  merge,
} from "lodash";

import DataTableRow from "@/components/table/DataTableRow.vue";
import DataTableHeader from "@/components/table/DataTableHeader.vue";
import DataTableCell from "@/components/table/DataTableCell.vue";
import Splash from "@/components/utils/Splash.vue";

import UseArrows from "@/mixins/useArrows";

export default {
  name: "DataTable",
  mixins: [UseArrows],
  components: {
    DataTableRow,
    DataTableHeader,
    DataTableCell,
    Splash,
  },
  filters: {
    classMapping(classes) {
      return join(compact(classes), " ");
    },
  },
  props: {
    value: {
      type: Object,
      default: () => ({ registro: {}, coluna: 0, text: "" }),
    },

    uniqBy: { type: String, default: "id" },

    orderBy: { type: Array, default: () => [] },
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    simpleLoading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    background: { type: Boolean, default: false },
    autoFocus: { type: Boolean, default: false },
    fixed: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: true },
    hideEmptyRow: { type: Boolean, default: false },
    hideFooter: { type: Boolean, default: false },
    hideHeader: { type: Boolean, default: false },

    topEmptyRow: { type: Boolean, default: false },

    useArrows: { type: Boolean, default: true },

    options: { type: Object },

    dimension: {
      type: Object,
      default: () => {
        return { width: "100%", height: "400px" };
      },
    },
  },
  data: () => ({
    cellHeight: 25,
    headerHeight: 26,
    keyCodes: {
      left: 37,
      up: 38,
      right: 39,
      down: 40,
      space: 32,
      enter: 13,
      delete_: 46,
    },
  }),
  computed: {
    /**
     * Retorna o id selecionado, se houver
     */
    selectedId() {
      // captura o registro id do model
      return get(this.value, "registro.id");
    },
    /**
     * Retorna o registro selecionado, se houver
     */
    selectedRegistro() {
      // captura o registro id do model
      return get(this.value, "registro") ?? {};
    },
    /**
     * Retorna o índice do registro selecionado
     */
    selectedIndex() {
      // Retorna a busca pelo indice
      return findIndex(this.items_, (item) => item.id === this.selectedId);
    },

    // ==================================================================
    // selected() {
    //   // Caso não houver registro, e houver indice no cache
    //   if (isFinite(this.value.index))
    //     // retorna o registro no index do cache
    //     return this.items_[this.value.index] ?? {};

    //   // Procura o registro
    //   const registro = find(
    //     // Lista de registros
    //     this.items_,
    //     // Verifica se o ID do registro é igual do cache
    //     (registro) => registro.id === this.value.id
    //   );

    //   // Caso contrário, retorna o registro, que pode ser null
    //   return registro ?? {};
    // },
    /**
     * Função que determina se é para ocultar a linha vazia
     */
    hideEmptyRow_() {
      return !this.selectable || this.hideEmptyRow || this.topEmptyRow;
    },
    /**
     * Itens da tabela, concat dos itens da props, junto de uma linha vazia
     */
    items_() {
      // Retorna a lista única por id
      let data = uniqBy(
        // lista concatenada
        concat(
          this.topEmptyRow ? [{}] : [],
          // Itens das props
          this.items ?? [],
          // Linha em branco, ou nada
          this.hideEmptyRow_ ? [] : [{}]
        ),
        this.uniqBy
      );

      if (this.orderBy.length === 2)
        data = orderBy(data, head(this.orderBy), last(this.orderBy));

      return data;
    },
    /**
     * Captura a ordem a partir das opções do props
     */
    order() {
      // Retorna a ordenação
      return (this.options ? this.options.ordenar : []) ?? [];
    },
    // currentItemIndex() {
    //   return (id) => findIndex(this.items_, (item) => item.id === id);
    // },
    // nextItem() {
    //   return (id) => {
    //     const currentIndex = this.currentItemIndex(id);
    //     if (currentIndex === -1) return {};
    //     return this.items_[currentIndex + 1];
    //   };
    // },
  },
  methods: {
    getModelValue(item, header) {
      return get(item, header.value);
    },
    onSetModelValue(item, value, header) {
      // console.log(item, {...item.icms})
      // console.log(set({}, header.value, value))
      // return set(item, header.value, value);
      return merge(item, { ...set({}, header.value, value) });
    },
    async autofocus() {
      if (!this.autoFocus) return;

      const container = this.$refs.container;

      if (!container) return;

      container.focus();
    },
    /**
     * Função que busca o registro através da coluna e valor informados
     */
    search(term, column, index = 0) {
      // Predicado da validação base
      const predicate = (item) =>
        // Ter o item, e o valor da coluna incluir o termo da busca
        item &&
        includes(
          (item[column] ?? "").toLowerCase(),
          (term ?? "").toLowerCase()
        );

      // Captura o dataset basedo no predicado
      const dataset = filter(this.items_, predicate);

      /**
       * Retorna Array
       *
       * [0] => Registro encontrado
       * [1] => Índice do registro encontrado
       * [2] => Índice do último registro encontrado
       */
      return [
        dataset[index] ?? dataset[0] ?? {},
        index,
        dataset.length - 1 < 0 ? index : dataset.length - 1,
      ];
    },
    /**
     * Função que emite o evento de célula ativa
     */
    setActiveCell(
      registro = null,
      coluna = null,
      force = false,
      scroll = false
    ) {
      // Captura o registro
      registro = registro ?? this.findById(null, force);
      // Captura a coluna
      coluna = coluna ?? this.value.coluna ?? 0;

      // se houver registro e for para scrollar
      if (registro && registro.id && scroll) {
        // Captura o index
        const index = this.findIndexById(registro.id);

        // FAz o scroll até na posição
        this.$refs.wrapper.scrollTop = (index * this.cellHeight) / 2;
      }

      // Emite o evento para atualizar o model
      this.$emit("input", {
        // Coluna informada, ou a mesma
        coluna,
        // Registro informado, ou captura pelo id
        registro,
        // Texto exibido na célula
        text: this.text(registro, coluna),
      });
    },
    /**
     * Função que captura o registro atualizado do grid
     */
    refresh(id) {
      const registro = this.findById(id);

      this.setActiveCell(registro, null);
    },
    /**
     * Função que retorna o registro pelo id
     */
    findById(id = null, force = false) {
      // Captura o id
      id = id ?? this.selectedId;

      // Captura o registro baseado no id
      const registro = find(this.items_, (item) => item.id === id);

      // Se não encontrar o registro, mas tiver id
      if (!registro && id && !force)
        // Retorna o registro vazio, mas mantem o id
        return { id };

      // Se não houver registro, retorna o primeiro
      return registro ?? head(this.items_) ?? {};
    },
    /**
     * Função que captura o índice através do ID
     */
    findIndexById(id) {
      // Retorna a busca pelo índice
      return findIndex(this.items_, (item) => item.id === id);
    },
    /**
     * Função que captura o texto do registro na posição id e coluna.
     */
    text(registro = null, column = 0) {
      // Se o registro for um objeto
      if (isObject(registro)) {
        // Captura o cabeçalho na posição do registro selecionado
        const header = this.headers[column];

        // Se houver header, retorna o registro na posição do valor da header (o texto exibido para o usuario)
        if (header) {
          if (header.json)
            return get(registro, `${header.value}.${header.json}`);

          return registro[header.value];
        }
      }
    },
    /**
     * Função que é chamada quando uma célula é clicada
     */
    onCellClick(_, props) {
      // Captura o item e a coluna clicado
      const { item, coluna, header } = props;

      if (header.selectable === false) return;

      // Seta a célula como ativa
      this.setActiveCell(item, coluna);
    },

    // ================================================================================
    async focus() {
      // Aguarda próximo tick
      await this.$nextTick();

      // Captura as células da tabela
      const currentActiveCell =
        this.$refs[
          `data-table-cell-item-${this.value.index}-${this.value.coluna}`
        ];

      // Captura a célula
      const cell = head(currentActiveCell);

      // se houver célula, forca o focus do elemento da célula
      if (cell && this.selectable && !this.background) {
        cell.focus();
        return true;
      }

      return false;
    },
    /**
     * Função que verifica a existência de um slot
     */
    // hasSlot(name = "default") {
    //   // Retorna se tem slot
    //   return (
    //     (this.$slots && this.$slots[name]) ||
    //     (this.$scopedSlots && !!this.$scopedSlots[name])
    //   );
    // },
    /**
     * Função que é chamada quando é feito scroll na tabela
     */
    onScroll(event) {
      // Captura as informações do scroll
      const { scrollHeight, clientHeight, scrollTop } = event.target;

      // Gera offset de 5 itens
      const offset = this.cellHeight * 7;

      // Se o scroll está no final
      if (scrollHeight - clientHeight <= scrollTop + offset) {
        // if (scrollHeight - clientHeight === scrollTop) {
        // Emite evento de paginação
        this.$emit("paginate", event);
      }
    },
    /**
     * Função que é chamada quando o textfield emitiu um foco
     */
    onInputFocused(event, props) {
      // Comportamento padrão de clique na célula
      this.onCellClick(event, props);
    },
    /**
     * Função que é chamada quando o textfield emitiu um input
     */
    onInputChange(event, props) {
      // Propaga o evento a diante
      this.$emit("change-input", event, props);
    },
    // nextItem() {
    //   const index = Math.min(
    //     this.findIndexById(get(this.value, "registro.id")) + 1,
    //     this.items_.length
    //   );

    //   // if (index < this.items_.length)
    //   this.setActiveCell(this.items_[index] ?? {});
    // },
    // updateActiveItem() {
    //   this.$nextTick(() => {
    //     this.$emit("registro", this.selected);
    //     if (!this.editable) this.focus();
    //   });
    // },
    /**
     * Função que é ativada quando o navegador dispara o evento mousedown
     */
    onMouseDown(e) {
      // Verifica se o evento é double click, prevendo o evento de se propagar, para que não selecione o texto.
      if (e.detail === 2) {
        e.preventDefault();
      }
    },
    /**
     * Função que gerencia o evento de clique de alguma tecla, o evento inicial do primeiro estágio do clique;
     * Ouve as setas do teclado para movimentar o registro selecionado pela tabela.
     */
    onKeyDown(event) {
      // Desestrutura os códigos de teclas que serão usadas
      const { left, up, right, down, delete_ } = this.keyCodes;

      // Se o código for !== de delete e se não está habilitada as setas e se a tabela é editável
      if (event.keyCode !== delete_ && (!this.useArrows || this.editable))
        // retorna
        return;

      // Define os eventos de tecla da tabela
      const events = {
        /**
         * Evento de tecla del
         */
        [delete_]: () => {
          // Se for tabela editável, retorna, pois o textfield controla esse evento.
          if (this.editable) return;

          // Captura o registro selecionado
          const registro = this.selectedRegistro;

          // Se for um ctrl + del
          if (event.ctrlKey)
            // Emite force delete
            this.$emit("force-delete", registro);
          // Caso contrário, delete normal
          else this.$emit("delete", registro);
        },
        // Evento de tecla para esquerda
        [left]: () => {
          this.moveLeft();
        },
        // Evento de tecla para cima
        [up]: () => {
          this.moveUp();
        },
        // Evento de tecla para direita
        [right]: () => {
          this.moveRight();
        },
        // Evento de tecla para baixo
        [down]: () => {
          this.moveDown();
        },
      };

      // Se não ouver eventos para essa tecla, retorna uma função nula
      if (!events[event.keyCode]) return;

      // Acessa o evento da tecla, e chama a função
      events[event.keyCode]();

      // Faz o foco da célula
      this.focus();

      if (event.keyCode === delete_ && !event.ctrlKey) return;

      // Prevê o padrão do evento
      event.preventDefault();
    },
  },
  /**
   * Hook sobrescrito do Vue
   */
  mounted() {
    // Captura a largura
    const { width } = this.dimension;
    // Captura as refs
    const { wrapper, container, footer } = this.$refs;

    // Seta a célula ativa
    this.setActiveCell();

    // Aguarda um tick
    this.$nextTick(async () => {
      // Define o height do container
      container.style.height = "100%";
      // Define a width do container
      container.style.width = width;

      // Define a width do wrapper
      wrapper.style.width = width;

      // Se tem footer e não for mobile
      if (!this.hideFooter && !this.$isMobile) {
        // Define a width do footer
        footer.style.width = width;
      }

      this.autofocus();
    });
  },
  /**
   * Observer
   */
  watch: {
    // Quando os itens mudarem
    items(value, oldValue) {
      // Se não forem arrays, retorna
      if (!isArray(value) || !isArray(oldValue)) return;

      // casi estão com tamanho igual, retorna
      // if (value.length === oldValue.length) return;

      // Caso contrário, ativa a célula selecionada
      this.setActiveCell(null, null, true);
    },
    loading: {
      handler(newValue, oldValue) {
        if (!newValue && oldValue) {
          // Caso contrário, ativa a célula selecionada
          this.setActiveCell(null, null, true);
        }
      },
    },
    // value(value, oldValue) {
    //   if (value.id !== oldValue.id || value.index !== oldValue.index)
    //     this.updateActiveItem();
    // },
    // this.$nextTick(() => {
    // const index = findIndex(
    //   this.items_,
    //   (item) => item.id === this.value.id
    // );
    // if (index === -1) {
    //   const coluna = this.value.coluna || 0;
    //   const item = this.items_[0] ?? {};
    //   this.setActiveCell({
    //     coluna,
    //     // id: item && item.id,
    //     index: 0,
    //     text: this.text(item && item.id, coluna),
    //   });
    // }
    // this.$nextTick(() => {
    //   const { wrapper } = this.$refs;
    //   if (!wrapper) return;
    //   const visibleItems =
    //     wrapper.clientHeight / this.cellHeight - 1;
    //   wrapper.scrollTop =
    //     this.cellHeight * index -
    //     parseInt(visibleItems / 2) * this.cellHeight;
    // });
    // });
    // }
    // },
    // },
  },
};
</script>

<style scoped>
div.s-datatable-wrapper {
  /* overflow: scroll; */
  overflow: auto;
  border: 1px solid var(--v-tableborder-base) !important;
  border-radius: 4px 4px 0px 0px;
}
div.s-datatable-wrapper:not(.footer) {
  height: calc(100%);
}
div.s-datatable-wrapper.footer {
  height: calc(100% - 28px);
}
table.s-datatable {
  /* border-collapse: collapse; */
  border-spacing: unset;
}
table.s-datatable.fixed {
  table-layout: fixed;
  width: 100%;
}
div.s-datatable-footer {
  border-bottom: 1px solid var(--v-tableborder-base) !important;
  border-right: 1px solid var(--v-tableborder-base) !important;
  border-left: 1px solid var(--v-tableborder-base) !important;
  border-radius: 0px 0px 4px 4px;
  width: 100%;
  height: 28px;
  font-size: 0.8rem;
  line-height: 28px;
}
.splash {
  position: relative;
  z-index: 10;
  top: 50%;
}
</style>
