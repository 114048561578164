<template>
  <v-stepper
    rounded
    non-linear
    elevation="0"
    min-height="35px"
    height="35px"
    :value="value"
    @change="onSelectStep"
  >
    <v-stepper-header
      style="height: 35px; min-height: 35px"
      class="pb-1 pb-md-0"
    >
      <template v-for="(step, i) in items">
        <v-stepper-step
          edit-icon="mdi-check"
          class="py-1 px-5"
          :key="i"
          :step="step.item"
          :editable="!isDisabled(step)"
          :complete="value > step.item"
        >
          {{ title(step) }}
        </v-stepper-step>
        <v-divider
          class="mx-4"
          v-if="i < items.length - 1"
          :key="`divider-${i}`"
        />
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import { find, get, head, isFunction } from "lodash";

export default {
  beforeMount() {
    this.$emit("input", head(this.items));
  },
  props: {
    value: {},
    disabled: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    __ficha: {},
  },
  computed: {
    /**
     * Função que retorna os dados da ficha
     */
    data() {
      return get(this.__ficha, "data") ?? {};
    },
    /**
     * Função que retorna os extras da ficha
     */
    extra() {
      return get(this.__ficha, "extra") ?? {};
    },
  },
  methods: {
    /**
     * Função que captura o title
     */
    title(step) {
      return isFunction(step.title)
        ? step.title(this.$store.state)
        : step.title;
    },
    /**
     * Função que retorna se a tab está desabilitada
     */
    isDisabled(tab) {
      return (
        this.disabled ||
        (tab.disabled &&
          tab.disabled.bind(this)({ data: this.data, extra: this.extra }))
      );
    },
    onSelectStep(value) {
      const item = find(this.items, (item) => item.item === value);

      if (this.value === item.item) return this.onSelectSameStep(item);
      this.$emit("input", item);
    },
    onSelectSameStep(item) {
      this.$emit("reselected", item);
    },
  },
};
</script>

<style lang="scss">
.theme--dark.v-stepper {
  background: #1e1e1e !important;
}
</style>
