<template>
  <tabs-items @loaded-tab-series="onDependenceTabLoaded" v-model="value">
    <template #tab-principal>
      <v-col lg="10" offset-lg="1">
        <v-row>
          <p-select-filter
            guard
            value-not-null
            md="4"
            modulo="sistema"
            name="nfse_ambiente_emissao"
            label="Ambiente"
            :disabled="isFichaDisabled"
            :items="environments"
            v-model="$config.nfse_ambiente_emissao"
          />
        </v-row>
        <v-row> </v-row>
        <v-row>
          <p-switch
            cols="12"
            modulo="sistema"
            name="nfse_envio_email"
            label="Enviar NFS-e por e-mail automaticamente"
            v-model="$config.nfse_envio_email"
          />
        </v-row>
      </v-col>
    </template>

    <template #tab-autenticacao>
      <v-col cols="12" lg="10" offset-lg="1">
        <v-row>
          <v-col cols="12" md="6" class="px-1" style="height: 250px">
            <p-text-field
              modulo="sistema"
              name="nfse_login"
              label="Login"
              v-model="$config.nfse_login"
            />
            <password
              name="palavra_passe_email"
              save-as="nfse_senha"
              modulo="sistema"
              label="Senha"
            />
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-series>
      <v-col cols="12" lg="10" offset-lg="1">
        <v-row>
          <v-col cols="12" class="px-1" style="height: 250px">
            <document-sequences
              modulo="nfse-series"
              :items="tabSeriesValues"
              :__ficha="__ficha"
              :value="selected"
              @input="setSelected"
            />
          </v-col>
        </v-row>
      </v-col>
    </template>
  </tabs-items>
</template>

<script>
import {
  TextField,
  PTextField,
  Switch as SSwitch,
  PSwitch,
  TextArea,
  PSelectFilter,
  SelectFilter,
  Btn,
  ContainerContent,
  Password,
} from "@/components/form";

import DataTable from "@/components/table/DataTable.vue";

import { TabsItems, ImageNfe } from "@/components/utils";
import DocumentSequences from "@/components/form/combos/DocumentSequences.vue";

// Mixins
import Ficha from "@/mixins/ficha";

export default Ficha.extend({
  components: {
    Btn,
    PSwitch,
    TextField,
    PTextField,
    TabsItems,
    DataTable,
    SSwitch,
    TextArea,
    PSelectFilter,
    SelectFilter,
    ImageNfe,
    ContainerContent,
    DocumentSequences,
    Password,
  },
  computed: {
    /**
     * Valores interpretados da tabela e arranjados para exibição
     */
    tabSeriesValues() {
      return this.data.series ?? [];
    },
    /**
     * Ambientes de emissão de NF-e
     */
    environments() {
      return [
        { text: "Homologação", value: 2 },
        { text: "Produção", value: 1 },
      ];
    },
  },
  methods: {
    /**
     * Função que faz a leitura das dependências
     */
    onDependenceTabLoaded(dependence) {
      if (dependence === "tab-series") {
        this.setSelected({ coluna: 2 });
      }

      this.__ficha.readDependence({
        dependence,
      });
    },
  },
});
</script>
