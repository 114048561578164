<template>
  <table class="coupon-table">
    <thead>
      <tr>
        <th
          v-for="(header, i) in headers"
          :key="i"
          v-bind="header"
          style="font-weight: bold !important"
        >
          {{ header.text }}
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(item, i) in items">
        <tr
          :key="i"
          :class="{
            'has-discount': clearCurrency(item.vlr_desconto) && item.ativo,
          }"
        >
          <td v-for="(header, j) in headers" :key="j" :class="header.class">
            <v-hover
              disabled
              v-slot="{ hover }"
              v-if="header.value === 'index'"
            >
              <template>
                <btn icon :color="hover ? 'error' : 'actionbutton'">
                  <v-icon small v-if="hover"> mdi-close </v-icon>
                  <template v-else>
                    {{ (i + 1).toString().padStart(3, "0") }}
                  </template>
                </btn>
              </template>
            </v-hover>

            <template v-else>
              {{
                typeof header.value === "function"
                  ? header.value(item, i)
                  : item[header.value]
              }}
            </template>
          </td>
        </tr>

        <tr
          :key="`discount-${i}`"
          class="discount"
          v-if="clearCurrency(item.vlr_desconto) && item.ativo"
        >
          <td class="font-weight-medium" :colspan="headers.length">
            <small>Desconto -{{ item.vlr_desconto }}</small>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { clearCurrency } from "@/utils";
import Btn from "../buttons/Btn.vue";

export default {
  components: { Btn },
  props: {
    items: { type: Array, default: () => [] },
    headers: { type: Array, default: () => [] },
  },
  methods: {
    clearCurrency,
  },
};
</script>

<style lang="scss">
.coupon-table {
  width: 100%;

  border-collapse: collapse;

  $padding: 6px;

  thead tr th {
    &:not(:first-child) {
      padding-left: $padding;
    }
    &:not(:last-child) {
      padding-right: $padding;
    }

    border-bottom: thin solid var(--v-tableborder-base);
    padding-bottom: $padding;
    background-color: var(--v-background-base);
    overflow: hidden;
    white-space: nowrap;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    z-index: 1;
    top: 0;
  }

  tbody tr {
    td {
      &:not(:first-child) {
        padding-left: $padding;
      }
      &:not(:last-child) {
        padding-right: $padding;
      }
    }

    &:not(.discount) td {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    &:not(:last-child):not(.has-discount) {
      border-bottom: thin solid var(--v-tableborder-base);
    }
  }
}
</style>
