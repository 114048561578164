<template>
  <v-dialog
    eager
    ref="dialog"
    scrollable
    no-click-animation
    :persistent="persistent"
    :content-class="`s-dialog s-dialog-${_uid} ${noShadow ? 'no-shadow' : ''} ${
      flexEnd ? 'flex-end' : ''
    }`"
    :fullscreen="fullscreen"
    :width="$isMobile && width !== '100vw' ? '90vw' : width"
    :value="value"
    @click:outside="onClickOutside"
    @keydown.esc="onClickOutside"
    @input="close"
    v-bind="$attrs"
  >
    <slot name="prepend" />
    <v-card
      outlined
      max-height="100vh"
      :height="$isMobile && height === 'auto' ? 'auto' : height"
      :min-width="minWidth"
    >
      <slot name="title" v-if="!hideTitle">
        <v-card-title class="py-2 px-3" :class="{ movable }" ref="title">
          <v-col class="pa-0" cols="10" sm="11" md="9">
            <div class="text-truncate text-subtitle-2 text-sm-h6">
              <template v-if="title">{{ title }}</template>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="pa-0" cols="auto" v-if="!hideCloseButton">
            <slot name="title-actions">
              <close-button btn-class="mt-0" @close="close" />
            </slot>
          </v-col>
        </v-card-title>
      </slot>
      <v-card-text
        ref="cardText"
        :style="`height: auto; max-height: ${
          fullscreen ? '100vh' : '90vh'
        } !important;`"
        class="pa-2 overflow-y-auto"
        :class="{ 'dark-background': dark }"
      >
        <slot
          name="text"
          :height="() => $refs && $refs.cardText && $refs.cardText.clientHeight"
        >
          {{ text }}
        </slot>
      </v-card-text>
      <slot name="actions" v-if="!hideActions">
        <v-card-actions class="px-4 py-2">
          <slot name="action-prepend" />
          <slot name="action-content">
            <v-spacer></v-spacer>
            <btn
              outlined
              color="actionbutton"
              btn-class="focus-priority"
              @click="
                close();
                $emit('ok', $event);
              "
              >{{ okText }}</btn
            >
          </slot>
        </v-card-actions>
      </slot>
      <slot name="overlay" />
      <slot name="extra" />
    </v-card>
  </v-dialog>
</template>

<script>
import Btn from "../form/buttons/Btn.vue";
import CloseButton from "../form/buttons/CloseButton.vue";

export default {
  props: {
    fullscreen: { type: Boolean, default: false },
    movable: { type: Boolean, default: false },
    hideCloseButton: { type: Boolean, default: false },
    value: { type: Boolean, default: true },
    title: { type: String },
    text: {},
    okText: { type: String, default: "Ok" },
    dark: { type: Boolean },
    flexEnd: { type: Boolean },
    noShadow: { type: Boolean },
    hideActions: { type: Boolean },
    hideTitle: { type: Boolean },
    width: { type: String, default: "initial" },
    minWidth: { type: String, default: "initial" },
    height: { type: String, default: "auto" },
    persistent: { type: Boolean, default: false },
  },
  data: () => ({
    d: {},
  }),
  components: { CloseButton, Btn },
  methods: {
    close() {
      this.$emit("input", false);
      this.$emit("close");
    },
    onClickOutside(event) {
      if (!this.persistent) this.close();
      this.$emit("click-outside", event);
    },
    onMouseDown(event) {
      const dialog = document.querySelector(`.s-dialog-${this._uid}`);
      const permit =
        !event.target.className.includes("v-btn") &&
        !event.target.className.includes("v-icon");

      if (event.button === 0 && dialog && permit) {
        this.d.el = dialog;
        this.d.mouseStartX = event.clientX;
        this.d.mouseStartY = event.clientY;
        this.d.elStartX = this.d.el.getBoundingClientRect().left;
        this.d.elStartY = this.d.el.getBoundingClientRect().top;
        this.d.el.style.position = "fixed";
        this.d.el.style.margin = 0;
        this.d.oldTransition = this.d.el.style.transition;
        this.d.el.style.transition = "none";
      }
    },
    onMouseMove(event) {
      if (this.d.el === undefined) return;

      this.d.el.style.left =
        Math.min(
          Math.max(this.d.elStartX + event.clientX - this.d.mouseStartX, 0),
          window.innerWidth - this.d.el.getBoundingClientRect().width
        ) + "px";

      this.d.el.style.top =
        Math.min(
          Math.max(this.d.elStartY + event.clientY - this.d.mouseStartY, 0),
          window.innerHeight - this.d.el.getBoundingClientRect().height
        ) + "px";
    },
    onMouseUp() {
      if (this.d.el === undefined) return;
      this.d.el.style.transition = this.d.oldTransition;
      this.d.el = undefined;
    },
  },
  mounted() {
    const title = this.$refs.title;
    if (!title || !this.movable) return;

    title.addEventListener("mousedown", this.onMouseDown);
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("mouseup", this.onMouseUp);
  },
  beforeDestroy() {
    const title = this.$refs.title;
    if (!title || !this.movable) return;

    title.removeEventListener("mousedown", this.onMouseDown);
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("mouseup", this.onMouseUp);
  },
};
</script>

<style lang="scss">
.s-dialog {
  &.no-shadow:not(.v-dialog--fullscreen) {
    box-shadow: none !important;
  }
  &.flex-end:not(.v-dialog--fullscreen) {
    margin-bottom: 4px !important;
    align-self: flex-end !important;
  }

  .v-card__title.movable {
    user-select: none;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
}

.v-dialog {
  &:not(.v-dialog--fullscreen) {
    max-height: none !important;
  }
  &:not(.v-dialog--fullscreen) {
    margin: 4px !important;
  }
}
</style>

<style scoped>
.s-dialog {
  min-width: calc(100vw / 3);
}

.dark-background {
  background-color: #282a36;
}
</style>
