<template>
  <v-col cols="12" md="8" offset-md="2" class="my-auto">
    <v-card flat class="background">
      <v-card-title>
        <v-img
          contain
          width="160"
          height="160"
          :src="require('@/assets/logo_smallsaas.svg')"
        />
      </v-card-title>
      <v-card-text class="text-body-1 text-center">
        <slot />
      </v-card-text>
      <v-card-actions>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
export default {};
</script>
