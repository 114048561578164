<template>
  <div>
    <v-badge overlap bordered color="success" :offset-x="25" :value="false">
      <template #badge> <div class="mx-auto">3</div> </template>
      <a
        class="s-menu-item-container"
        :href="
          actions
            ? null
            : !item.route
            ? `/${item.id}`
            : $routeResolver(item.route, false)
        "
        :class="{
          ...item.class,
          'bottom-navigation': bottomNavigation,
          'disabled-button': disabled,
          active: $route.params.modulo === item.id || $route.name === item.id,
        }"
        @contextmenu="
          (event) =>
            item.context
              ? (event.preventDefault(), $refs.menu.open(event))
              : null
        "
        @click.prevent="
          (event) =>
            event.ctrlKey && item.route
              ? $routeResolver(item.route)
              : !disabled && $emit('click', event)
        "
      >
        <template v-if="item.icon">
          <div class="s-image-container">
            <v-img class="item-icon mx-auto" contain :src="item.icon" />
          </div>
        </template>
        <template v-else>
          <div class="icon-wrapper">
            <div class="icon" />
          </div>
        </template>
        <span class="menu-label-font">{{ item.title }}</span>
      </a>
    </v-badge>

    <context-menu
      ref="menu"
      :context="item.context"
      @click-item="onContextClick"
    />
  </div>
</template>

<script>
import ContextMenu from "../popups/ContextMenu.vue";

export default {
  components: { ContextMenu },
  props: {
    item: { type: Object, required: true },
    actions: { type: Boolean, default: false },
    link: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },

    bottomNavigation: { type: Boolean, default: false },
  },
  methods: {
    onContextClick(item) {
      return item.onClick && item.onClick.bind(this)();
    },
  },
};
</script>

<style lang="scss" scoped>
.s-menu-item-container {
  display: inline-block;
  // color: #000000 !important;
  // border: solid thin black;
  color: var(--v-text-base) !important;
  margin-right: 4.5px;
  text-align: center;
  line-height: 1;

  border-radius: 5px;
  filter: brightness(105%);

  &:not(.disabled-button).active,
  &:not(.disabled-button).active .menu-label-font {
    font-weight: bold;
    text-decoration: underline;
  }

  &:not(.disabled-button):hover {
    font-weight: bold;
    filter: brightness(90%);
  }

  & span {
    display: inline-block;
  }

  &.disabled-button,
  &.disabled-button .menu-label-font {
    // color: rgba(0, 0, 0, 0.38);
    color: var(--v-text-lighten4);
    cursor: initial;
    -webkit-user-select: none;
    user-select: none;
    transition: all 0s ease-in-out;
    opacity: 0.6;
  }

  &:not(.disabled-button) {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    transition: all 0s ease-in-out;
  }

  .s-image-container {
    padding: 0px !important;
    margin: 0px !important;
  }

  .menu-label-font {
    // color: #000000;
    color: var(--v-text-base);
    padding: 0px !important;
    margin: 0px !important;
    line-height: 1.2 !important;
  }

  &.bottom-navigation {
    width: 76px !important;
    height: 96px !important;
    .item-icon {
      width: 59px;
      height: 59px;
    }

    .menu-label-font {
      font-size: 11.5px;
    }
  }

  &:not(.bottom-navigation) {
    @media (max-width: 349.98px) {
      width: 35px !important;

      .item-icon {
        width: 30px;
        height: 30px;
      }

      .menu-label-font {
        display: none !important;
        font-size: 6.6px;
      }
    }

    @media (min-width: 350px) and (max-width: 599.98px) {
      width: 40px !important;

      .item-icon {
        width: 35px;
        height: 35px;
      }

      .menu-label-font {
        display: none !important;
        font-size: 7.1px;
      }
    }

    @media (min-width: 600px) and (max-width: 959.98px) {
      width: 50px !important ;

      .item-icon {
        width: 38px;
        height: 38px;
      }

      .menu-label-font {
        font-size: 9px;
      }
    }

    @media (min-width: 960px) {
      width: 60px !important;

      .item-icon {
        width: 46px;
        height: 46px;
      }

      .menu-label-font {
        font-size: 10.5px;
      }
    }
  }
}
</style>
