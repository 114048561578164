<template>
  <image-cropper
    name="principal"
    :width="300"
    :height="300"
    :modulo="modulo"
    :filename="filename"
    :__ficha="__ficha"
    v-bind="{ ...$attrs }"
  />
</template>

<script>
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import ImageCropper from "./ImageCropper.vue";

export default {
  props: {
    modulo: {},
    __ficha: {},
  },
  components: {
    ImageCropper,
  },
  computed: {
    /**
     * Retorna a imagem baseada no config
     */
    filename() {
      const registro = get(this.__ficha, "data") ?? {};

      // Se houver galeria
      return !isEmpty(registro.galeria)
        ? // Retorna o caminho completo do arquivo
          `/${this.modulo}/${registro.id}/${registro.galeria.principal}`
        : // Caso contrário, retorna null
          null;
    },
  },
};
</script>
