<template>
  <tabs-items v-model="value">
    <template #tab-principal>
      <v-col class="px-0" lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row class="mx-0">
          <v-col cols="12" md="6">
            <v-row>
              <p-text-field
                auto-focus
                sm="10"
                md="12"
                name="descricao"
                label="Natureza da operação"
                maxlength="40"
                v-model="data.descricao"
              />
              <p-text-field
                sm="2"
                cols="3"
                type="tel"
                name="cfop"
                label="CFOP"
                v-mask="'####'"
                v-model="data.cfop"
              />
              <p-select-filter
                ignore-enter-focus
                cols="9"
                sm="9"
                md="7"
                name="plano_conta_id"
                label="Plano de contas"
                item-value="id"
                :item-texts="['conta', 'descricao']"
                :items="extra.plano_contas"
                v-model="data.plano_conta_id"
              />
              <p-text-field
                cols="3"
                name="st"
                label="CIT"
                v-mask="'XXX'"
                v-model="data.st"
              />
            </v-row>
            <v-row>
              <p-text-area
                cols="12"
                ignore-enter-focus
                maxlength="250"
                name="obs"
                label="Observação"
                v-model="data.obs"
              />
            </v-row>
          </v-col>
          <v-col cols="12" md="6" class="pt-0 pt-md-1">
            <v-row>
              <v-col class="px-0 pt-2 pb-1">
                <container-content title="ICMS / ICMS ST">
                  <v-row>
                    <p-select-filter
                      show-value-text
                      md="9"
                      label="CSOSN ICMS"
                      name="icms.csosn"
                      :items="extra__.csosn"
                      v-model="data.icms.csosn"
                      v-if="$emitente && $emitente.crt == '1'"
                    />
                    <p-select-filter
                      show-value-text
                      md="9"
                      label="CST ICMS"
                      name="icms.cst"
                      :items="extra__.cst"
                      v-model="data.icms.cst"
                      v-else
                    />
                    <p-precision-field
                      sm="4"
                      md="3"
                      cols="6"
                      suffix="%"
                      name="icms.pBC"
                      label="Base ICMS"
                      v-model="data.icms.pBC"
                    />

                    <p-precision-field
                      cols="6"
                      suffix="%"
                      name="icms.pICMSST"
                      label="% ICMS ST"
                      v-model="data.icms.pICMSST"
                    />
                  </v-row>
                </container-content>

                <container-content title="ISSQN">
                  <v-row>
                    <p-precision-field
                      ignore-enter-focus
                      sm="4"
                      md="6"
                      cols="6"
                      suffix="%"
                      name="issqn.pBC"
                      label="Base ISS"
                      v-model="data.issqn.pBC"
                    />
                    <p-precision-field
                      ignore-enter-focus
                      sm="4"
                      md="6"
                      cols="6"
                      suffix="%"
                      name="issqn.vAliq"
                      label="Alíquota ISS"
                      v-model="data.issqn.vAliq"
                    />
                  </v-row>
                </container-content>

                <container-content title="PIS / COFINS">
                  <v-row>
                    <p-select-filter
                      show-value-text
                      md="6"
                      label="CST PIS"
                      name="pis.CST"
                      :items="
                        isCfopEntrada
                          ? extra__.cst_pis_cofins_entrada
                          : extra__.cst_pis_cofins_saida
                      "
                      v-model="data.pis.CST"
                    />
                    <p-select-filter
                      show-value-text
                      md="6"
                      label="CST COFINS"
                      name="cofins.CST"
                      :items="
                        isCfopEntrada
                          ? extra__.cst_pis_cofins_entrada
                          : extra__.cst_pis_cofins_saida
                      "
                      v-model="data.cofins.CST"
                    />
                    <p-precision-field
                      ignore-enter-focus
                      md="6"
                      suffix="%"
                      name="pis.pPIS"
                      label="Alíquota PIS"
                      v-model="data.pis.pPIS"
                    />
                    <p-precision-field
                      ignore-enter-focus
                      md="6"
                      suffix="%"
                      name="cofins.pCOFINS"
                      label="Alíquota COFINS"
                      v-model="data.cofins.pCOFINS"
                    />
                  </v-row>
                </container-content>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-icms>
      <v-col class="px-0" lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row class="mx-0 pa-0">
          <v-col cols="auto" class="pa-0 mb-1 mt-n2">
            <s-switch
              label="Ocultar Mapa"
              v-if="!$isMobile"
              v-model="hideMap"
            />
          </v-col>
        </v-row>

        <v-row class="mx-0 mt-1">
          <v-col cols="auto" class="py-0 mx-auto">
            <brazil-map
              class="mx-auto"
              @change="onUFPerChanged"
              @contextmenu="onContextMenu"
              :aliquotas="data.per_icms ?? {}"
              v-if="!hideMap && !$isMobile"
            />
          </v-col>
        </v-row>

        <v-row class="mx-0" v-if="hideMap || $isMobile">
          <p-precision-field
            suffix="%"
            cols="6"
            sm="4"
            md="3"
            lg="2"
            maxlength="8"
            :key="i"
            :context="context"
            :auto-focus="i === 0"
            :color="uf(aliquota.text) && 'error--text'"
            :label="aliquota.text"
            :name="`per_icms.${aliquota.value}`"
            @contextmenu.native="
              (event) =>
                onContextMenu(event, {
                  text: aliquota.text,
                  value: data.per_icms[aliquota.value],
                })
            "
            v-for="(aliquota, i) in aliquotas"
            v-model="data.per_icms[aliquota.value]"
          />
        </v-row>

        <context-menu
          ref="menu"
          :context="context"
          @click-item="(item) => onContextItemSelected(item)"
        />
      </v-col>
    </template>

    <template #tab-preferencias>
      <v-col class="px-0" lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row class="mx-0">
          <v-col cols="12" md="6">
            <container-content title="Impostos sobre Impostos" v-if="!disabled">
              <v-row>
                <template>
                  <p-switch
                    ignore-enter-focus
                    sm="6"
                    class="my-auto"
                    cols="12"
                    v-model="data.preferencias.icms_sobre_ipi"
                    name="preferencias.icms_sobre_ipi"
                    label="ICMS sobre IPI"
                  />
                  <p-switch
                    ignore-enter-focus
                    sm="6"
                    class="my-auto"
                    cols="12"
                    v-model="data.preferencias.icms_st_sobre_ipi"
                    name="preferencias.icms_st_sobre_ipi"
                    label="ICMS ST sobre IPI"
                  />
                  <p-switch
                    ignore-enter-focus
                    sm="6"
                    class="my-auto"
                    cols="12"
                    v-model="data.preferencias.icms_sobre_outras"
                    name="preferencias.icms_sobre_outras"
                    label="ICMS sobre OUTRAS"
                  />
                  <p-switch
                    ignore-enter-focus
                    sm="6"
                    class="my-auto"
                    cols="12"
                    v-model="data.preferencias.ipi_sobre_frete"
                    name="preferencias.ipi_sobre_frete"
                    label="IPI sobre Frete"
                  />
                </template>
              </v-row>
            </container-content>

            <container-content title="Habilitar Impostos">
              <v-row>
                <p-switch
                  ignore-enter-focus
                  sm="6"
                  cols="12"
                  class="my-auto"
                  v-model="data.preferencias.calcular_ipi"
                  name="preferencias.calcular_ipi"
                  label="Calcular IPI"
                />
                <p-switch
                  ignore-enter-focus
                  cols="12"
                  class="my-auto"
                  v-model="data.preferencias.descontar_icms_desonerado"
                  name="preferencias.descontar_icms_desonerado"
                  label="Descontar ICMS Desonerado no Total"
                />
              </v-row>
            </container-content>

            <container-content title="Movimentação">
              <v-row>
                <p-switch
                  cols="12"
                  class="my-auto"
                  name="preferencias.aplicar_vlr_custo"
                  label="Venda com preço de custo ( 0 )"
                  v-if="!isCfopEntrada"
                  v-model="data.preferencias.aplicar_vlr_custo"
                />
                <p-switch
                  cols="12"
                  class="my-auto"
                  name="preferencias.calcular_custo_compra"
                  label="Calcular Custo de Compra"
                  v-if="isCfopEntrada"
                  v-model="data.preferencias.calcular_custo_compra"
                />
                <p-switch
                  cols="12"
                  class="my-auto"
                  name="preferencias.alterar_quantidade_estoque"
                  :label="`${
                    isCfopEntrada ? 'Compra' : 'Venda'
                  } com movimentação ( = )`"
                  v-model="data.preferencias.alterar_quantidade_estoque"
                />
              </v-row>
            </container-content>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-tags>
      <v-col class="px-0" lg="10" xl="8" offset-lg="1" offset-xl="2">
        <div :style="`height: ${height() - 38}px; width: 100%;`">
          <v-row class="mb-0 ml-1">
            Tags da natureza de operação (altere os valores da coluna VALOR)
          </v-row>

          <tag-list-json
            :disabled="isFichaDisabled"
            :__ficha="__ficha"
            :base="tagsTabValues"
            :headers="tagsTabHeaders"
            :height="height() - 48"
            v-model="data.tags"
          />
        </div>
      </v-col>
    </template>
  </tabs-items>
</template>

<script>
import { mask } from "vue-the-new-mask";
import { each, get, set } from "lodash";

import {
  tagsTabValues,
  tagsTabHeaders,
} from "@/config/modulos/naturezas-operacao";

import { extra } from "@/config/modulos/estoque";
import { TagListJson, ContainerContent } from "@/components/form";

import { TabsItems } from "@/components/utils";
import BrazilMap from "@/components/utils/BrazilMap.vue";

import {
  PTextField,
  PTextArea,
  PPrecisionField,
  PSelectFilter,
  Switch as SSwitch,
  PSwitch,
} from "@/components/form";

import ContextMenu from "@/components/popups/ContextMenu.vue";

// Mixins
import Ficha from "@/mixins/ficha";
import ufs from "@/mapping/ufs";
import { clearCurrency } from "@/utils";

export default Ficha.extend({
  directives: { mask },
  components: {
    TabsItems,
    SSwitch,
    PTextField,
    PTextArea,
    PPrecisionField,
    PSelectFilter,
    PSwitch,
    BrazilMap,
    ContainerContent,
    TagListJson,
    ContextMenu,
  },
  data: () => ({
    tagsTabValues,
    tagsTabHeaders,
    hideMap: false,
    selectedAliquota: "",
  }),
  methods: {
    onUFPerChanged(data) {
      data = {
        per_icms: {
          ...(this.data.per_icms ?? {}),
          ...data,
        },
      };

      this.__ficha.append(data);
      this.__ficha.onChangePersistent({
        data,
      });
    },
    uf(uf) {
      return this.$emitente.uf === uf;
    },
    onContextMenu(event, selected) {
      event.preventDefault();

      this.selectedAliquota = selected;
      this.$nextTick(() => {
        this.$refs.menu.open(event);
      });
    },
    updateMassPer(newValue, empty) {
      newValue = clearCurrency(newValue);

      each(this.aliquotas, (uf) => {
        if (!get(this.data, `per_icms.${uf.value}`) || !empty) {
          set(this.data.per_icms, uf.value, newValue);
        }
      });

      this.__ficha.onChangePersistent({
        data: { per_icms: this.data.per_icms },
        modulo: "naturezas-operacao",
      });
    },
    onContextItemSelected(item) {
      const aliquota = item.aliquota ?? {};
      const value = aliquota.value ?? "";

      if (item.id === "replicar") {
        this.__ficha.__modulo.$alert({
          actions: true,
          closeOnOk: true,
          text: true,
          movable: true,
          src: require("@/assets/menu/modulos/config.svg"),

          title: "Atenção",
          message: `Você deseja replicar a alíquota <strong>${value}%</strong> de <strong>${aliquota.text}</strong> para TODAS as UFs?`,
          on: { ok: () => this.updateMassPer(value, false) },
        });
      }

      if (item.id === "replicar-empty") {
        this.__ficha.__modulo.$alert({
          actions: true,
          closeOnOk: true,
          text: true,
          movable: true,
          src: require("@/assets/menu/modulos/config.svg"),

          title: "Atenção",
          message: `Você deseja replicar a alíquota <strong>${value}%</strong> de <strong>${aliquota.text}</strong> para TODAS as UFs que estão <strong>VAZIAS</strong>?`,
          on: { ok: () => this.updateMassPer(value, true) },
        });
      }
    },
  },
  computed: {
    extra__() {
      return extra;
    },
    disabled() {
      return (
        !this.data.cfop ||
        (!this.data.cfop.startsWith("5") && !this.data.cfop.startsWith("6"))
      );
    },
    cfop() {
      return this.data.cfop ?? "";
    },
    isCfopSaida() {
      return (
        this.cfop.startsWith("5") ||
        this.cfop.startsWith("6") ||
        this.cfop.startsWith("7")
      );
    },
    isCfopEntrada() {
      return (
        this.cfop.startsWith("1") ||
        this.cfop.startsWith("2") ||
        this.cfop.startsWith("3")
      );
    },
    context() {
      const aliquota = this.selectedAliquota ?? {};
      const aliq = get(aliquota, "value", "alíquota");

      return [
        {
          id: "replicar",
          title: `Replicar ${aliq} para Todos`,
          icon: "mdi-reply-all",
          aliquota,
        },
        {
          id: "replicar-empty",
          title: `Replicar ${aliq} para os Vazios`,
          icon: "mdi-reply",
          aliquota,
        },
      ];
    },
    aliquotas() {
      return ufs;
    },
  },
});
</script>
