
import get from "lodash/get";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";

import MainMixin from "@/mixin";
import DescontoAcrescimo from "@/components/popups/modulos/cupons-fiscais/DescontoAcrescimo.vue";
import InformarCliente from "@/components/popups/modulos/cupons-fiscais/InformarCliente.vue";

import Modal from "@/components/utils/Modal.vue";
import {
  Btn,
  TextField,
  PrecisionField,
  SelectFilter,
} from "@/components/form";

import { CODIGOS_PAGAMENTO_PRAZO } from "@/mapping/constants";
import { clearCurrency, setCurrency } from "@/utils";

import Parcelas from "@/components/form/combos/Parcelas.vue";
import actions from "@/store/actions";

const STEPS = {
  select: "select",

  troco: "troco",
  cartao: "cartao",
  prazo: "prazo",

  outros: "outros",
  default: "default",
};

const CODES = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
  "CTRL+1",
  "CTRL+2",
  "CTRL+3",
  "CTRL+4",
  "CTRL+5",
  "CTRL+6",
  "CTRL+7",
  "CTRL+8",
  "CTRL+9",
  "CTRL+0",
];

const initialDetalhes = {
  conta_bancaria_id: null,
  parcelas: null,
  justificativa: null,
  cAut: null,
};
const initialPagamento = {
  valor: "",
  bandeira_id: null,
  vlr_troco: null,
  vlr_parcial: null,
  parcial: false,
  parcelas: [],
};

export default MainMixin.extend({
  components: {
    Btn,
    Modal,
    Parcelas,
    TextField,
    SelectFilter,
    PrecisionField,
    InformarCliente,
    DescontoAcrescimo,
  },
  props: {
    __ficha: { type: Object },

    formas: { type: Array, default: () => [] as any[] },
    // bandeiras: { type: Array, default: () => [] as any[] },
    onFinish: { type: Function },
    onBeforeConfirm: { type: Function },

    title: { type: String },

    initialStep: { type: String, default: STEPS.select },
    initialPagamento: { type: Object },
    initialCodigoPagamento: {},
    persistent: { type: Boolean, default: false },
    preventFinish: { type: Boolean, default: false },
  },
  data: () => ({
    STEPS,
    step: STEPS.select,
    selected: -1,
    height: "auto",
    editMode: false,

    pagamento: {
      ...initialPagamento,
      detalhes: {
        ...initialDetalhes,
      },
    } as any,

    loading: "",
    isDiscountActive: false,
    isInsertClientActive: false,

    bandeiras: [],
    contas: [],
  }),
  created() {
    this.init();
    this.loadMissingDependencies();
  },
  mounted() {
    document.addEventListener("keydown", this.onKeyDown);

    this.$nextTick(() => {
      const height = get(this.$refs, "modal.$refs.cardText.clientHeight");
      this.height = height ? `${height}px` : "auto";
    });
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  methods: {
    clearCurrency,
    onClose(): void {
      this.$emit("close");
    },
    onCloseDiscount() {
      this.pagamento.valor = this.data.vlr_pagamento_restante;
      this.isDiscountActive = false;
    },
    onCloseInsertClient() {
      this.isInsertClientActive = false;
    },
    init(initialPagamento: any = null, initialCodigoPagamento: any = null) {
      this.resetCurrentPayment();
      this.step = this.initialStep;

      initialCodigoPagamento ??= this.initialCodigoPagamento;
      if (initialCodigoPagamento) {
        this.onSelect(
          this.methods.findIndex((it) => it.codigo === initialCodigoPagamento),
          false
        );
      }

      initialPagamento ??= this.initialPagamento;
      if (initialPagamento) {
        this.pagamento = {
          ...this.pagamento,
          ...initialPagamento,
        };

        this.onSelect(
          this.methods.findIndex((it) => it.codigo === this.pagamento.codigo),
          false
        );
      }
    },
    loadMissingDependencies: debounce(function (this: any) {
      if (this.loading) return;

      const bandeiras =
        this.initialCodigoPagamento === "03/04" || this.step === STEPS.cartao;

      const contas =
        this.initialCodigoPagamento === "15" ||
        (this.step === STEPS.prazo && this.current?.codigo === "15");

      const promises = [
        bandeiras && !this.bandeiras.length
          ? this.$store.dispatch(actions.CORE.READ, {
              path: "cadastro-bandeiras",
              type: "registros",
              ordered: true,
            })
          : null,

        contas && !this.contas.length
          ? this.$store.dispatch(actions.CORE.READ, {
              path: "contas-bancarias",
            })
          : null,
      ];

      if (!promises.filter((it) => it).length) return;

      this.loading = "dependencies";

      Promise.all(promises)
        .then(([bandeiras, contas]) => {
          this.bandeiras = get(bandeiras, "registros", []);
          this.contas = get(contas, "registros", []);
        })
        .finally(() => {
          this.loading = "";
        });
    }, 10),

    resetCurrentPayment() {
      this.editMode = false;
      this.pagamento = {
        ...initialPagamento,
        detalhes: {
          ...initialDetalhes,
        },
        valor: this.data.vlr_pagamento_restante,
      };
    },

    onKeyDown(event): void {
      if (this.step !== STEPS.select) return;
      if (this.isDiscountActive) return;

      const events = {
        ArrowDown: this.nextTile,
        ArrowUp: this.prevTile,
        Enter: () => this.onConfirmPayment(),
      };

      const _event = events[event.key];

      if (_event) {
        event.preventDefault();
        _event(event);
      } else {
        this.getMethodByShortcut(event);
      }
    },
    getMethodByShortcut(event): void {
      const shortcut = event.ctrlKey ? `CTRL+${event.key}` : event.key;
      const index = this.methods.findIndex(
        (_, i) => this.getShortcut(i) === shortcut
      );

      index >= 0 && event.preventDefault();

      return this.onSelect(index);
    },

    getShortcut(index?: number | string, payment: any = null): string | void {
      if (typeof index === "string") return index;
      index ??= this.methods.findIndex((it) => it.id === payment?.id);
      return this.codes[index];
    },
    onSelect(index, notify = true): void {
      if (index === undefined || index < 0 || index === null) {
        index = this.selected;
      }

      this.selected = index;

      this.onConfirmPayment(null, notify);
    },
    nextTile(): void {
      this.selected =
        this.selected + 1 >= this.methods.length ? 0 : this.selected + 1;
    },
    prevTile(): void {
      this.selected =
        this.selected - 1 < 0 ? this.methods.length - 1 : this.selected - 1;
    },
    reset() {
      const valor = clearCurrency(this.data.vlr_pagamento_restante);

      if (!this.editMode) {
        if (this.preventFinish || this.initialPagamento) {
          return this.onClose();
        }

        if (valor <= 0) {
          return this.onFinish?.();
        }
      }

      if (this.initialCodigoPagamento !== "03/04" && !this.editMode) {
        this.step = STEPS.select;
        this.selected = -1;
      } else {
        this.focusValueTextField();
      }

      this.resetCurrentPayment();
    },
    focusValueTextField() {
      (this.$refs?.valueTextField as HTMLInputElement)?.focus();
    },

    getPaymentType(payment) {
      const code = payment.codigo;
      if (["01", "02"].includes(code)) return STEPS.troco;
      if (code === "03/04") return STEPS.cartao;
      if (code === "99") return STEPS.outros;
      if (CODIGOS_PAGAMENTO_PRAZO.includes(code)) return STEPS.prazo;
      return STEPS.default;
    },

    onConfirmPayment(payment = null, notify = true): void {
      if (["F8"].includes(this.codes[this.selected])) {
        return this.handleDiscountAssistent();
      }

      if (["F4"].includes(this.codes[this.selected])) {
        return this.requestClientAssistent();
      }

      payment ||= this.current;

      if (!payment) return;

      if (
        notify &&
        typeof this.onBeforeConfirm === "function" &&
        this.onBeforeConfirm(payment) === false
      ) {
        return;
      }

      const type = this.getPaymentType(payment);
      type && (this.step = type);
    },
    onDeletePayment(id = null): Promise<any> | void {
      if (this.loading) return;
      this.loading = "deleting";

      const event = {
        modulo: "pagamentos",
        dependence: "pagamentos",
        document: this.__ficha.modulo,
        type: "delete",
        id: id ?? this.pagamento.id,
        options: {
          ignoreAfterDelete: true,
        },
      };

      return this.__ficha
        .handleDependence(event)
        .then(id ? this.resetCurrentPayment : this.reset)
        .finally(() => {
          this.loading = "";
        });
    },
    onEditPayment(payment): void {
      this.init(payment);
      this.editMode = true;
      this.$nextTick(this.focusValueTextField);
    },
    updateOrCreatePagamento(data = {}): Promise<any> | void {
      if (this.loading) return;

      if (!this.pagamento.pending && this.isPrazoPayment && isEmpty(data)) {
        return this.reset();
      }

      this.loading = "saving";

      data = {
        ...(data ?? {}),
        ...this.pagamento,
        meio_pagamento_id: this.current?.id,
      };

      const event = {
        modulo: "pagamentos",
        dependence: "pagamentos",
        document: this.__ficha.modulo,
        type: !this.pagamento?.id ? "create" : "update",
        id: this.pagamento.id,
        data,
        options: {
          ignoreAfterUpdate: true,
        },
      };

      return this.__ficha
        .handleDependence(event)
        .then((response) => {
          const registro = get(response, "data.registro");
          const parent = get(response, "data.parent");

          registro &&
            (this.pagamento = {
              ...this.pagamento,
              ...registro,
              pending: false,
            });

          parent && this.__ficha.append(parent);

          if (this.step !== STEPS.prazo) {
            this.reset();
          }
        })
        .finally(() => {
          this.loading = "";
        });
    },

    onValueChange() {
      if (!this.isPrazoPayment) {
        return;
      }

      const parcelas = parseInt(this.pagamento.detalhes.parcelas || 0);
      return this.updateOrCreatePagamento({
        parcelas,
      });
    },
    onParcelsBlur(): Promise<any> | void {
      const parcelas = parseInt(this.pagamento.detalhes.parcelas || 0);

      if (parcelas <= 0 || parcelas === this.pagamento.parcelas.length) {
        return;
      }

      return this.updateOrCreatePagamento({
        parcelas,
      });
    },
    checkChangeValue(): void {
      if (this.step !== STEPS.troco) return;
      if (this.initialPagamento && this.initialPagamento.id) return;

      const restante = clearCurrency(
        clearCurrency(this.pagamento.vlr_parcial) > 0
          ? this.pagamento.vlr_parcial
          : this.data.vlr_pagamento_restante
      );

      this.pagamento.vlr_troco = setCurrency(
        Math.max(clearCurrency(this.pagamento.valor) - restante, 0)
      );
    },

    handleDiscountAssistent() {
      this.requestDiscountAssistent();
      this.$nextTick(() => (this.selected = -1));
    },
    requestDiscountAssistent() {
      this.isDiscountActive = true;
    },
    requestClientAssistent() {
      this.isInsertClientActive = true;
    },
  },
  computed: {
    enablePartialPayment(): boolean {
      return (
        this.$config.frente_pagamento_parcial &&
        this.step === STEPS.troco &&
        this.modulo === "cupons-fiscais"
      );
    },
    computedTitle(): string {
      if (this.editMode) {
        return "Editar Pagamento";
      }

      return (
        this.title ??
        (this.modulo === "cupons-fiscais"
          ? "Assistente de Finalização"
          : "Inserir Forma de Pagamento")
      );
    },
    codes(): string[] {
      return (this.modulo === "cupons-fiscais" ? ["F8", "F4"] : []).concat(
        CODES
      );
    },
    allSamePayments(): any[] {
      return (this.data.pagamentos ?? []).filter(
        (it) => it.meio_pagamento_id === this.current?.id
      );
    },
    isFiscalDocument(): boolean {
      return !["orcamento", "ordem-servico"].includes(this.modulo);
    },
    isPrazoPayment() {
      return CODIGOS_PAGAMENTO_PRAZO.includes(
        this.initialPagamento?.codigo ?? this.pagamento?.codigo
      );
    },
    bandeira(): any {
      return this.bandeiras.find(
        (it: any) => it.id === this.pagamento.bandeira_id
      );
    },
    modulo(): string {
      return this.__ficha.modulo;
    },
    data(): any {
      return this.__ficha.data;
    },
    isConfirmDisabled(): boolean {
      if (!this.isFiscalDocument) {
        return false;
      }

      if (
        this.step === STEPS.prazo &&
        !clearCurrency(this.pagamento.detalhes.parcelas)
      ) {
        return true;
      }

      if (
        this.step === STEPS.prazo &&
        this.current?.codigo === "15" &&
        !this.pagamento.detalhes.conta_bancaria_id
      ) {
        return true;
      }

      return false;
    },
    current(): any {
      return this.methods[this.selected];
    },
    methods(): any[] {
      return (
        this.modulo === "cupons-fiscais"
          ? [
              {
                descricao: "Desconto / Acréscimo",
                valor: get(this.data.pagamentos_agrupados, "discount"),
              },
              {
                descricao: "Informar Consumidor",
                divider: true,
              },
            ]
          : []
      ).concat(
        this.formas.map((it) => ({
          ...it,
          valor: get(this.data.pagamentos_agrupados, `_${it.codigo}`),
        }))
      );
    },
  },
  watch: {
    current: "loadMissingDependencies",
    step: "loadMissingDependencies",
    "pagamento.valor": "checkChangeValue",
    "pagamento.vlr_parcial": "checkChangeValue",
  },
});
