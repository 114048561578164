<template>
  <p-combobox
    ref="combobox"
    v-bind="$attrs"
    :search-by="target"
    :items="items"
    :loading="loading"
    @native-input="onInput"
    @scroll="onScroll"
    v-on="$listeners"
  />
</template>

<script>
import { mapActions, mapState } from "vuex";

import get from "lodash/get";
import concat from "lodash/concat";
import filter from "lodash/filter";
import each from "lodash/each";
import last from "lodash/last";
import head from "lodash/head";
import debounce from "lodash/debounce";

import actions from "@/store/actions";

import PCombobox from "./PCombobox.vue";

export default {
  components: { PCombobox },
  props: {
    source: { type: String, required: true },
    target: {},
    orderBy: {},
    filter: { type: Array, default: () => [] },
    extra: { type: Array, default: () => [] },
  },

  created() {
    this.onPaginate(true);
  },

  computed: {
    ...mapState({
      loading: function (state) {
        return get(state.persistent, `fetching.${this.source}`, false);
      },
      items: function (state) {
        let data = get(state.persistent, `data.${this.source}`, []);

        data = concat(this.extra, data);

        each(this.filter ?? [], (_filter) => {
          const condition = head(_filter);
          const value = last(_filter);

          data = filter(data, (item) => {
            if (typeof condition === "function") {
              return value(item);
            }

            return item[condition] === value;
          });
        });

        return data;
      },
      paginator: function (state) {
        return get(state.persistent, `paginator.${this.source}`, {});
      },
    }),
  },

  methods: {
    ...mapActions({
      fetchPersistentData: actions.MODULO.PERSISTENT.READ,
      searchPersistentData: actions.MODULO.PERSISTENT.SEARCH,
    }),
    onInput(event) {
      if (typeof event === "object" && !event.isTrusted) return;
      this.onSearch(typeof event === "object" ? event.target.value : event);
    },
    onPaginate(firstAttempt = false) {
      this.fetchPersistentData({
        firstAttempt,
        modulo: this.source,
        orderBy: this.orderBy,
      });
    },
    onSearch: debounce(function (value) {
      if (!value) return;

      return this.searchPersistentData({
        modulo: this.source,
        target: this.target,
        value,
      });
    }, 400),
    onScroll: debounce(function (event) {
      if (this.paginator.page >= this.paginator.lastPage) return;

      // const offset = this.listItemHeight * this.visibleItems + 400;
      const offset = 40 * 8;

      if (event.target.scrollTop >= event.target.scrollHeight - offset) {
        return this.onPaginate();
      }
    }, 40),
  },
};
</script>
