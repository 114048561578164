<template>
  <v-badge
    dot
    avatar
    overlap
    bordered
    color="error"
    offset-y="12"
    :value="notifications.length && !$config.desativar_popup_notificacao"
  >
    <v-menu tile :close-on-click="false" offset-y nudge-top="-1" v-model="menu">
      <template #activator="{ on, attrs }">
        <btn id="notification-btn" icon class="px-0" v-on="on" v-bind="attrs">
          <v-icon small>
            {{
              $config.desativar_popup_notificacao
                ? "mdi-bell-sleep"
                : "mdi-bell"
            }}
          </v-icon>
        </btn>
      </template>

      <v-toolbar
        dense
        elevation="0"
        style="
          border-top-left-radius: 4px !important;
          border-top-right-radius: 4px !important;
        "
      >
        <btn icon>
          <v-icon small>mdi-bell</v-icon>
        </btn>

        <v-toolbar-title class="px-0">Notificações</v-toolbar-title>

        <v-spacer />

        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-btn small icon @click="() => clear()" v-bind="attrs" v-on="on">
              <v-icon>mdi-notification-clear-all</v-icon>
            </v-btn>
          </template>
          <span>Limpar</span>
        </v-tooltip>
      </v-toolbar>

      <v-list
        dense
        tile
        ref="list"
        v-if="notifications.length"
        class="overflow-y-auto"
        max-width="400px"
        min-width="300px"
        max-height="calc(100vh - 28px - 48px - 16px)"
      >
        <template v-for="(notification, i) in notifications">
          <v-list-item
            dense
            :key="i"
            style="max-height: 25px !important"
            @click="() => onClickNotification(notification)"
            v-bind="notification"
          >
            <v-list-item-avatar v-if="notification.src">
              <component
                :is="avatar(notification.src)"
                v-bind="notification.src"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="notification.titulo" />
              <v-list-item-subtitle v-text="notification.descricao_curta" />
            </v-list-item-content>

            <v-list-item-action v-if="notification.icon || notification.to">
              <v-btn icon>
                <v-icon>{{ notification.icon || "mdi-chevron-right" }}</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action
              @click.stop.prevent="
                () => notification.id && clear(notification.id)
              "
              v-else-if="notification.tipo !== 'P'"
            >
              <v-btn icon small>
                <v-icon color="error">mdi-trash-can</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider
            class="my-1"
            :key="`div->${i}`"
            v-if="i < notifications.length - 1"
          />
        </template>
      </v-list>

      <v-card
        flat
        v-else
        style="
          border-top-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
        "
      >
        <v-list dense min-width="300px" max-width="300px">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Nenhuma notificação</v-list-item-title>
            </v-list-item-content>

            <!-- <v-list-item-action>
              <btn text class="caption"> Ver todas </btn>
            </v-list-item-action> -->
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-badge>
</template>

<script>
import { mapState } from "vuex";
import { mDate } from "@/plugins/moment";
import get from "lodash/get";
import uniq from "lodash/uniq";

import Btn from "@/components/form/buttons/Btn.vue";
import actions from "@/store/actions";
import mutations from "@/store/mutations";

export default {
  components: { Btn },
  data: () => ({
    menu: false,
  }),
  computed: {
    ...mapState({
      data: (state) => state.notifications.data,
    }),
    notifications() {
      const list = [];

      if (get(this.$config, "nfe_contingencia_json.ativo", false)) {
        list.push({
          id: "nfe_contingencia",
          titulo: "Contingência NF-e ATIVA",
          to: "/config/nfe",
        });
      }

      if (get(this.$config, "nfce_contingencia_json.ativo", false)) {
        list.push({
          id: "nfce_contingencia",
          titulo: "Contingência NFC-e ATIVA",
          to: "/config/nfce",
        });
      }

      if (this.$info.certificate_will_expire_soon) {
        list.push({
          id: "certificado_digital",
          titulo:
            `Certificado Digital expira em: ` +
            mDate(this.$info.certificate_expiration_time),
          to: "/config/sistema#certificado",
        });
      }

      const data = uniq(
        list.concat(
          (this.data || []).filter((notification) => !notification.lida)
        )
      );

      const hasNotification =
        data.length && !this.$config.desativar_popup_notificacao;

      const favicon = hasNotification
        ? "/img/icons/favicon-badge"
        : "/img/icons/favicon";

      document.querySelectorAll("[rel=icon]").forEach((el) => {
        const size = el.getAttribute("sizes");

        el.href = `${favicon}-${size ?? "32x32"}.png`;
      });

      return data;
    },
  },
  async created() {
    await this.$store.dispatch(actions.NOTIFICATIONS.READ);
  },
  methods: {
    avatar() {
      return null;
    },
    clear(id = null) {
      this.$store.dispatch(actions.NOTIFICATIONS.CLEAR, { id });
    },
    onClickNotification(item) {
      const to = get(item, "action.to");
      if (to) return this.$router.push(to);

      if (!item.descricao) return;

      this.$store.commit(mutations.APP.ALERT, {
        active: true,
        type:
          item.categoria === "E"
            ? "error"
            : item.categoria === "A"
            ? "warning"
            : "info",
        title: item.titulo,
        message: item.descricao,
      });
    },
  },
  watch: {
    notifications(n, o) {
      n = (n || []).length;
      o = (o || []).length;
      if (n <= o) return;
      if (this.$config.desativar_popup_notificacao) return;
      if (!this.menu) this.menu = true;
    },
  },
};
</script>

<style scoped>
.pulse {
  -moz-animation: pulse 2s infinite linear;
  -o-animation: pulse 2s infinite linear;
  -webkit-animation: pulse 2s infinite linear;
  animation: pulse 2s infinite linear;
}
@-webkit-keyframes pulse {
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes pulse {
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes pulse {
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes pulse {
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pulse {
  25% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>

<style lang="scss" scoped>
.shake:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}
</style>
