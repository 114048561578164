<template>
  <btn size="x-small" color="error" v-bind="$attrs" @click="$emit('close')">
    <v-icon small>mdi-close</v-icon>
  </btn>
</template>

<script>
import Vue from "vue";
import Btn from "./Btn";

export default Vue.extend({
  components: { Btn },
});
</script>
