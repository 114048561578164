<template>
  <div>
    {{ time }}
  </div>
</template>

<script>
import moment, { FormatTimestamp } from "@/plugins/moment";

const format = `${FormatTimestamp}:ss`;

export default {
  data: () => ({
    timer: null,
    time: moment().format(format),
  }),
  created() {
    this.timer = setInterval(this.updateTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    updateTime() {
      this.time = moment().format(format);
    },
  },
};
</script>
