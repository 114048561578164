<template>
  <!-- Filtro entre códigos -->
  <v-row no-gutters v-if="type === types.ID">
    <text-field
      auto-focus
      required
      :label="`Filtrar do ${column.text || ''}`"
      v-model="value[queryStart]"
    />
    <text-field required label="Até" v-model="value[queryEnd]" />
  </v-row>

  <!-- Filtro entre horas -->
  <v-row no-gutters v-else-if="[types.TIMESTAMP, types.TIME].includes(type)">
    <date-time
      auto-focus
      :required="!isFromFilterAssistent"
      :nullable="isFromFilterAssistent"
      :type="type"
      :name="column.value"
      :label="`Filtrar ${column.text || ''} de`"
      v-model="value[queryStart]"
    />
    <date-time
      :required="!isFromFilterAssistent"
      :nullable="isFromFilterAssistent"
      :type="type"
      label="Até"
      :name="column.value"
      v-model="value[queryEnd]"
    />
  </v-row>

  <!-- Filtro entre intervalo de datas -->
  <v-row
    no-gutters
    v-else-if="[types.DATE, types.DATE_INTERVAL].includes(type)"
  >
    <!-- <p>{{ value[queryStart] }}</p><br> -->
    <date-time
      auto-focus
      startOfDay
      :required="!isFromFilterAssistent"
      :nullable="isFromFilterAssistent"
      :label="column.text ? `Filtrar ${column.text || ''} de` : 'Período de'"
      :name="column.value"
      v-model="value[queryStart]"
    />
    <!-- <p>{{ value[queryEnd] }}</p><br> -->
    <date-time
      endOfDay
      :required="!isFromFilterAssistent"
      :nullable="isFromFilterAssistent"
      label="Até"
      :name="column.value"
      v-model="value[queryEnd]"
    />
  </v-row>

  <!-- Filtro entre moedas -->
  <v-row no-gutters v-else-if="[types.CURRENCY, types.QUANTITY].includes(type)">
    <precision-field
      auto-focus
      :required="!isFromFilterAssistent"
      :name="column.value"
      :label="`Filtrar ${column.text || ''} de`"
      v-model="value[queryStart]"
    />
    <precision-field
      label="Até"
      :name="column.value"
      v-model="value[queryEnd]"
    />
  </v-row>

  <v-row v-else-if="type === types.BOOLEAN">
    <v-col class="py-1">
      <s-checkbox
        :label="`Possui ${column.text}`"
        v-model="value[queryStart]"
      />
    </v-col>
  </v-row>

  <v-row v-else-if="type === types.CHOOSE_ONE">
    <v-col class="py-0">
      <v-radio-group v-model="value.choose">
        <v-radio
          v-for="(option, i) in options"
          :key="i"
          :label="option.text"
          :value="option.value"
        />
      </v-radio-group>
    </v-col>
  </v-row>

  <v-row v-else-if="type === types.CFOP">
    <v-col class="pa-0">
      <v-checkbox
        label="Todas"
        :ripple="false"
        :input-value="isAllActive"
        @change="onToggleAll"
      />

      <v-col class="pa-0">
        <v-list
          dense
          class="overflow-y-auto"
          width="80%"
          height="200px"
          :outlined="extra.cfops && extra.cfops.length > 0"
        >
          <v-list-item-group multiple v-model="value.cfops">
            <v-list-item v-for="(item, i) in extra.cfops" :key="i">
              <template #default="{ active }">
                <v-list-item-action class="mr-1 my-0">
                  <v-checkbox :ripple="false" :input-value="active" />
                </v-list-item-action>

                <v-list-item-content class="pa-0">
                  <v-list-item-title v-text="item.descricao" />
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-col>
  </v-row>

  <v-row v-else-if="type === types.FORM">
    <template v-for="(est, i) in estrutura">
      <filter-types :key="i" v-model="value[est.queryStart || queryStart]" />
    </template>
  </v-row>

  <!-- Filtros de cpf/cnpj -->
  <v-row no-gutters v-else-if="type === types.CPF_CNPJ">
    <field-cpf-cnpj
      auto-focus
      required
      :persist="false"
      :label="label || `Filtrar ${column.text || ''} com`"
      v-model="value[queryStart]"
    />
  </v-row>

  <!-- Filtros padrões com um só valor -->
  <v-row no-gutters v-else>
    <text-field
      auto-focus
      required
      :label="label || `Filtrar ${column.text || ''} com`"
      v-model="value[queryStart]"
    />
  </v-row>
</template>

<script>
import get from "lodash/get";
import { filterTypes } from "@/utils/filters";

import moment from "@/plugins/moment";

import {
  DateTime,
  TextField,
  PrecisionField,
  FieldCpfCnpj,
  Checkbox as SCheckbox,
} from "@/components/form";

export default {
  name: "filter-types",
  components: {
    DateTime,
    TextField,
    PrecisionField,
    FieldCpfCnpj,
    SCheckbox,
  },
  props: {
    type: {},
    isFromFilterAssistent: { type: Boolean, default: false },
    options: { type: Array, default: () => [] },
    extra: { type: Object, default: () => ({}) },
    value: { type: Object, default: () => ({}) },
    column: { type: Object, default: () => ({}) },
    label: {},
    estrutura: { type: Array, default: () => [] },
    queryStart: { default: "queryStart" },
    queryEnd: { default: "queryEnd" },
  },
  data: () => ({
    types: filterTypes,
  }),
  methods: {
    onToggleAll(value) {
      if (value) {
        return this.$emit("input", {
          ...this.value,
          cfops: new Array(get(this.extra, "cfops", []).length)
            .fill(null)
            .map((_, k) => k),
        });
      }

      return this.$emit("input", {
        ...this.value,
        cfops: [],
      });
    },
  },
  computed: {
    isAllActive() {
      return (
        get(this.value, "cfops", []).length ===
        get(this.extra, "cfops", []).length
      );
    },
    moment() {
      return moment().utc().format("x");
      // return moment('01/01/1970 00:00:00').format("x");
    },
  },
};
</script>
