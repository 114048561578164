<template>
  <!-- <v-col class="pt-0 px-0" lg="10" xl="8" offset-lg="1" offset-xl="2"> -->
  <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
    <v-row>
      <v-col cols="12" md="4" class="pa-0">
        <container-content hidden cols="12" class="py-0 px-1">
          <v-row>
            <clifor-combobox-v2
              auto-focus
              label="CPF/CNPJ / Razão Social do Cliente"
              @click:append-outer="onEditCliforClicked"
              v-model="data.clifor"
            />
          </v-row>
          <v-row>
            <list-info :items="cliente" />
          </v-row>
        </container-content>
      </v-col>
      <v-col class="px-0" cols="12" md="8">
        <v-row class="mx-0">
          <clifor-select
            sm="6"
            md="5"
            label="Atendente"
            name="colaborador_id"
            :valueNotNull="false"
            :filter="tecnico_filter"
            :clifor="colaborador"
            v-model="data.colaborador_id"
          />
          <p-select-filter
            observe-items
            value-not-null
            cols="6"
            md="4"
            label="Situação"
            name="status_id"
            item-text="descricao"
            item-value="id"
            :items="extra.situacoes"
            v-model="data.status_id"
          />
          <p-date-time
            disabled
            cols="6"
            md="3"
            type="timestamp"
            label="Criada em"
            name="created_at"
            v-model="data.created_at"
          />
          <p-date-time
            sm="6"
            md="5"
            type="timestamp"
            label="Data/Hora Agendada"
            name="agendamento_at"
            v-model="data.agendamento_at"
          />
          <p-text-field
            type="tel"
            cols="4"
            sm="6"
            md="3"
            v-mask="'##:##'"
            maxlength="5"
            label="Tempo"
            name="tempo"
            v-model="data.tempo"
          />
          <p-date-time
            cols="8"
            sm="6"
            md="4"
            name="garantia_dt"
            label="Garantia"
            v-model="data.garantia_dt"
          />
          <field-label-editable
            md="12"
            maxlength="40"
            name="descricao"
            parametro="lbl_tb_ordem_servicos_descricao"
            v-model="data.descricao"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <field-label-editable
        sm="6"
        name="identificador1"
        parametro="lbl_tb_ordem_servicos_identificador_1"
        v-model="data.identificador1"
      />
      <field-label-editable
        sm="6"
        name="identificador2"
        parametro="lbl_tb_ordem_servicos_identificador_2"
        v-model="data.identificador2"
      />
      <field-label-editable
        sm="6"
        name="identificador3"
        parametro="lbl_tb_ordem_servicos_identificador_3"
        v-model="data.identificador3"
      />
      <field-label-editable
        sm="6"
        name="identificador4"
        parametro="lbl_tb_ordem_servicos_identificador_4"
        v-model="data.identificador4"
      />
    </v-row>
    <v-row>
      <p-text-area
        cols="12"
        md="6"
        maxlength="128"
        label="Problema Relatado"
        name="problema"
        rows="4"
        v-model="data.problema"
      />
      <p-text-area
        cols="12"
        md="6"
        rows="4"
        maxlength="254"
        label="Observação"
        name="obs"
        v-model="data.obs"
      />
    </v-row>

    <v-col class="pa-0">
      <v-row>
        <v-col class="px-0 pb-0 pt-2">
          <dependence-table
            height="180px"
            modulo="ordem-servico-itens"
            :__ficha="__ficha"
            :prepend-headers="tableTecnico"
            :selected="selected"
            @selected="setSelected"
            v-model="data"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pa-0" cols="12" md="6">
          <v-row class="ma-0">
            <p-precision-field
              readonly
              md="4"
              label="Total Produtos"
              name="vlr_produtos"
              v-model="data.vlr_produtos"
            />
            <p-precision-field
              readonly
              md="4"
              label="Total Serviços"
              name="vlr_servicos"
              v-model="data.vlr_servicos"
            />
            <p-precision-field
              md="4"
              label="Frete"
              name="vlr_frete"
              v-model="data.vlr_frete"
            />
            <precision-field
              readonly
              md="4"
              label="Desconto"
              v-model="data.vlr_desconto"
              @click.native="requestDiscountAssistent"
            />
            <p-precision-field
              readonly
              md="8"
              label="Total"
              name="vlr_total"
              v-model="data.vlr_total"
            />
          </v-row>
        </v-col>

        <v-col class="pt-0 px-0" sm="8" md="5">
          <single-pagamento
            :disabled="!data.id"
            :data="data"
            :__ficha="__ficha"
          />
        </v-col>

        <v-col sm="4" md="1" class="d-flex align-center">
          <btn
            text
            btn-class="mx-auto pa-0 focus-priority"
            color="secondary"
            height="50"
            :disabled="!data.id"
            @click="print"
          >
            <v-img
              contain
              height="50"
              width="50"
              :src="require('@/assets/menu/actions/imprimir.svg')"
            />
          </btn>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
import { mask } from "vue-the-new-mask";
import { mapState } from "vuex";
import { filter, get } from "lodash";

import {
  Btn,
  PTextArea,
  PDateTime,
  DependenceTable,
  PTextField,
  CliforSelect,
  PSelectFilter,
  PPrecisionField,
  PrecisionField,
  ContainerContent,
  FieldLabelEditable,
  CliforComboboxV2,
  SinglePagamento,
} from "@/components/form";

import { ListInfo } from "@/components/utils";

// Mixins
import Clifor from "@/mixins/clifor";
import { clearCurrency, setCurrency } from "@/utils";

export default Clifor.extend({
  components: {
    Btn,
    ListInfo,
    DependenceTable,
    PDateTime,
    PTextArea,
    PTextField,
    CliforSelect,
    PSelectFilter,
    PrecisionField,
    PPrecisionField,
    ContainerContent,
    FieldLabelEditable,
    CliforComboboxV2,
    SinglePagamento,
  },
  directives: { mask },
  computed: {
    ...mapState({
      cliforList: (state) => get(state.persistent, "data.cadastro", []),
    }),
    colaboradores() {
      return filter(this.cliforList, get(this.tecnico_filter, "0.0"));
    },
    tableTecnico() {
      return [
        {
          text: "Técnico",
          value: "colaborador_id",
          type: "select",
          source: this.colaboradores,
          options: {
            itemText: "nome_razao_social",
            itemValue: "id",
          },
        },
      ];
    },
  },
  methods: {
    print() {
      this.$routeResolver({
        name: "report",
        params: {
          modulo: this.__ficha.modulo,
          id: "visualizar",
          registro: this.data.id,
        },
      });
    },
    requestDiscountAssistent() {
      // Importa o componente
      return import(
        "@/components/popups/modulos/cupons-fiscais/DescontoAcrescimo.vue"
      ).then((Component) => {
        this.__ficha.activeContextProps = {
          __ficha: this.__ficha,
          onlyDiscount: true,
          initial_desconto: this.data.vlr_desconto,
          vlr_total: setCurrency(
            parseFloat(clearCurrency(this.data.vlr_total)) +
              parseFloat(clearCurrency(this.data.vlr_desconto))
          ),
        };

        return this.$nextTick(
          () => (this.__ficha.activeContextComponent = Component.default)
        );
      });
    },
  },
});
</script>
