<template>
  <btn
    v-bind="{ ...$attrs }"
    class="mr-1 mr-sm-2 mr-md-3 mr-xl-4 px-0 mb-1"
    :height="btnHeight"
    :width="btnWidth"
    :color="color"
    tabindex="-1"
    :style="{ visibility: show ? 'visible' : 'hidden' }"
    @click="$emit('click', $event)"
  >
    <v-list dense max-width="74" class="transparent pa-0">
      <v-list-item class="px-0">
        <v-list-item-content>
          <v-list-item-title
            class="mb-0 hidden-sm-and-down font-weight-black black--text text-caption text-md-body-2"
          >
            {{ nome }}
          </v-list-item-title>
          <v-list-item-subtitle
            class="mb-0 text-capitalize text-caption black--text"
            v-for="(desc, i) in descricao.split(' ')"
            :key="i"
          >
            {{ desc }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </btn>
</template>

<script>
import { Btn } from "@/components/form";
import { EventBus } from "@/main";
export default {
  props: {
    nome: { type: String, default: null, required: false },
    show: { type: Boolean, default: true },
    color: { type: String, default: "saleGreen" },
    descricao: { type: String, default: "", required: false },
  },
  components: {
    Btn,
  },
  data: () => ({
    btnWidth: 0,
    btnHeight: 0,
  }),
  created() {
    this.sizeBtn();
    EventBus.$on("resize", this.sizeBtn);
  },
  beforeDestroy() {
    EventBus.$off("resize", this.sizeBtn);
  },
  methods: {
    sizeBtn() {
      let width = 0;
      let height = 60;

      if (this.$vuetify.breakpoint.xs) {
        width = 75;
        height = 50;
      } else if (this.$vuetify.breakpoint.sm) {
        width = 85;
        height = 55;
      } else if (this.$vuetify.breakpoint.md) {
        width = 90;
      } else if (this.$vuetify.breakpoint.lg) {
        width = 110;
      } else if (this.$vuetify.breakpoint.xl) {
        width = 125;
      }

      this.btnWidth = width;
      this.btnHeight = height;
    },
  },
};
</script>

<style></style>
