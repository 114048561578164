<template>
  <component
    :is="component"
    :value="value"
    :label="label"
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    v-bind="attrs"
  />
</template>

<script>
import { get, isUndefined } from "lodash";
import { mapActions, mapState } from "vuex";

import { PSelectFilter, PTextField } from "@/components/form";

import actions from "@/store/actions";
import { ilike } from "@/utils/filters";

export default {
  props: {
    value: {},
    uf: { type: String },
    label: { type: String, default: "Município" },
    name: { type: String, default: "municipio_id" },

    itemText: { type: String, default: "municipio" },
    itemValue: { type: String, default: "municipio_id" },
  },
  /**
   * Created Vue hook
   */
  created() {
    // Se já houver UF, faz a leitura dos municipios
    this.read(this.uf);
  },
  computed: {
    ...mapState({
      loading: (state) => state.persistent.loading.municipios,
      cities: function (state) {
        return this.uf === "EX"
          ? []
          : get(state.persistent, `data.municipios.${this.uf}`, []);
      },
    }),
    component() {
      return this.uf === "EX" ? PTextField : PSelectFilter;
    },
    attrs() {
      return this.uf === "EX"
        ? {
            ...this.$attrs,
            name: "municipio_ex",
          }
        : {
            ...this.$attrs,
            observeItems: true,
            itemText: this.itemText,
            loading: this.loading,
            itemValue: this.itemValue,
            items: this.cities,
            name: this.name,
          };
    },
  },
  methods: {
    ...mapActions({
      fetchCities: actions.MODULO.PERSISTENT.READ,
    }),
    /**
     * Função que faz a leitura dos municípios da UF do parâmetro
     */
    read(uf) {
      if (!uf || uf === "EX") return;
      return this.fetchCities({
        modulo: "municipios",
        index: uf,
        filter: ilike("uf", [uf]),
      });
    },
  },
  /**
   * Watchers
   */
  watch: {
    // UF
    uf: {
      // Gerenciamento de mudança de estado da UF
      handler(val, old) {
        // Se a antiga for undefined e a nova não for undefined, e as mesmas forem diferentes
        if (isUndefined(old) && !isUndefined(val) && val !== old) {
          // Faz a leitura dos novos municipios
          this.read(val);
        }
      },
    },
  },
};
</script>
