<template>
  <v-col v-bind="{ ...$attrs }">
    <v-row class="ma-0">
      <p-switch
        md="6"
        :name="`${name}.ativo`"
        label="Habilitar configurações de E-mail do Usuário"
        v-if="modulo === 'usuarios'"
        v-model="value.ativo"
      />

      <p-select-filter
        :md="modulo === 'usuarios' ? '6' : '12'"
        :name="`${name}.preset`"
        :items="$config.email_presets"
        :disabled="isDisabled"
        item-text="text"
        item-value="value"
        label="Predefinição"
        v-model="value.preset"
      />
    </v-row>

    <p-text-field
      label="Servidor de saída de emails"
      :modulo="modulo"
      :disabled="isDisabled"
      :name="`${name}.host`"
      v-model="value.host"
      v-if="custom"
    />
    <p-text-field
      label="E-mail"
      name="nome_usuario_login_email"
      :modulo="modulo"
      :disabled="isDisabled"
      :save-as="`${name}.username`"
      v-model="value.username"
    />
    <p-precision-field
      fixed="0"
      label="Porta"
      :modulo="modulo"
      :disabled="isDisabled"
      :name="`${name}.port`"
      v-model="value.port"
      v-if="custom"
    />
    <p-text-field
      label="E-mail"
      :modulo="modulo"
      :disabled="isDisabled"
      :name="`${name}.address`"
      v-model="value.address"
      v-if="custom"
    />
    <p-text-field
      label="Nome"
      name="nome_usuario_email"
      :modulo="modulo"
      :disabled="isDisabled"
      :save-as="`${name}.name`"
      v-model="value.name"
    />
    <password
      persist
      label="Senha"
      name="palavra_passe_email"
      :modulo="modulo"
      :disabled="isDisabled"
      :save-as="`${name}.password`"
      v-model="value.password"
    />
    <v-row no-gutters>
      <p-switch
        cols="12"
        lg="auto"
        label="Conexão criptografada (SSL)"
        :modulo="modulo"
        :disabled="isDisabled"
        :name="`${name}.ssl_on`"
        v-model="value.ssl_on"
        v-if="custom"
      />
      <btn
        text
        :disabled="isDisabled"
        class="ml-auto mt-3 mr-1"
        @click="onClearConfig"
      >
        Limpar configurações
      </btn>
    </v-row>
  </v-col>
</template>

<script>
import get from "lodash/get";

import {
  PTextField,
  PSwitch,
  PPrecisionField,
  PSelectFilter,
  Password,
  Btn,
} from "@/components/form";
import mutations from "@/store/mutations";

export default {
  components: {
    PTextField,
    PPrecisionField,
    PSelectFilter,
    Password,
    PSwitch,
    Btn,
  },
  props: {
    __ficha: {},
    disabled: { default: false },
    value: { default: () => ({}) },
    name: { default: "email_json" },
  },
  computed: {
    modulo() {
      return this.__ficha.modulo;
    },
    custom() {
      return get(this.value, "preset", "Personalizado") === "Personalizado";
    },
    isDisabled() {
      return this.disabled || (this.modulo === "usuarios" && !this.value.ativo);
    },
  },
  methods: {
    async onClearConfig() {
      await this.__ficha.onChangePersistent({
        modulo: this.modulo,
        data: {
          [this.name]: this.name === "email_json" ? JSON.stringify({}) : {},
        },
      });

      this.$emit("input", {});

      this.$store.commit(mutations.APP.SNACKBAR, {
        active: true,
        text: "Preferências de E-mail limpas",
      });
    },
  },
};
</script>
