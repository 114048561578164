import { mDate } from "@/plugins/moment";
import getters from "@/store/getters";
import { active, inactive } from "@/utils/filters";
import { filter, get, includes } from "lodash";

/**
 * Colunas do Grid
 */
export const columns = [
  { text: "Conta", value: "conta" },
  { text: "Nome da Conta", value: "descricao" },
  { text: "Dia", value: "vlr_dia", align: "right" },
  { text: "Mês", value: "vlr_mes", align: "right" },
  { text: "Ano", value: "vlr_ano", align: "right" },
  { text: "Saldo", value: "vlr_saldo", align: "right" },
  { text: "Ativo", value: "ativo", type: "boolean" },
];

/**
 * Título do módulo
 */
export const title = function (this: any) {
  const cache = this.$store.getters[getters.APP.USER.CACHE]("plano-contas");
  const query = get(cache, "query.params[0]");
  const date = query ? ` de ${mDate(parseFloat(query))} ` : "";
  return `Planos de Conta${date}`;
};

/**
 * Pré-filtros antes de ler o grid do módulo
 */
export const prefilter = [
  {
    type: "calendar",
  },
];

/**
 * Ações de contexto da tabela
 */
export const context = function (this: any) {
  const isRegistroActive = this.registro && this.registro.ativo;

  return [
    {
      id: "ativo",
      title: "Ativo",
      modulo: "plano-contas",
      icon: !isRegistroActive ? "mdi-checkbox-blank-outline" : "mdi-check",
    },
  ];
};

/**
 * Ações do submenu
 */
export const subactions = function () {
  return [
    {
      title: "Arquivo",
      items: [
        {
          id: "choose_date",
          title: "Escoher data",
        },
      ],
    },
    {
      type: "report",
      title: "Relatórios",
      items: [
        {
          id: "balancete_gerencial_mensal",
          title: "Balancete gerencial mensal",
        },
        {
          id: "balancete_gerencial_anual",
          title: "Balancete gerencial anual",
          // divider: true,
        },
        // { id: "analise_mensal", title: "Análise mensal" },
        // { id: "analise_anual", title: "Análise anual" },
        // { id: "analise_grafica_contas", title: "Análise gráfica das contas" },
      ],
    },
    {
      title: "Exibir",
      type: "filter",
      items: [
        {
          title: "Ativos",
          filter: active(),
        },
        {
          title: "Inativos",
          filter: inactive(),
        },
      ],
    },
  ];
};

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = filter(columns, (column) =>
  // Retorna as colunas que as incluem
  includes(
    ["conta", "descricao", "vlr_dia", "vlr_mes", "vlr_ano", "vlr_saldo"],
    column.value
  )
);

/**
 * Cache default
 */
export const cache = {
  colunas: ["conta", "descricao", "vlr_dia", "vlr_mes", "vlr_ano", "vlr_saldo"],
  filtros: [],
  ordenar: [
    {
      coluna: "conta",
      ordem: "asc",
    },
  ],
};
