import moment from "@/plugins/moment";

import mutations from "@/store/mutations";
import actions from "@/store/actions";
import get from "lodash/get";

const DELETE_ALL_TEXT = "apagar meus dados permanentemente";
const EXPORT_ALL_TEXT = "exportar meus dados";

const base = {
  /**
   * Função que retorna o formulário a ser exibido no assistente
   */
  form() {
    return [
      {
        name: "nome_usuario",
        dontShow: true,
        type: "text",
        ignoreEnterFocus: true,
        value: "Administrador",
      },
      {
        name: "_admin_password",
        type: "password",
        autoFocus: true,
        label: "Senha do Administrador",
      },
      {
        name: "confirm_text",
        label: "Mensagem de confirmação",
      },
    ];
  },
  /**
   * Função que é chamada quando clicado no botão ok do assistente
   *
   * retorna uma string representando um erro ou true para válido
   */
  validator(this: any, data) {
    const confirmText =
      this.action === "export_all_data" ? EXPORT_ALL_TEXT : DELETE_ALL_TEXT;

    if (data.confirm_text !== confirmText) return "wrong";
    if (!data._admin_password) return "password";

    // Caso passar por tudo, retorna válido
    return true;
  },
  /**
   * Função que retorna todos os erros
   */
  error(this: any) {
    return {
      wrong: "Por favor, digite corretamente a mensagem de confirmação",
      password: "Por favor, informe a senha do Administrador",
    };
  },
};

/**
 * Configuração do assistente
 */
export const assistent = {
  // Identificador do assistente
  reset_pass_after_login: {
    // Impede o assistente de fechar
    persistent: true,
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message(item) {
      return `
        Atenção <strong>${
          item.nome ? item.nome : item.nome_usuario
        }</strong>.<br>
        <br>Por favor, cadastre uma nova senha para continuar o acesso ao sistema.<br>
        <br>A senha precisa ter no mínimo <strong>6 caracteres</strong> e a confirmação deve ser igual a senha.
      `;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      return [
        {
          name: "nome_usuario",
          dontShow: true,
          type: "text",
          ignoreEnterFocus: true,
          autoComplete: "username",
        },
        {
          autoFocus: true,
          name: "_login_pass",
          label: "Nova senha",
          type: "password",
          autoComplete: "new-password",
        },
        {
          loading: false,
          name: "_login_confirm_pass",
          label: "Confirmação da nova senha",
          type: "confirm-password",
          autoComplete: "new-password",
        },
      ];
    },
    /**
     * Função que é chamada quando clicado no botão ok do assistente
     *
     * retorna uma string representando um erro ou true para válido
     */
    validator(this: any) {
      // Se não tiver item ou não tiver senha
      // if (!item || !item._login_pass) return "no_blank";
      // // Se a senha for menor que 6
      // if (item._login_pass.length < 6) return "min_6";
      // // Se as senhas forem diferentes
      // if (item._login_pass !== item._login_confirm_pass) return "different";

      if (this.pw.errors.length) return "dynamic";

      // Caso passar por tudo, retorna válido
      return true;
    },
    /**
     * Função que retorna todos os erros
     */
    error(this: any) {
      return {
        dynamic: this.pw.errors.join("<br>"),
      };
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, item) {
      // Retorna a promessa da requisição
      return this.$store
        .dispatch(actions.USERS.UPDATE_PASSWORD, {
          // Id do usuário
          id: item.id,
          // Senha nova
          password: item._login_pass,
        })
        .then((response) => {
          // Se houver response e data
          if (response && response.data)
            // Commita a snackbar
            this.$store.commit(mutations.APP.SNACKBAR, {
              active: true,
              text: response.data.message,
            });

          // Commita o carregamento como falso
          this.$store.commit(mutations.APP.LOADING, false);

          return response;
        });
    },
  },
  // Identificador do assistente
  contingency: {
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message(item: any) {
      const desc =
        "<br><br><strong>A utilização de contingência offline deve se restringir as situações de efetiva impossibilidade de autorização da NFC-e em tempo real</strong><br>Após entrar em modo contingência, lembre-se de transmitir as notas quando voltar o serviço";

      return `
        Por favor, informe a justificativa de contingência${
          item.document === "nfce" ? desc : ""
        }
      `;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      return [
        {
          autoFocus: true,
          name: "justificativa",
          label: "Justificativa de contingência",
        },
      ];
    },
    /**
     * Função que é chamada quando clicado no botão ok do assistente
     *
     * retorna uma string representando um erro ou true para válido
     */
    validator(item) {
      // Se não tiver item ou não tiver senha
      if (!item || !item.justificativa) return "no_blank";
      // Caso passar por tudo, retorna válido
      return true;
    },
    /**
     * Função que retorna todos os erros
     */
    error() {
      return {
        no_blank: "A justificativa precisa ser informada",
      };
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, item) {
      this.$store.dispatch(actions.MODULO.FICHA.UPDATE_REGISTRO, {
        modulo: "sistema",
        data: {
          [item.document === "nfe"
            ? "nfe_contingencia_json"
            : "nfce_contingencia_json"]: JSON.stringify({
            justificativa: item.justificativa,
            inicio: moment().format("x"),
            ativo: true,
          }),
        },
      });
    },
  },
  clear_all_data: {
    ...base,
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message(this: any) {
      return `
        Atenção <strong>${this.$emitente.razao_social}</strong>.<br>
        <br><strong>TODOS</strong> os seus dados serão Excluídos. Para confirmar a operação, digite <strong>'${DELETE_ALL_TEXT}'</strong> no campo abaixo e confirme com a senha do </strong>Administrador</strong>
      `;
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, item) {
      // Retorna a promessa da requisição
      return this.$store
        .dispatch(actions.APP.CLEAR_ALL_DATA, {
          confirm_text: item.confirm_text,
          password: item._admin_password,
          type: item.type,
        })
        .then((response) => {
          // captura a mensagem
          const text = get(response, "data.message");

          // Se houver response e data
          if (text) {
            // Commita a snackbar
            this.$store.commit(mutations.APP.SNACKBAR, {
              active: true,
              text,
            });
          }

          this.$logout();

          return response;
        });
    },
  },
  export_all_data: {
    ...base,
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message(this: any) {
      return `
        Atenção <strong>${this.$emitente.razao_social}</strong>.<br>
        <br>Todos os seus dados serão Exportados para um arquivo compactado. Para confirmar a operação, digite <strong>'${EXPORT_ALL_TEXT}'</strong> no campo abaixo e confirme com a senha do </strong>Administrador</strong>
      `;
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, item) {
      // Retorna a promessa da requisição
      return this.$store
        .dispatch(actions.APP.EXPORT_ALL_DATA, {
          confirm_text: item.confirm_text,
          password: item._admin_password,
        })
        .then((response) => {
          // captura a mensagem
          const text = get(response, "data.message");

          // Se houver response e data
          if (text) {
            // Commita a snackbar
            this.$store.commit(mutations.APP.SNACKBAR, {
              active: true,
              text,
            });
          }

          return response;
        });
    },
  },
};
