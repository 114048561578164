export default [
  { text: "AC", value: "AC" },
  { text: "AL", value: "AL" },
  { text: "AM", value: "AM" },
  { text: "AP", value: "AP" },
  { text: "BA", value: "BA" },
  { text: "CE", value: "CE" },
  { text: "DF", value: "DF" },
  { text: "ES", value: "ES" },
  { text: "GO", value: "GO" },
  { text: "MA", value: "MA" },
  { text: "MG", value: "MG" },
  { text: "MS", value: "MS" },
  { text: "MT", value: "MT" },
  { text: "PA", value: "PA" },
  { text: "PB", value: "PB" },
  { text: "PE", value: "PE" },
  { text: "PI", value: "PI" },
  { text: "PR", value: "PR" },
  { text: "RJ", value: "RJ" },
  { text: "RN", value: "RN" },
  { text: "RO", value: "RO" },
  { text: "RR", value: "RR" },
  { text: "RS", value: "RS" },
  { text: "SC", value: "SC" },
  { text: "SE", value: "SE" },
  { text: "SP", value: "SP" },
  { text: "TO", value: "TO" },
];
