<template>
  <tabs-items
    v-model="value"
    @loaded-tab-chaves-acesso="onDependenceTabLoaded"
    @loaded-tab-geral="
      changePerJurosDuplicata(
        $config.per_juros_duplicata_atraso_diaria,
        'per_juros_duplicata_atraso_diaria'
      )
    "
  >
    <template #tab-imagens>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <container-content cols="auto" title="Logomarca para relatórios">
            <v-row>
              <image-report
                button-hidden
                :__ficha="__ficha"
                :disabled="isFichaDisabled"
              />
            </v-row>
          </container-content>
        </v-row>
        <v-row class="mt-4">
          <container-content cols="auto" title="Logo para NF-e" class="pr-2">
            <v-row>
              <image-nfe
                button-hidden
                class="pa-2"
                :disabled="isFichaDisabled"
                :__ficha="__ficha"
              />
            </v-row>
          </container-content>
          <container-content
            cols="auto"
            :title="`Logo para ${$nomeCupomFiscal}`"
          >
            <v-row>
              <image-nfce
                button-hidden
                class="pa-2"
                :disabled="isFichaDisabled"
                :__ficha="__ficha"
              />
            </v-row>
          </container-content>
        </v-row>
      </v-col>
    </template>

    <template #tab-certificado>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row no-gutters>
          <file-field
            md="6"
            accept=".pfx"
            label="Certificado digital"
            v-model="files"
          />
          <text-field
            md="6"
            v-model="data.certificado_pass"
            :required="!$config.senha_certificado_preenchida"
            name="certificado_pass"
            label="Senha do certificado"
            :type="visible ? 'text' : 'password'"
          >
            <template #append>
              <div class="v-input__icon v-input__icon--clear">
                <btn @click.stop="visible = !visible" icon>
                  <v-icon>
                    {{ visible ? "mdi-eye-outline" : "mdi-eye-off-outline" }}
                  </v-icon>
                </btn>
              </div>
            </template>
          </text-field>
        </v-row>
        <v-row v-if="$config.senha_certificado_alterada_at">
          <v-col>
            Senha do certificado alterada em
            {{ $config.senha_certificado_alterada_at }}
          </v-col>
        </v-row>
        <v-row no-gutters v-if="$info.certificate_expiration_time">
          <v-col>
            O certificado digital irá expirar em
            <strong class="error--text">
              {{ $info.certificate_expiration_time | date }}
            </strong>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col class="px-1" cols="auto">
            <btn
              outlined
              :disabled="isFichaDisabled"
              color="actionbutton"
              @click="onSaveData"
              >Salvar</btn
            >
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-geral>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <container-content title="Juros de mora">
            <v-row>
              <precision-field
                cols="12"
                md="auto"
                suffix="%"
                fixed="4"
                :label="juro.label"
                :key="i"
                v-model="perJuros[juro.value]"
                v-for="(juro, i) in taxasJuro"
                @change="changePerJurosDuplicata($event, juro.name, true)"
              />
            </v-row>
            <v-row>
              <v-radio-group
                row
                label="Fórmula de juros"
                hide-details
                class="mt-0 ml-2"
                @change="onRadioChange"
                v-model="$config.juros"
              >
                <v-radio label="Simples" value="simples" />
                <v-radio label="Compostos" value="compostos" />
              </v-radio-group>
            </v-row>
          </container-content>
        </v-row>

        <v-row>
          <container-content title="Cadastro">
            <v-row>
              <p-switch
                name="nao_permitir_clifor_cpf_cnpj_duplicado"
                label="Não permitir cadastros com CPF/CNPJ duplicados"
                v-model="$config.nao_permitir_clifor_cpf_cnpj_duplicado"
              />
            </v-row>
            <v-row>
              <p-switch
                name="nao_permitir_clifor_razao_social_duplicado"
                label="Não permitir cadastros com Nome/Razão Social duplicados"
                v-model="$config.nao_permitir_clifor_razao_social_duplicado"
              />
            </v-row>
          </container-content>
        </v-row>

        <v-row>
          <container-content title="Boletos">
            <v-row>
              <p-switch
                name="dfe_emitir_boletos_automaticamente"
                :label="`Emitir boletos automaticamente ao autorizar NF-e e ${$nomeCupomFiscal}`"
                v-model="$config.dfe_emitir_boletos_automaticamente"
              />
            </v-row>
            <v-row>
              <p-switch
                name="boleto_imprimir_recibo_entrega"
                label="Imprimir Recibo de Entrega"
                v-model="$config.boleto_imprimir_recibo_entrega"
              />
            </v-row>
          </container-content>
        </v-row>

        <v-row>
          <container-content title="Prazo duplicatas (em dias)">
            <v-row>
              <p-precision-field
                cols="12"
                sm="4"
                xl="2"
                fixed="0"
                :key="i"
                :label="duplicata.toUpperCase()"
                :name="`dias_duplicata_${duplicata}`"
                v-model="$config[`dias_duplicata_${duplicata}`]"
                v-for="(duplicata, i) in ['a', 'b', 'c']"
              />
            </v-row>
          </container-content>
        </v-row>

        <v-row>
          <container-content title="Documentos Fiscais">
            <v-row>
              <p-switch
                name="estoque_negativo_emissao_nfe"
                :label="`Estoque negativo na emissão da NF-e e ${$nomeCupomFiscal}`"
                v-model="$config.estoque_negativo_emissao_nfe"
              />
            </v-row>
            <v-row>
              <p-select-filter
                md="6"
                xl="4"
                type="tel"
                label="Teto limite para tributação de IR sobre serviços"
                name="ir_sobre_servicos_teto_limite"
                :items="irCeiling"
                v-model="$config.ir_sobre_servicos_teto_limite"
              />
              <p-select-filter
                md="6"
                xl="4"
                type="tel"
                label="Alíquota de IR sobre notas de serviços acima do teto"
                name="per_ir_sobre_notas_servicos_acima_teto"
                :items="irAliquota"
                v-model="$config.per_ir_sobre_notas_servicos_acima_teto"
              />
              <p-precision-field
                suffix="%"
                cols="12"
                md="6"
                lg="4"
                name="per_comissao"
                label="% Comissão Vendedor"
                v-model="$config.per_comissao"
              />
            </v-row>
          </container-content>

          <container-content title="Ajustes">
            <v-row>
              <p-select-filter
                value-not-null
                sm="6"
                lg="4"
                xl="3"
                type="tel"
                name="qtd_decimal_valor"
                label="Casas decimais no preço"
                :items="quantityDecimalCases(10)"
                v-model="$config.qtd_decimal_valor"
              />
              <p-select-filter
                value-not-null
                sm="6"
                lg="4"
                xl="3"
                type="tel"
                label="Casas decimais na quantidade"
                name="qtd_decimal_quantidade"
                :items="quantityDecimalCases(5)"
                v-model="$config.qtd_decimal_quantidade"
              />
              <p-select-filter
                value-not-null
                md="6"
                lg="4"
                xl="3"
                type="tel"
                label="Casas decimais na quantidade de serviços"
                name="qtd_decimal_quantidade_servicos"
                :items="quantityDecimalCases(5)"
                v-model="$config.qtd_decimal_quantidade_servicos"
              />
            </v-row>

            <v-row>
              <p-select-filter
                value-not-null
                md="6"
                xl="3"
                label="Montante na etiqueta da balança"
                name="etiqueta_balanca_montante"
                :items="[
                  { text: 'R$', value: 'preco' },
                  { text: 'KG', value: 'quantidade' },
                ]"
                v-model="$config.etiqueta_balanca_montante"
              />
              <s-switch
                label="Usar Balança"
                class="my-auto"
                :value="storage.frente ? storage.frente.usar_balanca : false"
                @change="() => onToggleStorage('frente.usar_balanca')"
              />
            </v-row>
          </container-content>

          <container-content title="Outros">
            <v-row>
              <p-switch
                label="Desativar notificações"
                name="desativar_popup_notificacao"
                v-model="$config.desativar_popup_notificacao"
              />
            </v-row>
          </container-content>
        </v-row>
      </v-col>
    </template>

    <template #tab-atendimento>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <p-date-time
            md="6"
            lg="3"
            type="time"
            name="inicio_atendimento_hr"
            v-model="$config.inicio_atendimento_hr"
            label="Horário inicial de atendimento"
          />
          <p-date-time
            md="6"
            lg="3"
            type="time"
            name="final_atendimento_hr"
            v-model="$config.final_atendimento_hr"
            label="Horário final de atendimento"
          />
        </v-row>
      </v-col>
    </template>

    <template #tab-email>
      <email-smtp
        cols="12"
        md="6"
        lg="5"
        offset-lg="1"
        modulo="sistema"
        class="mt-n2 mb-n4"
        :disabled="isFichaDisabled"
        :__ficha="__ficha"
        v-model="email_json"
      />
    </template>

    <template #tab-chaves-acesso>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <v-col md="12">
            <v-row class="ma-0">
              <text-field
                cols=""
                maxlength="45"
                label="Descrição"
                v-model="token.descricao"
              />

              <v-col cols="auto" class="px-1 d-flex">
                <btn
                  outlined
                  class="my-auto"
                  :disabled="isFichaDisabled"
                  color="actionbutton"
                  btn-class="ml-2 focus-priority"
                  @click="createToken"
                >
                  Criar chave de acesso
                </btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-col class="px-1 py-2">
          <div :style="`height: 200px !important`">
            <data-table
              hide-footer
              :headers="tabTerminalHeaders"
              :items="tabTerminalValues"
              :disabled="isFichaDisabled"
              @delete="onDeleteToken"
              @force-delete="onDeleteToken"
              @input="setSelected"
              :value="selected"
            />
          </div>
        </v-col>

        <v-row class="ma-0">
          <v-col class="pa-1">
            <template v-if="!$isMobile">
              <btn outlined :href="openTerminalUrl">Abrir Terminal</btn>
              <btn
                outlined
                class="mt-2 mt-sm-0 ml-sm-2"
                @click="onOpenTerminal"
                :href="logInTerminalUrl"
                :disabled="!logInTerminalUrl"
              >
                Abrir Terminal com a Chave Selecionada
              </btn>
            </template>
            <btn
              outlined
              class="mt-2 mt-sm-0 ml-sm-2"
              @click="assignAccessToken"
              :disabled="!logInTerminalUrl"
              v-else
            >
              Vincular a Chave Selecionada
            </btn>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-orcamento>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <container-content title="Permitir">
            <v-row>
              <p-precision-field
                suffix="%"
                cols="12"
                md="6"
                lg="4"
                name="per_max_desconto_item_orcamento"
                label="% Máxima de desconto no item"
                v-model="$config.per_max_desconto_item_orcamento"
              />
              <p-precision-field
                suffix="%"
                cols="12"
                md="6"
                lg="4"
                name="per_max_desconto_total_orcamento"
                label="% Máxima de desconto no total"
                v-model="$config.per_max_desconto_total_orcamento"
              />
            </v-row>
          </container-content>

          <container-content title="Configurações iniciais">
            <v-row>
              <p-precision-field
                md="6"
                name="orcamento_default_vlr_frete"
                v-model="$config.orcamento_default_vlr_frete"
                label="Frete"
              />
              <p-precision-field
                ignore-enter-focus
                md="6"
                label="Dias de validade"
                name="orcamento_default_dias_validade"
                fixed="0"
                v-model="$config.orcamento_default_dias_validade"
              />
              <p-text-area
                cols="12"
                rows="3"
                maxlength="254"
                label="Observação"
                name="orcamento_default_obs"
                v-model="$config.orcamento_default_obs"
              />
            </v-row>
          </container-content>
        </v-row>
      </v-col>
    </template>

    <template #tab-ordem-servico>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row>
          <container-content title="Permitir">
            <v-row>
              <p-precision-field
                suffix="%"
                cols="12"
                md="6"
                lg="4"
                name="per_max_desconto_item_ordem_servico"
                label="% Máxima de desconto no item"
                v-model="$config.per_max_desconto_item_ordem_servico"
              />
              <p-precision-field
                suffix="%"
                cols="12"
                md="6"
                lg="4"
                name="per_max_desconto_total_ordem_servico"
                label="% Máxima de desconto no total"
                v-model="$config.per_max_desconto_total_ordem_servico"
              />
            </v-row>
          </container-content>

          <container-content title="Configurações iniciais">
            <v-row>
              <p-text-field
                type="tel"
                md="6"
                maxlength="5"
                v-mask="'##:##'"
                label="Tempo"
                name="ordem_servico_default_tempo"
                v-model="$config.ordem_servico_default_tempo"
              />
              <p-precision-field
                md="6"
                label="Dias de garantia"
                name="ordem_servico_default_dias_garantia"
                fixed="0"
                v-model="$config.ordem_servico_default_dias_garantia"
              />
              <p-text-area
                cols="12"
                rows="3"
                maxlength="254"
                label="Observação"
                name="ordem_servico_default_obs"
                v-model="$config.ordem_servico_default_obs"
              />
              <p-switch
                cols="6"
                name="ordem_servico_default_importar_servicos"
                :label="`Importar os Serviços (NF-e / ${$nomeCupomFiscal})`"
                v-model="$config.ordem_servico_default_importar_servicos"
              />
              <p-switch
                cols="6"
                name="ordem_servico_default_gerar_contas_receber_servicos"
                :label="`Gerar contas a receber dos serviços NÃO importados (NF-e / ${$nomeCupomFiscal})`"
                v-model="
                  $config.ordem_servico_default_gerar_contas_receber_servicos
                "
              />
            </v-row>
          </container-content>
        </v-row>
      </v-col>
    </template>
  </tabs-items>
</template>

<script>
import { mask } from "vue-the-new-mask";
import { mapActions } from "vuex";
import { findIndex, get } from "lodash";

import { clearCurrency, setCurrency } from "@/utils";

import {
  PDateTime,
  TextField,
  PTextField,
  PTextArea,
  PSelectFilter,
  PPrecisionField,
  PrecisionField,
  PSwitch,
  PSelectUf,
  PSelectMunicipios,
  Btn,
  ContainerContent,
  EmailSmtp,
  FieldEmail,
  FileField,
  FieldTelefone,
  FieldNumero,
  FieldCep,
  FieldCpfCnpj,
  Switch as SSwitch,
} from "@/components/form";

import {
  TabsItems,
  ImageReport,
  ImageNfe,
  ImageNfce,
} from "@/components/utils";

import DataTable from "@/components/table/DataTable.vue";

import actions from "@/store/actions";
import mutations from "@/store/mutations";

// Mixins
import Ficha from "@/mixins/ficha";
import { mDate } from "@/plugins/moment";

export default Ficha.extend({
  filters: {
    date(value) {
      return mDate(value);
    },
  },
  directives: { mask },
  components: {
    FileField,
    SSwitch,
    PDateTime,
    TextField,
    PTextField,
    PSwitch,
    PPrecisionField,
    PrecisionField,
    FieldNumero,
    PSelectFilter,
    TabsItems,
    ContainerContent,
    ImageReport,
    ImageNfe,
    ImageNfce,
    PSelectUf,
    PSelectMunicipios,
    Btn,
    FieldEmail,
    DataTable,
    FieldTelefone,
    FieldCep,
    FieldCpfCnpj,
    EmailSmtp,
    PTextArea,
  },
  data: () => ({
    visible: false,
    files: {},
    storage: {},

    email_json: {},

    perJuros: {
      diaria: null,
      mensal: "0,00",
      anual: "0,00",
    },

    perJurosDuplicataAtrasoAnual: "0,00",

    token: {
      descricao: "",
    },
  }),
  created() {
    this.updateStorage();

    this.$nextTick(() => {
      this.email_json = { ...(this.$config.email_json ?? {}) };
    });
  },
  computed: {
    taxasJuro() {
      return [
        {
          label: "Diária",
          value: "diaria",
          name: "per_juros_duplicata_atraso_diaria",
        },
        {
          label: "Mensal",
          value: "mensal",
          name: "per_juros_duplicata_atraso_mensal",
        },
        {
          label: "Anual",
          value: "anual",
          name: "per_juros_duplicata_atraso_anual",
        },
      ];
    },
    openTerminalUrl() {
      return `${this.$terminalProtocol}`;
    },
    logInTerminalUrl() {
      const auth_token = get(this.selected, "registro.chave_base64");
      if (!auth_token) return "";

      const params = this.$jsonToUri({
        type: "auth",
        auth_token,
      });

      return `${this.$terminalProtocol}${params}`;
    },
    tabTerminalHeaders() {
      return [
        {
          text: "Descrição",
          value: "descricao",
        },
        {
          text: "Chave de acesso",
          value: "chave_base64",
          type: "copy-to-clipboard",
          maxWidth: "90%",
        },
      ];
    },
    tabTerminalValues() {
      return this.data.chaves_acesso ?? [];
    },
    perJurosDuplicataAtrasoDiaria: {
      get() {
        return (
          this.perJuros.diaria || this.$config.per_juros_duplicata_atraso_diaria
        );
      },
      set(value) {
        this.perJuros.diaria = value;
      },
    },
    irCeiling() {
      const opts = [];
      let sum = 0;

      const length = 34;

      for (let i = 1; i < length; i++) {
        const step =
          i <= 20 ? 500 : i <= 25 ? 1000 : i == length - 1 ? 50000 : 5000;
        sum += step;
        opts.push({ text: setCurrency(sum), value: sum });
      }

      return opts;
    },
    irAliquota() {
      const opts = [];

      let sum = 0;
      const length = 23;
      for (let i = 1; i < length; i++) {
        const step = i > 15 || i == 1 ? 1 : 0.5;
        sum += step;
        opts.push({
          text: `${sum.toFixed(2).replace(/(\.)/g, ",")}%`,
          value: sum,
        });
      }
      return opts;
    },
  },
  methods: {
    ...mapActions({
      coreCreate: actions.CORE.POST,
      coreDelete: actions.CORE.DELETE,
      uploadCertificate: actions.CONFIG.NFE.CERTIFICATE,
    }),
    updateStorage() {
      this.storage = { frente: this.$getStorage("frente") };
    },
    onOpenTerminal() {
      this.assignAccessToken();
    },
    onToggleStorage(name) {
      this.$toggleBooleanStorage(name);

      this.$nextTick(() => {
        this.updateStorage();
      });
    },
    /**
     * Função que é ativada quando o certificado/senha são salvos
     */
    onSaveData() {
      this.uploadCertificate({
        certificate: this.files,
        password: this.data.certificado_pass,
      });
    },
    onDeleteToken(value) {
      if (!value || !value.id) return;
      if (this.isFichaDisabled) return;

      return this.__ficha.$alert(
        {
          value: true,
          cancelable: true,
          title: "Apagar chave de acesso",
          message: `Você deseja apagar a Chave de Acesso ${value.descricao}?`,
        },
        {
          ok: () => {
            this.coreDelete({
              path: `/terminal/token/${value.id}`,
            }).then((data) => {
              this.data.chaves_acesso.splice(
                findIndex(
                  this.data.chaves_acesso,
                  (item) => item.id === value.id
                ),
                1
              );

              if (data.message)
                this.$store.commit(mutations.APP.SNACKBAR, {
                  active: true,
                  text: data.message,
                });
            });
          },
        }
      );
    },
    assignAccessToken() {
      const auth_token = get(this.selected, "registro.chave_base64");
      this.$setStorage("frente.acesso_terminal", auth_token);

      return this.$store.commit(mutations.APP.SNACKBAR, {
        active: true,
        text: "Chave de acesso vinculada com sucesso",
      });
    },
    createToken() {
      // this.__ficha.activeContextListeners = {};
      // this.__ficha.activeContextProps = {};

      // this.__ficha.$nextTick(() => {
      //   this.__ficha.activeContextComponent = null;
      // });

      if (!this.token.descricao)
        return this.$store.commit(mutations.APP.SNACKBAR, {
          active: true,
          color: "error",
          text: "Informe a descrição",
        });

      this.coreCreate({
        path: "/terminal/token",
        descricao: this.token.descricao,
      }).then((data) => {
        this.$store.commit(mutations.APP.SNACKBAR, {
          active: true,
          text: "Chave de acesso criada com sucesso",
        });

        if (!data || !data.registro) return;

        if (!this.data.chaves_acesso)
          this.__ficha.append({
            chaves_acesso: [data.registro],
          });
        else this.data.chaves_acesso.push(data.registro);

        this.token = {
          descricao: "",
        };
      });
    },
    /**
     * Função que faz a leitura das dependências
     */
    onDependenceTabLoaded(dependence) {
      this.__ficha.readDependence({
        dependence,
      });
    },
    quantityDecimalCases(n) {
      const opts = [];

      for (let i = 1; i < n; i++) {
        opts.push({
          value: i,
          text: `${i}`,
        });
      }
      return opts;
    },
    onRadioChange(value) {
      this.__ficha.onChangePersistent({
        data: {
          juros: value,
        },
      });
    },
    changePerJurosDuplicata(value, target, change = false) {
      value = clearCurrency(value) ?? 0.0;

      if (!change) {
        this.perJuros.diaria = this.$config.per_juros_duplicata_atraso_diaria;
      }

      switch (target) {
        case "per_juros_duplicata_atraso_diaria":
          this.perJuros.mensal = setCurrency(value * 30);
          this.perJuros.anual = setCurrency(value * 360);
          break;
        case "per_juros_duplicata_atraso_mensal":
          this.perJuros.diaria = setCurrency(value / 30);
          this.perJuros.anual = setCurrency(value * 12);
          break;
        case "per_juros_duplicata_atraso_anual":
          this.perJuros.diaria = setCurrency(value / 360);
          this.perJuros.mensal = setCurrency(value / 12);
          break;
      }

      if (change) {
        const event = {
          data: {
            per_juros_duplicata_atraso_diaria: this.perJuros.diaria,
          },
        };

        this.__ficha.onChangePersistent(event);
      }
    },
  },
});
</script>
