// Libs
import Vue from "vue";

// Layouts
import App from "./App.vue";

// Plugins
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueGtag from "vue-gtag";

// Mixins
import Main from "./mixin";
import { plugin as api } from "@/api";

// Css
import "typeface-roboto/index.css";
import "@mdi/font/css/materialdesignicons.min.css";

// Registra o service worker
import "./registerServiceWorker";

// Deixar = true quando for para produção | = false (desenvolvimento)
Vue.config.productionTip = process.env.VUE_APP_MODE !== "development";
Vue.config.devtools = process.env.VUE_APP_MODE === "development";

// Event bus
export const EventBus = new Vue();
export { store };

const analyticsId = process.env.VUE_APP_GOOGLE_ANALYTICS;

// Se não estiver no desenvolvimento
if (process.env.VUE_APP_MODE !== "development" && analyticsId) {
  // Usa o analytics
  Vue.use(
    VueGtag,
    {
      // ID da propriedade
      enabled: false,
      bootstrap: false,
      disableScriptLoad: true,
      config: { id: analyticsId },
      appName: process.env.VUE_APP_NAME,
      pageTrackerScreenviewEnabled: true,
    },
    router
  );
}

/**
 * Vue Mixin (mixed in)
 *
 * Funções em comum para todos os componentes
 */
Vue.mixin(Main);

Vue.use(api, { store });

/**
 * Main Vue instance
 */
new Vue({
  // VueRouter
  router,
  // VueX
  store,
  // Vuetify
  vuetify,
  // Render application
  render: (h) => h(App),
}).$mount("#app");
