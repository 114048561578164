import get from "lodash/get";
import sortBy from "lodash/sortBy";

import API from "@/api";

import actions from "@/store/actions";
import getters from "@/store/getters";
import mutations from "@/store/mutations";

import { modulo as initialState } from "@/store/defaultStates";

const api = API.config();

export default {
  state: Object.assign({}, initialState()),

  getters: {
    /**
     * Função que captura as colunas do módulo passado por parâmetro, ou o módulo atual
     */
    [getters.MODULO.COLUMNS]: (state, _getters) => (modulo) => {
      try {
        // Define o módulo como o do parâmetro, caso não houver, pega o atual
        modulo = modulo || state.current;
        // Tenta importar as colunas do arquivo de config do módulo
        let {
          columns,
          identificadores,
        } = require(`@/config/modulos/${modulo}`);

        let params = {};

        if (["cupons-fiscais", "estoque"].includes(modulo)) {
          const { uf, id, mei } = _getters[getters.APP.EMITENTE] ?? {};

          let useSat = get(
            JSON.parse(localStorage.getItem(`frente.${id}`) ?? "{}"),
            "cfe_sat",
            false
          );

          useSat = useSat && ["SP", "CE"].includes(uf);

          params = {
            document: useSat ? "cfe-sat" : mei ? "cupom-mei" : "nfce",
            name: useSat
              ? uf === "CE"
                ? "MFe"
                : "CFe-SAT"
              : mei
              ? "Cupom"
              : "NFC-e",
            pronoun: useSat ? "o" : mei ? "o" : "a",
            shortName: useSat ? "CFe" : mei ? "Cupom" : "NFC",
          };
        }

        if (typeof columns === "function")
          columns = columns.bind(state)(params);

        // Se o módulo tiver identificador
        if (identificadores) {
          // Captura os identificadores do módulo
          identificadores = _getters[getters.APP.IDENTIFICADORES](modulo);

          // Mapeia as colunas
          columns = columns.map((column) => {
            // Os que não forem identificador
            if (!column.value.match(/^identificador/))
              // Retorna a coluna
              return column;

            // Captura o id do identificador
            let text = column.text.substr(-1, 1);
            // Captura o texto
            text = get(
              // Buscando nos identificadores
              identificadores.find((id) => id.name.endsWith(text)),
              "value"
            );

            // Retorna a coluna
            return {
              ...column,
              // Com o identificador atualizado
              text,
            };
          });
        }

        // Retorna as colunas, ou uma lista vazia se for inválido
        return columns ?? [];
      } catch (e) {
        // Caso haja erros de importação, retorna uma lista vazia
        return [];
      }
    },

    /**
     * Getter que retorna as colunas ordenadas baseado no cache do usuário
     */
    [getters.MODULO.ORDERED_COLUMNS]: (state, _getters) => (modulo) => {
      // Define o módulo como o do parâmetro, caso não houver, pega o atual
      modulo = modulo || state.current;

      // Captura as colunas do modulo
      const columns = _getters[getters.MODULO.COLUMNS](modulo);

      const cache = _getters[getters.APP.USER.CACHE](modulo);

      // Captura as colunas marcadas no cache do usuario
      const visible = cache.colunas ?? [];

      // Se nao houver colunas marcadas, retorna as colunas inteiras
      if (!visible.length) return columns;

      // Filtra as colunas totais, baseadas nas visiveis
      let items = columns.filter((column) => visible.includes(column.value));

      const widths = cache.columnsWidth;

      if (widths) {
        items = items.map((header) => ({
          ...header,
          width: get(widths, header.value),
        }));
      }

      // Reordena a lista, baseada na ordem das colunas visiveis
      return sortBy(items, (item) => visible.indexOf(item.value));
    },

    /**
     * Captura o texto visível da coluna, através do valor da mesma
     */
    [getters.MODULO.COLUMN_TITLE]: (_, _getters) => (modulo, value) => {
      // Captura as colunas do módulo
      const columns = _getters[getters.MODULO.COLUMNS](modulo);

      // Procura pelo valor, a coluna do parametro
      let column = columns.find((column) => column.value === value);

      // Se não encontrar a coluna, tenta buscar pelo alias
      if (!column) column = columns.find((column) => column.from === value);

      // Retorna o text da coluna, ou null
      return column ? column.text : null;
    },

    /**
     * Função que retorna se o módulo parâmetro é do config ou não
     */
    [getters.MODULO.IS_CONFIG]: (state) => (modulo) =>
      state.configModulos.includes(modulo),

    /**
     * Função que retorna o módulo atual
     */
    [getters.MODULO.CURRENT]: (state) => state.current,
  },

  mutations: {
    /**
     * Mutação que libera ou bloqueia o módulo para edição
     */
    [mutations.MODULO.LOCKED](state, locked = null) {
      state.locked = locked ?? !state.locked;
    },

    /**
     * Função que gerencia a StatusBar, atualizando a mensagem quando o status muda
     */
    [mutations.MODULO.STATUSBAR]: (state, message) => {
      state.statusbar = {
        ...state.statusbar,
        text: message,
      };
    },
  },

  actions: {
    async [actions.MODULO.AFTER_CLOSE_FICHA]({ state }, params) {
      const modulo = params.modulo ?? state.current;

      const { data } = params ?? {};

      try {
        let { afterCloseFicha } = require(`@/config/modulos/${modulo}`);

        afterCloseFicha =
          typeof afterCloseFicha === "function"
            ? afterCloseFicha(data)
            : afterCloseFicha;

        if (!afterCloseFicha || !data.id) {
          return;
        }
      } catch (e) {
        return;
      }

      return await api.post(`/${modulo}/after-close-form/${data.id}`);
    },

    /**
     * Função que altera o estado do módulo atual
     */
    [actions.MODULO.CURRENT]({ state }, modulo) {
      state.current = modulo;
    },
  },
};
