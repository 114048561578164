<template>
  <s-switch
    v-bind="$attrs"
    @change="onChanged"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { Switch as SSwitch } from "@/components/form";

import Persistent from "./persistent";

export default Persistent.extend({
  name: "PSwitch",
  components: { SSwitch },
});
</script>
