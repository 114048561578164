import API from "@/api";

import actions from "@/store/actions";
import mutations from "@/store/mutations";
import { pick } from "lodash";
// import getters from "@/store/getters";
// import mutations from "@/store/mutations";

const prefix = "/register";

const api = API.config();

export default {
  state: {
    loading: false,
  },

  mutations: {
    [mutations.REGISTER.LOADING](state, loading) {
      state.loading = loading;
    },
  },

  actions: {
    /**
     * Função que valida o Serial e o CNPJ para ver se pode registrar
     */
    [actions.REGISTER.CHECK]({ commit }, params) {
      // Commita o loading
      commit(mutations.REGISTER.LOADING, true);

      // Retorna a promessa da requisição
      return api
        .post(`${prefix}/check`, params)
        .then((response) => {
          const { data } = response;

          // if (!data) return;

          // const { emitente } = data;

          return data ?? {};
        })
        .finally(() => commit(mutations.REGISTER.LOADING, false));
    },

    /**
     * Função que valida o Serial e o CNPJ para ver se pode registrar
     */
    [actions.REGISTER.CREATE]({ commit }, params) {
      // Commita o loading
      commit(mutations.REGISTER.LOADING, true);

      // Retorna a promessa da requisição
      return api
        .post(`${prefix}`, params)
        .then((response) => {
          const { data } = response;

          if (!data) return;

          return pick(data, ["emitente", "hasUsers"]);
        })
        .finally(() => commit(mutations.REGISTER.LOADING, false));
    },

    /**
     * Função que valida o Serial e o CNPJ para ver se pode registrar
     */
    [actions.REGISTER.USER]({ commit }, params) {
      // Commita o loading
      commit(mutations.REGISTER.LOADING, true);

      // Retorna a promessa da requisição
      return api
        .post(`${prefix}/user`, params)
        .then((response) => response.data ?? {})
        .finally(() => commit(mutations.REGISTER.LOADING, false));
    },
  },
};
