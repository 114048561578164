<template>
  <v-col lg="10" offset-lg="1" :style="`height:` + screenHeight + `px;`">
    <v-row>
      <v-col cols="12" md="6" class="pb-4" order-md="1">
        <v-row>
          <p-switch
            class="my-auto"
            ignore-enter-focus
            sm="3"
            name="ativo"
            label="Ativo"
            v-model="data.ativo"
          />
          <p-text-field
            auto-focus
            sm="9"
            name="documento"
            label="Documento"
            maxlength="30"
            v-model="data.documento"
          />
          <p-select-filter
            md="12"
            name="plano_conta_id"
            label="Plano de contas"
            item-value="id"
            :item-texts="['conta', 'descricao']"
            :clearable="false"
            :items="extra.plano_contas"
            v-model="data.plano_conta_id"
          />
          <clifor-select
            :label="
              __ficha.modulo.includes('receber') ? 'Cliente' : 'Fornecedor'
            "
            :clifor="clifor"
            v-model="data.clifor_id"
          />
        </v-row>
      </v-col>
      <v-col cols="12" class="pt-2 pb-4" order-md="3">
        <v-row>
          <p-text-field
            name="descricao"
            label="Histórico"
            maxlength="255"
            sm="12"
            md="6"
            v-model="data.descricao"
          />
          <p-date-time
            sm="6"
            md="3"
            name="pagamento_dt"
            label="Quitação"
            v-model="data.pagamento_dt"
          />
          <precision-field
            sm="6"
            md="3"
            prefix="R$"
            name="vlr_pago"
            label="Valor Quitado"
            v-model="data.vlr_pago"
            disabled
          />
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="pt-3 pb-4" order-md="2">
        <v-row>
          <p-date-time
            sm="6"
            name="emissao_dt"
            label="Emissão"
            v-model="data.emissao_dt"
          />
          <p-date-time
            sm="6"
            name="vencimento_dt"
            label="Vencimento"
            v-model="data.vencimento_dt"
          />
          <p-precision-field
            sm="6"
            prefix="R$"
            name="vlr_total"
            label="Valor"
            v-model="data.vlr_total"
          />
          <p-text-field
            sm="6"
            name="numero"
            label="Número da Parcela"
            v-mask="'XXX'"
            v-model="data.numero"
          />
          <template v-if="__ficha.modulo.includes('receber')">
            <precision-field
              sm="6"
              prefix="R$"
              name="vlr_juros"
              label="Valor Juros"
              class="ml-auto"
              v-model="data.vlr_juros"
              disabled
            />
            <precision-field
              sm="6"
              prefix="R$"
              name="vlr_atual"
              label="Valor Atual"
              class="ml-auto"
              v-model="data.vlr_atual"
              disabled
            />
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mask } from "vue-the-new-mask";
import get from "lodash/get";

import {
  PTextField,
  PPrecisionField,
  PrecisionField,
  PDateTime,
  CliforSelect,
  PSelectFilter,
  DependenceTable,
  PSwitch,
} from "@/components/form";

import Clifor from "@/mixins/clifor";

import { EventBus } from "@/main";

export default Clifor.extend({
  directives: { mask },
  components: {
    PTextField,
    PPrecisionField,
    PrecisionField,
    CliforSelect,
    PDateTime,
    PSelectFilter,
    DependenceTable,
    PSwitch,
  },
  created() {
    EventBus.$on("resize", this.onResize);
  },
  beforeDestroy() {
    EventBus.$off("resize", this.onResize);
  },
  mounted() {
    this.onResize();
  },
  data: () => ({
    heightTable: 0,
    screenHeight: 0,
  }),
  methods: {
    onResize() {
      this.screenHeight = 0;
      this.heightTable = 0;

      setTimeout(() => {
        this.screenHeight = this.height() - 18;
        this.heightTable =
          this.screenHeight -
          get(this.$refs, "cabecalho.clientHeight", 0) -
          14 -
          28;
      }, 40);
    },
  },
});
</script>
