<template>
  <v-col class="pa-0" v-bind="$attrs">
    <v-card :outlined="outlined" flat class="h-100 overflow-y-auto">
      <v-list class="py-1">
        <v-list-item
          v-for="(info, i) in items"
          :key="i"
          class="px-2"
          style="min-height: unset !important"
        >
          <v-list-item-content class="pa-0 pt-1">
            <v-list-item-title style="font-size: 10px" v-text="info" />
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <slot />
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    items: { type: Array, default: () => [] },
    outlined: { type: Boolean, default: false },
  },
};
</script>
