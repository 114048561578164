import printJS from "print-js";

export const base64ToArrayBuffer = (base64) => {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

/**
 * Função que faz a impressão do documento auxiliar
 */
export const printPDF = (response, title) => {
  // Se não houver data ou não houver pdf, retorna
  if (!response.data) return;

  // cria um blob
  const blob = new Blob([response.data], {
    type: "application/pdf",
  });

  // cria uma url temporária
  const url = window.URL.createObjectURL(blob);

  // se for android ou ios, não da para abrir o visualizador por cima da janela atual
  if (window.$isAndroid() || window.$isiOS()) {
    // portanto, abre em nova aba
    return window.openUrl(url);
  }

  // abre o pdf por cima da janela atual
  return printJS({
    printable: url,
    documentTitle: title,
  });
};
