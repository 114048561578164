import { compact, filter, includes } from "lodash";
import { endOfDay, startOfDaySubtract } from "@/plugins/moment";
import { active, inactive, filterTypes } from "@/utils/filters";

/**
 * Colunas do Grid.
 */
export const columns = ({ name }) => {
  return [
    { text: "Código", value: "id", padStart: 5 },
    { text: "Código Barras", value: "gtin" },
    { text: "Descrição", value: "descricao", maxWidth: "395px" },
    { text: "Grupo", value: "grupo" },
    { text: "Unidade", value: "unidade_medida" },
    { text: "Preço em R$", value: "vlr_preco", align: "right" },
    { text: "Preço em US$", value: "vlr_indexador", align: "right" },
    { text: "Custo de Compra", value: "vlr_custo_compra", align: "right" },
    { text: "Última Compra", value: "ultima_compra_dt", type: "date" },
    { text: "Custo Médio", value: "vlr_custo_medio", align: "right" },
    { text: "Quantidade", value: "qtd_atual", align: "right" },
    { text: "Qtd. Mínima", value: "qtd_minimo", align: "right" },
    { text: "Últ. Venda", value: "ultima_venda_dt", type: "date" },
    { text: "Localização", value: "localizacao" },
    { text: "Peso", value: "peso", align: "right" },
    { text: "Comissão", value: "per_comissao", align: "right" },
    { text: "Identificador 1", value: "identificador1" },
    { text: "Identificador 2", value: "identificador2" },
    { text: "Identificador 3", value: "identificador3" },
    { text: "Identificador 4", value: "identificador4" },
    { text: "Aplicação", value: "obs" },
    { text: "Tag's", value: "tags", hiddenReport: true },
    { text: "Final da promoção", value: "promocao_final_dt", type: "date" },
    { text: "Início da promoção", value: "promocao_inicio_dt", type: "date" },
    { text: "Preço promocional", value: "vlr_promocao_por", align: "right" },
    { text: "Fator Conversão", value: "fator_conversao", align: "right" },
    { text: "Unidade de entrada", value: "entrada_unidade_medida" },
    { text: "Fornecedor", value: "clifor_razao_social" },
    { text: "Tipo item", value: "tipo_item" },
    { text: "CSOSN", value: "csosn" },
    { text: "IPPT", value: "ippt" },
    { text: "IAT", value: "iat" },
    { text: "IVA", value: "per_iva" },
    { text: "CIT", value: "st" },
    { text: "CST ICMS", value: "origem_cst" },
    { text: "CST IPI", value: "cst_ipi" },
    { text: "CST PIS/COFINS Entrada", value: "cst_pis_cofins_entrada" },
    { text: "CST PIS/COFINS Saída", value: "cst_pis_cofins_saida" },
    { text: "NCM/TIP", value: "ncm" },
    { text: "CEST", value: "cest" },
    { text: `CFOP ${name}`, value: "cfop_nfce" },
    { text: `CSOSN ${name}`, value: "csosn_nfce" },
    { text: `CST ${name}`, value: "cst_nfce" },
    { text: `Alíquota ${name}`, value: "per_icms_nfce" },
    { text: "% Lucro Bruto", value: "per_lucro_bruto", align: "right" },
    { text: "% Imposto IBPT", value: "per_iia_federal", align: "right" },
    { text: "% IPI", value: "per_ipi", align: "right" },
    { text: "% PIS Entrada", value: "per_pis_entrada", align: "right" },
    { text: "% COFINS Entrada", value: "per_cofins_entrada", align: "right" },
    { text: "% PIS Saída", value: "per_pis_saida", align: "right" },
    { text: "% PIS COFINS Saída", value: "per_cofins_saida", align: "right" },
    // Colunas de relatórios
    { text: "Qtd X Preço", value: "qtd_preco", extra: true, hidden: true },
    {
      text: "Qtd X Custo compra",
      value: "qtd_custo_compra",
      extra: true,
      hidden: true,
    },
    {
      text: "Qtd X Custo médio",
      value: "qtd_custo_medio",
      extra: true,
      hidden: true,
    },
  ];
};

/**
 * Lista de identificadores para a ficha
 */
export const identificadores = new Array(4)
  .fill(null)
  .map((_, i) => `lbl_tb_produtos_identificador_${i + 1}`);

/**
 * Dependências do módulo
 */
export const dependencies = [
  {
    tab: "tab-cod-barras",
    name: "codigo_barras",
    modulo: "codigo-barras",
    target: ["produto_id"],
  },
  {
    tab: "tab-grade",
    name: "grade",
    modulo: "produto-grades",
    target: ["produto_id"],
  },
  {
    tab: "tab-composicao",
    name: "composicao",
    modulo: "produto-compostos",
    target: ["produto_id"],
  },
];

/**
 * Ações de contexto da tabela
 */
export const context = function (this: any) {
  const isRegistroActive = this.registro && this.registro.ativo;

  return [
    {
      id: "ativo",
      title: "Ativo",
      modulo: "estoque",
      icon: !isRegistroActive ? "mdi-checkbox-blank-outline" : "mdi-check",
    },
    {
      id: "etiquetas",
      title: "Etiquetas",
      descricao: this.registro?.descricao,
      icon: "mdi-tag-outline",
    },
  ];
};

/**
 * Ficha default (novo)
 */
export const ficha = () => ({
  ativo: true,
  tipo_item: "00",
  vlr_preco: "0,01",
  ippt: "T",
  iat: "T",
  unidade_medida: "UN",
});

/**
 * Função que define a estrutura da tela de replicar informações
 *
 * @returns {Object}
 */
export const replicate = function (this: any): any {
  const nomeCupomFiscal = this.$nomeCupomFiscal;

  return {
    // Fazer request para dados extra
    extra: true,
    columns: (_extra: any) => [
      {
        text: "Grupo",
        value: "grupo_id",
        options: {
          showValueText: true,
          type: "select",
          items: _extra.grupo,
          itemValue: "id",
          itemText: "descricao",
        },
      },
      {
        text: "Unidade Medida",
        value: "unidade_medida",
        options: {
          showValueText: true,
          type: "select",
          items: _extra.unidade_medida,
          itemTexts: ["sigla", "descricao"],
          itemValue: "sigla",
          itemText: "descricao",
        },
      },
      {
        text: "Qtd Mínima",
        value: "qtd_minimo",
        options: { type: "precision" },
      },
      {
        text: "Código de Barras",
        value: "gtin",
        options: { maxLength: "14" },
      },
      {
        text: "Comissão",
        value: "per_comissao",
        options: { type: "precision" },
      },
      {
        text: "Lucro Bruto",
        value: "per_lucro_bruto",
        options: { type: "precision" },
      },
      { text: "Localização", value: "localizacao", options: { maxlength: 10 } },

      {
        text: "Tipo do item",
        value: "tipo_item",
        options: {
          showValueText: true,
          type: "select",
          items: extra.tipo_item,
        },
      },
      { text: "NCM", value: "ncm", options: { maxlength: 13 } },
      { text: "CEST", value: "cest", options: { maxlength: 7 } },
      { text: "IVA", value: "per_iva", options: { type: "precision" } },
      { text: "CIT", value: "st", options: { maxlength: 3 } },
      {
        text: "Origem",
        value: "origem",
        options: { showValueText: true, type: "select", items: extra.origem },
      },
      {
        text: "CST",
        value: "cst",
        options: { showValueText: true, type: "select", items: extra.cst },
      },
      {
        text: "CSOSN",
        value: "csosn",
        options: { showValueText: true, type: "select", items: extra.csosn },
      },
      {
        text: `CFOP ${nomeCupomFiscal}`,
        value: "cfop_nfce",
        options: {
          showValueText: true,
          type: "select",
          items: extra.cfop_nfce,
        },
      },
      {
        text: `CSOSN ${nomeCupomFiscal}`,
        value: "csosn_nfce",
        options: {
          showValueText: true,
          type: "select",
          items: extra.csosn_nfce,
        },
      },
      {
        text: `CST ${nomeCupomFiscal}`,
        value: "cst_nfce",
        options: { showValueText: true, type: "select", items: extra.cst_nfce },
      },
      {
        text: `Alíquota ${nomeCupomFiscal}`,
        value: "per_icms_nfce",
        options: { type: "precision" },
      },

      {
        text: "CST PIS/COFINS Saida",
        value: "cst_pis_cofins_saida",
        options: {
          showValueText: true,
          type: "select",
          items: extra.cst_pis_cofins_saida,
        },
      },
      {
        text: "PIS Saida",
        value: "per_pis_saida",
        options: { type: "precision" },
      },
      {
        text: "COFINS Saida",
        value: "per_cofins_saida",
        options: { type: "precision" },
      },

      {
        text: "CST PIS/COFINS Entrada",
        value: "cst_pis_cofins_entrada",
        options: {
          showValueText: true,
          type: "select",
          items: extra.cst_pis_cofins_entrada,
        },
      },
      {
        text: "PIS Entrada",
        value: "per_pis_entrada",
        options: { type: "precision" },
      },
      {
        text: "COFINS Entrada",
        value: "per_cofins_entrada",
        options: { type: "precision" },
      },

      {
        text: "CST IPI",
        value: "cst_ipi",
        options: { showValueText: true, type: "select", items: extra.cst_ipi },
      },
      { text: "IPI", value: "per_ipi", options: { type: "precision" } },
    ],
  };
};

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = filter(columns({ name: "" }), (column: any) =>
  // Retorna as colunas que as incluem
  includes(
    [
      "id",
      "gtin",
      "descricao",
      "grupo",
      "unidade_medida",
      "vlr_preco",
      "vlr_indexador",
    ],
    column.value
  )
);

/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = [
  "ibpt",
  "per_iia_federal",
  "per_iia_estadual",
  "per_iia_municipal",
  "unidade_medida",
  "galeria",
  "qtd_atual",
  "vlr_preco",
  "vlr_preco_original",
  "promocao_ativa",
  "tem_codigo_barras",
  "tem_grade",
  "tem_composicao",
  "natureza",
];

/**
 * Função que valida se o item é um servico
 */
const isServico = (data) => data.tipo_item && data.tipo_item === "09";

/**
 * Tabs da ficha
 */
export const tabs = [
  { item: "tab-principal", title: "Principal" },
  { item: "tab-icms", title: "ICMS", disabled: (data) => !data.id },
  {
    item: "tab-piscofins-ipi",
    title: "PIS/COFINS e IPI",
    disabled: (data) => !data.id,
  },
  // { item: "tab-grade", title: "Grade", disabled: (data) => isServico(data) },
  {
    item: "tab-composicao",
    title: "Composição",
    disabled: (data) => !data.id || isServico(data),
  },
  { item: "tab-foto", title: "Foto", disabled: (data) => !data.id },
  { item: "tab-promocao", title: "Promoção", disabled: (data) => !data.id },
  {
    item: "tab-conversao",
    title: "Conversão",
    disabled: (data) => !data.id || isServico(data),
  },
  {
    item: "tab-cod-barras",
    title: "Código de barras",
    disabled: (data) => !data.id || isServico(data),
  },
  { item: "tab-tags", title: "Tags", disabled: (data) => !data.id },
];

/**
 * Ações do submenu
 */
export const subactions = function (this: any) {
  // Retorna a lista
  return [
    {
      title: "Arquivo",
      items: compact([
        {
          title: "Orçamentos",
          route: { name: "modulo", params: { modulo: "orcamento" } },
        },
        {
          title: "Grupos",
          route: { name: "modulo", params: { modulo: "grupos" } },
          divider: true,
        },
        // { id: "", title: "Inventário" },
        {
          id: "aumento-preco",
          title: "Efetuar aumento de preço",
          divider: true,
        },
        {
          id: "terminal-consulta",
          title: "Terminal Consulta Preço",
        },
        {
          id: "balancas",
          title: "Balanças",
          divider: true,
        },
        // {
        //   id: "",
        //   title: "FCI - Ficha de conteúdo de Importação",
        //   divider: true
        // },
        {
          id: "etiquetas",
          title: "Etiquetas",
          icon: "mdi-tag-outline",
          divider: true,
          types: [
            {
              id: "single",
              label: `Imprimir ${this.registro?.descricao}`,
              descricao: this.registro?.descricao,
            },
            {
              id: "filtered",
              label: "Imprimir Produtos Filtrados",
              descricao: "Produtos Filtrados",
            },
          ],
        },
        {
          guarded: true,
          id: "sintegra",
          title: "SINTEGRA",
        },
        {
          guarded: true,
          id: "efd-icms-ipi",
          title: "SPED FISCAL - ICMS/IPI",
        },
        {
          id: "inventario",
          title: "Inventário",
        },
      ]),
    },
    {
      title: "Relatórios",
      type: "report",
      items: reports.bind(this)(),
    },
    {
      title: "Exibir",
      type: "filter",
      items: [
        // {
        //   title: "Abaixo do mínimo",
        //   filter: [isNotNull('qtd_minimo', []), lessThanOrEqualTo("qtd_atual", ["qtd_minimo"], "and", "double")]
        // },
        {
          title: "Produtos inativos",
          filter: inactive(),
        },
        {
          title: "Produtos ativos",
          filter: active(),
        },
        {
          title: "Todos",
          filter: null,
        },
      ],
    },
  ];
};

/**
 * Relatórios
 */
export const reports = function (this: any) {
  // Captura o registro selecionado
  const disabled = !this.registro || !this.registro.id;
  const descricao = this.registro.descricao || "";

  const filterDateInterval = {
    // Iniciando 30 dias antes de hoje
    queryStart: startOfDaySubtract(),
    // Terminando hoje
    queryEnd: endOfDay(),
  };

  return [
    {
      id: "visualizar",
      title: `Movimentação do item: ${descricao}`,
      registro_id: this.registro.id,
      disabled,
    },
    {
      id: "receitas",
      title: "Composição",
      columns: true,
      divider: true,
    },
    {
      id: "relatorio_vendas",
      title: "Vendas (Nota Fiscal)",
      type: "form-report",
      form: [
        {
          title: "Vendas (Nota Fiscal)",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
        {
          title: "Selecione abaixo as operações que devem ser listadas",
          search: {
            id: "cfops",
            target: "cfop",
            value: "(5|6|7)%",
            path: "/naturezas-operacao/search",
          },
          default: {
            cfops: [],
          },
          type: filterTypes.CFOP,
        },
        {
          title: "Escolha o modelo do relatório",
          type: filterTypes.CHOOSE_ONE,
          default: {
            choose: "icms",
          },
          options: [
            {
              text: "Relatório de ICMS",
              value: "icms",
            },
            {
              text: "Item por item",
              value: "items",
            },
          ],
        },
      ],
    },
    {
      id: "servicos_nf",
      title: "Serviços (Nota Fiscal)",
      type: "form-report",
      form: [
        {
          title: "Serviços (Nota Fiscal)",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "ipi",
      title: "Relatório de IPI",
      type: "form-report",
      form: [
        {
          title: "Relatório de IPI",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "pis_cofins",
      title: "PIS/COFINS",
      type: "form-report",
      form: [
        {
          title: "PIS/COFINS",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "resumo_vendas",
      title: "Resumo das vendas",
      type: "form-report",
      form: [
        {
          title: "Resumo das vendas",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
        {
          title: "Selecione abaixo as operações que devem ser listadas",
          search: {
            id: "cfops",
            target: "cfop",
            value: "(5|6|7)%",
            path: "/naturezas-operacao/search",
          },
          default: {
            cfops: [],
          },
          type: filterTypes.CFOP,
        },
      ],
      divider: true,
    },
    {
      id: "relatorio_compras",
      title: "Compras",
      type: "form-report",
      form: [
        {
          title: "Relatório de compras",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
        {
          title: "Selecione abaixo as operações que devem ser listadas",
          search: {
            id: "cfops",
            target: "cfop",
            value: "(1|2|3)%",
            path: "/naturezas-operacao/search",
          },
          default: {
            cfops: [],
          },
          type: filterTypes.CFOP,
        },
        {
          title: "Escolha o modelo do relatório",
          type: filterTypes.CHOOSE_ONE,
          default: {
            choose: "icms",
          },
          options: [
            {
              text: "Relatório de ICMS",
              value: "icms",
            },
            {
              text: "Item por item",
              value: "items",
            },
          ],
        },
      ],
    },
    {
      id: "resumo_compras",
      title: "Resumo das compras",
      type: "form-report",
      form: [
        {
          title: "PIS/COFINS",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "vendas",
      title: "Vendas (Cupom Fiscal)",
      modulo: "cupons-fiscais",
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
          children: [
            {
              label: "Número do Caixa",
              queryStart: "numero_caixa",
              default: { numero_caixa: "" },
            },
          ],
        },
      ],
    },
    {
      id: "cupom_monofasicos",
      title: "Produtos Monofásicos (Cupom Fiscal)",
      modulo: "cupons-fiscais",
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "venda_monofasicos",
      title: "Produtos Monofásicos (Nota Fiscal)",
      modulo: "saida",
      type: "form-report",
      form: [
        {
          title: "Selecione o período",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "curva_abc",
      title: "Curva ABC do estoque",
      type: "form-report",
      form: [
        {
          title: "Curva ABC do estoque",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    // { id: "", title: "Orçamentos pendentes" },
    // { id: "", title: "Previsão de compras" },
    // { id: "", title: "Movimentação dos itens filtrados (Kardex ®)" },
    // { id: "", title: "Lista de grades" },
    // { id: "", title: "Lista de preços" },
    // { id: "", title: "Catálogo de produtos", divider: true },
  ];
};

/**
 * Cache default
 */
export const cache = {
  colunas: [
    "id",
    "gtin",
    "descricao",
    "unidade_medida",
    "qtd_atual",
    "vlr_preco",
    "tipo_item",
    "grupo",
  ],
  filtros: [],
  ordenar: [
    {
      coluna: "id",
      ordem: "asc",
    },
  ],
};

/**
 * Dados extra da ficha
 */
export const extra = {
  tipo_item: [
    { value: "00", text: "Mercadoria para revenda" },
    { value: "01", text: "Matéria-Prima" },
    { value: "02", text: "Embalagem" },
    { value: "03", text: "Produto em Proceso" },
    { value: "04", text: "Produto Acabado" },
    { value: "05", text: "Subproduto" },
    { value: "06", text: "Produto Intermediário" },
    { value: "07", text: "Material de Uso e Consumo" },
    { value: "08", text: "Ativo Imobilizado" },
    { value: "09", text: "Serviços" },
    { value: "10", text: "Outros insumos" },
    { value: "99", text: "Outros" },
  ],
  ippt: [
    { value: "P", text: "Produção própria" },
    { value: "T", text: "Produção por terceiros" },
  ],
  iat: [
    { value: "A", text: "Arredondamento" },
    { value: "T", text: "Truncamento" },
  ],
  csosn: [
    {
      value: "101",
      text: "Tributada pelo Simples Nacional com permissão de crédito",
    },
    {
      value: "102",
      text: "Tributada pelo Simples Nacional sem permissão de crédito",
    },
    {
      value: "103",
      text: "Isenção do ICMS no Simples Nacional para faixa de receita bruta",
    },
    {
      value: "201",
      text: "Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
    },
    {
      value: "202",
      text: "Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
    },
    {
      value: "203",
      text: "Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária",
    },
    { value: "300", text: "Imune" },
    { value: "400", text: "Não tributada pelo Simples Nacional" },
    {
      value: "500",
      text: "ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
    },
    { value: "900", text: "Outros" },
  ],
  origem: [
    {
      value: "0",
      text: "Nacional, exceto as indicadas nos códigos 3 a 5",
    },
    {
      value: "1",
      text: "Estrangeira - Importação direta, exceto a indicada no código 6",
    },
    {
      value: "2",
      text: "Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7",
    },
    {
      value: "3",
      text: "Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%",
    },
    {
      value: "4",
      text: "Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei nº 288/67 e as Leis nºs 8.248/91, 8.387/91, 10.176/01 e 11.484/07",
    },
    {
      value: "5",
      text: "Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%",
    },
    {
      value: "6",
      text: "Estrangeira - Importação direta, sem similar nacional, constante em lista de Resolução CAMEX",
    },
    {
      value: "7",
      text: "Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução CAMEX",
    },
  ],
  cst: [
    { value: "00", text: "Tributada integralmente" },
    {
      value: "10",
      text: "Tributada e com cobrança do ICMS por substituição tributária",
    },
    { value: "20", text: "Com redução da BC" },
    {
      value: "30",
      text: "Isenta / não tributada e com cobrança do ICMS por substituição tributária",
    },
    { value: "40", text: "Isenta" },
    { value: "41", text: "Não tributada" },
    { value: "50", text: "Com suspensão" },
    { value: "51", text: "Com diferimento" },
    {
      value: "60",
      text: "ICMS cobrado anteriormente por substituição tributária",
    },
    {
      value: "70",
      text: "Com redução da BC e cobrança do ICMS por substituição tributária",
    },
    { value: "90", text: "Outras" },
  ],
  cst_ipi: [
    { value: "50", text: "Saída Tributada" },
    { value: "51", text: "Saída Tributada com Alíquota Zero" },
    { value: "52", text: "Saída Isenta" },
    { value: "53", text: "Saída Não-Tributada" },
    { value: "54", text: "Saída Imune" },
    { value: "55", text: "Saída com Suspensão" },
    { value: "99", text: "Outras saídas" },
  ],
  cst_pis_cofins_entrada: [
    {
      value: "50",
      text: "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno.",
    },
    {
      value: "51",
      text: "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno.",
    },
    {
      value: "52",
      text: "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação.",
    },
    {
      value: "53",
      text: "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno.",
    },
    {
      value: "54",
      text: "Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação.",
    },
    {
      value: "55",
      text: "Operação com Direito a Crédito - Vinculada a Receitas Não Tributadas no Mercado Interno e de Exportação.",
    },
    {
      value: "56",
      text: "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação.",
    },
    {
      value: "60",
      text: "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno.",
    },
    {
      value: "61",
      text: "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno.",
    },
    {
      value: "62",
      text: "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação.",
    },
    {
      value: "63",
      text: "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno.",
    },
    {
      value: "64",
      text: "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação.",
    },
    {
      value: "65",
      text: "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação.",
    },
    {
      value: "66",
      text: "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação.",
    },
    { value: "67", text: "Crédito Presumido - Outras Operações." },
    {
      value: "70",
      text: "Operação de Aquisição sem Direito a Crédito.",
    },
    { value: "71", text: "Operação de Aquisição com Isenção." },
    { value: "72", text: "Operação de Aquisição com Suspensão." },
    { value: "73", text: "Operação de Aquisição a Alíquota Zero." },
    {
      value: "74",
      text: "Operação de Aquisição sem Incidência da Contribuição.",
    },
    {
      value: "75",
      text: "Operação de Aquisição por Substituição Tributária.",
    },
    { value: "98", text: "Outras Operações de Entrada." },
    { value: "99", text: "Outras Operações." },
  ],
  cst_pis_cofins_saida: [
    { value: "01", text: "Operação Tributável com Alíquota Básica." },
    {
      value: "02",
      text: "Operação Tributável com Alíquota Diferenciada.",
    },
    {
      value: "03",
      text: "Operação Tributável com Alíquota por Unidade de Medida de Produto.",
    },
    {
      value: "04",
      text: "Operação Tributável Monofásica - Revenda a Alíquota Zero.",
    },
    {
      value: "05",
      text: "Operação Tributável por Substituição Tributária.",
    },
    { value: "06", text: "Operação Tributável a Alíquota Zero." },
    { value: "07", text: "Operação Isenta da Contribuição." },
    { value: "08", text: "Operação sem Incidência da Contribuição." },
    { value: "09", text: "Operação com Suspensão da Contribuição." },
    { value: "49", text: "Outras Operações de Saída." },
    { value: "99", text: "Outras Operações." },
  ],
  cst_nfce: [
    { value: "00", text: "Tributada integralmente" },
    { value: "20", text: "Com redução da BC" },
    { value: "40", text: "Isenta" },
    { value: "41", text: "Não tributada" },
    {
      value: "60",
      text: "ICMS cobrado anteriormente por substituição tributária",
    },
    { value: "90", text: "Outras" },
  ],
  csosn_nfce: [
    {
      value: "102",
      text: "Tributada pelo Simples Nacional sem permissão de crédito",
    },
    {
      value: "103",
      text: "Isenção do ICMS no Simples Nacional para faixa de receita bruta",
    },
    { value: "300", text: "Imune" },
    { value: "400", text: "Não tributada pelo Simples Nacional" },
    {
      value: "500",
      text: "ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
    },
    { value: "900", text: "Outros" },
  ],
  cfop_nfce: [
    { value: "5101", text: "Venda de podução do estabelecimento;" },
    { value: "5102", text: "Venda de mercadoria de terceiros;" },
    {
      value: "5103",
      text: "Venda de produção do estabelecimento efetuada fora do estabelecimento;",
    },
    {
      value: "5104",
      text: "Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento;",
    },
    {
      value: "5115",
      text: "Venda de mercadoria de terceiros, recebida anteriormente em consignação mercantil;",
    },
    {
      value: "5405",
      text: "Venda de mercadoria de terceiros, sujeita a ST, como contribuinte substituído;",
    },
    {
      value: "5656",
      text: "Venda de combustível ou lubrificante de terceiros, destinados a consumidor final;",
    },
    {
      value: "5667",
      text: "Venda de combustível ou lubrificante a consumidor ou usuário final estabelecido em outra UF;",
    },
    {
      value: "5933",
      text: "Prestação de serviço tributado pelo ISSQN (Notas Fiscal conjugada);",
    },
  ],
};

/**
 * Valores da tabela de Tags
 */
export const tagsTabValues = [
  {
    nome: "cListServ",
    exemplo: "14.01",
    descricao: "Item da lista de serviços; Padrão ABRASF",
  },
  {
    nome: "cBenef",
    exemplo: "000000000000",
    descricao: "Código de Benefício Fiscal na UF aplicado ao item",
  },
  {
    nome: "motDesICMS",
    exemplo: "00",
    descricao:
      "Motivo da desoneração do ICMS: 3=Uso na agropecuária; 9=Outros; 12=Órgão de fomento e desenvolvimento agropecuário",
  },
  {
    nome: "motDesICMSST",
    exemplo: "00",
    descricao:
      "Motivo da desoneração do ICMS - ST: 3=Uso na agropecuária; 9=Outros; 12=Órgão de fomento e desenvolvimento agropecuário",
  },
  {
    nome: "FCP",
    categorias: "fcp",
    exemplo: "0.00",
    descricao: "Fundo de Combate a Pobreza",
  },
  {
    nome: "FCPST",
    categorias: "fcp",
    exemplo: "0.00",
    descricao: "Fundo de Combate a Pobreza ST",
  },

  // Início tags NT 2020.005 v1.20
  {
    nome: "pFCPDif",
    categorias: "fcp",
    descricao:
      "Percentual do diferimento do ICMS relativo ao Fundo de Combate à Pobreza (FCP)",
  },

  /* Início TAGS ANP */
  {
    nome: "descANP",
    exemplo: "ANP",
    categorias: "comb",
    descricao: "Descrição do produto conforme ANP",
  },
  {
    nome: "pGLP",
    exemplo: "0.0000",
    categorias: "comb",
    descricao: "Informar em número decimal o % do GLP derivado de petróleo",
  },
  {
    nome: "pGNn",
    exemplo: "0.0000",
    categorias: "comb",
    descricao: "Informar em número decimal o % do Gás Natural Nacional",
  },
  {
    nome: "pGNi",
    exemplo: "0.0000",
    categorias: "comb",
    descricao: "Informar em número decimal o % do Gás Natural Importado",
  },
  {
    nome: "vPart",
    exemplo: "0.00",
    categorias: "comb",
    descricao: "Valor de partida",
  },
  {
    nome: "cProdANP",
    exemplo: "00000001",
    categorias: "comb",
    descricao: "Código de produto da ANP",
  },
  /* FIM TAGS ANP */
  {
    nome: "uTrib",
    exemplo: "UN",
    descricao: "Unidade tributável",
  },
  {
    nome: "qTrib",
    exemplo: "0",
    descricao: "Quantidade tributável",
  },
  {
    nome: "cEANTrib",
    descricao: "EAN tributável do Produto",
  },
  {
    nome: "cBarra",
    descricao: "Código de barras diferente do padrão GTIN",
  },
  {
    nome: "cBarraTrib",
    descricao:
      "Código de Barras da unidade tributável que seja diferente do padrão GTIN",
  },
  {
    nome: "cProd",
    descricao: "Código Original do fornecedor do Produto ou Serviço",
  },
  {
    nome: "vUnid",
    exemplo: "0.99",
    descricao: "Valor do IPI por Unidade",
  },
  {
    nome: "cServico",
    exemplo: "00000000000000000001",
    descricao: "Código do serviço prestado dentro do município",
  },
  {
    nome: "pRedBC",
    exemplo: "0.00",
    descricao: "Percentual da redução de BC na NFC-e",
  },
  {
    nome: "indSomaPISST",
    exemplo: "1",
    descricao:
      "0 = Valor do PISST não compõe o valor total da NF-e; 1 = Valor do PISST compõe o valor total da NF-e",
  },
  {
    nome: "indSomaCOFINSST",
    exemplo: "1",
    descricao:
      "0 = Valor do COFINSST não compõe o valor total da NF-e; 1 = Valor do COFINSST compõe o valor total da NF-e",
  },
  {
    nome: "VAL",
    exemplo: "007",
    descricao: "Validade do produto em dias",
  },
  {
    nome: "Obs1",
    categorias: "obs",
    exemplo: "Observação 1",
    descricao: "Esta observação será mostrada no corpo da NF",
  },
  {
    nome: "Obs2",
    categorias: "obs",
    exemplo: "Observação 2",
    descricao: "Esta observação será mostrada no corpo da NF",
  },
  {
    nome: "Obs3",
    categorias: "obs",
    exemplo: "Observação 3",
    descricao: "Esta observação será mostrada no corpo da NF",
  },
  {
    nome: "Obs4",
    categorias: "obs",
    exemplo: "Observação 4",
    descricao: "Esta observação será mostrada no corpo da NF",
  },
  {
    nome: "Obs5",
    categorias: "obs",
    exemplo: "Observação 5",
    descricao: "Esta observação será mostrada no corpo da NF",
  },
  {
    nome: "Obs6",
    categorias: "obs",
    exemplo: "Observação 6",
    descricao: "Esta observação será mostrada no corpo da NF",
  },
  {
    nome: "Obs7",
    categorias: "obs",
    exemplo: "Observação 7",
    descricao: "Esta observação será mostrada no corpo da NF",
  },
  {
    nome: "Obs8",
    categorias: "obs",
    exemplo: "Observação 8",
    descricao: "Esta observação será mostrada no corpo da NF",
  },
  {
    nome: "Obs9",
    categorias: "obs",
    exemplo: "Observação 9",
    descricao: "Esta observação será mostrada no corpo da NF",
  },

  // Vendas de veículos novos
  {
    nome: "tpOp",
    categorias: "veicProd",
    exemplo: "2",
    descricao:
      "1=Venda concessionária, 2=Faturamento direto para consumidor final, 3=Venda direta (frotista, governo, ...), 0=Outros",
  },
  {
    nome: "chassi",
    categorias: "veicProd",
    descricao: "VIN (código-identificação-veículo)",
  },
  {
    nome: "cCor",
    categorias: "veicProd",
    descricao: "Código da cor de cada montadora",
  },
  {
    nome: "xCor",
    categorias: "veicProd",
  },
  {
    nome: "nMotor",
    categorias: "veicProd",
    descricao: "Número de Motor",
  },
  {
    nome: "cCorDENATRAN",
    categorias: "veicProd",
    descricao: "Código da Cor (ver tab. DENATRAN)",
  },
  {
    nome: "tpRest",
    categorias: "veicProd",
    descricao:
      "Restrição: 0 - Não há; 1 - Alienação Fiduciária; 2 - Arrendamento Mercantil; 3 - Reserva de Domínio; 4 - Penhor de veículos; 9 - Outras.",
  },
  {
    nome: "pot",
    categorias: "veicProd",
    exemplo: "40",
    descricao:
      "Potência Motor (CV) Potência máxima do motor do veículo em cavalo vapor",
  },
  {
    nome: "cilin",
    categorias: "veicProd",
    exemplo: "4",
    descricao:
      "Cilindradas Capacidade voluntária do motor expressa em centímetros cúbicos (CC). (cilindradas) (v2.0)",
  },
  {
    nome: "pesoL",
    categorias: "veicProd",
    exemplo: "9.9999",
    descricao: "Peso líquido em toneladas - 4 casas decimais",
  },
  {
    nome: "pesoB",
    categorias: "veicProd",
    exemplo: "9.9999",
    descricao: "Peso bruto total em toneladas - 4 casas decimais",
  },
  {
    nome: "tpComb",
    categorias: "veicProd",
    exemplo: "02",
    descricao: "Tipo de combustível",
  },
  {
    nome: "CMT",
    categorias: "veicProd",
    exemplo: "9.9999",
    descricao:
      "Capacidade máxima de Tração CMT em toneladas - 4 casas decimais (v2.0)",
  },
  {
    nome: "dist",
    categorias: "veicProd",
    exemplo: "99",
    descricao: "Distância entre eixos",
  },
  {
    nome: "anoMod",
    categorias: "veicProd",
    exemplo: "2020",
    descricao: "Ano modelo de fabricação",
  },
  {
    nome: "anoFab",
    categorias: "veicProd",
    exemplo: "2020",
    descricao: "Ano de fabricação",
  },
  {
    nome: "tpPint",
    categorias: "veicProd",
    exemplo: "",
    descricao: "Tipo de pintura",
  },
  {
    nome: "tpVeic",
    categorias: "veicProd",
    exemplo: "01",
    descricao: "Tipo de veículo: Utilizar tabela RENAVAM",
  },
  {
    nome: "espVeic",
    categorias: "veicProd",
    exemplo: "1",
    descricao: "espécie de veículo: Utilizar tabela RENAVAM",
  },
  {
    nome: "VIN",
    categorias: "veicProd",
    exemplo: "N",
    descricao:
      "Condição do VIN Informa se o veículo tem VIN (chassi) remarcado; R=Remarcado, N=Normal",
  },
  {
    nome: "condVeic",
    categorias: "veicProd",
    exemplo: "1",
    descricao: "Condição do veículo: 1=Acabado, 2=Inacabado, 3=Semiacabado",
  },
  {
    nome: "cMod",
    categorias: "veicProd",
    exemplo: "",
    descricao: "Código Marca Modelo: Utilizar Tabela RENAVAM",
  },
  {
    nome: "lota",
    categorias: "veicProd",
    exemplo: "5",
    descricao:
      "Capacidade máxima de lotação, Quantidade máxima permitida de passageiros sentados, inclusive o motorista. (v2.0)",
  },
  // Fim tags de venda de veículos
  // Início tags armamento
  {
    nome: "nSerie",
    categorias: "veicProd,arma",
    descricao:
      "[Veículos Novos] Serial (série) do veículo; [Armamento] Número de série da Arma",
  },
  {
    nome: "tpArma",
    categorias: "arma",
    exemplo: "0=Uso permitido; 1=Uso restrito;",
    descricao: "Indicador do tipo de arma de fogo",
  },
  {
    nome: "nCano",
    categorias: "arma",
    descricao: "Número de série do cano",
  },
  {
    nome: "descr",
    categorias: "arma",
    descricao:
      "Descrição completa da arma, compreendendo: calibre, marca, capacidade, tipo de funcionamento, comprimento e demais elementos que permitam a sua perfeita identificação",
  },
];

/**
 * Colunas da tabela de Tags
 */
export const tagsTabHeaders = [
  {
    text: "Nome",
    value: "nome",
    noEditable: true,
    maxWidth: "120px",
  },
  {
    text: "Valor",
    value: "valor",
    maxWidth: "120px",
  },
  {
    text: "Exemplo",
    value: "exemplo",
    noEditable: true,
    maxWidth: "120px",
  },
  {
    text: "Descrição",
    value: "descricao",
    noEditable: true,
    width: "650px",
  },
];
