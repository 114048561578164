<template>
  <v-menu
    offset-y
    top
    left
    max-width="90vw"
    :min-width="$isMobile ? '80vw' : '500'"
    :value="value"
    :close-on-content-click="false"
    @input="$emit('input', $event)"
  >
    <template #activator="{ on, attrs }">
      <sale-btn
        color="saleRed"
        nome="F10"
        descricao="Menu"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <!-- width="500" -->
    <v-list
      dense
      width="100%"
      height="calc(100vh - 124px)"
      class="overflow-y-auto"
      v-show="value"
    >
      <template v-for="(item, i) in configs">
        <v-list-group
          no-action
          append-icon="mdi-chevron-right"
          :key="i"
          :class="[
            highlighted === i &&
              subhighlighted === -1 &&
              'v-list-group-item__highlighted',
          ]"
          :value="expanded[i]"
          @input="(value) => (expanded[i] = value)"
          @click="
            () => {
              expanded[i] = !expanded[i];
              highlighted = i;
            }
          "
          v-if="item.children"
        >
          <template #activator>
            <v-list-item dense ref="``">
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-for="(child, j) in item.children">
            <v-list-item
              style="padding: 0px"
              :key="j"
              :class="[subhighlighted === j && 'v-list-item-item__highlighted']"
              @click="() => onClick(child, item)"
            >
              <v-list-item-action class="my-0 mx-2">
                <v-checkbox
                  disabled
                  :input-value="data | json(child.key ? child.key : child.id)"
                  v-if="child.type && child.type.includes('boolean')"
                />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title v-text="child.title" />
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="child.divider" :key="`d-${j}`" />
          </template>
        </v-list-group>

        <v-list-item
          :class="[
            highlighted === i &&
              subhighlighted === -1 &&
              'v-list-item-item__highlighted',
          ]"
          dense
          :key="i"
          @click="() => onClick(item)"
          v-else
        >
          <v-list-item-title v-text="item.title" />
        </v-list-item>
        <v-divider v-if="item.divider" :key="`d-${i}`" />
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { get, includes } from "lodash";
import { configs } from "@/config/modulos/cupons-fiscais";

import SaleBtn from "@/components/form/cupons/SaleBtn.vue";

export default {
  components: {
    SaleBtn,
  },
  props: {
    value: {},
    storage: { type: Function },
  },
  filters: {
    json(value, key) {
      return get(value, key);
    },
  },
  data: () => ({
    expanded: {},
    data: {},

    highlighted: 0,
    subhighlighted: -1,
  }),
  computed: {
    configs() {
      return configs.bind(this)();
    },
  },
  methods: {
    onClick(...params) {
      this.highlighted = 0;
      this.subhighlighted = -1;
      this.expanded = {};

      this.$nextTick(() => {
        this.$emit("click", ...params);
      });
    },
    onKeyDown(event) {
      if (!this.value) return;

      const available = [
        "ArrowUp",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
        "Enter",
        "NumpadEnter",
      ];

      if (!includes(available, event.code)) return;

      const item = get(this.configs, `${this.highlighted}`, {});

      const child = item.children ?? [];

      if (includes(["Enter", "NumpadEnter"], event.code)) {
        if (this.subhighlighted > -1)
          return this.onClick(child[this.subhighlighted], item);

        if (child.length) return (this.expanded = { [this.highlighted]: true });

        return this.onClick(item);
      }

      if (event.code === "ArrowDown") {
        if (this.expanded[this.highlighted] === true) {
          if (this.subhighlighted === child.length - 1)
            return (this.subhighlighted = 0);

          return this.subhighlighted++;
        }

        if (this.highlighted >= this.configs.length - 1)
          return (this.highlighted = 0);

        this.highlighted++;
      }

      if (event.code === "ArrowUp") {
        if (this.expanded[this.highlighted] === true) {
          if (this.subhighlighted <= 0)
            return (this.subhighlighted = child.length - 1);

          return this.subhighlighted--;
        }

        if (this.highlighted <= 0)
          return (this.highlighted = this.configs.length - 1);

        this.highlighted--;
      }

      if (!child.length) return;

      if (event.code === "ArrowRight") {
        return (this.expanded = { [this.highlighted]: true });
      }

      if (event.code === "ArrowLeft") {
        this.subhighlighted = -1;
        return (this.expanded = { [this.highlighted]: false });
      }
    },
  },
  created() {
    document.addEventListener("keydown", this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  watch: {
    value() {
      this.data = { ...this.$config, ...this.storage() };
    },
  },
};
</script>

<style lang="scss">
$color: var(--v-tabs-darken1);

.v-list-group-item__highlighted {
  .v-list-group__header {
    background-color: #{$color} !important;
  }
}
.v-list-item-item__highlighted {
  background-color: #{$color} !important;
}
</style>
