<template>
  <stepper-items v-model="value">
    <template #item-1>
      <v-col
        lg="10"
        offset-lg="1"
        class="d-flex flex-column pa-1"
        :style="`height:` + screenHeight + `px;`"
      >
        <v-flex shrink ref="cabecalho">
          <v-row no-gutters>
            <p-text-field
              type="tel"
              auto-focus
              maxlength="44"
              cols="12"
              md="6"
              name="nfe_id"
              label="ID da NF-e (Chave)"
              :readonly="data.tem_xml_nfe"
              @change="splitNfeId"
              v-mask="'############################################'"
              v-model="data.nfe_id"
            />
            <p-text-field
              type="tel"
              cols="8"
              sm="6"
              md="4"
              lg="3"
              maxlength="13"
              name="numero_serie"
              label="NF/Série"
              :readonly="!!data.id || data.tem_xml_nfe"
              @change="onSerieChanged"
              v-model="data.numero_serie"
            />
            <p-text-field
              type="tel"
              cols="4"
              sm="6"
              md="2"
              lg="3"
              name="modelo"
              label="Modelo"
              :readonly="data.tem_xml_nfe"
              v-mask="'##'"
              v-model="data.modelo"
            />
          </v-row>
          <basico-notas
            class="pt-1"
            modulo="entrada"
            :data="data"
            :extra="extra"
            :__ficha="__ficha"
          />
        </v-flex>
        <v-row>
          <v-col class="pb-1 pb-md-2">
            <dependence-table
              modulo="compra-itens"
              :height="heightTable"
              :__ficha="__ficha"
              :disabled="!data.id"
              :default-headers="headers"
              :selected="selected"
              @selected="setSelected"
              v-model="data"
            />
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #item-2>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2" class="px-1 py-0">
        <v-row class="mx-0 mt-3">
          <v-expansion-panels flat multiple v-model="expansion">
            <expansion-panel value title="Impostos">
              <impostos
                :editable="data.nfe_finalidade === '2'"
                :data="data"
                modulo="entrada"
              />
            </expansion-panel>

            <expansion-panel title="Frete / Transportadora">
              <transportadora :data="data" :extra="extra" />
            </expansion-panel>
          </v-expansion-panels>
        </v-row>

        <v-row class="mx-0 pt-1">
          <p-text-area
            rows="4"
            colClass="px-0"
            name="complemento"
            label="Informações complementares"
            v-model="data.complemento"
          />
        </v-row>
      </v-col>
    </template>

    <template #item-3>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2" class="px-1 py-0">
        <pagamentos-v2 auto-focus :data="data" :__ficha="__ficha" />
      </v-col>
    </template>
  </stepper-items>
</template>

<script>
import { mask } from "vue-the-new-mask";

import head from "lodash/head";
import includes from "lodash/includes";
import last from "lodash/last";
import split from "lodash/split";
import get from "lodash/get";

import { itensHeaders } from "@/config/modulos/entrada";

import { StepperItems } from "@/components/utils";

import {
  PTextArea,
  PTextField,
  DependenceTable,
  Impostos,
  Transportadora,
  ExpansionPanel,
  PagamentosV2,
  BasicoNotas,
} from "@/components/form";

// Mixins
import Ficha from "@/mixins/ficha";
import { EventBus } from "@/main";

export default Ficha.extend({
  directives: { mask },
  components: {
    PTextField,
    StepperItems,
    DependenceTable,
    Impostos,
    PagamentosV2,
    PTextArea,
    Transportadora,
    ExpansionPanel,
    BasicoNotas,
  },
  data: () => ({
    heightTable: 0,
    screenHeight: 0,

    expansion: [0],
  }),
  created() {
    EventBus.$on("resize", this.onResize);
  },
  mounted() {
    this.onResize();
  },
  beforeDestroy() {
    EventBus.$off("resize", this.onResize);
  },
  computed: {
    /**
     * Retorna o cabeçalho dos itens
     */
    headers() {
      return itensHeaders.bind(this)();
    },
  },
  methods: {
    onResize() {
      this.screenHeight = 0;
      this.heightTable = 0;

      setTimeout(() => {
        this.screenHeight = this.height() - 18;
        this.heightTable =
          this.screenHeight -
          get(this.$refs, "cabecalho.clientHeight", 0) -
          16 -
          16 -
          28;
      }, 40);
    },
    /**
     * Quando a série alterar
     */
    onSerieChanged(value) {
      // Captura o value
      value = !includes(value, "/")
        ? (value ?? "").length === 13
          ? value.substring(0, 9) + "/" + value.substring(10, 3)
          : value + "/001"
        : value;

      // Separa por numero e serie
      const numeroSerie = split(value, "/");

      // Define o value ajustado
      value =
        (head(numeroSerie) ?? "").toString().padStart(9, "0").substring(0, 9) +
        "/" +
        (last(numeroSerie) ?? "").toString().padStart(3, "0").substring(0, 3);

      // Commita a numeração atualizada
      this.__ficha.append({
        numero_serie: value,
      });
    },
    /**
     * Função que interpreta a chave e preenche os valores que encontrar
     */
    splitNfeId(chave) {
      // Se não houver id, retorna
      if (this.data && this.data.id) return;

      // Inicia a chave
      chave = chave ?? "";

      // Se não for uma chave válida, retorna
      if (chave.length !== 44) return;

      // Captura o cnpj/cpf
      // TODO: VERIFICAR AS CHAVES QUE TEM CPF
      // const cpfCnpj = chave.substring(6, 20);

      // Captura o modelo
      const modelo = chave.substring(20, 22);

      // Captura numero serie
      const numeroSerie =
        chave.substring(25, 34) + "/" + chave.substring(22, 25);

      // Acessa o select do clifor
      // const { clifor } = this.$refs;

      // Se houver a ref, faz o search pelo cnpj encontrado
      // if (clifor && cpfCnpj) clifor.searchByCpfCnpj(cpfCnpj);

      // Commita as alterações
      this.__ficha.append({
        numero_serie: numeroSerie,
        modelo: modelo,
        nfe_id: chave,
      });
    },
  },
  watch: {
    "__ficha.fullscreen"() {
      this.onResize();
    },
  },
});
</script>
