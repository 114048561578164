<template>
  <p-text-field
    ref="input"
    type="tel"
    :name="name"
    :label="label"
    v-mask="['###.###.###-##', '##.###.###/####-##']"
    v-bind="$attrs"
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  />
  <!-- v-model="data.cpf_cnpj" -->
</template>

<script>
import { mask } from "vue-the-new-mask";

import { PTextField } from "@/components/form";
import get from "lodash/get";

export default {
  components: { PTextField },
  directives: { mask },
  props: {
    name: { default: "cpf_cnpj" },
    label: { default: "CPF/CNPJ" },
  },
  methods: {
    focus() {
      const ref = get(this.$refs, "input.$refs.input");
      if (ref) {
        ref.getRef().focus();
      }
    },
  },
};
</script>

<style></style>
