<template>
  <v-app id="app" v-resize="onResize">
    <v-col v-if="blocked.code" cols="12" md="11" class="mx-auto mt-2">
      <status-layout :code="blocked.code">
        <template #title>
          <p class="text-h1 font-weight-bold primary--text">
            {{ blocked.code }}
          </p>
          <p class="text-h6">
            {{ blocked.message || "Erro interno do servidor" }}
          </p>
        </template>
        <template #content v-if="blocked.reasons && blocked.reasons.length">
          <p class="text-subtitle-1 font-weight-bold">
            Possíveis motivos e/ou soluções:
          </p>
          <ul class="text-body-2">
            <li v-for="(reason, i) in blocked.reasons" :key="i">
              {{ reason.text }}
            </li>
          </ul>
        </template>
      </status-layout>
    </v-col>

    <router-view v-else />

    <alert v-if="alert.active" v-model="alert.active" v-bind="alert" />

    <!-- <snackbar
      vertical
      color="background"
      text-color="text--text"
      :style="installPromotion ? 'margin-top: 92px' : ''"
      :text="`Nova atualização disponível! Clique em <strong>Atualizar</strong> para obter a última versão do <strong>${$appName}</strong>`"
      :timeout="-1"
      :value="true"
      v-model="updateExists"
      v-if="updateExists"
    >
      <template #action>
        <btn auto-focus outlined color="primary" @click="refreshApp">
          Atualizar
        </btn>
      </template>
    </snackbar> -->

    <snackbar
      vertical
      color="background"
      text-color="text--text"
      :style="
        snackbar.active || (pendingDownload && pendingDownload.text)
          ? 'margin-top: 92px'
          : ''
      "
      :text="newVersion.text"
      :timeout="-1"
      :value="true"
      v-if="newVersion && newVersion.text"
    >
      <template #action>
        <btn text @click="onHideVersion"> Ocultar </btn>
        <btn outlined color="primary" @click="reloadPage"> Atualizar </btn>
      </template>
    </snackbar>

    <snackbar
      vertical
      color="background"
      text-color="text--text"
      :style="snackbar.active ? 'margin-top: 92px' : ''"
      :text="pendingDownload.text"
      :timeout="-1"
      :value="true"
      v-if="pendingDownload && pendingDownload.text"
    >
      <template #action>
        <btn text @click="onHidePending"> Ocultar </btn>
        <btn
          outlined
          color="primary"
          @click="() => $pendingDownload(pendingDownload)"
          v-if="pendingDownload && pendingDownload.id"
        >
          Baixar
        </btn>
      </template>
    </snackbar>

    <snackbar
      vertical
      color="background"
      text-color="text--text"
      :top="!$isMobile"
      :bottom="$isMobile"
      :text="`Clique em <strong>Instalar</strong> para adicionar o <strong>${$appName}</strong> à tela inicial`"
      :timeout="-1"
      :value="true"
      v-model="installPromotion"
      v-if="installPromotion"
    >
      <template #action>
        <v-row class="ma-0">
          <v-col class="pa-0 d-flex" cols="12" md="auto">
            <btn
              outlined
              auto-focus
              class="ml-md-2 mx-auto"
              @click="() => onPromotionInstall()"
            >
              Instalar
            </btn>
          </v-col>
          <v-col class="pa-0 d-flex" cols="12" md="auto">
            <btn
              text
              color="actionbutton"
              class="ml-md-2 mx-auto"
              @click="() => hideInstallPromotion('never')"
            >
              Não exibir novamente
            </btn>
          </v-col>
          <v-col class="pa-0 d-flex" cols="12" md="auto">
            <btn
              text
              class="mx-auto"
              color="actionbutton"
              @click="() => hideInstallPromotion()"
            >
              Ocultar
            </btn>
          </v-col>
        </v-row>
      </template>
    </snackbar>

    <snackbar
      v-if="snackbar.active"
      v-model="snackbar.active"
      v-bind="snackbar"
    />
    <assistent
      v-if="assistent.active"
      v-model="assistent.active"
      v-bind="assistent"
      @assistent="assistent = $event"
    />
  </v-app>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import debounce from "lodash/debounce";
import get from "lodash/get";

import moment from "moment";

import { Alert, Snackbar, Assistent } from "@/components/utils";
import { Btn } from "@/components/form";

import StatusLayout from "./layouts/StatusLayout.vue";

import { EventBus } from "@/main";

import mutations from "@/store/mutations";

import update from "./mixins/update";
import installpromotion from "./mixins/installpromotion";
import { FormatUnix } from "./plugins/moment";

export default {
  mixins: [update, installpromotion],
  components: {
    Alert,
    Snackbar,
    StatusLayout,
    Assistent,
    Btn,
  },
  methods: {
    ...mapMutations({
      snackbar_: mutations.APP.SNACKBAR,
      assistent_: mutations.APP.ASSISTENT,
      alert_: mutations.APP.ALERT,
    }),
    onResize: debounce(function (event) {
      EventBus.$emit("resize", event);
    }, 300),
    onAppKeyDown(event) {
      if (
        get(this.$route, "params.modulo") === "cupons-fiscais" &&
        get(this.$route, "name") === "step-container"
      ) {
        return;
      }
      if (event.key === "F1") {
        this.$help(get(this.$route, "params.modulo"));
      }
    },
    reloadPage() {
      window.location.reload();
    },
    onHidePending() {
      this.$store.commit(mutations.APP.PENDING_DOWNLOAD);
    },
    onHideVersion() {
      this.$store.commit(mutations.APP.NEW_VERSION);
    },
  },
  computed: {
    ...mapState({
      alert: (state) => state.app.alert,
      pendingDownload: (state) => state.app.pendingDownload,
      newVersion: (state) => state.app.newVersion,
      snackbar: (state) => state.app.snackbar,
      assist_: (state) => state.app.assistent,

      blocked: (state) => state.app.blocked,
    }),
    assistent: {
      get() {
        return this.assist_;
      },
      set(assistent) {
        this.$store.commit(mutations.APP.ASSISTENT, assistent);
      },
    },
  },
  created() {
    document.addEventListener("keydown", this.onAppKeyDown);

    // Ouve o evento do snackbar, para mostrar mensagens na tela
    EventBus.$on("snackbar", (snackbar) => this.snackbar_(snackbar));
    // Ouve o evento do snackbar, para mostrar erros na tela
    EventBus.$on("alert", (alert) => this.alert_(alert));

    if (get(navigator, "userActivation") && this.$appMode !== "development") {
      const endOfDay = parseFloat(moment().endOf("day").format(FormatUnix));
      const now = parseFloat(moment().format(FormatUnix));

      setTimeout(() => {
        if (!navigator.userActivation.isActive) {
          this.$logout();
        }
      }, endOfDay - now);
    }

    window
      .matchMedia("(display-mode: standalone)")
      .addEventListener("change", () => {
        this.$store.commit(
          mutations.APP.DISPLAY_MODE,
          this.$getPWADisplayMode()
        );
      });
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onAppKeyDown);
    EventBus.$off("snackbar");
    EventBus.$off("alert");
  },
};
</script>

<style lang="scss">
* {
  // font-family: "Roboto", "Arial", sans-serif;
  font-display: swap;
  letter-spacing: 0.0592857143em !important;
  scrollbar-color: rgba(77, 200, 244, 0.6) transparent !important;
  scrollbar-width: thin !important;
}

@-moz-document url-prefix() {
  html,
  body {
    overflow: hidden !important;
  }
}

html {
  overflow-y: auto;
}

::-webkit-scrollbar-track:horizontal {
  border-top: rgba(0, 0, 0, 0.12) thin solid;
  background-color: #ffffff;
}
::-webkit-scrollbar-track:vertical {
  border-left: rgba(0, 0, 0, 0.12) thin solid;
  background-color: #ffffff;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(77, 200, 244, 0.6);
}

.h-100 {
  height: 100% !important;
}
.h-70 {
  height: 70%;
}
.h-30 {
  height: 30%;
}
</style>
