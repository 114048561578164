import { clearCurrency } from "@/utils";
import get from "lodash/get";

/**
 * Colunas do Grid
 */
export const columns = [
  addToRightSideColumn("contas-pagar"),
  { text: "Documento", value: "documento", maxWidth: "150px" },
  { text: "Parcela", value: "numero" },
  { text: "Histórico", value: "descricao" },
  { text: "Plano de contas", value: "plano_conta_descricao" },
  { text: "Fornecedor", value: "clifor_razao_social" },
  { text: "Emissão", value: "emissao_dt", type: "date" },
  { text: "Vencimento", value: "vencimento_dt", type: "date" },
  { text: "Data de quitação", value: "pagamento_dt", type: "date" },
  { text: "Valor", value: "vlr_total", align: "right" },
  { text: "Valor recebido", value: "vlr_pago", align: "right" },
  { text: "Portador", value: "portador" },
  { text: "Ativo", value: "ativo", type: "boolean" },
];

export {
  cache,
  forceUpdateKeys,
  dependencies,
  ficha,
  context,
} from "./cobrancas/contas";
import {
  addToRightSideColumn,
  deletes as _deletes,
  subactions as _subactions,
  exibir,
} from "./cobrancas/contas";

export const deletes = _deletes(columns);

export const fichaActions = function (this: any) {
  const id = get(this.__ficha, "data.id");

  const disabled =
    !id ||
    get(this.__ficha, "data.is_pago") ||
    !clearCurrency(get(this.__ficha, "data.vlr_total"));

  return [
    {
      title: "Pagar",
      id: "quitar",
      icon: require("@/assets/menu/modulos/pagar.svg"),
      disabled,
    },
    {
      title: "Replicar",
      id: "replicate-registro",
      icon: require("@/assets/menu/actions/duplicar.svg"),
      disabled: !id,
    },
  ];
};

/**
 * Ações do submenu
 */
export const subactions = function (this: any) {
  const disabled = !this.registro || !this.registro.id;
  const data = this.__modulo.data ?? [];

  return [
    {
      title: "Arquivo",
      items: [
        {
          id: "quitar-contas",
          title: "Pagar Contas",
        },
        {
          id: "quitar-contas",
          init: {
            initialStep: 2,
            initialCliforOrConvenio: {
              clifor_id: this.registro.clifor_id,
              nome_razao_social: this.registro.clifor_razao_social,
            },
          },
          hidden: disabled || !this.registro.clifor_id,
          title: `Pagar Contas de ${this.registro.clifor_razao_social ?? ""}`,
        },
        {
          id: "quitar-contas",
          init: {
            initialStep: 2,
            initialParcels: data,
          },
          hidden: disabled || !this.registro.clifor_id,
          title: "Pagar Contas Filtradas",
          divider: true,
        },
        {
          id: "gerar-parcelas",
          title: "Gerar Parcelas",
        },
      ],
    },
    {
      title: "Exibir",
      type: "filter",
      items: exibir.bind(this)("contas-pagar"),
    },
    ..._subactions.bind(this)(),
  ];
};
