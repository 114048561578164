<template>
  <ficha
    ref="ficha"
    :id="id"
    :modulo="modulo"
    :options="currentModuloOptions"
    :overlay="overlay"
    :__modulo="__modulo"
    @close="$emit('close', $event)"
    v-bind="$attrs"
  >
    <template #title="{ processing }" v-if="!isTitleDisabled">
      <template v-if="currentHeaderComponent">
        <v-col class="pa-0 d-flex mx-sm-0" cols="auto">
          <component
            class="caption text-lg-subtitle-1"
            :__ficha="internalFicha"
            :is="currentHeaderComponent"
          />
        </v-col>
      </template>

      <template v-else>
        <v-card-title
          v-if="!isTitleHide"
          class="pl-4 pr-4 pt-1 pb-0 font-weight-regular"
        >
          <actions
            :overlay="overlay"
            :__ficha="internalFicha"
            @click-action="onActionClick"
            v-if="!isActionsHide"
          />
          <v-spacer />
          <btn
            color="error"
            size="x-small"
            btn-class="align-self-start mt-1"
            :loading="processing"
            :disabled="processing"
            @click="() => $refs.ficha.onModalClose()"
          >
            <v-icon small>mdi-close</v-icon>
          </btn>
        </v-card-title>

        <div v-else />
      </template>
    </template>

    <template #tab="{ current, setCurrent }">
      <tabs
        :value="current"
        :items="tabs"
        :__ficha="internalFicha"
        @input="setCurrent"
        v-if="tabs.length"
      />
    </template>

    <template #extra>
      <search
        :__ficha="internalFicha"
        :__modulo="__modulo"
        v-if="searchModal"
        @close="searchModal = false"
      />
    </template>
  </ficha>
</template>

<script>
import { capitalize, find, get, head, includes } from "lodash";

import Ficha from "@/components/fichas/Ficha.vue";
import Actions from "@/components/modulo/actions/Actions.vue";
import Search from "@/components/popups/actions/Search.vue";

import { Tabs } from "@/components/utils";
import { Btn } from "@/components/form";

export default {
  props: {
    /**
     * $ref da instância do componente Modulo.vue
     */
    __modulo: {},

    overlay: { default: false, type: Boolean },
    modulo: {},
    id: {},
  },
  components: {
    Ficha,
    Actions,
    Tabs,
    Search,
    Btn,
  },
  filters: {
    capitalized(value) {
      return capitalize(value);
    },
  },
  /**
   * Vue override Hook
   */
  created() {
    this.init();
  },
  data: () => ({
    defaultOptions: {
      tabs: [],
    },
    searchModal: false,
    currentModuloOptions: null,

    internalFicha: {},
  }),
  computed: {
    /**
     * Função que retorna as abas da ficha
     */
    tabs() {
      return this.currentModuloOptions
        ? this.currentModuloOptions.tabs ?? []
        : [];
    },
    isTitleHide() {
      return (
        this.modulo !== "emitente" &&
        this.$isConfig(this.modulo) &&
        !includes(["planos-conta-contabil"], this.modulo)
      );
    },
    /**
     * Componente Header atual
     */
    currentHeaderComponent() {
      // Tenta
      try {
        // Formatar o modulo
        const modulo = this.$moduloFilename(this.modulo);
        // Tentar recuperar a header do modulo
        return require(`@/components/fichas/headers/${modulo}`).default;
      } catch (e) {
        // Caso errors retorna null
        return null;
      }
    },
    isTitleDisabled() {
      return includes(["planos-conta-contabil"], this.modulo);
    },
    isActionsHide() {
      return includes(["planos-conta-contabil"], this.modulo);
    },
  },
  mounted() {
    this.internalFicha = this.$refs.ficha;
    this.internalFicha.__tabContainer = this;

    const { hash } = this.$route;

    if (hash) {
      const item = find(
        this.tabs,
        (tab) =>
          tab.item ===
          `tab-${hash.replaceAll("#", "").replaceAll("tab-", "").toLowerCase()}`
      );

      if (item) {
        this.internalFicha.setCurrent(item);
        this.$router.push({ query: "" });
      }
    }
  },
  methods: {
    init() {
      try {
        const { tabs } = this.$isConfig(this.modulo)
          ? require(`@/config/modulos/config/${this.modulo}`)
          : require(`@/config/modulos/${this.modulo}`);

        this.currentModuloOptions = {
          tabs: tabs ?? this.defaultOptions.tabs,
        };
      } catch (e) {
        this.$debug(e);
        this.currentModuloOptions = this.defaultOptions;
      }
    },
    /**
     * Função que valida o click das ações do módulo/ficha
     */
    onActionClick(action) {
      const primaryKey = get(this.__modulo, "primaryKey", "id");

      // Salva o id
      const oldId = get(this.internalFicha, "data" + primaryKey);

      // se forem botões próximo e avançar
      if (includes(["next-registro", "previous-registro"], action.id)) {
        const registro = this.internalFicha.goTo({
          primaryKey,
          position: action.id === "next-registro" ? 1 : -1,
        });

        if (!registro) return;

        return this.$nextTick(() => {
          const id = get(registro, primaryKey);

          if (id !== oldId) {
            this.internalFicha.setCurrent(head(this.tabs));

            this.$nextTick(() => {
              this.$router.replace({
                params: { id, action: "alterar" },
              });
            });
          }
        });
      }

      if (action.id === "search") {
        return (
          !!this.__modulo && this.$nextTick(() => (this.searchModal = true))
        );
      }

      if (action.id === "detail") {
        return this.$routeResolver({
          name: "report",
          params: {
            modulo: this.modulo,
            registro: get(this.internalFicha, "data.id"),
            id: "visualizar",
          },
        });
      }

      if (action.id === "new-registro") {
        return this.internalFicha.clearAndGoToNew(head(this.tabs));
      }

      try {
        // Ações em comum
        const common = ["replicate-registro"];

        // Se for uma ação em comum
        includes(common, action.id)
          ? // Importa o handler comum
            import("@/handlers/common").then(({ ficha }) => {
              // Se existir a função, faz o bind e chama
              ficha && ficha.bind(this.internalFicha)(action);
            })
          : // Caso contrário, importa o handler do módulo
            import(`@/handlers/modulos/${this.modulo}`).then(({ ficha }) => {
              // Se existir a função, faz o bind e chama
              ficha && ficha.bind(this.internalFicha)(action);
            });
      } catch {
        //
      }
    },
  },
  watch: {
    modulo(modulo, old) {
      if (!modulo || modulo === old) return;

      this.init();

      return this.$nextTick(() => {
        this.internalFicha.deinit();
        this.internalFicha.init();
      });
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__title.ficha-title {
  padding: 8px 16px 0px 16px;
}
</style>
