<template>
  <btn @click="onClick" v-bind="$attrs">
    <slot />

    <v-icon right v-if="!authenticated"> mdi-lock </v-icon>
    <admin
      destroy
      :onlyPassword="onlyPassword"
      @password="onPassword"
      @close="onAuthClosed"
      @auhorized="onAuthorized"
      v-if="authModalOpen"
    />
  </btn>
</template>

<script>
import admin from "../../../mixins/admin";
import Admin from "../../../components/utils/Admin.vue";
import Btn from "./Btn.vue";

export default admin.extend({
  components: { Btn, Admin },
  methods: {
    onClick(event) {
      if (!this.authenticated) {
        return this.requestAuth();
      }
      return this.$emit("click", event);
    },
  },
});
</script>
