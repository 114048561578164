
import { defineComponent } from "vue";
import actions from "@/store/actions";

import { Btn, DateTime, FieldCpfCnpj, MonthPicker } from "@/components/form";
import { Modal } from "@/components/utils";
import SelectItem from "@/components/form/cupons/SelectItem.vue";

export default defineComponent({
  components: { Btn, Modal, DateTime, FieldCpfCnpj, SelectItem, MonthPicker },
  props: {
    report: { default: () => ({} as any) },
  },
  data: () => ({
    loading: false,
    menu: false,
    query: "",
    data: {},
    items: [] as any[],
    config: {
      queryStart: null,
      queryEnd: null,
      cpf_cnpj: null,
      ano_mes: "",
    },
  }),
  created() {
    if (this.auto) this.loadReport();
  },

  computed: {
    title(): string {
      return this.report?.params?.id === "identificacao-paf-nfce"
        ? this.report.label
        : `${this.report.params?.id?.toUpperCase()} - ${this.report.label}`;
    },
    auto(): boolean {
      return this.report?.params?.id === "identificacao-paf-nfce";
    },
    card() {
      return {
        class: "d-flex flex-column",
        style:
          "justify-content: center; align-items: center; min-height: 300px",
      };
    },
    is(): any {
      const estoque = "i" === this.report.params?.id;

      return {
        estoque,
        parcial: estoque && this.report.params?.type === "parcial",
        interval: ["i"].includes(this.report.params?.id),
        clifor: "ii" === this.report.params?.id,
      };
    },
  },

  methods: {
    onSelectItem(item) {
      this.query = "";
      if (!item || !item.produto_id) return;

      const index = this.items.findIndex(
        (it) => it.produto_id === item.produto_id
      );

      if (!~index) this.items.push(item);
    },
    onEnter() {
      (this.$refs.selectItem as any)?.search();
    },
    async loadReport() {
      const config = {
        ...(this.config as any),
        items: this.items.map((it: any) => it.produto_id),
      };

      if (
        this.is.interval &&
        !(this.config.queryStart && this.config.queryEnd)
      ) {
        return;
      }

      if (this.is.clifor && !this.config.ano_mes) {
        return;
      }

      if (this.is.parcial && !config.items.length) {
        return;
      }

      this.loading = true;

      // Identificação do PAF retorna JSON
      const responseType =
        this.report.params?.id === "identificacao-paf-nfce"
          ? "json"
          : "arraybuffer";

      const request = this.$api.post(
        `/cupons-fiscais/paf-nfce-report/${this.report.params.id}`,
        { ...this.report.params, config },
        { responseType }
      );

      try {
        if (responseType !== "arraybuffer") {
          const response = await request;
          return (this.data = response.data);
        }

        // handle file download
        await this.$store.dispatch(actions.UTILS.HANDLE_FILE, () => request);
      } finally {
        this.loading = false;
      }
    },
  },
});
