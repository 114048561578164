import { endOfDay, startOfDaySubtract } from "@/plugins/moment";

import get from "lodash/get";
import { equals, filterTypes } from "@/utils/filters";

/**
 * Colunas do Grid
 */
export const columns = [
  {
    text: "Número",
    value: "numero",
    maxWidth: "100px",
    position: 0,
  },
  { text: "Data", value: "created_at", type: "timestamp" },
  { text: "Atendente", value: "colaborador_razao_social" },
  { text: "Data agendada", value: "agendamento_at", type: "timestamp" },
  { text: "Tempo", value: "tempo" },
  { text: "Cliente", value: "clifor_razao_social" },
  { text: "Situação", value: "status" },
  { text: "Descrição do objeto", value: "descricao" },
  { text: "Problema reclamado", value: "problema" },
  { text: "Identificador 1", value: "identificador1" },
  { text: "Identificador 2", value: "identificador2" },
  { text: "Identificador 3", value: "identificador3" },
  { text: "Identificador 4", value: "identificador4" },
  { text: "Garantia", value: "garantia_dt", type: "date" },
  { text: "Data da entrega", value: "finalizado_at", type: "timestamp" },
  {
    text: "Total de serviços",
    value: "vlr_servicos",
    align: "right",
    class: "text--text",
  },
  {
    text: "Total de peças",
    value: "vlr_produtos",
    align: "right",
    class: "text--text",
  },
  { text: "Frete", value: "vlr_frete", align: "right", class: "text--text" },
  {
    text: "Desconto",
    value: "vlr_desconto",
    align: "right",
    class: "text--text",
  },
  {
    text: "Total",
    value: "vlr_total",
    align: "right",
    class: "text--text",
  },
  { text: "NF-e", value: "numero_nfe" },
  { text: "Cupom Fiscal", value: "numero_nfce" },
];

/**
 * Lista de identificadores para a ficha
 */
export const identificadores = new Array(4)
  .fill(null)
  .map((_, i) => `lbl_tb_ordem_servicos_identificador_${i + 1}`);

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = columns.filter((column) =>
  ["id", "colaborador_razao_social", "clifor_razao_social"].includes(
    column.value
  )
);

/**
 * Ações de contexto da tabela
 */
export const context = function (this: any) {
  const id = (this.registro?.numero ?? "").toString().padStart(10, "0");

  return [
    {
      id: "etiquetas",
      title: "Etiquetas",
      descricao: `Ordem de Servico #${id}`,
      icon: "mdi-tag-outline",
    },
  ];
};

/**
 * Ações do submenu
 */
export const subactions = function (this: any) {
  // Captura o registro selecionado
  const disabled = !this.registro || !this.registro.id;
  const formattedId = (this.registro?.numero ?? "")
    .toString()
    .padStart(10, "0");

  return [
    {
      title: "Arquivo",
      items: [
        {
          id: "recibo",
          title: !disabled
            ? `Imprimir recibo da entrega da OS ${formattedId}`
            : "Imprimir recibo",
          registro_id: this.registro.id,
          disabled,
          type: "report",
        },
        {
          id: "visualizar",
          title: `Imprimir OS ${formattedId}`,
          registro_id: this.registro.id,
          type: "report",
          divider: true,
        },
        {
          id: "imprimir_todas_os",
          title: "Imprimir todas as OS filtradas",
          disabled: true,
          hidden: true,
        },
        {
          id: "import_document",
          modulo: "saida",
          import: this.__modulo.modulo,
          import_registro_id: formattedId,
          title: `Gerar NF-e da OS #${formattedId}`,
          disabled,
        },
        {
          id: "import_document",
          modulo: "cupons-fiscais",
          import: this.__modulo.modulo,
          import_registro_id: formattedId,
          title: `Gerar ${this.$nomeCupomFiscal} da OS #${formattedId}`,
          disabled,
        },
      ],
    },
    {
      title: "Relatórios",
      type: "report",
      items: reports.bind(this)(),
    },
    {
      title: "Exibir",
      type: "filter",
      items: [
        {
          title: "Agendadas",
          filter: equals("status", ["Agendada"]),
        },
        {
          title: "Abertas",
          filter: equals("status", ["Aberta"]),
        },
        {
          title: "Fechadas",
          filter: equals("status", ["Fechada"]),
        },
        {
          title: "Todas",
          filter: null,
        },
      ],
    },
  ];
};

/**
 * Relatórios
 */
export const reports = function (this: any) {
  // Define o intervalo de data de 1 mes
  const filterDateInterval = {
    queryStart: startOfDaySubtract(),
    queryEnd: endOfDay(),
  };

  return [
    {
      id: "itens_os_status",
      title: "Itens em OS por Status",
      type: "form-report",
      form: [
        {
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "itens_tecnicos",
      title: "Total de serviços por técnico",
      type: "form-report",
      form: [
        {
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    {
      id: "agenda",
      title: "Agenda por técnico",
      type: "form-report",
      form: [
        {
          default: {
            queryStart: startOfDaySubtract("0"),
            queryEnd: endOfDay(),
          },
          type: filterTypes.DATE_INTERVAL,
        },
        // {
        //   type: filterTypes.FORM,
        //   estrutura: [
        //     {
        //       component: CliforSelect,
        //       options: {
        //         label: "Atendente",
        //         name: "colaborador_id",
        //         valueNotNull: false,
        //         persist: false,
        //         clearable: true,
        //         filter: tecnico_filter(),
        //         // clifor: "colaborador",
        //         vModel: "value.tecnico",
        //       },
        //     },
        //   ],
        // },
      ],
    },
  ];
};

/**
 * Dependências do módulo
 */
export const dependencies = [{ name: "itens" }];

/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = [
  "vlr_total",
  "vlr_produtos",
  "vlr_servicos",
  "clifor",
  "vlr_desconto",
  "vlr_frete",
  "colaborador",
  "colaborador_razao_social",
];

export const fichaBottomActions = function (this: any) {
  return [
    {
      text: true,
      title: "Desconto",
      class: "mr-4",
      on: {
        click: () =>
          this.__ficha?.currentModuloInstance?.requestDiscountAssistent(),
      },
    },
  ];
};

/**
 * Formata os dados da ficha antes de enviar pro backend (update/create)
 */
export const format = function (this: any) {
  return {
    clifor_id: get(this.data, "clifor_id", get(this.data, "clifor.id")),
  };
};

/**
 * Cache default
 */
export const cache = {
  selecionado: {
    index: 0,
    coluna: 0,
  },
  colunas: [
    "numero",
    "colaborador_razao_social",
    "agendamento_at",
    "tempo",
    "clifor_razao_social",
    "status",
    "numero_nfe",
    "numero_nfce",
  ],
  ordenar: [
    {
      coluna: "numero",
      ordem: "desc",
    },
  ],
};
