<template>
  <v-col :class="class_" :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <v-textarea
      outlined
      hide-details
      no-resize
      ref="textArea"
      :rows="rows"
      :name="name"
      :value="value"
      :type="type"
      :class="{ 'ignore-enter-focus': ignoreEnterFocus }"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :id="`textarea-${_uid}`"
      @input="$emit('input', $event)"
      @focus="onFocus"
      @blur="onBlur"
      @keydown="onKeyDown"
      v-bind="$attrs"
    />
  </v-col>
</template>

<script>
import { focusNextTextField } from "@/utils";
import { trim } from "lodash";

export default {
  props: {
    value: {},
    name: { type: String },

    tooltip: { type: String, default: null },
    colClass: {},
    placeholder: {},
    type: { type: String },
    ignoreEnterFocus: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    persistent: { type: Boolean, default: false },
    noBottomRadius: { type: Boolean, default: false },
    appendIcon: { type: String },
    rows: { type: String, default: "5" },
    resize: { type: Boolean, default: false },
    // Cols
    cols: {},
    sm: {},
    md: {},
    lg: {},
    xl: {},
  },
  data: () => ({
    enterCount: 0,
    value_: "",
  }),
  computed: {
    class_() {
      return this.colClass ?? "pb-1 pt-2 px-1";
    },
  },
  methods: {
    /**
     * Função que ativa toda vez que uma tecla é peressionada no focus do textarea
     */
    onKeyDown(event) {
      // Emite o evento keydown
      this.$emit("keydown", event);

      // Se não for enter, reseta o contador
      if (event.keyCode !== 13) return (this.enterCount = 0);
      return this.onEnterKeyPress(event);
    },
    /**
     * Função responsável pelo focus do input
     */
    focus() {
      // Aguarda o próximo tick
      this.$nextTick(() => {
        // Ativa o focus do input
        this.$refs.input.focus();
      });
    },
    /**
     * Função que ativa toda vez que é pressionado enter no focus
     */
    onEnterKeyPress(event) {
      // Se chegou em 2, quer dizer que deve ir para o próximo campo
      if (this.enterCount === 2) {
        event.preventDefault();

        this.$emit("input", trim(this.value));

        // Faz o focus para o proximo campo
        return (
          this.$refs.textArea.blur(),
          focusNextTextField(event, `textarea-${this._uid}`),
          (this.enterCount = 0)
        );
      }

      // Aumenta um no contador
      this.enterCount += 1;
    },
    onFocus(event) {
      // Emite o evento de focus
      this.$emit("focus", event);

      this.value_ = trim(event.target.value);
    },
    /**
     * Função executada no blur do input
     */
    onBlur(event) {
      // Emite o evento blur
      this.$emit("blur", event);

      // Compara se o valor atual é diferente do antigo
      if (trim(event.target.value) !== trim(this.value_)) {
        // Se for, emite o evento change (alterou de um valor para o outro)
        this.$emit("change", event.target.value, this.value_);
      }

      // Limpa a contagem de enters
      this.enterCount = 0;

      // Limpa o valor antigo para nulo
      this.value_ = "";
    },
  },
};
</script>

<style scoped lang="scss">
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  min-height: 35px !important;
  padding-left: 8px;
  padding-right: 8px;
}
.v-textarea textarea {
  &::-webkit-scrollbar-track:horizontal {
    border-top: rgba(0, 0, 0, 0.12) thin solid;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track:vertical {
    border-left: rgba(0, 0, 0, 0.12) thin solid;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 3px;
    height: 8px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(77, 200, 244, 0.6) !important;
  }
}
.v-textarea textarea {
  margin-right: 5px;
  padding: 8px 0px;
  font-size: 14px !important;
  line-height: 1.4rem !important;
}

.legend_adjust {
  margin-left: -3px;
}

.text-area-legend {
  font-size: 12px !important;
  color: #6e6e6e !important;
  padding-left: 2px;
  padding-right: 2px;
}

textarea:-webkit-autofill {
  -webkit-text-fill-color: #000000;
  background-color: transparent !important;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
