<template>
  <modal
    :value="value"
    :width="width"
    @click-outside="$emit('click-outside')"
    @input="$emit('input', $event)"
    @close="$emit('close')"
    v-bind="$attrs"
  >
    <template #text>
      <v-row no-gutters>
        <v-col v-if="hasIcon" cols="12" md="3" class="d-flex">
          <v-img class="align-self-center" height="120" contain :src="src" />
        </v-col>
        <v-col class="d-flex align-center" cols="12" :md="hasIcon ? 9 : 12">
          <slot name="text">
            <div
              class="overflow-y-auto"
              style="max-height: 70vh"
              v-html="message"
              v-if="text"
            />
          </slot>
        </v-col>
      </v-row>
    </template>

    <template #actions><slot name="full-actions" /></template>

    <template #action-content>
      <slot name="actions">
        <template v-if="actions">
          <v-spacer />
          <btn
            outlined
            auto-focus
            class="focus-priority"
            color="actionbutton"
            @click="$emit('ok'), closeOnOk && $emit('close')"
            >Ok</btn
          >
          <v-spacer />
        </template>
      </slot>
    </template>
  </modal>
</template>

<script>
import Modal from "./Modal.vue";
import { Btn } from "@/components/form";

export default {
  components: { Modal, Btn },
  props: {
    value: {},
    actions: { default: false },
    hideIcon: { type: Boolean, default: false },
    text: { default: false },
    closeOnOk: { default: false },
    message: { default: "" },
    width: { default: "50vw" },
    src: { default: require("@/assets/menu/actions/imprimir.svg") },
  },
  computed: {
    hasIcon() {
      return !this.$isMobile && !this.hideIcon;
    },
  },
};
</script>
