/**
 * Tabs da ficha
 */
export const tabs = [
  {
    title: "Principal",
    item: "tab-principal",
  },
  {
    title: "Séries",
    item: "tab-series",
  },
  {
    title: "Inutilização",
    item: "tab-inutilizacao",
  },
];

/**
 * Dependências do módulo
 */
export const dependencies = [
  {
    tab: "tab-series",
    name: "series",
    modulo: "nfe-series",
  },
  {
    tab: "tab-inutilizacao",
    name: "inutilizacoes",
    modulo: "nfe-inutilizacoes",
  },
];
