import Vue from "vue";

import Vuetify from "vuetify/lib";

import pt from "vuetify/src/locale/pt";

Vue.use(Vuetify);

const dark =
  window.location.pathname.includes("/relatorios") ||
  window.location.pathname === "/"
    ? false
    : JSON.parse(window.localStorage.getItem("darkmode") ?? "false");

export default new Vuetify({
  theme: {
    dark,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#44c8f5",
        secondary: "#b0bec5",
        accent: "#a8e8ff",
        tabs: "#f5f5f5",
        error: "#b71c1c",
        info: "#2765f5",
        success: "#4CAF50",
        warning: "#FFC107",
        saleGreen: "#9dd3af",
        saleRed: "#ff6766",
        saleGrey: "#bebfc1",

        cupomtext: "#121212",
        background: "#FFF",

        actionbutton: "#616161",

        th: "#f9f9f9",
        text: "#141414",
        tableborder: "#e6e6e6",
      },
      dark: {
        // primary: "#80e1ff",
        primary: "#44c8f5",
        secondary: "#b0bec5",
        accent: "#a8e8ff",
        tabs: "#121212",
        error: "#b71c1c",
        info: "#2765f5",
        success: "#4CAF50",
        warning: "#FFC107",
        saleGreen: "#9dd3af",
        saleRed: "#ff6766",
        saleGrey: "#bebfc1",

        cupomtext: "#1E1E1E",
        background: "#121212",

        actionbutton: "#f5f5f5",

        th: "#1E1E1E",
        text: "#f5f5f5",
        tableborder: "#4a575d",
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
