<template>
  <component
    ref="input"
    v-bind="{ ...$attrs }"
    :name="`${_uid}-${name}`"
    :is="component"
    @change="onChanged"
    @input="$emit('input', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    @enter="$emit('enter', $event)"
  >
    <template #append>
      <slot name="append" />
    </template>
    <template #progress>
      <slot name="progress" />
    </template>
  </component>
</template>

<script>
import { CTextField, TextField } from "@/components/form";

import Persistent from "./persistent";

export default Persistent.extend({
  name: "PTextField",
  props: {
    clear: { type: Boolean, default: false },
  },
  computed: {
    component() {
      return this.clear ? CTextField : TextField;
    },
  },
});
</script>
