import Mixin from "@/mixin";

export default Mixin.extend({
  props: {
    __ficha: Object,
    data: Object,

    selected: Object,
    setSelected: Function,

    isFichaDisabled: Boolean,
    isFichaReadonly: Boolean,
    value: [String, Number],
    extra: [Array, Object],
    height: Function,
  },
  mounted() {
    if (this.__ficha) {
      this.__ficha.currentModuloInstance = this;
    }
  },
});
