import { head, filter, includes, get, compact } from "lodash";
import { endOfDay, milliseconds, startOfDaySubtract } from "@/plugins/moment";

import { filterTypes } from "@/utils/filters";

import _actions from "@/store/actions";

/**
 * Colunas do Grid
 */
export const columns = [
  { text: "NF", value: "numero_nf", maxWidth: "86px" },
  { text: "Série", value: "serie" },
  { text: "Emissão", value: "emissao_dt", type: "date" },
  { text: "Data Entrada", value: "saida_at", type: "timestamp" },
  {
    text: "Manifesto do Destinatário",
    value: "manifesto_ciencia",
    action: "nfe_manifesto",
    type: "value-icon",
    align: "left",
    maxWidth: "245px",
  },
  { text: "Fornecedor", value: "clifor_razao_social" },
  { text: "Mercadoria", value: "vlr_produtos", align: "right" },
  { text: "Valor Serviços", value: "vlr_servicos", align: "right" },
  { text: "Total", value: "vlr_total", align: "right" },
  { text: "Desconto", value: "vlr_desconto", align: "right" },
  { text: "Natureza Operação", value: "natureza_descricao" },
  { text: "ICMS", value: "vlr_icms", align: "right" },
  { text: "ISS", value: "vlr_iss", align: "right" },
  { text: "IPI", value: "vlr_ipi", align: "right" },
  { text: "Frete", value: "vlr_frete", align: "right" },
  { text: "Seguro", value: "vlr_seguro", align: "right" },
  { text: "Outras Despesas", value: "vlr_despesas", align: "right" },
  { text: "ICMS Sub. Trib.", value: "vlr_icms_st", align: "right" },
  { text: "Base Sub. Trib.", value: "vlr_bc_icms_st", align: "right" },
  {
    text: "Transportadora",
    value: "transportadora_razao_social",
  },
  {
    document: "nfe",
    text: "ID da NF-e",
    value: "nfe_id",
    action: "dfe_consultar_chave",
  },
  {
    id: "xml_nfe",
    text: "NF-e XML",
    value: "tem_xml_nfe",
    action: "xml",
    type: "xml",
    hiddenReport: true,
  },
  {
    id: "xml_manifesto_destino",
    text: "Manifesto XML",
    value: "tem_xml_manifesto_destino",
    action: "xml",
    type: "xml",
    hiddenReport: true,
  },
  { text: "Volumes", value: "numeracao_volumes" },
  { text: "Espécie", value: "especie" },
  { text: "Marca", value: "marca" },
];

/**
 * Se vai executar ação após fechar a ficha
 */
export const afterCloseFicha = (item) => item.incompleta;

/**
 * Função que valida se o registro pode ou não ser alterado
 */
export const validateForEditAction = function (registro) {
  // A NF-e não pode ser entrada eletronica
  return !!get(registro, "id") && !registro.entrada_eletronica_finalizada;
};

/**
 * Função que valida se o registro pode ou não ser alterado
 */
export const validateForDeleteAction = function (registro) {
  // A NF-e não pode ser entrada eletronica
  return !!get(registro, "id") && !registro.entrada_eletronica_finalizada;
};

/**
 * Ação de contexto da tabela
 */
export const context = function (this: any) {
  const disabled = !this.registro || !this.registro.id;
  const id = `${this.registro?.numero_nf}/${this.registro?.serie}`;

  // Retorna a lista dos itens
  return [
    {
      id: "nfe_manifesto",
      icon: "mdi-thumb-up-outline",
      tpEvento: "210200",
      title: "Manifestação do destinatário (Confirmação da Operação)",
      disabled,
    },
    {
      id: "nfe_manifesto",
      icon: "mdi-thumb-up-outline",
      tpEvento: "210220",
      title: "Manifestação do destinatário (Desconhecimento da Operação)",
      disabled,
    },
    {
      id: "nfe_manifesto_nao_realizado",
      tpEvento: "210240",
      icon: "mdi-thumb-up-outline",
      title: "Manifestação do destinatário (Operação não realizada)",
      disabled,
    },
    {
      divider: true,
      id: "xml_nfe",
      action: "xml",
      title: "Visualizar XML",
      icon: "mdi-magnify",
    },
    {
      id: "xml_manifesto_destino",
      action: "xml",
      title: "Visualizar XML da manifestação do destinatário",
      icon: "mdi-magnify",
      disabled: disabled,
    },
    {
      id: "dfe_copiar_nfe_id",
      icon: "mdi-content-copy",
      code: "9",
      title: "Copiar Chave da NF-e",
      disabled: !this.registro.nfe_id,
      divider: true,
    },
    {
      id: "etiquetas",
      title: "Etiquetas",
      descricao: `NF-e ${id}`,
      icon: "mdi-tag-outline",
    },
  ];
};

/**
 * Ações do submenu
 */
export const subactions = function (this: any) {
  // Captura o registro selecionado
  const disabled = !this.registro || !this.registro.id;

  return [
    {
      title: "Arquivo",
      items: compact([
        {
          id: "dfe_importar_xml",
          title: "Importar XML NF-e",
          modulo: "entrada",
        },
        {
          id: "dfe_exportar_xml",
          modulo: "entrada",
          title: "Exportar NFs de entrada para contabilidade",
          divider: true,
        },
        // { id: "buscar_xml_sefaz", title: "Buscar XML no site da SEFAZ" },
        // { id: "consultar_nfe", title: "Consultar validade da NF-e" },
        // {
        //   id: "importar_venda_ambulante",
        //   title: "Importar retorno de venda ambulante",
        //   divider: true
        // },
        {
          disabled,
          id: "dfe_retorno_registro",
          type: "devolucao",
          modulo: "entrada",
          title: `Devolver NF-e ${this.registro.numero_nf || ""}/${
            this.registro.serie || ""
          }`,
        },
        {
          disabled,
          id: "gerar_nfe_entrada",
          title: "Gerar NF-e de entrada",
          divider: true,
        },
        {
          id: "dfe_dist",
          modulo: "entrada",
          title: "Consultar NFs emitidas",
        },
      ]),
    },
    {
      title: "Relatórios",
      type: "report",
      items: reports.bind(this)(),
    },
  ];
};

/**
 * Relatórios
 */
export const reports = function () {
  // Captura o registro selecionado
  // const disabled = !this.registro || !this.registro.id;
  // const descricao = this.registro.descricao;

  const filterDateInterval = {
    // Iniciando 30 dias antes de hoje
    queryStart: startOfDaySubtract(),
    // Terminando hoje
    queryEnd: endOfDay(),
  };

  return [
    {
      id: "relatorio_compras",
      modulo: "estoque",
      title: "Compras",
      type: "form-report",
      form: [
        {
          title: "Relatório de compras",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
        {
          title: "Selecione abaixo as operações que devem ser listadas",
          search: {
            id: "cfops",
            target: "cfop",
            value: "(1|2|3)%",
            path: "/naturezas-operacao/search",
          },
          default: {
            cfops: [],
          },
          type: filterTypes.CFOP,
        },
        {
          title: "Escolha o modelo do relatório",
          type: filterTypes.CHOOSE_ONE,
          default: {
            choose: "icms",
          },
          options: [
            {
              text: "Relatório de ICMS",
              value: "icms",
            },
            {
              text: "Item por item",
              value: "items",
            },
          ],
        },
      ],
    },
    {
      id: "resumo_compras",
      modulo: "estoque",
      title: "Resumo das compras",
      type: "form-report",
      form: [
        {
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
    // { id: "compras_previsao", title: "Previsão de compras" },
  ];
};
/**
 * Dependências do módulo
 */
export const dependencies = [
  {
    name: "itens",
  },
  {
    name: "billing",
  },
];

/**
 * Relações do módulo que SÃO JSON
 */
// export const relations = [
//   {
//     name: "clifor",
//     modulo: "cadastro",
//     updateKeys: [
//       "id",
//       "nome_razao_social",
//       "cpf_cnpj",
//       "rg_ie",
//       "cep",
//       "endereco",
//       "bairro",
//       "telefone"
//     ]
//   },
//   {
//     name: "transportadora",
//     modulo: "transportadoras",
//     updateKeys: [
//       "id",
//       "cpf_cnpj",
//       "rg_ie",
//       "uf",
//       "municipio_id",
//       "endereco",
//       "telefone",
//       "veiculo_uf",
//     ]
//   }
// ];

/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = [
  "vlr_total",
  "clifor",
  "itens",
  "parcelas_json",
  "nfe_id",
  "transportadora",
  "transportadora_id",
  "veiculo",
  "veiculo_id",
];

/**
 * Ficha default
 */
export const ficha = () => ({
  ativo: true,
  numero_serie: "0".repeat(9) + "/" + "0".repeat(2) + "1",
  nfe_id: "0".repeat(44),
  modelo: "01",
  qtd_volumes: "1",
  nfe_finalidade: "1",
  indicador_consumidor: "0",
  indicador_frete: "0",
  indicador_presenca: "1",
  emissao_dt: milliseconds(),
  saida_at: milliseconds(),
});

/**
 * Formata os dados da ficha antes de enviar pro backend (update/create)
 */
export const format = function (this: any) {
  return {
    clifor_id: get(this.data, "clifor_id", get(this.data, "clifor.id")),
  };
};

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = filter(columns, (column) =>
  // Retorna as colunas que as incluem
  includes(
    [
      "numero_nf",
      "serie",
      "emissao_dt",
      "saida_at",
      "clifor",
      "vlr_produtos",
      "vlr_servicos",
      "vlr_total",
    ],
    column.value
  )
);

/**
 * Steps da ficha
 */
export const steps = [
  {
    title: "Básico",
    item: 1,
  },
  // {
  //   title: "Itens",
  //   item: 2,
  //   rules: true
  // },
  {
    title: "Complemento",
    item: 2,
    rules: true,
  },
  {
    title: "Pagamento",
    item: 3,
    rules: true,
  },
];

export const itensHeaders = function (this: any) {
  const isCSOSN =
    this.data.venda_id && get(this.data, "icms.CSOSN")
      ? true
      : this.$emitente.crt === "1";

  return [
    {
      text: "Qtd Original",
      value: "qtd_original",
      disabled: "produto_id",
      type: "precision",
    },
    {
      text: "Unitário",
      width: "80px",
      value: "vlr_unitario_original",
      disabled: "produto_id",
      type: "precision",
    },
    {
      text: "Total",
      width: "80px",
      value: "vlr_total",
      disabled: "produto_id",
      type: "precision",
    },
    {
      text: isCSOSN ? "CSOSN ICMS" : "CST ICMS",
      value: isCSOSN ? "icms.CSOSN" : "icms.CST",
      disabled: "produto_id",
      type: "tel",
      options: { maxLength: isCSOSN ? "3" : "2" },
    },
    {
      text: "% ICMS",
      width: "100px",
      value: "icms.pICMS",
      disabled: "produto_id",
      type: "precision",
    },
    {
      text: "% BC ICMS",
      value: "icms.pBC",
      disabled: "produto_id",
      type: "precision",
    },
    {
      text: "CST IPI",
      value: "ipi.CST",
      disabled: "produto_id",
      type: "tel",
      options: { maxLength: "2" },
    },
    {
      text: "% IPI",
      width: "100px",
      value: "ipi.pIPI",
      disabled: "produto_id",
      type: "precision",
    },
    {
      text: "IPI",
      width: "100px",
      value: "ipi.vIPI",
      disabled: "produto_id",
      type: "precision",
    },
    { text: "CFOP", value: "cfop", disabled: "produto_id" },
    {
      text: "ICMS",
      width: "100px",
      value: "icms.vICMS",
      disabled: "produto_id",
      type: "precision",
    },
    {
      text: "BC ICMS",
      width: "100px",
      value: "icms.vBC",
      disabled: "produto_id",
      type: "precision",
    },
    {
      text: "BC ST",
      width: "100px",
      value: "icms.vBCST",
      disabled: "produto_id",
      type: "precision",
    },
    {
      text: "ICMS ST",
      width: "100px",
      value: "icms.vICMSST",
      disabled: "produto_id",
      type: "precision",
    },
    {
      text: "CST PIS/COFINS",
      value: "pis.CST",
      disabled: "produto_id",
      type: "tel",
      options: { maxLength: "2" },
    },
    {
      text: "% PIS",
      width: "100px",
      value: "pis.pPIS",
      disabled: "produto_id",
      type: "precision",
    },
    {
      text: "PIS",
      width: "100px",
      value: "pis.vPIS",
      disabled: "produto_id",
      type: "precision",
    },
    {
      text: "% COFINS",
      width: "100px",
      value: "cofins.pCOFINS",
      disabled: "produto_id",
      type: "precision",
    },
    {
      text: "COFINS",
      width: "100px",
      value: "cofins.vCOFINS",
      disabled: "produto_id",
      type: "precision",
    },
    {
      width: "100px",
      text: "GTIN Fornecedor",
      value: "ean_original",
      disabled: "produto_id",
      options: { maxLength: "14" },
    },
  ];
};

/**
 * Cache default
 */
export const cache = {
  colunas: [
    "numero_nf",
    "serie",
    "emissao_dt",
    "saida_at",
    "manifesto_ciencia",
    "clifor_razao_social",
    "vlr_total",
    "nfe_id",
  ],
  filtros: [],
  ordenar: [
    {
      coluna: "numero_nf",
      ordem: "desc",
    },
  ],
};

/**
 * Configuração do assistente
 */
export const assistent = {
  importar_xml: {
    // Fechar o assistente após confirmar o erro
    closeAfterError: true,
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message(this: any) {
      return `
        Importação de XMLs de NF-e.<br>
        <br>Você poderá importar 1 XML por vez, ou um ZIP contendo até 25 XMLs.<br>
        <br>OBS: Os XMLs só serão importados se estiverem Autorizados, cStat = [100, 124, 150].
      `;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      return [
        {
          name: "_import_xmls",
          type: "file",
          accept: ".zip,.xml",
          label: "Arquivo XML ou ZIP",
        },
      ];
    },
    /**
     * Função que é chamada quando clicado no botão ok do assistente
     *
     * retorna uma string representando um erro ou true para válido
     */
    validator(item) {
      // Se não houver item ou arquivos
      if (!item || !item._import_xmls) {
        // Retorna sem arquivos
        return "no_files";
      }

      // Captura o arquivo
      const file = item._import_xmls as any;

      // Define os tipos aceitos
      const accept = ["text/xml", "application/x-zip-compressed"];

      // Se não tiver arquivo ou não for do tipo aceito, retorna
      if (!file || !includes(accept, file.type)) return "invalid_extension";

      // Caso passar por tudo, retorna válido
      return true;
    },
    /**
     * Função que retorna todos os erros
     */
    error() {
      return {
        no_files: "É necessário escolher um arquivo.",
        invalid_extension:
          'O arquivo inserido é inválido, arquivos aceitos: ".xml" e ".zip".',
      };
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, info) {
      // Retorna a promessa da requisição
      return this.$store
        .dispatch(_actions.IMPORT.XML, {
          file: info._import_xmls,
          modulo: this.registro.modulo,
        })
        .then((response) => {
          // saida não abre a ficha
          if (this.registro.modulo !== "entrada") {
            return response;
          }

          // Captura o data da resposta
          const { data } = response;

          // Captura os registros
          const { registros } = data;

          // Se houver registros e somente um registro
          if (registros && registros.length === 1) {
            // Captura o id do registro
            const id = get(head(registros), "id");

            // Se houver id
            if (id) {
              // faz o redirect para abrir a nota após a importação
              this.$router.push({
                name: "step-container",
                params: {
                  id,
                  modulo: this.registro.modulo,
                  action: "alterar",
                },
              });
            }
          }

          // Propaga a resposta
          return response;
        });
    },
  },
  manifesto_nao_realizado: {
    /**
     * Função que retorna a mensagem inicial do assistente
     */
    message() {
      return `
        Atenção<br>
        <br>Informe uma justificativa (min. 15 caracteres).
      `;
    },
    /**
     * Função que retorna o formulário a ser exibido no assistente
     */
    form() {
      return [
        {
          autoFocus: true,
          name: "_nfe_justificativa",
          label: "Justificativa",
        },
      ];
    },
    /**
     * Função que é chamada quando clicado no botão ok do assistente
     *
     * retorna uma string representando um erro ou true para válido
     */
    validator(item) {
      if (!item || !item._nfe_justificativa) return "empty";
      if (item._nfe_justificativa.length < 15) return "min_15";
      return true;
    },
    /**
     * Função que retorna todos os erros
     */
    error() {
      return {
        empty: "A justificativa não pode ser vazia.",
        min_15: "A justificativa precisa ter no mínimo 15 caracteres.",
      };
    },
    /**
     * Função chamada caso passe pelo validator sem nenhum erro
     */
    success(this: any, data) {
      // Retorna a promessa da requisição
      return this.$store.dispatch(_actions.DFE.MANIFESTO, {
        id: data.id,
        xJust: data._nfe_justificativa,
        tpEvento: "210240",
      });
    },
  },
};
