<template>
  <modal
    persistent
    width="45vw"
    title="Fazer login como Administrador"
    @close="onModalClose"
    v-if="!logged"
    v-model="modal"
  >
    <template #text>
      <input style="display: none" name="username" value="Administrador" />
      <v-form @submit.prevent="() => null">
        <password
          auto-focus
          :disabled="false"
          ref="password"
          label="Senha"
          @keydown.enter.native="onLogin"
          v-model="password"
        />
      </v-form>
    </template>

    <template #action-content>
      <v-spacer></v-spacer>
      <btn outlined @click="onLogin">Entrar</btn>
    </template>
  </modal>
</template>

<script>
import { mapActions } from "vuex";

import Modal from "@/components/utils/Modal.vue";
import Btn from "@/components/form/buttons/Btn.vue";
import Password from "@/components/form/inputs/Password.vue";

import actions from "@/store/actions";
import get from "lodash/get";
import mutations from "@/store/mutations";

export default {
  props: {
    destroy: { default: false },
  },
  data: () => ({
    modal: true,
    logged: false,
    password: "",
  }),
  components: {
    Modal,
    Btn,
    Password,
  },
  methods: {
    ...mapActions({
      login: actions.APP.LOGIN_ADMIN,
    }),
    /**
     * Função que faz login como admin
     */
    onLogin() {
      // Chama a ação de login
      this.login(this.password)
        .then((response) => {
          const admin = get(response, "data.user", {});

          // Seta como logado
          this.logged = true;
          // Emite o evento de autorizado
          this.$emit("auhorized");

          // Se for para destruir, destrói o componente
          if (this.destroy) this.$destroy();

          // se estiver marcado para resetar a senha do admin
          if (admin.reset_pass_after_login) {
            return this.$store.commit(mutations.APP.ASSISTENT, {
              modulo: "general",
              action: "reset_pass_after_login",
              registro: admin,
            });
          }
        })
        .catch(() => {
          this.$refs.password && this.$refs.password.focus();
        });
    },
    /**
     * Função que é ativa quando o modal é fechado
     */
    onModalClose() {
      // Volta para rota de config
      this.$emit("close");
    },
  },
};
</script>
