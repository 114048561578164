<template>
  <v-col
    class="pa-0 ma-0 d-flex"
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
  >
    <v-file-input
      outlined
      dense
      hide-details
      show-size
      clearable
      class="pb-1 pt-2 px-1"
      prepend-icon=""
      prepend-inner-icon="$file"
      @change="onChange"
      v-bind="$attrs"
    />
  </v-col>
</template>

<script>
export default {
  props: {
    value: { default: () => [] },
    // Cols
    cols: {},
    sm: {},
    md: {},
    lg: {},
    xl: {},
  },
  methods: {
    /**
     * Função que é chamada quando o input recebe um arquivo
     */
    onChange(files) {
      // Emite o evento input = lista de arquivos
      this.$emit("input", files);
    },
  },
};
</script>
