import get from "lodash/get";
import { endOfDay, startOfDaySubtract } from "@/plugins/moment";

import { filterTypes } from "@/utils/filters";

/**
 * Colunas do Grid
 */
export const columns = [
  {
    text: "Orçamento",
    value: "numero",
    maxWidth: "100px",
    position: 0,
  },
  { text: "Data", value: "created_at", type: "date" },
  { text: "Vendedor", value: "colaborador_razao_social" },
  { text: "Cliente", value: "clifor_razao_social" },
  {
    text: "Total",
    value: "vlr_total",
    class: "text--text",
    align: "right",
  },
  {
    text: "Desconto",
    value: "vlr_desconto",
    class: "text--text",
    align: "right",
  },
  { text: "Frete", value: "vlr_frete", class: "text--text", align: "right" },
  { text: "NF-e", value: "numero_nfe" },
  { text: "Cupom Fiscal", value: "numero_nfce" },
];

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = columns.filter((column) =>
  ["id", "colaborador_razao_social", "clifor_razao_social"].includes(
    column.value
  )
);

/**
 * Dependências do módulo
 */
export const dependencies = [{ name: "itens" }];

/**
 * Ações do submenu
 */
export const subactions = function (this: any) {
  const disabled = !this.registro || !this.registro.id;
  const formattedId = (this.registro?.numero ?? "")
    .toString()
    .padStart(10, "0");

  return [
    {
      title: "Arquivo",
      items: [
        {
          id: "import_document",
          modulo: "saida",
          import: this.__modulo.modulo,
          import_registro_id: formattedId,
          title: `Gerar NF-e do Orçamento #${formattedId}`,
          disabled,
        },
        {
          id: "import_document",
          modulo: "cupons-fiscais",
          import: this.__modulo.modulo,
          import_registro_id: formattedId,
          title: `Gerar ${this.$nomeCupomFiscal} do Orçamento #${formattedId}`,
          disabled,
        },
      ],
    },
    // {
    //   title: "Relatórios",
    //   disabled: true,
    //   type: "report",
    //   items: reports.bind(this)()
    // }
  ];
};

/**
 * Relatórios
 */
export const reports = function (this: any) {
  const filterDateInterval = {
    queryStart: startOfDaySubtract(),
    queryEnd: endOfDay(),
  };

  return [
    {
      id: "orcamentos_pendentes",
      title: "Orçamentos pendentes",
      type: "form-report",
      form: [
        {
          title: "Vendas por vendedor",
          default: filterDateInterval,
          type: filterTypes.DATE_INTERVAL,
        },
      ],
    },
  ];
};

export const fichaBottomActions = function (this: any) {
  return [
    {
      text: true,
      title: "Desconto",
      class: "mr-4",
      on: {
        click: () =>
          this.__ficha?.currentModuloInstance?.requestDiscountAssistent(),
      },
    },
  ];
};

/**
 * Formata os dados da ficha antes de enviar pro backend (update/create)
 */
export const format = function (this: any) {
  return {
    clifor_id: get(this.data, "clifor_id", get(this.data, "clifor.id")),
  };
};

/**
 * Chaves para forçar atualização na tela da ficha, quando update
 */
export const forceUpdateKeys = [
  "vlr_total",
  "vlr_desconto",
  "vlr_frete",
  "colaborador",
  "colaborador_razao_social",
  "clifor",
];

/**
 * Cache default
 */
export const cache = {
  colunas: [
    "numero",
    "created_at",
    "clifor_razao_social",
    "vlr_total",
    "vlr_desconto",
    "vlr_frete",
    "numero_nfe",
    "numero_nfce",
  ],
  ordenar: [
    {
      coluna: "numero",
      ordem: "desc",
    },
  ],
};
