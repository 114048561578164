import get from "lodash/get";

import API from "@/api";
import actions from "@/store/actions";
import mutations from "@/store/mutations";
import { printPDF } from "@/utils/print";

const api = API.config({
  state: "loading",
  mutation: mutations.MODULOS.CONTAS.LOADING,
  delay: 0,
});

const intialState = {
  loading: false,
};

export default {
  state: Object.assign({}, intialState),

  mutations: {
    /**
     */
    [mutations.MODULOS.CONTAS.LOADING](state, loading) {
      // Atribui o loading
      state.loading = loading;
    },
  },

  actions: {
    /**
     */
    [actions.MODULOS.CONTAS.MULTIPLE](store, params) {
      return api.store(store).post(`/${params.modulo}/multiple`, params);
    },
    /**
     */
    [actions.MODULOS.CONTAS.GENERATE](store, params) {
      return api.store(store).post(`/${params.modulo}/generate`, params);
    },

    /**
     * Gera o PDF dos boletos
     */
    [actions.MODULOS.CONTAS.BOLETO_PDF](store, { path }) {
      return api
        .store(store)
        .get(path, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          printPDF(response, "Boleto");
        });
    },
    /**
     * Envia os boletos por email
     */
    [actions.MODULOS.CONTAS.BOLETO_EMAIL](store, { path }) {
      return api
        .store(store)
        .post(path)
        .then((response) => {
          const text = get(response, "data.message");
          if (text) {
            store.commit(mutations.APP.SNACKBAR, {
              text,
              active: true,
            });
          }
        });
    },
  },
};
