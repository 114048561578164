
import { defineComponent } from "vue";
import TextField from "./textfield/TextField.vue";

export default defineComponent({
  components: { TextField },
  props: {
    colClass: {},
    value: { type: String, default: "" },
  },

  data: () => ({
    modal: false,
  }),

  computed: {
    formattedDate(): string {
      const splited = this.date?.split("-");
      if (splited.length !== 2) return this.date;
      const [y, m] = splited;
      return `${m}/${y}`;
    },
    date: {
      set(value) {
        this.$emit("input", value);
      },
      get(): string {
        return this.value;
      },
    },
  },
});
