import Vue from "vue";

import { debounce, get, head } from "lodash";

const itemHeight = 23;

export default Vue.extend({
  methods: {
    /* ===== Arrow behaviors ===== */
    /**
     * Função que move a posição selecionada para esquerda
     */
    moveLeft: debounce(function (this: any, start = false) {
      // Captura a coluna selecionada
      let { coluna } = this.value;

      // Se tentar sair da tabela horizontalmente, retorna
      if (coluna === 0) return;

      // Se for ir para o inicio
      if (start) {
        // Zera a coluna
        coluna = 0;

        // Aguarda um tick
        this.$nextTick(() => {
          // Faz o scroll x para inicio
          this.scrollX(0);
        });
      }

      // Caso contrario, só volta uma coluna
      else coluna--;

      const item = head(
        this.$refs[`data-table-cell-item-${this.selectedIndex}-${coluna}`]
      ) as any;

      let after = head(
        this.$refs[`data-table-cell-item-${this.selectedIndex}-${coluna + 1}`]
      ) as any;

      if (coluna === 0) after = null;

      if (item) {
        this.scrollX(
          get(this.$refs, "wrapper.scrollLeft", 0) -
            get(after, "$el.clientWidth", get(item, "$el.clientWidth", 0)) -
            1
        );
      }

      // Seleciona a célula na mesma linha, porém na coluna anterior
      this.setActiveCell(null, coluna);
    }, 1),
    /**
     * Função que move a posição selecionada para cima
     */
    moveUp: debounce(function (this: any, start = false) {
      // Captura o indice selecionado
      let index = this.selectedIndex;

      // Se o índice atual for no limite da tabela (<= 0) retorna
      if (index <= 0) return;

      // Se for ir para o inicio da tabela
      if (start) {
        // Inicia o index
        index = 0;

        // Aguarda um tick
        this.$nextTick(() => {
          // Faz scroll para o topo
          this.scrollY(0);
        });
      }

      // Caso contrário, só diminui um index
      else index--;

      this.scrollY(get(this.$refs, "wrapper.scrollTop", 0) - itemHeight);

      // Seleciona a célula uma linha anterior, porém na mesma coluna
      this.setActiveCell(this.items_[index]);
    }, 1),
    /**
     * Função que move a posição selecionada para direita
     */
    moveRight: debounce(function (this: any, end = false) {
      // Captura a coluna selecionada
      let { coluna } = this.value;

      // Se a coluna for no limite da tabela (coluna === tamanho das headers) retorna
      if (coluna === this.headers.length - 1) return;

      // Se for para ir ate o fim
      if (end) {
        // Captura a ultima coluna
        coluna = this.headers.length - 1;

        // Aguarda um tick
        this.$nextTick(() => {
          // Faz scroll x para o fim
          this.scrollX("max");
        });
      }

      // Caso contrário, apenas anda uma coluna para frente
      else coluna++;

      const item = head(
        this.$refs[`data-table-cell-item-${this.selectedIndex}-${coluna}`]
      ) as any;

      let before = head(
        this.$refs[`data-table-cell-item-${this.selectedIndex}-${coluna - 1}`]
      ) as any;

      if (coluna === this.headers.length - 1) {
        before = null;
      }

      if (item) {
        this.scrollX(
          get(this.$refs, "wrapper.scrollLeft", 0) +
            get(before, "$el.clientWidth", get(item, "$el.clientWidth", 0)) +
            1
        );
      }

      // Seleciona a célula na mesma linha, porém na coluna posterior
      this.setActiveCell(null, coluna);
    }, 1),
    /**
     * Função que move a posição selecionada para baixo
     */
    moveDown: debounce(function (this: any, end = false) {
      // Captura o index selecionado
      let index = this.selectedIndex;

      // Se estiver no limite, retorna
      if (index === this.items_.length - 1) return;

      // Se for pra ir ate o fim
      if (end) {
        // Captura o index
        index = this.items.length - 1;

        // Aguarda um tick
        this.$nextTick(() => {
          // Faz scroll y pro maximo
          this.scrollY("max");
        });
      }

      // Caso contrário, anda um index
      else index++;

      this.scrollY(get(this.$refs, "wrapper.scrollTop", 0) + itemHeight);

      // Seleciona a célula na próxima linha, porém na mesma coluna
      this.setActiveCell(this.items_[index]);
    }, 1),
    /* ===== Scroll behaviors ===== */
    /**
     * Função que modifica o scroll vertical
     */
    scrollY(pos) {
      // Captura a div scrollable
      const { wrapper } = this.$refs;

      // Se foi passado max
      if (pos === "max")
        // Captura a altura maxima
        pos = (wrapper as HTMLElement).scrollHeight;

      // Modifica o scroll
      (wrapper as HTMLElement).scrollTop = pos;
    },
    /**
     * Função que modifica o scroll horizontal
     */
    scrollX(pos) {
      // Captura a div scrollable
      const { wrapper } = this.$refs;

      // Se foi passado max
      if (pos === "max")
        // Captura a largura maxima
        pos = (wrapper as HTMLElement).scrollWidth;

      // Modifica o scroll
      (wrapper as HTMLElement).scrollLeft = pos;
    },
    /**
     * Função que modifica o scroll vertical em pagination
     */
    scrollBy(pos = 500) {
      // Captura a div scrollable
      const { wrapper } = this.$refs;

      // Modifica o scroll
      (wrapper as HTMLElement).scrollBy(0, pos);
    },
  },
});
