<template>
  <v-menu
    offset-y
    max-width="100%"
    transition="scale-transition"
    :close-on-content-click="false"
    :nudge-right="40"
    :disabled="readonly || disabled"
    v-model="menu"
  >
    <template #activator="{ on }">
      <component
        type="tel"
        ref="input"
        v-model="value_"
        :readonly="readonly"
        :disabled="disabled"
        :is="component"
        v-mask="mask"
        v-bind="{ ...$attrs, white }"
        @ctrl-delete="$emit('ctrl-delete', $event)"
        @change="onChange"
        @focus="(event) => $emit('focus', event)"
      >
        <template #append v-if="!readonly && !disabled">
          <btn tabindex="-1" tooltip="Data atual" icon v-if="!hideButtonNow">
            <v-icon
              small
              tabindex="-1"
              :color="white ? 'white' : null"
              @click.stop="onButtonNowClick"
              >mdi-alarm-plus</v-icon
            >
          </btn>
          <btn
            tabindex="-1"
            tooltip="Abrir calendário"
            icon
            v-if="!hideButtonCalendar"
          >
            <v-icon
              small
              tabindex="-1"
              :color="white ? 'white' : null"
              v-on="on"
              >mdi-calendar</v-icon
            >
          </btn>
        </template>
      </component>
    </template>

    <v-tabs
      dark
      touchless
      class="elevation-2"
      :background-color="options.titleBarColor"
      v-model="activeTab"
    >
      <v-tab href="#tab-calendar" v-show="type !== 'time'">
        <v-icon left>mdi-calendar</v-icon>
        {{ options.tabDateTitle }}
      </v-tab>

      <v-tab href="#tab-time" v-show="type !== 'date'">
        <v-icon left>mdi-calendar-clock</v-icon>
        {{ options.tabTimeTitle }}
      </v-tab>

      <v-tabs-items touchless v-model="activeTab">
        <v-tab-item value="tab-calendar">
          <v-card flat style="overflow: auto">
            <v-date-picker
              no-title
              width="260"
              :locale="options.locale"
              :value="datePickerValue"
              @change="onDatePickerChange"
            />
          </v-card>
        </v-tab-item>

        <v-tab-item value="tab-time">
          <v-card flat tile>
            <v-time-picker
              landscape
              ref="refTimePicker"
              format="24hr"
              :value="timePickerValue"
              :color="options.titleBarColor"
              :use-seconds="options.useSeconds"
              :disabled="type !== 'time' && !value"
              @change="onTimePickerChange"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-menu>
</template>

<script>
import { get, join, split, toString } from "lodash";
import { mask } from "vue-the-new-mask";

import moment, {
  startOfDay,
  endOfDay,
  milliseconds,
  mDate,
  mTime,
  mTimestamp,
  mNow,
} from "@/plugins/moment";

import TextField from "./textfield/TextField.vue";
import CTextField from "./textfield/CTextField.vue";
import Btn from "../buttons/Btn.vue";

export default {
  components: { Btn },
  directives: {
    mask,
  },
  props: {
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    white: { type: Boolean, default: false },
    clear: { type: Boolean, default: false },
    hideButtonNow: { type: Boolean, default: false },
    hideButtonCalendar: { type: Boolean, default: false },
    startOfDay: { type: Boolean, default: false },
    endOfDay: { type: Boolean, default: false },
    nullable: { type: Boolean, default: false },
    type: {
      type: String,
      default: "date",
      validator: () => ["date", "time", "timestamp"],
    },
    value: { default: 0 },
    options: {
      type: Object,
      default: () => ({
        tabDateTitle: "Data",
        tabTimeTitle: "Hora",
        locale: "pt-BR",
        format: "DD/MM/YYYY",
        nativeFormat: "YYYY-MM-DD",
        titleBarColor: "primary",
        closeOnDateClick: false,
        useSeconds: false,
      }),
    },
  },
  created() {
    if (this.type === "time") this.activeTab = "tab-time";

    if (this.hasModifiers)
      this.$nextTick(() => {
        let value = parseFloat(this.value);

        if (isNaN(value) || !value) return;

        value = mNow(value);

        value = this.modifiers(value);

        this.emit(value, null, false);
      });
    else {
      this.updateTextField();
    }
  },
  data: () => ({
    menu: false,
    value_: "",
    activeTab: "tab-calendar",
  }),
  computed: {
    hasModifiers() {
      return this.startOfDay || this.endOfDay;
    },
    mask() {
      return this.type === "time"
        ? "##:##"
        : this.type === "date"
        ? "##/##/####"
        : ["##/##/####", "##/##/#### ##:##"];
    },
    component() {
      return this.clear ? CTextField : TextField;
    },

    datePickerValue() {
      return mNow(this.value).format(this.options.nativeFormat);
    },
    timePickerValue() {
      return moment(this.value || undefined).format("HH:mm");
    },
  },
  watch: {
    menu() {
      if (!this.menu) {
        this.activeTab = this.type === "time" ? "tab-time" : "tab-calendar";
        if (this.$refs.refTimePicker) {
          this.$refs.refTimePicker.selectingHour = true;
        }
      }
    },
    value() {
      this.updateTextField();
    },
  },
  methods: {
    updateTextField() {
      let date = parseFloat(this.value);

      if (isNaN(date) || date === 0) return (this.value_ = null);

      date = mNow(date);

      switch (this.type) {
        case "time":
          return (this.value_ = mTime(date));
        // return date.toLocaleTimeString().substr(0, 5);
        case "date":
          return (this.value_ = mDate(date));
        // return date.toLocaleDateString();
        default:
          return (this.value_ = mTimestamp(date));
        // return date.toLocaleString().substr(0, 16);
      }
    },
    onChange(value, oldValue) {
      if (!value) return this.emit(null);

      const [h, i, s] = split(value, ":");
      const [oh, oi, os] = split(oldValue ?? "", ":");

      switch (this.type) {
        case "time":
          return this.emit(
            milliseconds(
              `1970-01-01 ${this.handleLeftZeros(h) ?? "00"}:${
                this.handleLeftZeros(i) ?? "00"
              }:${this.handleLeftZeros(s) ?? "00"}`
            ),
            milliseconds(
              `1970-01-01 ${this.handleLeftZeros(oh) ?? "00"}:${
                this.handleLeftZeros(oi) ?? "00"
              }:${this.handleLeftZeros(os) ?? "00"}`
            )
          );
        case "date":
          return this.emit(
            milliseconds(value.split("/").reverse().join("-") + " 00:00:00"),
            milliseconds(oldValue.split("/").reverse().join("-") + " 00:00:00")
          );
        default:
          return this.emit(
            milliseconds(
              value.substr(0, 10).split("/").reverse().join("-") +
                ` ${value.substr(11, 8)}`
            ),
            milliseconds(
              oldValue.substr(0, 10).split("/").reverse().join("-") +
                ` ${oldValue.substr(11, 8)}`
            )
            // parseFloat(
            //   moment(
            //     value.substr(0, 10).split("/").reverse().join("-") +
            //       ` ${value.substr(11, 8)}`
            //   ).format("x")
            // ),
            // parseFloat(
            //   moment(
            //     oldValue.substr(0, 10).split("/").reverse().join("-") +
            //       ` ${oldValue.substr(11, 8)}`
            //   ).format("x")
            // )
          );
      }
    },
    onButtonNowClick() {
      this.emit(milliseconds());

      this.focusInput();
    },
    focusInput() {
      this.$nextTick(() => {
        const ref = get(this.$refs, "input");
        ref && ref.focus();
      });
    },
    modifiers(value) {
      if (!value && this.nullable) return value;

      if (this.startOfDay) return startOfDay(value);
      if (this.endOfDay) return endOfDay(value);

      return value;
    },
    emit(value, old = null, change = true) {
      let mValue = this.modifiers(value);

      if (isNaN(mValue)) mValue = old;

      this.$emit("input", mValue);

      if (change) this.$emit("change", mValue);

      this.$nextTick(() => {
        this.updateTextField();
      });
    },
    handleLeftZeros(intTime) {
      return toString(intTime).length == 1 ? `0${intTime}` : intTime;
    },
    onDatePickerChange(value) {
      // Se não houver valor, emite null
      if (!value) this.emit(null);
      // Caso contrário
      else {
        // Faz o parse do valor atual
        const parsed = parseFloat(this.value);

        // Inicia uma data nova
        const current = new Date(this.value);

        // Se não houver valor atual
        if (isNaN(parsed) || parsed === 0) {
          // Emite com a hora zerada
          this.emit(parseFloat(moment(`${value} 00:00:00`).format("x")));
        }
        // Caso contrário
        else {
          // Define as hrs, mins e secs
          const time = [
            this.handleLeftZeros(current.getHours()),
            this.handleLeftZeros(current.getMinutes()),
            this.handleLeftZeros(current.getSeconds()),
          ];

          // Emite com as horas que já estavam preenchidas, ou 00
          this.emit(
            // parseFloat(moment(`${value} ${join(time, ":")}`).format("x"))
            milliseconds(`${value} ${join(time, ":")}`)
          );
        }
      }

      if (this.options.closeOnDateClick || this.type === "date") {
        this.menu = false;
      } else {
        this.activeTab = "tab-time";
      }

      this.focusInput();
    },
    onTimePickerChange(value) {
      const current = new Date(this.value);

      this.emit(
        milliseconds(current.toISOString().substr(0, 10) + ` ${value}`)
      );
      // this.emit(Date.parse(current.toISOString().substr(0, 10) + ` ${value}`));

      this.menu = false;
    },
  },
};
</script>
