<template>
  <th
    @click="onClick"
    @dblclick="$emit('dblclick', $event)"
    ref="header"
    class="s-datatable-header"
    :style="`width: ${width}`"
    :class="{ sortable: !header.unsortable, sorting: !!sorting }"
  >
    <v-row
      style="flex-wrap: nowrap !important"
      class="s-col draggable-target"
      no-gutters
    >
      <v-col class="font-weight-bold align-self-center">
        {{ typeof header === "object" ? header.text : header }}
      </v-col>

      <v-col
        class="ml-auto text-right th-icon-wrapper"
        v-if="sortIcon && !header.editableIcon"
      >
        <v-icon small>{{ sortIcon }}</v-icon>
      </v-col>

      <v-col
        class="ml-auto text-right th-icon-wrapper"
        v-else-if="header.editableIcon"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-col>
    </v-row>
    <div
      v-if="!header.noResize"
      @mousedown.prevent.stop="onResizeMouseDown"
      @dblclick.stop="onResizeDoubleClick"
      ref="resize"
      class="s-datatable-header-resize-area"
    />
  </th>
</template>

<script>
import { find, get, debounce } from "lodash";

export default {
  name: "DataTableHeader",
  props: {
    header: { type: [Object, String], required: true },
    order: { type: Array },
  },
  created() {
    this.width = this.header.width ?? "auto";
  },
  computed: {
    /**
     * Retorna o ícone baseado no modo de ordenação ASC/DESC
     */
    sortIcon() {
      if (!this.sorting) return;
      if (!this.sorting.ordem) return;
      if (this.sorting.ordem.toLowerCase() === "asc") return "mdi-chevron-up";
      return "mdi-chevron-down";
    },
    /**
     * Retorna o objeto|null que representa se a coluna está ordenando ou não
     */
    sorting() {
      return this.order
        ? find(
            this.order,
            (order) =>
              this.header.value === order.coluna ||
              this.header.from === order.coluna
          )
        : null;
    },
  },
  mounted() {
    if (!this.header.noResize) {
      document.addEventListener("mousemove", this.onMouseMove);
      document.addEventListener("mouseup", this.onResizeMouseUp);
    }
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("mouseup", this.onResizeMouseUp);
  },
  data: () => ({
    moving: false,
    pageX: undefined,
    curCol: undefined,
    curColWidth: undefined,
    maxWidth: undefined,
    timeout: undefined,

    width: "auto",
  }),
  methods: {
    onClick(event) {
      !this.moving && this.$emit("click", event);
    },
    onResizeMouseUp() {
      this.curCol = undefined;
      this.pageX = undefined;
      this.curColWidth = undefined;

      setTimeout(() => {
        this.moving = false;
      }, 10);
    },
    onResize: debounce(function (e) {
      // se for duplo click
      if (e.detail === 2) {
        return;
      }

      this.curCol = e.target.parentElement;
      this.pageX = e.pageX;
      this.curColWidth = this.curCol.offsetWidth;
      if (!this.maxWidth)
        this.maxWidth = this.curCol ? this.curCol.clientWidth : 0;
    }, 40),
    onResizeMouseDown(e) {
      this.moving = true;
      this.onResize(e);
    },
    onMouseMove(e) {
      if (this.curCol) {
        const diff = this.curColWidth + (e.pageX - this.pageX);
        this.curCol.style.width = diff - 16 + "px";
      }
    },
    onResizeDoubleClick(e) {
      e.target.parentElement.style.width = "auto";
    },
  },
  watch: {
    header(header) {
      const ref = get(this.$refs, "header");

      if (!ref) return;

      ref.style.width = get(header, "width", "auto");
    },
    moving(isMoving, wasMoving) {
      const ref = get(this.$refs, "header");

      if (!ref) return;

      if (isMoving === false && wasMoving === true) {
        this.$emit("resize:header", {
          header: this.header,
          width: ref.style.width,
        });
      }
    },
  },
};
</script>

<style scoped>
th.s-datatable-header {
  font-size: 11px !important;
  /* font-weight: bold; */
  /* letter-spacing: -0.5em; */
  overflow: hidden;
  text-overflow: text-truncate;
  text-align: left;
  border-left: unset !important;
  border-bottom: 1px solid var(--v-tableborder-base) !important;
  border-right: 1px solid var(--v-tableborder-base) !important;

  min-width: min-content;
  white-space: nowrap;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  z-index: 1;
  top: 0;
  user-select: none;
  /* background-color: #f9f9f9; */
  background-color: var(--v-th-base);

  padding: 0px 4px 0px 2px;

  /* height: 30px; */
}
.s-datatable-header.sortable {
  cursor: pointer;
}
.s-datatable-header {
  color: var(--v-text-lighten4);
}
.s-datatable-header.sorting {
  color: var(--v-text-base);
}
.s-col {
  width: inherit;
  padding: 0px;
  margin: 0px 6px;
  line-height: 1.6rem;
  vertical-align: middle;
}
.s-datatable-header-resize-area {
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  position: absolute;
  cursor: col-resize;
  user-select: none;
}
.s-datatable-header-resize-area:hover {
  background-color: #4dc8f4;
}
</style>

<style lang="scss">
// .th-icon-wrapper::before {
//   content: "\00a0 ";
// }
th.th-draggable-highlight {
  background-color: rgb(155, 228, 255) !important;
  color: #ffffff !important;
}
.th-draggable-chosen {
  background-color: #e7e7e7 !important;
  color: #000 !important;
}
</style>
