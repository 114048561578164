<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="pl-7">
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pb-2">
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    title: {},
  },
};
</script>

<style lang="scss">
.v-expansion-panel {
  .v-expansion-panel-header {
    min-height: 16px !important;

    &:before {
      background-color: unset !important;
    }
  }

  outline: thin solid var(--v-tableborder-base);
}
</style>
