<template>
  <v-col cols="12" md="6" lg="5" offset-lg="1">
    <v-row class="ma-0">
      <p-text-field
        auto-focus
        name="descricao"
        label="Descrição"
        maxlength="35"
        v-model="data.descricao"
      />
    </v-row>
  </v-col>
</template>

<script>
import { PTextField } from "@/components/form";

// Mixins
import Ficha from "@/mixins/ficha";

export default Ficha.extend({
  components: {
    PTextField,
  },
});
</script>
