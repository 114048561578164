import { active, inactive } from "@/utils/filters";
import { filter, includes } from "lodash";

/**
 * Colunas do Grid
 */
export const columns = [
  { text: "Nome", value: "nome", action: "abrir_controle_bancario" },
  // { text: "Agência", value: "agencia", action: "abrir_controle_bancario" },
  // { text: "Conta corrente", value: "conta", action: "abrir_controle_bancario" },
  {
    text: "Plano de contas",
    value: "plano_conta",
    action: "abrir_controle_bancario",
  },
  {
    text: "Saldo do banco",
    value: "vlr_saldo",
    align: "right",
    action: "abrir_controle_bancario",
  },
  { text: "Ativo", value: "ativo", type: "boolean" },
];

/**
 * Ações do submenu
 */
export const subactions = function (this: any) {
  return [
    {
      title: "Exibir",
      type: "filter",
      items: [
        {
          title: "Ativos",
          filter: active(),
        },
        {
          title: "Inativos",
          filter: inactive(),
        },
      ],
    },
  ];
};

/**
 * Ações de contexto da tabela
 */
export const context = function (this: any) {
  const active = this.registro && this.registro.ativo;

  return [
    {
      id: "ativo",
      title: "Ativo",
      icon: !active ? "mdi-checkbox-blank-outline" : "mdi-check",
    },
  ];
};

export const forceUpdateKeys = ["banco", "boleto"];

/**
 * Tabs da ficha
 */
export const tabs = [
  { item: "tab-principal", title: "Principal" },
  {
    item: "tab-boleto",
    title: "Boleto",
    disabled: (data) => !data.id || !data.banco || !data.banco.codigo,
  },
];

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = filter(columns, (column) =>
  // Retorna as colunas que as incluem
  includes(["nome", "plano_conta", "vlr_saldo"], column.value)
);

export const ficha = () => ({
  banco: {},
});

/**
 * Cache default
 */
export const cache = {
  colunas: ["nome", "plano_conta", "vlr_saldo"],
  filtros: [],
  ordenar: [
    {
      coluna: "nome",
      ordem: "asc",
    },
  ],
};
