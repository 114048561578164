<template>
  <v-row no-gutters v-bind="$attrs">
    <p-precision-field
      cols="6"
      sm="4"
      md="2"
      :disabled="!editable"
      :auto-focus="editable"
      name="vlr_bc_icms"
      v-model="data.vlr_bc_icms"
      label="Base ICMS"
    />
    <p-precision-field
      cols="6"
      sm="4"
      md="2"
      :disabled="!editable"
      name="vlr_icms"
      v-model="data.vlr_icms"
      label="Valor ICMS"
    />
    <p-precision-field
      cols="6"
      sm="4"
      md="2"
      :disabled="!editable"
      name="vlr_bc_icms_st"
      v-model="data.vlr_bc_icms_st"
      label="Base Subst."
    />
    <p-precision-field
      cols="6"
      sm="4"
      md="2"
      :disabled="!editable"
      name="vlr_icms_st"
      v-model="data.vlr_icms_st"
      label="ICMS Subst."
    />
    <p-precision-field
      disabled
      cols="6"
      sm="4"
      md="2"
      name="vlr_fcp_st"
      v-model="data.vlr_fcp_st"
      label="Total FCPST"
    />
    <p-precision-field
      cols="6"
      sm="4"
      md="2"
      name="vlr_desconto"
      label="Desconto"
      :auto-focus="!editable"
      v-model="data.vlr_desconto"
    />
    <p-precision-field
      cols="6"
      sm="4"
      md="2"
      name="vlr_frete"
      v-model="data.vlr_frete"
      label="Frete"
    />
    <p-precision-field
      cols="6"
      sm="4"
      md="2"
      name="vlr_seguro"
      v-model="data.vlr_seguro"
      label="Seguro"
    />
    <p-precision-field
      cols="6"
      sm="4"
      md="2"
      name="vlr_despesas"
      v-model="data.vlr_despesas"
      label="Outras despesas"
    />
    <p-precision-field
      cols="6"
      sm="4"
      md="2"
      name="vlr_ipi"
      label="Valor IPI"
      :disabled="!editable"
      v-model="data.vlr_ipi"
    />
    <p-precision-field
      disabled
      cols="6"
      sm="4"
      md="2"
      name="vlr_iss"
      v-model="data.vlr_iss"
      label="Valor ISS"
      v-if="modulo !== 'entrada'"
    />
    <p-precision-field
      disabled
      cols="6"
      sm="4"
      md="2"
      name="vlr_retencao_ir"
      v-model="data.vlr_retencao_ir"
      label="Retenção IR"
    />
    <p-precision-field
      disabled
      cols="6"
      sm="4"
      md="2"
      name="vlr_servicos"
      v-model="data.vlr_servicos"
      label="Total serviços"
    />
    <p-precision-field
      cols="6"
      sm="4"
      md="2"
      disabled
      name="vlr_produtos"
      v-model="data.vlr_produtos"
      label="Total produtos"
    />
    <p-precision-field
      disabled
      cols="6"
      sm="4"
      md="2"
      name="vlr_total"
      v-model="data.vlr_total"
      label="Total nota"
    />
  </v-row>
</template>

<script>
import PPrecisionField from "../persistents/PPrecisionField.vue";

export default {
  components: {
    PPrecisionField,
  },
  props: {
    data: { default: () => ({}), type: Object },

    modulo: { required: true },
    editable: { default: false, type: Boolean },
  },
};
</script>
