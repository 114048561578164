import API from "@/api";
import actions from "@/store/actions";
import mutations from "@/store/mutations";

const prefix = "/entrada";

const api = API.config({
  state: "loading",
  mutation: mutations.MODULOS.ENTRADA.LOADING,
  delay: 0,
});

const intialState = {
  loading: false,
};

export default {
  state: Object.assign({}, intialState),

  mutations: {
    /**
     * Mutação que controla o carregamento das funções do estoque
     */
    [mutations.MODULOS.ENTRADA.LOADING](state, loading) {
      // Atribui o loading
      state.loading = loading;
    },
  },

  actions: {
    /**
     * Função que envia a requisição da alteração do fator de conversão de um produto por dentro da NF-e de entrada
     */
    [actions.MODULOS.ENTRADA.FATOR_CONVERSAO](store, params) {
      // Retorna a promessa da requisição
      return api.store(store).post(`${prefix}/fator-conversao`, params);
    },
    /**
     * Função que envia a requisição de fabricação do item baseado na sua composição
     */
    [actions.MODULOS.ENTRADA.REFERENCE](store, params) {
      const { id } = params;

      // Retorna a promessa da requisição
      return api.store(store).put(`${prefix}/reference/${id}`, params);
    },

    /**
     * Função que envia a requisição de fabricação do item baseado na sua composição
     */
    [actions.MODULOS.ENTRADA.GERAR_NFE](store, params) {
      const { commit } = store;

      // Retorna a promessa da requisição
      return api
        .store(store)
        .post(`${prefix}/gerar-nfe-entrada`, params)
        .then((response) => {
          // Captura os dados da resposta
          const { data } = response;

          // SE não houver, retorna
          if (!data) return;

          // Captura a mensagem e o registro dos dados
          const { message, registro } = data;

          // Se houver mensagem
          if (message)
            // Commita o snackbar
            commit(mutations.APP.SNACKBAR, {
              text: message,
              active: true,
            });

          // Se houver registro
          if (registro) {
            // Atualiza o registro no grid
            commit(mutations.MODULO.REGISTROS.UPDATED, registro);
          }

          // Propaga a resposta
          return response;
        });
    },
  },
};
