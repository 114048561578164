<template>
  <v-row class="mx-0" v-bind="$attrs">
    <v-col cols="12" md="6" class="pl-0">
      <v-row no-gutters>
        <p-select-filter
          name="indicador_frete"
          label="Frete por conta"
          :item-texts="['value', 'text']"
          :items="fretes"
          v-model="data.indicador_frete"
        />
        <p-text-field
          sm="4"
          maxlength="60"
          name="especie"
          label="Espécie"
          v-model="data.especie"
        />
        <p-text-field
          sm="4"
          name="marca"
          maxlength="60"
          v-model="data.marca"
          label="Marca"
        />
        <p-precision-field
          prefix
          cols="6"
          sm="4"
          fixed="4"
          v-model="data.peso_bruto"
          name="peso_bruto"
          label="Peso bruto"
        />
        <p-precision-field
          prefix
          cols="6"
          sm="3"
          fixed="4"
          v-model="data.peso_liquido"
          name="peso_liquido"
          label="Peso líquido"
        />
        <p-precision-field
          sm="3"
          name="qtd_volumes"
          label="Qtd. volumes"
          v-model="data.qtd_volumes"
        />
        <p-text-field
          sm="6"
          maxlength="60"
          name="numeracao_volumes"
          v-model="data.numeracao_volumes"
          label="Numeração volumes"
        />
      </v-row>
    </v-col>

    <v-col cols="12" md="6" class="px-0">
      <v-row no-gutters>
        <p-select-filter
          lg="6"
          item-value="id"
          name="transportadora_id"
          label="Transportadora"
          item-text="nome_razao_social"
          :filter-by="['nome_razao_social']"
          :items="extra.transportadoras"
          v-model="data.transportadora_id"
        />
        <p-select-filter
          lg="6"
          item-value="id"
          name="veiculo_id"
          label="Veículo"
          :item-texts="['placa', 'uf']"
          :filter-by="['placa', 'uf']"
          :disabled="!data.transportadora_id"
          :items="veiculos"
          v-model="data.veiculo_id"
        />
      </v-row>
      <container-content hidden cols="12">
        <v-row>
          <list-info :items="transpor" />
        </v-row>
      </container-content>
    </v-col>
  </v-row>
</template>

<script>
import { mask } from "vue-the-new-mask";

import ListInfo from "@/components/utils/info/ListInfo.vue";
import ContainerContent from "@/components/form/utils/ContainerContent.vue";

import PPrecisionField from "../persistents/PPrecisionField.vue";
import PSelectFilter from "../persistents/PSelectFilter.vue";
import PTextField from "../persistents/PTextField.vue";

export default {
  directives: { mask },
  props: {
    data: { default: () => ({}) },
    extra: { default: () => [] },
  },
  components: {
    PPrecisionField,
    PSelectFilter,
    PTextField,
    ContainerContent,
    ListInfo,
  },
  computed: {
    fretes() {
      return [
        {
          text: "Contratação do frete por conta do Remetente (CIF)",
          value: "0",
        },
        {
          text: "Contratação do frete por conta do Destinatário/Remetente (FOB)",
          value: "1",
        },
        { text: "Contratação do frete por conta de terceiros", value: "2" },
        { text: "Transporte próprio por conta do remetente", value: "3" },
        {
          text: "Transporte próprio por conta do destinatário",
          value: "4",
        },
        { text: "Sem ocorrência do transporte", value: "9" },
      ];
    },
    veiculos() {
      return (this.extra.veiculos ?? []).filter(
        (it) => it.clifor_id === this.$json(this.data.transportadora, "id")
      );
    },
    transpor() {
      const clifor = this.data.transportadora ?? {};
      const veiculo = this.data.veiculo ?? {};
      const isCNPJ = (clifor.cpf_cnpj ?? "").length == 18 ? true : false;

      return [
        `${
          clifor.cpf_cnpj
            ? (isCNPJ ? "CNPJ " : "CPF ") + clifor.cpf_cnpj
            : "<CPF/CNPJ> "
        }, ${
          clifor.rg_ie ? (isCNPJ ? " IE " : " RG ") + clifor.rg_ie : " <RG/IE>"
        }`,
        `${veiculo.placa ? "Placa. " + veiculo.placa : "<Placa>"} - ${
          veiculo.uf || "<UF Veículo>"
        }`,
        `${clifor.telefone ? "Tel. " + clifor.telefone : "<Telefone>"}, ${
          clifor.endereco || "<Endereço>"
        }`,
        `${clifor.municipio || "<Cidade>"} - ${clifor.uf || "<UF>"}`,
      ];
    },
  },
};
</script>
