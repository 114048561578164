import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";

Vue.use(Vuex);

/**
 * @exports Vuex.Store Instância da Store Vuex
 */
export default new Vuex.Store({
  modules,
});
