<template>
  <v-col cols="12" md="8" offset-md="2" v-bind="$attrs">
    <v-row>
      <v-col>
        <h5>Forma de pagamento</h5>
        <btn
          block
          outlined
          class="mr-2"
          :disabled="disabled || !meios.length || nothingToReceive"
          :loading="loading"
          @click="onInsertPayment"
        >
          {{ pagamento ? pagamento.forma?.descricao : "Inserir Pagamento" }}
        </btn>
      </v-col>
    </v-row>

    <inserir-forma-pagamento
      :__ficha="__ficha"
      ref="assistent"
      :data="data"
      :formas="meios"
      :onBeforeConfirm="onBeforeConfirm"
      :onFinish="onActiveComponentClose"
      :initialPagamento="pagamento"
      @close="onActiveComponentClose"
      v-if="activeComponent"
    />
  </v-col>
</template>

<script>
import get from "lodash/get";

import Btn from "@/components/form/buttons/Btn.vue";
import InserirFormaPagamento from "@/components/popups/modulos/cupons-fiscais/InserirFormaPagamento.vue";

import actions from "@/store/actions";
import { clearCurrency } from "@/utils";
import { head } from "lodash";

export default {
  components: {
    Btn,
    InserirFormaPagamento,
  },
  props: {
    __ficha: {},

    data: { default: () => ({}) },
    disabled: { default: false, type: Boolean },
    autoFocus: { default: false, type: Boolean },
  },
  data: () => ({
    loading: true,
    meios: [],

    activeComponent: false,
  }),
  methods: {
    onBeforeConfirm() {
      this.$refs.assistent.updateOrCreatePagamento({ pending: false });
      this.$refs.assistent.onClose();

      // precisa retornar false para encerrar o fluxo
      return false;
    },
    onInsertPayment() {
      this.activeComponent = true;
    },
    onActiveComponentClose() {
      this.activeComponent = false;
    },
  },
  created() {
    this.$store
      .dispatch(actions.CORE.READ, {
        path: "meios-pagamentos",
        type: "registros",
        orderBy: "ordem",
        ativo: true,
      })
      .then((data) => {
        this.meios = data.registros;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    pagamento() {
      return head(get(this.data, "pagamentos") ?? []);
    },

    modulo() {
      return get(this.__ficha, "modulo");
    },

    nothingToReceive() {
      return clearCurrency(this.data.vlr_total) <= 0;
    },
  },
};
</script>
