<template>
  <data-table
    editable
    hide-footer
    hide-empty-row
    :items="items"
    :headers="tabSeriesHeaders"
    :value="value"
    :disabled="__ficha.isFichaDisabled"
    @input="$emit('input', $event)"
    v-bind="$attrs"
  >
    <template #textfield="{ on, index, selected, value, item, ...rest }">
      <c-text-field
        :auto-focus="index === 0"
        :value="value"
        :white="selected"
        v-on="on"
        v-bind="rest"
        @change="onSequenceChange($event, item)"
      />
    </template>
  </data-table>
</template>

<script>
import compact from "lodash/compact";
import pick from "lodash/pick";

import DataTable from "@/components/table/DataTable.vue";
import { CTextField } from "@/components/form";

export default {
  components: { CTextField, DataTable },
  props: {
    modulo: { required: true },
    value: {},
    items: { default: () => [] },

    __ficha: {},
  },
  computed: {
    /**
     * Cabeçalho da tab de séries
     */
    tabSeriesHeaders() {
      return compact([
        this.modulo !== "nfse-series"
          ? {
              text: "Modelo",
              value: "modelo",
              noEditable: true,
            }
          : null,
        this.modulo !== "nfse-series"
          ? {
              text: "Série",
              value: "serie",
              // disabled: (item) => [1, 2].includes(parseInt(item.serie)),
              // disabled: (item) => !!item.serie,
              noEditable: true,
            }
          : null,
        { text: "Número do último documento", value: "numero" },
        { text: "Ambiente", value: "ambiente", noEditable: true },
      ]);
    },
  },
  methods: {
    /**
     * Função ativada quando uma seqêncua de série é alterada
     */
    onSequenceChange(_, item) {
      // Captura o numero
      const numero = parseInt(item.numero ?? "0");

      // Se for menor ou igual a zero ou maior que 9, retorna
      if (numero < 0 || (item.numero ?? "").toString().length > 9) return;

      // Se não tiver id retorna
      if (!item.id) return;

      // Faz o update
      this.__ficha.onChangePersistent({
        // modulo das series
        modulo: this.modulo,
        // Somente do numero
        data: pick(item, ["id", "numero"]),
      });
    },
  },
};
</script>
