import API from "@/api";
import actions from "@/store/actions";
import omit from "lodash/omit";

const api = API.config();

export default {
  actions: {
    /**
     * Função que dispara o GET para a API
     */
    [actions.CORE.READ]({}, params) {
      // Retorna a promessa da requisição
      return api
        .get(params.path, { params: omit(params, ["path"]) })
        .then((response) => response.data ?? {});
    },
    /**
     * Função que dispara o GET para a API
     */
    [actions.CORE.UPDATE]({}, params) {
      // Retorna a promessa da requisição
      return api
        .put(params.path, { ...omit(params, ["path"]) })
        .then((response) => response.data ?? {});
    },
    /**
     * Função que dispara o GET para a API
     */
    [actions.CORE.POST]({}, params) {
      // Retorna a promessa da requisição
      return api
        .post(params.path, { ...omit(params, ["path"]) })
        .then((response) => response.data ?? {});
    },
    /**
     * Função que dispara o POST de form data para a API
     */
    [actions.CORE.POST_FORM]({}, { path, data, ...params }) {
      // Retorna a promessa da requisição
      return api.post(path, data, { params });
    },
    /**
     * Função que dispara o POST para a API
     */
    [actions.CORE.POST_REQUEST]({}, params) {
      // Retorna a promessa da requisição
      return api.post(params.path, { ...omit(params, ["path"]) });
    },
    /**
     * Função que dispara o DELETE para a API
     */
    [actions.CORE.DELETE]({}, params) {
      // Retorna a promessa da requisição
      return api
        .delete(params.path, { ...omit(params, ["path"]) })
        .then((response) => response.data ?? {});
    },
  },
};
