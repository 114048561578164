<template>
  <v-col class="pb-1 pt-2 px-1" cols="auto">
    <label :for="`fileInput-${_uid}`" slot="upload-label">
      <image-uploader
        capture="environment"
        outputFormat="file"
        accept="image/*"
        class="fileInput"
        :id="`fileInput-${_uid}`"
        :autoRotate="true"
        :preview="false"
        :maxWidth="width"
        :value="value"
        :maxHeight="height"
        @input="setImage"
        v-if="!disabled"
      />

      <image-view
        ref="image"
        :filename="filename"
        :width="width"
        :height="height"
        style="
          cursor: pointer;
          background-color: transparent;
          border: rgba(0, 0, 0, 0.12) thin solid;
        "
        v-bind="$attrs"
        v-model="value"
      />
    </label>

    <template v-if="!buttonHidden">
      <v-row>
        <v-col class="mt-2">
          <btn :disabled="disabled" outlined color="error" @click="onDelete">
            Apagar
            <v-icon right>mdi-trash-can-outline</v-icon>
          </btn>
        </v-col>
      </v-row>
    </template>
  </v-col>
</template>

<script>
import Vue from "vue";
import get from "lodash/get";
import ImageUploader from "vue-image-upload-resize";

import { Btn } from "@/components/form";
import ImageView from "./ImageView";

import loadResource from "@/mixins/loadResource";

export default Vue.extend({
  extends: loadResource,
  components: {
    ImageUploader,
    ImageView,
    Btn,
  },
  props: {
    path: {},
    disabled: { default: false },

    width: { default: 100 },
    height: { default: 100 },

    name: {},
    modulo: {},
    __ficha: {},
    buttonHidden: { type: Boolean, default: false },

    filename: { type: String },
  },
  data: () => ({
    value: "",
  }),
  methods: {
    onDelete() {
      this.uncacheImage(this.filename);

      this.__ficha
        .deleteImage({
          name: this.name,
          modulo: this.modulo,
          path: this.path,
        })
        .then(() => {
          this.$refs.image.onError();
        });
    },
    setImage(image) {
      this.$refs.image.loading = true;

      const mimeType = (image && image.type) ?? "image/*";

      const reader = new FileReader();

      reader.readAsDataURL(image);
      reader.onloadend = () => {
        const dataUrl = reader.result;

        if (!dataUrl) return;

        this.value = dataUrl;

        this.__ficha
          .updateImage({
            name: this.name,
            modulo: this.modulo,
            path: this.path,
            data: image,
            mimeType,
          })
          .then((response) => {
            const { galeria, id } = get(response, "data.registro") ?? {};
            const filename = get(galeria, "principal");

            if (id && filename) {
              this.cacheImage(`/${this.modulo}/${id}/${filename}`, dataUrl);
            } else if (this.filename) {
              this.cacheImage(this.filename, dataUrl);
            }
          })
          .finally(() => {
            this.$refs.image.loading = false;
          });
      };
    },
  },
});
</script>

<style lang="scss">
.fileInput {
  display: none;
}
</style>
