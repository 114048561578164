<template>
  <v-col
    cols="12"
    class="pa-0 cursor-pointer"
    @click="(event) => $emit('click', event)"
  >
    <v-row no-gutters v-if="label">
      <v-col class="pa-0 mt-0 mb-0 text-truncate">
        <label class="text-caption font-weight-bold">
          {{ label }}
        </label>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <precision-field
        sale
        clear
        ref="textfield"
        height="50"
        class="rounded text-right"
        :persist="persist"
        :value="value"
        :class="color"
        @input="(id, item) => $emit('input', id, item)"
        @search="$emit('search', $event)"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </v-row>
  </v-col>
</template>

<script>
import PrecisionField from "@/components/form/inputs/PrecisionField.vue";

export default {
  components: { PrecisionField },
  props: {
    value: {},
    color: { type: String, default: "blue-grey lighten-3", required: false },
    label: { type: String, default: null, required: false },
    selectFilter: { type: Boolean, default: false },
    persist: { type: Boolean, default: false },
  },
  methods: {
    focus() {
      this.$refs.textfield.focus();
    },
    select() {
      this.$refs.textfield.select();
    },
    getSelectFilterRef() {
      return this.$refs.textfield;
    },
    getRef() {
      this.$refs.textfield.getRef();
    },
  },
};
</script>
