import API from "@/api";
import actions from "@/store/actions";
import mutations from "@/store/mutations";

const prefix = "/import";

const api = API.config();

const initialState = {
  loading: false,
};

export default {
  state: Object.assign({}, initialState),

  mutations: {
    [mutations.IMPORT.LOADING](state, loading) {
      state.loading = loading;
    },
  },

  actions: {
    /**
     *
     */
    async [actions.IMPORT.HANDLE]({ commit }, props) {
      // Commita loading true
      commit(mutations.IMPORT.LOADING, true);

      // Captura as informações usadas de props
      const { promise } = props;

      // Retorna a promessa da requisição
      return promise()
        .then((response) => {
          const { data } = response;
          if (data.message)
            // Commita para snackbar
            commit(mutations.APP.SNACKBAR, {
              active: true,
              text: data.message,
            });

          // Se houver registros
          if (data.registros) {
            // Commita o grid
            commit(mutations.MODULO.REGISTROS.APPEND_DATA, data.registros);
          }

          return response;
        })
        .catch((error) => {
          // Captura a resposta
          const { response } = error;

          // Captura os dados da resposta
          const { data } = response;

          // Se houver registros
          if (data.registros) {
            // Commita o grid
            commit(mutations.MODULO.REGISTROS.APPEND_DATA, data.registros);
          }

          // Propaga a exceção
          throw error;
        })
        .finally(() => {
          // Commitar loading false
          commit(mutations.IMPORT.LOADING, false);
        });
    },

    /**
     * Função que importa o XML de compra
     */
    [actions.IMPORT.XML](store, { modulo, file }) {
      const { dispatch } = store;

      // Dispara a ação das rotinas padrões de import
      return dispatch(actions.IMPORT.HANDLE, {
        // Função de execução (promise), a qual a rotina padrão deve esperar
        promise() {
          // Cria um form
          const form = new FormData();
          // Adiciona o arquivo
          form.append("xml", file);
          // adiciona o tipo do arquivo
          form.append("mimeType", file && file.type);

          // Retorna a promessa da requisição
          return api.store(store).post(`${prefix}/xml/${modulo}`, form, {
            // Headers
            headers: {
              // content type para form
              "Content-Type": "multipart/form-data",
            },
          });
        },
      });
    },

    /**
     * Função que consulta o status do serviço da SEFAZ
     */
    [actions.IMPORT.DOCUMENT](store, props) {
      const { dispatch } = store;

      // Dispara a ação das rotinas padrões da NF-e
      return dispatch(actions.IMPORT.HANDLE, {
        promise() {
          // Captura os dados da importação
          const { target, target_id, ...rest } = props;
          const path = "import-document";

          // Retorna a promessa da requisição
          return target_id
            ? api.store(store).put(`/${target}/${path}/${target_id}`, rest)
            : api.store(store).post(`/${target}/${path}`, rest);
        },
      });
    },
  },
};
