import { filter, includes } from "lodash";

/**
 * Colunas do Grid
 */
export const columns = [
  { text: "Convênio", value: "descricao" },
  { text: "Razão Social", value: "razao" },
  { text: "Telefone", value: "telefone" },
  { text: "E-mail", value: "email" },
  { text: "% Desconto", value: "per_desconto" },
];

/**
 * Ações do submenu
 */
export const subactions = function () {
  // Captura o registro selecionado
  // const disabled = !this.registro || !this.registro.id;

  return [
    // {
    //   type: "report",
    //   title: "Relatórios",
    //   disabled: true,
    //   items: [
    // {
    //   id: "convenio",
    //   title: `Convênio ${this.registro.descricao}`,
    //   disabled,
    // },
    // { id: "todos_convenios", title: "Todos os convênios" },
    // ]
    // }
  ];
};

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = filter(columns, (column) =>
  // Retorna as colunas que as incluem
  includes(["descricao", "razao", "telefone", "email"], column.value)
);

/**
 * Cache default
 */
export const cache = {
  selecionado: {
    index: 0,
    coluna: 0,
  },
  colunas: ["descricao", "razao", "telefone", "email", "per_desconto"],
  filtros: [],
  ordenar: [
    {
      coluna: "descricao",
      ordem: "asc",
    },
  ],
};
