<template>
  <image-report
    class="mx-auto"
    button-hidden
    :disabled="__ficha.isFichaDisabled"
    :__ficha="__ficha"
  />
</template>

<script>
import Vue from "vue";
import { ImageReport } from "@/components/utils";

export default Vue.extend({
  components: { ImageReport },
  props: {
    __ficha: {},
  },
});
</script>
