<template>
  <image-cropper
    modulo="emitente"
    name="logomarca_nfce"
    :filename="filename"
    :width="200"
    :height="131"
    v-bind="{ ...$attrs }"
  />
</template>

<script>
import get from "lodash/get";
import ImageCropper from "./ImageCropper.vue";

export default {
  components: {
    ImageCropper,
  },
  computed: {
    /**
     * Retorna a imagem baseada no config
     */
    filename() {
      // Captura o nome do arquivo do config
      const filename = get(this.$config, "caminho_logomarca_nfce");
      // se não houver, retorna vazio
      if (!filename) return;
      // Retorna o caminho completo do arquivo
      return `/${filename}`;
    },
  },
};
</script>
