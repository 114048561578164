<template>
  <contas v-bind="{ ...$attrs, ...$props }" />
</template>

<script>
import Ficha from "@/mixins/ficha";
import Contas from "@/components/fichas/modulos/cobrancas/Contas.vue";

export default Ficha.extend({
  components: { Contas },
});
</script>
