<template>
  <data-table
    editable
    :selectable="false"
    :headers="headers"
    :items="value.parcelas"
    v-bind="$attrs"
  >
    <template #textfield="{ on, item, type, index, header, selected, ...rest }">
      <date-time
        clear
        :white="selected"
        :ref="`text-field-${index}`"
        :disabled="!item.id"
        @change="onDatetimeChange($event, { item, header })"
        v-if="type === 'date'"
        v-on="on"
        v-bind="{ ...rest, ...(header.options || {}) }"
      />

      <precision-field
        clear
        :white="selected"
        :ref="`text-field-${index}`"
        :disabled="!item.id"
        @change="onValueChange($event, { item, header })"
        v-else
        v-on="on"
        v-bind="{ ...rest, ...(header.options || {}) }"
      />
    </template>
  </data-table>
</template>

<script>
import Vue from "vue";
import get from "lodash/get";

import DateTime from "../inputs/DateTime.vue";
import PrecisionField from "../inputs/PrecisionField.vue";
import DataTable from "../../table/DataTable.vue";

import { clearCurrency } from "@/utils";

export default Vue.extend({
  components: {
    DataTable,
    PrecisionField,
    DateTime,
  },
  props: {
    __ficha: {},

    value: { default: () => ({}) },
  },
  methods: {
    async onDatetimeChange(value, { item, header }) {
      if (!item || !item.id) return;

      return this.handleResponse(
        await this.$api.put(`/pagamentos/${this.value.id}/parcel-date`, {
          parcela_id: item.id,
          [header.value]: value,
          document: get(this.__ficha, "modulo"),
          registro_id: get(this.__ficha, "data.id"),
        })
      );
    },
    async onValueChange(value, { item }) {
      if (!item || !item.id) return;

      return this.handleResponse(
        await this.$api.put(`/pagamentos/${this.value.id}/parcel-value`, {
          parcela_id: item.id,
          vlr_parcela: clearCurrency(value),
          document: get(this.__ficha, "modulo"),
          registro_id: get(this.__ficha, "data.id"),
        })
      );
    },
    handleResponse(response) {
      const registro = get(response, "data.registro");
      const parent = get(response, "data.parent");

      registro && this.$emit("input", { ...registro, pending: false });
      parent && this.__ficha.append(parent);
    },
  },
  computed: {
    headers() {
      return [
        { text: "Número", value: "numero", noEditable: true },
        {
          text: "Vencimento",
          value: "vencimento_dt",
          type: "date",
        },
        { text: "Valor total", value: "vlr_total" },
      ];
    },
  },
});
</script>
