<template>
  <div
    v-if="$appMode === 'beta'"
    ref="version-debug-container"
    class="app-debug-container d-print-none"
    :class="[hideLayout ? 'mb-10' : 'mb-14 mb-md-0']"
  >
    <v-chip
      label
      close
      x-small
      color="success darken-2"
      class="caption"
      @click:close="close"
    >
      Versão {{ $appMode }} {{ $appVersion && `${$appVersion} - ` }}
      {{ $appLastVersionDate }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    hideLayout: { default: false },
  },
  methods: {
    close() {
      this.$refs["version-debug-container"].remove();
    },
  },
};
</script>

<style scoped>
.app-debug-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  margin-left: 4px;
}
</style>
