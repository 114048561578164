import { debounce } from "lodash";
import { get, includes } from "lodash";
import Ficha from "./ficha";

export default Ficha.extend({
  computed: {
    /**
     * Função que retorna os filtros dos vendedores na lista de clifor
     * @returns {any[]}
     */
    vendedor_filter() {
      return [
        [
          (item) =>
            item && includes(item.clifor_tipos, "Vendedor") && item.ativo,
        ],
      ];
    },
    /**
     * Função que retorna os filtros dos tecnicos na lista de clifor
     * @returns {any[]}
     */
    tecnico_filter() {
      return [
        [
          (item) =>
            item && includes(item.clifor_tipos, "Técnico") && item.ativo,
        ],
      ];
    },

    /**
     * Relação do clifor
     */
    colaborador() {
      return this.data.colaborador_id
        ? {
            modulo: "cadastro",
            id: this.data.colaborador_id,
            nome_razao_social: get(
              this.data,
              "colaborador.nome_razao_social",
              get(this.data, "colaborador_razao_social")
            ),
          }
        : null;
    },

    /**
     * Relação do clifor
     */
    clifor() {
      return this.data.clifor_id
        ? {
            modulo: "cadastro",
            id: this.data.clifor_id,
            nome_razao_social: get(
              this.data,
              "clifor.nome_razao_social",
              get(this.data, "nome_razao_social")
            ),
          }
        : null;
    },
    /**
     * Função que retorna as informações do cliente
     */
    cliente() {
      const data = this.data.clifor ?? {};
      const isCNPJ = (data.cpf_cnpj ?? "").length === 18 ? true : false;

      return [
        `${
          data.cpf_cnpj
            ? (isCNPJ ? "CNPJ " : "CPF ") + data.cpf_cnpj
            : "<CPF/CNPJ> "
        }, ${
          data.rg_ie ? (isCNPJ ? " IE " : " RG ") + data.rg_ie : " <RG/IE>"
        }`,
        `${data.telefone ? "Tel. " + data.telefone : "<Telefone>"}`,
        `${data.endereco || "<Endereço>"}, ${data.bairro || "<Bairro>"}`,
        `${data.cep || "<CEP>"}, ${data.municipio || "<Cidade>"} - ${
          data.uf || "<UF>"
        }`,
      ];
    },
  },
  methods: {
    onEditCliforClicked() {
      if (!this.data.clifor || !this.data.clifor.id) return;

      this.__ficha.initFichaAuxiliar({
        modulo: "cadastro",
        id: this.data.clifor.id,
        onData: debounce((data) => {
          this.__ficha.append({
            clifor: {
              ...this.data.clifor,
              ...data,
            },
          });
        }, 100),
      });
    },
  },
});
