<template>
  <div>
    <div>
      <span class="font-weight-bold hidden-md-and-up">Num. Orçamento.:</span>
      <span class="font-weight-bold hidden-sm-and-down">
        Número do Orçamento:
      </span>
      {{ data.numero ?? "" }}

      <template v-if="data.numero_nfce">
        <span class="font-weight-bold"> {{ $nomeCupomFiscal }}: </span>
        {{ data.numero_nfce }}
      </template>

      <template v-if="data.numero_nfe">
        <span class="font-weight-bold"> NF-e: </span>
        {{ data.numero_nfe }}
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { default: () => ({}) },
  },
};
</script>
