import includes from "lodash/includes";
import concat from "lodash/concat";
import uniqBy from "lodash/uniqBy";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import omit from "lodash/omit";

import API from "@/api";

import actions from "@/store/actions";
import mutations from "@/store/mutations";

import { persistent as initialState } from "@/store/defaultStates";

const api = API.config();

export default {
  // Estado da store
  state: Object.assign({}, initialState()),

  mutations: {
    /**
     * Função que adiciona os itens novos na lista
     */
    [mutations.MODULO.PERSISTENT.PAGINATOR](state, { page, lastPage, modulo }) {
      // Se o módulo não estiver na lista de persistentes, retorna
      if (!includes(state.modulos, modulo)) return;

      // Atribui a lista
      state.paginator[modulo] = {
        page,
        lastPage,
      };
    },

    /**
     * Função que adiciona os itens novos na lista
     */
    [mutations.MODULO.PERSISTENT.DATA](state, { data, modulo, index }) {
      // Se o módulo não estiver na lista de persistentes, retorna
      if (!includes(state.modulos, modulo)) return;

      if (index) {
        return (state.data[modulo] = {
          ...state.data[modulo],
          [index]: uniqBy(concat(state.data[modulo][index] ?? [], data), "id"),
        });
      }

      // Atribui a lista
      state.data[modulo] = uniqBy(concat(state.data[modulo], data), "id");
    },

    /**
     * Função
     */
    [mutations.MODULO.PERSISTENT.UPDATED](state, { registro, modulo }) {
      // Se o módulo não estiver na lista de persistentes, retorna
      if (!includes(state.modulos, modulo)) return;

      const index = findIndex(
        state.data[modulo],
        (item) => item.id === registro.id
      );

      if (index === -1)
        return (state.data[modulo] = concat(state.data[modulo], [registro]));

      // Faz o splice da lista
      state.data[modulo].splice(
        // Captura o índice do registro atual
        index,
        // Remove 1
        1,
        // Adiciona o registro mas mantem o atual
        { ...state.data[modulo][index], ...registro }
      );
    },

    /**
     * Função
     */
    [mutations.MODULO.PERSISTENT.DELETED](state, { id, modulo }) {
      // Se o módulo não estiver na lista de persistentes, retorna
      if (!includes(state.modulos, modulo)) return;

      const index = findIndex(state.data[modulo], (item) => item.id === id);

      if (index === -1) return;

      // Faz o splice da lista
      state.data[modulo].splice(
        // Captura o índice do registro atual
        index,
        // Remove 1
        1
      );
    },

    /**
     * Mutação que altera o status de loading dos dados
     */
    [mutations.MODULO.PERSISTENT.LOADING](state, { loading, modulo }) {
      // Loading
      // state.loading = loading;

      // Modulo
      // loading
      //   ? state.fetching.push(modulo)
      //   : state.fetching.splice(findIndex(state.fetching, modulo), 1);
      state.fetching = { ...state.fetching, [modulo]: loading };
    },
  },

  actions: {
    [actions.MODULO.PERSISTENT.SEARCH](store, params) {
      const { commit, state } = store;
      // Recupera as informações que serão usadas para a query
      const { modulo, ignoreWhenLastPage } = params;

      const path = `/${modulo}/search`;

      if (state.fetching[modulo]) return;

      const page = get(state.paginator, `${modulo}.page`, 0);
      const lastPage = get(state.paginator, `${modulo}.lastPage`, 0);

      if (ignoreWhenLastPage && page >= lastPage) return;

      // Commita o carregamento para true
      commit(mutations.MODULO.PERSISTENT.LOADING, { loading: true, modulo });

      // Retorna a promessa da requisição
      return api
        .store(store)
        .get(path, {
          params: omit(params, ["modulo", "ignoreWhenLastPage"]),
        })
        .then((response) => {
          commit(mutations.MODULO.PERSISTENT.DATA, {
            data: get(response, "data.registros", []),
            modulo,
          });

          return response.data;
        })
        .finally(() => {
          // Commita o carregamento para false
          commit(mutations.MODULO.PERSISTENT.LOADING, {
            loading: false,
            modulo,
          });
        });
    },
    /**
     * Função que faz a leitura de TODOS os registros do módulo persistente
     */
    [actions.MODULO.PERSISTENT.READ](
      { state, commit },
      { modulo, firstAttempt, ...params }
    ) {
      // Se o módulo não estiver na lista de persistentes, retorna
      if (!includes(state.modulos, modulo)) return;

      // Se o módulo já tiver valores, retorna
      // if (includes(state.fetched, modulo)) return;

      // Se o módulo já estiver carregando, retorna
      if (state.fetching[modulo]) return;

      // state.fetched.push(modulo);

      // Captura a página
      const page = get(state.paginator, `${modulo}.page`, 0) + 1;

      if (page > 1 && firstAttempt) return;

      if (params.index && state.data[modulo][params.index]) return;

      // Commita o carregamento para true
      commit(mutations.MODULO.PERSISTENT.LOADING, { loading: true, modulo });
      // Retorna a promessa da requisição
      return api
        .get(`/${modulo}/persistent`, {
          // Parâmetros
          params: {
            page,
            perPage: 1000,
            filter: params.filter ?? [],
            orderBy: params.orderBy,
          },
        })
        .then((response) => {
          // Faz o merge da lista atual com os registros que acabaram de chegar
          commit(mutations.MODULO.PERSISTENT.DATA, {
            data: response.data.registros ?? [],
            index: params.index,
            modulo,
          });

          if (!includes(["municipios"], modulo))
            commit(mutations.MODULO.PERSISTENT.PAGINATOR, {
              modulo,
              page: response.data.current_page,
              lastPage: response.data.pages,
            });

          return response.data;
        })
        .finally(() => {
          // Commita o carregamento para false
          commit(mutations.MODULO.PERSISTENT.LOADING, {
            loading: false,
            modulo,
          });
        });
    },
  },
};
