<template>
  <tabs-items v-model="value">
    <template #tab-principal>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <v-row no-gutters>
          <p-text-field
            auto-focus
            md="4"
            name="nome_usuario"
            label="Nome para Login"
            v-model="data.nome_usuario"
          />
          <p-text-field
            md="4"
            name="nome"
            label="Nome do Usuário"
            v-model="data.nome"
          />
        </v-row>

        <v-row no-gutters>
          <field-cpf-cnpj md="4" v-model="data.cpf_cnpj" />
          <btn
            text
            class="ml-2 my-auto"
            :disabled="!data.id"
            @click="modal = true"
            >Definir nova Senha</btn
          >

          <modal
            movable
            v-if="modal"
            :value="modal"
            title="Definir nova senha"
            width="30vw"
            @input="(value) => (modal = value)"
          >
            <template #text>
              <password
                auto-focus
                label="Senha"
                name="new-password"
                autocomplete="new-password"
                :score="pw.score"
                :score-color="pw.scoreColor"
                v-model="data.senha"
                @input="(event) => checkPass(event, data.confirmar_senha)"
              />
              <password
                label="Confirmar Senha"
                name="confirm-password"
                autocomplete="new-password"
                :loading="false"
                :score="pw.score"
                :score-color="pw.scoreColor"
                v-model="data.confirmar_senha"
                @input="(event) => checkPass(data.senha, event)"
              />

              <alert-info
                :message="pw.errors.join('<br>')"
                :value="pw.errors.length > 0"
                v-if="pw.errors.length"
              />
            </template>

            <template #action-content>
              <v-spacer />
              <btn
                btn-class="focus-priority"
                @click="updatePassword"
                :loading="updating"
                >Confirmar</btn
              >
            </template>
          </modal>
        </v-row>

        <container-content title="Configurações" md="8">
          <v-row>
            <p-switch
              ignore-enter-focus
              name="ativo"
              label="Ativo"
              v-model="data.ativo"
            />

            <p-switch
              cols="12"
              name="reset_pass_after_login"
              label="Nova senha no próximo login"
              v-model="data.reset_pass_after_login"
            />

            <v-col cols="12" md="3" class="pa-1">
              <btn
                outlined
                :disabled="!data.id"
                class="ml-md-auto"
                title="Redefinirá filtros ativos, colunas exibidas assim como sua ordem de exibição."
                @click="resetCache"
              >
                <v-icon left small>mdi-reload</v-icon>
                Redefinir Preferências
              </btn>
            </v-col>
          </v-row>
        </container-content>
      </v-col>
    </template>

    <template #tab-permissoes>
      <v-col lg="9" xl="8" class="mx-auto">
        <v-row no-gutters>
          <v-col md="6" class="pa-0">
            <btn
              text
              outlined
              btnClass="mr-2"
              color="error"
              @click="() => toggleAll(0)"
              >Bloquear tudo</btn
            >
            <btn @click="() => toggleAll()">Liberar tudo</btn>
          </v-col>
        </v-row>
        <v-row no-gutters class="my-2">
          <v-col cols="12" md="6" v-for="(modulo, i) in modulos" :key="i">
            <v-row no-gutters>
              <img
                cols="2"
                class="ma-0 pa-0 mt-2"
                style="width: 40px; height: 40px"
                :src="modulo.icon"
              />
              <select-filter
                value-not-null
                cols="10"
                class="ma-0 pa-0 pt-1"
                :disabled="!modulo.id"
                :label="modulo.title"
                :name="modulo.id"
                :items="options(modulo.id)"
                :value="permissoes[modulo.id] || 0"
                @change="(value) => onPermissionChanged(value, modulo.id)"
                @input="(value) => (permissoes = { modulo: modulo.id, value })"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-email>
      <email-smtp
        raw-json
        md="8"
        class="mt-n2 mb-n4"
        name="parametros_email"
        :__ficha="__ficha"
        v-model="email"
      />
    </template>
  </tabs-items>
</template>

<script>
import get from "lodash/get";

import { Modal, TabsItems, AlertInfo } from "@/components/utils";

import {
  SelectFilter,
  PTextField,
  PSwitch,
  Btn,
  EmailSmtp,
  Checkbox,
  ContainerContent,
  FieldCpfCnpj,
  Password as PasswordField,
} from "@/components/form";

import { permissions } from "@/config/modulos/usuarios";

import actions from "@/store/actions";
import mutations from "@/store/mutations";

// Mixins
import Ficha from "@/mixins/ficha";
import Password from "@/mixins/password";

export default Ficha.extend({
  mixins: [Password],
  components: {
    Btn,
    TabsItems,
    SelectFilter,
    FieldCpfCnpj,
    Modal,
    Checkbox,
    PTextField,
    PSwitch,
    ContainerContent,
    AlertInfo,
    EmailSmtp,
    password: PasswordField,
  },
  created() {
    this.__ficha.$on("read", this.onRead);
  },
  destroyed() {
    this.__ficha.$off("read", this.onRead);
  },
  data: () => ({
    email: {},
    modal: false,
    updating: false,
    // Reescreve o objeto pw (o que não foi informado usa o default do mixin)
    pw: {
      rules: ["min:6", "equals", "strong"],
    },
  }),
  computed: {
    permissoes: {
      get() {
        return this.data.permissoes;
      },
      set(permissao) {
        this.data.permissoes = {
          ...this.permissoes,
          [permissao.modulo]: permissao.value,
        };
      },
    },
    modulos() {
      return this.$store.state.app.modules.filter((modulo) => !modulo.config);
    },
  },
  methods: {
    updatePermission(permissions) {
      return this.$store
        .dispatch(actions.USERS.UPDATE_PERMISSIONS, permissions)
        .then((response) => {
          const registro = get(response, "data.registro");
          // Commita o registro no grid
          this.$store.commit(mutations.MODULO.REGISTROS.UPDATED, {
            ...registro,
          });
        });
    },

    toggleAll(permission = 1) {
      this.modulos.forEach((modulo) => {
        if (modulo.id === "config") this.permissoes.config = 1;
        else this.permissoes[modulo.id] = permission;
      });

      this.$nextTick(() => {
        this.updatePermission({
          ...this.permissoes,
          id: this.data.id,
        });
      });
    },
    onPermissionChanged(value, modulo) {
      this.updatePermission({ [modulo]: value, id: this.data.id });
    },
    updatePassword() {
      if (!this.isPasswordValid || this.updating) return;
      this.updating = true;

      this.$store
        .dispatch(actions.USERS.UPDATE_PASSWORD, {
          id: this.data.id,
          password: this.data.senha,
        })
        .then(() => {
          this.resetPasswords();
          this.modal = false;
        })
        .finally(() => {
          this.updating = false;
        });
    },
    resetPasswords() {
      this.data.senha = "";
      this.data.confirmar_senha = "";

      this.resetScore();
    },
    /**
     * Função que retorna as opções de mensagem do módulo
     */
    options() {
      return permissions;
    },
    resetCache() {
      this.$store.dispatch(actions.USERS.RESET_USER, this.data.id);
    },
    onRead(state) {
      this.email = { ...(get(state, "data.parametros_email") ?? {}) };
    },
  },
});
</script>
