<template>
  <tabs-items
    @loaded-tab-series="onDependenceTabLoaded"
    @loaded-tab-inutilizacao="onDependenceTabLoaded"
    v-model="value"
  >
    <template #tab-principal>
      <v-col lg="10" offset-lg="1">
        <v-row>
          <p-select-filter
            guard
            value-not-null
            md="4"
            modulo="sistema"
            name="nfe_ambiente_emissao"
            label="Ambiente"
            :disabled="isFichaDisabled"
            :items="environments"
            v-model="$config.nfe_ambiente_emissao"
          />
          <!-- <p-select-filter
            value-not-null
            md="4"
            modulo="sistema"
            name="nfe_versao_layout"
            label="Versão do layout da NF-e"
            :items="layouts"
            v-model="$config.nfe_versao_layout"
          /> -->
        </v-row>
        <v-row>
          <p-select-filter
            value-not-null
            md="4"
            modulo="sistema"
            name="nfe_orientacao"
            label="Orientação do DANFE"
            :items="formats"
            v-model="$config.nfe_orientacao"
          />
        </v-row>
        <v-row>
          <p-switch
            cols="4"
            modulo="sistema"
            name="nfe_danfe_simples"
            label="DANFE Simples"
            :value="$config.nfe_danfe_simples"
          />
        </v-row>
        <v-row>
          <s-switch
            cols="12"
            modulo="sistema"
            name="nfe_contingencia"
            label="(SVC) Sefaz Virtual de Contingência"
            @change="onContingencyChanged"
            :value="$config.nfe_contingencia_json.ativo"
          />
        </v-row>
        <!-- <v-row>
          <p-switch
            cols="12"
            modulo="sistema"
            name="nfe_envio_email"
            label="Enviar DANFE por e-mail automaticamente"
            v-model="$config.nfe_envio_email"
          />
        </v-row> -->
        <!-- <v-row>
          <p-switch
            cols="12"
            modulo="sistema"
            name="nfe_zipar_xml_email"
            label="Zipar XML ao enviar por e-mail"
            v-model="$config.nfe_zipar_xml_email"
          />
        </v-row> -->
        <v-row>
          <p-switch
            modulo="sistema"
            name="itens_duplos_nfe"
            label="Itens duplos na NF"
            v-model="$config.itens_duplos_nfe"
          />
        </v-row>

        <v-row>
          <s-switch
            modulo="sistema"
            label="Consultar NFs emitidas ao abrir o sistema"
            @change="(value) => onJsonBooleanChanged('dist_dfe_json', value)"
            v-model="$config.dist_dfe_json.ativo"
          />
        </v-row>

        <v-row>
          <btn text :disabled="isFichaDisabled" @click="onGenerateNSU">
            Reiniciar sequência NSU
          </btn>
        </v-row>

        <v-row>
          <p-switch
            modulo="sistema"
            name="nfe_envio_email"
            label="Envio automático de e-mail ao autorizar"
            v-model="$config.nfe_envio_email"
          />
        </v-row>

        <v-row>
          <p-text-field
            modulo="sistema"
            name="nfe_mensagem_promocional"
            label="Mensagem promocional"
            v-model="$config.nfe_mensagem_promocional"
          />
        </v-row>
      </v-col>
    </template>

    <template #tab-series>
      <v-col cols="12" lg="10" offset-lg="1">
        <v-row>
          <v-col cols="12" class="px-1" style="height: 250px">
            <document-sequences
              modulo="nfe-series"
              :items="tabSeriesValues"
              :__ficha="__ficha"
              :value="selected"
              @input="setSelected"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <p-text-field
              value-not-null
              md="6"
              type="tel"
              maxlength="3"
              modulo="sistema"
              class="mt-2"
              name="nfe_numero_serie_especial"
              label="Número de Série especial para NF-e"
              v-model="$config.nfe_numero_serie_especial"
            />
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template #tab-inutilizacao>
      <v-col lg="10" xl="8" offset-lg="1" offset-xl="2">
        <document-inutilizacao
          modelo="55"
          :data="data.inutilizacoes"
          :extra="extra"
          :__ficha="__ficha"
        />
      </v-col>
    </template>
  </tabs-items>
</template>

<script>
import {
  TextField,
  PTextField,
  Switch as SSwitch,
  PSwitch,
  TextArea,
  PSelectFilter,
  SelectFilter,
  Btn,
  ContainerContent,
} from "@/components/form";

import DataTable from "@/components/table/DataTable.vue";

import { TabsItems, ImageNfe } from "@/components/utils";
import DocumentSequences from "@/components/form/combos/DocumentSequences.vue";
import DocumentInutilizacao from "@/components/form/combos/DocumentInutilizacao.vue";

import mutations from "@/store/mutations";

// Mixins
import Ficha from "@/mixins/ficha";

export default Ficha.extend({
  components: {
    Btn,
    PSwitch,
    TextField,
    PTextField,
    TabsItems,
    DataTable,
    SSwitch,
    TextArea,
    PSelectFilter,
    SelectFilter,
    ImageNfe,
    ContainerContent,
    DocumentSequences,
    DocumentInutilizacao,
  },
  computed: {
    /**
     * Valores interpretados da tabela e arranjados para exibição
     */
    tabSeriesValues() {
      // Retorna as séries
      return this.data.series ?? [];
    },
    /**
     * Ambientes de emissão de NF-e
     */
    environments() {
      return [
        { text: "Homologação", value: 2 },
        { text: "Produção", value: 1 },
      ];
    },
    /**
     * Formatos do danfe
     */
    formats() {
      // Retorna a lista
      return [
        { text: "Retrato", value: "Retrato" },
        { text: "Paisagem", value: "Paisagem" },
      ];
    },
    /**
     * Layouts de NF-e
     */
    layouts() {
      // Retorna a lista
      return [
        { text: "2.00", value: "2.00", disabled: true },
        { text: "3.10", value: "3.10", disabled: true },
        { text: "4.00", value: "4.00" },
      ];
    },
  },
  methods: {
    onGenerateNSU() {
      this.__ficha.$alert(
        {
          value: true,
          cancelable: true,
          info: true,
          okText: "SIM",
          cancelText: "NÃO",
          title: "Reinciar sequência NSU",
          message: `Você tem certeza que deseja reiniciar a sequência NSU?<br><br>Na próxima tentativa de consulta, o sistema irá buscar as NFs emitidas nos últimos 3 meses.`,
        },
        {
          ok: () => {
            this.__ficha.onChangePersistent({
              modulo: "sistema",
              data: { "dist_dfe_json.ultima_numeracao": null },
            });

            this.$store.commit(mutations.APP.SNACKBAR, {
              active: true,
              text: "Sequência NSU reiniciada com sucesso",
            });
          },
        }
      );
    },
    onJsonBooleanChanged(key, value) {
      this.__ficha.onChangePersistent({
        modulo: "sistema",
        data: {
          [key]: JSON.stringify({
            ativo: value,
          }),
        },
      });
    },
    /**
     * Função que gerencia a contingencia
     */
    onContingencyChanged(value) {
      return value
        ? this.$store.commit(mutations.APP.ASSISTENT, {
            modulo: "general",
            action: "contingency",
            registro: {
              ...this.$config.nfe_contingencia_json,
              document: "nfe",
            },
          })
        : this.__ficha.onChangePersistent({
            modulo: "sistema",
            data: {
              nfe_contingencia_json: JSON.stringify({
                justificativa: "",
                inicio: "",
                ativo: false,
              }),
            },
          });
    },
    /**
     * Função que faz a leitura das dependências
     */
    onDependenceTabLoaded(dependence) {
      if (dependence === "tab-series") {
        this.setSelected({ coluna: 2 });
      }

      this.__ficha.readDependence({
        dependence,
      });
    },
  },
});
</script>
