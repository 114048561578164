import { milliseconds } from "@/plugins/moment";
import { equals, ilike, notEqual } from "@/utils/filters";
import compact from "lodash/compact";
import filter from "lodash/filter";
import includes from "lodash/includes";
import get from "lodash/get";
import find from "lodash/find";
import getters from "@/store/getters";

export const addToRightSideColumn = (modulo: string) => ({
  text: "...",
  value: "check_item",
  type: "icon",
  action: "add_to_right_side",
  position: 0,
  // permanent: true,
  noResize: true,
  unsortable: true,
  selectable: false,
  hiddenReport: true,
  icon(this: any, item: any) {
    const cache = this.$store.getters[getters.APP.USER.CACHE](modulo);
    const selected = get(cache, "parcels", []);

    // Se houver quitação, retorna icone de quitado
    if (item.is_pago) {
      return "mdi-currency-usd";
    }

    if (item.is_boleto_emitido) {
      return "mdi-card-text-outline";
    }

    if (find(selected, (it) => it.id === item.id)) {
      return "mdi-checkbox-marked";
    }

    if ((item.cobranca_tipo || "").includes("cartao")) {
      return "mdi-credit-card";
    }

    // Se encontrar o item na lista marca o checkbox, caso contrário, desmarca
    return "mdi-checkbox-blank-outline";
  },
});

export const exibir = function (this: any, modulo: string) {
  const isPagar = modulo === "contas-pagar";

  const pending = `A ${isPagar ? "Pagar" : "Receber"}`;
  const paid = `Já ${isPagar ? "Pagas" : "Recebidas"}`;

  return compact([
    {
      title: "Todas",
      filter: null,
    },
    {
      title: pending,
      filter: {
        ...notEqual("status", ["paga"]),
        customTitle: `Contas ${pending}`,
        label: `<strong>${pending}</strong>`,
      },
    },
    {
      title: paid,
      filter: {
        ...equals("status", ["paga"]),
        customTitle: `Contas ${paid}`,
        label: `<strong>${paid}</strong>`,
      },
    },
    {
      title: "Atrasadas",
      filter: {
        ...equals("status", ["vencida"]),
        customTitle: "Contas Atrasadas",
        label: "<strong>Atrasadas</strong>",
      },
    },
    {
      title: "A Vencer",
      filter: {
        ...equals("status", ["a_vencer"]),
        customTitle: "Contas à Vencer",
        label: "<strong>À Vencer</strong>",
      },
    },
    {
      title: "Vencendo Hoje",
      divider: !isPagar,
      filter: {
        ...equals("status", ["vence_hoje"]),
        customTitle: "Contas Vencendo Hoje",
        label: "<strong>Vencendo Hoje</strong>",
      },
    },
    isPagar
      ? null
      : {
          title: "Boletos",
          filter: {
            ...ilike("cobranca_tipo", ["boleto"]),
            customTitle: "Boletos",
            label: "<strong>Boletos</strong>",
          },
        },
    isPagar
      ? null
      : {
          title: "Cartão",
          filter: {
            ...ilike("cobranca_tipo", ["cartao"]),
            customTitle: "Cartão",
            label: "<strong>Contas em Cartão</strong>",
          },
        },
  ]);
};

export const subactions = function (this: any) {
  return [
    {
      title: "Layout",
      type: "layout",
      items: [
        {
          id: "rightSide",
          type: "boolean",
          title: "Assistente Lateral de Cobranças",
          active: get(this.__modulo.currentCache, "layout.rightSide") !== false,
        },
      ],
    },
  ];
};

/**
 * Ações de contexto da tabela
 */
export const context = function (this: any) {
  return [
    {
      id: "spread",
      title: `Desdobrar ${
        this.registro?.documento ||
        this.registro?.descricao ||
        "conta selecionada"
      }`,
      icon: "mdi-format-page-split",
    },
  ];
};

/**
 * Ficha default
 */
export const ficha = () => ({
  ativo: true,
  emissao_dt: milliseconds(),
});

/**
 * Dependências do módulo
 */
export const dependencies = [
  {
    name: "parcels",
  },
];

export const forceUpdateKeys = [
  "vlr_total",
  "vlr_pago",
  "vlr_juros",
  "vlr_atual",
];

/**
 * Colunas que serão exibidas no modal de delete
 */
export const deletes = (columns) =>
  filter(columns, (column) =>
    // Retorna as colunas que as incluem
    includes(
      [
        "documento",
        "descricao",
        "emissao_dt",
        "clifor_razao_social",
        "plano_conta_descricao",
        "vlr_total",
        "vlr_atual",
        "vlr_pago",
      ],
      column.value
    )
  );

/**
 * Cache default
 */
export const cache = {
  selecionado: {
    coluna: 1,
  },
  colunas: [
    "check_item",
    "documento",
    "numero",
    "plano_conta_descricao",
    "clifor_razao_social",
    "emissao_dt",
    "vencimento_dt",
    "pagamento_dt",
    "vlr_total",
    "vlr_pago",
  ],
  ordenar: [
    // { coluna: "documento", ordem: "desc" }
  ],
};
