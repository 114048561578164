export const tabs = [
  {
    title: "Imagens",
    item: "tab-imagens",
  },
  {
    title: "Certificado digital",
    item: "tab-certificado",
  },
  {
    title: "Geral",
    item: "tab-geral",
  },
  // {
  //   title: "Juros",
  //   item: "tab-juros"
  // },
  // {
  //   title: "Prazo",
  //   item: "tab-prazo",
  // },
  // {
  //   title: "Ajustes",
  //   item: "tab-ajustes",
  // },
  // {
  //   title: "IR",
  //   item: "tab-ir",
  // },
  // {
  //   title: "Atendimento",
  //   item: "tab-atendimento",
  // },
  {
    title: "E-mail",
    item: "tab-email",
  },
  {
    title: "Chaves de Acesso",
    item: "tab-chaves-acesso",
  },
  {
    title: "Orçamento",
    item: "tab-orcamento",
  },
  {
    title: "Ordem de Serviço",
    item: "tab-ordem-servico",
    hidden(this: any) {
      return !this.$emitente.modulo_ordem_servico;
    },
  },
];

/**
 * Chaves para forçar atualização na tela da ficha, quando updade
 */
export const forceUpdateKeys = [];

/**
 * Dependências do módulo
 */
export const dependencies = [
  {
    tab: "tab-chaves-acesso",
    name: "chaves_acesso",
    modulo: "chaves-acesso",
  },
];
