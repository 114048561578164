import pick from "lodash/pick";
import findIndex from "lodash/findIndex";
import concat from "lodash/concat";
import uniqBy from "lodash/uniqBy";
import get from "lodash/get";

import API from "@/api";

import actions from "@/store/actions";
import getters from "@/store/getters";
import mutations from "@/store/mutations";

import { registros as initialState } from "@/store/defaultStates";

const api = API.config({
  state: "fetching",
  mutation: mutations.MODULO.REGISTROS.FETCHING,
});

export default {
  state: Object.assign({}, initialState()),

  getters: {
    /**
     * Função que retorna os registros da tabela já filtrados com base no cache
     */
    [getters.MODULO.REGISTROS.DATA](state) {
      return state.data;
    },

    /**
     * Função que retorna a quantidade de registros que estão no Grid
     */
    [getters.MODULO.REGISTROS.COUNT](_, _getters) {
      // Captura os dados do Grid, já ordenados
      const data = _getters[getters.MODULO.REGISTROS.DATA] ?? [];
      // retorna o tamanho da lista de registros
      return data.length ?? 0;
    },
  },

  mutations: {
    /**
     * Mutação que gerencia quando um registro foi alterado, e atualiza ele na tabela
     */
    [mutations.MODULO.REGISTROS.UPDATED]: (state, data) => {
      // Procura o index do registro atual
      const index = findIndex(
        state.data,
        (registro) => registro.id === data.id
      );

      // Se o index for inválido, retorna
      if (index === -1) return;

      // Captura os dados antigos
      const oldData = state.data[index];

      // Substitui os dados antigos pelos novos.
      state.data.splice(index, 1, {
        ...oldData,
        ...data,
      });
    },

    /**
     * Mutação que remove um registro da tabela
     */
    [mutations.MODULO.REGISTROS.DELETED](state, id) {
      // Procura o index do registro que foi apagado
      const index = findIndex(state.data, (registro) => registro.id === id);

      // Se o index for inválido, retorna
      if (index === -1) return;

      // Remove o registro da tabela
      state.data.splice(index, 1);
    },

    /**
     * Mutação que insere um novo registro na tabela
     */
    [mutations.MODULO.REGISTROS.CREATED](state, data) {
      // Se o novo registro for inválido, retorna
      if (!data || !data.id) return;

      const index = findIndex(
        state.data,
        (registro) => registro.id === data.id
      );

      if (index >= 0) {
        // Captura os dados antigos
        const oldData = state.data[index];

        // Substitui os dados antigos pelos novos.
        return state.data.splice(index, 1, {
          ...oldData,
          ...data,
        });
      }

      // Adiciona à lista o novo registro criado.
      state.data.unshift(data);
    },

    /**
     * Função que reseta as informações dos registros
     * @param {Object} state Estado da store
     */
    [mutations.MODULO.REGISTROS.CLEAR_DATA](state) {
      state.fetching = false;
      state.data = [];
      state.extra = {};
      state.total = 0;
      state.currentPage = 1;
      state.pages = -1;
      state.perPage = 0;
    },

    [mutations.MODULO.REGISTROS.DATA](state, data) {
      state.data = uniqBy(data, "id");
    },

    [mutations.MODULO.REGISTROS.APPEND_DATA](state, data) {
      // state.data = concat(state.data, data);
      state.data = uniqBy(concat(state.data, data), "id");
    },

    [mutations.MODULO.REGISTROS.APPEND_DATA_DIRECTION](
      state,
      { data, direction }
    ) {
      // state.data = concat(state.data, data);
      state.data = uniqBy(
        direction === -1 ? concat(data, state.data) : concat(state.data, data),
        "id"
      );
    },

    [mutations.MODULO.REGISTROS.UNSHIFT_DATA](state, data) {
      const newData = [...state.data];
      newData.unshift(...data);

      state.data = uniqBy(newData, "id");
    },

    /**
     * Função que commita o carregamento do módulo
     */
    [mutations.MODULO.REGISTROS.FETCHING](state, fetching) {
      state.fetching = fetching;
    },

    /**
     * Função que seta as informações dos registros
     */
    [mutations.MODULO.REGISTROS.LOADED](state, data = {}) {
      state.total = data.total;
      state.currentPage = data.current_page;
      state.pages = data.pages;
      state.perPage = data.per_page;
      state.loadedPages.push(
        data.current_page,
        ...get(data, "extra_pages", [])
      );
    },
  },

  actions: {
    /**
     * Função que dispara o GET para a API e recupera os registros do módulo atual.
     */
    async [actions.MODULO.REGISTROS.READ](store, props) {
      const { state, commit, getters: _getters, rootState } = store;

      const { direction, nextPage, force, oldModulo } = props;
      let { page, query } = props;

      if (state.pages === 0 && !force) return;

      const currentCount = _getters[getters.MODULO.REGISTROS.COUNT];

      if (
        currentCount > 0 &&
        state.pages <= state.loadedPages.length &&
        !force
      ) {
        return;
      }

      // Direção (scroll abaixo ou scroll acima)
      // Página atual (registro selecionado)

      if (nextPage) {
        page =
          direction === -1
            ? Math.min(...state.loadedPages)
            : Math.max(...state.loadedPages);

        page += direction;
      }

      if (page <= 0 || page > state.pages) {
        return;
      }

      if (state.loadedPages.includes(page) && !force) {
        return;
      }

      const modulo = rootState.modulo.current;

      let cache = _getters[getters.APP.USER.CACHE](modulo, true);

      const oldModuloCache = _getters[getters.APP.USER.CACHE](oldModulo, true);

      if (oldModulo && oldModuloCache) {
        cache = { [modulo]: cache, [oldModulo]: oldModuloCache };
      }

      query = { ...query, ...get(cache, "query", {}) };

      const response = await api.store(store).get(`/${modulo}`, {
        params: {
          query,
          page,
          cache: window.btoa(
            (JSON.stringify(cache) || "").replace(
              /[\u007F-\uFFFF]/g,
              (chr) =>
                "\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4)
            )
          ),
        },
      });

      const { data } = response;

      // Se a resposta não tiver "data", retorna
      if (!data) return;

      // Commita os registros
      if (data.registros) {
        !force
          ? commit(mutations.MODULO.REGISTROS.APPEND_DATA_DIRECTION, {
              data: data.registros,
              direction,
            })
          : commit(mutations.MODULO.REGISTROS.DATA, data.registros);
      }

      // Se houver dados extras do assistente
      if (data.extra) {
        state.extra = { ...state.extra, ...data.extra };
      }

      if (force) {
        state.loadedPages = [];
      }

      // Commita dados extras da paginação e informações dos registros
      commit(
        mutations.MODULO.REGISTROS.LOADED,
        pick(data, [
          "extra_pages",
          "current_page",
          "total",
          "per_page",
          "pages",
        ])
      );
    },

    /**
     * Função que dispara o GET para a API e recupera os registros do módulo atual.
     */
    [actions.MODULO.REGISTROS.LAZY_READ](store, props) {
      // Captura o commit da store
      const { commit } = store;

      // Captura os dados das props
      const { modulo, id } = props;

      return api
        .store(null)
        .get(`/${modulo}/lazy/${id}`)
        .then((response) => {
          // Captura os dados da resposta
          const { data } = response;

          // Se houver registro
          if (data && data.registro)
            // Adiciona no grid
            commit(mutations.MODULO.REGISTROS.UPDATED, data.registro);

          // Retorna
          return get(data, "registro", {});
        });
    },

    /**
     * Função que duplica um registro
     */
    [actions.MODULO.REGISTROS.REPLICATE](store, props) {
      // Captura o commit da store
      const { commit } = store;

      // Captura os dados das props
      const { modulo, id } = props;

      // Retorna a promessa da requisição
      return api.post(`/${modulo}/replicate`, { id }).then((response) => {
        // Captura os dados da resposta
        const { data } = response;

        // Se houver registro
        if (data && data.registro) {
          // Adiciona no grid
          commit(mutations.MODULO.REGISTROS.CREATED, data.registro);
        }

        commit(mutations.APP.SNACKBAR, {
          active: true,
          text: "Registro replicado com sucesso",
          color: "success",
        });

        // Propaga a resposta
        return response;
      });
    },

    /**
     * Função que replica as informações para todos os registros filtrados
     */
    [actions.MODULO.REGISTROS.REPLACE_FILTERED_DATA](_, props) {
      // Captura os dados das props
      const { modulo, data } = props;

      // Retorna a promessa da requisição
      return api
        .post(`/${modulo}/replace-filtered-data`, { data })
        .then((response) => {
          // Propaga a resposta
          return response;
        });
    },

    /**
     * Função que deleta o registro (id) do modulo atual
     */
    [actions.MODULO.REGISTROS.DELETE](store, { id, modulo, justificativa }) {
      const { rootState, commit } = store;

      // Captura o modulo atual
      modulo = modulo ?? rootState.modulo.current;

      // Retorna a resposta da requisição de delete
      // .store(store)
      return api
        .delete(`/${modulo}/${id}`, {
          params: {
            justificativa,
          },
        })
        .then(() => {
          // Dispara a função que exibe a snackbar
          commit(mutations.APP.SNACKBAR, {
            active: true,
            text: "Registro apagado com sucesso",
            color: "success",
          });

          // se for deletado o registro do módulo aberto
          if (modulo === rootState.modulo.current) {
            // Remove o registro deletado da tabela
            commit(mutations.MODULO.REGISTROS.DELETED, id);
          }

          // Commita o registro persistente
          commit(mutations.MODULO.PERSISTENT.DELETED, { modulo, id });
        });
    },

    /**
     * Função que faz a busca de registros para o grid, com base na coluna e valor informados
     */
    [actions.MODULO.REGISTROS.SEARCH](store, { modulo, target, value }) {
      const { rootState, commit } = store;

      // se não houver mais páginas, retorna
      // if (state.currentPage >= state.pages) return;

      // Captura o modulo atual
      modulo = modulo ?? rootState.modulo.current;

      // const source = _getters[getters.MODULO.IS_CONFIG](modulo)
      //   ? `/config/${modulo}/search`
      //   : `/${modulo}/search`;
      const source = `/${modulo}/search`;

      const api = API.config({
        state: "fetching",
        mutation: mutations.MODULO.REGISTROS.FETCHING,
        delay: 200,
      });

      return api
        .store(store)
        .get(source, {
          params: { value, target, columns: "all" },
        })
        .then((response) => {
          commit(
            mutations.MODULO.REGISTROS.UNSHIFT_DATA,
            get(response, "data.registros", [])
          );
        })
        .catch(() => {
          return;
        });
    },
  },
};
