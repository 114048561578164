<template>
  <v-col
    class="pa-0"
    style="cursor: initial !important"
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
  >
    <p-text-field
      ignore-enter-focus
      :maxlength="maxlength"
      :label="label_"
      :name="name"
      :value="value"
      @input="$emit('input', $event)"
      v-bind="$attrs"
    >
      <template #append>
        <btn icon tabindex="-1" :tooltip="`Editar ${label_}`" @click="onEditId">
          <v-icon small>mdi-pencil</v-icon>
        </btn>
      </template>
    </p-text-field>

    <modal
      :title="`Alterar ${label_}`"
      width="50vw"
      v-model="dialog"
      v-if="dialog"
      @ok="onChange"
    >
      <template #text>
        <text-field
          auto-focus
          maxlength="15"
          @enter="onChange"
          v-model="current"
        />
      </template>
    </modal>
  </v-col>
</template>

<script>
import { mapActions } from "vuex";

import Modal from "../../utils/Modal.vue";

import { TextField, PTextField, Btn } from "@/components/form";

import actions from "@/store/actions";
import { capitalize, toString } from "lodash";

export default {
  props: {
    name: { required: true },
    value: {},
    label: {},
    parametro: { required: true },
    maxlength: { default: "30" },

    // Cols
    cols: { default: "12" },
    pl: { type: Boolean, default: false },
    sm: { required: false },
    md: { required: false },
    lg: { required: false },
    xl: { required: false },
  },
  components: {
    Modal,
    Btn,
    TextField,
    PTextField,
  },
  data: () => ({
    dialog: false,

    current: "",
  }),
  methods: {
    ...mapActions({
      onDataChanged: actions.MODULO.FICHA.UPDATE_REGISTRO,
    }),
    onEditId() {
      this.current = this.label_;

      this.$nextTick(() => {
        this.dialog = !this.dialog;
      });
    },
    onChange() {
      const name = this.current || capitalize(this.name);

      this.onDataChanged({
        data: { [this.parametro]: name },
        modulo: "sistema",
      });

      this.$nextTick(() => {
        this.dialog = false;
      });
    },
  },
  computed: {
    identificador() {
      return toString(this.$config[this.parametro]);
    },
    label_() {
      return this.identificador || capitalize(this.name);
    },
  },
};
</script>
