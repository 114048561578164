<template>
  <modal
    dark
    width="90vw"
    height="90vh"
    v-model="modal"
    title="Visualizar XML"
    @click:outside="$emit('close', $event)"
    @ok="$emit('close', $event)"
    @close="$emit('close', $event)"
  >
    <template #text>
      <div class="d-flex h-100" v-if="loading">
        <v-progress-circular class="white--text ma-auto" indeterminate />
      </div>
      <ul style="list-style-type: none" v-else>
        <template v-if="jsonData">
          <tree-item :value="annotation" />
          <tree-item v-model="jsonData" />
        </template>
        <tree-item :value="annotation" v-else />
      </ul>
    </template>

    <template #action-content>
      <v-spacer></v-spacer>
      <btn text @click="downloadXml">Baixar</btn>
      <btn text @click="checkXml">Validar</btn>
      <btn text @click="copyXmlToClipBoard">Copiar</btn>
      <btn outlined color="primary" @click="$emit('close', $event)">OK</btn>
    </template>
  </modal>
</template>

<script>
import { get, head } from "lodash";
import { mapActions, mapMutations } from "vuex";

import copy from "copy-to-clipboard";

import TreeItem from "./TreeItem.vue";
import Modal from "@/components/utils/Modal.vue";

import { Btn } from "@/components/form";

import mutations from "@/store/mutations";
import actions from "@/store/actions";

export default {
  components: {
    Btn,
    TreeItem,
    Modal,
  },
  props: {
    xml: { required: true },
    modulo: { required: true },
    registro: { required: true },
  },
  data: () => ({
    jsonData: {},
    data: "",
    chNFe: "",
    loading: true,
    modal: true,
  }),
  computed: {
    /**
     * Anotação do XML
     */
    annotation() {
      // Retorna o objeto
      return {
        // Tag xml
        name: "xml",
        // Anotação
        annotation: true,
        // Atributos da anotação
        attrs: [
          { name: "version", text: "1.0" },
          { name: "encoding", text: "utf-8" },
        ],
      };
    },
  },
  /**
   * Vue Created Hook
   */
  async created() {
    // Tenta
    try {
      // Captura o xml do registro atual
      const response = await this.read({
        // Nome da coluna xml
        xml: this.xml,
        // Registro
        id: this.registro,
        // Módulo
        modulo: this.modulo,
      });

      this.data = get(response, "xml");
      this.chNFe = get(response, "ch_nfe");

      //  Inicializa o parser de xml
      const parser = new DOMParser();
      // Faz o parse
      const xml = parser.parseFromString(this.data, "text/xml");

      // Se houver erro
      if (xml.getElementsByTagName("parsererror").length)
        // Retorna a anotação default
        return (this.loading = false), (this.jsonData = null);

      // Captura o primeiro filho
      const rootNode = head(xml.children);

      // Define os dados de inicio do xml
      this.jsonData = {
        // Primeira tag
        name: rootNode.tagName,
        // Faz o build recursivo das tags do xml
        children: this.xmlToJson(rootNode.children),
        // Define como aberto
        open: true,
      };

      // Termina o loading
      return (this.loading = false);
    } catch (e) {
      // Debuga o erro
      this.$debug(e);
      // Mostra a anotação default
      this.jsonData = null;
    }
  },
  methods: {
    ...mapMutations({
      snackbar: mutations.APP.SNACKBAR,
    }),
    ...mapActions({
      read: actions.UTILS.READ_XML,
    }),
    downloadXml() {
      const encoder = new TextEncoder();

      const blob = new Blob([encoder.encode(this.data)], {
        type: "application/xml",
      });

      const url = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.setAttribute("href", url);

      anchor.setAttribute("download", this.chNFe || new Date().getTime());
      document.body.appendChild(anchor);

      anchor.click();
      anchor.parentNode.removeChild(anchor);
    },
    /**
     * Função que copia o xml para a área de transferência
     */
    copyXmlToClipBoard() {
      // Retorna a promessa de cópia do XML
      copy(this.data) &&
        // Ativa o snackbar de sucesso
        this.snackbar({
          text: "XML copiado para a área de transferência",
          active: true,
        });
      // );
    },
    /**
     * Função que copia o XML para a área de transferência e abre o site para validar XML
     */
    checkXml() {
      // Copia o XML
      copy(this.data) &&
        // Abre o site em uma nova janela
        window.open(this.$config.nfe_sefaz_validar_xml, "_blank");
    },
    /**
     * Função que transforma o XML em um JSON
     */
    xmlToJson(children) {
      // Se children não for um HTMLCollection, return
      if (!(children instanceof HTMLCollection)) return;
      // Inicia o JSON vazio
      const data = [];

      // Para cada filho dos filhos
      for (const child of children || children.children) {
        // Inicia com os atributos vazio
        const attrs = [];

        // Para cada atributo do item
        for (const attr of child.attributes || []) {
          // Adiciona o atributo na lista de atributos, mas somente name e text
          attrs.push({ name: attr.name, text: attr.textContent });
        }

        // Define se o item estará ou não aberto
        // const open = [
        //   "protNFe",
        //   "infProt",
        //   "retEnvEvento",
        //   "retEvento",
        //   "infEvento",
        // ].includes(child.tagName)
        //   ? true
        //   : undefined;

        const open = true;

        // Se o filho, tiver filhos
        if (child.children.length > 0) {
          // Insere o filho, de uma forma recursiva, adicionando os filhos desse filho
          data.push({
            // Se a tag estará aberta ou não
            open,
            // Atributos da Tag
            attrs,
            // Nome da tag
            name: child.tagName,
            // Filhos da tag
            children: [
              // Filhos do item
              ...(data.children ?? []),
              // Juntos com os filhos desse filho
              ...this.xmlToJson(child.children),
            ],
          });
        }

        // Caso contrário, apenas insere o item
        else {
          data.push({
            // Atributos da tag
            attrs,
            // Nome da tag
            name: child.tagName,
            // Conteúdo da tag
            value: child.textContent,
          });
        }
      }

      // Retorna o JSON do xml
      return data;
    },
  },
};
</script>
