import keys from "lodash/keys";
import omit from "lodash/omit";
import each from "lodash/each";
import find from "lodash/find";
import get from "lodash/get";
import isObject from "lodash/isObject";

import API from "@/api";

import { ficha as initialState } from "@/store/defaultStates";

import actions from "@/store/actions";
import getters from "@/store/getters";
import mutations from "@/store/mutations";

const api = API.config({
  // delay: 1500,
  delay: process.env.VUE_APP_MODE === "production" ? 800 : 1500,
  state: "fetching",
  mutation: mutations.MODULO.FICHA.FETCHING,
});

export default {
  state: Object.assign({}, initialState()),

  mutations: {
    /**
     * Função que adiciona um registro à uma dependencia especifica
     */
    [mutations.MODULO.FICHA.FETCHING](state, fetching) {
      state.fetching = fetching;
    },
  },

  actions: {
    /**
     * Função que faz a pesquisa no banco de dados baseado no input do select
     */
    [actions.MODULO.FICHA.SEARCH](store, params) {
      // Recupera as informações que serão usadas para a query
      let { source } = params;

      source = `/${source}/search`;

      // Retorna a promessa da requisição
      return api.store(store).get(source, {
        params: omit(params, "source"),
      });
    },

    /**
     * Função que faz a leitura do registro e executa a mutation no state
     */
    [actions.MODULO.FICHA.READ](store, options) {
      const { commit, getters: _getters } = store;

      const { id, modulo, config, ...params } = options;

      // Se não houver módulo
      if (!modulo) {
        throw new Error(
          "Tentando Ler os dados de uma Ficha sem informar o módulo"
        );
      }

      // Ajusta o id da ficha
      const path = config ? "" : `/${id ?? null}`;

      // Captura o cache do módulo
      const cache = _getters[getters.APP.USER.CACHE](modulo, true);
      // captura a query do cache
      const query = get(cache, "query", {});

      return api
        .store(store)
        .get(`/${modulo}${path}`, { query, params })
        .then((response) => {
          const modules = get(response, "data.modules");

          // se houver módulos atualizados na resposta, commita
          if (modules) commit(mutations.APP.MODULES, modules);

          return response;
        });
    },

    /**
     * Função que envia a requisição de criação de um registro
     */
    [actions.MODULO.FICHA.CREATE_REGISTRO](store, event) {
      const { commit } = store;

      // Desestrutura o evneto
      const { modulo } = event;

      // se não houver módulo
      if (!modulo) {
        throw new Error(
          "Não é possível criar o registro sem as informações necessárias"
        );
      }

      // Retorna a chamada da função post, que tenta criar um novo registro e retorna uma Promise
      return api
        .store(store)
        .post(`/${modulo}`, { data: event.data })
        .then((response) => {
          // Recupera o registro criado
          const registro = get(response, "data.registro");

          // Se não houver registro
          if (!registro) return;

          // Commita o registro persistente
          commit(mutations.MODULO.PERSISTENT.DATA, {
            // Registro
            data: [registro],
            // Módulo
            modulo,
          });

          // Propaga a resposta
          return response;
        });
    },

    /**
     * Função que faz o update de um campo alterado
     */
    [actions.MODULO.FICHA.UPDATE_REGISTRO](store, event) {
      // Desestrutura
      const { commit, rootState, getters: _getters } = store;

      // Desestrutura o evento
      const { modulo, originalId } = event;
      let { id } = event;

      // se for sistema/emitente
      if (modulo === "sistema" || modulo === "emitente") {
        // Captura o id do emitente
        id = get(rootState, "app.user.emitente_id");
      }

      // se não houver id ou módulo
      if (!id || !modulo) {
        throw new Error(
          "Não é possível alterar o registro sem as informações necessárias"
        );
      }

      // Verifica se o módulo é do config
      const isConfig = _getters[getters.MODULO.IS_CONFIG](modulo);

      // Substitui o Id basedo no isConfig
      const path = isConfig ? "" : `/${id}`;

      // Captura as opções
      const options = event.options;

      // Retorna a promessa da requisição
      return api
        .store(store)
        .put(`/${modulo}${path}`, { data: event.data })
        .then((response) => {
          // Desestrutura os dados da resposta
          const { data } = response;

          // Caso não houver data, retorna
          if (!data) return response;

          // Captura o data da requisição
          const { registro, config, modules } = data;

          // se houver config na resposta, commita
          if (config) commit(mutations.APP.CONFIG, config);

          // se houver módulos atualizados na resposta, commita
          if (modules) commit(mutations.APP.MODULES, modules);

          // Caso não houver registro, retorna
          if (!registro || (options && options.ignoreAfterUpdate))
            return response;

          // Commita a mutation de registro atualizado no grid
          commit(
            // Mutação
            mutations.MODULO.REGISTROS.UPDATED,
            // Registro atualizado
            event.relation ? { ...registro, id: originalId } : registro
          );

          // Commita o registro persistente
          commit(mutations.MODULO.PERSISTENT.UPDATED, { registro, modulo });

          // Propaga a resposta
          return response;
        });
    },

    /**
     * Função que faz a leitura da dependência
     */
    [actions.MODULO.FICHA.DEPENDENCIES.READ](store, params) {
      // Retorna uma nova promessa
      return new Promise((resolve, reject) => {
        // Desestrutura
        const { dispatch, getters: _getters } = store;

        // Captura os parâmetros
        const { modulo, value } = params;
        let { dependence } = params;

        // se não houver módulo
        if (!modulo || !value) {
          return reject(
            "Não é possível ler a dependência sem as informações necessárias"
          );
        }

        // Tenta
        try {
          // monta o path com base no config
          const path = _getters[getters.MODULO.IS_CONFIG](modulo)
            ? `config/${modulo}`
            : modulo;

          // Importar as dependencias
          const { dependencies } = require(`@/config/modulos/${path}`);

          // Se houver dependencias
          if (dependencies) {
            // Procura a dependência em questão
            dependence = find(
              // Na lista de dependencias
              dependencies,
              (dep) =>
                isObject(dependence)
                  ? dependence.name === dep.name
                  : dep.tab === dependence
            );

            // Se houver dependência
            if (dependence) {
              // Dispara a ação de procurar
              return dispatch(actions.MODULO.FICHA.SEARCH, {
                value,
                target: dependence.target,
                source: dependence.modulo,
              })
                .then((response) => {
                  return resolve({ response, dependence });
                })
                .catch(reject);
            }

            return reject();
          }
        } catch (e) {
          window.debug(e);
          // Caso haja erros, retorna
          return reject(e);
        }
      });
    },

    /**
     * Funçao utilizada para upload de arquivos
     */
    [actions.MODULO.FICHA.DELETE_FILE](store, props) {
      // Captura os valores da props
      let { path } = props;
      const { modulo, id } = props;

      if (!modulo || !id) {
        throw new Error(
          "Tentando atualizar imagem sem informar o módulo ou registro"
        );
      }

      path = path ?? `/${modulo}/file/${id}`;

      // REtorna a promessa da requisição
      return api.delete(path);
    },

    /**
     * Funçao utilizada para upload de arquivos
     */
    [actions.MODULO.FICHA.UPDATE_FILE](store, props) {
      // Captura os valores da props
      let { path } = props;
      const { modulo, id } = props;

      if (!modulo || !id) {
        throw new Error(
          "Tentando atualizar imagem sem informar o módulo ou registro"
        );
      }

      const { name, data, mimeType, ...extra } = props;

      // Incializa um novo FormData
      const form = new FormData();

      // Adiciona o nome do arquivo com o arquivo
      form.append(name, data);

      // Adiciona o mimeType do arquivo
      form.append("mimeType", mimeType);

      // Para cada índice extra
      each(keys(extra), (key) => {
        // Se o valor existir
        if (extra[key]) {
          // Adiciona ao form
          form.append(key, extra[key]);
        }
      });

      path = path ?? `/${modulo}/file/${id}`;

      // Retorna a promessa da requisição
      return api.store(store).post(path, form, {
        headers: {
          // Cabeçalho de form-data
          "Content-Type": "multipart/form-data",
        },
      });
    },

    /**
     * Função que gerencia as dependendências
     *
     * A API irá esperar objetos nos seguintes formatos:
     * caso de UPDATE de informação do item (ex: alterou 'qtd_produto para 5,00' no produto de algum documento) {
     *  type: 'update',
     *  id: dependence_id,
     *  modulo: dependence_modulo,
     *  data: Object (as informações do item novo)
     * }
     *
     * caso de CREATE do item (ex: adicionou um produto no documento ) {
     *  type: 'create',
     *  modulo: dependence_modulo,
     *  data: Object (as informações do item que será inserido (ex: o produto), com junção do id do registro atual (nesse exemplo, venda_id))
     * }
     *
     * caso de DELETE do item (ex: removeu um produto do documento) {
     *  type: 'delete',
     *  dependence: String,
     *  modulo: String,
     *  id: Number,
     *  data: Object
     * }
     */
    [actions.MODULO.FICHA.HANDLE_DEPENDENCE](store, event) {
      // Captura as informações do evento
      const { type, id, modulo } = event;

      // Omite as informações desnecessárias
      event = omit(event, "type", "options");

      // Define o caminho
      const path = `/${modulo}`;

      // Inicia a request vazia
      let request;

      // Caso for update
      if (type === "update") {
        // Atribui request como um PUT
        request = api.store(store).put(`${path}/${id}`, event);
      }

      // Senão se for um create
      else if (type === "create") {
        // Atribui request com um POST
        // request = api.store(store).post(path, {
        //   // Informações do evento
        //   ...event,
        //   // Id do registro que tem a dependência em questão
        //   registro_id: get(this.data, "id"),
        // });
        request = api.store(store).post(path, event);
      }

      // Senão se for um delete
      else if (type === "delete") {
        // Atribui request com um DELETE
        request = api
          .store(store)
          .delete(`${path}/${event.id}`, { params: event });
      }

      // Caso contrário, objeto inválido, retorna
      else {
        throw new Error("Tipo do evento da dependência inválida");
      }

      // Retorna a promessa da requisição
      return request;
    },

    /**
     * Função que faz update de uma relação
     */
    [actions.MODULO.FICHA.UPDATE_RELATION]({ dispatch }, event) {
      // Retorna a ação de update registro
      return dispatch(actions.MODULO.FICHA.UPDATE_REGISTRO, {
        // Dados da relação
        data: event.data,
        // Modulo da relação
        modulo: event.relation.modulo,
        // Id do registro da relação
        id: event.relation.id,
        // Ignore update keys
        options: event.relation.options,
        // Relação
        relation: true,
      });
    },
  },
};
