<template>
  <v-col cols="12" md="6" lg="5" offset-lg="1">
    <v-row>
      <p-text-field
        auto-focus
        type="tel"
        name="conta"
        label="Conta"
        maxlength="5"
        v-model="data.conta"
      />
      <p-text-field
        name="descricao"
        label="Descrição"
        maxlength="30"
        v-model="data.descricao"
      />
      <p-precision-field
        readonly
        name="vlr_dia"
        label="Dia"
        sm="6"
        prefix="R$"
        v-model="data.vlr_dia"
      />
      <p-precision-field
        readonly
        name="vlr_mes"
        label="Mês"
        sm="6"
        prefix="R$"
        v-model="data.vlr_mes"
      />
      <p-precision-field
        readonly
        name="vlr_ano"
        label="Ano"
        sm="6"
        prefix="R$"
        v-model="data.vlr_ano"
      />
      <p-precision-field
        readonly
        name="vlr_saldo"
        label="Saldo"
        sm="6"
        prefix="R$"
        v-model="data.vlr_saldo"
      />
    </v-row>
  </v-col>
</template>

<script>
import { PTextField, PPrecisionField } from "@/components/form";

// Mixins
import Ficha from "@/mixins/ficha";

export default Ficha.extend({
  components: {
    PTextField,
    PPrecisionField,
  },
});
</script>
