<template>
  <v-col
    :class="colClass"
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
    v-bind="$attrs"
  >
    <!-- <div
      :class="{ 'v-input--is-dirty': internalValue }"
      @click="onClick"
      class="
        ml-1
        align-self-center
        v-input
        mt-0
        v-input--hide-details
        v-input--is-label-active
        v-input--dense
        v-input--selection-controls
        v-input--switch
        primary--text
      "
    >
      <div class="v-input__control">
        <div class="v-input__slot">
          <div class="v-input--selection-controls__input">
            <input
              role="switch"
              type="checkbox"
              @input="(event) => onChange(event.target.checked)"
              :id="`input-${_uid}`"
              :aria-disabled="disabled_"
              :disabled="disabled_"
              :name="name"
              :checked="internalValue"
            />
            <div
              @click="() => onChange(!internalValue)"
              class="v-input--selection-controls__ripple"
              :class="{ 'primary--text': internalValue }"
            />
            <div
              class="v-input--switch__track"
              :class="{ 'primary--text': internalValue }"
            />
            <div
              class="v-input--switch__thumb"
              :class="{ 'primary--text': internalValue }"
            />
          </div>
          <label
            :for="`input-${_uid}`"
            class="v-label"
            style="left: 0px; right: auto; position: relative"
            >{{ label }}</label
          >
        </div>
      </div>
    </div> -->

    <v-switch
      dense
      class="pa-0 mt-0"
      :class="switchClass"
      hide-details
      :input-value="value"
      :disabled="disabled_"
      @change="onChange"
      v-bind="$attrs"
    />

    <admin
      destroy
      @close="(authorized = false), (modal = false)"
      @auhorized="(authorized = true), onChange(!value)"
      v-if="guard && modal"
    />
  </v-col>
</template>

<script>
import Admin from "@/components/utils/Admin.vue";

export default {
  components: {
    Admin,
  },
  props: {
    value: { default: false },
    guard: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },

    colClass: { default: "px-1 py-2 d-flex" },
    switchClass: { default: "" },

    // Cols
    cols: {},
    sm: {},
    md: {},
    lg: {},
    xl: {},
  },
  data: () => ({
    modal: false,
    authorized: false,
  }),
  computed: {
    disabled_() {
      return this.disabled || (this.guard && !this.authorized);
    },
  },
  methods: {
    onChange(value) {
      if (this.disabled_) return;

      // this.$emit("input", value ? this.trueValue : this.falseValue);
      // this.$emit("change", value ? this.trueValue : this.falseValue);
      this.$emit("input", value);
      this.$emit("change", value);
    },
  },
};
</script>
