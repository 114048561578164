<template>
  <ficha
    flex-end
    ref="ficha"
    :id="id"
    :modulo="modulo"
    :__modulo="__modulo"
    :options="currentModuloOptions"
    :fullscreen="fullscreen"
    :hideFooter="hideFooter"
    @loaded="onLoaded"
    @close="$emit('close', $event)"
    v-bind="$attrs"
  >
    <template #title="{ processing, __ficha }">
      <v-card-title
        class="ficha-title px-4 py-0"
        :class="{ 'pb-1': !steps.length }"
        v-if="!hideTitle"
      >
        <v-row class="ma-0">
          <v-col cols="auto" class="pa-0 mr-4 d-flex">
            <btn
              size="x-small"
              class="my-auto"
              :loading="processing"
              :disabled="processing"
              @click="(event) => $refs.menu.open(event)"
            >
              <v-icon small>mdi-dots-horizontal</v-icon>
            </btn>
          </v-col>

          <template v-if="currentHeaderComponent">
            <v-col class="pa-0 d-flex mx-auto mx-sm-0" cols="auto">
              <component
                class="caption text-lg-subtitle-1 mt-auto text-center"
                :data="__ficha.data"
                :is="currentHeaderComponent"
              />
            </v-col>
          </template>

          <v-col cols="auto" class="pa-0 text-right ml-auto">
            <slot name="title-actions">
              <btn
                btn-class="ml-auto my-auto"
                size="x-small"
                @click="() => toggleFullscreen()"
                v-if="!$isMobile"
              >
                <v-icon small>
                  {{ fullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand" }}
                </v-icon>
              </btn>
              <btn
                color="error"
                size="x-small"
                btn-class="ml-2 my-auto"
                :loading="processing"
                :disabled="processing"
                @click="() => $refs.ficha.onModalClose()"
              >
                <v-icon small>mdi-close</v-icon>
              </btn>
            </slot>
          </v-col>
        </v-row>
      </v-card-title>

      <div v-else />
    </template>

    <template #tab="{ current, setCurrent, __ficha }">
      <stepper
        :items="steps"
        :value="current"
        :disabled="!__ficha.data.id"
        :__ficha="__ficha"
        @input="setCurrent"
        v-if="steps.length"
      />
    </template>

    <template #extra>
      <slot name="extra" />

      <component
        :is="activeContextComponent"
        @close="
          (activeContextComponent = null),
            (activeContextListeners = {}),
            (activeContextProps = {})
        "
        v-bind="activeContextProps"
        v-on="activeContextListeners"
        v-if="activeContextComponent"
      />

      <context-menu
        ref="menu"
        @click-item="onContextItemSelected"
        :context="context"
      />
    </template>

    <template #action-content="{ current }" v-if="!hideFooter">
      <template v-if="steps.length">
        <btn
          text
          color="actionbutton"
          v-if="current > 1"
          @click="toggleStep(-1)"
        >
          Voltar
        </btn>
        <btn
          outlined
          color="actionbutton"
          btn-class="focus-priority mx-2"
          @click="onNext"
          v-if="current === enabledSteps.length && current !== 1"
        >
          Próxima
        </btn>

        <btn
          outlined
          color="actionbutton"
          btn-class="focus-priority mx-2"
          :disabled="current === enabledSteps.length && current === 1"
          v-else
          @click="toggleStep(1)"
        >
          Avançar
        </btn>
      </template>

      <template v-if="fichaBottomActions && fichaBottomActions.length">
        <template v-for="({ on, ...btn }, i) in fichaBottomActions">
          <btn :key="`btn-${i}`" v-on="on || {}" v-bind="btn">
            {{ btn.title }}
          </btn>
        </template>
      </template>
    </template>
  </ficha>
</template>

<script>
import { compact, find, get, head } from "lodash";

import Ficha from "@/components/fichas/Ficha.vue";
import ContextMenu from "@/components/popups/ContextMenu.vue";

import { Stepper } from "@/components/utils";
import { Btn } from "@/components/form";

export default {
  props: {
    /**
     * $ref da instância do componente Modulo.vue
     */
    __modulo: {},

    import: { type: String },
    import_registro_id: {},
    modulo: {},
    id: {},
  },
  components: {
    Btn,
    Ficha,
    Stepper,
    ContextMenu,
  },
  /**
   * Vue override Hook
   */
  created() {
    this.init();
  },
  data: () => ({
    /**
     * Opções padrões para a ficha
     */
    currentModuloOptions: null,

    activeContextComponent: null,
    activeContextProps: {},
    activeContextListeners: {},
    fullscreen_: Boolean(
      JSON.parse(localStorage.getItem("step-fullscreen") ?? "false")
    ),
    keyCodes: {
      insert: 45,
    },
    __ficha: {},
  }),
  mounted() {
    document.addEventListener("keydown", this.onInsertPressed);

    this.__ficha = this.$refs.ficha;
    this.__ficha.__stepContainer = this;
  },
  beforeDestroy() {
    this.deinit();
  },
  computed: {
    fullscreen: {
      get() {
        return this.modulo === "cupons-fiscais" ? true : this.fullscreen_;
      },
      set(fullscreen) {
        this.fullscreen_ = fullscreen;
      },
    },
    hideTitle() {
      return this.modulo === "cupons-fiscais";
    },
    hideFooter() {
      return this.modulo === "cupons-fiscais";
    },
    /**
     * Função que retorna apenas os steps ativos
     */
    enabledSteps() {
      // Filtra os steps
      return this.steps.filter((step) => {
        if (step.rules && !this.id) return false;

        // !step.rules ||
        // Retorna true Se não tiver a função
        return (
          !step.disabled ||
          // Ou se tiver regras e não tiver id
          // Ou se o retorno da função for false
          !step.disabled.bind(this)({
            extra: get(this.__ficha, "extra", {}),
            data: get(this.__ficha, "data", {}),
          })
        );
      });
    },
    /**
     * Componente Header atual
     */
    currentHeaderComponent() {
      // Tenta
      try {
        // Formatar o modulo
        const modulo = this.$moduloFilename(this.modulo);
        // Tentar recuperar a header do modulo
        return require(`@/components/fichas/headers/${modulo}`).default;
      } catch (e) {
        window.error(e);
        // Caso errors retorna null
        return null;
      }
    },
    /**
     * Ações do menu contexto
     */
    context() {
      const isFiscal = ["saida", "servicos", "cupons-fiscais"].includes(
        this.modulo
      );

      return compact([
        isFiscal ? { id: "orcamento", title: "Importar Orçamento" } : null,
        this.$emitente.modulo_ordem_servico && isFiscal
          ? { id: "ordem-servico", title: "Importar Ordem de Serviço" }
          : false,
        this.modulo === "saida"
          ? {
              id: "cupons-fiscais",
              title: `Importar ${this.$nomeCupomFiscal}`,
              divider: true,
            }
          : null,
        {
          id: "new-ficha",
          params: { modulo: "estoque" },
          title: `Novo Produto`,
        },
        {
          id: "new-ficha",
          params: { modulo: "cadastro" },
          title: `Novo Cadastro`,
          divider: true,
        },
        {
          id: "new-ficha",
          params: { modulo: "emitente" },
          title: `Dados Emitente`,
        },
        this.modulo === "saida"
          ? {
              id: "new-ficha",
              params: { modulo: "nfe" },
              title: "Configurações",
            }
          : null,
        this.modulo === "servicos"
          ? {
              id: "new-ficha",
              params: { modulo: "nfse" },
              title: "Configurações",
            }
          : null,
      ]);
    },
    /**
     * Steps que serão renderizados para o módulo atual
     */
    steps() {
      return this.currentModuloOptions
        ? this.currentModuloOptions.steps ?? []
        : [];
    },
    fichaBottomActions() {
      return this.currentModuloOptions
        ? this.currentModuloOptions.fichaBottomActions ?? []
        : [];
    },
  },
  methods: {
    init() {
      try {
        // Tentar recuperar as informações principais do módulo, se vier vazias ou caso encontrar erro, define um default
        const {
          steps,
          fichaBottomActions,
        } = require(`@/config/modulos/${this.modulo}`);

        // Seta as options
        this.currentModuloOptions = {
          steps: steps ?? [],
          fichaBottomActions:
            typeof fichaBottomActions === "function"
              ? fichaBottomActions.bind(this)()
              : [],
        };
      } catch (e) {
        // Debuga o erro
        window.error(e);
        return;
      }
    },
    deinit() {
      document.removeEventListener("keydown", this.onInsertPressed);
    },
    /**
     * Função ativada quando é clicado o botão Próxima (novo registro)
     */
    onNext() {
      // Limpa a ficha e adiciona os valores defaults
      this.__ficha.clearAndGoToNew(head(this.steps));
    },
    /**
     * Função executada quando carregar
     */
    onLoaded() {
      // Se houver ação de import, abre a janela
      if (this.import) {
        this.requireForImport();
      }
    },
    /**
     * Função que alterna a tela cheia
     */
    toggleFullscreen() {
      // Inverte a tela cheia
      this.fullscreen = !this.fullscreen;

      // Salva no storage
      localStorage.setItem("step-fullscreen", JSON.stringify(this.fullscreen));
    },
    /**
     * Quando selecionar um item do menu context
     */
    onContextItemSelected(action) {
      // Alterna entre id
      switch (action.id) {
        case "orcamento":
        case "ordem-servico":
        case "cupons-fiscais":
          // Abre a janela
          return this.$nextTick(() => {
            this.requireForImport(action.id);
          });
        case "new-ficha":
          return this.requestFichaAuxiliar(action.params);
      }
    },
    /**
     * Função que requisita uma ficha auxiliar
     */
    requestFichaAuxiliar(params) {
      this.__ficha.initFichaAuxiliar(params);
    },
    /**
     * Função que abre a janela de import
     */
    requireForImport(action = null) {
      action = action ?? this.import;

      // Se já tiver aberto, retorna
      if (this.activeContextComponent !== null) return;

      // Importa o componente
      return import("@/components/popups/actions/Importar.vue").then(
        (Import) => {
          // Atribui as props
          this.activeContextProps = {
            origin: action,
            target: this.modulo,
            initialOriginId: this.import_registro_id,
            registro: this.__ficha.data,
            __ficha: this.__ficha,
          };

          // Aguarda um tick
          return this.$nextTick(
            // Abre o componente
            () => (this.activeContextComponent = Import.default)
          );
        }
      );
    },
    onInsertPressed(event) {
      const { insert } = this.keyCodes;

      const events = {
        [insert]: () => {
          this.$refs.menu.open(event);
        },
      };

      const event_ = events[event.keyCode];

      if (event_ && !event_()) {
        event.preventDefault();
      }
    },
    /**
     * Função que gerencia a troca de passos pelo rodapé (voltar e próximo)
     */
    toggleStep(pos) {
      if (!this.__ficha) return;

      const next = find(this.steps, (step) => {
        return (
          step.item === this.__ficha.getCurrent + pos &&
          (!step.rules || get(this.__ficha, "data.id"))
        );
      });

      if (!next) return;

      if (
        next.disabled &&
        next.disabled({
          data: get(this.__ficha, "data", {}),
          extra: get(this.__ficha, "extra", {}),
        })
      )
        return;

      this.__ficha.setCurrent(next);
    },
  },
  watch: {
    modulo(modulo, old) {
      if (!modulo || modulo === old) return;

      this.deinit();
      this.init();

      return this.$nextTick(() => {
        this.__ficha.deinit();
        this.__ficha.init();
      });
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__title.ficha-title {
  padding: 8px 16px 0px 16px;
}
</style>
