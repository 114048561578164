<template>
  <v-card height="35px" class="" elevation="0">
    <v-tabs
      center-active
      show-arrows
      height="35px"
      background-color=""
      :value="value"
      @change="onSelectTab"
    >
      <v-tabs-slider />

      <template v-for="(tab, i) in items">
        <v-tab
          :key="i"
          :href="`#${tab.item}`"
          :disabled="isDisabled(tab)"
          class=""
          v-if="!isHidden(tab)"
        >
          {{ tab.title }}
        </v-tab>
      </template>
    </v-tabs>
  </v-card>
</template>

<script>
import {
  head,
  findIndex,
  filter,
  map,
  reduce,
  debounce,
  get,
  find,
} from "lodash";

export default {
  props: {
    value: {},
    disabled: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },

    __ficha: {},
  },
  data: () => ({
    booted: false,

    showArrows: false,
    currentPage: 1,
    offset: 0,
    lastTouchX: null,
    firstTouchX: null,
    groupWrapperWidth: null,
    groupWrapperContentWidth: null,
  }),
  /**
   * Antes de montar o componente
   */
  beforeMount() {
    this.$emit("input", head(this.items));
  },
  /**
   * Quando o componente montar
   */
  mounted() {
    this.booted = true;
  },
  computed: {
    /**
     * Função que retorna os dados da ficha
     */
    data() {
      return get(this.__ficha, "data") ?? {};
    },
    /**
     * Função que determina a posição do slider
     */
    slider() {
      // Captura o índice ativo
      const currentIndex = findIndex(
        // Tabs
        this.items,
        // Tab ativa
        (tab) => this.value === tab.item
      );

      // Captura a lista das tabs anteriores à selecionada
      let previousList = filter(
        // Tabs
        this.items,
        // Anteriores
        (_, index) => index < currentIndex
      );

      // Captura a atual
      const current = this.items[currentIndex];

      // A lista de anteriores é mapeada
      previousList = map(previousList, (tab) => {
        // Captura o elemento
        const leftEl =
          this.$el && this.$el.querySelector(`[href='#${tab.item}']`);

        // Retorna a largura do elemento
        return leftEl && leftEl.clientWidth;
      });

      // Faz a soma das larguras
      const sum = reduce(previousList, (a, b) => a + b, 0);

      // Captura o elemento atual
      const currentEl =
        this.$el &&
        this.$el.querySelector(`[href='#${current && current.item}']`);

      // Retorna um objeto com as informações do slider
      return {
        // Altura fixa
        height: "2px",
        // Esquerda vai ser a soma dos elementos a esquerda
        left: `${sum}px`,
        // Largura da tab ativa
        width: `${(currentEl && currentEl.clientWidth) || 0}px`,
      };
    },
  },
  methods: {
    /**
     * Função que retorna se a tab está desabilitada
     */
    isDisabled(tab) {
      return tab.disabled && tab.disabled.bind(this)(this.data);
    },
    /**
     * Função que retorna se a tab está desabilitada
     */
    isHidden(tab) {
      return tab.hidden && tab.hidden.bind(this)(this.data);
    },
    /**
     * Função chamada sempre que for redimensionada a página
     */
    onResize: debounce(function () {
      // Captura o elemento com as tabs
      const wrapper = this.$refs.groupWrapperContent;

      // Se não houver retorna
      if (!wrapper) return false;

      // Captura a largura
      const { clientWidth: ww } = wrapper;

      // Captura a largura da div pai
      const { clientWidth: wpw } = wrapper.parentNode;

      // Se a largura dos itens for maior que a do pai, quer dizer que precisa aparecer as flechas
      this.showArrows = ww > wpw;
    }, 300),
    /**
     * Função chamada quando alguma das setas é clicada
     */
    onScrollContent(type) {
      // Captura a lista de tabs
      const wrapper = this.$refs.groupWrapperContent;

      // Se não houver retorna
      if (!wrapper) return false;

      // Captura a largura
      const { clientWidth: ww } = wrapper;

      // Define o total de páginas
      const totalPages = 4;
      // A largura de cada página
      const pageWidth = ww / totalPages;

      // Se for seta para frente
      if (type === "next") {
        // Se estiver passando da ultima pagina, retorna
        if (this.currentPage >= totalPages - 1) return;
      }
      // Caso for seta para trás e for passar da primeira página, retorna
      else if (this.currentPage - 1 === 0) return;

      // Atribui a página
      this.currentPage += type === "next" ? 1 : -1;

      // Faz a lista andar para os lados
      this.offset += type === "next" ? -pageWidth : pageWidth;
    },
    /**
     * Função chamada quando é feito um toque nas tabs
     */
    onTouchStart(event) {
      // Captura o lugar exato do toque
      const { clientX } = head(event.touches);
      // Captura a largura dos itens
      const { clientWidth: group } = this.$refs.groupWrapper;
      // Captura a largura
      const { clientWidth: groupContent } = this.$refs.groupWrapperContent;

      // Seta as informações para usar na hora de calcular a posição
      this.lastTouchX = clientX;
      this.groupWrapperWidth = group;
      this.groupWrapperContentWidth = groupContent;
    },
    /**
     * Função chamada quando um toque é movido pelas tabs
     */
    onTouchMove(event) {
      // Captura o local que começou a mover
      const { clientX } = head(event.touches);

      // Calcula a distância movida
      const delta = clientX - this.lastTouchX;

      // Seta o offset com o delta, baseado na direção
      this.offset =
        clientX > this.lastTouchX ? this.offset + delta : this.offset - -delta;

      // Atribui o último toque
      this.lastTouchX = clientX;
    },
    /**
     * Função chamada quando o toque é solto da tela
     */
    onTouchEnd() {
      // Se o offset for maior que 0, atribui 0
      if (this.offset > 0) this.offset = 0;

      // Captura o offset final
      const endOffset = this.groupWrapperContentWidth - this.groupWrapperWidth;

      // Se o -offset for maior que o final, quer dizer que ultrapassou os limites
      if (-this.offset > endOffset)
        // Atribui para o máximo permitido
        this.offset = -endOffset;

      // Reseta as informações
      this.lastTouchX = null;
      this.groupWrapperWidth = null;
      this.groupWrapperContentWidth = null;
    },
    /**
     * Função que seleciona uma tab
     */
    onSelectTab(value) {
      // Se o valor for o mesmo, retorna a função de mesma seleção
      if (this.value === value) return this.onSelectSameTab(item);

      const item = find(this.items, (item) => item.item === value);

      // Emite o evento
      this.$emit("input", item);
    },
    /**
     * Função que seleciona a mesma tab ativa
     */
    onSelectSameTab(item) {
      // Emite o evento
      this.$emit("reselected", item);
    },
  },
};
</script>

<style lang="scss">
.s-tabs
  > .v-item-group.theme--light.v-slide-group.v-tabs-bar.v-tabs-bar--show-arrows.grey--text.text--darken-3.tabs {
  height: 38px !important;
}
</style>
<style scoped>
.s-tabs {
  flex: none;
}
.v-tabs-bar {
  height: 35px !important;
}
</style>
