var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticStyle:{"outline":"none"},attrs:{"tabindex":"0"},on:{"mousedown":_vm.onMouseDown,"keydown":_vm.onKeyDown,"contextmenu":function($event){$event.preventDefault();return _vm.$emit('context', $event)}}},[_c('div',{directives:[{name:"scroll",rawName:"v-scroll.self",value:(_vm.onScroll),expression:"onScroll",modifiers:{"self":true}}],ref:"wrapper",staticClass:"s-datatable-wrapper",class:{ footer: !_vm.hideFooter && !_vm.$isMobile }},[(_vm.simpleLoading && _vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","relative":""}}):_vm._e(),_c('table',{ref:"table",staticClass:"s-datatable",class:{ fixed: _vm.fixed }},[(!_vm.hideHeader && (_vm.headers || !_vm.loading))?_c('thead',{ref:"thead",staticClass:"s-datatable-thead"},_vm._l((_vm.headers),function(header,i){return _c('data-table-header',{key:i,attrs:{"order":_vm.order,"header":header},on:{"click":function($event){return _vm.$emit('click-header', header)},"dblclick":function($event){return _vm.$emit('double-click-header', header)},"resize:header":(event) => _vm.$emit('resize:header', event)}})}),1):_vm._e(),_c('tbody',{ref:"tbody",staticClass:"s-datatable-tbody"},[_vm._l((_vm.items_),function(item,index){return [_c('data-table-row',{key:index},[_vm._l((_vm.headers),function(header,column){return [_c('data-table-cell',_vm._b({key:column,ref:`data-table-cell-item-${index}-${column}`,refInFor:true,class:_vm._f("classMapping")([
                    item[`${header.value}_color`],
                    item.row_color,
                    header.class,
                  ]),attrs:{"color":item.color || item.row_color || header.color,"editable":_vm.editable && !header.noEditable,"selected":_vm.selectable &&
                  header.selectable !== false &&
                  column === _vm.value.coluna &&
                  item.id === _vm.selectedId,"item":item,"icon":header.type === 'icon' || header.type === 'value-icon'
                    ? item.icon
                    : null,"selectable":_vm.selectable,"disabled":header.disabled &&
                  (typeof header.disabled === 'function'
                    ? header.disabled(item)
                    : header.disabled === true || !item[header.disabled]),"value":_vm.getModelValue(item, header)},on:{"mousedown":(event) =>
                    _vm.onCellClick(event, {
                      item,
                      header,
                      index,
                      coluna: column,
                    }),"dblclick":function($event){return _vm.$emit('dblclick-cell', { item, header, coluna: column })},"focus":(event) =>
                    _vm.onInputFocused(event, {
                      item,
                      header,
                      index,
                      coluna: column,
                    }),"change-input":(event) => _vm.onInputChange(event, { item, header }),"input":(value) => _vm.onSetModelValue(item, value, header)},scopedSlots:_vm._u([{key:"persistent-select",fn:function(attrs){return [_vm._t("persistent-select",null,null,{
                      ...attrs,
                      item,
                      header,
                      column,
                      index,
                      disabled: _vm.disabled,
                    })]}},{key:"textfield",fn:function(attrs){return [_vm._t("textfield",null,null,{
                      ...attrs,
                      item,
                      header,
                      column,
                      index,
                      disabled: _vm.disabled,
                    })]}},{key:"select",fn:function(attrs){return [_vm._t("select",null,null,{
                      ...attrs,
                      item,
                      header,
                      column,
                      index,
                      disabled: _vm.disabled,
                    })]}}],null,true)},'data-table-cell',header,false))]})],2)]})],2)])],1),(!_vm.hideFooter && !_vm.$isMobile)?_c('div',{ref:"footer",staticClass:"s-datatable-footer text-center text--text"},[_vm._t("footer-content")],2):_vm._e(),(!_vm.simpleLoading)?_c('v-overlay',{attrs:{"absolute":"","value":_vm.loading,"opacity":0.5}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticStyle:{"background-color":"transparent"},attrs:{"flat":""}},[_c('v-col',{staticClass:"splash",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('splash',{attrs:{"fixed":"","white":"","width":!_vm.$isMobile ? '30vw' : '80vw'}})],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }