import mutations from "@/store/mutations";
import Vue from "vue";

let deferredPrompt;

export default Vue.extend({
  data: () => ({
    installPromotion: false,
  }),
  created() {
    this.$store.commit(
      mutations.APP.DISPLAY_MODE,
      (this as any).$getPWADisplayMode()
    );

    // se não estiver em pwa
    if ((this as any).$appDisplayMode !== "standalone") {
      // ouve o evento de beforeinstallprompt
      window.addEventListener(
        "beforeinstallprompt",
        this.onBeforeInstallPrompt
      );
    }

    window.addEventListener("appinstalled", this.onAppInstalled);
  },
  beforeDestroy() {
    window.removeEventListener(
      "beforeinstallprompt",
      this.onBeforeInstallPrompt
    );
    window.removeEventListener("appinstalled", this.onAppInstalled);
  },
  methods: {
    onBeforeInstallPrompt(e) {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      this.showInstallPromotion();
    },
    onAppInstalled() {
      // Hide the app-provided install promotion
      this.hideInstallPromotion();
      // Clear the deferredPrompt so it can be garbage collected
      deferredPrompt = null;
    },
    showInstallPromotion() {
      // nsipa = never show install promotion again
      if (window.localStorage.getItem("nsipa") === "true") return;

      // aparece a sugestão apenas quando estiver dentro do sistema
      if (!window.location.origin.includes("app.")) return;

      this.installPromotion = true;
    },
    hideInstallPromotion(condition = "") {
      if (condition === "never") {
        window.localStorage.setItem("nsipa", "true");
      }

      this.installPromotion = false;
    },
    async onPromotionInstall() {
      // Hide the app provided install promotion
      this.hideInstallPromotion();

      // Show the install prompt
      deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      // const { outcome } = await deferredPrompt.userChoice;

      // We've used the prompt, and can't use it again, throw it away
      deferredPrompt = null;
    },
  },
});
