<template>
  <modal
    movable
    title="Informar Consumidor"
    :value="true"
    width="50vw"
    @input="$emit('close')"
  >
    <template #text>
      <v-col cols="12" class="px-1 py-1">
        <v-row no-gutters class="justify-center">
          <clifor-combobox-v2
            md="10"
            auto-focus
            label="CPF/CNPJ / Razão Social do Cliente"
            :item-text="
              (it) =>
                it.cpf_cnpj || it.nome_razao_social || `Cliente ${it.id || ''}`
            "
            :item-subtitle="
              (it) => `${!it.cpf_cnpj ? '' : it.nome_razao_social || ''}`
            "
            @click:append-outer="onEditCliforClicked"
            v-model="data.clifor"
          />
        </v-row>

        <ficha
          use-as-slot
          modulo="cadastro"
          class="d-flex justify-center"
          :initial-data="typeof data.clifor === 'object' ? data.clifor : {}"
          :id="clifor.id"
        >
          <p-text-field
            md="10"
            name="nome_razao_social"
            label="Razão Social"
            :event="{ force: true }"
            :readonly="!data.clifor_id"
            v-model="clifor.nome_razao_social"
          />
        </ficha>
      </v-col>
    </template>
  </modal>
</template>

<script>
import { Btn, PTextField, CliforComboboxV2 } from "@/components/form";

import Clifor from "@/mixins/clifor";
import Ficha from "@/components/fichas/Ficha.vue";
import Modal from "@/components/utils/Modal.vue";

export default Clifor.extend({
  components: {
    Btn,
    Ficha,
    Modal,

    PTextField,
    CliforComboboxV2,
  },
  props: {
    data: { default: () => ({}) },
  },
  computed: {
    clifor() {
      return this.data.clifor ?? {};
    },
  },
});
</script>
