var render = function render(){var _vm=this,_c=_vm._self._c;return _c('p-select-search',_vm._g(_vm._b({ref:"select",attrs:{"observe-items":"","item-text":"nome_razao_social","listItemHeight":50,"item-value":_vm.itemValue,"value-not-null":_vm.valueNotNull,"source":_vm.source,"target":_vm.target,"name":_vm.name,"label":_vm.label,"filter-by":_vm.target,"filter":_vm.filter,"initial":_vm.clifor || null,"input-initial":_vm.clifor && _vm.clifor.nome_razao_social,"value":_vm.value},scopedSlots:_vm._u([{key:"list-item-content",fn:function({ item, buildTexts }){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"caption",class:{
          'v-select-list-item-selected': item[_vm.itemValue] === _vm.value,
        },domProps:{"textContent":_vm._s(buildTexts(item))}}),_vm._t("subtitle",function(){return [_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
            `${item.cpf_cnpj || ''} ${item.municipio || ''} ${
              item.uf || ''
            }&nbsp;`
          )}})]},null,{ item })],2)]}}],null,true)},'p-select-search',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }