import { each, includes, split } from "lodash";
import Vue from "vue";

export const minScore = 40;
const WEAK_PASSWORD = "A senha é fraca. Por favor, inclua números e pontuações";

export default Vue.extend({
  data: () => ({
    pw: {
      errors: [] as string[],
      rules: [],
      scoreColor: "",
      score: 0,
    },
  }),
  computed: {
    isPasswordValid() {
      return !this.pw.errors.length && this.pw.score > minScore;
    },
  },
  methods: {
    checkPass(password: string, confirmPassword: string) {
      // Limpa os erros
      this.pw.errors.splice(0);

      // Para cada regra
      each(this.pw.rules, (rule) => {
        // Se houver regra min
        if (includes(rule, "min")) {
          // Captura o min no formato min:xxx
          const [, minLength] = split(rule, ":");

          // Se a senha for menor que o min
          if (password.length < parseInt(minLength))
            // Adiciona o erro na lista
            this.pw.errors.push(
              `A senha deve conter ao menos ${minLength} caracteres.`
            );
        }

        // Se tiver regra de senhas iguais e a senha for diferente da confirmação
        if (
          includes(rule, "strong") &&
          !/[*+?^$.,;:/@!#$%&()]/.test(password)
        ) {
          // Adiciona o erro na lista
          this.pw.errors.push(WEAK_PASSWORD);
        }

        // Se tiver regra de senhas iguais e a senha for diferente da confirmação
        if (includes(rule, "equals") && password !== confirmPassword)
          // Adiciona o erro na lista
          this.pw.errors.push("As senhas não coincidem.");
      });

      // Checa o score
      const score = this.scorePassword(password);

      // Se a pontuação for menor que a mínima
      if (score < minScore)
        // Adiciona o erro
        this.pw.errors.push(
          "A força da sua senha precisa ser no mínimo amarela."
        );
    },
    /**
     * Função que determina o score da senha
     */
    scorePassword(password: string) {
      // Reinicia o score
      this.pw.score = 0;

      // Se não houver senha, retorna
      if (!password) return 0;

      // Inicia as letras
      const letters = new Object();

      // Para cada caractere da senha
      for (let i = 0; i < password.length; i++) {
        // Captura o caractere da senha
        letters[password[i]] = (letters[password[i]] || 0) + 1;

        // Incrementa o score
        this.pw.score += 5.0 / letters[password[i]];
      }

      // bonus points for mixing it up
      const variations = {
        digits: /\d/.test(password),
        lower: /[a-z]/.test(password),
        upper: /[A-Z]/.test(password),
        nonWords: /\W/.test(password),
      };

      // Inicia a contagem das variações
      let variationCount = 0;

      // Para cada variação
      for (const check in variations) {
        // incrementa a variação em 1, caso o teste for com sucesso
        variationCount += variations[check] == true ? 1 : 0;
      }

      // Incrementa o score com base na variação
      this.pw.score += (variationCount - 1) * 10;

      // if (!this.isPasswordValid) {
      if (this.pw.errors.includes(WEAK_PASSWORD)) {
        this.pw.score = Math.min(this.pw.score, minScore);
      }

      // Define a cor do score
      this.pw.scoreColor =
        this.pw.score > 80
          ? "success"
          : this.pw.score > minScore
          ? "warning"
          : "error";

      // Retorna o score
      return this.pw.score;
    },
    /**
     * Função que reseta o score
     */
    resetScore() {
      // REseta o score
      this.pw.score = 0;
    },
  },
});
