<template>
  <v-col
    class="px-1 pb-1 pt-2"
    :cols="cols"
    :sm="sm"
    :md="md"
    :lg="lg"
    :xl="xl"
  >
    <v-autocomplete
      dense
      outlined
      multiple
      hide-details
      :value="value"
      :itemText="itemText"
      :itemValue="itemValue"
      @focus="onFocus"
      @blur="onBlur"
      v-bind="$attrs"
      v-on="listeners"
      color="primary"
    >
      <template #item="{ item, on, attrs }">
        <v-list-item dense v-on="on" v-bind="attrs" style="min-height: 30px">
          <v-list-item-action class="mr-1 my-2">
            <v-icon>
              {{
                value.includes(item[itemValue])
                  ? "mdi-checkbox-marked"
                  : "mdi-checkbox-blank-outline"
              }}
            </v-icon>
          </v-list-item-action>
          <v-list-item-title class="caption">
            {{ item[itemText] }}
          </v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:append>
        <btn icon tabindex="-1">
          <v-icon
            :color="$attrs.white ? 'white' : 'primary'"
            :class="{ 'v-icon-animated': menu }"
          >
            mdi-menu-down
          </v-icon>
        </btn>
      </template>
    </v-autocomplete>
  </v-col>
</template>

<script>
import omit from "lodash/omit";

import { Btn } from "@/components/form";

import Persistent from "./persistent";

export default Persistent.extend({
  name: "PMultipleSelectFilter",
  components: { Btn },
  props: {
    value: { default: () => [] },

    itemText: {},
    itemValue: {},

    cols: {},
    sm: {},
    md: {},
    lg: {},
    xl: {},
  },
  data: () => ({
    input: false,
    menu: false,
  }),
  computed: {
    listeners() {
      return omit(this.$listeners, ["focus", "blur"]);
    },
  },
  methods: {
    onFocus(event) {
      this.menu = true;
      this.$emit("focus", event);
      this.input = this.value;
    },
    onBlur(event) {
      this.menu = false;
      this.$emit("blur", event);

      if (this.input !== this.value) {
        this.onChanged(this.value, this.input);
      }
    },
  },
});
</script>

<style scoped>
.v-icon-animated {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  transform: rotate(180deg);
}
</style>
