<template>
  <p-text-field
    type="tel"
    :name="name"
    :label="label"
    :value="value"
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    v-mask="'#####-###'"
    v-bind="$attrs"
  >
  </p-text-field>
</template>

<script>
import { mask } from "vue-the-new-mask";

import { PTextField } from "@/components/form";

export default {
  components: { PTextField },
  directives: { mask },
  props: {
    name: { default: "cep" },
    label: { default: "CEP" },
    value: {},
  },
};
</script>
