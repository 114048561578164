<template>
  <p-text-field
    type="tel"
    ref="textfield"
    :name="name"
    :label="label"
    :value="value"
    @input="$emit('input', $event)"
    v-bind="$attrs"
  >
    <template #append>
      <v-btn icon small tabindex="-1" color="primary" @click="onSnClick">
        S/N
      </v-btn>
    </template>
  </p-text-field>
</template>

<script>
import { PTextField } from "@/components/form";

export default {
  components: { PTextField },
  props: {
    value: {},
    name: { default: "numero" },
    label: { default: "Número" },
  },
  methods: {
    onSnClick() {
      if (this.$refs.textfield) {
        this.$refs.textfield.onChanged("S/N");
        this.$emit("input", "S/N");
      }
    },
  },
};
</script>
