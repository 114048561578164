import { findIndex, isNull, isUndefined, isEmpty } from "lodash";

/**
 * Função que identifica o componente atual focused, e encontra o próximo válido para fazer focus
 * @param {String} currentTextFieldId Id do componente atual para referência do próximo
 */
export const focusNextTextField = (
  event: KeyboardEvent,
  currentTextFieldId: string
) => {
  // Captura todos os inputs
  const inputs = document.querySelectorAll(
    `#${currentTextFieldId}, input[id^='input-']:not([disabled]):not([role='switch']), 
    textarea[id^='textarea-']:not([readonly]):not([disabled]):not(.ignore-enter-focus),
    button.v-btn.focus-priority:not([readonly]):not([disabled])`
  ) as NodeListOf<HTMLElement>;

  // Encontra o index do componente atual na lista
  const currentIndex = findIndex(
    inputs,
    // Compara se o id do input percorrido é igual ao id do componente atual
    (input) => input.id === currentTextFieldId
  );

  // Acessa o próximo elemento da lista, que seria o próximo input da sequência
  let target =
    inputs[event && event.shiftKey ? currentIndex - 1 : currentIndex + 1];

  // Se encontrar o próximo da sequência
  if (target) {
    setTimeout(() => {
      // faz o focus do mesmo.
      target.focus();
    }, 40);

    return target;
  }

  // Captura o input atual
  target = inputs[currentIndex];

  // Se houver
  if (target)
    // Faz o blur
    target.blur();
};

/**
 * Função que limpa a formatação monetária
 */
export const clearCurrency = (value: any): number => {
  if (!value) return 0.0;

  if (value.toString().indexOf(",") === -1) {
    return parseFloat(value);
  }

  return parseFloat(
    (value ?? "").toString().replaceAll(".", "").replace(",", ".")
  );
};

/**
 * Função que gera a formatação monetária
 */
export const setCurrency = (value: any, fixed = 2) => {
  return new Intl.NumberFormat("pt-BR", {
    maximumFractionDigits: fixed,
    minimumFractionDigits: fixed,
  }).format(value ?? 0.0);
};

export const floor = (value: number) =>
  Math.floor((value + Number.EPSILON) * 100) / 100;

export const isInvalid = (value: any) =>
  isNull(value) || isUndefined(value) || isEmpty(value);

// export const isCpf = (value: any) =>
//   /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value || "");

export const isCpf = (value: any) =>
  (value || "").replace(/[^0-9]/g, "").length === 11;

/** indicador: 1 = saida, 0 = entrada */
export const handleCfop = (
  cfop?: string,
  ufEmit?: string,
  ufDest?: string,
  indicador = "1"
) => {
  cfop = cfop ?? "";

  if (cfop.startsWith("7") || cfop.startsWith("3")) return cfop;

  return indicador == "0"
    ? (ufDest === ufEmit ? "1" : "2") + cfop.substring(1)
    : (ufDest === ufEmit ? "5" : "6") + cfop.substring(1);
};
