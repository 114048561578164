<template>
  <v-dialog
    max
    persistent
    scrollable
    hide-overlay
    no-click-animation
    :width="$isMobile ? '90vw' : width"
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-card-text class="pa-0">
        <v-alert
          prominent
          colored-border
          border="left"
          class="ma-0 pl-5"
          :color="$isMobile ? type_ : null"
          :type="!$isMobile ? type_ : null"
        >
          <v-row>
            <v-col class="grow">
              <div class="title">{{ title }}</div>
              <div
                class="overflow-y-auto text-body-2 text-lg-body-1"
                style="max-height: 70vh"
                v-html="message"
              />
              <div class="mt-4" v-if="errors.length">
                <ul>
                  <li v-for="(e, i) in errors" :key="i">
                    {{ e }}
                  </li>
                </ul>
              </div>
              <div class="mt-4" v-if="detail">
                <code>
                  {{ detail }}
                </code>
              </div>
              <div class="mt-4" v-if="help">
                Já possuímos um artigo mapeado para essa situação.
                <div class="mt-1">
                  <btn color="success" @click="() => $help(help)"
                    >Acessar Artigo</btn
                  >
                </div>
              </div>
              <slot name="content" />
              <div class="row pa-4 myt-6" v-if="image">
                <v-img
                  :src="image"
                  contain="contain"
                  width="300px"
                  height="300px"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              <btn
                outlined
                color="actionbutton"
                :loading="loading"
                :disabled="loading"
                v-if="type === 'error'"
                @click="copyToClipboard"
              >
                Copiar
              </btn>
              <btn
                v-if="cancelable"
                outlined
                :auto-focus="type !== 'popup'"
                color="actionbutton"
                btn-class="ml-1"
                :loading="loading"
                :disabled="loading"
                @click="
                  $emit('input', false),
                    $emit('close', $event),
                    $emit('cancel', $event)
                "
              >
                {{ cancelText }}
              </btn>
              <btn
                auto-focus
                :color="type === 'error' ? 'error' : 'success'"
                btn-class="ml-1"
                :loading="loading"
                :disabled="loading"
                @click="onClickOk"
                v-if="type !== 'popup'"
              >
                {{ okText }}
              </btn>
              <btn
                outlined
                auto-focus
                btn-class="ml-1"
                @click="onOpenPopup"
                v-if="type === 'popup'"
              >
                Visualizar
              </btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { Btn } from "@/components/form";
import copy from "copy-to-clipboard";

export default {
  components: {
    Btn,
  },
  props: {
    width: { default: "60vw" },
    value: {},

    cancelable: { type: Boolean, default: false },

    type: { default: "alert" },

    warning: { default: false },
    success: { default: false },
    loading: { default: false, type: Boolean },
    preventClose: { default: false, type: Boolean },
    info: { default: false },
    error: { default: true },

    cancelText: { default: "Cancelar" },
    okText: { default: "Ok" },

    popupURL: { default: "" },
    message: { default: "" },
    title: { default: "" },
    detail: { default: "" },
    help: { default: "" },
    image: { default: "" },
    errors: { type: Array, default: () => [] },
  },
  methods: {
    onOpenPopup() {
      // Abre o link na nova aba
      window.open(this.popupURL, "_blank");

      // Aguarda o tempo do foco
      setTimeout(() => {
        // Fecha o alert
        this.onClickOk();
      }, 40);
    },
    onClickOk() {
      // se não prever close, fecha o alert
      !this.preventClose && (this.$emit("input", false), this.$emit("close"));

      // Emite evento de confirmar
      this.$emit("ok");
    },
    copyToClipboard() {
      copy(
        `[${this.title || ""}] ${this.message || ""} ${
          this.detail || ""
        }\n${this.errors.join("\n")}`
      );
    },
  },
  computed: {
    type_() {
      return this.warning
        ? "warning"
        : this.success
        ? "success"
        : this.info
        ? "info"
        : "error";
    },
  },
};
</script>
